import React from 'react'
import Title from '../../../components/Title';

import {
  Box, Grid, IconButton, InputAdornment, TextField, Button, styled, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
  FormLabel,
  FormControl,
  Paper,
  Typography,
  Divider,
  Popover,
  Pagination,
  Stack,
} from '@mui/material'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate } from 'react-router-dom';
// import '../../../theme/style.scss';
import ReactQuill from 'react-quill';
import { Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import { AsyncCustomSelect } from '../../../components/CustomFields/CustomSelect';
import DraggableList from '../../../components/DraggableTable/DraggableList';
import { reorder } from '../../../Helpers/helpers';
import { DropResult } from 'react-beautiful-dnd';


export default function InvestigationMasterList() {
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl3] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const rowData = [
    {
      followUpReason: 'Regular follow up',

    },
    {
      followUpReason: 'Review reports',
    }
  ]

  const CustomTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: '#FAFBFD', // Set the desired background color
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.grey[300],
        borderWidth: '1px',
        BorderStyle: 'solid'
      },
      '&:hover fieldset': {
        borderColor: theme.palette.grey[500],
        borderWidth: '1px',
        BorderStyle: 'solid'
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: "2px",

      },
    },
  }));
  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(itemsOther, source.index, destination.index);

    setItemsOther(newItems);
  };
  const [itemsOther, setItemsOther] = React.useState([
    {
      id: "Item2 1",
      primary: "2Practical Rubber Computer1",
      secondary: "1Synergistic leading edge matrices"
    }, {
      id: "Item1 2",
      primary: "2Practical Rubber Computer2",
      secondary: "1Synergistic leading edge matrices"
    }, {
      id: "Item1 3",
      primary: "2Practical Rubber Computer3",
      secondary: "2Synergistic leading edge matrices"
    }
  ]);

  // for Edit & Delet pop 
  const handleOpenMore = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: any
  ) => {
    setAnchorEl3(event.currentTarget);
    // setBuyerId(id);
  };
  const handleCloseMore = () => {
    setAnchorEl3(null);
  };
  //  Table Header 
  //  Table Header 
  interface EnhancedTableProps {
    numSelected: number;
    //onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    //order: Order;
    orderBy: String;
    rowCount: number;
  }


  return (
    
    <Box className="main-container-popup" pl={2}>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
        }}
        onSubmit={async (values) => {
          console.log("values", values);
        }}
      >
        <Grid container spacing={2} className="padding-add-Investigation">
          <Grid item xs={12} mb={2} className="group-container">
            <Grid container item spacing={2} >
              <Grid item xs={12} sm={12} md={12}>
                <FormControl fullWidth >
                  <FormLabel className='fileds-label-text'>Instructions to take medicine</FormLabel>
                  <ReactQuill className="react-quill-alignment" theme="snow" value={""} onChange={(e) => { }} />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <></>
              </Grid>
            </Grid>
          </Grid>
           
        </Grid>
      </Formik>
    </Box>
  )
}
