import React from "react";
import { useNavigate } from "react-router-dom";
import "../../../theme/style.scss";
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import Title from "../../../components/Title";
import LoadingButton from "@mui/lab/LoadingButton";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { AsyncCustomSelect, CustomSelect } from "../../../components/CustomFields/CustomSelect";
import { Formik } from "formik";
import CustomBreadcrumbs from '../../../components/BreadcrumbsComponent/CustomBreadcrumbs'

export default function MedicineInstructionSave() {
  const CustomTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: "#FAFBFD", // Set the desired background color
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.grey[300],
        borderWidth: "1px",
        BorderStyle: "solid",
      },
      "&:hover fieldset": {
        borderColor: theme.palette.grey[500],
        borderWidth: "1px",
        BorderStyle: "solid",
      },
      "&.Mui-focused fieldset": {
        borderWidth: "2px",
        borderColor: theme.palette.primary.main,
      },
    },
  }));

  const navigate = useNavigate();
  return (
    <Box className="main-container " pl={2}>
      <Grid xs={12} container className="page-header-save">
        <Grid xs={12} sm={12} md={12} item className="page-breadcrumbs">
          <CustomBreadcrumbs />
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
        }}
        onSubmit={async (values) => {
          console.log("values", values);
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} mb={3} className="group-container">
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Basic Information</Typography>
              </Grid>
              
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    Instructions to take medicine
                  </FormLabel>
                  <ReactQuill
                    className="react-quill-alignment"
                    theme="snow"
                    value={""}
                    onChange={(e) => { }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
              <LoadingButton
                color="primary"
                tabIndex={3}
                loadingPosition="start"
                startIcon={<></>}
                variant="contained"
                type="submit"
                className="primary-button"
                width="180px"
                name="submit"
                onClick={() => navigate("/master/medicine-instruction")}
              >
                SAVE
              </LoadingButton>
              <LoadingButton
                tabIndex={3}
                loadingPosition="start"
                startIcon={<></>}
                variant="outlined"
                type="submit"
                className="secondary-button"
                color="secondary"
                name="submit"
                onClick={() => navigate("/master/medicine-instruction")}
              >
                CANCEL
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Formik>
    </Box>
  );
}
