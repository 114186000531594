import React, { useEffect } from 'react';
import { SITE_TITLE } from '../services/common/const';
import { Grid, Typography } from '@mui/material';



// export default function Title(props: {pageTitle: string,}) {
export default function LinkTitle(props: any) {

    function pageLinkTitleName(page: any) {
        let pageName = "";

        switch (page) {
            case "master": {
                pageName = "Master";
                break;
            }
            case "": {
                break;
            }

        }
        return pageName;

    }
    return (
        <Typography variant='h4' color='primary'>{pageLinkTitleName(props.pageLinkTitle)}</Typography>
    );
}