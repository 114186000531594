import * as React from "react";
import { styled } from "@mui/system";
import { Tabs } from "@mui/base/Tabs";
import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import { TabPanel as BaseTabPanel } from "@mui/base/TabPanel";
import { buttonClasses } from "@mui/base/Button";
import { Tab as BaseTab, tabClasses } from "@mui/base/Tab";
import GeneralExamination from "./Tabs/GeneralExamination";
import RespiratorySystem from "./Tabs/RespiratorySystem";
import CardiovascularSystem from "./Tabs/CardiovascularSystem";
import PerAbdomen from "./Tabs/PerAbdomen";
import CentralNervousSystem from "./Tabs/CentralNervousSystem";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const Tab = styled(BaseTab)`
  color: black;
  cursor: pointer;
  font-size: 0.875rem;
  background-color: transparent;
  width: 100%;
  padding: 10px 12px;
  margin: 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: #fff;
  }

  &:focus {
    color: #fff;
  }

  &.${tabClasses.selected} {
    background-color: #2e67a3;
    color: #fff;
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabsList = styled(BaseTabsList)`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  padding: 0;
  margin: 0;
  margin-left: 40px; 
  padding-right: 40px; 
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  position: relative;
  flex-shrink: 0;
`;

const ScrollableTabsContainer = styled('div')`
  overflow: hidden;
  position: relative;
 background-color: #f1f5f8;
`;

const ScrollButton = styled('button')`
  background-color: #fff;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 40px;
  z-index: 1;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ScrollLeftButton = styled(ScrollButton)`
  left: 0;
`;

const ScrollRightButton = styled(ScrollButton)`
  right: 0;
`;

export default function History() {
  const tabsListRef = React.useRef(null);

  const handleScroll = (direction) => {
    if (tabsListRef.current) {
      tabsListRef.current.scrollBy({
        left: direction === "left" ? -100 : 100,
        behavior: "smooth",
      });
    }
  };

  return (
    <Tabs defaultValue={0}>
      <ScrollableTabsContainer style={{ marginBottom: "16px" }}>
        <ScrollLeftButton
          onClick={() => handleScroll("left")}
        >
          <ChevronLeftIcon fontSize="small" />
        </ScrollLeftButton>
        <TabsList ref={tabsListRef}>
          <Tab value={0}>General Examination</Tab>
          <Tab value={1}>Respiratory System</Tab>
          <Tab value={2}>Cardiovascular System</Tab>
          <Tab value={3}>Per Abdomen</Tab>
          <Tab value={4}>Central Nervous System</Tab>
        </TabsList>
        <ScrollRightButton
          onClick={() => handleScroll("right")}
        >
          <ChevronRightIcon fontSize="small" />
        </ScrollRightButton>
      </ScrollableTabsContainer>
      <BaseTabPanel value={0}>
      <GeneralExamination />
      </BaseTabPanel>
      <BaseTabPanel value={1}>
      <RespiratorySystem />
      </BaseTabPanel>
      <BaseTabPanel value={2}>
      <CardiovascularSystem />
      </BaseTabPanel>
      <BaseTabPanel value={3}>
      <PerAbdomen />
      </BaseTabPanel>
      <BaseTabPanel value={4}>
      <CentralNervousSystem />
      </BaseTabPanel>
    </Tabs>
  );
}
