import React from 'react'
import {
  Box, Grid, IconButton, InputAdornment, TextField, Button, styled, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
  Popover,
  Pagination,
  Stack,
} from '@mui/material'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate } from 'react-router-dom';
import '../../../theme/style.scss';
import BreadcrumbsComponent from '../../../components/BreadcrumbsComponent/BreadcrumbsComponent'
import CustomBreadcrumbs from '../../../components/BreadcrumbsComponent/CustomBreadcrumbs';

// import ClearIcon from "@mui/icons-material/Clear";
export default function BillingItemMaster() {
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl3] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const CustomTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: '#FAFBFD', // Set the desired background color
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.grey[300],
        borderWidth: '1px',
        BorderStyle: 'solid'
      },
      '&:hover fieldset': {
        borderColor: theme.palette.grey[500],
        borderWidth: '1px',
        BorderStyle: 'solid'
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: "2px",
      },
    },
  }));

  // for Edit & Delet pop 
  const handleOpenMore = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: any
  ) => {
    setAnchorEl3(event.currentTarget);
    // setBuyerId(id);
  };
  const handleCloseMore = () => {
    setAnchorEl3(null);
  };
  //  Table Header 

  function EnhancedTableHead(props: EnhancedTableProps) {
    return (
      <TableHead className='table-header-color'>
        <TableRow>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "10%"
            }}
            align="left"
          >
            Code
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "15%"
            }}
            align="left"
          >
            Item Name
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "none",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "15%"
            }}
            align="left"
          >
            Under
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "none",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "15%"
            }}
            align="left"
          >
            Department
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "none",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "15%"
            }}
            align="left"
          >
            Speciality
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "none",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "10%"
            }}
            align="left"
          >
            Amount
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "none",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "10%"
            }}
            align="left"
          >
            Discount
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "none",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "10%"
            }}
            align="left"
          >
            Tax
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "none",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "20%"
            }}
            align="left"
          >
            Net Amount
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "10%"
            }}
            align="left"
          >
            {''}
          </TableCell>

        </TableRow>
      </TableHead>
    );
  }

  const rowData = [
    {
      code: '001',
      itemName: 'Consultation Fee',
      under: 'General Medicine',
      department: 'OPD',
      speciality: 'General Physician',
      amount: 500,
      discount: 50,
      tax: 45,
      netAmount: 495
    },
    {
      code: '002',
      itemName: 'Blood Test',
      under: 'Pathology',
      department: 'OPD',
      speciality: 'Hematology',
      amount: 300,
      discount: 30,
      tax: 27,
      netAmount: 297
    },
    {
      code: '003',
      itemName: 'X-Ray',
      under: 'Radiology',
      department: 'OPD',
      speciality: 'Radiologist',
      amount: 700,
      discount: 70,
      tax: 63,
      netAmount: 693
    },
    {
      code: '004',
      itemName: 'ECG',
      under: 'Cardiology',
      department: 'OPD',
      speciality: 'Cardiologist',
      amount: 400,
      discount: 40,
      tax: 36,
      netAmount: 396
    },
    {
      code: '005',
      itemName: 'Physiotherapy Session',
      under: 'Rehabilitation',
      department: 'OPD',
      speciality: 'Physiotherapist',
      amount: 600,
      discount: 60,
      tax: 54,
      netAmount: 594
    }
  ];


  return (
    <>
      <Box className='main-container'>
        <Grid xs={12} container className='page-header'>
        <Grid xs={6} sm={6} item className="page-breadcrumbs">
          <CustomBreadcrumbs />
        </Grid>
          <Grid xs={6} sm={6} item className='page-header-icon'>
            <Button
              color="primary"
              variant="contained"
              onClick={() => navigate('billing-item-master-save')}
              size="medium"
              startIcon={<AddIcon />}
            >
              Add
            </Button></Grid>
        </Grid>
        <Grid container className="list-container" sx={{ padding: '16px' }}>
          <Grid item xs={12} sm={4} md={3} lg={2} className='search-bar' sx={{ gap: 2 }} >
            <CustomTextField
              placeholder="Search..."
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              sx={{ paddingLeft: '0px' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" >
                    <IconButton onClick={() => searchValue("")} >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                // endAdornment: value && (
                //   <IconButton
                //     aria-label="toggle password visibility"
                //     onClick={() => searchValue("")}
                //   >
                //     <ClearIcon />
                //   </IconButton>
                // ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} className='search-bar pdf-icon'>
            <img src="./xls.png" alt="" className='pdf-icon-hover' />
            <img src="./pdf.png" alt="" className='pdf-icon-hover' />
          </Grid>

          <TableContainer className='table'>
            <Table>
              <EnhancedTableHead />
              <TableBody>
                {rowData.map((item, i) => {
                  return (
                    <TableRow>
                      <TableCell>
                        <Link className='text-link' to="billing-item-master-save">
                          {item.code}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {item.itemName}
                      </TableCell>
                      <TableCell sx={{
                        display: {
                          xs: "none",
                          sm: "table-cell",
                          md: "table-cell",
                          lg: "table-cell",
                        },
                      }}>{item.under}</TableCell>
                      <TableCell sx={{
                        display: {
                          xs: "none",
                          sm: "table-cell",
                          md: "table-cell",
                          lg: "table-cell",
                        },
                      }}>{item.department}</TableCell>
                      <TableCell sx={{
                        display: {
                          xs: "none",
                          sm: "table-cell",
                          md: "table-cell",
                          lg: "table-cell",
                        },
                      }}>{item.speciality}</TableCell>
                      <TableCell sx={{
                        display: {
                          xs: "none",
                          sm: "table-cell",
                          md: "table-cell",
                          lg: "table-cell",
                        },
                      }}>{item.amount}</TableCell>
                      <TableCell sx={{
                        display: {
                          xs: "none",
                          sm: "table-cell",
                          md: "table-cell",
                          lg: "table-cell",
                        },
                      }}>{item.discount}</TableCell>
                      <TableCell sx={{
                        display: {
                          xs: "none",
                          sm: "table-cell",
                          md: "table-cell",
                          lg: "table-cell",
                        },
                      }}>{item.tax}</TableCell>
                      <TableCell sx={{
                        display: {
                          xs: "none",
                          sm: "table-cell",
                          md: "table-cell",
                          lg: "table-cell",
                        },
                      }}>{item.netAmount}</TableCell>
                      <TableCell align="right">
                        <Tooltip title="More" placement="top">
                          <IconButton
                            onClick={(e: any) =>
                              handleOpenMore(e)
                            }
                          >
                            <MoreVertRoundedIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid
            container
            paddingTop={1}
            className='pagination'
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid item xs={12} sm={6}
              className="pagination-label"
            >
              1 of 1 {/* {pageStartIndex} - {pageEndIndex} of {totalCount} */}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack
                component="div"
                className='pagination'
              >
                <Pagination
                  color="primary"
                // count={counData}
                // page={page_Index}
                // onChange={handleChangePageNew}
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Popover
        elevation={1}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseMore}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('billing-item-master-save')}>
              <ListItemText primary="Edit" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              className="delete-container"
            >
              Delete
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </>
  )
}
