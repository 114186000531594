import React, { useState } from "react";
import Title from '../../../components/Title';

import {
  Box, Grid, TextField, Button, styled, Table,
 
  FormLabel,
  FormControl,
  Paper,
  Typography,
  Divider,
  Toolbar,
  AppBar,
  Dialog,
  DialogActions,
  DialogContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material'
import Select from "react-select";
import { useNavigate } from 'react-router-dom';
// import '../../../theme/style.scss';
import { Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import CustomTimePicker from "../../../components/TimePicker/CustomTimePicker";
import { AsyncCustomSelect } from '../../../components/CustomFields/CustomSelect';
import DraggableList from '../../../components/DraggableTable/DraggableList';
import { reorder } from '../../../Helpers/helpers';
import { DropResult } from 'react-beautiful-dnd';
import MedicineInstructionSave from '../../../pages/Medicine/_component/MedicineInstructionSave';


export default function InvestigationMasterList() {
  const navigate = useNavigate()
  const [dialuentIsUsed, setDiluentIsUsed] = useState(false);
  const [morning, setMorning] = useState(false);
  const [afternoon, setAfternoon] = useState(false);
  const [evening, setEvening] = useState(false);
  const [night, setNight] = useState(false);

  const [anchorEl, setAnchorEl3] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const rowData = [
    {
      followUpReason: 'Regular follow up',

    },
    {
      followUpReason: 'Review reports',
    }
  ]

  const CustomTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: '#FAFBFD', // Set the desired background color
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.grey[300],
        borderWidth: '1px',
        BorderStyle: 'solid'
      },
      '&:hover fieldset': {
        borderColor: theme.palette.grey[500],
        borderWidth: '1px',
        BorderStyle: 'solid'
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: "2px",

      },
    },
  }));
  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(itemsOther, source.index, destination.index);

    setItemsOther(newItems);
  };
  const [itemsOther, setItemsOther] = React.useState([
    {
      id: "Item2 1",
      primary: "2Practical Rubber Computer1",
      secondary: "1Synergistic leading edge matrices"
    }, {
      id: "Item1 2",
      primary: "2Practical Rubber Computer2",
      secondary: "1Synergistic leading edge matrices"
    }, {
      id: "Item1 3",
      primary: "2Practical Rubber Computer3",
      secondary: "2Synergistic leading edge matrices"
    }
  ]);

 

  // for Edit & Delet pop 
  const handleOpenMore = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: any
  ) => {
    setAnchorEl3(event.currentTarget);
    // setBuyerId(id);
  };
  const handleCloseMore = () => {
    setAnchorEl3(null);
  };
  //  Table Header 
  //  Table Header 
  interface EnhancedTableProps {
    numSelected: number;
    //onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    //order: Order;
    orderBy: String;
    rowCount: number;
  }

  const [openDialog, setOpenDialog] = React.useState(false);
  const onHandleOpenDialog = (item: Item) => {

    handleClickOpen(true);
    console.log("Opening dialog for item:", item);
  };
  const handleClickOpen = (value: boolean) => {
    setOpenDialog(value);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    
    <Box className="main-container-popup" pl={2}>
      <Formik>
        <Grid container spacing={2} className="padding-add-Investigation">
          <Grid item xs={12} mb={2} className="group-container">
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Medicine Information</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl
                  sx={{
                    minWidth: "auto",
                  }}
                  fullWidth
                >
                  <FormLabel className="fileds-label-text">  Generic Name</FormLabel>
                  <AsyncCustomSelect
                    tabSelectsValue={false}
                    isNotCreatable={false}
                    isInnerWidth={true}
                    menuPortalTarget={document.querySelector("body")}
                    id={"followUpReason"}
                    isClearable={true}
                    FieldName={`followUpReason`}
                    
                    handleChange={(e) => {
                     
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Brand Name</FormLabel>
                  <CustomTextField
                    name="name"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    Medicine Group
                  </FormLabel>
                  <Select
                    name="organizationType"
                    
                    placeholder="Select"
                    styles={{
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: "0.813rem", // Set the desired placeholder font size here
                      }),
                      control: (base) => ({
                        ...base,
                        backgroundColor: "#FAFBFD",
                        border: "2px solid #cbcbda",
                        "&:hover": {
                          border: "2px solid #bdc1cd",
                        },
                        boxShadow: "none",
                      }),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl
                  sx={{
                    minWidth: "auto",
                  }}
                  fullWidth
                >
                  <FormLabel className="fileds-label-text">Used as</FormLabel>
                  <AsyncCustomSelect
                    tabSelectsValue={false}
                 
                    isNotCreatable={false}
                    isInnerWidth={true}
                   
                    menuPortalTarget={document.querySelector("body")}
                    id={"followUpReason"}
                    isClearable={true}
                    FieldName={`followUpReason`}
                    
                    handleChange={(e) => {
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl
                  sx={{
                    minWidth: "auto",
                  }}
                  fullWidth
                >
                  <FormLabel className="fileds-label-text">Formulation</FormLabel>
                  <AsyncCustomSelect
                    tabSelectsValue={false}
                    isNotCreatable={false}
                    isInnerWidth={true}
                    menuPortalTarget={document.querySelector("body")}
                    id={"followUpReason"}
                    isClearable={true}
                    FieldName={`followUpReason`}
                    handleChange={(e) => {
                    }}
                  />
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={6} /> */}

              <Grid item xs={12} sm={6} md={6}>
                <FormControl
                  sx={{
                    minWidth: "auto",
                  }}
                  fullWidth
                >
                  <FormLabel className="fileds-label-text">Strength</FormLabel>
                  <AsyncCustomSelect
                    tabSelectsValue={false}
                    isNotCreatable={false}
                    isInnerWidth={true}
                    menuPortalTarget={document.querySelector("body")}
                    id={"followUpReason"}
                    isClearable={true}
                    FieldName={`followUpReason`}
                    handleChange={(e) => {
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    Unit of Strength
                  </FormLabel>
                  <CustomTextField
                    name="name"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    Per Unit/Vol.
                  </FormLabel>
                  <CustomTextField
                    name="name"
                    placeholder=""
                    autoCapitalize="none"
                    type="number"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Unit/Volume</FormLabel>
                  <Select
                    name="organizationType"
                    placeholder="Select"
                    styles={{
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: "0.813rem",
                      }),
                      control: (base) => ({
                        ...base,
                        backgroundColor: "#FAFBFD",
                        border: "2px solid #cbcbda",
                        "&:hover": {
                          border: "2px solid #bdc1cd",
                        },
                        boxShadow: "none",
                      }),
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={12}>
                <FormControl
                  sx={{
                    minWidth: "auto",
                  }}
                  fullWidth
                >
                  <FormLabel className="fileds-label-text"> Company / Marketer Name</FormLabel>
                  <AsyncCustomSelect
                    tabSelectsValue={false}
                    isNotCreatable={false}
                    isInnerWidth={true}
                    menuPortalTarget={document.querySelector("body")}
                    id={"followUpReason"}
                    isClearable={true}
                    FieldName={`followUpReason`}
                    handleChange={(e) => {
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mb={2} className="group-container">
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Dosage Calculation</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        // defaultChecked
                        onChange={() => setDiluentIsUsed(!dialuentIsUsed)}
                      />
                    }
                    label="Diluent being used for dosage calculation"
                  />
                </FormGroup>
              </Grid>

              {dialuentIsUsed && (
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl
                    sx={{
                      minWidth: "auto",
                    }}
                    fullWidth
                  >
                    <FormLabel className="fileds-label-text">  Name of Diluent</FormLabel>
                    <AsyncCustomSelect
                      tabSelectsValue={false}
                      isNotCreatable={false}
                      isInnerWidth={true}
                      menuPortalTarget={document.querySelector("body")}
                      id={"followUpReason"}
                      isClearable={true}
                      FieldName={`followUpReason`}
                      handleChange={(e) => {
                      }}
                    />
                  </FormControl>
                </Grid>
              )}
              {dialuentIsUsed && (<Grid item xs={12} sm={6} md={9} />)}
              {dialuentIsUsed && (
                <Grid item xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <FormLabel className="fileds-label-text">
                      Vol. of Diluent
                    </FormLabel>
                    <CustomTextField
                      name="code"
                      placeholder=""
                      autoCapitalize="none"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </FormControl>
                </Grid>
              )}
              {dialuentIsUsed && (
                <Grid item xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <FormLabel className="fileds-label-text">
                      Unit of Diluent Volume
                    </FormLabel>
                    <Select
                      name="organizationType"
                      placeholder="Select"
                      styles={{
                        placeholder: (provided) => ({
                          ...provided,
                          fontSize: "0.813rem", // Set the desired placeholder font size here
                        }),
                        control: (base) => ({
                          ...base,
                          backgroundColor: "#FAFBFD",
                          border: "2px solid #cbcbda",
                          "&:hover": {
                            border: "2px solid #bdc1cd",
                          },
                          boxShadow: "none",
                        }),
                      }}
                    />
                  </FormControl>
                </Grid>
              )}
              {dialuentIsUsed && <Grid item xs={12} sm={6} md={9} />}

              {dialuentIsUsed && (
                <Grid item xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <FormLabel className="fileds-label-text">
                      Concentration
                    </FormLabel>
                    <CustomTextField
                      name="code"
                      placeholder=""
                      autoCapitalize="none"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </FormControl>
                </Grid>
              )}
              {dialuentIsUsed && (
                <Grid item xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <FormLabel className="fileds-label-text">
                      Unit of Concentration
                    </FormLabel>
                    <Select
                      name="organizationType"
                      // options={options}
                      // value={selectedOption}
                      // onChange={handleSelectChange}
                      placeholder="Select"
                      styles={{
                        placeholder: (provided) => ({
                          ...provided,
                          fontSize: "0.813rem", // Set the desired placeholder font size here
                        }),
                        control: (base) => ({
                          ...base,
                          backgroundColor: "#FAFBFD",
                          border: "2px solid #cbcbda",
                          "&:hover": {
                            border: "2px solid #bdc1cd",
                          },
                          boxShadow: "none",
                        }),
                      }}
                    />
                  </FormControl>
                </Grid>
              )}
              {dialuentIsUsed}

              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    Dose
                    {/* of the Drug */}
                  </FormLabel>
                  <CustomTextField
                    name="code"
                    placeholder=""
                    autoCapitalize="none"
                    type="number"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    Unit of the Dose
                  </FormLabel>
                  <Select
                    name="organizationType"
                    placeholder="Select"
                    styles={{
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: "0.813rem", // Set the desired placeholder font size here
                      }),
                      control: (base) => ({
                        ...base,
                        backgroundColor: "#FAFBFD",
                        border: "2px solid #cbcbda",
                        "&:hover": {
                          border: "2px solid #bdc1cd",
                        },
                        boxShadow: "none",
                      }),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    Frequency
                    {/* of medicine */}
                  </FormLabel>
                  <Select
                    name="organizationType"
                    placeholder="Select"
                    styles={{
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: "0.813rem", // Set the desired placeholder font size here
                      }),
                      control: (base) => ({
                        ...base,
                        backgroundColor: "#FAFBFD",
                        border: "2px solid #cbcbda",
                        "&:hover": {
                          border: "2px solid #bdc1cd",
                        },
                        boxShadow: "none",
                      }),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    Unit of Frequency
                  </FormLabel>
                  <Select
                    name="organizationType"
                    placeholder="Select"
                    styles={{
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: "0.813rem", // Set the desired placeholder font size here
                      }),
                      control: (base) => ({
                        ...base,
                        backgroundColor: "#FAFBFD",
                        border: "2px solid #cbcbda",
                        "&:hover": {
                          border: "2px solid #bdc1cd",
                        },
                        boxShadow: "none",
                      }),
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    Duration
                    {/* of Medicine */}
                  </FormLabel>
                  <Select
                    name="organizationType"
                    placeholder="Select"
                    styles={{
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: "0.813rem", // Set the desired placeholder font size here
                      }),
                      control: (base) => ({
                        ...base,
                        backgroundColor: "#FAFBFD",
                        border: "2px solid #cbcbda",
                        "&:hover": {
                          border: "2px solid #bdc1cd",
                        },
                        boxShadow: "none",
                      }),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    Unit of Duration
                  </FormLabel>
                  <Select
                    name="organizationType"
                    // options={options}
                    // value={selectedOption}
                    // onChange={handleSelectChange}
                    placeholder="Select"
                    styles={{
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: "0.813rem", // Set the desired placeholder font size here
                      }),
                      control: (base) => ({
                        ...base,
                        backgroundColor: "#FAFBFD",
                        border: "2px solid #cbcbda",
                        "&:hover": {
                          border: "2px solid #bdc1cd",
                        },
                        boxShadow: "none",
                      }),
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={12}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    Print Unit of Drug
                  </FormLabel>
                  <Select
                    name="organizationType"
                    // options={options}
                    // value={selectedOption}
                    // onChange={handleSelectChange}
                    placeholder="Select"
                    styles={{
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: "0.813rem", // Set the desired placeholder font size here
                      }),
                      control: (base) => ({
                        ...base,
                        backgroundColor: "#FAFBFD",
                        border: "2px solid #cbcbda",
                        "&:hover": {
                          border: "2px solid #bdc1cd",
                        },
                        boxShadow: "none",
                      }),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    Route of Administration
                  </FormLabel>
                  <Select
                    name="organizationType"
                    // options={options}
                    // value={selectedOption}
                    // onChange={handleSelectChange}
                    placeholder="Select"
                    styles={{
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: "0.813rem", // Set the desired placeholder font size here
                      }),
                      control: (base) => ({
                        ...base,
                        backgroundColor: "#FAFBFD",
                        border: "2px solid #cbcbda",
                        "&:hover": {
                          border: "2px solid #bdc1cd",
                        },
                        boxShadow: "none",
                      }),
                    }}
                  />
                </FormControl>
              </Grid>
                         </Grid>
          </Grid>
          <Grid item xs={12} mb={2} className="group-container">
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Medicine Schedule</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl
                  sx={{
                    minWidth: "auto",
                  }}
                  fullWidth
                >
                  <FormLabel className="fileds-label-text"> Relation to food</FormLabel>
                  <AsyncCustomSelect
                    tabSelectsValue={false}
                    // openMenuOnFocus={
                    //   saleId <= 0
                    // }
                    isNotCreatable={false}
                    isInnerWidth={true}
                    // optionInnerWidth={
                    //   sourceProductCollection.length <
                    //     1
                    //     ? "837px !important"
                    //     : ""
                    // }
                    menuPortalTarget={document.querySelector("body")}
                    id={"followUpReason"}
                    isClearable={true}
                    FieldName={`followUpReason`}
                    // onFocus={(e) => {
                    // }}
                    // options={ }
                    // defaultOptions={ }
                    // loadOptions={
                    //   loadProductOptions
                    // }
                    // onCreateOption={(value) => {  }}
                    // autoFocus={ }
                    handleChange={(e) => { }}
                  // Value={ }
                  // CustomOptionComponent={  }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl
                  sx={{
                    minWidth: "auto",
                  }}
                  fullWidth
                >
                  <FormLabel className="fileds-label-text"> Relation to sleep</FormLabel>
                  <AsyncCustomSelect
                    tabSelectsValue={false}
                    // openMenuOnFocus={
                    //   saleId <= 0
                    // }
                    isNotCreatable={false}
                    isInnerWidth={true}
                    // optionInnerWidth={
                    //   sourceProductCollection.length <
                    //     1
                    //     ? "837px !important"
                    //     : ""
                    // }
                    menuPortalTarget={document.querySelector("body")}
                    id={"followUpReason"}
                    isClearable={true}
                    FieldName={`followUpReason`}
                    handleChange={(e) => {
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    {" "}
                    Time Gap with food
                  </FormLabel>
                  <CustomTextField
                    name="branchName"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl
                  sx={{
                    minWidth: "auto",
                  }}
                  fullWidth
                >
                  <FormLabel className="fileds-label-text">  Unit of time gap</FormLabel>
                  <AsyncCustomSelect
                    tabSelectsValue={false}
                    // openMenuOnFocus={
                    //   saleId <= 0
                    // }
                    isNotCreatable={false}
                    isInnerWidth={true}
                    // optionInnerWidth={
                    //   sourceProductCollection.length <
                    //     1
                    //     ? "837px !important"
                    //     : ""
                    // }
                    menuPortalTarget={document.querySelector("body")}
                    id={"followUpReason"}
                    isClearable={true}
                    FieldName={`followUpReason`}
                    handleChange={(e) => {
                    }}
                  // Value={ }
                  // CustomOptionComponent={  }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        // defaultChecked
                        onChange={() => setMorning(!morning)}
                      />
                    }
                    label="Morning"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        // defaultChecked
                        onChange={() => setAfternoon(!afternoon)}
                      />
                    }
                    label="Afternoon"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        // defaultChecked
                        onChange={() => setEvening(!evening)}
                      />
                    }
                    label="Evening"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        // defaultChecked
                        onChange={() => setNight(!night)}
                      />
                    }
                    label="Night"
                  />
                </FormGroup>
              </Grid>
              {!morning && <Grid item xs={12} sm={6} md={6} />}
              {morning && <Grid item xs={12} sm={6} md={6}>
                <CustomTimePicker />
              </Grid>}
              {!afternoon && <Grid item xs={12} sm={6} md={6} />}
              {afternoon && <Grid item xs={12} sm={6} md={6} >
                <CustomTimePicker />
              </Grid>}
              {!evening && <Grid item xs={12} sm={6} md={6} />}
              {evening && <Grid item xs={12} sm={6} md={6} >
                <CustomTimePicker />
              </Grid>}
              {!night && <Grid item xs={12} sm={6} md={6} />}
              {night && <Grid item xs={12} sm={6} md={6}>
                <CustomTimePicker />
              </Grid>}
            </Grid>
          </Grid>
          <Grid item xs={12} mb={2} className="group-container">
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Instruction(s)</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Box
                  className="template-table-container"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    border: "1px solid #0000001f",
                    borderRadius: 4,
                  }}
                >
                  <Box className="inner-title-container">
                    <Box className="template-box">
                      <Typography>Medicine Instruction(s)</Typography>
                    </Box>
                    <DraggableList items={itemsOther} onDragEnd={onDragEnd} onHandleOpenDialog={onHandleOpenDialog} />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Formik>
      <Dialog
        className="dialog1"
        open={openDialog}
        onClose={handleClose}
        fullScreen
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "1200px", // Adjust the width as needed
            maxWidth: "none",
          },
        }}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography
              // className={selected ? "symptom-text" : ""}
              style={{ fontWeight: 600, marginLeft: 0 }}
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
            >
              Medicine Edit
            </Typography>


          </Toolbar>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </AppBar>
        <DialogContent className="padding-remove-Investigation-Edit  main-dialog-background-color">
          <MedicineInstructionSave
            onHandleOpenDialog={onHandleOpenDialog}
            handleClose1={handleClose}
          />
        </DialogContent>
        <Divider />

        <DialogActions className="padding-add-investigation-templete-save-button">
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
              <LoadingButton
                color="primary"
                tabIndex={3}
                loadingPosition="start"
                startIcon={<></>}
                variant="contained"
                type="submit"
                onClick={handleClose}
                className="primary-button"
                name="submit"
              >
                Save
              </LoadingButton>
              <LoadingButton
                tabIndex={3}
                loadingPosition="start"
                startIcon={<></>}
                variant="outlined"
                type="submit"
                name="submit"
                className="secondary-button"
                color="secondary"
                onClick={handleClose}                >
                Cancel
              </LoadingButton>
            </Box>
          </Grid>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
