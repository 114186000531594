import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../theme/style.scss';
import { Box, FormControl, FormLabel, Grid, TextField, styled } from '@mui/material';
import Title from '../../components/Title';
import LoadingButton from '@mui/lab/LoadingButton';
import DatePickerCompponent from '../../components/CustomFields/DatePicker';
import CustomBreadcrumbs from '../../components/BreadcrumbsComponent/CustomBreadcrumbs';


const CustomTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: '#FAFBFD', // Set the desired background color
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.grey[300],
            borderWidth: '1px',
            BorderStyle: 'solid'
        },
        '&:hover fieldset': {
            borderColor: theme.palette.grey[500],
            borderWidth: '1px',
            BorderStyle: 'solid'
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.main,
            borderWidth: '2px',
        },
    },
}));



export default function DepartmentSave() {
    const navigate = useNavigate()

    return (
        <Box className="main-container " pl={2}>
            <Grid xs={12} container className='page-header-save'>
                <Grid xs={12} sm={12} md={12} item className="page-breadcrumbs">
                    <CustomBreadcrumbs />
                </Grid>
            </Grid>
            <Grid container spacing={2}  >
                <Grid item xs={12} mb={2} className="group-container">
                    <Grid container item spacing={2} >
                        <Grid item xs={12} sm={6} md={3}>
                            <FormControl fullWidth>
                                <FormLabel className='fileds-label-text'>Code</FormLabel>
                                <CustomTextField
                                    name="code"
                                    placeholder=""
                                    autoCapitalize='none'
                                    type="text"
                                    fullWidth
                                    size="small"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item sm={6} md={6}></Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <FormControl fullWidth>
                                <FormLabel className='fileds-label-text'>Department Name</FormLabel>
                                <CustomTextField
                                    name="code"
                                    placeholder=""
                                    autoCapitalize='none'
                                    type="text"
                                    fullWidth
                                    size="small"
                                />
                            </FormControl>
                        </Grid>
                        
                        <Grid item xs={12} sm={6} md={6}>
                            <FormControl fullWidth>
                                <FormLabel className='fileds-label-text '>Department Head</FormLabel>
                                <CustomTextField
                                    name="departmentHead"
                                    placeholder=""
                                    autoCapitalize='none'
                                    type="text"
                                    fullWidth
                                    size="small"
                                />
                            </FormControl>
                        </Grid>
                        
                        <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                                <FormLabel className='fileds-label-text'>Description</FormLabel>
                                <CustomTextField
                                    placeholder=""
                                    multiline
                                    rows={4}
                                    maxRows={4}
                                />

                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'end', gap: 2 }}>
                        <LoadingButton
                            color="primary"
                            tabIndex={3}
                            loadingPosition="start"
                            startIcon={<></>}
                            variant="contained"
                            type="submit"
                            className='submit-btn'
                            name="submit">
                            SAVE
                        </LoadingButton>
                        <LoadingButton
                            color="primary"
                            tabIndex={3}
                            loadingPosition="start"
                            startIcon={<></>}
                            variant="outlined"
                            type="submit"
                            className='submit-btn'
                            name="submit"
                            onClick={() => navigate('/settings/department')}
                        >
                            CANCEL
                        </LoadingButton>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}