import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../theme/style.scss";
import {
    Box,
    FormControl,
    FormLabel,
    Grid,
    TextField,
    Typography,
    styled,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Title from "../../../components/Title";
import { CustomSelect } from "../../../components/CustomFields/CustomSelect";
import { Formik } from "formik";
import PhoneInputWithCountrySelect from "react-phone-number-input";

const CustomTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: "#FAFBFD", // Set the desired background color
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: theme.palette.grey[300],
            borderWidth: "1px",
            BorderStyle: "solid",
        },
        "&:hover fieldset": {
            borderColor: theme.palette.grey[500],
            borderWidth: "1px",
            BorderStyle: "solid",
        },
        "&.Mui-focused fieldset": {
            borderWidth: "2px",
            borderColor: theme.palette.primary.main,
        },
    },
}));

interface MainProps {
    isEdit: boolean,
    setIsEdit: boolean
}

export default function SecuritySetting(props: MainProps) {
    const { isEdit, setIsEdit } = props;
    const [value, setValue] = useState('');
    const navigate = useNavigate();

    return (
        <Box className="main-container  profile-contianer"  >
            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                }}
                onSubmit={async (values) => {
                    console.log("values", values)
                }}
            >
                <Grid container spacing={2}>
                    {!isEdit &&
                        <Grid item xs={12} mb={3} className="group-container">
                            <Grid container item spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">Security</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={12}>
                                    <Typography>Email</Typography>
                                    <span style={{ fontWeight: 600, fontSize: '14px' }}>ristinagroves@example.com</span>
                                </Grid>
                                <Grid item xs={12} sm={6} md={12}>
                                    <Typography>Primary Number </Typography>
                                    <span style={{ fontWeight: 600, fontSize: '14px' }}>+919876543200</span>
                                </Grid>
                            </Grid>
                        </Grid>}
                    {isEdit && <Grid item xs={12} mb={3} className="group-container">
                        <Grid container item spacing={2}>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text">Email</FormLabel>
                                    <CustomTextField
                                        name="laboratoryName"
                                        placeholder=""
                                        autoCapitalize="none"
                                        type="text"
                                        fullWidth
                                        size="small"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <div className="input-group">
                                    <label htmlFor="phone">Primary Number</label>
                                    <PhoneInputWithCountrySelect
                                        id="phone"
                                        international
                                        countryCallingCodeEditable={false}
                                        defaultCountry="IN"
                                        value={value}
                                        onChange={setValue}
                                        placeholder="Enter phone number"
                                        className="phone-input"
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>}
                    {isEdit && <Grid item xs={12}>
                        <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
                            <LoadingButton
                                color="primary"
                                tabIndex={3}
                                loadingPosition="start"
                                startIcon={<></>}
                                variant="contained"
                                type="submit"
                                className="primary-button"
                                // onClick={() => navigate("/master/laboratory-master")}
                                name="submit"
                            >
                                Save
                            </LoadingButton>
                            <LoadingButton
                                tabIndex={3}
                                loadingPosition="start"
                                startIcon={<></>}
                                variant="outlined"
                                type="submit"
                                name="submit"
                                className="secondary-button"
                                color="secondary"
                                onClick={() => setIsEdit(false)}
                            >
                                Cancel
                            </LoadingButton>
                        </Box>
                    </Grid>}
                </Grid>

            </Formik>
        </Box>
    );
}
