import React, { useState } from "react";
import Title from "../../../components/Title";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
  Popover,
  Pagination,
  Stack,
  FormControl,
  Alert,
  Tabs,
  Tab,
  Typography,
  Divider,
  MenuItem,
} from "@mui/material";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import "../../../theme/style.scss";
import DateRangeIcon from "@mui/icons-material/DateRange";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { CustomSelect } from "../../../components/CustomFields/CustomSelect";
import { TransitionProps } from "@mui/material/transitions";
import OPDClinicalDetail from "./OPDClinicalDetail";
import Menu, { MenuProps } from "@mui/material/Menu";
import { styled, alpha } from "@mui/material/styles";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";
import { DateRange } from "react-date-range";
import { format } from "date-fns";
import CustomBreadcrumbs from "../../../components/BreadcrumbsComponent/CustomBreadcrumbs";
import { Formik } from "formik";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function OPDClinical() {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [selectedDateRange, setDateRange] = useState<any>([{}]);
  const popupState = usePopupState({ variant: "popover", popupId: "demoMenu" });
  const [isCustomDate, setIsCustomDate] = React.useState(false);
  const [dateHeading, setDateHeading] = React.useState<any>("Last 30 days");
  const [selectedIndex, setSelectedIndex] = React.useState(3);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function TabProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const [anchorEl, setAnchorEl3] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);

  const CustomTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: "#FAFBFD", // Set the desired background color
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.grey[300],
        borderWidth: "1px",
        BorderStyle: "solid",
      },
      "&:hover fieldset": {
        borderColor: theme.palette.grey[500],
        borderWidth: "1px",
        BorderStyle: "solid",
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
        borderWidth: "2px",
      },
    },
  }));

  // for Edit & Delet pop
  const handleOpenMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl3(event.currentTarget);
    // setBuyerId(id);
  };
  const handleCloseMore = () => {
    setAnchorEl3(null);
  };

  const rowData = [
    {
      patientWaitingNumber: "01",
      patientNumber: "P001",
      status: "Completed",
      patientName: "Alice Johnson",
      age: "38",
      gender: "F",
      mobileNo: "555-123-4567",
      followUpVisitReason: "Routine Checkup",
      referralDoctorName: "Dr. Marry Smith",
      address: "123 Main St, Springfield",
      symptoms: "Essential hypertension, Diabetes mellitus, Osteoarthritis",
      patientStatus: "New",
    },
    {
      patientWaitingNumber: "02",
      patientNumber: "P002",
      status: "Active",
      patientName: "John Doe",
      age: "31",
      gender: "M",
      mobileNo: "555-987-6543",
      followUpVisitReason: "Consultation",
      referralDoctorName: "Dr. Red Brown",
      address: "456 Elm St, Springfield",
      symptoms: "Cough, sore throat",
      patientStatus: "Revisit",
    },
    {
      patientWaitingNumber: "03",
      patientNumber: "P003",
      status: "Waiting",
      patientName: "Emily Davis",
      age: "24",
      gender: "F",
      mobileNo: "555-654-3210",
      followUpVisitReason: "Follow-up",
      referralDoctorName: "Dr. Black White",
      address: "789 Oak St, Springfield",
      symptoms: "Back pain, nausea",
      patientStatus: "Revisit",
    },
    {
      patientWaitingNumber: "04",
      patientNumber: "P004",
      status: "Waiting",
      patientName: "Michael Brown",
      age: "45",
      gender: "O",
      mobileNo: "555-321-0987",
      followUpVisitReason: "Annual Physical",
      referralDoctorName: "Dr. Green Yellow",
      address: "321 Pine St, Springfield",
      symptoms: "No current symptoms",
      patientStatus: "New",
    },
    {
      patientWaitingNumber: "05",
      patientNumber: "P005",
      status: "Waiting",
      patientName: "Linda Williams",
      age: "59",
      gender: "F",
      mobileNo: "555-213-5467",
      followUpVisitReason: "Specialist Referral",
      referralDoctorName: "Dr. Pink Black",
      address: "654 Birch St, Springfield",
      symptoms: "Joint pain, swelling",
      patientStatus: "Revisit",
    },
  ];

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: "10px !important",
      marginTop: theme.spacing(1),
      minWidth: 250,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px !important",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  const handleDateRange = (selected: any, popupState) => {
    // Your logic here
    const calenderHeading =
      format(new Date(selected[0].startDate), "dd-MM-yyyy") +
      " To " +
      format(new Date(selected[0].endDate), "dd-MM-yyyy");
    setDateRange(selected);
    setDateHeading(calenderHeading);
    popupState.close();
  };

  const handleListItemClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    setSelectedIndex(index);
    switch (
    index
    // case 0:
    //   setDateRange([{
    //     startDate: (userContext.financialYear !== getCurrentFinancialYear() ? new Date(userContext.financialYearEndDate) : new Date()),
    //     endDate: (userContext.financialYear !== getCurrentFinancialYear() ? new Date(userContext.financialYearEndDate) : new Date()),
    //     key: "selection",
    //   },])
    //   setDateHeading("Today")
    //   break;
    // case 1:
    //   setDateRange([{
    //     startDate: (userContext.financialYear !== getCurrentFinancialYear() ? new Date(userContext.financialYearEndDate).getTime() - (1 * 24 * 60 * 60 * 1000) : new Date().getTime() - (1 * 24 * 60 * 60 * 1000)),
    //     endDate: (userContext.financialYear !== getCurrentFinancialYear() ? new Date(userContext.financialYearEndDate).getTime() - (1 * 24 * 60 * 60 * 1000) : new Date().getTime() - (1 * 24 * 60 * 60 * 1000)),
    //     key: "selection",
    //   },])
    //   setDateHeading("Yestarday")
    //   break;
    // case 2:
    //   setDateRange([{
    //     startDate: (userContext.financialYear !== getCurrentFinancialYear() ? new Date(userContext.financialYearEndDate).getTime() - (14 * 24 * 60 * 60 * 1000) : new Date().getTime() - (14 * 24 * 60 * 60 * 1000)),
    //     endDate: (userContext.financialYear !== getCurrentFinancialYear() ? new Date(userContext.financialYearEndDate) : new Date()),
    //     key: "selection",
    //   },])
    //   setDateHeading("Last 15 days")
    //   break;
    // case 3:
    //   setDateRange([{
    //     startDate: (userContext.financialYear !== getCurrentFinancialYear() ? new Date(userContext.financialYearEndDate).getTime() - (29 * 24 * 60 * 60 * 1000) : new Date().getTime() - (29 * 24 * 60 * 60 * 1000)),
    //     endDate: (userContext.financialYear !== getCurrentFinancialYear() ? new Date(userContext.financialYearEndDate) : new Date()),
    //     key: "selection",
    //   },])
    //   setDateHeading("Last 30 days")
    //   break;
    // case 4:
    //   setDateRange([{
    //     startDate: (userContext.financialYear !== getCurrentFinancialYear() ? new Date(userContext.financialYearEndDate).getTime() - (89 * 24 * 60 * 60 * 1000) : new Date().getTime() - (89 * 24 * 60 * 60 * 1000)),
    //     endDate: (userContext.financialYear !== getCurrentFinancialYear() ? new Date(userContext.financialYearEndDate) : new Date()),
    //     key: "selection",
    //   },])
    //   setDateHeading("Last 90 days")
    //   break;
    // case 5:
    //   setDateRange([{
    //     startDate: new Date(userContext.financialYearStartDate),
    //     endDate: new Date(userContext.financialYearEndDate),
    //     key: "selection",
    //   },])
    //   setDateHeading("Current financial year")
    //   break;
    // case 6:
    //   // setDateHeading(`${formatDate(new Date(selectedDateRange[0].startDate))} To ${formatDate(new Date(selectedDateRange[0].endDate))}`)
    //   setIsCustomDate(true);
    //   break;
    // default:
    //   break;
    ) {
    }
  };
  return (
    <>
      <Formik>
        <Box className="main-container">
          <Grid xs={12} container className="page-header">
            <Grid xs={6} sm={6} item className="page-breadcrumbs">
              <CustomBreadcrumbs />
            </Grid>
            <Grid xs={6} sm={6} item className="page-header-icon">
              <Button
                color="primary"
                variant="contained"
                onClick={() => handleClickOpen()}
                size="small"
                startIcon={<AddIcon />}
              >
                Add
              </Button>
            </Grid>
          </Grid>

          <Grid container className="list-container " sx={{ padding: '12px' }}>
            <Grid item xs={12} sm={6} md={3} lg={2} className='search-bar'   >
              <CustomTextField
                placeholder="Search..."
                type="text"
                variant="outlined"
                size="small"
                fullWidth
                sx={{ paddingLeft: '0px' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" >
                      <IconButton onClick={() => searchValue("")} >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2} style={{ paddingLeft: '10px' }}>
              <FormControl fullWidth>
                <CustomSelect
                  placeholder="Doctor"
                  isNotCreatable={true}
                  id={"country"}
                  FieldName={"country"}
                  //options={countryData}
                  openMenuOnFocus
                  isClearable
                  isSearchable
                  menuPortalTarget={document.querySelector("body")}
                  handleChange={(e) => {
                    console.log("event:", e)
                  }}
                  Value=""
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}

            >
              <div className='days-filter' style={{ display: "flex", alignItems: 'center' }}>
                <Button
                  className="button-responsive-calender"
                  variant="outlined"
                  fullWidth
                  endIcon={<DateRangeIcon />}
                  // style={{ paddingBottom: 5 }}
                  {...bindTrigger(popupState)}
                >
                  {dateHeading.toString()}
                </Button>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  {...bindMenu(popupState)}
                >
                  {!isCustomDate && (
                    <>
                      <Grid className="dashboard-date-label">
                        <Typography className="typography-first-child">
                          {dateHeading.toString()}
                        </Typography>
                      </Grid>
                      <Divider sx={{ margin: "0px !important" }} />
                      <MenuItem
                        sx={{ padding: "0px !important" }}
                        onClick={popupState.close}
                      >
                        <ListItemButton
                          selected={selectedIndex === 0}
                          sx={{
                            "&.Mui-selected": {
                              backgroundColor: "#2e67a3",
                              color: "#ffffff",
                            },
                            "&.Mui-focusVisible": {
                              backgroundColor: "#f1f6fe !important",
                            },
                            ":hover": {
                              backgroundColor: "#f1f6fe",
                            },
                          }}
                          onClick={(event: any) => handleListItemClick(event, 0)}
                        >
                          <span>Today</span>
                        </ListItemButton>
                      </MenuItem>
                      <MenuItem
                        sx={{ padding: "0px !important" }}
                        onClick={popupState.close}
                      >
                        <ListItemButton
                          selected={selectedIndex === 1}
                          sx={{
                            "&.Mui-selected": {
                              backgroundColor: "#2e67a3",
                              color: "#ffffff",
                            },
                            "&.Mui-focusVisible": {
                              backgroundColor: "#f1f6fe !important",
                            },
                            ":hover": {
                              backgroundColor: "#f1f6fe",
                            },
                          }}
                          onClick={(event: any) => handleListItemClick(event, 1)}
                        >
                          <span>Yestarday</span>
                        </ListItemButton>
                      </MenuItem>
                      <MenuItem
                        sx={{ padding: "0px !important" }}
                        onClick={popupState.close}
                      >
                        <ListItemButton
                          selected={selectedIndex === 2}
                          sx={{
                            "&.Mui-selected": {
                              backgroundColor: "#2e67a3",
                              color: "#ffffff",
                            },
                            "&.Mui-focusVisible": {
                              backgroundColor: "#f1f6fe !important",
                            },
                            ":hover": {
                              backgroundColor: "#f1f6fe",
                            },
                          }}
                          onClick={(event: any) => handleListItemClick(event, 2)}
                        >
                          <span>Last 15 days</span>
                        </ListItemButton>
                      </MenuItem>
                      <MenuItem
                        sx={{ padding: "0px !important" }}
                        onClick={popupState.close}
                      >
                        <ListItemButton
                          selected={selectedIndex === 3}
                          sx={{
                            "&.Mui-selected": {
                              backgroundColor: "#2e67a3",
                              color: "#ffffff",
                            },
                            "&.Mui-focusVisible": {
                              backgroundColor: "#f1f6fe !important",
                            },
                            ":hover": {
                              backgroundColor: "#f1f6fe",
                            },
                          }}
                          onClick={(event: any) => handleListItemClick(event, 3)}
                        >
                          <span>Last 30 days</span>
                        </ListItemButton>
                      </MenuItem>

                      <MenuItem
                        sx={{ padding: "0px !important" }}
                        onClick={popupState.close}
                      >
                        <ListItemButton
                          selected={selectedIndex === 4}
                          sx={{
                            "&.Mui-selected": {
                              backgroundColor: "#2e67a3",
                              color: "#ffffff",
                            },
                            "&.Mui-focusVisible": {
                              backgroundColor: "#f1f6fe !important",
                            },
                            ":hover": {
                              backgroundColor: "#f1f6fe",
                            },
                          }}
                          onClick={(event: any) => handleListItemClick(event, 4)}
                        >
                          <span>Last 90 days</span>
                        </ListItemButton>
                      </MenuItem>

                      <MenuItem
                        sx={{ padding: "0px !important" }}
                        onClick={popupState.close}
                      >
                        <ListItemButton
                          selected={selectedIndex === 5}
                          sx={{
                            "&.Mui-selected": {
                              backgroundColor: "#2e67a3",
                              color: "#ffffff",
                            },
                            "&.Mui-focusVisible": {
                              backgroundColor: "#f1f6fe !important",
                            },
                            ":hover": {
                              backgroundColor: "#f1f6fe",
                            },
                          }}
                          onClick={(event: any) => handleListItemClick(event, 5)}
                        >
                          <span>Current financial year</span>
                        </ListItemButton>
                      </MenuItem>
                      <Divider sx={{ margin: "0px !important" }} />
                      <MenuItem sx={{ padding: "0px !important" }}>
                        <ListItemButton
                          selected={selectedIndex === 6}
                          sx={{
                            "&.Mui-selected": {
                              backgroundColor: "#2e67a3",
                              color: "#ffffff",
                            },
                            "&.Mui-focusVisible": {
                              backgroundColor: "#f1f6fe !important",
                            },
                            ":hover": {
                              backgroundColor: "#f1f6fe",
                            },
                          }}
                          onClick={(event: any) => handleListItemClick(event, 6)}
                        >
                          <span>Custom...</span>
                        </ListItemButton>
                      </MenuItem>
                    </>
                  )}
                  {isCustomDate && (
                    <DateRange
                      editableDateInputs={true}
                      onChange={
                        (item) => handleDateRange([item.selection], popupState)
                        // console.log(item)
                      }
                      // minDate={new Date(userContext.financialYearStartDate)}
                      // maxDate={new Date(userContext.financialYearEndDate)}
                      moveRangeOnFirstSelection={false}
                      ranges={selectedDateRange}
                    />
                  )}
                </StyledMenu>
              </div>
            </Grid>

            
            <Grid item xs={12} sm={6} md={3} lg={6} className='search-bar pdf-icon' style={{ paddingTop: '10px' }}>
              <img src="./xls.png" alt="" className='pdf-icon-hover' />
              <img src="./pdf.png" alt="" className='pdf-icon-hover' />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box
                sx={{ borderBottom: 1, borderColor: "divider", paddingTop: 2 }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  indicatorColor="primary"
                  aria-label="Sale Invoice"
                  TabIndicatorProps={{ sx: { backgroundColor: " #2e67a3" } }}
                  sx={{
                    "& button:hover": {
                      backgroundColor: "#f1f6fe",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    },
                  }}
                >

                  <Tab
                    sx={{ textTransform: "capitalize !important" }}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <span>Active</span>
                        <Box
                          sx={{ backgroundColor: "#fdeded" }}
                          className="inner-tabs"
                        >
                          1
                        </Box>
                      </Box>
                    }
                    {...TabProps(1)}
                  />
                  <Tab
                    sx={{ textTransform: "capitalize !important" }}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <span>Waiting</span>
                        <Box
                          sx={{ backgroundColor: "#e5f6fd" }}
                          className="inner-tabs"
                        >
                          3
                        </Box>
                      </Box>
                    }
                    {...TabProps(3)}
                  />
                  <Tab
                    sx={{ textTransform: "capitalize !important" }}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <span>Schedule</span>
                        <Box
                          sx={{ backgroundColor: "#e5f6fd" }}
                          className="inner-tabs"
                        >
                          3
                        </Box>
                      </Box>
                    }
                    {...TabProps(3)}
                  />
                  <Tab
                    sx={{ textTransform: "capitalize !important" }}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <span>Completed</span>
                        <Box
                          sx={{ backgroundColor: "#fff4e5" }}
                          className="inner-tabs"
                        >
                          1
                        </Box>
                      </Box>
                    }
                    {...TabProps(2)}
                  />

                  <Tab
                    sx={{ textTransform: "capitalize !important" }}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <span>All</span>
                        <Box
                          sx={{ backgroundColor: "#2e67a3", color: "#fff" }}
                          className="inner-tabs"
                        >
                          5
                        </Box>
                      </Box>
                    }
                    {...TabProps(0)}
                  />
                </Tabs>
              </Box>
            </Grid>
            <Box className="table">
              <Grid container padding={0} spacing={1}>
                {rowData.map((item) => {
                  return (
                    <Grid item xs={12} sm={12}>
                      <Box key={item.patientNumber} className="item-grid-row"
                      >
                        <Grid container padding={0} spacing={1}>
                          <Grid item xs={6} sm={10}>
                            <Box
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span className="patient-waiting-number">
                                <Alert
                                  icon={false}
                                  sx={{
                                    width: "fit-content !important",
                                    paddingX: "6px !important",
                                    paddingY: "0px !important",
                                    fontWeight: "bold",
                                    border: "none !important",
                                    lineHeight: "8px !important",
                                    color: "#fff",
                                    backgroundColor: "#495057"
                                  }}
                                >
                                  {item.patientWaitingNumber}
                                </Alert>
                              </span>
                              <span>
                                <Alert
                                  icon={false}
                                  className={
                                    item.patientStatus.toUpperCase() == "NEW"
                                      ? "badge badge-white"
                                      : "badge-new badge-white"
                                  }

                                >
                                  {item.patientStatus}
                                </Alert>
                              </span>
                              <span className="text-saperator">|</span>
                              <span>{item.patientNumber}</span>
                            </Box>
                          </Grid>
                          <Grid item xs={6} sm={2}>
                            <Box className="action-icon-placement">
                              <Box>
                                <Alert
                                  icon={false}
                                  className={
                                    item.status.toUpperCase() === "COMPLETED"
                                      ? "badge-new1 badge-warning"
                                      : item.status.toUpperCase() === "ACTIVE"
                                        ? "badge-new1 badge-success"
                                        : "badge-new1 badge-dark"
                                  }
                                >
                                  {item.status}
                                </Alert>
                              </Box>
                              <Tooltip
                                title="More"
                                placement="top"
                                style={{ padding: 2 }}
                              >
                                <IconButton
                                  onClick={(e: any) => handleOpenMore(e)}
                                >
                                  <MoreVertRoundedIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={12}
                            onClick={() => {
                              handleClickOpen();
                            }}>
                            <span
                              className="text-link patient-container"

                              style={{ cursor: "pointer" }}
                            >
                              {/* <Link
                              className="text-link patient-container"
                              to="/opd-clinical-detail"
                            > */}
                              {item.patientName}
                              {/* </Link> */}
                            </span>
                            <span className="text-saperator">|</span>
                            <span
                              style={{
                                fontSize: 16,
                                fontWeight: "bold",
                                color:
                                  item.gender === "M"
                                    ? " #2e67a3"
                                    : item.gender === "F"
                                      ? "#BC3965"
                                      : "black",
                              }}
                            >
                              {` ${item.gender} `}
                            </span>
                            <span
                              style={{
                                fontSize: 16,
                                fontWeight: "bold",
                              }}
                            >
                              {` ${item.age} `}
                            </span>
                            <span className="text-saperator">|</span>
                            <span
                              style={{
                                fontSize: 16,
                              }}
                            >
                              {` ${item.followUpVisitReason}`}
                            </span>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={1}
                          style={{
                            marginTop: 1,
                          }}
                          onClick={() => {
                            handleClickOpen();
                          }}
                        >
                          <Grid item xs={12} sm={12}>
                            {item.mobileNo} {item.address}
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={1}
                          style={{
                            marginTop: 1,
                          }}
                          onClick={() => {
                            handleClickOpen();
                          }}
                        >
                          <Grid item xs={12} sm={12}>
                            <Box className="symptoms-container">
                              {item.symptoms}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
            <Grid
              container
              paddingTop={1}
              className="pagination"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Grid item xs={12} sm={6} className="pagination-label">
                1 of 1 {/* {pageStartIndex} - {pageEndIndex} of {totalCount} */}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack
                  component="div"
                  alignItems={"end"}
                  justifyContent={"center"}
                >
                  <Pagination
                    color="primary"
                  // count={counData}
                  // page={page_Index}
                  // onChange={handleChangePageNew}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Formik>
      
      <Popover
        elevation={1}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseMore}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleClickOpen()}>
              <ListItemText primary="Edit" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton className="delete-container">Delete</ListItemButton>
          </ListItem>
        </List>
      </Popover>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <OPDClinicalDetail handleClose={handleClose} />
      </Dialog>
    </>
  );
}
