import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../../theme/style.scss";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  // FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  // Radio,
  // RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  // Tab,
  // Tabs,
  Typography,
  styled,
} from "@mui/material";
// import Title from "../../../components/Title";
import LoadingButton from "@mui/lab/LoadingButton";
import DatePickerCompponent from "../../../components/CustomFields/DatePicker";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css';
import { CustomSelect } from "../../../components/CustomFields/CustomSelect";
import { Formik } from "formik";
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import AddIcon from "@mui/icons-material/Add";
import CustomDocSave from "../../../components/CustomDocSave/CustomDocSave";
import CustomBreadcrumbs from "../../../components/BreadcrumbsComponent/CustomBreadcrumbs";
import useMediaQuery from "@mui/material/useMediaQuery";



const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#FAFBFD", // Set the desired background color
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));
function TabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  setValue(newValue);
};

export default function DoctorSave() {
  const [value, setValue] = useState('');
  const [countryData, setCountry] = useState([
    { countryID: 0, code: "", name: "" },
    { countryID: 1, code: "123", name: "India" },
    { countryID: 2, code: "456", name: "USA" },
  ]);
  const [anchorEl, setAnchorEl3] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);

  const [openDialog, setOpenDialog] = React.useState(false); 
    
  const handleClickOpen = (value: boolean) => {
    setOpenDialog(value);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };
  // for Edit & Delet pop 
  const handleOpenMore = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: any
  ) => {
    setAnchorEl3(event.currentTarget);
    // setBuyerId(id);
  };
  const handleCloseMore = () => {
    setAnchorEl3(null);
  };

  const navigate = useNavigate();
  const isLaptop = useMediaQuery('(min-width:1024px)');

  interface ColourOption {
    value: string;
    label: string;
  }

  const colourOptions: ColourOption[] = [
    { value: 'dr', label: 'Dr.' },
    { value: 'mr', label: 'Mr.' },
    { value: 'ms', label: 'Ms' },
    { value: 'mrs', label: 'Mrs' },
    { value: 'prof', label: 'Prof' },
  ];

  //  Table Header 

  function EnhancedTableHead(props: EnhancedTableProps) {
    return (
      // checkBox for Header

      <TableHead className='table-header-color'>
        <TableRow>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "15%"
            }}
            align="left"
          >
            Name
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "15%"
            }}
            align="left"
          >
            Registration #
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "15%"
            }}
            align="left"
          >
            Registration / Birth Date
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "10%"
            }}
            align="left"
          >
            Expiration Date
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "15%"
            }}
            align="left"
          >
            Document Name
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "10%"
            }}
            align="right"
          >
            Size
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "20%"
            }}
            align="left"
          >
            Uploaded Date
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "10%"
            }}
            align="left"
          >
            {''}
          </TableCell>

        </TableRow>
      </TableHead>
    );
  }
  const rowData = [
    {
      docName: "Passport",
      registration: "123456",
      registrationOrBirthDate: "15-01-1990",
      expirationDate: "2030-01-15",
      documentName: "JohnDoePassport.pdf",
      size: "2 MB",
      uploadedDate: "01-01-2024",
    },
    {
      docName: "Driver's License",
      registration: "654321",
      registrationOrBirthDate: "20-05-2010",
      expirationDate: "2025-05-20",
      documentName: "JaneDoeDriversLicense.pdf",
      size: "1 MB",
      uploadedDate: "15-12-2023",
    },
    {
      docName: "Birth Certificate",
      registration: "789012",
      registrationOrBirthDate: "10-08-2000",
      expirationDate: "",
      documentName: "JohnSmithBirthCertificate.pdf",
      size: "500 KB",
      uploadedDate: "10-02-2024",
    },
    {
      docName: "Social Security Card",
      registration: "345678",
      registrationOrBirthDate: "30-09-2015",
      expirationDate: "",
      documentName: "JaneSmithSocialSecurityCard.pdf",
      size: "300 KB",
      uploadedDate: "05-03-2024",
    },
    {
      docName: "Vaccination Record",
      registration: "987654",
      registrationOrBirthDate: "01-07-2021",
      expirationDate: "",
      documentName: "JohnDoeVaccinationRecord.pdf",
      size: "700 KB",
      uploadedDate: "20-04-2024",
    },
  ];

  return (
    <Box className="main-container " pl={2}>
      <Grid xs={12} container className="page-header-save">
        <Grid xs={12} sm={12} md={12} item className="page-breadcrumbs">
          <CustomBreadcrumbs />
        </Grid>
      </Grid>

      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
        }}
        onSubmit={async (values) => {
          console.log("values", values)
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} mb={3} className="group-container">
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Basic Information</Typography>
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Doctor Type</FormLabel>
                  <CustomSelect
                    isNotCreatable={true}
                    id={"referredBy"}
                    FieldName={"referredBy"}
                    options={colourOptions}
                    openMenuOnFocus
                    isClearable
                    isSearchable
                    menuPortalTarget={document.querySelector("body")}
                    handleChange={(e) => {
                      setValue(e.value)
                      console.log("event:", e)
                    }}
                    Value={value}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} sm={6}/>
              <Grid item xs={12} md={6} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Department</FormLabel>
                  <CustomSelect
                    isNotCreatable={true}
                    id={"country"}
                    FieldName={"country"}
                    options={countryData}
                    openMenuOnFocus
                    isClearable
                    isSearchable
                    menuPortalTarget={document.querySelector("body")}
                    handleChange={(e) => {
                      console.log("event:", e)
                    }}
                    Value=""
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Speciality</FormLabel>
                  <CustomSelect
                    isNotCreatable={true}
                    id={"country"}
                    FieldName={"country"}
                    options={countryData}
                    openMenuOnFocus
                    isClearable
                    isSearchable
                    menuPortalTarget={document.querySelector("body")}
                    handleChange={(e) => {
                      console.log("event:", e)
                    }}
                    Value=""
                  />
                </FormControl>
              </Grid>
              
              <Grid item xs={12} md={3} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Prefix</FormLabel>
                  <CustomSelect
                    isNotCreatable={true}
                    id={"referredBy"}
                    FieldName={"referredBy"}
                    options={colourOptions}
                    openMenuOnFocus
                    isClearable
                    isSearchable
                    menuPortalTarget={document.querySelector("body")}
                    handleChange={(e) => {
                      setValue(e.value)
                      console.log("event:", e)
                    }}
                    Value={value}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">First Name</FormLabel>
                  <CustomTextField
                    name="laboratoryName"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Middle Name</FormLabel>
                  <CustomTextField
                    name="laboratoryName"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Last Name</FormLabel>
                  <CustomTextField
                    name="laboratoryName"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Qualification</FormLabel>
                  <CustomTextField
                    name="laboratoryName"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Designation</FormLabel>
                  <CustomTextField
                    name="laboratoryName"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
              <FormControl fullWidth>
                <FormLabel className="fileds-label-text">Gender</FormLabel>
                <CustomSelect
                  isNotCreatable={true}
                  id={"country"}
                  FieldName={"country"}
                  options={countryData}
                  openMenuOnFocus
                  isClearable
                  isSearchable
                  menuPortalTarget={document.querySelector("body")}
                  handleChange={(e) => {
                    console.log("event:", e)
                  }}
                  Value=""
                />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Marital Status</FormLabel>
                  <CustomSelect
                    isNotCreatable={true}
                    id={"country"}
                    FieldName={"country"}
                    options={countryData}
                    openMenuOnFocus
                    isClearable
                    isSearchable
                    menuPortalTarget={document.querySelector("body")}
                    handleChange={(e) => {
                      console.log("event:", e)
                    }}
                    Value=""
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <div >
                  <Box style={{ fontSize: 12 }}>Date of Birth</Box>
                  <FormControl sx={{ width: '100% !important' }}>
                    <DatePickerCompponent
                      format="DD-MM-YYYY"
                      mode="date"
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Age</FormLabel>
                  <CustomTextField
                    name="laboratoryName"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Preferred Language</FormLabel>
                  <CustomSelect
                    isNotCreatable={true}
                    id={"referredBy"}
                    FieldName={"referredBy"}
                    options={countryData}
                    openMenuOnFocus
                    isClearable
                    isSearchable
                    menuPortalTarget={document.querySelector("body")}
                    handleChange={(e) => {
                      console.log("event:", e)
                    }}
                    Value=""
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                {/* <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label" className="fileds-label-text">Gender</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                    <FormControlLabel value="female " control={<Radio />} label="Female" />
                    <FormControlLabel value="other" control={<Radio />} label="Other" />

                  </RadioGroup>
                </FormControl> */}
                
              </Grid>
            </Grid>
          </Grid>


          <Grid item xs={12} mb={3} className="group-container">
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Contact Information</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Address 1</FormLabel>
                  <CustomTextField
                    name="address1"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Address 2</FormLabel>
                  <CustomTextField
                    name="address2"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">City</FormLabel>
                  <CustomTextField
                    name="city"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Postal Code</FormLabel>
                  <CustomTextField
                    name="postalCode"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
             
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">State</FormLabel>
                  <CustomSelect
                    isNotCreatable={true}
                    id={"state"}
                    FieldName={"state"}
                    options={countryData}
                    openMenuOnFocus
                    isClearable
                    isSearchable
                    menuPortalTarget={document.querySelector("body")}
                    handleChange={(e) => {
                      console.log("event:", e)
                    }}
                    Value=""
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Country</FormLabel>
                  <CustomSelect
                    isNotCreatable={true}
                    id={"country"}
                    FieldName={"country"}
                    options={countryData}
                    openMenuOnFocus
                    isClearable
                    isSearchable
                    menuPortalTarget={document.querySelector("body")}
                    handleChange={(e) => {
                      console.log("event:", e)
                    }}
                    Value=""
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Email</FormLabel>
                  <CustomTextField
                    name="email"
                    placeholder=""
                    autoCapitalize="none"
                    type="email"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div className="input-group">
                  <label htmlFor="phone">Primary Mobile Number</label>
                  <PhoneInput
                    id="phone"
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="IN"
                    value={value}
                    onChange={setValue}
                    placeholder="Enter phone number"
                    className="phone-input"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div className="input-group">
                  <label htmlFor="phone">Alternate Mobile Number</label>
                  <PhoneInput
                    id="phone"
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="IN"
                    value={value}
                    onChange={setValue}
                    placeholder="Enter phone number"
                    className="phone-input"
                  />
                </div>
              </Grid>
             
            </Grid>
          </Grid>


          <Grid item xs={12} mb={3} className="group-container">
            
            <Grid container item spacing={2}>
              <Grid item xs={11} className="new2">
                <Typography variant="h6" >Documents</Typography>
              </Grid>
              <Grid item xs={1} >
                <Box style={{ display: 'flex', justifyContent: 'end' }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleClickOpen(true)}
                    size="medium"
                    startIcon={<AddIcon />}
                  >
                    Add
                  </Button>
                </Box>
                
              </Grid>
              <TableContainer className='table padding-1' >
                
                <Table style={{ overflowX: 'scroll' }}>
                  <EnhancedTableHead />
                  <TableBody>
                    {rowData.map((item) => {
                      return (
                        <TableRow>
                          
                          <TableCell><Link className='text-link' onClick={handleClickOpen}>{item.docName}</Link></TableCell>
                          <TableCell>{item.registration}</TableCell>
                          <TableCell align="left">{item.registrationOrBirthDate}</TableCell>
                          <TableCell>{item.expirationDate}</TableCell>
                          <TableCell><Link className='text-link' to="/patient-master-save">{item.documentName}</Link></TableCell>
                          <TableCell align="right">{item.size}</TableCell>
                          <TableCell>{item.uploadedDate}</TableCell>
                          <TableCell align="right">
                            <Tooltip title="More" placement="top">
                              <IconButton
                                onClick={(e: any) =>
                                  handleOpenMore(e)
                                }
                              >
                                <MoreVertRoundedIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
              <LoadingButton
                color="primary"
                tabIndex={3}
                loadingPosition="start"
                startIcon={<></>}
                variant="contained"
                type="submit"
                className="primary-button"
                onClick={() => navigate("/master/doctor")}
                width="180px"
                name="submit"
              >
                SAVE
              </LoadingButton>
              <LoadingButton
                color="secondary"
                tabIndex={3}
                loadingPosition="start"
                startIcon={<></>}
                variant="outlined"
                type="submit"
                className="secondary-button"
                name="submit"
                onClick={() => navigate("/master/doctor")}
              >
                CANCEL
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Formik>
      <Popover
        elevation={1}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseMore}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={handleClickOpen}>
              <ListItemText primary="Edit" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              className="delete-container"
            >
              Delete
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
      <Dialog
        className="dialog"
        open={openDialog}
        onClose={handleClose}
        fullScreen
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          
            borderRadius: '16px', 
        
        }}
        
      >
        <DialogContent  className="padding-0">
          <CustomDocSave
            handleClose={handleClose}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
