

import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../../../../theme/style.scss';
import { Box, FormControl, FormLabel, Grid, TextField, Typography, styled } from '@mui/material';
import Select from 'react-select';
import LoadingButton from '@mui/lab/LoadingButton';


const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: '#FAFBFD', // Set the desired background color
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.grey[300],
      borderWidth: '1px',
      BorderStyle: 'solid'
    },
    '&:hover fieldset': {
      borderColor: theme.palette.grey[500],
      borderWidth: '1px',
      BorderStyle: 'solid'
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: "2px",
    },
  },
}));


export default function DiagnosisInstructionMasterSave() {
  const navigate = useNavigate()

  return (
    <Box className="main-container " pl={2}>
    
      <Grid container spacing={2} sx={{marginRight:{
        xs:-2,
        sm:0
       }}} >
        <Grid item xs={12} mb={2} className="group-container">
          <Grid container item spacing={2} >
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>Code</FormLabel>

                <CustomTextField
                  name="code"
                  placeholder=""
                  autoCapitalize='none'
                  type="text"
                  fullWidth
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={9}>
              <></>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>Diagnosis Name</FormLabel>
                <CustomTextField
                  name="diagnosisName"
                  placeholder=""
                  autoCapitalize='none'
                  type="text"
                  fullWidth
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>Diagnosis Type</FormLabel>
                <Select
                  name="organizationType"
                  // options={options}
                  // value={selectedOption}
                  // onChange={handleSelectChange}
                  placeholder="Select"
                  styles={{
                    placeholder: (provided) => ({
                      ...provided,
                      fontSize: '0.813rem', // Set the desired placeholder font size here
                    }),
                    control: (base) => ({
                      ...base,
                      backgroundColor: '#FAFBFD',
                      border: '2px solid #cbcbda',
                      '&:hover': {
                        border: '2px solid #bdc1cd',
                      },
                      boxShadow: 'none',
                    }),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      
      </Grid>
    </Box>
  )
}