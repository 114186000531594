import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../theme/style.scss";
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    TextField,
    Typography,
    styled,
    Select,
    Radio,
    InputAdornment,
    MenuItem,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Formik } from "formik";
import BreadcrumbsComponent from "../../../components/BreadcrumbsComponent/BreadcrumbsComponent";
import { CustomSelect } from "../../../components/CustomFields/CustomSelect";
import CustomBreadcrumbs from "../../../components/BreadcrumbsComponent/CustomBreadcrumbs";
import { ClassNames } from "@emotion/react";

const CustomTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: "#FAFBFD", // Set the desired background color
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: theme.palette.grey[300],
            borderWidth: "1px",
            BorderStyle: "solid",
        },
        "&:hover fieldset": {
            borderColor: theme.palette.grey[500],
            borderWidth: "1px",
            BorderStyle: "solid",
        },
        "&.Mui-focused fieldset": {
            borderWidth: "2px",
            borderColor: theme.palette.primary.main,
        },
    },
}));


export default function BillingItemMasterSave() {
    const [countryData, setCountry] = useState([
        { countryID: 0, code: "", name: "" },
        { countryID: 1, code: "123", name: "India" },
        { countryID: 2, code: "456", name: "USA" },
    ]);
    const [discountType, setDiscountType] = useState('%');
    const [discountValue, setDiscountValue] = useState(20);
    const navigate = useNavigate();

    const handleDiscountTypeChange = (event) => {
        setDiscountType(event.target.value);
    };

    const handleDiscountValueChange = (event) => {
        setDiscountValue(event.target.value);
    };
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    return (
        <Box className="main-container" pl={2}>
            <Grid item xs={12} container className="page-header-save">
                <Grid xs={6} sm={6} item className="page-breadcrumbs">
                    <CustomBreadcrumbs />
                </Grid>
            </Grid>
            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                }}
                onSubmit={async (values) => {
                    console.log("values", values)
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} mb={3} className="group-container">
                        <Grid container item spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Basic Information</Typography>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <FormControl>
                                    <FormControlLabel value="yes" control={<Radio checked />}  label="Service" />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={9} md={9} />
                            <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth>
                                    <FormLabel className='fileds-label-text'>Department </FormLabel>
                                    <Select
                                        name="city"
                                        size="small"
                                        placeholder="Select"
                                        styles={{
                                            placeholder: (provided) => ({
                                                ...provided,
                                                fontSize: '0.813rem', // Set the desired placeholder font size here
                                            }),
                                            control: (base) => ({
                                                ...base,
                                                backgroundColor: '#FAFBFD',
                                                border: '2px solid #cbcbda',
                                                '&:hover': {
                                                    border: '2px solid #bdc1cd',
                                                },
                                                boxShadow: 'none',
                                            }),
                                        }}
                                    >
                                        {/* <MenuItem value={'Ahmadabad'}>OPD</MenuItem>
                                        <MenuItem value={'Ahmadabad'}>IPD</MenuItem>
                                        <MenuItem value={'Ahmadabad'}>BOTH</MenuItem> */}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth>
                                    <FormLabel className='fileds-label-text'>Speciality </FormLabel>
                                    <Select
                                        name="city"
                                        size="small"
                                        placeholder="Select"
                                        styles={{
                                            placeholder: (provided) => ({
                                                ...provided,
                                                fontSize: '0.813rem', // Set the desired placeholder font size here
                                            }),
                                            control: (base) => ({
                                                ...base,
                                                backgroundColor: '#FAFBFD',
                                                border: '2px solid #cbcbda',
                                                '&:hover': {
                                                    border: '2px solid #bdc1cd',
                                                },
                                                boxShadow: 'none',
                                            }),
                                        }}
                                    >
                                        {/* <MenuItem value={'Ahmadabad'}>OPD</MenuItem>
                                        <MenuItem value={'Ahmadabad'}>IPD</MenuItem>
                                        <MenuItem value={'Ahmadabad'}>BOTH</MenuItem> */}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={3} md={3}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text">Code</FormLabel>
                                    <CustomTextField
                                        name="code"
                                        placeholder=""
                                        autoCapitalize="none"
                                        type="text"
                                        fullWidth
                                        size="small"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={9} md={9} />
                            <Grid item xs={12} sm={3} md={3}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text">Item Name</FormLabel>
                                    <CustomTextField
                                        name="branchName"
                                        placeholder=""
                                        autoCapitalize="none"
                                        type="text"
                                        fullWidth
                                        size="small"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <FormControl fullWidth>
                                    <FormLabel className='fileds-label-text'>Under</FormLabel>
                                    <Select
                                        name="city"
                                        size="small"
                                        placeholder="Select"
                                        styles={{
                                            placeholder: (provided) => ({
                                                ...provided,
                                                fontSize: '0.813rem', // Set the desired placeholder font size here
                                            }),
                                            control: (base) => ({
                                                ...base,
                                                backgroundColor: '#FAFBFD',
                                                border: '2px solid #cbcbda',
                                                '&:hover': {
                                                    border: '2px solid #bdc1cd',
                                                },
                                                boxShadow: 'none',
                                            }),
                                        }}
                                    >
                                        <MenuItem value={'Ahmadabad'}>OPD</MenuItem>
                                        <MenuItem value={'Ahmadabad'}>IPD</MenuItem>
                                        <MenuItem value={'Ahmadabad'}>BOTH</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} />
                            <Grid item xs={12} sm={3} md={3}>
                                <FormControl fullWidth>
                                    <FormLabel className='fileds-label-text'>Tax Type</FormLabel>
                                    <Select
                                        name="city"
                                        size="small"
                                        placeholder="Select"
                                        styles={{
                                            placeholder: (provided) => ({
                                                ...provided,
                                                fontSize: '0.813rem', // Set the desired placeholder font size here
                                            }),
                                            control: (base) => ({
                                                ...base,
                                                backgroundColor: '#FAFBFD',
                                                border: '2px solid #cbcbda',
                                                '&:hover': {
                                                    border: '2px solid #bdc1cd',
                                                },
                                                boxShadow: 'none',
                                            }),
                                        }}
                                    >
                                        <MenuItem value={'Ahmadabad'}>Tax Extra</MenuItem>
                                        <MenuItem value={'Ahmadabad'}>Tax Included</MenuItem>
                                        <MenuItem value={'Ahmadabad'}>Tax Not Applicable</MenuItem>
                                        <MenuItem value={'Ahmadabad'}>Tax Exemption</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text">Tax </FormLabel>
                                    <CustomTextField
                                        name="branchName"
                                        placeholder=""
                                        autoCapitalize="none"
                                        type="text"
                                        fullWidth
                                        size="small"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} />
                            
                            <Grid item xs={12} sm={3} md={3}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text">Amount </FormLabel>
                                    <CustomTextField
                                        name="branchName"
                                        placeholder=""
                                        autoCapitalize="none"
                                        type="text"
                                        fullWidth
                                        size="small"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={9} md={9} />
                            <Grid item xs={12} sm={3} md={3}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text">Default Discount </FormLabel>
                                    <TextField
                                        inputProps={{
                                            className: 'no-spinner',
                                            inputMode: 'numeric', pattern: '[0-9]*', step: '1', onKeyPress: (event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        value={discountValue}
                                        onChange={handleDiscountValueChange}
                                        type="number"
                                        size="small"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Select
                                                        value={discountType}
                                                        onChange={handleDiscountTypeChange}
                                                        size="small"
                                                        displayEmpty
                                                        inputProps={{
                                                            "aria-label": "Without label",
                                                        }}
                                                        sx={{
                                                            paddingLeft: 1, // Adjust padding as needed
                                                            paddingRight: 0,
                                                        }}
                                                    >
                                                        <MenuItem value="%">%</MenuItem>
                                                        <MenuItem value="₹">₹</MenuItem>
                                                    </Select>
                                                </InputAdornment>
                                            ),
                                            endAdornment: null, // No adornment on the right side
                                        }}
                                        sx={{
                                            minWidth: "150px",
                                            ".MuiOutlinedInput-root": {
                                                paddingLeft: 0, // Adjust padding as needed
                                                overflow: 'hidden', // Prevents scrollbar
                                            },
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={9} md={9}/>
                            <Grid item xs={12} sm={3} md={3}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text">Final Amount</FormLabel>
                                    <CustomTextField
                                        name="branchName"
                                        placeholder=""
                                        autoCapitalize="none"
                                        type="text"
                                        fullWidth
                                        size="small"
                                    />
                                </FormControl>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
                            <LoadingButton
                                color="primary"
                                tabIndex={3}
                                loadingPosition="start"
                                startIcon={<></>}
                                variant="contained"
                                type="submit"
                                className="primary-button"
                                name="submit"
                            >
                                Save
                            </LoadingButton>
                            <LoadingButton
                                tabIndex={3}
                                loadingPosition="start"
                                startIcon={<></>}
                                variant="outlined"
                                type="submit"
                                name="submit"
                                className="secondary-button"
                                color="secondary"
                                onClick={() => navigate("/master/billing-item-master")}
                            >
                                Cancel
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </Formik>
        </Box>
    );
}
