import "../../../../../../theme/style.scss";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
  styled,
  Dialog,
  DialogContent
} from "@mui/material";
import React, { useState } from "react";
import { Formik } from "formik";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import ReactQuill from "react-quill";
import ChiefComplaintsDraggableTable from "../../ChiefComplaints/ChiefComplaintsDraggable/ChiefComplaintsDraggableTable";
import { reorder } from "../../../../../../Helpers/helpers";
import { DropResult } from "react-beautiful-dnd";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import BloodPresureInput from "../../../../../../components/BloodPresure/BloodPresureInput";
import { AsyncCustomSelect, CustomSelect } from "../../../../../../components/CustomFields/CustomSelect";
import useMediaQuery from '@mui/material/useMediaQuery'
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import { isGeneratorFunction } from "util/types";
import SubjectRoundedIcon from "@mui/icons-material/SubjectRounded";

const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#FAFBFD",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));

const addmissionState = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
]
const bpMeasurmentState = [
  {label:"Right Arm", value:0},
  {label:"Left Arm", value:1},
  {label:"Right Leg", value:2},
  {label:"Left Leg", value:3},
]

export default function GeneralExamination() {
  const [showNote, setShowNote] = useState(false);
  const [showGeneralNote, setShowGeneralNote] = useState(false);
  const [selected, setSelected] = useState("");
  const [isVitalParameters, setIsVitalParameters] = useState("");
  // const [isGeneralExamination, setIsGeneralExamination] = useState("");
  const [isTemperature, setIsTemperature] = useState(false);
  const [pulse, setPulse] = useState(false);
  const [respiratory, setRespiratory] = useState(false);
  const [spO2, setSpO2] = useState(false);
  const [bloodPressure, setBloodPressure] = useState(false);
  const smallScreen = useMediaQuery("(max-width:768px)");
  const mobileScreen = useMediaQuery("(max-width:425px)");
  const [countryData, setCountry] = useState([
    { countryID: 0, code: "", name: "" },
    { countryID: 1, code: "123", name: "India" },
    { countryID: 2, code: "456", name: "USA" },
  ]);

  const [items, setItems] = React.useState([
    {
      id: 1,
      symptom: "Temperature",
    },
    {
      id: 2,
      symptom: "Pulse",
    },
    {
      id: 3,
      symptom: "Respiratory Rate",
    },
    {
      id: 4,
      symptom: "SpO2",
    },
    {
      id: 5,
      symptom: "Blood pressure",
    },
  ]);
  const [generalPhysicalExamination, setGeneralPhysicalExamination] =
    React.useState([
      { id: "Item 1", symptom: "Built" },
      { id: "Item 2", symptom: "Nourishment" },
      { id: "Item 3", symptom: "Consciousness" },
      { id: "Item 4", symptom: "Orientation to time place and person" },
      { id: "Item 5", symptom: "Anemia/Pallor" },
      { id: "Item 6", symptom: "Cyanosis" },
      { id: "Item 7", symptom: "Clubbing" },
      { id: "Item 8", symptom: "Jaundice" },
      { id: "Item 9", symptom: "Edema" },
      { id: "Item 10", symptom: "Lymphadenopathy" },
      { id: "Item 11", symptom: "Pigmentation" },
      { id: "Item 12", symptom: "Any scar on body" },
      { id: "Item 13", symptom: "Any Swelling on body" },
      { id: "Item 14", symptom: "Lump" },
      { id: "Item 16", symptom: "Scalp and Hair" },
      { id: "Item 17", symptom: "Eyes" },
      { id: "Item 18", symptom: "Ears" },
      { id: "Item 19", symptom: "Nose" },
      { id: "Item 20", symptom: "Mouth and Oral cavity" },
      { id: "Item 21", symptom: "Tongue" },
      { id: "Item 22", symptom: "Teeth" },
      { id: "Item 23", symptom: "Neck and Neck Glands" },
      { id: "Item 24", symptom: "Any visible/distended veins in Neck" },
      { id: "Item 25", symptom: "JVP" },
      { id: "Item 26", symptom: "Chest" },
      { id: "Item 27", symptom: "Back" },
      { id: "Item 28", symptom: "Abdomen" },
      { id: "Item 29", symptom: "Upper extremities" },
      { id: "Item 30", symptom: "Finger Nails" },
      { id: "Item 31", symptom: "Lower extremities" },
      { id: "Item 32", symptom: "Toe-Nails" },
      { id: "Item 33", symptom: "Chest deformity" },
      { id: "Item 34", symptom: "Limb deformity" },
      { id: "Item 35", symptom: "External Genitalia" },
      { id: "Item 36", symptom: "SMR (Sexual Maturity rating)" },
      { id: "Item 37", symptom: "Any other finding" },
    ]
    );

  const [temperature, setTemperature] = useState("");
  const [unit, setUnit] = useState("C");

  const handleTemperatureChange = (event) => {
    setTemperature(event.target.value);
  };

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return;
    const newItems = reorder(
      generalPhysicalExamination,
      source.index,
      destination.index
    );
    setGeneralPhysicalExamination(newItems);
  };

  const [openVitalParameter, setOpenVitalParameter] = React.useState(false);

  const handleClickOpenVitalParameter = (value: boolean) => {
    if(smallScreen){
      setOpenVitalParameter(value);
    }else if(mobileScreen){
      setOpenVitalParameter(value);
      
    }
  };

  const handleClickCloseVitalParameter = () => {
    setOpenVitalParameter(false);
  };

  const [openVitalGeneralExamination, setOpenGeneralExamination] = React.useState(false);

  const handleClickOpenGeneralExamination = (value: boolean) => {
    if(smallScreen){
      
      setOpenGeneralExamination(value);
    }else if(mobileScreen){
      setOpenGeneralExamination(value);
      
    }
  };

  const handleClickCloseGeneralExamination = () => {
    setOpenGeneralExamination(false);
  };

  const onHandleSelectData = (val) => {
    setSelected(val);
  };

  // Vital parameters summary
  const [openVitalSummary, setopenVitalSummary] = React.useState(false);

  const handleClickOpenVitalSummary = (value: boolean) => {
    if (smallScreen) {
      setopenVitalSummary(value);
    }
    else if (mobileScreen) {
      setopenVitalSummary(value);
    }
  };

  const handleClickCloseVitalSummary = () => {
    setopenVitalSummary(false);
  };

  // General Physical Examination summary
  const [openGeneralSummary, setopenGeneralSummary] = React.useState(false);

  const handleClickOpenGeneralSummary = (value: boolean) => {
    if (mobileScreen) {
      setopenGeneralSummary(value);
    }
    else if (smallScreen) {
      setopenGeneralSummary(value);
    }
  };

  const handleClickCloseGeneralSummary = () => {
    setopenGeneralSummary(false);
  };

  return (
    <Box className="main-container">
      <Grid container spacing={0}>
        <Grid item xs={12} mb={2}>
          <Grid container item spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                className="template-table-container"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                }}
              >
                <Box className="inner-title-container">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box className="inner-header-container"
                    sx={{display:"flex",  justifyContent:"space-between"}}
                    >
                      <Typography style={{ fontWeight: 600 }}>
                        Vital Parameters
                      </Typography>
                      {mobileScreen || smallScreen ? <Box>
                        <Button onClick={() => handleClickOpenVitalSummary(true)} sx={{ padding: 0, margin: 0,minWidth:"30px" }} aria-label="delete">
                         <SubjectRoundedIcon></SubjectRoundedIcon>
                        </Button>
              </Box> : ''}
                    </Box>
                  </Paper>
                  <Divider />
                  <List>
                    {items.map((item: any, index: number) => (
                      <ListItem
                        sx={{
                          borderBottom: "1px solid #E9EBEC",
                          cursor: 'pointer'
                        }}
                        className={
                          isVitalParameters === item.symptom
                            ? "draggable-list-item"
                            : " "
                        }
                      >
                        <Grid container item spacing={2}>
                          <Grid item xs={12} sm={12} md={12}>
                            <Box
                              style={{
                                display: "flex",
                              }}
                              onClick = {()=>{handleClickOpenVitalParameter(true)}}
                            >
                              <Grid container item>
                                <Grid item xs={12} sm={12} md={12}>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() => {
                                      setIsVitalParameters(item.symptom)
                                    }}
                                  >
                                    <Box
                                      className={
                                        isVitalParameters === item.symptom
                                          ? "symptom-text"
                                          : " "
                                      }
                                      sx={{ paddingBottom: '8px', paddingTop: '8px' }}
                                    
                                      >
                                      <Typography>{item.symptom}</Typography>
                                    </Box>

                                    {isVitalParameters === item.symptom && (
                                      <Box
                                        style={{
                                          paddingTop: "8px",
                                          paddingLeft: '8px'
                                        }}
                                      >
                                        <KeyboardDoubleArrowRightRoundedIcon />
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                  </List>


                  <Box className="note-container">
                    <Box>
                      {!showNote && (
                        <Button
                          variant="text"
                          style={{ fontSize: "6px" }}
                          onClick={() => {
                            setShowNote(true);
                          }}
                        >
                          <AddIcon className="add-icon" />
                          <span className="add-icon"> Note</span>
                        </Button>
                      )}
                    </Box>

                    {showNote && (
                      <FormControl fullWidth>
                        <Box className="note-container-box">
                          <FormLabel
                            sx={{ paddingLeft: 1 }}
                            className="fileds-label-text"
                          >
                            Note
                          </FormLabel>
                          <Button
                            variant="text"
                            onClick={() => {
                              setShowNote(false);
                            }}
                          >
                            <RemoveIcon className="remove-note" />
                            <span className="remove-note">Remove Note</span>
                          </Button>
                        </Box>
                        <ReactQuill
                          className="react-quill-alignment"
                          theme="snow"
                          value={""}
                          onChange={(e) => { }}
                        />
                      </FormControl>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container template-hide-small-screen template-hide-medium-screen"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                  minHeight: 400,
                }}
                style = {{position:"sticky", top:"5px"}}
              >
                <Box className="inner-title-container">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="inner-header-container">
                      <Typography
                        className={isVitalParameters ? "symptom-text" : ""}
                        style={{ fontWeight: 600 }}
                      >
                        {isVitalParameters ? isVitalParameters : "Vital Parameter Summary"}
                      </Typography>
                      {isVitalParameters !== '' && <Typography>
                        <IconButton onClick={() => setIsVitalParameters("")} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                          <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                        </IconButton>
                      </Typography>}
                    </Box>
                  </Paper>
                  {isVitalParameters ? (
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                        <Divider />
                        <Grid
                          container
                          item
                          spacing={2}
                          style={{ padding: 20 }}
                        >
                          {/* {isVitalParameters === 'Temperature' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setIsTemperature(!isTemperature);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Temperature"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {isVitalParameters === "Temperature" &&
                            //  isTemperature && (
                            <Grid item xs={4} sm={6} md={3}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Temperature by Palpation
                                </FormLabel>
                                <CustomTextField
                                  name="duration"
                                  placeholder=""
                                  autoCapitalize="none"
                                  type="number"
                                  fullWidth
                                  size="small"
                                />
                              </FormControl>
                            </Grid>
                            // )
                          }
                          {isVitalParameters === "Temperature" && <Grid item xs={4} sm={6} md={9} />}
                          {isVitalParameters === "Temperature" &&
                            // isTemperature && (
                            <Grid item xs={4} sm={2} md={3}>
                              <Box display="flex" alignItems="center">
                                <FormControl fullWidth>
                                  <FormLabel className="fileds-label-text">
                                    Temperature
                                  </FormLabel>
                                  <TextField
                                    variant="outlined"
                                    fullWidth
                                    value={temperature}
                                    onChange={handleTemperatureChange}
                                    type="number"
                                    size="small"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <Select
                                            value={unit}
                                            onChange={handleUnitChange}
                                            size="small"
                                            displayEmpty
                                            inputProps={{
                                              "aria-label": "Without label",
                                            }}
                                            sx={{
                                              marginLeft: 1,
                                              ".MuiSelect-select": {
                                                paddingRight: "0px",
                                              },
                                            }}
                                          >
                                            <MenuItem value="C">°C</MenuItem>
                                            <MenuItem value="F">°F</MenuItem>
                                          </Select>
                                        </InputAdornment>
                                      ),
                                    }}
                                    sx={{
                                      minWidth: "150px",
                                      ".MuiOutlinedInput-root": {
                                        paddingRight: 0,
                                      },
                                    }}
                                  />
                                </FormControl>
                              </Box>
                            </Grid>
                            // )
                          }
                          {isVitalParameters === "Temperature"
                            // && isTemperature
                            && (
                              <Grid item xs={8} sm={12} md={12}>
                                <FormControl fullWidth>
                                  <FormLabel className="fileds-label-text">
                                    Note
                                  </FormLabel>
                                  <ReactQuill
                                    className="react-quill-alignment"
                                    theme="snow"
                                    value={""}
                                    onChange={(e) => { }}
                                  />
                                </FormControl>
                              </Grid>
                            )}
                          {/* {isVitalParameters === 'Pulse' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setPulse(!pulse);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Pulse"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {isVitalParameters === "Pulse" &&
                            //  pulse && (
                            <Grid item xs={4} sm={6} md={3}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Pulse Rate
                                </FormLabel>
                                <OutlinedInput
                                  id="outlined-adornment-weight"
                                  size="small"
                                  endAdornment={<InputAdornment position="end">bpm</InputAdornment>}
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    'aria-label': 'weight',
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            // )
                          }
                          {isVitalParameters === "Pulse" &&
                            // pulse && (
                            <Grid item xs={8} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                            // )
                          }
                          {/* {isVitalParameters === 'Respiratory' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setRespiratory(!respiratory);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Respiratory"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {isVitalParameters === "Respiratory Rate" &&
                            //  respiratory && (
                            <Grid item xs={4} sm={6} md={3}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Respiratory Rate
                                </FormLabel>
                                <OutlinedInput
                                  id="outlined-adornment-weight"
                                  size="small"
                                  endAdornment={<InputAdornment position="end">/min</InputAdornment>}
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    'aria-label': 'weight',
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            // )
                          }
                          {isVitalParameters === "Respiratory Rate" &&
                            //  respiratory && (
                            <Grid item xs={8} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                            // )
                          }
                          {/* {isVitalParameters === 'SpO2' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setSpO2(!spO2);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="SpO2"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {isVitalParameters === "SpO2" &&
                            // spO2 && (
                            <Grid item xs={4} sm={6} md={3}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  SpO2
                                </FormLabel>
                                <OutlinedInput
                                  id="outlined-adornment-weight"
                                  size="small"
                                  endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    'aria-label': 'weight',
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            // )
                          }
                         {isVitalParameters === "SpO2" && <Grid item xs={4} sm={12} md={6} />}
                          {isVitalParameters === "SpO2" &&
                            // spO2 && (
                            <Grid item xs={4} sm={6} md={6}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Site of SPO2 measurement
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                            // )
                          }
                          {isVitalParameters === "SpO2" &&
                            //  spO2 && (
                            <Grid item xs={8} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                            // )
                          }
                          {/* {isVitalParameters === 'Blood pressure' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setBloodPressure(!bloodPressure);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Blood pressure"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {isVitalParameters === "Blood pressure" &&
                            //  bloodPressure && (
                            <Grid item xs={4} sm={2} md={3}>
                              <BloodPresureInput
                                // label="Min/Max"
                                name="monthYear"
                                // value={values.monthYear}
                                onChange={() => { console.log('blood presure') }}
                              // error={errors.monthYear && touched.monthYear}
                              // helperText={touched.monthYear && errors.monthYear}
                              />
                            </Grid>
                            // )
                          }
                          {isVitalParameters === "Blood pressure" && <Grid item xs={4} sm={12} md={9} />}
                          {isVitalParameters === "Blood pressure" &&
                            //  bloodPressure && (
                            <Grid item xs={4} sm={6} md={6}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Site of Blood Presure measurement
                                </FormLabel>
                                <CustomSelect
                                   id={"bpState"}
                                   FieldName={"bpState"}
                                   options={bpMeasurmentState}
                                   openMenuOnFocus
                                   isClearable
                                   isSearchable
                                   menuPortalTarget={document.querySelector("body")}
                                   handleChange={(e) => {
                                     console.log("event:", e)
                                   }}
                                   Value=""
                                  // tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  // isNotCreatable={false}
                                  // isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  // menuPortalTarget={document.querySelector("body")}
                                  // id={"followUpReason"}
                                  // isClearable={true}
                                  // FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  // handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                            // )
                          }
                          {isVitalParameters === "Blood pressure" &&
                            //  bloodPressure && (
                            <Grid item xs={8} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                            // )
                          }
                        </Grid>
                      </Box>
                    </Formik>
                  ) : (
                    <Box>  <Divider />
                    <Box className="summary-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mb={2} >
          <Grid container item spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                className="template-table-container"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                }}
              >
                <Box className="inner-title-container">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box className="inner-header-container"
                    sx={{display:"flex", justifyContent:"space-between"}}
                    >
                      <Typography style={{ fontWeight: 600 }}>
                        General Physical Examination
                      </Typography>
                      {mobileScreen || smallScreen ? <Box>
                        <Button onClick={() => handleClickOpenGeneralSummary(true)} sx={{ padding: 0, margin: 0,minWidth:"30px"}} aria-label="delete">
                         <SubjectRoundedIcon></SubjectRoundedIcon>
                        </Button>
              </Box> : ''}
                    </Box>
                  </Paper>
                  <Divider />
                  <List>
                    {generalPhysicalExamination.map((item: any, index: number) => (
                      <ListItem
                        sx={{
                          borderBottom: "1px solid #E9EBEC",
                          cursor: 'pointer'
                        }}
                        className={
                          selected === item.symptom
                            ? "draggable-list-item"
                            : " "
                        }
                      >
                        <Grid container item spacing={2}>
                          <Grid item xs={12} sm={12} md={12}>
                            <Box
                              style={{
                                display: "flex",
                              }}
                              onClick = {() => {
                                handleClickOpenGeneralExamination(true)
                              }}
                            >
                              <Grid container item>
                                <Grid item xs={12} sm={12} md={12}>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() => {
                                      setSelected(item.symptom)
                                    }}
                                  >
                                    <Box
                                      className={
                                        selected === item.symptom
                                          ? "symptom-text"
                                          : " "
                                      }
                                      sx={{ paddingBottom: '8px', paddingTop: '8px' }}
                                     
                                      >
                                      <Typography>{item.symptom}</Typography>
                                    </Box>

                                    {selected === item.symptom && (
                                      <Box
                                        style={{
                                          paddingTop: "8px",
                                          paddingLeft: '8px'
                                        }}
                                      >
                                        <KeyboardDoubleArrowRightRoundedIcon />
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                  </List>
                  <Box className="note-container">
                    <Box>
                      {!showGeneralNote && (
                        <Button
                          variant="text"
                          style={{ fontSize: "6px" }}
                          onClick={() => {
                            setShowGeneralNote(true);
                          }}
                        >
                          <AddIcon className="add-icon" />
                          <span className="add-icon"> Note</span>
                        </Button>
                      )}
                    </Box>

                    {showGeneralNote && (
                      <FormControl fullWidth>
                        <Box className="note-container-box">
                          <FormLabel
                            sx={{ paddingLeft: 1 }}
                            className="fileds-label-text"
                          >
                            Note
                          </FormLabel>
                          <Button
                            variant="text"
                            onClick={() => {
                              setShowGeneralNote(false);
                            }}
                          >
                            <RemoveIcon className="remove-note" />
                            <span className="remove-note">Remove Note</span>
                          </Button>
                        </Box>
                        <ReactQuill
                          className="react-quill-alignment"
                          theme="snow"
                          value={""}
                          onChange={(e) => { }}
                        />
                      </FormControl>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container template-hide-small-screen template-hide-medium-screen"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                }}
                style = {{position:"sticky", top:"5px"}}
              >
                <Box
                  style={{
                    flex: 1,
                    borderRadius: 8,
                    minHeight: 400,
                  }}
                >
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="inner-header-container">
                      <Typography
                        className={selected ? "symptom-text" : ""}
                        style={{ fontWeight: 600 }}
                      >
                        {selected ? selected : "General Physical Examination Summary"}
                      </Typography>
                      {selected !== '' && <Typography>
                        <IconButton onClick={() => setSelected("")} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                          <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                        </IconButton>
                      </Typography>}
                    </Box>
                  </Paper>
                  {selected ? (
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                        <Divider />
                        <Grid
                          container
                          item
                          spacing={2}
                          style={{ padding: 20 }}
                        >
                          {/* {selected === "Built" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Built"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Built" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}

                          {selected === "Nourishment" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}

                          {selected === "Consciousness" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Orientation to time place and person" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Orientation to time place and person"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Orientation to time place and person" && <Grid item xs={8} sm={6} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Orientation to time place and person
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={false}
                                id={"state"}
                                FieldName={"state"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Orientation to time place and person" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Anemia/Pallor" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Anemia/Pallor"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Anemia/Pallor" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Cyanosis" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Cyanosis"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Cyanosis" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Clubbing" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Clubbing"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Clubbing" && <Grid item xs={8} sm={12} md={6}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Grade of clubbing
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                options={[]}
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value=""
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Clubbing" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Jaundice" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Jaundice"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Jaundice" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Edema" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Edema"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Edema" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Edema
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"edmaState"}
                                FieldName={"edmaState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Edema" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Lymphadenopathy" && <Grid item xs={8} sm={6} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Lymphadenopathy
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"lymphadenopathyState"}
                                FieldName={"lymphadenopathyState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Lymphadenopathy" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Pigmentation" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Pigmentation"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Pigmentation" && <Grid item xs={8} sm={6} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Pigmentation
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"pigmentationState"}
                                FieldName={"pigmentationState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Pigmentation" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Any scar on body" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Any scar on body"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Any scar on body" && <Grid item xs={8} sm={6} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Any scar on body
                              </FormLabel>
                              <CustomSelect
                                isNotCreatable={true}
                                id={"scarState"}
                                FieldName={"scarState"}
                                options={addmissionState}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Any scar on body" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Any Swelling on body" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Any Swelling on body"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Any Swelling on body" && <Grid item xs={8} sm={6} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Any Swelling on body
                              </FormLabel>
                              <CustomSelect
                                isNotCreatable={true}
                                id={"swellingState"}
                                FieldName={"swellingState"}
                                options={addmissionState}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Any Swelling on body" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Lump" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Lump"
                              />
                            </FormGroup>
                          </Grid>}
                          {selected === "Lump" && <Grid item xs={8} sm={4} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Lump
                              </FormLabel>
                              <CustomSelect
                                isNotCreatable={true}
                                id={"state"}
                                FieldName={"state"}
                                options={countryData}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>} */}
                          {selected === "Lump" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Any other finding" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Any other finding"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Any other finding" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Scalp and Hair" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Scalp and Hair"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Scalp and Hair" && <Grid item xs={8} sm={6} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Scalp and Hair
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"scalpState"}
                                FieldName={"scalpState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Scalp and Hair" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Eyes" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Eyes"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Eyes" && <Grid item xs={8} sm={6} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Eyes
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"eyeState"}
                                FieldName={"eyeState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Eyes" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Ears" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Ears"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Ears" && <Grid item xs={8} sm={6} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Ears
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"earstate"}
                                FieldName={"earState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Ears" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Nose" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Nose"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Nose" && <Grid item xs={8} sm={6} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Nose
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"noseState"}
                                FieldName={"noseState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Nose" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Mouth and Oral cavity" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Mouth and Oral cavity"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Mouth and Oral cavity" && <Grid item xs={8} sm={6} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Mouth and Oral cavity
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"mouthState"}
                                FieldName={"mouthState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Mouth and Oral cavity" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Tongue" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Tongue"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Tongue" && <Grid item xs={8} sm={6} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Tongue
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"tongueState"}
                                FieldName={"tongueState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Tongue" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Teeth" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Teeth"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Teeth" && <Grid item xs={8} sm={6} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Teeth
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"teethState"}
                                FieldName={"teethState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Teeth" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Neck and Neck Glands" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Neck and Neck Glands"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Neck and Neck Glands" && <Grid item xs={8} sm={6} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Neck and Neck Glands
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"neckState"}
                                FieldName={"neckState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Neck and Neck Glands" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Any visible/distended veins in Neck" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "JVP" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="JVP"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "JVP" && <Grid item xs={8} sm={6} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                JVP
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                // isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"jvpReason"}
                                isClearable={true}
                                FieldName={`jvpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "JVP" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Chest" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Chest"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Chest" && <Grid item xs={8} sm={6} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Chest
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"chestState"}
                                FieldName={"chestState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Chest" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Back" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Back"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Back" && <Grid item xs={8} sm={6} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Back
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"backState"}
                                FieldName={"backState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Back" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Abdomen" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Abdomen"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Abdomen" && <Grid item xs={8} sm={6} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Abdomen
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"abdomenState"}
                                FieldName={"abdomenState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Abdomen" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Upper extremities" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Upper extremities"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Upper extremities" && <Grid item xs={8} sm={6} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Upper extremities
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"upperExtremitiesState"}
                                FieldName={"upperExtremitiesState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Upper extremities" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Finger Nails" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Finger Nails"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Finger Nails" && <Grid item xs={8} sm={6} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Finger Nails
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"fingerNails"}
                                FieldName={"fingerNails"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Finger Nails" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Lower extremities" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Lower extremities"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Lower extremities" && <Grid item xs={8} sm={6} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Lower extremities
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"lowerExtremitiesState"}
                                FieldName={"lowerExtremitiesState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Lower extremities" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Toe-Nails" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Toe-Nails"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Toe-Nails" && <Grid item xs={8} sm={6} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Toe-Nails
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"toeNailsState"}
                                FieldName={"toeNailsState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Toe-Nails" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Chest deformity" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Chest deformity"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Chest deformity" && <Grid item xs={8} sm={6} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Chest deformity
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"chestDeformityState"}
                                FieldName={"chestDeformityState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Chest deformity" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Chest deformity" && <Grid item xs={8} sm={4} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Chest deformity description
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>} */}
                          {/* {selected === "Limb deformity" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Limb deformity"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Limb deformity" && <Grid item xs={8} sm={6} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Limb deformity
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"limbDeformityState"}
                                FieldName={"limbDeformityState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Limb deformity" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Limb deformity" && <Grid item xs={8} sm={4} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                              Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>} */}
                          {/* {selected === "External Genitalia" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="External Genitalia"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "External Genitalia" && <Grid item xs={8} sm={6} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                External Genitalia
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"externalGenitaliaState"}
                                FieldName={"externalGenitaliaState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "External Genitalia" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "SMR (Sexual Maturity rating)" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="SMR (Sexual Maturity rating)"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "SMR (Sexual Maturity rating)" && <Grid item xs={8} sm={6} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                SMR (Sexual Maturity rating)
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"smrState"}
                                FieldName={"smrState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "SMR (Sexual Maturity rating)" && <Grid item xs={8} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                        </Grid>
                      </Box>
                    </Formik>
                  ) : (
                    <Box>  <Divider />
                     <Box className="summary-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
      fullScreen
                open={openVitalParameter}
                onClose={handleClickCloseVitalParameter}
       
            >
               <AppBar sx={{ position: 'relative' }}>
          <Toolbar
           sx={{ display:"flex",
            justifyContent:"space-between"}}>
            <Typography
              className={selected ? "symptom-text" : ""}
              style={{ fontWeight: 600, margin: 1 }}
              sx={{
                paddingLeft:{
                  xs:1,
                  sm:0,
                }
              }}
              variant="h6"
            >
          {isVitalParameters ? isVitalParameters : "Vital Parameters"}
            </Typography>
            <IconButton onClick={() => handleClickCloseVitalParameter(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
              <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
            </IconButton>
          </Toolbar>
          <Divider />
        </AppBar>
                <DialogContent style={{ height: 400 }}>
          
                <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container"
                sx={{
                  border: "0px solid #0000001f",
                  borderRadius: 1,
                  minHeight: 400,
                }}
              >
                <Box className="inner-title-container">
                 
                  {isVitalParameters ? (
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                       
                        <Grid
                          container
                          item
                          spacing={2}
                      
                        >
                          {/* {isVitalParameters === 'Temperature' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setIsTemperature(!isTemperature);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Temperature"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {isVitalParameters === "Temperature" &&
                            //  isTemperature && (
                            <Grid item xs={6} sm={6} md={3}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Temperature by Palpation
                                </FormLabel>
                                <CustomTextField
                                  name="duration"
                                  placeholder=""
                                  autoCapitalize="none"
                                  type="number"
                                  fullWidth
                                  size="small"
                                />
                              </FormControl>
                            </Grid>
                            // )
                          }
                          {isVitalParameters === "Temperature" && <Grid item xs={6} sm={6} md={9} />}
                          {isVitalParameters === "Temperature" &&
                            // isTemperature && (
                            <Grid item xs={4} sm={2} md={3}>
                              <Box display="flex" alignItems="center">
                                <FormControl fullWidth>
                                  <FormLabel className="fileds-label-text">
                                    Temperature
                                  </FormLabel>
                                  <TextField
                                    variant="outlined"
                                    fullWidth
                                    value={temperature}
                                    onChange={handleTemperatureChange}
                                    type="number"
                                    size="small"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <Select
                                            value={unit}
                                            onChange={handleUnitChange}
                                            size="small"
                                            displayEmpty
                                            inputProps={{
                                              "aria-label": "Without label",
                                            }}
                                            sx={{
                                              marginLeft: 1,
                                              ".MuiSelect-select": {
                                                paddingRight: "0px",
                                              },
                                            }}
                                          >
                                            <MenuItem value="C">°C</MenuItem>
                                            <MenuItem value="F">°F</MenuItem>
                                          </Select>
                                        </InputAdornment>
                                      ),
                                    }}
                                    sx={{
                                      minWidth: "150px",
                                      ".MuiOutlinedInput-root": {
                                        paddingRight: 0,
                                      },
                                    }}
                                  />
                                </FormControl>
                              </Box>
                            </Grid>
                            // )
                          }
                          {isVitalParameters === "Temperature"
                            // && isTemperature
                            && (
                              <Grid item xs={12} sm={12} md={12}>
                                <FormControl fullWidth>
                                  <FormLabel className="fileds-label-text">
                                    Note
                                  </FormLabel>
                                  <ReactQuill
                                    className="react-quill-alignment"
                                    theme="snow"
                                    value={""}
                                    onChange={(e) => { }}
                                  />
                                </FormControl>
                              </Grid>
                            )}
                          {/* {isVitalParameters === 'Pulse' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setPulse(!pulse);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Pulse"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {isVitalParameters === "Pulse" &&
                            //  pulse && (
                            <Grid item xs={12} sm={12} md={6}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Pulse Rate
                                </FormLabel>
                                <OutlinedInput
                                  id="outlined-adornment-weight"
                                  size="small"
                                  endAdornment={<InputAdornment position="end">bpm</InputAdornment>}
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    'aria-label': 'weight',
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            // )
                          }
                          {isVitalParameters === "Pulse" &&
                            // pulse && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                            // )
                          }
                          {/* {isVitalParameters === 'Respiratory' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setRespiratory(!respiratory);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Respiratory"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {isVitalParameters === "Respiratory Rate" &&
                            //  respiratory && (
                            <Grid item xs={12} sm={12} md={6}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Respiratory Rate
                                </FormLabel>
                                <OutlinedInput
                                  id="outlined-adornment-weight"
                                  size="small"
                                  endAdornment={<InputAdornment position="end">/min</InputAdornment>}
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    'aria-label': 'weight',
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            // )
                          }
                          {isVitalParameters === "Respiratory Rate" &&
                            //  respiratory && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                            // )
                          }
                          {/* {isVitalParameters === 'SpO2' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setSpO2(!spO2);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="SpO2"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {isVitalParameters === "SpO2" &&
                            // spO2 && (
                            <Grid item xs={12} sm={12} md={6}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  SpO2
                                </FormLabel>
                                <OutlinedInput
                                  id="outlined-adornment-weight"
                                  size="small"
                                  endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    'aria-label': 'weight',
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            // )
                          }
                         {isVitalParameters === "SpO2" && <Grid item xs={6} sm={12} md={6} />}
                          {isVitalParameters === "SpO2" &&
                            // spO2 && (
                            <Grid item xs={12} sm={12} md={6}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Site of SPO2 measurement
                                </FormLabel>
                                <AsyncCustomSelect
                                  // tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                            // )
                          }
                          {isVitalParameters === "SpO2" &&
                            //  spO2 && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                            // )
                          }
                          {/* {isVitalParameters === 'Blood pressure' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setBloodPressure(!bloodPressure);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Blood pressure"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {isVitalParameters === "Blood pressure" &&
                            //  bloodPressure && (
                            <Grid item xs={6} sm={6} md={3}>
                              <BloodPresureInput
                                // label="Min/Max"
                                name="monthYear"
                                // value={values.monthYear}
                                onChange={() => { console.log('blood presure') }}
                              // error={errors.monthYear && touched.monthYear}
                              // helperText={touched.monthYear && errors.monthYear}
                              />
                            </Grid>
                            // )
                          }
                          {isVitalParameters === "Blood pressure" && <Grid item xs={4} sm={12} md={9} />}
                          {isVitalParameters === "Blood pressure" &&
                            //  bloodPressure && (
                            <Grid item xs={12} sm={12} md={6}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Site of Blood Presure measurement
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  // isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                            // )
                          }
                          {isVitalParameters === "Blood pressure" &&
                            //  bloodPressure && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                            // )
                          }
                        </Grid>
                      </Box>
                    </Formik>
                  ) : (
                    <Box>  <Divider />
                      <Box>This is summary section </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
                </DialogContent>
            </Dialog>

            <Dialog
                fullScreen
                open={openVitalGeneralExamination}
                onClose={handleClickOpenGeneralExamination}
            >
               <AppBar sx={{ position: 'relative' }}>
          <Toolbar
             sx={{ display:"flex",
             justifyContent:"space-between"}}>
            <Typography
             
              style={{ fontWeight: 600, margin: 1 }}
              sx={{
                paddingLeft:{
                  xs:1,
                  sm:0,
                }
              }}
              variant="h6"
            >
          {selected ? selected : "General Examination"}
            </Typography>
            <IconButton onClick={() => handleClickCloseGeneralExamination(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
              <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
            </IconButton>
          </Toolbar>
          <Divider />
        </AppBar>
                <DialogContent style={{ height: 400 }}>
          
                <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container"
                sx={{
                  border: "0px solid #0000001f",
                  borderRadius: 1,
                }}
              >
                <Box
                  style={{
                    flex: 1,
                    borderRadius: 8,
                    minHeight: 400,
                  }}
                >
                
                  {selected ? (
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                      
                        <Grid
                          container
                          item
                          spacing={2}
                        >
                          {/* {selected === "Built" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Built"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Built" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}

                          {selected === "Nourishment" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}

                          {selected === "Consciousness" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Orientation to time place and person" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Orientation to time place and person"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Orientation to time place and person" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Orientation to time place and person
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"state"}
                                FieldName={"state"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Orientation to time place and person" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Anemia/Pallor" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Anemia/Pallor"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Anemia/Pallor" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Cyanosis" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Cyanosis"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Cyanosis" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Clubbing" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Clubbing"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Clubbing" && <Grid item xs={12} sm={12} md={6}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Grade of clubbing
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={true}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Clubbing" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Jaundice" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Jaundice"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Jaundice" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Edema" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Edema"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Edema" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Edema
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"edmaState"}
                                FieldName={"edmaState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Edema" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Lymphadenopathy" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Lymphadenopathy
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"lymphadenopathyState"}
                                FieldName={"lymphadenopathyState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Lymphadenopathy" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Pigmentation" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Pigmentation"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Pigmentation" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Pigmentation
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"pigmentationState"}
                                FieldName={"pigmentationState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Pigmentation" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Any scar on body" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Any scar on body"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Any scar on body" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Any scar on body
                              </FormLabel>
                              <CustomSelect
                                isNotCreatable={true}
                                id={"scarState"}
                                FieldName={"scarState"}
                                options={addmissionState}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Any scar on body" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Any Swelling on body" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Any Swelling on body"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Any Swelling on body" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Any Swelling on body
                              </FormLabel>
                              <CustomSelect
                                isNotCreatable={true}
                                id={"swellingState"}
                                FieldName={"swellingState"}
                                options={addmissionState}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Any Swelling on body" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Lump" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Lump"
                              />
                            </FormGroup>
                          </Grid>}
                          {selected === "Lump" && <Grid item xs={8} sm={4} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Lump
                              </FormLabel>
                              <CustomSelect
                                isNotCreatable={true}
                                id={"state"}
                                FieldName={"state"}
                                options={countryData}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>} */}
                          {selected === "Lump" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Any other finding" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Any other finding"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Any other finding" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Scalp and Hair" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Scalp and Hair"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Scalp and Hair" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Scalp and Hair
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"scalpState"}
                                FieldName={"scalpState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Scalp and Hair" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Eyes" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Eyes"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Eyes" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Eyes
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"eyeState"}
                                FieldName={"eyeState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Eyes" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Ears" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Ears"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Ears" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Ears
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"earState"}
                                FieldName={"earState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Ears" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Nose" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Nose"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Nose" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Nose
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"noseState"}
                                FieldName={"noseState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Nose" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Mouth and Oral cavity" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Mouth and Oral cavity"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Mouth and Oral cavity" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Mouth and Oral cavity
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"mouthState"}
                                FieldName={"mouthState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Mouth and Oral cavity" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Tongue" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Tongue"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Tongue" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Tongue
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"tongueState"}
                                FieldName={"tongueState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Tongue" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Teeth" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Teeth"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Teeth" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Teeth
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"teethState"}
                                FieldName={"teethState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Teeth" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Neck and Neck Glands" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Neck and Neck Glands"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Neck and Neck Glands" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Neck and Neck Glands
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"neckState"}
                                FieldName={"neckState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Neck and Neck Glands" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Any visible/distended veins in Neck" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "JVP" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="JVP"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "JVP" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                JVP
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={true}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"jvpReason"}
                                isClearable={true}
                                FieldName={`jvpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "JVP" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Chest" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Chest"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Chest" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Chest
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"chestState"}
                                FieldName={"chestState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Chest" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Back" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Back"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Back" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Back
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"backState"}
                                FieldName={"backState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Back" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Abdomen" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Abdomen"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Abdomen" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Abdomen
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"abdomenState"}
                                FieldName={"abdomenState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Abdomen" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Upper extremities" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Upper extremities"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Upper extremities" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Upper extremities
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"upperExtremitiesState"}
                                FieldName={"upperExtremitiesState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Upper extremities" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Finger Nails" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Finger Nails"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Finger Nails" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Finger Nails
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"fingerNailsState"}
                                FieldName={"fingerNailsState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Finger Nails" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Lower extremities" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Lower extremities"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Lower extremities" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Lower extremities
                              </FormLabel>
                              <CustomSelect
                                isNotCreatable={true}
                                id={"lowerExtremitiesState"}
                                FieldName={"lowerExtremitiesState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Lower extremities" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Toe-Nails" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Toe-Nails"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Toe-Nails" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Toe-Nails
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"toeNailsState"}
                                FieldName={"toeNailsState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Toe-Nails" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Chest deformity" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Chest deformity"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Chest deformity" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Chest deformity
                              </FormLabel>
                              <CustomSelect
                                isNotCreatable={true}
                                id={"chestDeformityState"}
                                FieldName={"chestDeformityState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Chest deformity" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Chest deformity" && <Grid item xs={8} sm={4} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Chest deformity description
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>} */}
                          {/* {selected === "Limb deformity" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Limb deformity"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "Limb deformity" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Limb deformity
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"limbDeformityState"}
                                FieldName={"limbDeformityState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "Limb deformity" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "Limb deformity" && <Grid item xs={8} sm={4} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                              Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>} */}
                          {/* {selected === "External Genitalia" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="External Genitalia"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "External Genitalia" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                External Genitalia
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={" externalGenitaliaState"}
                                FieldName={"externalGenitaliaState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "External Genitalia" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                          {/* {selected === "SMR (Sexual Maturity rating)" && <Grid item xs={8} sm={4} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setSurgeryDoneInPast(!surgeryDoneInPast);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="SMR (Sexual Maturity rating)"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {selected === "SMR (Sexual Maturity rating)" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                SMR (Sexual Maturity rating)
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"smrState"}
                                FieldName={"smrState"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e)
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {selected === "SMR (Sexual Maturity rating)" && <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>}
                        </Grid>
                      </Box>
                    </Formik>
                  ) : (
                    <Box>  <Divider />
                      <Box>This is summary section </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
                </DialogContent>
            </Dialog>
            <Dialog
            fullScreen
            open={openVitalSummary}
            onClose={handleClickCloseVitalSummary}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
                <Typography
                  // className={selected ? "symptom-text" : ""}
                  style={{ fontWeight: 600, marginLeft: 0 }}
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                >
                   Vital Paramters Summary
                </Typography>
                <IconButton onClick={() => handleClickCloseVitalSummary(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                  <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent style={{ height: 400 }}>
            <Box className="template-table-container summary-dialog-mainContainer">
                      <Box className="summary-dialog-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
            </Box>
            </DialogContent>
          </Dialog>
          <Dialog
            fullScreen
            open={openGeneralSummary}
            onClose={handleClickCloseGeneralSummary}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
                <Typography
                  // className={selected ? "symptom-text" : ""}
                  style={{ fontWeight: 600, marginLeft: 0 }}
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                >
                   General Physical Examination Summary
                </Typography>
                <IconButton onClick={() => handleClickCloseGeneralSummary(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                  <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent style={{ height: 400 }}>
            <Box className="template-table-container summary-dialog-mainContainer">
                      <Box className="summary-dialog-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
            </Box>
            </DialogContent>
          </Dialog>
    </Box>
  );
}
