import React from 'react';
import Title from '../../../components/Title';
import {
  Box, Grid, IconButton, InputAdornment, TextField, Button, styled, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow, List,
  ListItem, ListItemButton, ListItemText, Tooltip, Popover, Alert,
  Pagination, Stack
} from '@mui/material';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate } from 'react-router-dom';
import '../../../theme/style.scss';
import BreadcrumbsComponent from '../../../components/BreadcrumbsComponent/BreadcrumbsComponent';
import CustomBreadcrumbs from '../../../components/BreadcrumbsComponent/CustomBreadcrumbs';

export default function MedicalRepresentativeList() {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const CustomTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: '#FAFBFD', // Set the desired background color
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.grey[300],
        borderWidth: '1px',
        BorderStyle: 'solid'
      },
      '&:hover fieldset': {
        borderColor: theme.palette.grey[500],
        borderWidth: '1px',
        BorderStyle: 'solid'
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: "2px",
      },
    },
  }));

  const handleOpenMore = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: any
  ) => {
    setAnchorEl(event.currentTarget);
    // setBuyerId(id);
  };

  const handleCloseMore = () => {
    setAnchorEl(null);
  };

  const rowData = [
    {
      name: "Alice Johnson",
      mrId: "MR12345",
      companyName: "Tech Solutions",
      city: "San Francisco",
      state: "California",
      mobileNo: "555-123-4567",
      email: "alice.johnson@example.com",
    },
    {
      name: "Bob Smith",
      mrId: "MR12345",
      companyName: "Innovate Inc.",
      city: "New York",
      state: "New York",
      mobileNo: "555-987-6543",
      email: "bob.smith@example.com",
    },
    {
      name: "Carol Martinez",
      mrId: "MR12345",
      companyName: "HealthCorp",
      city: "Miami",
      state: "Florida",
      mobileNo: "555-456-7890",
      email: "carol.martinez@example.com",
    },
    {
      name: "David Lee",
      companyName: "FinancePros",
      mrId: "MR12345",
      city: "Chicago",
      state: "Illinois",
      mobileNo: "555-234-5678",
      email: "david.lee@example.com",
    },
  ];

  function EnhancedTableHead(props: EnhancedTableProps) {
    return (
      <TableHead className='table-header-color'>
        <TableRow>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "15%"
            }}
            align="left"
          >
            Name
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "15%"
            }}
            align="left"
          >
            Company Name
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "20%"
            }}
            align="left"
          >
            Address
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "15%"
            }}
            align="left"
          >
            Mobile No
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "20%"
            }}
            align="left"
          >
            Email
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "15%"
            }}
            align="left"
          >
            {''}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  return (
    <>
      <Box className='main-container'>
        <Grid xs={12} container className='page-header'>
          <Grid xs={8} sm={6} item className="page-breadcrumbs">
            <CustomBreadcrumbs />
          </Grid>
          <Grid xs={4} sm={6} item className='page-header-icon'>
            <Button
              color="primary"
              variant="contained"
              onClick={() => navigate('medical-representative-save')}
              size="medium"
              startIcon={<AddIcon />}
            >
              Add
            </Button>
          </Grid>
        </Grid>

        <Grid container className="list-container" sx={{ padding: '16px' }}>
          <Grid item xs={12} sm={4} md={3} lg={2} className='search-bar' sx={{ gap: 2 }} >
            <CustomTextField
              placeholder="Search..."
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              sx={{ paddingLeft: '0px' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" >
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} className='search-bar pdf-icon'>
            <img src="./xls.png" alt="" className='pdf-icon-hover' />
            <img src="./pdf.png" alt="" className='pdf-icon-hover' />
          </Grid>

          <TableContainer className='table'>
            <Table>
              <EnhancedTableHead />
              <TableBody>
                {rowData.map((item) => (
                  <TableRow key={item.mrId}>
                    <TableCell>
                      <Link className='text-link doctor-name-link' to="medical-representative-save">
                        {item.name}
                      </Link>
                      <div>{item.mrId}</div>
                    </TableCell>
                    <TableCell>{item.companyName}</TableCell>
                    <TableCell>{`${item.city}, ${item.state}`}</TableCell>
                    <TableCell>{item.mobileNo}</TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell align="right">
                      <Tooltip title="More" placement="top">
                        <IconButton
                          onClick={(e: any) => handleOpenMore(e)}
                        >
                          <MoreVertRoundedIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>  
          </TableContainer>

          <Grid
            container
            paddingTop={1}
            className='pagination'
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid item xs={12} sm={6} className="pagination-label">
              1 of 1
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack component="div" className='pagination'>
                <Pagination color="primary" />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Popover
        elevation={1}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseMore}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('medical-representative-save')}>
              <ListItemText primary="Edit" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton className="delete-container">
              Delete
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </>
  );
}
