import * as React from "react";
import { BootstrapTooltip } from "../../../components/Common/BootstrapTooltip";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Grid,
  Collapse,
  Tooltip,
  Button,
  Typography,
  Avatar,
  Menu,
  useMediaQuery,
  SwipeableDrawer,
  Divider,
} from "@mui/material";
import CustomBreadcrumbs from '../../../components/BreadcrumbsComponent/CustomBreadcrumbs';
import MenuIcon from "@mui/icons-material/Menu";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MenuOpenOutlinedIcon from "@mui/icons-material/MenuOpenOutlined";
import Zoom from "@mui/material/Zoom";
import HmisLogo from "../../../components/CustomButton/HmisLogo/HmisLogo";
import { useLocation, useNavigate } from "react-router-dom";
import "./navbardrawer.scss";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AutoAwesomeMosaicOutlinedIcon from "@mui/icons-material/AutoAwesomeMosaicOutlined";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import GroupsIcon from "@mui/icons-material/Groups";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpIcon from "@mui/icons-material/Help";
import BusinessIcon from "@mui/icons-material/Business";
import LogoutIcon from "@mui/icons-material/Logout";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import FrontDeskOutlineIcon from "../../../assets/images/menu-icons/reception-desk-outline.png";
import OPDOutlineIcon from "../../../assets/images/menu-icons/stethoscope-outline.png";
import PersonIcon from "@mui/icons-material/Person";
import { useTheme } from "@mui/material/styles";

// =================Navbar Drawar============================
const drawerWidth = 210;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: "0.15s",
  }),
  overflowX: "hidden",
});
const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: "0.15s",
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));
// const PAGE_SIZE = 15

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// =============END Drawar============================

// For trial account

export default function Nav(props: any) {
  //  const [openNew, setopenNew] = React.useState(false);
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const [openMasterNull, setOpenMasterNull] = React.useState(false);
  const [openFrontDesk, setOpenFrontDesk] = React.useState(false);
  const [openOPD, setOpenOPD] = React.useState(false);
  const [openPatient, setOpenPatient] = React.useState(false);
  const [openDoctor, setOpenDoctor] = React.useState(false);
  const [openAccounts, setOpenAccounts] = React.useState(false);
  const [openTeam, setOpenTeam] = React.useState(false);
  const [state, setState] = React.useState({ left: false });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const matches = useMediaQuery("(max-width:1024px)");
  const smallScreen = useMediaQuery("(max-width:767px)");
  const mobileScreen = useMediaQuery("(max-width:425px)");

  const handleClickDashboard = () => {
    navigate("/dashboard");
    setOpenFrontDesk(false);
    setNewOpen(true)
    setOpenAccounts(false);
    setOpenOPD(false);
    setOpenPatient(false);
    setOpenTeam(false);
    setOpenDoctor(false);
  };

  const handleClickMaster = () => {
    navigate("/master");
    setOpenFrontDesk(false);
    setNewOpen(true)
    setOpenAccounts(false);
    setOpenOPD(false);
    setOpenPatient(false);
    setOpenTeam(false);
    setOpenDoctor(false);
  };

  const handleClickConsumer = () => {
    navigate("/consumer");
    setOpenFrontDesk(false);
    setNewOpen(true)
    setOpenAccounts(false);
    setOpenOPD(false);
    setOpenPatient(false);
    setOpenTeam(false);
    setOpenDoctor(false);
  };

  const handleClickReport = () => {
    navigate("/report");
  };

  type Anchor = "top" | "left" | "bottom" | "right"

  const toggleDrawer =
    (anchor: any, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event &&
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }

        let page = event !== undefined ? event.target.innerText : null;
        setNewOpen(true)

        switch (page) {
          case "Dashboard": {
            navigate("/dashboard");
            break;
          }
          case "Master": {
            navigate("/master");
            break;
          }
          case "Consumer": {
            navigate("/consumer");
            break;
          }
          case "Add Appointments": {
            navigate("/add-appointment");
            break;
          }
          case "Appointments": {
            navigate("/reception-appointments");
            break;
          }
          case "OPD Clinical": {

            navigate("/opd-clinical");
            break;
          }
          case "Certificates": {
            navigate("/certificates");
            break;
          }
          case "Patients": {
            navigate("/patient-master");
            break;
          }

          case "Patient History": {
            navigate("/patient-history");
            break;
          }
          case "Doctors": {
            navigate("/doctors");
            break;
          }
          case "Referral Doctors": {
            navigate("/referral-doctors");
            break;
          }
          case "Visiting Doctors": {
            navigate("/visiting-doctors");
            break;
          }
          case "Outreach Visits": {
            navigate("/outreach-visits");
            break;
          }
          case "Doctor Schedule": {
            navigate("/doctor-schedule");
            break;
          }
          case "OPD Billing": {
            navigate("/opd-billing");
            break;
          }
          case "Staff": {
            navigate("/staff");
            break;
          }
          case "Users": {
            navigate("/users");
            break;
          }
          default: {
          }
        }
        setState({ ...state, [anchor]: open });
      };

  const handleDrawerBtn = () => {
    if (openNew) {
      handleDrawerClose();
    } else {
      handleopenNew();
    }
    setOpenFrontDesk(false);
    setOpenAccounts(false);
    setOpenOPD(false);
    setOpenPatient(false);
    setOpenTeam(false);
    setOpenDoctor(false);
  };

  const handleClickMasterNull = () => {
    setOpenMasterNull(openMasterNull);
  };


  const handleClickFrontDesk = () => {
    setOpenFrontDesk(!openFrontDesk);
    setNewOpen(true)
    setOpenAccounts(false);
    setOpenOPD(false);
    setOpenPatient(false);
    setOpenTeam(false);
    setOpenDoctor(false);
  };

  const handleClickOPD = () => {
    setOpenOPD(!openOPD);
    setNewOpen(true)
    setOpenPatient(false);
    setOpenAccounts(false);
    setOpenDoctor(false);
    setOpenTeam(false);
    setOpenFrontDesk(false);
  };

  const handleClickPatient = () => {
    setOpenPatient(!openPatient);
    setOpenOPD(false);
    setOpenDoctor(false);
    setOpenAccounts(false);
    setNewOpen(true)
    setOpenTeam(false);
    setOpenFrontDesk(false);
  };

  const handleClickDoctor = () => {
    setOpenDoctor(!openDoctor);
    setNewOpen(true)
    setOpenPatient(false);
    setOpenOPD(false);
    setOpenTeam(false);
    setOpenAccounts(false);
    setOpenFrontDesk(false);
  };

  const handleClickAccounts = () => {
    setOpenAccounts(!openAccounts);
    setOpenDoctor(false);
    setNewOpen(true)
    setOpenPatient(false);
    setOpenOPD(false);
    setOpenTeam(false);
    setOpenFrontDesk(false);
  };

  const handleClickTeam = () => {
    setOpenTeam(!openTeam);
    setOpenAccounts(false);
    setOpenDoctor(false);
    setNewOpen(true)
    setOpenPatient(false);
    setOpenOPD(false);
    setOpenFrontDesk(false);
  };
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const spanRef = React.useRef(null);
  const open4 = Boolean(anchorEl4);

  const profieClick = (any) => {
    setAnchorEl4(spanRef.current);
  };

  const profileClose = (page: string) => {
    switch (page) {
      case "My Profile": {
        setAnchorEl4(null);
        navigate("/profile");
        break;
      }
      case "Sign out": {
        setAnchorEl4(null);

        navigate("/");
        break;
      }
      default: {
        setAnchorEl4(null);
      }
    }
    setAnchorEl4(null);
  };

  const [openNew, setNewOpen] = React.useState(false);

  const handleopenNew = () => {
    setNewOpen(true);
  };

  const handleDrawerClose = () => {
    setNewOpen(false);
    // setOpenMaster(false);
    // setOpenDetail(false);
    // setOpenSale(false);
    // setOpenStock(false);
    // setOpenTeam(false);
    // setopenReport(false);
    // setOpenAccounting(false);
  };

  // const handleDrawerBtn = () => {

  // };

  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const hide = () => setAnchorEl2(null);

  const dashboardClick = () => {
    if (matches) {
      setState({ left: false });
    }
    navigate("/dashboard");
    setOpenFrontDesk(false);
    setNewOpen(true)
    setOpenAccounts(false);
    setOpenOPD(false);
    setOpenPatient(false);
    setOpenTeam(false);
    setOpenDoctor(false);
  };

  const consumerClick = () => {
    if (matches) {
      setState({ left: false });
    }
    navigate("/consumer");
    setOpenFrontDesk(false);
    setNewOpen(true)
    setOpenAccounts(false);
    setOpenOPD(false);
    setOpenPatient(false);
    setOpenTeam(false);
    setOpenDoctor(false);
  };

  const masterClick = () => {
    if (matches) {
      setState({ left: false });
    }
    navigate("/master");
    setOpenFrontDesk(false);
    setNewOpen(true)
    setOpenAccounts(false);
    setOpenOPD(false);
    setOpenPatient(false);
    setOpenTeam(false);
    setOpenDoctor(false);
  };

  const settingClick = () => {
    if (matches) {
      //  console.log("object click to redirect")
      setState({ left: false });
      navigate("/settings");
      setOpenFrontDesk(false);
      setNewOpen(true)
      setOpenAccounts(false);
      setOpenOPD(false);
      setOpenPatient(false);
      setOpenTeam(false);
      setOpenDoctor(false);
    }
  }


  //Mobile  View Data

  const list = (anchor: Anchor) => (
    <List style={{ height: "50vh", width: "33vh" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          marginTop: "7px",
          marginLeft: "14px",
          marginBottom: "7px",
        }}
      >
        <span className="logo">Logo</span>
        {/* <BillingLogo className="logo" size={45} /> */}
      </Box>
      <Divider />
      <span
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100%",
          paddingTop: 26
        }}
      >
        <span>
          <ListItem
            key={"Dashboard"}
            disablePadding
            className={
              "/dashboard" === pathname
                ? "active-master-class"
                : "nav-ListItemButton"
            }
            sx={{ display: "block" }}
            onClick={dashboardClick}
          >
            <ListItemButton
              sx={{
                minHeight: 24,
                justifyContent: openNew ? "initial" : "center",
                px: 1.3,
                "&:hover": {
                  backgroundColor: "#3b75ff",
                  color: "#ffffff",
                },
              }}
              onClick={dashboardClick}
            >
              <ListItemIcon
                className="nav-ListItemButton"
                sx={{
                  minWidth: 0,
                  mr: openNew ? 3 : "auto",
                  justifyContent: "center",
                }}
                onClick={dashboardClick}
              >
                {openNew ? (
                  <span className="menu-icon-span">
                    <HomeOutlinedIcon className="menu-icon" />
                  </span>
                ) : (
                  <BootstrapTooltip
                    TransitionComponent={Zoom}
                    title={
                      <React.Fragment>
                        <span style={{ fontSize: "12px" }}>
                          Dashboard
                        </span>
                      </React.Fragment>
                    }
                    placement="right"
                  >
                    <span className="menu-icon-span">
                      <HomeOutlinedIcon
                        className="menu-icon"
                      // onClick={() => {
                      //   Handlemenu(1);
                      // }}
                      />
                    </span>
                  </BootstrapTooltip>
                )}
              </ListItemIcon>
              <ListItemText
                primary={"Dashboard"}
                onClick={dashboardClick}
                className="menu-item-text"
                sx={{ opacity: openNew ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem
            key={"Consumer"}
            disablePadding
            className={
              "/consumer" === pathname
                ? "active-master-class"
                : "nav-ListItemButton"
            }
            sx={{ display: "block" }}
            onClick={consumerClick}
          >
            <ListItemButton
              sx={{
                minHeight: 24,
                justifyContent: openNew ? "initial" : "center",
                px: 1.3,
                "&:hover": {
                  backgroundColor: "#3b75ff",
                  color: "#ffffff",
                },
              }}
              onClick={consumerClick}
            >
              <ListItemIcon
                className="nav-ListItemButton"
                sx={{
                  minWidth: 0,
                  mr: openNew ? 3 : "auto",
                  justifyContent: "center",
                }}
                onClick={consumerClick}
              >
                {openNew ? (
                  <span className="menu-icon-span">
                    <PersonIcon className="menu-icon" />
                  </span>
                ) : (
                  <BootstrapTooltip
                    TransitionComponent={Zoom}
                    title={
                      <React.Fragment>
                        <span style={{ fontSize: "12px" }}>
                          Consumer
                        </span>
                      </React.Fragment>
                    }
                    placement="right"
                  >
                    <span className="menu-icon-span">
                      <PersonIcon
                        className="menu-icon"
                      // onClick={() => {
                      //   Handlemenu(1);
                      // }}
                      />
                    </span>
                  </BootstrapTooltip>
                )}
              </ListItemIcon>
              <ListItemText
                primary={"Consumer"}
                onClick={consumerClick}
                sx={{ opacity: openNew ? 1 : 0 }}
                className="menu-item-text"
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            key={"FrontDesk"}
            disablePadding
            className="nav-ListItemButton"
            sx={{ display: "block" }}
          >
            <ListItemButton
              onClick={
                openNew
                  ? handleClickFrontDesk
                  : handleClickMasterNull
              }
              sx={{
                minHeight: 24,
                justifyContent: openNew ? "initial" : "center",
                px: 1.3,
                "&:hover": {
                  backgroundColor: "#3b75ff",
                  color: "#ffffff",
                },
              }}
            >
              <ListItemIcon
                className="nav-ListItemButton"
                onClick={handleClickFrontDesk}
                sx={{
                  minWidth: 0,
                  mr: openNew ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {openNew ? (
                  <span className="menu-icon-span">
                    <img
                      src={FrontDeskOutlineIcon}
                      alt="menu icon"
                      height={25}
                      width={25}
                    />
                    {/* <AssignmentIndOutlinedIcon
                                  className={
                                    "/patient-master" === pathname ||
                                    "/patient-history" === pathname
                                      ? "active-master-class-icon menu-icon"
                                      : "menu-icon"
                                  }
                                /> */}
                  </span>
                ) : (
                  <BootstrapTooltip
                    TransitionComponent={Zoom}
                    title={
                      <React.Fragment>
                        <span style={{ fontSize: "12px" }}>
                          Front Desk
                        </span>
                      </React.Fragment>
                    }
                    placement="right"
                  >
                    <span className="menu-icon-span">
                      <img
                        src={FrontDeskOutlineIcon}
                        alt="menu icon"
                        height={25}
                        width={25}
                      />
                      {/* <AssignmentIndOutlinedIcon
                                    className={
                                      "/patient-master" === pathname ||
                                      "/patient-history" === pathname
                                        ? "active-master-class-icon menu-icon"
                                        : "menu-icon"
                                    }
                                  /> */}
                    </span>
                  </BootstrapTooltip>
                )}
              </ListItemIcon>

              <ListItemText
                primary="Front Desk"
                sx={{ opacity: openNew ? 1 : 0 }}
                className={
                  "/add-mr" === pathname ||
                    "/add-appointment" === pathname ||
                    "/reception-appointments" === pathname
                    ? "active-master-class-icon menu-item-text"
                    : "menu-item-text"
                }
              />
              {openPatient ? (
                <ExpandLess
                  sx={{
                    "&:hover": {
                      backgroundColor: "#3b75ff",
                      color: "#ffffff",
                    },
                  }}
                  className={
                    "/add-mr" === pathname ||
                      "/add-appointment" === pathname ||
                      "/reception-appointments" === pathname
                      ? "active-master-class-icon menu-item-icon-more"
                      : "menu-item-icon-more"
                  }
                />
              ) : (
                <ExpandMore
                  className={
                    "/add-mr" === pathname ||
                      "/add-appointment" === pathname ||
                      "/reception-appointments" === pathname
                      ? "active-master-class-icon menu-item-icon-more"
                      : "menu-item-icon-more"
                  }
                />
              )}
            </ListItemButton>
            <Collapse in={openFrontDesk} timeout="auto" unmountOnExit>
              <List component="div" disablePadding className="">
                <ListItemButton
                  className={
                    "/add-appointment" === pathname
                      ? "active-master-class sub-menu"
                      : "sub-menu"
                  }
                  sx={{
                    pl: 4,
                    "&:hover": {
                      backgroundColor: "#3b75ff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={toggleDrawer("left", false)}
                >
                  <ListItemText primary="Add Appointments" />
                </ListItemButton>
                <ListItemButton
                  className={
                    "/reception-appointments" === pathname
                      ? "active-master-class sub-menu"
                      : "sub-menu"
                  }
                  sx={{
                    pl: 4,
                    "&:hover": {
                      backgroundColor: "#3b75ff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={toggleDrawer("left", false)}
                >
                  <ListItemText primary="Appointments" />
                </ListItemButton>
              </List>
            </Collapse>
          </ListItem>
          <ListItem
            key={"OPD"}
            disablePadding
            className="nav-ListItemButton"
            sx={{ display: "block" }}
          >
            <ListItemButton
              onClick={
                openNew ? handleClickOPD : handleClickMasterNull
              }
              sx={{
                minHeight: 24,
                justifyContent: openNew ? "initial" : "center",
                px: 1.3,
                "&:hover": {
                  backgroundColor: "#3b75ff",
                  color: "#ffffff",
                },
              }}
            >
              <ListItemIcon
                className="nav-ListItemButton"
                onClick={handleClickOPD}
                sx={{
                  minWidth: 0,
                  mr: openNew ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {openNew ? (
                  <span className="menu-icon-span">
                    <img
                      src={OPDOutlineIcon}
                      alt="menu icon"
                      height={25}
                      width={25}
                    />
                    {/* <AssignmentIndOutlinedIcon
                                  className={
                                    "/patient-master" === pathname ||
                                    "/patient-history" === pathname
                                      ? "active-master-class-icon menu-icon"
                                      : "menu-icon"
                                  }
                                /> */}
                  </span>
                ) : (
                  <BootstrapTooltip
                    TransitionComponent={Zoom}
                    title={
                      <React.Fragment>
                        <span style={{ fontSize: "12px" }}>
                          OPD
                        </span>
                      </React.Fragment>
                    }
                    placement="right"
                  >
                    <span className="menu-icon-span">
                      <img
                        src={OPDOutlineIcon}
                        alt="menu icon"
                        height={25}
                        width={25}
                      />
                      {/* <AssignmentIndOutlinedIcon
                                    className={
                                      "/patient-master" === pathname ||
                                      "/patient-history" === pathname
                                        ? "active-master-class-icon menu-icon"
                                        : "menu-icon"
                                    }
                                  /> */}
                    </span>
                  </BootstrapTooltip>
                )}
              </ListItemIcon>

              <ListItemText
                primary="OPD"
                sx={{ opacity: openNew ? 1 : 0 }}
                className={
                  "/opd-clinical" === pathname ||
                    "/certificates" === pathname
                    ? "active-master-class-icon menu-item-text"
                    : "menu-item-text"
                }
              />
              {openPatient ? (
                <ExpandLess
                  sx={{
                    "&:hover": {
                      backgroundColor: "#3b75ff",
                      color: "#ffffff",
                    },
                  }}
                  className={
                    "/opd-clinical" === pathname ||
                      "/certificates" === pathname
                      ? "active-master-class-icon menu-item-icon-more"
                      : "menu-item-icon-more"
                  }
                />
              ) : (
                <ExpandMore
                  className={
                    "/opd-clinical" === pathname ||
                      "/certificates" === pathname
                      ? "active-master-class-icon menu-item-icon-more"
                      : "menu-item-icon-more"
                  }
                />
              )}
            </ListItemButton>
            <Collapse in={openOPD} timeout="auto" unmountOnExit>
              <List component="div" disablePadding className="">
                <ListItemButton
                  className={
                    "/opd-clinical" === pathname
                      ? "active-master-class sub-menu"
                      : "sub-menu"
                  }
                  sx={{
                    pl: 4,
                    "&:hover": {
                      backgroundColor: "#3b75ff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={toggleDrawer("left", false)}
                >
                  <ListItemText primary="OPD Clinical" />
                </ListItemButton>
                <ListItemButton
                  className={
                    "/certificates" === pathname
                      ? "active-master-class sub-menu"
                      : "sub-menu"
                  }
                  sx={{
                    pl: 4,
                    "&:hover": {
                      backgroundColor: "#3b75ff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={toggleDrawer("left", false)}
                >
                  <ListItemText primary="Certificates" />
                </ListItemButton>
              </List>
            </Collapse>
          </ListItem>

          <ListItem
            key={"Patient"}
            disablePadding
            className="nav-ListItemButton"
            sx={{ display: "block" }}
          >
            <ListItemButton
              onClick={
                openNew
                  ? handleClickPatient
                  : handleClickMasterNull
              }
              sx={{
                minHeight: 24,
                justifyContent: openNew ? "initial" : "center",
                px: 1.3,
                "&:hover": {
                  backgroundColor: "#3b75ff",
                  color: "#ffffff",
                },
              }}
            >
              <ListItemIcon
                className="nav-ListItemButton"
                onClick={handleClickPatient}
                sx={{
                  minWidth: 0,
                  mr: openNew ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {openNew ? (
                  <span className="menu-icon-span">
                    <AssignmentIndOutlinedIcon
                      className={
                        "/patient-master" === pathname ||
                          "/patient-history" === pathname
                          ? "active-master-class-icon menu-icon"
                          : "menu-icon"
                      }
                    />
                  </span>
                ) : (
                  <BootstrapTooltip
                    TransitionComponent={Zoom}
                    title={
                      <React.Fragment>
                        <span style={{ fontSize: "12px" }}>
                          Patient
                        </span>
                      </React.Fragment>
                    }
                    placement="right"
                  >
                    <span className="menu-icon-span">
                      <AssignmentIndOutlinedIcon
                        className={
                          "/patient-master" === pathname ||
                            "/patient-history" === pathname
                            ? "active-master-class-icon menu-icon"
                            : "menu-icon"
                        }
                      />
                    </span>
                  </BootstrapTooltip>
                )}
              </ListItemIcon>

              <ListItemText
                primary="Patient"
                sx={{ opacity: openNew ? 1 : 0 }}
                className={
                  "/patient-master" === pathname ||
                    "/patient-history" === pathname
                    ? "active-master-class-icon menu-item-text"
                    : "menu-item-text"
                }
              />
              {openPatient ? (
                <ExpandLess
                  sx={{
                    "&:hover": {
                      backgroundColor: "#3b75ff",
                      color: "#ffffff",
                    },
                  }}
                  className={
                    "/patient-master" === pathname ||
                      "/patient-history" === pathname
                      ? "active-master-class-icon menu-item-icon-more"
                      : "menu-item-icon-more"
                  }
                />
              ) : (
                <ExpandMore
                  className={
                    "/patient-master" === pathname ||
                      "/patient-history" === pathname
                      ? "active-master-class-icon menu-item-icon-more"
                      : "menu-item-icon-more"
                  }
                />
              )}
            </ListItemButton>
            <Collapse in={openPatient} timeout="auto" unmountOnExit>
              <List component="div" disablePadding className="">
                <ListItemButton
                  className={
                    "/patient-master" === pathname
                      ? "active-master-class sub-menu"
                      : "sub-menu"
                  }
                  sx={{
                    pl: 4,
                    "&:hover": {
                      backgroundColor: "#3b75ff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={toggleDrawer("left", false)}
                >
                  <ListItemText primary="Patient" />
                </ListItemButton>
                <ListItemButton
                  className={
                    "/patient-history" === pathname
                      ? "active-master-class sub-menu"
                      : "sub-menu"
                  }
                  sx={{
                    pl: 4,
                    "&:hover": {
                      backgroundColor: "#3b75ff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={toggleDrawer("left", false)}
                >
                  <ListItemText primary="Patient History" />
                </ListItemButton>
              </List>
            </Collapse>
          </ListItem>
          <ListItem
            key={"Team"}
            disablePadding
            className="nav-ListItemButton"
            sx={{ display: "block" }}
          >
            <ListItemButton
              onClick={
                openNew
                  ? handleClickTeam
                  : handleClickMasterNull
              }
              sx={{
                minHeight: 24,
                justifyContent: openNew ? "initial" : "center",
                px: 1.3,
                "&:hover": {
                  backgroundColor: "#3b75ff",
                  color: "#ffffff",
                },
              }}
            >
              <ListItemIcon
                className="nav-ListItemButton"
                onClick={handleClickTeam}
                sx={{
                  minWidth: 0,
                  mr: openNew ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {openNew ? (
                  <span className="menu-icon-span">
                    <GroupsIcon
                      className={
                        "/staff" === pathname ||
                          "/users" === pathname
                          ? "active-master-class-icon menu-icon"
                          : "menu-icon"
                      }
                    />
                  </span>
                ) : (
                  <BootstrapTooltip
                    TransitionComponent={Zoom}
                    title={
                      <React.Fragment>
                        <span style={{ fontSize: "12px" }}>
                          Team
                        </span>
                      </React.Fragment>
                    }
                    placement="right"
                  >
                    <span className="menu-icon-span">
                      <GroupsIcon
                        className={
                          "/staff" === pathname ||
                            "/users" === pathname
                            ? "active-master-class-icon menu-icon"
                            : "menu-icon"
                        }
                      />
                    </span>
                  </BootstrapTooltip>
                )}
              </ListItemIcon>

              <ListItemText
                primary="Team"
                sx={{ opacity: openNew ? 1 : 0 }}
                className={
                  "/staff" === pathname ||
                    "/users" === pathname
                    ? "active-master-class-icon menu-item-text"
                    : "menu-item-text"
                }
              />
              {openPatient ? (
                <ExpandLess
                  sx={{
                    "&:hover": {
                      backgroundColor: "#3b75ff",
                      color: "#ffffff",
                    },
                  }}
                  className={
                    "/staff" === pathname ||
                      "/users" === pathname
                      ? "active-master-class-icon menu-item-icon-more"
                      : "menu-item-icon-more"
                  }
                />
              ) : (
                <ExpandMore
                  className={
                    "/staff" === pathname ||
                      "/users" === pathname
                      ? "active-master-class-icon menu-item-icon-more"
                      : "menu-item-icon-more"
                  }
                />
              )}
            </ListItemButton>
            <Collapse in={openTeam} timeout="auto" unmountOnExit>
              <List component="div" disablePadding className="">
                <ListItemButton
                  className={
                    "/staff" === pathname
                      ? "active-master-class sub-menu"
                      : "sub-menu"
                  }
                  sx={{
                    pl: 4,
                    "&:hover": {
                      backgroundColor: "#3b75ff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={toggleDrawer("left", false)}
                >
                  <ListItemText primary="Staff" />
                </ListItemButton>
                <ListItemButton
                  className={
                    "/users" === pathname
                      ? "active-master-class sub-menu"
                      : "sub-menu"
                  }
                  sx={{
                    pl: 4,
                    "&:hover": {
                      backgroundColor: "#3b75ff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={toggleDrawer("left", false)}
                >
                  <ListItemText primary="Users" />
                </ListItemButton>
              </List>
            </Collapse>
          </ListItem>

          <ListItem
            key={"Accounts"}
            disablePadding
            className="nav-ListItemButton"
            sx={{ display: "block" }}
          >
            <ListItemButton
              onClick={
                openNew
                  ? handleClickAccounts
                  : handleClickMasterNull
              }
              sx={{
                minHeight: 24,
                justifyContent: openNew ? "initial" : "center",
                px: 1.3,
                "&:hover": {
                  backgroundColor: "#3b75ff",
                  color: "#ffffff",
                },
              }}
            >
              <ListItemIcon
                className="nav-ListItemButton"
                onClick={handleClickAccounts}
                sx={{
                  minWidth: 0,
                  mr: openNew ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {openNew ? (
                  <span className="menu-icon-span">
                    <AccountBalanceIcon
                      className={
                        "/opd-billing" === pathname
                          ? "active-master-class-icon menu-icon"
                          : "menu-icon"
                      }
                    />
                  </span>
                ) : (
                  <BootstrapTooltip
                    TransitionComponent={Zoom}
                    title={
                      <React.Fragment>
                        <span style={{ fontSize: "12px" }}>
                          Accounts
                        </span>
                      </React.Fragment>
                    }
                    placement="right"
                  >
                    <span className="menu-icon-span">

                      <AccountBalanceIcon
                        className={
                          "/opd-billing" === pathname
                            ? "active-master-class-icon menu-icon"
                            : "menu-icon"
                        }
                      />
                    </span>
                  </BootstrapTooltip>
                )}
              </ListItemIcon>

              <ListItemText
                primary="Accounts"
                sx={{ opacity: openNew ? 1 : 0 }}
                className={
                  "/opd-billing" === pathname
                    ? "active-master-class-icon menu-item-text"
                    : "menu-item-text"
                }
              />
              {openPatient ? (
                <ExpandLess
                  sx={{
                    "&:hover": {
                      backgroundColor: "#3b75ff",
                      color: "#ffffff",
                    },
                  }}
                  className={
                    "/opd-billing" === pathname
                      ? "active-master-class-icon menu-item-icon-more"
                      : "menu-item-icon-more"
                  }
                />
              ) : (
                <ExpandMore
                  className={
                    "/opd-billing" === pathname
                      ? "active-master-class-icon menu-item-icon-more"
                      : "menu-item-icon-more"
                  }
                />
              )}
            </ListItemButton>
            <Collapse in={openAccounts} timeout="auto" unmountOnExit>
              <List component="div" disablePadding className="">
                <ListItemButton
                  className={
                    "/opd-billing" === pathname
                      ? "active-master-class sub-menu"
                      : "sub-menu"
                  }
                  sx={{
                    pl: 4,
                    "&:hover": {
                      backgroundColor: "#3b75ff",
                      color: "#ffffff",
                    },
                  }}
                  onClick={toggleDrawer("left", false)}
                >
                  <ListItemText primary="OPD Billing" />
                </ListItemButton>
              </List>
            </Collapse>
          </ListItem>
          <ListItem
            key={"Master"}
            disablePadding
            className={
              "/master" === pathname
                ? "active-master-class"
                : "nav-ListItemButton"
            }
            sx={{ display: "block" }}
            onClick={masterClick}
          >
            <ListItemButton
              sx={{
                minHeight: 24,
                justifyContent: openNew ? "initial" : "center",
                px: 1.3,
                "&:hover": {
                  backgroundColor: "#3b75ff",
                  color: "#ffffff",
                },
              }}
            >
              <ListItemIcon
                className="nav-ListItemButton"
                sx={{
                  minWidth: 0,
                  mr: openNew ? 3 : "auto",
                  justifyContent: "center",
                }}
                onClick={masterClick}
              >
                {openNew ? (
                  <span className="menu-icon-span">
                    <AutoAwesomeMosaicOutlinedIcon className="menu-icon" />
                  </span>
                ) : (
                  <BootstrapTooltip
                    TransitionComponent={Zoom}
                    title={
                      <React.Fragment>
                        <span style={{ fontSize: "12px" }}>
                          Master
                        </span>
                      </React.Fragment>
                    }
                    placement="right"
                  >
                    <span className="menu-icon-span">
                      <AutoAwesomeMosaicOutlinedIcon
                        className="menu-icon"
                      // onClick={() => {
                      //   Handlemenu(1);
                      // }}
                      />
                    </span>
                  </BootstrapTooltip>
                )}
              </ListItemIcon>
              <ListItemText
                primary={"Master"}
                onClick={masterClick}
                sx={{ opacity: openNew ? 1 : 0 }}
                className="menu-item-text"
              />
            </ListItemButton>
          </ListItem>

          <ListItem
            key={"Settings"}
            disablePadding
            className={
              "/settings" === pathname
                ? "active-master-class"
                : "nav-ListItemButton"
            }
            sx={{ display: "block" }}
          >
            <ListItemButton
              sx={{
                minHeight: 24,
                justifyContent: openNew ? "initial" : "center",
                px: 1.3,
                "&:hover": {
                  backgroundColor: "#3b75ff",
                  color: "#ffffff",
                },
              }}
            >
              <ListItemIcon
                className="nav-ListItemButton"
                sx={{
                  minWidth: 0,
                  mr: openNew ? 3 : "auto",
                  justifyContent: "center",
                }}
                onClick={settingClick}
              >
                {openNew ? (
                  <span className="menu-icon-span">
                    <SettingsIcon className="menu-icon" />
                  </span>
                ) : (
                  <BootstrapTooltip
                    TransitionComponent={Zoom}
                    title={
                      <React.Fragment>
                        <span style={{ fontSize: "12px" }}>
                          Settings
                        </span>
                      </React.Fragment>
                    }
                    placement="right"
                  >
                    <span className="menu-icon-span">
                      <SettingsIcon
                        className="menu-icon"
                      // onClick={() => {
                      //   Handlemenu(1);
                      // }}
                      />
                    </span>
                  </BootstrapTooltip>
                )}</ListItemIcon>
              <ListItemText
                primary={"Settings"}
                onClick={settingClick}
                sx={{ opacity: openNew ? 1 : 0 }}
                className="menu-item-text"
              />
            </ListItemButton>

          </ListItem>

          <ListItem
            key={"Help"}
            disablePadding
            className={
              "/help " === pathname
                ? "active-master-class"
                : "nav-ListItemButton"
            }
            sx={{ display: "block" }}
          >
            <ListItemButton
              sx={{
                minHeight: 24,
                justifyContent: openNew ? "initial" : "center",
                px: 1.3,
                "&:hover": {
                  backgroundColor: "#3b75ff",
                  color: "#ffffff",
                },
              }}
            >
              <ListItemIcon
                className="nav-ListItemButton"
                sx={{
                  minWidth: 0,
                  mr: openNew ? 3 : "auto",
                  justifyContent: "center",
                }}
                onClick={() => { }}
              >
                {openNew ? (
                  <span className="menu-icon-span">
                    <HelpIcon className="menu-icon" />
                  </span>
                ) : (
                  <BootstrapTooltip
                    TransitionComponent={Zoom}
                    title={
                      <React.Fragment>
                        <span style={{ fontSize: "12px" }}>
                          Help
                        </span>
                      </React.Fragment>
                    }
                    placement="right"
                  >
                    <span className="menu-icon-span">
                      <HelpIcon
                        className="menu-icon"
                      // onClick={() => {
                      //   Handlemenu(1);
                      // }}
                      />
                    </span>
                  </BootstrapTooltip>
                )}</ListItemIcon>
              <ListItemText
                primary={"Help"}
                onClick={() => { }}
                sx={{ opacity: openNew ? 1 : 0 }}
                className="menu-item-text"
              />
            </ListItemButton>

          </ListItem>
        </span>
      </span>
    </List>
  );

  return (
    <Box>
      <AppBar position="static" enableColorOnDark>
        <Toolbar
          sx={{
            padding: !matches ? "0px !important" : "",
            paddingLeft: "0px",
            paddingRight: "0px"
          }} >
          {matches ? (
            <>
              <AppBar
                style={{
                  paddingBottom: "0px !important",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.04)",
                }}
                position="fixed"
                open={openNew}
              >
                <CssBaseline />
                <Box>
                  <Toolbar className="new"
                    sx={{
                      backgroundColor: "#ffffff",
                      boxShadow:
                        "rgba(20, 46, 110, 0.1) 0px 1px 8px !important",
                    }}
                  >
                    <IconButton
                      color="primary"
                      aria-label="open drawer"
                      onClick={
                        toggleDrawer("left", !state.left)}
                      edge="start"
                    >
                      <MenuIcon />
                    </IconButton>
                    <Box
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{ marginRight: "50px", marginLeft: "10px" }}
                      >
                        {/* <HmisLogo className="logo nav-logo" size={56} /> */}
                      </div>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          <Grid onClick={profieClick} className="logo_grid">
                            <Grid
                              style={{
                                paddingRight: "8px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src="/pediaverse-logo.png"
                                alt="Image"
                                className="logo_image"
                              />
                            </Grid>
                            <IconButton size="small" onClick={profieClick}>
                              <Tooltip title="Profile">
                                <Avatar
                                  alt="Remy Sharp"
                                  // src="/static/images/avatar/1.jpg"
                                  sx={{ width: 25, height: 25 }}
                                />
                              </Tooltip>
                            </IconButton>
                          </Grid>
                        </Box>
                        <Box component="span" ref={spanRef}>
                          <Menu
                            className="main-profie-container"
                            anchorEl={anchorEl4}
                            open={open4}
                            sx={{ maxWidth: "329px !important" }}
                            onClose={() => profileClose("")}
                            MenuListProps={{
                              "aria-labelledby": "basic-button3",
                            }}
                          >
                            <Grid>
                              <>
                                <Grid className="profile-user-detail-component">
                                  <Grid className="profile-component-grid">
                                    <Grid width={"100%"} paddingX={"8px"}>
                                      <Grid className="firm-container">
                                        <Grid
                                          color={"#3b75ff !important"}
                                          sx={{ paddingTop: "2px" }}
                                        >
                                          <BusinessIcon />
                                        </Grid>
                                        <Grid
                                          marginTop={0.5}
                                          marginLeft={1.3}
                                        >
                                          <h5 className="profilename">
                                            Pediaverse Solution Private
                                            Limited
                                          </h5>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid className="avatar-upload">
                                      <Grid className="avatar-edit"></Grid>
                                      <Grid className="avatar-preview">
                                        <Typography
                                          className="avatar"
                                        // {...stringAvatar(
                                        //     userContext.firstName + " " + userContext.lastName
                                        // )}
                                        />
                                        <Avatar
                                          alt="Remy Sharp"
                                          // src="/static/images/avatar/1.jpg"
                                          sx={{ width: 80, height: 80 }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid className="profile-name-grid">
                                    <h4 className="text-size-medium">
                                      Jatin vaishnav
                                      {/* {userContext.firstName + " " + userContext.lastName} */}
                                    </h4>
                                    <Grid style={{ marginTop: "-5px" }}>
                                      <Typography className="text-size-small">
                                        jatin.vaishnav@tririd.com
                                      </Typography>
                                    </Grid>
                                    <Grid className="grid-myprofile">
                                      <Button
                                        className="one-myprofile"
                                        variant="outlined"
                                        size="medium"
                                        onClick={() =>
                                          profileClose("My Profile")
                                        }
                                      >
                                        My Profile
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid className="branch-selection-component">
                                  <Tooltip title={"userContext.branchName"}>
                                    <Button
                                      className="branch-selection-btn"
                                      // onClick={handleBrancherOpen}
                                      variant="outlined"
                                      startIcon={<BusinessIcon />}
                                    >
                                      <Typography className="branch-selection">
                                        Ahemdabad
                                      </Typography>
                                    </Button>
                                  </Tooltip>
                                </Grid>
                                <Grid className="sign-out-grid">
                                  <Grid>
                                    <Button
                                      className="sign-out-btn-myprofile"
                                      variant="contained"
                                      size="medium"
                                      onClick={() => profileClose("Sign out")}
                                      startIcon={<LogoutIcon />}
                                    >
                                      Sign out
                                    </Button>
                                  </Grid>
                                </Grid>
                              </>
                            </Grid>
                          </Menu>
                        </Box>
                      </Box>
                    </Box>
                  </Toolbar>
                </Box>
              </AppBar>
              <SwipeableDrawer
                anchor="left"
                open={state["left"]}
                onClose={toggleDrawer("left", false)}
                onOpen={toggleDrawer("left", true)}
              >
                {list("left")}
              </SwipeableDrawer>
              <DrawerHeader id="drwerheader" />
              <Box sx={{ padding: "5px", marginTop: "50px" }}>{props.children}</Box>
            </>
          ) : (
            <>
              <Grid sx={{ width: "100%", padding: "0px !important" }}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    // minWidth: "96vw",
                    padding: "0px !important",
                    // backgroundColor: "#f1f5f8"
                  }}
                >
                  <AppBar
                    style={{
                      paddingBottom: "0px !important",
                      borderBottom: "1px solid rgba(0, 0, 0, 0.04)",
                    }}
                    position="fixed"
                    open={openNew}
                  >
                    <CssBaseline />
                    <Box>
                      <Toolbar
                        sx={{
                          backgroundColor: "#ffffff",
                          boxShadow:
                            "rgba(20, 46, 110, 0.1) 0px 1px 8px !important",
                        }}
                      >
                        <IconButton
                          className="icon-color"
                          aria-label="open drawer"
                          onClick={handleDrawerBtn}
                          edge="start"
                        >
                          {openNew ? <MenuOpenOutlinedIcon /> : <MenuIcon />}
                        </IconButton>
                        <Box
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{ marginRight: "50px", marginLeft: "10px", marginTop: '8px' }}
                          >
                            <HmisLogo className="logo nav-logo" size={38} />
                          </div>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                            }}
                          >
                            <Box>
                              <Tooltip title="Settings">
                                <IconButton
                                  className="icon-color"
                                  aria-label=""
                                  onClick={() => navigate("/settings")}
                                >
                                  <SettingsIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                            <Box>
                              <Tooltip title="Help">
                                <IconButton className="icon-color" aria-label="">
                                  <HelpIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                            <Box>
                              <Grid onClick={profieClick} className="logo_grid">
                                <Grid
                                  style={{
                                    paddingRight: "8px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src="/pediaverse-logo.png"
                                    alt="Image"
                                    className="logo_image"
                                  />
                                </Grid>
                                <IconButton size="small" onClick={profieClick}>
                                  <Tooltip title="Profile">
                                    <Avatar
                                      alt="Remy Sharp"
                                      // src="/static/images/avatar/1.jpg"
                                      sx={{ width: 25, height: 25 }}
                                    />
                                  </Tooltip>
                                </IconButton>
                              </Grid>
                            </Box>
                            <Box component="span" ref={spanRef}>
                              <Menu
                                className="main-profie-container"
                                anchorEl={anchorEl4}
                                open={open4}
                                sx={{ maxWidth: "329px !important" }}
                                onClose={() => profileClose("")}
                                MenuListProps={{
                                  "aria-labelledby": "basic-button3",
                                }}
                              >
                                <Grid>
                                  <>
                                    <Grid className="profile-user-detail-component">
                                      <Grid className="profile-component-grid">
                                        <Grid width={"100%"} paddingX={"8px"}>
                                          <Grid className="firm-container">
                                            <Grid
                                              color={"#3b75ff !important"}
                                              sx={{ paddingTop: "2px" }}
                                            >
                                              <BusinessIcon />
                                            </Grid>
                                            <Grid
                                              marginTop={0.5}
                                              marginLeft={1.3}
                                            >
                                              <h5 className="profilename">
                                                Pediaverse Solution Private
                                                Limited
                                              </h5>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                        <Grid className="avatar-upload">
                                          <Grid className="avatar-edit"></Grid>
                                          <Grid className="avatar-preview">
                                            <Typography
                                              className="avatar"
                                            // {...stringAvatar(
                                            //     userContext.firstName + " " + userContext.lastName
                                            // )}
                                            />
                                            <Avatar
                                              alt="Remy Sharp"
                                              // src="/static/images/avatar/1.jpg"
                                              sx={{ width: 80, height: 80 }}
                                            />
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid className="profile-name-grid">
                                        <h4 className="text-size-medium">
                                          Jatin vaishnav
                                          {/* {userContext.firstName + " " + userContext.lastName} */}
                                        </h4>
                                        <Grid style={{ marginTop: "-5px" }}>
                                          <Typography className="text-size-small">
                                            jatin.vaishnav@tririd.com
                                          </Typography>
                                        </Grid>
                                        <Grid className="grid-myprofile">
                                          <Button
                                            className="one-myprofile"
                                            variant="outlined"
                                            size="medium"
                                            onClick={() =>
                                              profileClose("My Profile")
                                            }
                                          >
                                            My Profile
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid className="branch-selection-component">
                                      <Tooltip title={"userContext.branchName"}>
                                        <Button
                                          className="branch-selection-btn"
                                          // onClick={handleBrancherOpen}
                                          variant="outlined"
                                          startIcon={<BusinessIcon />}
                                        >
                                          <Typography className="branch-selection">
                                            Ahemdabad
                                          </Typography>
                                        </Button>
                                      </Tooltip>
                                    </Grid>
                                    <Grid className="sign-out-grid">
                                      <Grid>
                                        <Button
                                          className="sign-out-btn-myprofile"
                                          variant="contained"
                                          size="medium"
                                          onClick={() => profileClose("Sign out")}
                                          startIcon={<LogoutIcon />}
                                        >
                                          Sign out
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </>
                                </Grid>
                              </Menu>
                            </Box>
                          </Box>
                        </Box>
                      </Toolbar>
                    </Box>
                  </AppBar>
                  <Drawer
                    variant="permanent"
                    open={openNew}
                    style={{ transitionDuration: "0.15s" }}>
                    <DrawerHeader />
                    <List
                      className="drawer-container"
                      sx={{
                        boxShadow:
                          "rgba(20, 46, 110, 0.1) 0px 1px 8px !important",
                        height: "100vh",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "column",
                        }}
                      >
                        <ListItem
                          key={"Dashboard"}
                          disablePadding
                          className={
                            "/dashboard" === pathname
                              ? "active-master-class"
                              : "nav-ListItemButton"
                          }
                          sx={{ display: "block" }}
                          onClick={handleClickDashboard}

                        >
                          <ListItemButton
                            sx={{
                              minHeight: 24,
                              justifyContent: openNew ? "initial" : "center",
                              px: 1.3,
                              "&:hover": {
                                backgroundColor: "#3b75ff",
                                color: "#ffffff",
                              },
                            }}
                          >
                            <ListItemIcon
                              className="nav-ListItemButton"
                              sx={{
                                minWidth: 0,
                                mr: openNew ? 3 : "auto",
                                justifyContent: "center",
                              }}
                              onClick={handleClickDashboard}
                            >
                              {openNew ? (
                                <span className="menu-icon-span">
                                  <HomeOutlinedIcon className="menu-icon" />
                                </span>
                              ) : (
                                <BootstrapTooltip
                                  TransitionComponent={Zoom}
                                  title={
                                    <React.Fragment>
                                      <span style={{ fontSize: "12px" }}>
                                        Dashboard
                                      </span>
                                    </React.Fragment>
                                  }
                                  placement="right"
                                >
                                  <span className="menu-icon-span">
                                    <HomeOutlinedIcon
                                      className="menu-icon"
                                    // onClick={() => {
                                    //   Handlemenu(1);
                                    // }}
                                    />
                                  </span>
                                </BootstrapTooltip>
                              )}
                            </ListItemIcon>
                            <ListItemText
                              primary={"Dashboard"}
                              onClick={handleClickDashboard}
                              className="menu-item-text"
                              sx={{ opacity: openNew ? 1 : 0 }}
                            />
                          </ListItemButton>
                        </ListItem>

                        <ListItem
                          key={"Consumer"}
                          disablePadding
                          className={
                            "/consumer" === pathname
                              ? "active-master-class"
                              : "nav-ListItemButton"
                          }
                          sx={{ display: "block" }}
                          onClick={handleClickConsumer}
                        >
                          <ListItemButton
                            sx={{
                              minHeight: 24,
                              justifyContent: openNew ? "initial" : "center",
                              px: 1.3,
                              "&:hover": {
                                backgroundColor: "#3b75ff",
                                color: "#ffffff",
                              },
                            }}
                          >
                            <ListItemIcon
                              className="nav-ListItemButton"
                              sx={{
                                minWidth: 0,
                                mr: openNew ? 3 : "auto",
                                justifyContent: "center",
                              }}
                              onClick={handleClickConsumer}
                            >
                              {openNew ? (
                                <span className="menu-icon-span">
                                  <PersonIcon className="menu-icon" />
                                </span>
                              ) : (
                                <BootstrapTooltip
                                  TransitionComponent={Zoom}
                                  title={
                                    <React.Fragment>
                                      <span style={{ fontSize: "12px" }}>
                                        Consumer
                                      </span>
                                    </React.Fragment>
                                  }
                                  placement="right"
                                >
                                  <span className="menu-icon-span">
                                    <PersonIcon
                                      className="menu-icon"
                                    // onClick={() => {
                                    //   Handlemenu(1);
                                    // }}
                                    />
                                  </span>
                                </BootstrapTooltip>
                              )}
                            </ListItemIcon>
                            <ListItemText
                              primary={"Consumer"}
                              onClick={handleClickConsumer}
                              sx={{ opacity: openNew ? 1 : 0 }}
                              className="menu-item-text"
                            />
                          </ListItemButton>
                        </ListItem>

                        <ListItem
                          key={"FrontDesk"}
                          disablePadding
                          className="nav-ListItemButton"
                          sx={{ display: "block" }}
                        >
                          <ListItemButton
                            onClick={
                              openNew
                                ? handleClickFrontDesk
                                : handleClickMasterNull
                            }
                            sx={{
                              minHeight: 24,
                              justifyContent: openNew ? "initial" : "center",
                              px: 1.3,
                              "&:hover": {
                                backgroundColor: "#3b75ff",
                                color: "#ffffff",
                              },
                            }}
                          >
                            <ListItemIcon
                              className="nav-ListItemButton"
                              onClick={handleClickFrontDesk}
                              sx={{
                                minWidth: 0,
                                mr: openNew ? 3 : "auto",
                                justifyContent: "center",
                              }}
                            >
                              {openNew ? (
                                <span className="menu-icon-span">
                                  <img
                                    src={FrontDeskOutlineIcon}
                                    alt="menu icon"
                                    height={25}
                                    width={25}
                                  />
                                  {/* <AssignmentIndOutlinedIcon
                                  className={
                                    "/patient-master" === pathname ||
                                    "/patient-history" === pathname
                                      ? "active-master-class-icon menu-icon"
                                      : "menu-icon"
                                  }
                                /> */}
                                </span>
                              ) : (
                                <BootstrapTooltip
                                  TransitionComponent={Zoom}
                                  title={
                                    <React.Fragment>
                                      <span style={{ fontSize: "12px" }}>
                                        Front Desk
                                      </span>
                                    </React.Fragment>
                                  }
                                  placement="right"
                                >
                                  <span className="menu-icon-span">
                                    <img
                                      src={FrontDeskOutlineIcon}
                                      alt="menu icon"
                                      height={25}
                                      width={25}
                                    />
                                  </span>
                                </BootstrapTooltip>
                              )}
                            </ListItemIcon>

                            <ListItemText
                              primary="Front Desk"
                              sx={{ opacity: openNew ? 1 : 0 }}
                              className={
                                "/add-mr" === pathname ||
                                  "/add-appointment" === pathname ||
                                  "/reception-appointments" === pathname
                                  ? "active-master-class-icon menu-item-text"
                                  : "menu-item-text"
                              }
                            />
                            {openPatient ? (
                              <ExpandLess
                                sx={{
                                  "&:hover": {
                                    backgroundColor: "#3b75ff",
                                    color: "#ffffff",
                                  },
                                }}
                                className={
                                  "/add-mr" === pathname ||
                                    "/add-appointment" === pathname ||
                                    "/reception-appointments" === pathname
                                    ? "active-master-class-icon menu-item-icon-more"
                                    : "menu-item-icon-more"
                                }
                              />
                            ) : (
                              <ExpandMore
                                className={
                                  "/add-mr" === pathname ||
                                    "/add-appointment" === pathname ||
                                    "/reception-appointments" === pathname
                                    ? "active-master-class-icon menu-item-icon-more"
                                    : "menu-item-icon-more"
                                }
                              />
                            )}
                          </ListItemButton>
                          <Collapse in={openFrontDesk} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding className="">

                              <ListItemButton
                                className={
                                  "/add-appointment" === pathname
                                    ? "active-master-class sub-menu"
                                    : "sub-menu"
                                }
                                sx={{
                                  pl: 4,
                                  "&:hover": {
                                    backgroundColor: "#3b75ff",
                                    color: "#ffffff",
                                  },
                                }}
                                onClick={toggleDrawer("right", false)}
                              >
                                <ListItemText primary="Add Appointments" />
                              </ListItemButton>
                              <ListItemButton
                                className={
                                  "/reception-appointments" === pathname
                                    ? "active-master-class sub-menu"
                                    : "sub-menu"
                                }
                                sx={{
                                  pl: 4,
                                  "&:hover": {
                                    backgroundColor: "#3b75ff",
                                    color: "#ffffff",
                                  },
                                }}
                                onClick={toggleDrawer("right", false)}
                              >
                                <ListItemText primary="Appointments" />
                              </ListItemButton>
                            </List>
                          </Collapse>
                        </ListItem>

                        <ListItem
                          key={"OPD"}
                          disablePadding
                          className="nav-ListItemButton"
                          sx={{ display: "block" }}
                        >
                          <ListItemButton
                            onClick={
                              openNew ? handleClickOPD : handleClickMasterNull
                            }
                            sx={{
                              minHeight: 24,
                              justifyContent: openNew ? "initial" : "center",
                              px: 1.3,
                              "&:hover": {
                                backgroundColor: "#3b75ff",
                                color: "#ffffff",
                              },
                            }}
                          >
                            <ListItemIcon
                              className="nav-ListItemButton"
                              onClick={handleClickOPD}
                              sx={{
                                minWidth: 0,
                                mr: openNew ? 3 : "auto",
                                justifyContent: "center",
                              }}
                            >
                              {openNew ? (
                                <span className="menu-icon-span">
                                  <img
                                    src={OPDOutlineIcon}
                                    alt="menu icon"
                                    height={25}
                                    width={25}
                                  />
                                  {/* <AssignmentIndOutlinedIcon
                                  className={
                                    "/patient-master" === pathname ||
                                    "/patient-history" === pathname
                                      ? "active-master-class-icon menu-icon"
                                      : "menu-icon"
                                  }
                                /> */}
                                </span>
                              ) : (
                                <BootstrapTooltip
                                  TransitionComponent={Zoom}
                                  title={
                                    <React.Fragment>
                                      <span style={{ fontSize: "12px" }}>
                                        OPD
                                      </span>
                                    </React.Fragment>
                                  }
                                  placement="right"
                                >
                                  <span className="menu-icon-span">
                                    <img
                                      src={OPDOutlineIcon}
                                      alt="menu icon"
                                      height={25}
                                      width={25}
                                    />
                                    {/* <AssignmentIndOutlinedIcon
                                    className={
                                      "/patient-master" === pathname ||
                                      "/patient-history" === pathname
                                        ? "active-master-class-icon menu-icon"
                                        : "menu-icon"
                                    }
                                  /> */}
                                  </span>
                                </BootstrapTooltip>
                              )}
                            </ListItemIcon>

                            <ListItemText
                              primary="OPD"
                              sx={{ opacity: openNew ? 1 : 0 }}
                              className={
                                "/opd-clinical" === pathname ||
                                  "/certificates" === pathname
                                  ? "active-master-class-icon menu-item-text"
                                  : "menu-item-text"
                              }
                            />
                            {openPatient ? (
                              <ExpandLess
                                sx={{
                                  "&:hover": {
                                    backgroundColor: "#3b75ff",
                                    color: "#ffffff",
                                  },
                                }}
                                className={
                                  "/opd-clinical" === pathname ||
                                    "/certificates" === pathname
                                    ? "active-master-class-icon menu-item-icon-more"
                                    : "menu-item-icon-more"
                                }
                              />
                            ) : (
                              <ExpandMore
                                className={
                                  "/opd-clinical" === pathname ||
                                    "/certificates" === pathname
                                    ? "active-master-class-icon menu-item-icon-more"
                                    : "menu-item-icon-more"
                                }
                              />
                            )}
                          </ListItemButton>
                          <Collapse in={openOPD} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding className="">
                              <ListItemButton
                                className={
                                  "/opd-clinical" === pathname
                                    ? "active-master-class sub-menu"
                                    : "sub-menu"
                                }
                                sx={{
                                  pl: 4,
                                  "&:hover": {
                                    backgroundColor: "#3b75ff",
                                    color: "#ffffff",
                                  },
                                }}
                                onClick={toggleDrawer("right", false)}
                              >
                                <ListItemText primary="OPD Clinical" />
                              </ListItemButton>
                              <ListItemButton
                                className={
                                  "/certificates" === pathname
                                    ? "active-master-class sub-menu"
                                    : "sub-menu"
                                }
                                sx={{
                                  pl: 4,
                                  "&:hover": {
                                    backgroundColor: "#3b75ff",
                                    color: "#ffffff",
                                  },
                                }}
                                onClick={toggleDrawer("right", false)}
                              >
                                <ListItemText primary="Certificates" />
                              </ListItemButton>
                            </List>
                          </Collapse>
                        </ListItem>

                        <ListItem
                          key={"Patient"}
                          disablePadding
                          className="nav-ListItemButton"
                          sx={{ display: "block" }}
                        >
                          <ListItemButton
                            onClick={
                              openNew
                                ? handleClickPatient
                                : handleClickMasterNull
                            }
                            sx={{
                              minHeight: 24,
                              justifyContent: openNew ? "initial" : "center",
                              px: 1.3,
                              "&:hover": {
                                backgroundColor: "#3b75ff",
                                color: "#ffffff",
                              },
                            }}
                          >
                            <ListItemIcon
                              className="nav-ListItemButton"
                              onClick={handleClickPatient}
                              sx={{
                                minWidth: 0,
                                mr: openNew ? 3 : "auto",
                                justifyContent: "center",
                              }}
                            >
                              {openNew ? (
                                <span className="menu-icon-span">
                                  <AssignmentIndOutlinedIcon
                                    className={
                                      "/patient-master" === pathname ||
                                        "/patient-history" === pathname
                                        ? "active-master-class-icon menu-icon"
                                        : "menu-icon"
                                    }
                                  />
                                </span>
                              ) : (
                                <BootstrapTooltip
                                  TransitionComponent={Zoom}
                                  title={
                                    <React.Fragment>
                                      <span style={{ fontSize: "12px" }}>
                                        Patient
                                      </span>
                                    </React.Fragment>
                                  }
                                  placement="right"
                                >
                                  <span className="menu-icon-span">
                                    <AssignmentIndOutlinedIcon
                                      className={
                                        "/patient-master" === pathname ||
                                          "/patient-history" === pathname
                                          ? "active-master-class-icon menu-icon"
                                          : "menu-icon"
                                      }
                                    />
                                  </span>
                                </BootstrapTooltip>
                              )}
                            </ListItemIcon>

                            <ListItemText
                              primary="Patient"
                              sx={{ opacity: openNew ? 1 : 0 }}
                              className={
                                "/patient-master" === pathname ||
                                  "/patient-history" === pathname
                                  ? "active-master-class-icon menu-item-text"
                                  : "menu-item-text"
                              }
                            />
                            {openPatient ? (
                              <ExpandLess
                                sx={{
                                  "&:hover": {
                                    backgroundColor: "#3b75ff",
                                    color: "#ffffff",
                                  },
                                }}
                                className={
                                  "/patient-master" === pathname ||
                                    "/patient-history" === pathname
                                    ? "active-master-class-icon menu-item-icon-more"
                                    : "menu-item-icon-more"
                                }
                              />
                            ) : (
                              <ExpandMore
                                className={
                                  "/patient-master" === pathname ||
                                    "/patient-history" === pathname
                                    ? "active-master-class-icon menu-item-icon-more"
                                    : "menu-item-icon-more"
                                }
                              />
                            )}
                          </ListItemButton>
                          <Collapse in={openPatient} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding className="">
                              <ListItemButton
                                className={
                                  "/patient-master" === pathname
                                    ? "active-master-class sub-menu"
                                    : "sub-menu"
                                }
                                sx={{
                                  pl: 4,
                                  "&:hover": {
                                    backgroundColor: "#3b75ff",
                                    color: "#ffffff",
                                  },
                                }}
                                onClick={toggleDrawer("right", false)}
                              >
                                <ListItemText primary="Patients" />
                              </ListItemButton>
                              <ListItemButton
                                className={
                                  "/patient-history" === pathname
                                    ? "active-master-class sub-menu"
                                    : "sub-menu"
                                }
                                sx={{
                                  pl: 4,
                                  "&:hover": {
                                    backgroundColor: "#3b75ff",
                                    color: "#ffffff",
                                  },
                                }}
                                onClick={toggleDrawer("right", false)}
                              >
                                <ListItemText primary="Patient History" />
                              </ListItemButton>
                            </List>
                          </Collapse>
                        </ListItem>
                        <ListItem
                          key={"Team"}
                          disablePadding
                          className="nav-ListItemButton"
                          sx={{ display: "block" }}
                        >
                          <ListItemButton
                            onClick={
                              openNew
                                ? handleClickTeam
                                : handleClickMasterNull
                            }
                            sx={{
                              minHeight: 24,
                              justifyContent: openNew ? "initial" : "center",
                              px: 1.3,
                              "&:hover": {
                                backgroundColor: "#3b75ff",
                                color: "#ffffff",
                              },
                            }}
                          >
                            <ListItemIcon
                              className="nav-ListItemButton"
                              onClick={handleClickTeam}
                              sx={{
                                minWidth: 0,
                                mr: openNew ? 3 : "auto",
                                justifyContent: "center",
                              }}
                            >
                              {openNew ? (
                                <span className="menu-icon-span">
                                  <GroupsIcon
                                    className={
                                      "/staff" === pathname ||
                                        "/users" === pathname
                                        ? "active-master-class-icon menu-icon"
                                        : "menu-icon"
                                    }
                                  />
                                </span>
                              ) : (
                                <BootstrapTooltip
                                  TransitionComponent={Zoom}
                                  title={
                                    <React.Fragment>
                                      <span style={{ fontSize: "12px" }}>
                                        Team
                                      </span>
                                    </React.Fragment>
                                  }
                                  placement="right"
                                >
                                  <span className="menu-icon-span">
                                    <GroupsIcon
                                      className={
                                        "/staff" === pathname ||
                                          "/users" === pathname
                                          ? "active-master-class-icon menu-icon"
                                          : "menu-icon"
                                      }
                                    />
                                  </span>
                                </BootstrapTooltip>
                              )}
                            </ListItemIcon>

                            <ListItemText
                              primary="Team"
                              sx={{ opacity: openNew ? 1 : 0 }}
                              className={
                                "/staff" === pathname ||
                                  "/users" === pathname
                                  ? "active-master-class-icon menu-item-text"
                                  : "menu-item-text"
                              }
                            />
                            {openPatient ? (
                              <ExpandLess
                                sx={{
                                  "&:hover": {
                                    backgroundColor: "#3b75ff",
                                    color: "#ffffff",
                                  },
                                }}
                                className={
                                  "/staff" === pathname ||
                                    "/users" === pathname
                                    ? "active-master-class-icon menu-item-icon-more"
                                    : "menu-item-icon-more"
                                }
                              />
                            ) : (
                              <ExpandMore
                                className={
                                  "/staff" === pathname ||
                                    "/users" === pathname
                                    ? "active-master-class-icon menu-item-icon-more"
                                    : "menu-item-icon-more"
                                }
                              />
                            )}
                          </ListItemButton>
                          <Collapse in={openTeam} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding className="">
                              <ListItemButton
                                className={
                                  "/staff" === pathname
                                    ? "active-master-class sub-menu"
                                    : "sub-menu"
                                }
                                sx={{
                                  pl: 4,
                                  "&:hover": {
                                    backgroundColor: "#3b75ff",
                                    color: "#ffffff",
                                  },
                                }}
                                onClick={toggleDrawer("right", false)}
                              >
                                <ListItemText primary="Staff" />
                              </ListItemButton>
                              <ListItemButton
                                className={
                                  "/users" === pathname
                                    ? "active-master-class sub-menu"
                                    : "sub-menu"
                                }
                                sx={{
                                  pl: 4,
                                  "&:hover": {
                                    backgroundColor: "#3b75ff",
                                    color: "#ffffff",
                                  },
                                }}
                                onClick={toggleDrawer("right", false)}
                              >
                                <ListItemText primary="Users" />
                              </ListItemButton>
                            </List>
                          </Collapse>
                        </ListItem>

                        <ListItem
                          key={"Accounts"}
                          disablePadding
                          className="nav-ListItemButton"
                          sx={{ display: "block" }}
                        >
                          <ListItemButton
                            onClick={
                              openNew
                                ? handleClickAccounts
                                : handleClickMasterNull
                            }
                            sx={{
                              minHeight: 24,
                              justifyContent: openNew ? "initial" : "center",
                              px: 1.3,
                              "&:hover": {
                                backgroundColor: "#3b75ff",
                                color: "#ffffff",
                              },
                            }}
                          >
                            <ListItemIcon
                              className="nav-ListItemButton"
                              sx={{
                                minWidth: 0,
                                mr: openNew ? 3 : "auto",
                                justifyContent: "center",
                              }}
                              onClick={handleClickAccounts}
                            >
                              {openNew ? (
                                <span className="menu-icon-span">
                                  <AccountBalanceIcon
                                    className={
                                      "/opd-billing" === pathname
                                        ? "active-master-class-icon menu-icon"
                                        : "menu-icon"
                                    }
                                  />
                                </span>
                              ) : (
                                <BootstrapTooltip
                                  TransitionComponent={Zoom}
                                  title={
                                    <React.Fragment>
                                      <span style={{ fontSize: "12px" }}>
                                        Accounts
                                      </span>
                                    </React.Fragment>
                                  }
                                  placement="right"
                                >
                                  <span className="menu-icon-span">

                                    <AccountBalanceIcon
                                      className={
                                        "/opd-billing" === pathname
                                          ? "active-master-class-icon menu-icon"
                                          : "menu-icon"
                                      }
                                    />
                                  </span>
                                </BootstrapTooltip>
                              )}
                            </ListItemIcon>

                            <ListItemText
                              primary="Accounts"
                              sx={{ opacity: openNew ? 1 : 0 }}
                              className={
                                "/opd-billing" === pathname
                                  ? "active-master-class-icon menu-item-text"
                                  : "menu-item-text"
                              }
                            />
                            {openPatient ? (
                              <ExpandLess
                                sx={{
                                  "&:hover": {
                                    backgroundColor: "#3b75ff",
                                    color: "#ffffff",
                                  },
                                }}
                                className={
                                  "/opd-billing" === pathname
                                    ? "active-master-class-icon menu-item-icon-more"
                                    : "menu-item-icon-more"
                                }
                              />
                            ) : (
                              <ExpandMore
                                className={
                                  "/opd-billing" === pathname
                                    ? "active-master-class-icon menu-item-icon-more"
                                    : "menu-item-icon-more"
                                }
                              />
                            )}
                          </ListItemButton>
                          <Collapse in={openAccounts} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding className="">
                              <ListItemButton
                                className={
                                  "/opd-billing" === pathname
                                    ? "active-master-class sub-menu"
                                    : "sub-menu"
                                }
                                sx={{
                                  pl: 4,
                                  "&:hover": {
                                    backgroundColor: "#3b75ff",
                                    color: "#ffffff",
                                  },
                                }}
                                onClick={toggleDrawer("right", false)}
                              >
                                <ListItemText primary="OPD Billing" />
                              </ListItemButton>
                            </List>
                          </Collapse>
                        </ListItem>

                        <ListItem
                          key={"Master"}
                          disablePadding
                          className={
                            "/master" === pathname
                              ? "active-master-class"
                              : "nav-ListItemButton"
                          }
                          sx={{ display: "block" }}
                          onClick={
                            handleClickMaster
                          }
                        >
                          <ListItemButton
                            sx={{
                              minHeight: 24,
                              justifyContent: openNew ? "initial" : "center",
                              px: 1.3,
                              "&:hover": {
                                backgroundColor: "#3b75ff",
                                color: "#ffffff",
                              },
                            }}
                          >
                            <ListItemIcon
                              className="nav-ListItemButton"
                              sx={{
                                minWidth: 0,
                                mr: openNew ? 3 : "auto",
                                justifyContent: "center",
                              }}
                              onClick={handleClickMaster}
                            >
                              {openNew ? (
                                <span className="menu-icon-span">
                                  <AutoAwesomeMosaicOutlinedIcon className="menu-icon" />
                                </span>
                              ) : (
                                <BootstrapTooltip
                                  TransitionComponent={Zoom}
                                  title={
                                    <React.Fragment>
                                      <span style={{ fontSize: "12px" }}>
                                        Master
                                      </span>
                                    </React.Fragment>
                                  }
                                  placement="right"
                                >
                                  <span className="menu-icon-span">
                                    <AutoAwesomeMosaicOutlinedIcon
                                      className="menu-icon"
                                    // onClick={() => {
                                    //   Handlemenu(1);
                                    // }}
                                    />
                                  </span>
                                </BootstrapTooltip>
                              )}
                            </ListItemIcon>
                            <ListItemText
                              primary={"Master"}
                              onClick={handleClickMaster}
                              sx={{ opacity: openNew ? 1 : 0 }}
                              className="menu-item-text"
                            />
                          </ListItemButton>
                        </ListItem>

                      </span>
                    </List>
                  </Drawer>
                  <Box
                    component="main"
                    sx={{
                      flexGrow: 1,
                      minHeight: "99.8vh",
                      paddingLeft: "14px",
                      paddingRight: "14px",
                      position: "relative",
                      backgroundColor: "#f1f5f8",
                    }}
                  >
                    <DrawerHeader id="drwerheader" />
                    <Box
                      sx={{ padding: "5px" }}
                    >{props.children}</Box>
                  </Box>
                </Box>
              </Grid>
            </>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
