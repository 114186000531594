import React, { useState } from "react";
import "./style.scss";
import { Box, Button, Divider, Grid, IconButton, ListItem, ListItemButton, ListItemText, List,  Paper, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import ReactECharts from "echarts-for-react";
import { Link, useNavigate } from "react-router-dom";
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import AssignmentIndRoundedIcon from '@mui/icons-material/AssignmentIndRounded';
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import CustomBreadcrumbs from "../../components/BreadcrumbsComponent/CustomBreadcrumbs";


const dataOptions = {
  legend: {
    top: "5%",
    left: "center",
  },
  series: [
    {
      name: "Access From", 
      type: "pie",
      radius: ["40%", "70%"],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: "center",
      },
      emphasis: {
        label: {
          show: false,
          fontSize: 40,
          fontWeight: "bold",
        },
      },
      labelLine: {
        show: false,
      },
      data: [
        { value: 1500, name: "New Patients", itemStyle: { color: '#2e67a3' } },
        { value: 1048, name: "Old Patients", itemStyle: { color: '#91CC75' } },
      ],
    },
  ],
};
const genderTypeOptions = {
  legend: {
    top: "5%",
    left: "center",
  },
  series: [
    {
      name: "Access From",
      type: "pie",
      radius: ["40%", "70%"],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: "center",
      },
      emphasis: {
        label: {
          show: false,
          fontSize: 40,
          fontWeight: "bold",
        },
      },
      labelLine: {
        show: false,
      },
      // data: [
      //   { value: 1048, name: "Male" },
      //   { value: 735, name: "Female" },
      // ],
      data: [
        { value: 735, name: "New Patients", itemStyle: { color: '#2e67a3' } }, // Blue color
        { value: 1048, name: "Old Patients", itemStyle: { color: '#91CC75' } }, // Green color
      ],
    },
  ],
};
export default function Dashboard() {
  const navigate = useNavigate()
  const [dense, setDense] = React.useState(false);
  const localizer = momentLocalizer(moment)
  const [anchorEl, setAnchorEl3] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [events, setEvents] = useState([
    {
      title: 'Handle Patient',
      start: new Date(),
      end: new Date(),
    },
  ]);
  const open = Boolean(anchorEl);

  // for Edit & Delet pop 
  const handleOpenMore = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: any
  ) => {
    setAnchorEl3(event.currentTarget);
    // setBuyerId(id);
  };
  const handleCloseMore = () => {
    setAnchorEl3(null);
  };


  const rowData = [
    {
      queueNo: '001',
      name: 'John Doe',
      contactNo: '123-456-7890',
      reasonForVisit: 'Routine Checkup',
      newOrFollowUp: 'New',
      refDoc: 'Dr. Smith'
    },
    {
      queueNo: '002',
      name: 'Jane Smith',
      contactNo: '987-654-3210',
      reasonForVisit: 'Flu Symptoms',
      newOrFollowUp: 'New',
      refDoc: 'Dr. Brown'
    },
    {
      queueNo: '003',
      name: 'Michael Johnson',
      contactNo: '555-123-4567',
      reasonForVisit: 'Follow-up on Surgery',
      newOrFollowUp: 'Follow-up',
      refDoc: 'Dr. Green'
    },
    {
      queueNo: '004',
      name: 'Emily Davis',
      contactNo: '444-555-6666',
      reasonForVisit: 'Consultation for Allergy',
      newOrFollowUp: 'New',
      refDoc: 'Dr. White'
    },
    {
      queueNo: '005',
      name: 'David Wilson',
      contactNo: '333-222-1111',
      reasonForVisit: 'Physical Therapy',
      newOrFollowUp: 'Follow-up',
      refDoc: 'Dr. Black'
    }
  ];

  type Order = "asc" | "desc" | "";
  interface EnhancedTableProps {
    numSelected: number;
    //onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: String;
    rowCount: number;
  }

  //  Table Header 

  function EnhancedTableHead(props: EnhancedTableProps) {
    return (
      // checkBox for Header

      <TableHead className='table-header-color'>
        <TableRow>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "15%"
            }}
            align="left"
          >
            Queue No
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "15%"
            }}
            align="left"
          >
            Name
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "none",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "15%"
            }}
            align="left"
          >
            Contact No
          </TableCell>

          <TableCell
            sx={{
              display: {
                xs: "none",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "15%"
            }}
            align="left"
          >
            New/Follow up
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "none",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "15%"
            }}
            align="left"
          >
            Ref Doc
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "none",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "15%"
            }}
            align="left"
          >
            Reason for visit
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "10%"
            }}
            align="left"
          >
            {''}
          </TableCell>

        </TableRow>
      </TableHead>
    );
  }

  const handleSelectSlot = ({ start, end }) => {
    const title = window.prompt('New Event Name');
    if (title) {
      setEvents([...events, { start, end, title }]);
    }
  };

  return (
    <>
      <Box className="main-container" >
        <Grid xs={12} container className="page-header">
          <Grid xs={12} sm={12} md={12} item >
            <CustomBreadcrumbs />
          </Grid>
          <Grid xs={12} sm={12} md={12} item sx={{ display: 'flex', alignItems: 'start' }} >
            <span className="welcome-text">Welcome,</span>
            <span className="welcome-text"> Dr. {"Avinash Singh"}</span>
          </Grid>
        </Grid>
        <Grid container  >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
            >
              <Paper className="dashboard-data-card" >
                <CalendarMonthRoundedIcon />
                <Box className="card-text-box-data">
                  <Typography>Appointments</Typography>
                  <span className="plus-icon">{'134'} </span>
                  <span> / </span>
                  <span> {'250'}</span>
                </Box>
              </Paper>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
            >
              <Paper className="dashboard-data-card">
                <AssignmentIndRoundedIcon />
                <Box className="card-text-box-data">
                  <Typography>New Patients</Typography>
                  <span className="plus-icon">{'61'} </span>
                  <span> / </span>
                  <span> {'100'}</span>
                </Box>
              </Paper>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
            >
              <Paper className="dashboard-data-card">
                <AssignmentRoundedIcon />
                <Box className="card-text-box-data">
                  <Typography>Task</Typography>
                  <span className="plus-icon">{'39'} </span>
                  {/* <span> / </span> */}
                  {/* <span> {'39'}</span> */}
                </Box>
              </Paper>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
            >
              <Paper className="dashboard-data-card">
                <NotificationsActiveRoundedIcon />
                <Box className="card-text-box-data">
                  <Typography>Reminders</Typography>
                  <span className="plus-icon" style={{ fontSize: '24px', display: 'flex', alignItems: 'center' }}> 20 </span>
                </Box>
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ paddingTop: "16px" }}>
            <Grid item xs={12} sm={12} md={9}>
              <Paper sx={{ borderRadius: '12px' }}>
                <Typography sx={{ paddingBottom: '8px', paddingLeft: '16px', paddingTop: '12px', fontWeight: 'bold' }}>Events</Typography>
                <Divider />
                <Box sx={{ paddingBottom: '16px', paddingLeft: '16px', paddingRight: '16px', paddingTop: '12px' }}>
                  <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    selectable
                    onSelectSlot={handleSelectSlot}
                    style={{ height: 630 }}
                  />
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Grid container spacing={2} >
                <Grid item xs={12} sm={12} md={12}>
                  <Paper sx={{ borderRadius: '12px' }}>
                    <Typography sx={{ paddingBottom: '8px', paddingLeft: '16px', paddingTop: '12px', fontWeight: 'bold' }}>Patients</Typography>
                    <Divider />
                    <ReactECharts
                      option={dataOptions}
                      notMerge={true}
                      lazyUpdate={true}
                      theme={"theme_name"}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Paper sx={{ borderRadius: '12px' }}>
                    <Typography sx={{ paddingBottom: '8px', paddingLeft: '16px', paddingTop: '12px', fontWeight: 'bold' }}>Gender</Typography>
                    <Divider />
                    <ReactECharts
                      option={genderTypeOptions}
                      notMerge={true}
                      lazyUpdate={true}
                      theme={"theme_name"}
                    />
                  </Paper>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
        <Grid container className="list-container" sx={{ marginTop: '16px' }}  >
          <Grid item xs={8} sm={6} md={8} lg={11} className='search-bar'  >
            <Typography sx={{ fontWeight: 'bold' }}>Waiting Patients</Typography>
          </Grid>
          <Grid item xs={4} sm={6} md={4} lg={1}  >
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <Button
                color="primary"
                variant="contained"
                // onClick={() => navigate('/branch-save')}
                size="medium"
              >
                View All
              </Button>
            </Box>
          </Grid>
          <TableContainer className='table'>
            <Table >
              <EnhancedTableHead />
              <TableBody>
                {rowData.map((item) => {
                  return (
                    <TableRow>
                      <TableCell><Link className='text-link' to="/branch-save">{item.queueNo}</Link></TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell sx={{
                        display: {
                          xs: "none",
                          sm: "table-cell",
                          md: "table-cell",
                          lg: "table-cell",
                        },
                        
                      }} >{item.contactNo}</TableCell>
                      <TableCell sx={{
                        display: {
                          xs: "none",
                          sm: "table-cell",
                          md: "table-cell",
                          lg: "table-cell",
                        },
                        
                      }}>{item.newOrFollowUp}</TableCell>
                      <TableCell sx={{
                        display: {
                          xs: "none",
                          sm: "table-cell",
                          md: "table-cell",
                          lg: "table-cell",
                        },
                       
                      }}>{item.refDoc}</TableCell>
                      <TableCell sx={{
                        display: {
                          xs: "none",
                          sm: "table-cell",
                          md: "table-cell",
                          lg: "table-cell",
                        },
                        
                      
                      }}>{item.reasonForVisit}</TableCell>
                        <TableCell align="right">
                          <Tooltip title="More" placement="top">
                            <IconButton
                              onClick={
                                (e:any) => 
                                  handleOpenMore(e)
                              }
                            >
                              <MoreVertRoundedIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Box >
      <Popover
        elevation={1}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseMore}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton >
              <ListItemText primary="Edit" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              className="delete-container"
            >
              Delete
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </>
  );
}
