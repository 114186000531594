import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import '../../../theme/style.scss';
import { Box, Divider, FormControl, FormLabel, Grid, Paper, TextField, Typography, styled, Dialog, AppBar, Toolbar, DialogContent } from '@mui/material';
import Title from '../../../components/Title';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogContentText from '@mui/material/DialogContentText';
import DraggableList from '../../../components/DraggableTable/DraggableList';
import DialogActions from '@mui/material/DialogActions';
import ReactQuill from 'react-quill';
import { reorder } from '../../../Helpers/helpers';
import { DropResult } from 'react-beautiful-dnd';
import { AsyncCustomSelect } from '../../../components/CustomFields/CustomSelect';
import { Formik } from 'formik';
import CustomBreadcrumbs from '../../../components/BreadcrumbsComponent/CustomBreadcrumbs';
import InvestigationInstructionSave from '../../../pages/Investigation/InvestigationMaster/_component/InvestigationInstructionSave';

const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: '#FAFBFD', // Set the desired background color
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.grey[300],
      borderWidth: '1px',
      BorderStyle: 'solid'
    },
    '&:hover fieldset': {
      borderColor: theme.palette.grey[500],
      borderWidth: '1px',
      BorderStyle: 'solid'
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: "2px",
    },
  },
}));

export default function InvestigationMasterSave() {
  const [itemsOther, setItemsOther] = React.useState([
    {
      id: "Item1 1",
      primary: "1Practical Rubber Computer1",
      secondary: "1Synergistic leading edge matrices"
    }, {
      id: "Item1 2",
      primary: "1Practical Rubber Computer2",
      secondary: "1Synergistic leading edge matrices"
    }, {
      id: "Item1 3",
      primary: "1Practical Rubber Computer3",
      secondary: "1Synergistic leading edge matrices"
    }
  ]);
  const [itemsOther1, setItemsOther1] = React.useState([
    {
      id: "Item2 1",
      primary: "2Practical Rubber Computer1",
      secondary: "1Synergistic leading edge matrices"
    }, {
      id: "Item1 2",
      primary: "2Practical Rubber Computer2",
      secondary: "1Synergistic leading edge matrices"
    }, {
      id: "Item1 3",
      primary: "2Practical Rubber Computer3",
      secondary: "2Synergistic leading edge matrices"
    }
  ]);

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(itemsOther1, source.index, destination.index);

    setItemsOther(newItems);
  };
  const [openDialog, setOpenDialog] = React.useState(false);
  // const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');


  const navigate = useNavigate()
  const onHandleOpenDialog = (item: Item) => {

    handleClickOpen(true);
    console.log("Opening dialog for item:", item);
  };
  const handleClickOpen = (value: boolean) => {
    setOpenDialog(value);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <Box className="main-container " pl={2}>
      <Grid xs={12} container className='page-header-save'>
        <Grid xs={12} sm={12} md={12} item className="page-breadcrumbs">
          <CustomBreadcrumbs />
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
        }}
        onSubmit={async (values) => {
          console.log("values", values);
        }}
      >
        <Grid container spacing={2}  >
          <Grid item xs={12} mb={2} className="group-container">
            <Grid container item spacing={2} >
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <FormLabel className='fileds-label-text'>Investigation Name</FormLabel>
                  <CustomTextField
                    name="investigationName"
                    autoCapitalize='none'
                    type="text"
                    fullWidth
                    size='small'
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl
                  sx={{
                    minWidth: "auto",
                  }}
                  fullWidth
                >
                  <FormLabel className="fileds-label-text">Sample collected from</FormLabel>
                  <AsyncCustomSelect
                    tabSelectsValue={false}
                    // openMenuOnFocus={
                    //   saleId <= 0
                    // }
                    isNotCreatable={false}
                    isInnerWidth={true}
                    // optionInnerWidth={
                    //   sourceProductCollection.length <
                    //     1
                    //     ? "837px !important"
                    //     : ""
                    // }
                    menuPortalTarget={document.querySelector("body")}
                    id={"followUpReason"}
                    isClearable={true}
                    FieldName={`followUpReason`}
                    // onFocus={(e) => {
                    //   setOpenCreateProduct({
                    //     ...openCreateProduct,
                    //     event: e,
                    //   });
                    // }}
                    // options={sourceProductCollection?.map(
                    //   (product) => {
                    //     if (product !== null) {
                    //       let allProduct = 0;
                    //       let currowquantity =
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]?.quantity;
                    //       props?.values?.saleDetailCollection?.map(
                    //         (rowData) => {
                    //           if (
                    //             rowData.productPriceId ===
                    //             product.productPriceId &&
                    //             rowData.isDeleted ===
                    //             false
                    //           ) {
                    //             allProduct =
                    //               allProduct +
                    //               rowData.quantity;
                    //           }
                    //         }
                    //       );
                    //       if (
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]
                    //           ?.productPriceId ===
                    //         product?.productPriceId
                    //       ) {
                    //         product.quantity =
                    //           product.orgquantity -
                    //           allProduct +
                    //           currowquantity;
                    //       } else {
                    //         product.quantity =
                    //           product.orgquantity -
                    //           allProduct;
                    //       }
                    //     }
                    //     return product;
                    //   }
                    // )}
                    // defaultOptions={sourceProductCollection
                    //   ?.map((product) => {
                    //     if (product !== null) {
                    //       let allProduct = 0;
                    //       let currowquantity =
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]?.quantity;
                    //       props?.values?.saleDetailCollection?.map(
                    //         (rowData) => {
                    //           if (
                    //             rowData?.productPriceId ===
                    //             product?.productPriceId &&
                    //             rowData?.isDeleted ===
                    //             false
                    //           ) {
                    //             allProduct =
                    //               allProduct +
                    //               rowData?.quantity;
                    //           }
                    //         }
                    //       );
                    //       if (
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]
                    //           ?.productPriceId ===
                    //         product?.productPriceId
                    //       ) {
                    //         product.quantity =
                    //           product?.orgquantity -
                    //           allProduct +
                    //           currowquantity;
                    //       } else {
                    //         product.quantity =
                    //           product?.orgquantity -
                    //           allProduct;
                    //       }
                    //     }
                    //     return product;
                    //   })
                    //   .slice(0, 7)}
                    // loadOptions={
                    //   loadProductOptions
                    // }
                    // onCreateOption={(value) => {
                    //   handleCreateProduct(
                    //     value,
                    //     `saleDetailCollection[${dataRow.rowNo}].productId`
                    //   );
                    // }}
                    // autoFocus={
                    //   dataRow.rowNo > 0 &&
                    //   saleData.saleDetailCollection !=
                    //   null &&
                    //   saleData
                    //     .saleDetailCollection[
                    //     dataRow.rowNo
                    //   ]?.productId < 1
                    // }
                    handleChange={(e) => {
                      // if (e) {
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productId`,
                      //     e.value !== null
                      //       ? e.productId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].taxCode`,
                      //     e.value !== null
                      //       ? e.taxCode
                      //       : "",
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productDescription`,
                      //     e.value !== null
                      //       ? e.description
                      //       : "",
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].unitId`,
                      //     e.value !== null
                      //       ? e.unitId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].taxId`,
                      //     e.value !== null
                      //       ? e.taxId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productpriceId`,
                      //     e.value !== null
                      //       ? e.productPriceId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productObj`,
                      //     e.value !== null
                      //       ? e
                      //       : null,
                      //     false
                      //   );
                      //   var pId: number =
                      //     +(e.value
                      //       ? e.productId
                      //       : 0);
                      //   handleSaleDetailChange(
                      //     "product",
                      //     pId,
                      //     dataRow.rowNo,
                      //     pId,
                      //     e.value ? e : "",
                      //     ''
                      //   );
                      // }
                    }}
                  // Value={ }
                  // CustomOptionComponent={  }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Timing of Investigation</FormLabel>
                  <CustomTextField
                    name="juridiation"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Preparation required before investigation</FormLabel>
                  <CustomTextField
                    name="juridiation"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Box
                  className="template-table-container"
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    border: '1px solid #0000001f',
                    borderRadius: 4
                  }}
                >
                  <Paper
                    style={{
                      flex: 1,
                    }}>
                    <Box
                      style={{ padding: 20, fontWeight: 600, backgroundColor: '#f1f5f8' }}
                    >
                      <Typography
                        style={{ fontWeight: 600 }}
                      >
                        Investigation Instructions
                      </Typography>

                    </Box>
                    <Divider />
                    <DraggableList
                      items={itemsOther}
                      onDragEnd={onDragEnd}
                      onHandleOpenDialog={onHandleOpenDialog}
                    />
                  </Paper>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>

          </Grid>
          <Dialog
            className="dialog1"
            open={openDialog}
            onClose={handleClose}
            fullScreen
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                width: "1200px", // Adjust the width as needed
                maxWidth: "none",
              },
            }}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
                <Typography
                  // className={selected ? "symptom-text" : ""}
                  style={{ fontWeight: 600, marginLeft: 0 }}
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                >
                  Investigation Edit
                </Typography>
              </Toolbar>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </AppBar>
            <DialogContent className="padding-remove-Investigation-Edit main-dialog-background-color">
              <InvestigationInstructionSave
                handleClose={handleClose}
              />
            </DialogContent>
            <Divider />
            <DialogActions className="padding-add-investigation-templete-save-button">
              <Grid item xs={12}>
                <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
                  <LoadingButton
                    color="primary"
                    tabIndex={3}
                    loadingPosition="start"
                    startIcon={<></>}
                    variant="contained"
                    type="submit"
                    onClick={handleClose}
                    className="primary-button"
                    name="submit"
                  >
                    Save
                  </LoadingButton>
                  <LoadingButton
                    tabIndex={3}
                    loadingPosition="start"
                    startIcon={<></>}
                    variant="outlined"
                    type="submit"
                    name="submit"
                    className="secondary-button"
                    color="secondary"
                    onClick={handleClose}                >
                    Cancel
                  </LoadingButton>
                </Box>
              </Grid>
            </DialogActions>
          </Dialog>
        </Grid>
      </Formik>
    </Box>
  )
}