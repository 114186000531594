import "../../../../../../theme/style.scss";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  Typography,
  Dialog,
  DialogContent,
  Button,
  List,
  ListItem,
} from "@mui/material";
import React, { useState } from "react";
import { Formik } from "formik";
import ReactQuill from "react-quill";
import ChiefComplaintsDraggableTable from "../../ChiefComplaints/ChiefComplaintsDraggable/ChiefComplaintsDraggableTable";
import { reorder } from "../../../../../../Helpers/helpers";
import { DropResult } from "react-beautiful-dnd";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { AsyncCustomSelect, CustomSelect } from "../../../../../../components/CustomFields/CustomSelect";
import ExaminationDraggableTable from "../ExaminationDraggable/ExaminationDraggableTable";
import useMediaQuery from '@mui/material/useMediaQuery'
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import SubjectRoundedIcon from "@mui/icons-material/SubjectRounded";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const addmissionState = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
]

export default function RespiratorySystem() {
  const [selectedInspection, setSelectedInspection] = useState("");
  const [selectedPalpation, setSelectedPalpation] = useState("");
  const [selectedPercussion, setSelectedPercussion] = useState("");
  const [selectedAuscultation, setSelectedAuscultation] = useState("");
  const [showNote, setShowNote] = useState(false);
  const [showNotePaplation, setShowNotePaplation] = useState(false);
  const [showNotePercussion, setShowNotePercussion] = useState(false);
  const [showNoteAusculation, setShowNoteAusculation] = useState(false);
  const smallScreen = useMediaQuery("(max-width:768px)");
  const mobileScreen = useMediaQuery("(max-width:425px)");

  const [inspection, setInspection] = React.useState([
    {
      id: "Item 1",
      symptom: "Respiratory rhythm",
    },
    {
      id: "Item 2",
      symptom: "Chest movements",
    },
    {
      id: "Item 3",
      symptom: "Chest retractions",
    },
    {
      id: "Item 4",
      symptom: "Any chest deformity",
    },
    {
      id: "Item 5",
      symptom: "Apex Impulse",
    },
    {
      id: "Item 6",
      symptom: "Any Scar swelling or lump",
    },
    {
      id: "Item 7",
      symptom: "Back",
    },
  ]);
  const [palpation, setPalpation] = React.useState([
    // {
    //   id: "Item 1",
    //   symptom: "Confirmation of inspection findings",
    // },
    {
      id: "Item 2",
      symptom: "Chest movements",
    },
    {
      id: "Item 3",
      symptom: "Chest deformity",
    },
    {
      id: "Item 4",
      symptom: "Apex Impulse",
    },
    {
      id: "Item 5",
      symptom: "Any Scar swelling or lump",
    },
    {
      id: "Item 6",
      symptom: "Back",
    },
    {
      id: "Item 7",
      symptom: "Tenderness at any site",
    },
  ]);
  const [percussion, setPercussion] = React.useState([
    {
      id: "Item 1",
      symptom: "Abnormal resonant",
    },
    {
      id: "Item 2",
      symptom: "Cardiac Dullness",
    },
    {
      id: "Item 3",
      symptom: "Liver Dullness",
    },
    {
      id: "Item 4",
      symptom: "Other Dull notes",
    },
    {
      id: "Item 5",
      symptom: "Any hyperresonant notes",
    },
  ]);
  const [auscultation, setAuscultation] = React.useState([
    {
      id: "Item 1",
      symptom: "Breath sounds",
    },
    {
      id: "Item 2",
      symptom: "Adventitious sounds",
    },
  ]);

  const [openInspection, setOpenInspection] = React.useState(false);

  const handleClickOpenInspection = (value: boolean) => {
  if(smallScreen){

    setOpenInspection(value);
  } else if(mobileScreen){
    
    setOpenInspection(value);
  }

  };
  const handleClickCloseInspection = () => {
    setOpenInspection(false);
  };

  const [openPalpation, setOpenPalpation] = React.useState(false);

  const handleClickOpenPalpation = (value: boolean) => {

    if(smallScreen){

      setOpenPalpation(value);
    } else if(mobileScreen){
      
      setOpenPalpation(value);
    }
   
  };
  const handleClickClosePalpation = () => {
    setOpenPalpation(false);
  };

  const [openPercussion, setOpenPercussion] = React.useState(false);

  const handleClickOpenPercussion = (value: boolean) => {
    
    if(smallScreen){

      setOpenPercussion(value);
    } else if(mobileScreen){
      
      setOpenPercussion(value);
    }
   
  };

  const handleClickClosePercussion = () => {
    setOpenPercussion(false);
  };

  const [openAuscultation, setOpenAuscultation] = React.useState(false);

  const handleClickOpenAuscultation = (value: boolean) => {
    
    if(smallScreen){

      setOpenAuscultation(value);
    } else if(mobileScreen){
      
      setOpenAuscultation(value);
    }

  };

  const handleClickCloseAuscultation = () => {
    setOpenAuscultation(false);
  };

  // const onDragInspectionEnd = ({ destination, source }: DropResult) => {
  //   if (!destination) return;
  //   const newItems = reorder(inspection, source.index, destination.index);
  //   setInspection(newItems);
  // };
  // const onDragPalpationEnd = ({ destination, source }: DropResult) => {
  //   if (!destination) return;
  //   const newItems = reorder(palpation, source.index, destination.index);
  //   setPalpation(newItems);
  // };
  // const onDragPercussionEnd = ({ destination, source }: DropResult) => {
  //   if (!destination) return;
  //   const newItems = reorder(percussion, source.index, destination.index);
  //   setPercussion(newItems);
  // };
  // const onDragAuscultationEnd = ({ destination, source }: DropResult) => {
  //   if (!destination) return;
  //   const newItems = reorder(auscultation, source.index, destination.index);
  //   setAuscultation(newItems);
  // };

  // const onHandleSelectInspection = (val) => {
  //   if(smallScreen){
  //     handleClickOpenInspection(true);
  //   }else if(mobileScreen){
  //     handleClickOpenInspection(true);
  //   }
  //   setSelectedInspection(val);
  // };

  // const onHandleSelectPalpation = (val) => {
  //   if(smallScreen){
  //     handleClickOpenPalpation(true);
  //   }else if(mobileScreen){
  //     handleClickOpenPalpation(true);
  //   }
  //   setSelectedPalpation(val);
  // };
  
  // const onHandleSelectPercussion = (val) => {
  //   if(smallScreen){
  //     handleClickOpenPercussion(true);
  //   }else if(mobileScreen){
  //     handleClickOpenPercussion(true);
  //   }
  //   setSelectedPercussion(val);
  // };
  // const onHandleSelectAuscultation = (val) => {
    
  //   if(smallScreen){
  //     handleClickOpenAuscultation(true);
  //   }else if(mobileScreen){
  //     handleClickOpenAuscultation(true);
  //   }
    
  //   setSelectedAuscultation(val);
  // };

  const [openRespiratorySummary, setopenRespiratorySummary] = React.useState(false);

  const handleClickOpenRespiratorySummary = (value: boolean) => {
    if (mobileScreen) {
      setopenRespiratorySummary(value);
    }
    else if (smallScreen) {
      setopenRespiratorySummary(value);
    }
  };

  const handleClickCloseRespiratorySummary = () => {
    setopenRespiratorySummary(false);
  };

  const [openPaplationSummary, setopenPaplationSummary] = React.useState(false);

  const handleClickOpenPaplationSummary = (value: boolean) => {
    if (mobileScreen) {
      setopenPaplationSummary(value);
    }
    else if (smallScreen) {
      setopenPaplationSummary(value);
    }
  };

  const handleClickClosePaplationSummary = () => {
    setopenPaplationSummary(false);
  };

  const [openPercussionSummary, setopenPercussionSummary] = React.useState(false);

  const handleClickOpenPercussionSummary = (value: boolean) => {
    if (mobileScreen) {
      setopenPercussionSummary(value);
    }
    else if (smallScreen) {
      setopenPercussionSummary(value);
    }
  };

  const handleClickClosePercussionSummary = () => {
    setopenPercussionSummary(false);
  };

  const [openAuscultationSummary, setopenAuscultationSummary] = React.useState(false);

  const handleClickOpenAuscultationSummary = (value: boolean) => {
    if (mobileScreen) {
      setopenAuscultationSummary(value);
    }
    else if (smallScreen) {
      setopenAuscultationSummary(value);
    }
  };

  const handleClickCloseAuscultationSummary = () => {
    setopenAuscultationSummary(false);
  };


  // // Inspection Summary
  // const [openInspectionSummary, setopenInspectionSummary] = React.useState(false);

  // const handleClickOpenInspectionSummary = (value: boolean) => {
  //   if (mobileScreen) {
  //     setopenInspectionSummary(value);
  //   }
  //   else if (smallScreen) {
  //     setopenInspectionSummary(value);
  //   }
  // };

  // const handleClickCloseInspectionSummary = () => {
  //   setopenInspectionSummary(false);
  // };

  //   // General Physical Examination summary
  //   const [openPalpationSummary, setopenPalpationSummary] = React.useState(false);

  //   const handleClickOpenPalpationSummary = (value: boolean) => {
  //     if (mobileScreen) {
  //       setopenPalpationSummary(value);
  //     }
  //     else if (smallScreen) {
  //       setopenPalpationSummary(value);
  //     }
  //   };
  
  //   const handleClickClosePalpationSummary = () => {
  //     setopenPalpationSummary(false);
  //   };

  //     // General Physical Examination summary
  // const [openPercussionSummary, setopenPercussionSummary] = React.useState(false);

  // const handleClickOpenPercussionSummary = (value: boolean) => {
  //   if (mobileScreen) {
  //     setopenPercussionSummary(value);
  //   }
  //   else if (smallScreen) {
  //     setopenPercussionSummary(value);
  //   }
  // };

  // const handleClickClosePercussionSummary = () => {
  //   setopenPercussionSummary(false);
  // };

  return (
    <Box className="main-container">
      <Grid container spacing={0}>
        <Grid item xs={12} mb={2}>
          <Grid container item spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
            <Box
                className="template-table-container"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                }}
              >
                <Box className="inner-title-container">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box className="inner-header-container"
                    sx={{display:"flex",  justifyContent:"space-between"}}
                    >
                      <Typography style={{ fontWeight: 600 }}>
                        Inspection
                      </Typography>
                      {mobileScreen || smallScreen ? <Box>
                        <Button onClick={() => handleClickOpenRespiratorySummary(true)} sx={{ padding: 0, margin: 0,minWidth:"30px" }} aria-label="delete">
                         <SubjectRoundedIcon></SubjectRoundedIcon>
                        </Button>
              </Box> : ''}
                    </Box>
                  </Paper>
                  <Divider />
                  <List>
                    {inspection.map((item: any, index: number) => (
                      <ListItem
                        sx={{
                          borderBottom: "1px solid #E9EBEC",
                          cursor: 'pointer'
                        }}
                        className={
                          selectedInspection === item.symptom
                            ? "draggable-list-item"
                            : " "
                        }
                      >
                        <Grid container item spacing={2}>
                          <Grid item xs={12} sm={12} md={12}>
                            <Box
                              style={{
                                display: "flex",
                              }}
                              onClick = {()=>{handleClickOpenInspection(true)}}
                            >
                              <Grid container item>
                                <Grid item xs={12} sm={12} md={12}>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() => {
                                      setSelectedInspection(item.symptom)
                                    }}
                                  >
                                    <Box
                                      className={
                                        selectedInspection === item.symptom
                                          ? "symptom-text"
                                          : " "
                                      }
                                      sx={{ paddingBottom: '8px', paddingTop: '8px' }}
                                   
                                      >
                                      <Typography>{item.symptom}</Typography>
                                    </Box>

                                    {selectedInspection === item.symptom && (
                                      <Box
                                        style={{
                                          paddingTop: "8px",
                                          paddingLeft: '8px'
                                        }}
                                      >
                                        <KeyboardDoubleArrowRightRoundedIcon />
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                  </List>

                  <Box className="note-container">
                    <Box>
                      {!showNote && (
                        <Button
                          variant="text"
                          style={{ fontSize: "6px" }}
                          onClick={() => {
                            setShowNote(true);
                          }}
                        >
                          <AddIcon className="add-icon" />
                          <span className="add-icon"> Note</span>
                        </Button>
                      )}
                    </Box>

                    {showNote && (
                      <FormControl fullWidth>
                        <Box className="note-container-box">
                          <FormLabel
                            sx={{ paddingLeft: 1 }}
                            className="fileds-label-text"
                          >
                            Note
                          </FormLabel>
                          <Button
                            variant="text"
                            onClick={() => {
                              setShowNote(false);
                            }}
                          >
                            <RemoveIcon className="remove-note" />
                            <span className="remove-note">Remove Note</span>
                          </Button>
                        </Box>
                        <ReactQuill
                          className="react-quill-alignment"
                          theme="snow"
                          value={""}
                          onChange={(e) => { }}
                        />
                      </FormControl>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container template-hide-small-screen template-hide-medium-screen"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                }}
                style = {{position:"sticky", top:"5px"}}
              >
                <Box
                  style={{
                    flex: 1,
                    borderRadius: 8,
                    minHeight: 400,
                  }}
                >
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="inner-header-container">
                      <Typography
                        className={selectedInspection ? "symptom-text" : ""}
                        style={{ fontWeight: 600 }}
                      >
                        {selectedInspection ? selectedInspection : "Inspection Summary"}
                      </Typography>
                      {selectedInspection !== '' && <Typography>
                        <IconButton onClick={() => setSelectedInspection("")} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                          <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                        </IconButton>
                      </Typography>}
                    </Box>

                  </Paper>
                  {selectedInspection ? (
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                        <Divider />
                        <Grid
                          container
                          item
                          spacing={2}
                          style={{ padding: 20 }}
                        >
                          {selectedInspection === "Respiratory rhythm" && (
                            <Grid item xs={8} sm={6} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Respiratory rhythm
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"respiratoryReason"}
                                  isClearable={true}
                                  FieldName={`respiratoryReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedInspection === "Respiratory rhythm" && (
                            <Grid item xs={8} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedInspection === "Chest movements" && (
                            <Grid item xs={8} sm={6} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Chest movements
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedInspection === "Chest movements" && (
                            <Grid item xs={8} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedInspection === "Chest retractions" && (
                            <Grid item xs={8} sm={6} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Chest retractions
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedInspection === "Chest retractions" && (
                            <Grid item xs={8} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Site of chest retractions
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedInspection === "Chest retractions" && (
                            <Grid item xs={8} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedInspection === "Any chest deformity" && (
                            <Grid item xs={8} sm={6} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Any chest deformity
                                </FormLabel>
                                <CustomSelect
                                isNotCreatable={true}
                                id={"admission"}
                                FieldName={"admission"}
                                options={addmissionState}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e);
                                }}
                                Value=""
                              />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedInspection === "Any chest deformity" && (
                            <Grid item xs={8} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedInspection === "Apex Impulse" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Apex Impulse
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )} */}
                          {selectedInspection === "Apex Impulse" && (
                            <Grid item xs={8} sm={6} md={6}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Apex Impulse
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedInspection === "Apex Impulse" && (
                            <Grid item xs={8} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedInspection ===
                            "Any Scar swelling or lump" && (
                              <Grid item xs={8} sm={6} md={12}>
                                <FormControl fullWidth>
                                  <FormLabel className="fileds-label-text">
                                    Any Scar swelling or lump
                                  </FormLabel>
                                  <CustomSelect
                                isNotCreatable={true}
                                id={"admission"}
                                FieldName={"admission"}
                                options={addmissionState}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e);
                                }}
                                Value=""
                              />
                                </FormControl>
                              </Grid>
                            )}
                          {selectedInspection ===
                            "Any Scar swelling or lump" && (
                              <Grid item xs={8} sm={12} md={12}>
                                <FormControl fullWidth>
                                  <FormLabel className="fileds-label-text">
                                    Note
                                  </FormLabel>
                                  <ReactQuill
                                    className="react-quill-alignment"
                                    theme="snow"
                                    value={""}
                                    onChange={(e) => { }}
                                  />
                                </FormControl>
                              </Grid>
                            )}
                          {selectedInspection === "Back" && (
                            <Grid item xs={8} sm={6} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Back
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedInspection === "Back" && (
                            <Grid item xs={8} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Formik>
                  ) : (
                    <Box>  <Divider />
                      <Box className="summary-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mb={2}>
          <Grid container item spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
            <Box
                className="template-table-container"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                }}
              >
                <Box className="inner-title-container">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box className="inner-header-container"
                    sx={{display:"flex",  justifyContent:"space-between"}}
                    >
                      <Typography style={{ fontWeight: 600 }}>
                        Palpation
                      </Typography>
                      {mobileScreen || smallScreen ? <Box>
                        <Button onClick={() => handleClickOpenPaplationSummary(true)} sx={{ padding: 0, margin: 0,minWidth:"30px" }} aria-label="delete">
                         <SubjectRoundedIcon></SubjectRoundedIcon>
                        </Button>
              </Box> : ''}
                    </Box>
                  </Paper>
                  <Divider />
                  <List>
                    {palpation.map((item: any, index: number) => (
                      <ListItem
                        sx={{
                          borderBottom: "1px solid #E9EBEC",
                          cursor: 'pointer'
                        }}
                        className={
                          selectedPalpation === item.symptom
                            ? "draggable-list-item"
                            : " "
                        }
                      >
                        <Grid container item spacing={2}>
                          <Grid item xs={12} sm={12} md={12}>
                            <Box
                              style={{
                                display: "flex",
                              }}
                              onClick = {()=>{handleClickOpenPalpation(true)}}
                            >
                              <Grid container item>
                                <Grid item xs={12} sm={12} md={12}>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() => {
                                      setSelectedPalpation(item.symptom)
                                    }}
                                  >
                                    <Box
                                      className={
                                        selectedPalpation === item.symptom
                                          ? "symptom-text"
                                          : " "
                                      }
                                      sx={{ paddingBottom: '8px', paddingTop: '8px' }}
                                     
                                      >
                                      <Typography>{item.symptom}</Typography>
                                    </Box>

                                    {selectedPalpation === item.symptom && (
                                      <Box
                                        style={{
                                          paddingTop: "8px",
                                          paddingLeft: '8px'
                                        }}
                                      >
                                        <KeyboardDoubleArrowRightRoundedIcon />
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                  </List>

                  <Box className="note-container">
                    <Box>
                      {!showNotePaplation && (
                        <Button
                          variant="text"
                          style={{ fontSize: "6px" }}
                          onClick={() => {
                            setShowNotePaplation(true);
                          }}
                        >
                          <AddIcon className="add-icon" />
                          <span className="add-icon"> Note</span>
                        </Button>
                      )}
                    </Box>

                    {showNotePaplation && (
                      <FormControl fullWidth>
                        <Box className="note-container-box">
                          <FormLabel
                            sx={{ paddingLeft: 1 }}
                            className="fileds-label-text"
                          >
                            Note
                          </FormLabel>
                          <Button
                            variant="text"
                            onClick={() => {
                              setShowNotePaplation(false);
                            }}
                          >
                            <RemoveIcon className="remove-note" />
                            <span className="remove-note">Remove Note</span>
                          </Button>
                        </Box>
                        <ReactQuill
                          className="react-quill-alignment"
                          theme="snow"
                          value={""}
                          onChange={(e) => { }}
                        />
                      </FormControl>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container template-hide-small-screen template-hide-medium-screen"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                }}
                style = {{position:"sticky", top:"5px"}}
              >
                <Box
                  style={{
                    flex: 1,
                    borderRadius: 8,
                    minHeight: 400,
                  }}
                >
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="inner-header-container">
                      <Typography
                        className={selectedPalpation ? "symptom-text" : ""}
                        style={{ fontWeight: 600 }}
                      >
                        {selectedPalpation ? selectedPalpation : "Palpation Summary"}
                      </Typography>
                      {selectedPalpation !== '' && <Typography>
                        <IconButton onClick={() => setSelectedPalpation("")} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                          <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                        </IconButton>
                      </Typography>}
                    </Box>
                  </Paper>
                  {selectedPalpation ? (
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                        <Divider />
                        <Grid
                          container
                          item
                          spacing={2}
                          style={{ padding: 20 }}
                        >
                        
                          {/* {selectedPalpation ===
                            "Confirmation of inspection findings" && (
                              <Grid item xs={8} sm={4} md={12}>
                                <FormControl fullWidth>
                                  <FormLabel className="fileds-label-text">
                                    Note
                                  </FormLabel>
                                  <ReactQuill
                                    className="react-quill-alignment"
                                    theme="snow"
                                    value={""}
                                    onChange={(e) => { }}
                                  />
                                </FormControl>
                              </Grid>
                            )} */}
                          {selectedPalpation === "Chest movements" && (
                            <Grid item xs={8} sm={6} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Chest movements
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}

                          {selectedPalpation === "Chest movements" && (
                            <Grid item xs={8} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}

                          {selectedPalpation === "Chest deformity" && (
                            <Grid item xs={8} sm={6} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Chest deformity
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedPalpation === "Chest deformity" && (
                            <Grid item xs={8} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedPalpation === "Apex Impulse" && (
                            <Grid item xs={8} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedPalpation ===
                            "Any Scar swelling or lump" && (
                              <Grid item xs={8} sm={6} md={12}>
                                <FormControl fullWidth>
                                  <FormLabel className="fileds-label-text">
                                    Any Scar swelling or lump
                                  </FormLabel>
                                  <CustomSelect
                                isNotCreatable={true}
                                id={"admission"}
                                FieldName={"admission"}
                                options={addmissionState}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e);
                                }}
                                Value=""
                              />
                                </FormControl>
                              </Grid>
                            )}
                          {selectedPalpation ===
                            "Any Scar swelling or lump" && (
                              <Grid item xs={8} sm={12} md={12}>
                                <FormControl fullWidth>
                                  <FormLabel className="fileds-label-text">
                                    Note
                                  </FormLabel>
                                  <ReactQuill
                                    className="react-quill-alignment"
                                    theme="snow"
                                    value={""}
                                    onChange={(e) => { }}
                                  />
                                </FormControl>
                              </Grid>
                            )}

                          {selectedPalpation === "Back" && (
                            <Grid item xs={8} sm={6} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Back
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedPalpation === "Back" && (
                            <Grid item xs={8} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedPalpation === "Tenderness at any site" && (
                            <Grid item xs={8} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}

                        </Grid>
                      </Box>
                    </Formik>
                  ) : (
                    <Box>  <Divider />
                      <Box className="summary-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mb={2}>
          <Grid container item spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
            <Box
                className="template-table-container"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                }}
              >
                <Box className="inner-title-container">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box className="inner-header-container"
                    sx={{display:"flex",  justifyContent:"space-between"}}
                    >
                      <Typography style={{ fontWeight: 600 }}>
                        Percussion
                      </Typography>
                      {mobileScreen || smallScreen ? <Box>
                        <Button onClick={() => handleClickOpenPercussionSummary(true)} sx={{ padding: 0, margin: 0,minWidth:"30px" }} aria-label="delete">
                         <SubjectRoundedIcon></SubjectRoundedIcon>
                        </Button>
              </Box> : ''}
                    </Box>
                  </Paper>
                  <Divider />
                  <List>
                    {percussion.map((item: any, index: number) => (
                      <ListItem
                        sx={{
                          borderBottom: "1px solid #E9EBEC",
                          cursor: 'pointer'
                        }}
                        className={
                          selectedPercussion === item.symptom
                            ? "draggable-list-item"
                            : " "
                        }
                      >
                        <Grid container item spacing={2}>
                          <Grid item xs={12} sm={12} md={12}>
                            <Box
                              style={{
                                display: "flex",
                              }}
                              onClick = {()=>{handleClickOpenPercussion(true)}}
                            >
                              <Grid container item>
                                <Grid item xs={12} sm={12} md={12}>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() => {
                                      setSelectedPercussion(item.symptom)
                                    }}
                                  >
                                    <Box
                                      className={
                                        selectedPercussion === item.symptom
                                          ? "symptom-text"
                                          : " "
                                      }
                                      sx={{ paddingBottom: '8px', paddingTop: '8px' }}
                                     
                                      >
                                      <Typography>{item.symptom}</Typography>
                                    </Box>

                                    {selectedPercussion === item.symptom && (
                                      <Box
                                        style={{
                                          paddingTop: "8px",
                                          paddingLeft: '8px'
                                        }}
                                      >
                                        <KeyboardDoubleArrowRightRoundedIcon />
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                  </List>

                  <Box className="note-container">
                    <Box>
                      {!showNotePercussion && (
                        <Button
                          variant="text"
                          style={{ fontSize: "6px" }}
                          onClick={() => {
                            setShowNotePercussion(true);
                          }}
                        >
                          <AddIcon className="add-icon" />
                          <span className="add-icon"> Note</span>
                        </Button>
                      )}
                    </Box>

                    {showNotePercussion && (
                      <FormControl fullWidth>
                        <Box className="note-container-box">
                          <FormLabel
                            sx={{ paddingLeft: 1 }}
                            className="fileds-label-text"
                          >
                            Note
                          </FormLabel>
                          <Button
                            variant="text"
                            onClick={() => {
                              setShowNotePercussion(false);
                            }}
                          >
                            <RemoveIcon className="remove-note" />
                            <span className="remove-note">Remove Note</span>
                          </Button>
                        </Box>
                        <ReactQuill
                          className="react-quill-alignment"
                          theme="snow"
                          value={""}
                          onChange={(e) => { }}
                        />
                      </FormControl>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container template-hide-small-screen template-hide-medium-screen"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                }}
                style = {{position:"sticky", top:"5px"}}
              >
                <Box
                  style={{
                    flex: 1,
                    borderRadius: 8,
                    minHeight: 400,
                  }}
                >
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="inner-header-container">
                      <Typography
                        className={selectedPercussion ? "symptom-text" : ""}
                        style={{ fontWeight: 600 }}
                      >
                        {selectedPercussion ? selectedPercussion : "Percussion Summary"}
                      </Typography>
                      {selectedPercussion !== '' && <Typography>
                        <IconButton onClick={() => setSelectedPercussion("")} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                          <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                        </IconButton>
                      </Typography>}
                    </Box>
                  </Paper>
                  {selectedPercussion ? (
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                        <Divider />
                        <Grid
                          container
                          item
                          spacing={2}
                          style={{ padding: 20 }}
                        >
                          {selectedPercussion === "Abnormal resonant" && (
                            <Grid item xs={8} sm={6} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Abnormal resonant
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedPercussion === "Abnormal resonant" && (
                            <Grid item xs={8} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                 Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedPercussion === "Cardiac Dullness" && (
                            <Grid item xs={8} sm={6} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Cardiac Dullness
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedPercussion === "Cardiac Dullness" && (
                            <Grid item xs={8} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedPercussion === "Liver Dullness" && (
                            <Grid item xs={8} sm={6} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Liver Dullness
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedPercussion === "Liver Dullness" && (
                            <Grid item xs={8} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedPercussion === "Other Dull notes" && (
                            <Grid item xs={8} sm={6} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Other Dull notes
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedPercussion === "Other Dull notes" && (
                            <Grid item xs={8} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}

                          {selectedPercussion === "Any hyperresonant notes" && (
                            <Grid item xs={8} sm={6} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Any hyperresonant notes
                                </FormLabel>
                                <CustomSelect
                                isNotCreatable={true}
                                id={"admission"}
                                FieldName={"admission"}
                                options={addmissionState}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e);
                                }}
                                Value=""
                              />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedPercussion === "Any hyperresonant notes" && (
                            <Grid item xs={8} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedPercussion === "Tenderness at any site" && (
                            <Grid item xs={8} sm={6} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Tenderness at any site
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedPercussion === "Tenderness at any site" && (
                            <Grid item xs={8} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Formik>
                  ) : (
                    <Box>
                      <Divider />
                      <Box className="summary-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mb={2}>
          <Grid container item spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
            <Box
                className="template-table-container"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                }}
              >
                <Box className="inner-title-container">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box className="inner-header-container"
                    sx={{display:"flex",  justifyContent:"space-between"}}
                    >
                      <Typography style={{ fontWeight: 600 }}>
                       Auscultation
                      </Typography>
                      {mobileScreen || smallScreen ? <Box>
                        <Button onClick={() => handleClickOpenAuscultationSummary(true)} sx={{ padding: 0, margin: 0,minWidth:"30px"}} aria-label="delete">
                         <SubjectRoundedIcon></SubjectRoundedIcon>
                        </Button>
              </Box> : ''}
                    </Box>
                  </Paper>
                  <Divider />
                  <List>
                    {auscultation.map((item: any, index: number) => (
                      <ListItem
                        sx={{
                          borderBottom: "1px solid #E9EBEC",
                          cursor: 'pointer'
                        }}
                        className={
                          selectedAuscultation === item.symptom
                            ? "draggable-list-item"
                            : " "
                        }
                      >
                        <Grid container item spacing={2}>
                          <Grid item xs={12} sm={12} md={12}>
                            <Box
                              style={{
                                display: "flex",
                              }}
                              onClick = {()=>{handleClickOpenAuscultation(true)}}
                            >
                              <Grid container item>
                                <Grid item xs={12} sm={12} md={12}>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() => {
                                      setSelectedAuscultation(item.symptom)
                                    }}
                                  >
                                    <Box
                                      className={
                                        selectedAuscultation === item.symptom
                                          ? "symptom-text"
                                          : " "
                                      }
                                      sx={{ paddingBottom: '8px', paddingTop: '8px' }}
                                     
                                      >
                                      <Typography>{item.symptom}</Typography>
                                    </Box>

                                    {selectedAuscultation === item.symptom && (
                                      <Box
                                        style={{
                                          paddingTop: "8px",
                                          paddingLeft: '8px'
                                        }}
                                      >
                                        <KeyboardDoubleArrowRightRoundedIcon />
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                  </List>

                  <Box className="note-container">
                    <Box>
                      {!showNoteAusculation && (
                        <Button
                          variant="text"
                          style={{ fontSize: "6px" }}
                          onClick={() => {
                            setShowNoteAusculation(true);
                          }}
                        >
                          <AddIcon className="add-icon" />
                          <span className="add-icon"> Note</span>
                        </Button>
                      )}
                    </Box>

                    {showNoteAusculation && (
                      <FormControl fullWidth>
                        <Box className="note-container-box">
                          <FormLabel
                            sx={{ paddingLeft: 1 }}
                            className="fileds-label-text"
                          >
                            Note
                          </FormLabel>
                          <Button
                            variant="text"
                            onClick={() => {
                              setShowNoteAusculation(false);
                            }}
                          >
                            <RemoveIcon className="remove-note" />
                            <span className="remove-note">Remove Note</span>
                          </Button>
                        </Box>
                        <ReactQuill
                          className="react-quill-alignment"
                          theme="snow"
                          value={""}
                          onChange={(e) => { }}
                        />
                      </FormControl>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container template-hide-small-screen template-hide-medium-screen"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                }}
                style = {{position:"sticky", top:"5px"}}
              >
                <Box
                  style={{
                    flex: 1,
                    borderRadius: 8,
                    minHeight: 400,
                  }}
                >
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="inner-header-container">
                      <Typography
                        className={selectedAuscultation ? "symptom-text" : ""}
                        style={{ fontWeight: 600 }}
                      >
                        {selectedAuscultation ? selectedAuscultation : "Auscultation Summary"}
                      </Typography>
                      {selectedAuscultation !== '' && <Typography>
                        <IconButton onClick={() => setSelectedAuscultation("")} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                          <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                        </IconButton>
                      </Typography>}
                    </Box>
                  </Paper>
                  {selectedAuscultation ? (
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                        <Divider />
                        <Grid
                          container
                          item
                          spacing={2}
                          style={{ padding: 20 }}
                        >
                          {selectedAuscultation === "Breath sounds" && (
                            <Grid item xs={8} sm={6} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Breath sounds
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedAuscultation === "Breath sounds" && (
                            <Grid item xs={8} sm={7} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Type of abnormal breath sounds
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedAuscultation === "Breath sounds" && (
                            <Grid item xs={8} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedAuscultation === "Adventitious sounds" && (
                            <Grid item xs={8} sm={6} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Adventitious sounds
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedAuscultation === "Adventitious sounds" && (
                            <Grid item xs={8} sm={7} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Type of adventitious sounds
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedAuscultation === "Adventitious sounds" && (
                            <Grid item xs={8} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Formik>
                  ) : (
                    <Box>  <Divider />
                      <Box className="summary-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
                fullScreen
                open={openInspection}
                onClose={handleClickCloseInspection}
       
            >
               <AppBar sx={{ position: 'relative' }}>
          <Toolbar
            sx={{ display:"flex",
            justifyContent:"space-between"}}>
            <Typography
              style={{ fontWeight: 600, margin: 1 }}
              sx={{
                paddingLeft:{
                  xs:1,
                  sm:0,
                }
              }}
              variant="h6"
            >
       {selectedInspection ? selectedInspection : "Inspection"}
            </Typography>
            <IconButton onClick={() => handleClickCloseInspection(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
              <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
            </IconButton>
          </Toolbar>
          <Divider />
        </AppBar>
                <DialogContent style={{ height: 400 }}>
                <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container"
                sx={{
                  border: "0px solid #0000001f",
                  borderRadius: 1,
                }}
              >
                <Box
                  style={{
                    flex: 1,
                    borderRadius: 8,
                    minHeight: 400,
                  }}
                >
                  
                  {selectedInspection ? (
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                      
                        <Grid
                          container
                          item
                          spacing={2}
                        
                        >
                          {selectedInspection === "Respiratory rhythm" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Respiratory rhythm
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedInspection === "Respiratory rhythm" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedInspection === "Chest movements" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Chest movements
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedInspection === "Chest movements" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedInspection === "Chest retractions" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Chest retractions
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedInspection === "Chest retractions" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Site of chest retractions
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedInspection === "Chest retractions" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedInspection === "Any chest deformity" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Any chest deformity
                                </FormLabel>
                                <CustomSelect
                                isNotCreatable={true}
                                id={"admission"}
                                FieldName={"admission"}
                                options={addmissionState}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e);
                                }}
                                Value=""
                              />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedInspection === "Any chest deformity" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedInspection === "Apex Impulse" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Apex Impulse
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )} */}
                          {selectedInspection === "Apex Impulse" && (
                            <Grid item xs={12} sm={12} md={6}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Apex Impulse
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedInspection === "Apex Impulse" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedInspection ===
                            "Any Scar swelling or lump" && (
                              <Grid item xs={12} sm={12} md={12}>
                                <FormControl fullWidth>
                                  <FormLabel className="fileds-label-text">
                                    Any Scar swelling or lump
                                  </FormLabel>
                                  <CustomSelect
                                isNotCreatable={true}
                                id={"admission"}
                                FieldName={"admission"}
                                options={addmissionState}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e);
                                }}
                                Value=""
                              />
                                </FormControl>
                              </Grid>
                            )}
                          {selectedInspection ===
                            "Any Scar swelling or lump" && (
                              <Grid item xs={12} sm={12} md={12}>
                                <FormControl fullWidth>
                                  <FormLabel className="fileds-label-text">
                                    Note
                                  </FormLabel>
                                  <ReactQuill
                                    className="react-quill-alignment"
                                    theme="snow"
                                    value={""}
                                    onChange={(e) => { }}
                                  />
                                </FormControl>
                              </Grid>
                            )}
                          {selectedInspection === "Back" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Back
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedInspection === "Back" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Formik>
                  ) : (
                    <Box>  <Divider />
                      <Box>This is summary section </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
         
                </DialogContent>
     </Dialog>
          
       <Dialog
                fullScreen
                open={openPalpation}
                onClose={handleClickClosePalpation}
       
            >
               <AppBar sx={{ position: 'relative' }}>
             <Toolbar 
              sx={{ display:"flex",
              justifyContent:"space-between"}}>
            <Typography
            
              style={{ fontWeight: 600, margin: 1 }}
              sx={{
                paddingLeft:{
                  xs:1,
                  sm:0,
                }
              }}
              variant="h6"
            >
       {selectedPalpation ? selectedPalpation : "Palpation"}
            </Typography>
            <IconButton onClick={() => handleClickClosePalpation(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
              <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
            </IconButton>
          </Toolbar>
          <Divider />
        </AppBar>
                <DialogContent style={{ height: 400 }}>
                <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container"
                sx={{
                  border: "0px solid #0000001f",
                  borderRadius: 1,
                }}
              >
                <Box
                  style={{
                    flex: 1,
                    borderRadius: 8,
                    minHeight: 400,
                  }}
                >
                
                  {selectedPalpation ? (
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                    
                        <Grid
                          container
                          item
                          spacing={2}
                          
                        >
                        
                          {/* {selectedPalpation ===
                            "Confirmation of inspection findings" && (
                              <Grid item xs={8} sm={4} md={12}>
                                <FormControl fullWidth>
                                  <FormLabel className="fileds-label-text">
                                    Note
                                  </FormLabel>
                                  <ReactQuill
                                    className="react-quill-alignment"
                                    theme="snow"
                                    value={""}
                                    onChange={(e) => { }}
                                  />
                                </FormControl>
                              </Grid>
                            )} */}
                          {selectedPalpation === "Chest movements" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Chest movements
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}

                          {selectedPalpation === "Chest movements" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}

                          {selectedPalpation === "Chest deformity" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Chest deformity
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedPalpation === "Chest deformity" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedPalpation === "Apex Impulse" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedPalpation ===
                            "Any Scar swelling or lump" && (
                              <Grid item xs={12} sm={12} md={12}>
                                <FormControl fullWidth>
                                  <FormLabel className="fileds-label-text">
                                    Any Scar swelling or lump
                                  </FormLabel>
                                  <CustomSelect
                                isNotCreatable={true}
                                id={"admission"}
                                FieldName={"admission"}
                                options={addmissionState}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e);
                                }}
                                Value=""
                              />
                                </FormControl>
                              </Grid>
                            )}
                          {selectedPalpation ===
                            "Any Scar swelling or lump" && (
                              <Grid item xs={12} sm={12} md={12}>
                                <FormControl fullWidth>
                                  <FormLabel className="fileds-label-text">
                                    Note
                                  </FormLabel>
                                  <ReactQuill
                                    className="react-quill-alignment"
                                    theme="snow"
                                    value={""}
                                    onChange={(e) => { }}
                                  />
                                </FormControl>
                              </Grid>
                            )}

                          {selectedPalpation === "Back" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Back
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedPalpation === "Back" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedPalpation === "Tenderness at any site" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}

                        </Grid>
                      </Box>
                    </Formik>
                  ) : (
                    <Box>  <Divider />
                      <Box>This is summary section </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
                </DialogContent>
        </Dialog>

        <Dialog
                fullScreen
                open={openPercussion}
                onClose={handleClickClosePercussion}
       
            >
               <AppBar sx={{ position: 'relative' }}>
                <Toolbar
                 sx={{ display:"flex",
                 justifyContent:"space-between"}}>
               <Typography

              style={{ fontWeight: 600, margin: 1 }}
              sx={{
                paddingLeft:{
                  xs:1,
                  sm:0,
                }
              }}
              variant="h6"
            >
       {selectedPercussion ? selectedPercussion : "Percussion"}
            </Typography>
            <IconButton onClick={() => handleClickClosePercussion(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
              <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
            </IconButton>
          </Toolbar>
          <Divider />
        </AppBar>
                <DialogContent style={{ height: 400 }}>
                <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container"
                sx={{
                  border: "0px solid #0000001f",
                  borderRadius: 1,
                }}
              >
                <Box
                  style={{
                    flex: 1,
                    borderRadius: 8,
                    minHeight: 400,
                  }}
                >
                 
                  {selectedPercussion ? (
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                     
                        <Grid
                          container
                          item
                          spacing={2}
                          
                        >
                          {selectedPercussion === "Abnormal resonant" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Abnormal resonant
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedPercussion === "Abnormal resonant" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                 Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedPercussion === "Cardiac Dullness" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Cardiac Dullness
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedPercussion === "Cardiac Dullness" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedPercussion === "Liver Dullness" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Liver Dullness
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedPercussion === "Liver Dullness" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedPercussion === "Other Dull notes" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Other Dull notes
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedPercussion === "Other Dull notes" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}

                          {selectedPercussion === "Any hyperresonant notes" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Any hyperresonant notes
                                </FormLabel>
                                <CustomSelect
                                isNotCreatable={true}
                                id={"admission"}
                                FieldName={"admission"}
                                options={addmissionState}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e);
                                }}
                                Value=""
                              />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedPercussion === "Any hyperresonant notes" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedPercussion === "Tenderness at any site" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Tenderness at any site
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedPercussion === "Tenderness at any site" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Formik>
                  ) : (
                    <Box>
                      <Divider />
                      <Box>This is summary section </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
                </DialogContent>
         </Dialog>

         <Dialog
                fullScreen
                open={openAuscultation}
                onClose={handleClickCloseAuscultation}
       
            >
               <AppBar sx={{ position: 'relative' }}>
          <Toolbar
             sx={{ display:"flex",
             justifyContent:"space-between"}}>
            <Typography
            
              style={{ fontWeight: 600, margin: 1 }}
              sx={{
                paddingLeft:{
                  xs:1,
                  sm:0,
                }
              }}
              variant="h6"
            >
       {selectedAuscultation ? selectedAuscultation : "Auscultation"}
            </Typography>
            <IconButton onClick={() => handleClickCloseAuscultation(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
              <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
            </IconButton>
          </Toolbar>
          <Divider />
        </AppBar>
                <DialogContent style={{ height: 400 }}>
                <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container"
                sx={{
                  border: "0px solid #0000001f",
                  borderRadius: 1,
                }}
              >
                <Box
                  style={{
                    flex: 1,
                    borderRadius: 8,
                    minHeight: 400,
                  }}
                >
                 
                  {selectedAuscultation ? (
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                     
                        <Grid
                          container
                          item
                          spacing={2}
                         
                        >
                          {selectedAuscultation === "Breath sounds" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Breath sounds
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedAuscultation === "Breath sounds" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Type of abnormal breath sounds
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedAuscultation === "Breath sounds" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedAuscultation === "Adventitious sounds" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Adventitious sounds
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedAuscultation === "Adventitious sounds" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Type of adventitious sounds
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedAuscultation === "Adventitious sounds" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Formik>
                  ) : (
                    <Box>  <Divider />
                      <Box>This is summary section </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
                </DialogContent>
         </Dialog>

         <Dialog
            fullScreen
            open={openRespiratorySummary}
            onClose={handleClickCloseRespiratorySummary}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
                <Typography
                  // className={selected ? "symptom-text" : ""}
                  style={{ fontWeight: 600, marginLeft: 0 }}
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                >
                  Inspection Summary
                </Typography>
                <IconButton onClick={() => handleClickCloseRespiratorySummary(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                  <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent style={{ height: 400 }}>
            <Box className="template-table-container summary-dialog-mainContainer">
                      <Box className="summary-dialog-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
            </Box>
            </DialogContent>
          </Dialog>

          <Dialog
            fullScreen
            open={openPaplationSummary}
            onClose={handleClickClosePaplationSummary}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
                <Typography
                  // className={selected ? "symptom-text" : ""}
                  style={{ fontWeight: 600, marginLeft: 0 }}
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                >
                 Palpation Summary
                </Typography>
                <IconButton onClick={() => handleClickClosePaplationSummary(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                  <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent style={{ height: 400 }}>
            <Box className="template-table-container summary-dialog-mainContainer">
                      <Box className="summary-dialog-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
            </Box>
            </DialogContent>
          </Dialog>

          <Dialog
            fullScreen
            open={openPercussionSummary}
            onClose={handleClickClosePercussionSummary}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
                <Typography
                  // className={selected ? "symptom-text" : ""}
                  style={{ fontWeight: 600, marginLeft: 0 }}
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                >
               Percussion Summary
                </Typography>
                <IconButton onClick={() => handleClickClosePercussionSummary(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                  <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent style={{ height: 400 }}>
            <Box className="template-table-container summary-dialog-mainContainer">
                      <Box className="summary-dialog-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
            </Box>
            </DialogContent>
          </Dialog>

          <Dialog
            fullScreen
            open={openAuscultationSummary}
            onClose={handleClickCloseAuscultationSummary}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
                <Typography
                  // className={selected ? "symptom-text" : ""}
                  style={{ fontWeight: 600, marginLeft: 0 }}
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                >
            Auscultation Summary
                </Typography>
                <IconButton onClick={() => handleClickCloseAuscultationSummary(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                  <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent style={{ height: 400 }}>
            <Box className="template-table-container summary-dialog-mainContainer">
                      <Box className="summary-dialog-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
            </Box>
            </DialogContent>
          </Dialog>
    </Box>
  );
}
