import React from 'react'
import Title from '../../../components/Title';

import {
  Box, Grid, IconButton, InputAdornment, TextField, Button, styled, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
  FormLabel,
  FormControl,
  Paper,
  Typography,
  Divider,
  Toolbar,
  AppBar,
  Dialog,
  DialogActions,
  DialogContent,
  Popover,
  Pagination,
  Stack,
} from '@mui/material'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate } from 'react-router-dom';
// import '../../../theme/style.scss';
import { Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import { AsyncCustomSelect } from '../../../../components/CustomFields/CustomSelect';
import DraggableList from '../../../../components/DraggableTable/DraggableList';
import { reorder } from '../../../../Helpers/helpers';
import { DropResult } from 'react-beautiful-dnd';
import InvestigationInstructionSave from '../../../Investigation/InvestigationMaster/_component/InvestigationInstructionSave';


export default function InvestigationMasterList() {
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl3] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const rowData = [
    {
      followUpReason: 'Regular follow up',

    },
    {
      followUpReason: 'Review reports',
    }
  ]

  const CustomTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: '#FAFBFD', // Set the desired background color
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.grey[300],
        borderWidth: '1px',
        BorderStyle: 'solid'
      },
      '&:hover fieldset': {
        borderColor: theme.palette.grey[500],
        borderWidth: '1px',
        BorderStyle: 'solid'
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: "2px",

      },
    },
  }));
  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(itemsOther, source.index, destination.index);

    setItemsOther(newItems);
  };
  const [itemsOther, setItemsOther] = React.useState([
    {
      id: "Item2 1",
      primary: "2Practical Rubber Computer1",
      secondary: "1Synergistic leading edge matrices"
    }, {
      id: "Item1 2",
      primary: "2Practical Rubber Computer2",
      secondary: "1Synergistic leading edge matrices"
    }, {
      id: "Item1 3",
      primary: "2Practical Rubber Computer3",
      secondary: "2Synergistic leading edge matrices"
    }
  ]);

 

  // for Edit & Delet pop 
  const handleOpenMore = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: any
  ) => {
    setAnchorEl3(event.currentTarget);
    // setBuyerId(id);
  };
  const handleCloseMore = () => {
    setAnchorEl3(null);
  };
  //  Table Header 
  //  Table Header 
  interface EnhancedTableProps {
    numSelected: number;
    //onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    //order: Order;
    orderBy: String;
    rowCount: number;
  }

  const [openDialog, setOpenDialog] = React.useState(false);
  const onHandleOpenDialog = (item: Item) => {

    handleClickOpen(true);
    console.log("Opening dialog for item:", item);
  };
  const handleClickOpen = (value: boolean) => {
    setOpenDialog(value);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    
    <Box className="main-container-popup" pl={2}>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
        }}
        onSubmit={async (values) => {
          console.log("values", values);
        }}
      >
        <Grid container spacing={2} className='padding-add-Investigation'>
          <Grid item xs={12} mb={2} className="group-container">
            <Grid container item spacing={2} >
              <Grid item xs={12} sm={6} md={12}>
                <FormControl fullWidth>
                  <FormLabel className='fileds-label-text'>Investigation Name</FormLabel>
                  <CustomTextField
                    name="investigationName"
                    autoCapitalize='none'
                    type="text"
                    fullWidth
                    size='small'
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <FormControl
                  sx={{
                    minWidth: "auto",
                  }}
                  fullWidth
                >
                  <FormLabel className="fileds-label-text">Sample collected from</FormLabel>
                  <AsyncCustomSelect
                    tabSelectsValue={false}
                   
                    isNotCreatable={false}
                    isInnerWidth={true}
                   
                    menuPortalTarget={document.querySelector("body")}
                    id={"followUpReason"}
                    isClearable={true}
                    FieldName={`followUpReason`}
                    handleChange={(e) => {
                     
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Timing of Investigation</FormLabel>
                  <CustomTextField
                    name="juridiation"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Preparation required before investigation</FormLabel>
                  <CustomTextField
                    name="juridiation"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Box
                  className="template-table-container"
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    border: '1px solid #0000001f',
                    borderRadius: 4
                  }}
                >
                  <Paper
                    style={{
                      flex: 1,
                    }}>
                    <Box
                      style={{ padding: 20, fontWeight: 600, backgroundColor: '#f1f5f8' }}
                    >
                      <Typography
                        style={{ fontWeight: 600 }}
                      >
                        Investigation Instructions
                      </Typography>

                    </Box>
                    <Divider />
                    <DraggableList
                      items={itemsOther}
                      onDragEnd={onDragEnd}
                    onHandleOpenDialog={onHandleOpenDialog}
                    />
                  </Paper>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          
        </Grid>
      </Formik>
      <Dialog
        className="dialog1"
        open={openDialog}
        onClose={handleClose}
        fullScreen
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "1200px", // Adjust the width as needed
            maxWidth: "none",
          },
        }}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography
              // className={selected ? "symptom-text" : ""}
              style={{ fontWeight: 600, marginLeft: 0 }}
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
            >
              Investigation Edit
            </Typography>


          </Toolbar>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </AppBar>
        <DialogContent className="padding-remove-Investigation-Edit main-dialog-background-color">
          <InvestigationInstructionSave
            onHandleOpenDialog={onHandleOpenDialog}
            handleClose1={handleClose}
          />
        </DialogContent>
        <Divider />

        <DialogActions>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
              <LoadingButton
                color="primary"
                tabIndex={3}
                loadingPosition="start"
                startIcon={<></>}
                variant="contained"
                type="submit"
                onClick={handleClose}
                className="primary-button"
                name="submit"
              >
                Save
              </LoadingButton>
              <LoadingButton
                tabIndex={3}
                loadingPosition="start"
                startIcon={<></>}
                variant="outlined"
                type="submit"
                name="submit"
                className="secondary-button"
                color="secondary"
                onClick={handleClose}                >
                Cancel
              </LoadingButton>
            </Box>
          </Grid>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
