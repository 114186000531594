import React from 'react'
import Title from '../../../components/Title';
import {
  Box, Grid, IconButton, InputAdornment, TextField, Button, styled, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
  Popover,
  Pagination,
  Stack,
  Tabs,
  Tab,
  FormControl,
} from '@mui/material'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate } from 'react-router-dom';
import '../../../theme/style.scss';
import BreadcrumbsComponent from '../../../components/BreadcrumbsComponent/BreadcrumbsComponent';
import { CustomSelect } from '../../../components/CustomFields/CustomSelect';
import { Formik } from 'formik';
import CustomBreadcrumbs from '../../../components/BreadcrumbsComponent/CustomBreadcrumbs';


export default function Appointments() {
  const navigate = useNavigate()
  const [value, setValue] = React.useState(0);

  const [anchorEl, setAnchorEl3] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const rowData = [
    {
      queueno: '1',
      patientId: 'P001',
      patientName: 'John Doe',
      consultingDoctor: 'Dr. Smith',
      status: 'completed',
      isEmergency: true,
      visitType: 'New'
    },
    {
      queueno: '2',
      patientId: 'P002',
      patientName: 'Jane Smith',
      consultingDoctor: 'Dr. Adams',
      status: 'completed',
      isEmergency: false,
      visitType: 'Follow-up'
    },
    {
      queueno: '3',
      patientId: 'P003',
      patientName: 'Michael Johnson',
      consultingDoctor: 'Dr. Brown',
      status: 'completed',
      isEmergency: false,
      visitType: 'New'
    },
    {
      queueno: '3',
      patientId: 'P004',
      patientName: 'Emily Davis',
      consultingDoctor: 'Dr. White',
      status: 'completed',
      isEmergency: true,
      visitType: 'Follow-up'
    }
  ];


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function TabProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const CustomTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: '#FAFBFD', // Set the desired background color
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.grey[300],
        borderWidth: '1px',
        BorderStyle: 'solid'
      },
      '&:hover fieldset': {
        borderColor: theme.palette.grey[500],
        borderWidth: '1px',
        BorderStyle: 'solid'
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: "2px",

      },
    },
  }));

  // for Edit & Delet pop 
  const handleOpenMore = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: any
  ) => {
    setAnchorEl3(event.currentTarget);
    // setBuyerId(id);
  };
  const handleCloseMore = () => {
    setAnchorEl3(null);
  };
  //  Table Header 
  //  Table Header 
  interface EnhancedTableProps {
    numSelected: number;
    //onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    //order: Order;
    orderBy: String;
    rowCount: number;
  }
  function EnhancedTableHead(props: EnhancedTableProps) {
    return (
      // checkBox for Header

      <TableHead className='table-header-color'>
        <TableRow>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "10%"
            }}
            align="left"
          >
            Queue No
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "20%"
            }}
            align="left"
          >
            Patient Name
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "none",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "20%"
            }}
            align="left"
          >
            Consulting Doctor
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "20%"
            }}
            align="left"
          >
            Visit Type
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "none",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "10%"
            }}
            align="left"
          >
            Status
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "20%"
            }}
            align="left"
          >
            Emergency
          </TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
    );
  }

  const visitTypeOptions: ColourOption[] = [
    { value: 'gujrat', label: 'New' },
    { value: 'rajesthan', label: 'Revisit' },
  ];

  return (
    <>
      <Box className='main-container'>
        <Grid xs={12} container className='page-header'>
          <Grid xs={6} sm={6} md={8} item className="page-breadcrumbs">
            <CustomBreadcrumbs />
          </Grid>
          <Grid xs={6} sm={6} md={4} item className='page-header-icon'>
            <Button
              color="primary"
              variant="contained"
              onClick={() => navigate('/patient-save')}
              size="medium"
              startIcon={<AddIcon />}
            >
              Patient / MR
            </Button>
          </Grid>
        </Grid>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
          }}
          onSubmit={async (values) => {
            console.log("values", values)
          }}
        >
          <Grid container className="list-container" sx={{ padding: '16px' }}  >
            <Grid item xs={12} sm={4} md={4} lg={2} className='search-bar'   >
              <CustomTextField
                placeholder="Search..."
                type="text"
                variant="outlined"
                size="small"
                fullWidth
                style={{ marginTop: 10 }}
                sx={{ paddingBottom: '10px' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" >
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={2} className='search-bar'   >
              <FormControl fullWidth>
                <CustomSelect
                  className='doctor-filter'
                  placeholder='Doctor'
                  isNotCreatable={true}
                  id={"referredBy"}
                  FieldName={"referredBy"}
                  options={visitTypeOptions}
                  openMenuOnFocus
                  isClearable
                  isSearchable
                  menuPortalTarget={document.querySelector("body")}
                  handleChange={(e) => {
                    // setValue(e.value)
                    console.log("event:", e)
                  }}
                // Value={value}

                />
              </FormControl>
            </Grid>


            
            {/* <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box
              sx={{ borderBottom: 1, borderColor: "divider", paddingTop: 2 }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                indicatorColor="primary"
                aria-label="Sale Invoice"
                TabIndicatorProps={{ sx: { backgroundColor: " #2e67a3" } }}
                sx={{
                  "& button:hover": {
                    backgroundColor: "#f1f6fe",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  },
                }}
              >
                <Tab
                  sx={{ textTransform: "capitalize !important" }}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <span>Patients</span>
                      <Box
                        sx={{ backgroundColor: "#2e67a3", color: "#fff" }}
                        className="inner-tabs"
                      >
                        5
                      </Box>
                    </Box>
                  }
                  {...TabProps(0)}
                />
                <Tab
                  sx={{ textTransform: "capitalize !important" }}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <span>MR</span>
                      <Box
                        sx={{ backgroundColor: "#fdeded" }}
                        className="inner-tabs"
                      >
                        1
                      </Box>
                    </Box>
                  }
                  {...TabProps(1)}
                />
              </Tabs>
            </Box>
          </Grid> */}
            <TableContainer className='table'>
              <Box  >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  indicatorColor="primary"
                  aria-label="Sale Invoice"
                  TabIndicatorProps={{ sx: { backgroundColor: " #2e67a3" } }}
                  sx={{
                    "& button:hover": {
                      backgroundColor: "#f1f6fe",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    },
                  }}
                >
                  <Tab
                    sx={{ textTransform: "capitalize !important" }}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <span>Patients</span>
                        <Box
                          sx={{ backgroundColor: "#2e67a3", color: "#fff" }}
                          className="inner-tabs"
                        >
                          5
                        </Box>
                      </Box>
                    }
                    {...TabProps(0)}
                  />
                  <Tab
                    sx={{ textTransform: "capitalize !important" }}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <span>MR</span>
                        <Box
                          sx={{ backgroundColor: "#fdeded" }}
                          className="inner-tabs"
                        >
                          1
                        </Box>
                      </Box>
                    }
                    {...TabProps(1)}
                  />
                </Tabs>
              </Box>
              <Table>
                <EnhancedTableHead />
                <TableBody>
                  {rowData.map((item) => {
                    return (
                      <TableRow key={item.patientId}>
                        <TableCell>
                          <Box
                            sx={{
                              backgroundColor: "#2e67a3",
                              color: "#fff",
                              display: "inline-flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              padding: "4px 8px",
                              textAlign: "center",
                              minWidth: "40px",
                            }}
                          >
                            {item.queueno}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Link className="text-link" to="/patient-save">
                            {item.patientName}
                          </Link>
                        </TableCell>
                        <TableCell
                          sx={{
                            display: {
                              xs: "none",
                              sm: "table-cell",
                              md: "table-cell",
                              lg: "table-cell",
                            },
                          }}
                        >
                          {item.consultingDoctor}
                        </TableCell>
                        <TableCell>{item.visitType}</TableCell>
                        <TableCell>{item.status}</TableCell>
                        <TableCell>{item.isEmergency ? "Yes" : "No"}</TableCell>
                        <TableCell align="right">
                          <Tooltip title="More" placement="top">
                            <IconButton onClick={(e: any) => handleOpenMore(e)}>
                              <MoreVertRoundedIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    )
                  })}

                </TableBody>
              </Table>
            </TableContainer>

            <Grid
              container
              paddingTop={1}
              className='pagination'
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Grid item xs={12} sm={6}
                className="pagination-label"
              >
                1 of 1 {/* {pageStartIndex} - {pageEndIndex} of {totalCount} */}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack
                  component="div"
                  alignItems={"end"}
                  justifyContent={"center"}
                >
                  <Pagination
                    color="primary"
                  // count={counData}
                  // page={page_Index}
                  // onChange={handleChangePageNew}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Formik>
      </Box>
      <Popover
        elevation={1}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseMore}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('/patient-save')}>
              <ListItemText primary="Edit" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              className="delete-container"
            >
              Delete
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </>
  )
}
