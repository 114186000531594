import { AES } from 'crypto-ts';
import * as CryptoTS from 'crypto-ts';

export class EncryptDecryptService {
    public EncryptKey: string = "1~4@60E2#D-C0d8Bf-4B81$aB9b$63@F8*E2&03$F28bB"; // It's static  encryption key.

    constructor() { }

    encrypt(plainText: any) {
        try {
            if (typeof plainText === "string")
                return AES.encrypt(plainText.trim(), this.EncryptKey).toString();
            else if (typeof plainText === "boolean" || typeof plainText === "number") {
                return AES.encrypt(plainText.toString(), this.EncryptKey).toString();
            } else {
                return AES.encrypt(plainText, this.EncryptKey).toString();
            }
        } catch (Error: any) {
            // console.log("plainText : " , plainText);
            // console.log("encrypt() Error Message : " , Error.message);
        }
    }

    decrypt(cipherText: any) {
        try {
            return AES.decrypt(cipherText.trim(), this.EncryptKey).toString(CryptoTS.enc.Utf8);

        } catch (Error: any) {
            // console.log("cipherText : " + cipherText);
            // console.log("decrypt() Error Message : " + Error.message);
        }
    }

    //The set method is use for encrypt the value.
    set(keys: any, value: any) {
        var key = CryptoTS.enc.Utf8.parse(keys);
        var iv = CryptoTS.enc.Utf8.parse(keys);
        var encrypted = CryptoTS.AES.encrypt(CryptoTS.enc.Utf8.parse(value.toString()), key,
            {
                //keySize: 128 / 8,
                iv: iv,
                mode: CryptoTS.mode.CBC,
                padding: CryptoTS.pad.PKCS7
            });

        return encrypted.toString();
    }

    //The get method is use for decrypt the value.
    get(keys: any, value: any) {
        var keySize = 128 / 8
        var key = CryptoTS.enc.Utf8.parse(keys);
        var iv = CryptoTS.enc.Utf8.parse(keys);
        var decrypted = CryptoTS.AES.decrypt(value, key, {
            // keySize: keySize,
            iv: iv,
            mode: CryptoTS.mode.CBC,
            padding: CryptoTS.pad.PKCS7
        });

        return decrypted.toString(CryptoTS.enc.Utf8);
    }
}