import React, { FC } from "react";
import { Draggable } from "react-beautiful-dnd";
import {
  Box,
  Button,
  Divider,
  Grid,
  ListItem,
  ThemeProvider,
  Typography,
  createTheme,
  IconButton
} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Item } from "../../../../../../Helpers/typings";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import EditIcon from '@mui/icons-material/Edit';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {},
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontSize: 20,
        },
      },
    },
  },
});

export type Props = {
  item: Item;
  index: number;
  onHandleSelect: (value: string) => void;
  selectedSymptom: string;
};

const ChiefComplaintsDraggableTableItem: FC<Props> = ({
  item,
  index,
  onHandleSelect,
  selectedSymptom,
}) => {
  const handleSelect = () => {
    onHandleSelect(item.symptom);
  };
  return (
    <ThemeProvider theme={theme}>
      <Divider />
      <Draggable draggableId={item.id} index={index}>
        {(provided, snapshot) => (
          <ListItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            sx={{
              ...(snapshot.isDragging && {
                backgroundColor: "rgb(235,235,235)",
              }),
            }}
            className={
              selectedSymptom === item.symptom ? "draggable-list-item" : " "
            }
          >
            <Grid container item spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Box
                  onClick={handleSelect}
                  style={{
                    display: "flex",
                    cursor: "pointer",
                  }}
                >
                  <Box
                    style={{ paddingRight: 16, paddingTop: 5 }}
                    {...provided.dragHandleProps}
                  >
                    <DragIndicatorIcon />
                  </Box>
                  <Grid container item>
                    <Grid item xs={12} sm={12} md={12}>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "start",
                          paddingTop: 5,
                        }}
                      >
                        <Typography
                          className={
                            selectedSymptom === item.symptom
                              ? "symptom-text"
                              : " "
                          }
                        >
                          {item.symptom}
                        </Typography>
                        {selectedSymptom === item.symptom && (
                          <Box style={{ paddingLeft: "8px" }}>
                            <KeyboardDoubleArrowRightRoundedIcon />
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                  <Box>
                        <IconButton>
                             <EditIcon sx={{ color: "rgb(65, 77, 85)" }} />
                        </IconButton>
                        </Box>
                <Box>
                  <Button size="small" style={{ minWidth: "30px" }}>
                    <CloseIcon style={{ color: "#d32f2f" }} />
                  </Button>
                </Box>
             
                </Box>
              </Grid>
            </Grid>
          </ListItem>
        )}
      </Draggable>
    </ThemeProvider>
  );
};

export default ChiefComplaintsDraggableTableItem;
