import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../theme/style.scss";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import Title from "../../components/Title";
import LoadingButton from "@mui/lab/LoadingButton";
import { CustomSelect } from "../../components/CustomFields/CustomSelect";
import { Formik } from "formik";
import CustomBreadcrumb from "../../components/BreadcrumbsComponent/Breadcrumb";
import CustomBreadcrumbs from "../../components/BreadcrumbsComponent/CustomBreadcrumbs";

const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#FAFBFD", // Set the desired background color
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));


export default function BranchSave() {
  const [countryData, setCountry] = useState([
    { countryID: 0, code: "", name: "" },
    { countryID: 1, code: "123", name: "India" },
    { countryID: 2, code: "456", name: "USA" },
  ]);
  const navigate = useNavigate();
 
  return (
    <Box className="main-container" pl={2}>
      <Grid item xs={12} container className="page-header-save">
        <Grid xs={12} sm={12} md={12} item className="page-breadcrumbs">
            <CustomBreadcrumbs />
          </Grid>
      </Grid>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
        }}
        onSubmit={async (values) => {
          console.log("values", values)
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} mb={3} className="group-container">
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Basic Information</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Code</FormLabel>

                  <CustomTextField
                    name="code"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} />

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Branch Name</FormLabel>
                  <CustomTextField
                    name="branchName"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box className='branch-checkbox'>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label="Is Head Office"
                    />
                  </FormGroup>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} mb={3} className="group-container">
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Contact Information</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    Authorized Person
                  </FormLabel>
                  <CustomTextField
                    name="authorizedPerson"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Email</FormLabel>
                  <CustomTextField
                    name="email"
                    placeholder=""
                    autoCapitalize="none"
                    type="email"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Address 1</FormLabel>
                  <CustomTextField
                    name="address1"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Address 2</FormLabel>
                  <CustomTextField
                    name="address2"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">City</FormLabel>
                  <CustomTextField
                    name="city"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Postal Code</FormLabel>
                  <CustomTextField
                    name="postalCode"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Country</FormLabel>
                  <CustomSelect
                    isNotCreatable={true}
                    id={"country"}
                    FieldName={"country"}
                    options={countryData}
                    openMenuOnFocus
                    isClearable
                    isSearchable
                    menuPortalTarget={document.querySelector("body")}
                    handleChange={(e) => {
                      console.log("event:", e)
                    }}
                    Value=""
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">State</FormLabel>
                  <CustomSelect
                    isNotCreatable={true}
                    id={"state"}
                    FieldName={"state"}
                    options={countryData}
                    openMenuOnFocus
                    isClearable
                    isSearchable
                    menuPortalTarget={document.querySelector("body")}
                    handleChange={(e) => {
                      console.log("event:", e)
                    }}
                    Value=""
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} mb={3} className="group-container">
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Bank Details</Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Bank Name</FormLabel>
                  <CustomTextField
                    name="bankName"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    Bank IFSC Code
                  </FormLabel>
                  <CustomTextField
                    name="bankIfscCode"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    Bank Branch Name
                  </FormLabel>
                  <CustomTextField
                    name="bankBranchName"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    Bank Address
                  </FormLabel>
                  <CustomTextField
                    name="bankAdress"
                    placeholder=""
                    autoCapitalize="none"
                    type="email"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    Account Holder Name
                  </FormLabel>
                  <CustomTextField
                    name="accountHoalderName"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    Account Number
                  </FormLabel>
                  <CustomTextField
                    name="accountNumber"
                    placeholder=""
                    autoCapitalize="none"
                    type="number"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}></Grid>

              <Grid item xs={12} sm={6} md={3}></Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} mb={2} className="group-container">
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Other</Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Juridiation</FormLabel>
                  <CustomTextField
                    name="juridiation"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">GST Number</FormLabel>
                  <CustomTextField
                    name="gstNumber"
                    placeholder=""
                    autoCapitalize="none"
                    type="email"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Max Department</FormLabel>

                  <CustomTextField
                    name="max department"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                    inputProps={{
                      className: 'no-spinner',
                      inputMode: 'numeric', pattern: '[0-9]*', step: '1', onKeyPress: (event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
              <LoadingButton
                color="primary"
                tabIndex={3}
                loadingPosition="start"
                startIcon={<></>}
                variant="contained"
                type="submit"
                className="primary-button"
                name="submit"
              >
                Save
              </LoadingButton>
              <LoadingButton
                tabIndex={3}
                loadingPosition="start"
                startIcon={<></>}
                variant="outlined"
                type="submit"
                name="submit"
                className="secondary-button"
                color="secondary"
                onClick={() => navigate("/settings/branch")}
              >
                Cancel
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Formik>
    </Box>
  );
}
