import "../../../../../theme/style.scss";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
  styled,
  Dialog,
  DialogContent,
  List,
  ListItem,
  DialogActions
} from "@mui/material";
import React, { useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { reorder } from "../../../../../Helpers/helpers";
import { Formik } from "formik";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ChiefComplaintsDraggableTable from "../ChiefComplaints/ChiefComplaintsDraggable/ChiefComplaintsDraggableTable";
import DraggableList from "../../../../OPD/Components/DraggableTable/DraggableList";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from '@mui/material/useMediaQuery';
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import SubjectRoundedIcon from "@mui/icons-material/SubjectRounded";
import ReactQuill from "react-quill";
import MedicinePrescription from "../../../OPDClinical/Components/Diagnosis/MedicineComponent/MedicinePrescription"
import LoadingButton from "@mui/lab/LoadingButton";
import InvestigationInstructionMasterSave from "../../../../OPD/OPDClinical/DraggableComponent/_component/InvestigationInstructionMasterSave";
import InvestigationInstructionSave from "../../../../OPD/OPDClinical/DraggableComponent/_component/InvestigationInstructionSave";
import EditIcon from '@mui/icons-material/Edit';
import { CustomSelect } from "../../../../../components/CustomFields/CustomSelect";

const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#FAFBFD",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));

const initialData = [
  {
    id: 1,
    agravatedBy: "One",
  },
];
const initialRelievedData = [
  {
    id: 1,
    relievedBy: "One",
  },
];

export default function Diagnosis() {
  const [showNote, setShowNote] = useState(false);
  const [showNoteNew, setShowNoteNew] = useState(false);
  const [addAgravated, setAddAgravated] = useState(initialData);
  const [addRelieved, setAddRelieved] = useState(initialRelievedData);
  const [selected, setSelected] = useState("");
  const smallScreen = useMediaQuery("(max-width:768px)");
  const mobileScreen = useMediaQuery("(max-width:425px)");
  //Draggable Table
  const [items, setItems] = React.useState([
    {
      id: "Item 1",
      symptom: " Essential hypertension",
    },
    {
      id: "Item 2",
      symptom: "Diabetes mellitus",
    },
    {
      id: "Item 3",
      symptom: "Osteoarthritis",
    },
    {
      id: "Item 4",
      symptom: "Acute respiratory infections",
    },
    {
      id: "Item 5",
      symptom: "Joint disorders",
    },
  ]);

  const [countryData, setCountry] = useState([
    { countryID: 0, code: "", name: "" },
    { countryID: 1, code: "123", name: "India" },
    { countryID: 2, code: "456", name: "USA" },
  ]);
  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(items, source.index, destination.index);

    setItems(newItems);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (value: boolean) => {
    if (smallScreen) {

      setOpen(value);
    } else if (mobileScreen) {

      setOpen(value);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const onHandleSelectData = (val) => {
  //   if (smallScreen || mobileScreen) {

  //     handleClickOpen(true);
  //   }
  //   setSelected(val);
  // };

  //Draggable Table
  const [itemsOther, setItemsOther] = React.useState([
    {
      id: "Item1 1",
      primary: "1Practical Rubber Computer1",
      secondary: "1Synergistic leading edge matrices",
    },
    {
      id: "Item1 2",
      primary: "1Practical Rubber Computer2",
      secondary: "1Synergistic leading edge matrices",
    },
    {
      id: "Item1 3",
      primary: "1Practical Rubber Computer3",
      secondary: "1Synergistic leading edge matrices",
    },
  ]);
  const [itemsOther1, setItemsOther1] = React.useState([
    {
      id: "Item2 1",
      primary: "2Practical Rubber Computer1",
      secondary: "1Synergistic leading edge matrices",
    },
    {
      id: "Item1 2",
      primary: "2Practical Rubber Computer2",
      secondary: "1Synergistic leading edge matrices",
    },
    {
      id: "Item1 3",
      primary: "2Practical Rubber Computer3",
      secondary: "2Synergistic leading edge matrices",
    },
  ]);

  const onDragEnd1 = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(itemsOther, source.index, destination.index);

    setItemsOther(newItems);
  };

  const onDragEnd2 = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(itemsOther1, source.index, destination.index);

    setItemsOther1(newItems);
  };

  const [openDiagnosisSummary, setopenDiagnosisSummary] = React.useState(false);

  const handleClickOpenDiagnosisSummary = (value: boolean) => {
    if (mobileScreen) {
      setopenDiagnosisSummary(value);
    }
    else if (smallScreen) {
      setopenDiagnosisSummary(value);
    }
  };

  const handleClickCloseDiagnosisSummary = () => {
    setopenDiagnosisSummary(false);
  };

  const [openDialog, setOpenDialog] = React.useState(false);
  const onHandleOpenDialog = (item: Item) => {

    handleClickOpenDialog(true);
    console.log("Opening dialog for item:", item);
  };
  const handleClickOpenDialog = (value: boolean) => {
    setOpenDialog(value);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const [openDialog1, setOpenDialog1] = React.useState(false);
  const onHandleOpenDialog1 = (item: Item) => {

    handleClickOpenDialog1(true);
    console.log("Opening dialog for item:", item);
  };
  const handleClickOpenDialog1 = (value: boolean) => {
    setOpenDialog1(value);
  };

  const handleCloseDialog1 = () => {
    setOpenDialog1(false);
  };

  const [openDialogInstruction, setOpenInstruction] = React.useState(false);
  const onHandleOpenDialogInstruction = (item: Item) => {

    handleClickOpenDialogInstruction(true);
    console.log("Opening dialog for item:", item);
  };
  const handleClickOpenDialogInstruction = (value: boolean) => {
    setOpenInstruction(value);
  };

  const handleCloseDialogInstruction = () => {
    setOpenInstruction(false);
  };


  return (
    <Box className="main-container">
      <Grid container spacing={2}>
        <Grid item xs={12} mb={2}>
          <Grid container item spacing={2}>
          <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
            <Grid item xs={12} sm={12} md={6}>
              <Box
                className="template-table-container"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                }}
              >
                <Box className="inner-title-container">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box className="inner-header-container"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography style={{ fontWeight: 600 }}>
                        Diagnosis
                      </Typography>
                      {mobileScreen || smallScreen ? <Box>
                        <Button onClick={() => handleClickOpenDiagnosisSummary(true)} sx={{ padding: 0, margin: 0, minWidth: "30px" }} aria-label="delete">
                          <SubjectRoundedIcon></SubjectRoundedIcon>
                        </Button>
                      </Box> : ''}
                    </Box>
                  </Paper>
                  <Divider />
                  <List>
                    {items.map((item: any, index: number) => (
                      <ListItem
                        sx={{
                          borderBottom: "1px solid #E9EBEC",
                          cursor: 'pointer'
                        }}
                        className={
                          selected === item.symptom
                            ? "draggable-list-item"
                            : " "
                        }
                      >
                        <Grid container item spacing={2}>
                          <Grid item xs={12} sm={12} md={12}>
                            <Box
                              style={{
                                display: "flex",
                              }}
                              onClick={() => { handleClickOpen(true) }}
                            >
                              <Grid container item>
                                <Grid item xs={12} sm={12} md={12}>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() => {
                                      setSelected(item.symptom)
                                    }}
                                  >
                                    <Box
                                      className={
                                        selected === item.symptom
                                          ? "symptom-text"
                                          : " "
                                      }
                                      sx={{ paddingBottom: '8px', paddingTop: '8px' }}

                                    >
                                      <Typography>{item.symptom}</Typography>
                                    </Box>

                                    {selected === item.symptom && (
                                      <Box
                                        style={{
                                          paddingTop: "8px",
                                          paddingLeft: '8px'
                                        }}
                                      >
                                        <KeyboardDoubleArrowRightRoundedIcon />
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                        <Box>
                        <IconButton>
                             <EditIcon sx={{ color: "rgb(65, 77, 85)" }} />
                        </IconButton>
                        </Box>
                        <Box>
                          <IconButton>
                            <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                          </IconButton>
                        </Box>
                      </ListItem>
                    ))}
                  </List>

                  <Box style={{ padding: 10, paddingRight: 24, paddingLeft: 24 }}>
                    <FormControl fullWidth>
                    <CustomSelect
                                // isNotCreatable={true}
                                id={"admission"}
                                FieldName={"admission"}
                                options={[]}
                                placeholder="Search..."
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e);
                                }}
                                Value=""
                              />
                    </FormControl>
                  </Box>
                  <Divider />
                  <Box className="note-container">
                    <Box>
                      {!showNote && (
                        <Button
                          variant="text"
                          style={{ fontSize: "6px" }}
                          onClick={() => {
                            setShowNote(true);
                          }}
                        >
                          <AddIcon className="add-icon" />
                          <span className="add-icon"> Note</span>
                        </Button>
                      )}
                    </Box>

                    {showNote && (
                      <FormControl fullWidth>
                        <Box className="note-container-box">
                          <FormLabel
                            sx={{ paddingLeft: 1 }}
                            className="fileds-label-text"
                          >
                            Note
                          </FormLabel>
                          <Button
                            variant="text"
                            onClick={() => {
                              setShowNote(false);
                            }}
                          >
                            <RemoveIcon className="remove-note" />
                            <span className="remove-note">Remove Note</span>
                          </Button>
                        </Box>
                        <ReactQuill
                          className="react-quill-alignment"
                          theme="snow"
                          value={""}
                          onChange={(e) => { }}
                        />
                      </FormControl>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            </Formik>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container template-hide-small-screen template-hide-medium-screen "
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                  minHeight: 400,
                }}
              >
                <Box className="inner-title-container">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="inner-header-container">
                      <Typography
                        className={selected ? "symptom-text" : ""}
                        style={{ fontWeight: 600 }}
                      >
                        {selected ? selected : "Diagnosis Summary"}
                      </Typography>
                      {selected !== '' && <Typography>
                        <IconButton onClick={() => setSelected("")} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                          <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                        </IconButton>
                      </Typography>}
                    </Box>
                  </Paper>
                  {selected ?
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                        <Divider />
                        <Grid
                          container
                          item
                          spacing={2}
                          style={{ padding: 20 }}
                        >
                          <Grid item xs={12} sm={6} md={6}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Template Name
                              </FormLabel>
                              <CustomTextField
                                name="templateName"
                                placeholder=""
                                autoCapitalize="none"
                                type="text"
                                fullWidth
                                size="small"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} />
                          <Grid item xs={12} sm={6} md={6}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">Diagnosis</FormLabel>
                              <CustomTextField
                                name="diagnosis"
                                placeholder=""
                                autoCapitalize="none"
                                type="text"
                                fullWidth
                                size="small"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} />

                          <Grid item xs={12} sm={6} md={12}>
                            <Box
                              className="template-table-container"
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                border: "1px solid #0000001f",
                                borderRadius: 4,
                              }}
                            >
                              <Paper
                                style={{
                                  flex: 1,
                                }}
                              >
                                <Box className="inner-header-container">
                                  <Typography style={{ fontWeight: 600 }}>
                                    Investigation
                                  </Typography>
                                </Box>
                                <Divider />
                                <DraggableList
                                  items={itemsOther}
                                  onDragEnd={onDragEnd1}
                                  onHandleOpenDialog={onHandleOpenDialog1}
                                />
                              </Paper>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6} md={12}>
                            <Box
                              className="template-table-container"
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                border: "1px solid #0000001f",
                                borderRadius: 4,
                              }}
                            >
                              <Paper
                                style={{
                                  flex: 1,
                                }}
                              >
                                <Box className="inner-header-container">
                                  <Typography style={{ fontWeight: 600 }}>
                                    Medicine
                                  </Typography>
                                </Box>
                                <Divider />
                                <DraggableList
                                  items={itemsOther1}
                                  onDragEnd={onDragEnd2}
                                  onHandleOpenDialog={onHandleOpenDialog}
                                />
                              </Paper>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6} md={12}>
                            <Box
                              className="template-table-container"
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                border: "1px solid #0000001f",
                                borderRadius: 4,
                              }}
                            >
                              <Paper
                                style={{
                                  flex: 1,
                                }}
                              >
                                <Box className="inner-header-container">
                                  <Typography style={{ fontWeight: 600 }}>
                                    Instruction
                                  </Typography>
                                </Box>
                                <Divider />
                                <DraggableList
                                  items={itemsOther1}
                                  onDragEnd={onDragEnd2}
                                  onHandleOpenDialog={onHandleOpenDialogInstruction}
                                />
                              </Paper>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container item spacing={2}>
                          <Grid item xs={12} sm={6} md={12}>
                            <Divider />
                            <Box className="note-container">
                              <Box>
                                {!showNoteNew && (
                                  <Button
                                    variant="text"
                                    style={{ fontSize: "6px" }}
                                    onClick={() => {
                                      setShowNoteNew(true);
                                    }}
                                  >
                                    <AddIcon className="add-icon" />
                                    <span className="add-icon"> Note</span>
                                  </Button>
                                )}
                              </Box>

                              {showNoteNew && (
                                <FormControl fullWidth>
                                  <Box className="note-container-box">
                                    <FormLabel
                                      sx={{ paddingLeft: 1 }}
                                      className="fileds-label-text"
                                    >
                                      Note
                                    </FormLabel>
                                    <Button
                                      variant="text"
                                      onClick={() => {
                                        setShowNoteNew(false);
                                      }}
                                    >
                                      <RemoveIcon className="remove-note" />
                                      <span className="remove-note">Remove Note</span>
                                    </Button>
                                  </Box>
                                  <ReactQuill
                                    className="react-quill-alignment"
                                    theme="snow"
                                    value={""}
                                    onChange={(e) => { }}
                                  />
                                </FormControl>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Formik>
                    :
                    <Box>
                      <Divider />
                      <Box className="summary-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
                    </Box>}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar
              sx={{ display:"flex",
                justifyContent:"space-between"}}
          >
            <Typography
              
              style={{ fontWeight: 600, color:"#495057" }}
              sx={{
                paddingLeft:{
                xs:1,
                sm:0,
               }
            }}
            >
              {selected ? selected : "Diagnosis"}
            </Typography>
            <IconButton onClick={() => handleClose(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
              <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
            </IconButton>
          </Toolbar>
          <Divider />
        </AppBar>
        <DialogContent style={{ height: 400 }}>
          <Box
            className="template-table-container"
            sx={{
              border: "0px solid #0000001f",
              borderRadius: 1,
              minHeight: 400,
            }}
          >
            <Box className="inner-title-container">

              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  email: "",
                }}
                onSubmit={async (values) => {
                  console.log("values", values);
                }}
              >
                <Box>

                  <Grid
                    container
                    item
                    spacing={2}

                  >
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl fullWidth>
                        <FormLabel className="fileds-label-text">
                          Template Name
                        </FormLabel>
                        <CustomTextField
                          name="templateName"
                          placeholder=""
                          autoCapitalize="none"
                          type="text"
                          fullWidth
                          size="small"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} />
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl fullWidth>
                        <FormLabel className="fileds-label-text">Diagnosis</FormLabel>
                        <CustomTextField
                          name="diagnosis"
                          placeholder=""
                          autoCapitalize="none"
                          type="text"
                          fullWidth
                          size="small"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} />

                    <Grid item xs={12} sm={12} md={12}>
                      <Box
                        className="template-table-container"
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          border: "1px solid #0000001f",
                          borderRadius: 4,
                        }}
                      >
                        <Paper
                          style={{
                            flex: 1,
                          }}
                        >
                          <Box className="inner-header-container">
                            <Typography style={{ fontWeight: 600 }}>
                              Investigation
                            </Typography>
                          </Box>
                          <Divider />
                          <DraggableList
                            items={itemsOther}
                            onDragEnd={onDragEnd1}
                            onHandleOpenDialog={onHandleOpenDialog1}
                          />
                        </Paper>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Box
                        className="template-table-container"
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          border: "1px solid #0000001f",
                          borderRadius: 4,
                        }}
                      >
                        <Paper
                          style={{
                            flex: 1,
                          }}
                        >
                          <Box className="inner-header-container">
                            <Typography style={{ fontWeight: 600 }}>
                              Medicine
                            </Typography>
                          </Box>
                          <Divider />
                          <DraggableList
                            items={itemsOther1}
                            onDragEnd={onDragEnd2}
                            onHandleOpenDialog={onHandleOpenDialog}
                          />
                        </Paper>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Box
                        className="template-table-container"
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          border: "1px solid #0000001f",
                          borderRadius: 4,
                        }}
                      >
                        <Paper
                          style={{
                            flex: 1,
                          }}
                        >
                          <Box className="inner-header-container">
                            <Typography style={{ fontWeight: 600 }}>
                              Instruction
                            </Typography>
                          </Box>
                          <Divider />
                          <DraggableList
                            items={itemsOther1}
                            onDragEnd={onDragEnd2}
                            onHandleOpenDialog={onHandleOpenDialogInstruction}
                          />
                        </Paper>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>

                      <Box className="note-container">
                        <Box>
                          {!showNote && (
                            <Button
                              variant="text"
                              style={{ fontSize: "6px" }}
                              onClick={() => {
                                setShowNote(true);
                              }}
                            >
                              <AddIcon className="add-icon" />
                              <span className="add-icon"> Note</span>
                            </Button>
                          )}
                        </Box>

                        {showNote && (
                          <FormControl fullWidth>
                            <Box className="note-container-box">
                              <FormLabel
                                sx={{ paddingLeft: 1 }}
                                className="fileds-label-text"
                              >
                                Note
                              </FormLabel>
                              <Button
                                variant="text"
                                onClick={() => {
                                  setShowNote(false);
                                }}
                              >
                                <RemoveIcon className="remove-note" />
                                <span className="remove-note">Remove Note</span>
                              </Button>
                            </Box>
                            <ReactQuill
                              className="react-quill-alignment"
                              theme="snow"
                              value={""}
                              onChange={(e) => { }}
                            />
                          </FormControl>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Formik>

            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        fullScreen
        open={openDiagnosisSummary}
        onClose={handleClickCloseDiagnosisSummary}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography
              // className={selected ? "symptom-text" : ""}
              style={{ fontWeight: 600, marginLeft: 0 }}
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
            >
              Diagnosis Summary
            </Typography>
            <IconButton onClick={() => handleClickCloseDiagnosisSummary(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
              <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Divider />
        <DialogContent style={{ height: 400 }}>
        <Box className="template-table-container summary-dialog-mainContainer">
                      <Box className="summary-dialog-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
            </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        className="dialog"
        open={openDialog}
        onClose={handleCloseDialog}
        fullScreen
      >
          <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography
              // className={selected ? "symptom-text" : ""}
              style={{ fontWeight: 600, marginLeft: 1 }}
              sx={{
                paddingLeft: {
                  xs: 1,
                  sm: 0,
                }
              }}
             
              variant="h6"
            >
           Medicine Edit
            </Typography>
          
          </Toolbar>
        </AppBar>
        <Divider />
        <DialogContent>
        <MedicinePrescription/>
        </DialogContent>
        <DialogActions>
        <LoadingButton
            color="primary"
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="contained"
            type="submit"
            className="primary-button"
            name="submit"
            onClick={()=>{
              handleCloseDialog()
            }}
          >
            Save
          </LoadingButton>
          <LoadingButton
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="outlined"
            className="secondary-button"
            color="secondary"
            onClick={()=>{
              handleCloseDialog()
            }}
          >
            Cancel
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        className="dialog"
        open={openDialog1}
        onClose={handleCloseDialog1}
        fullScreen
      >
           <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography
              // className={selected ? "symptom-text" : ""}
              style={{ fontWeight: 600, marginLeft: 1 }}
              sx={{
                paddingLeft: {
                  xs: 1,
                  sm: 0,
                }
              }}
             
              variant="h6"
            >
           Investigation Edit
            </Typography>
          
          </Toolbar>
        </AppBar>
        <Divider />
        <DialogContent>
       <InvestigationInstructionMasterSave/>
        </DialogContent>
        <DialogActions>
        <LoadingButton
            color="primary"
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="contained"
            type="submit"
            className="primary-button"
            name="submit"
            onClick={()=>{
              handleCloseDialog1()
             }}
          >
            Save
          </LoadingButton>
          <LoadingButton
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="outlined"
            className="secondary-button"
            color="secondary"
           onClick={()=>{
            handleCloseDialog1()
           }}
          >
            Cancel
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        className="dialog"
        open={openDialogInstruction}
        onClose={handleCloseDialogInstruction}
        fullScreen
      >
           <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography
              // className={selected ? "symptom-text" : ""}
              style={{ fontWeight: 600, marginLeft: 1 }}
              sx={{
                paddingLeft: {
                  xs: 1,
                  sm: 0,
                }
              }}
             
              variant="h6"
            >
           Instruction Edit
            </Typography>
          
          </Toolbar>
        </AppBar>
        <Divider />
        <DialogContent>
    <InvestigationInstructionSave/>
        </DialogContent>
        <DialogActions>
        <LoadingButton
            color="primary"
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="contained"
            type="submit"
            className="primary-button"
            name="submit"
            onClick={()=>{
              handleCloseDialogInstruction()
             }}
          >
            Save
          </LoadingButton>
          <LoadingButton
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="outlined"
            className="secondary-button"
            color="secondary"
           onClick={()=>{
            handleCloseDialogInstruction()
           }}
          >
            Cancel
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box >
  );
}
