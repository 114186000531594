import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../theme/style.scss";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  TextField,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Title from "../../components/Title";
import LoadingButton from "@mui/lab/LoadingButton";
import CustomBreadcrumbs from "../../components/BreadcrumbsComponent/CustomBreadcrumbs";
import DatePickerCompponent from "../../components/CustomFields/DatePicker";
import { Formik } from "formik";
import { format } from "date-fns";
import * as Yup from "yup";
import { CreateConsumer, UpdateConsumer, GetConsumerDetail } from "../../services/ConsumerService";
import { PreferencesContext } from "../../components/PreferencesContext";
import { AlertType } from "../../services/models/AlertType";
import { GetCountryListForSelect } from "../../services/CountryService";
import { GetPlanList } from "../../services/PlanService";
import { GetCountryRegionList, GetCountryRegionListForSelect } from "../../services/CountryRegionService";
import { EncryptDecryptService } from "../../services/common/EncryptionService";
import PhoneInput from 'react-phone-number-input'
import { CustomSelect } from "../../components/CustomFields/CustomSelect";
import dayjs from "dayjs";


const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#FAFBFD", // Set the desired background color
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
      borderWidth: "2px",
    },
  },
}));


interface formInitialValueTypes {
  consumerId: Number;
  code?: string | null;
  organization: string;
  authorizedPerson: string;
  designation: string;
  address1: string;
  address2?: string | null;
  city: string;
  postalCode: string;
  state: string,
  country: string,
  email: string;
  website?: string | null;
  primaryMobileNumber: string;
  secondaryMobileNumber: string;
  isTrialActive: boolean;
  isMobileAccess: boolean;
  trialStartDays?: string | null;
  trialEndDays?: Number | null;
  maxFirm: Number;

}

const formInitialValues: formInitialValueTypes = {
  consumerId: 0,
  code: '',
  organization: '',
  authorizedPerson: '',
  designation: '',
  address1: '',
  address2: '',
  city: '',
  postalCode: '',
  state: '',
  country: '91',
  primaryMobileNumber: '',
  secondaryMobileNumber: '',
  email: '',
  website: '',
  isTrialActive: false,
  isMobileAccess: false,
  trialStartDays: format(new Date(), 'yyyy-MM-dd'),
  trialEndDays: 14,
  maxFirm: 1,

}



export default function ConsumerSave(props: any) {
  const matches = useMediaQuery('(max-width:599px)');
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [consumerinitial, setConsumerInitial] =
    React.useState(formInitialValues);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [initConData, setInitConData] = useState(formInitialValues);

  const [checked, setChecked] = useState(false);
  const [mobileAccess, setMobileAccess] = useState(false);
  //const [chekBoxchecked, setChekBoxchecked] = React.useState(false);
  const [hide, setHide] = useState(false);
  const [mobileAccessHide, setMobileAccessHide] = useState(false);
  const [stateData, setCountryRegion] = useState([]);
  const [countryData, setCountry] = useState([]);
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  const [selectcountry, setSelectcountry] = useState(0);
  const [isLoading, setLoading] = useState(false);
  // const [planData, setPlanData] = useState('');


  const [openCreatePlan, setOpenCreatePlan] = useState<any>({ props: {}, value: false });
  const [openSelectPlan, setOpenSelectPlan] = useState<any>({ props: {}, value: false });
  const [updatePlan, setUpdatePlan] = useState<any>({ plan: {}, value: false });
  const [planListRefresh, setplanListRefresh] = useState<number>(0);
  const edService = new EncryptDecryptService();


  let navigate = useNavigate();

  // let urlParams = useQuery();
  // let curRawConsumerId = urlParams.get("q");
  // let curConsumerId = 0;

  // if (curRawConsumerId !== null && curRawConsumerId !== undefined) {

  // } else {
  //  // navigate("/not-found");
  // }

  // const [consumerId, setConsumerId] = useState(curConsumerId);

  // const handleCancleconsumer = () => {
  //   navigate("/consumers");
  // };

  // const { title, button, consumerId } =
  //   (props.location && props.location.state) || {};

  const formValidation = Yup.object().shape({
    name: Yup.string().required("Organization is required."),
    designation: Yup.string().required("Designation is required."),
    city: Yup.string().required("City is required."),
    address1: Yup.string().required("Address 1 is required."),
    postalCode: Yup.string().required("Postal code is required."),
    email: Yup.string()
      .required("Email  is required.")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, {
        message: "Email is Invalid",
      }),
    phone: Yup.string()
      .required("Phone is required.")
    // .matches(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, {
    //   message: "Invalid  number",
    //   excludeEmptyString: false,
    // })
    ,
    maxbranch: Yup.string()
      .required("Max Branches is required.")
      .matches(/^[+-]?\d*(?:[.,]\d*)?$/, {
        message: "Invalid  Value",
        excludeEmptyString: false,
      }),

    maxFirm: Yup.string()
      .required("Max Firms is required.")
      .matches(/^[+-]?\d*(?:[.,]\d*)?$/, {
        message: "Invalid  Value",
        excludeEmptyString: false,
      }),

    maxuser: Yup.string()
      .required("Max Users is required.")
      .matches(/^[+-]?\d*(?:[.,]\d*)?$/, {
        message: "Invalid  Value",
        excludeEmptyString: false,
      }),

    maxadmin: Yup.string()
      .required("Max Admins is required.")
      .matches(/^[+-]?\d*(?:[.,]\d*)?$/, {
        message: "Invalid  Value",
        excludeEmptyString: false,
      }),
    state: Yup.string().required("State is required."),
    country: Yup.string().required("Country is required."),
    contactperson: Yup.string().required("Contact Person is required."),
  });

  const handleChnageCheckbox = () => {
    if (checked === false) {
      setChecked(true);
      setHide((oldState) => !oldState);
    } else {
      setChecked(false);
      setHide((oldState) => !oldState);
    }
  };
  const handleChnageMobileAccessCheckbox = () => {
    if (mobileAccess === false) {
      setMobileAccess(true);
      setMobileAccessHide((oldState) => !oldState);
    } else {
      setMobileAccess(false);
      setMobileAccessHide((oldState) => !oldState);
    }
  }

  // function useQuery() {
  //   const { search } = useLocation();
  //   return useMemo(() => new URLSearchParams(search), [search]);
  // }
  // let urlParams = useQuery();
  // let curRawOrgId = urlParams.get("q");
  // console.log("curRawOrgId:", curRawOrgId)
  // let curConsumerId = 0;
  // if (curRawOrgId !== null && curRawOrgId !== undefined) {
  //   let decId = edService.decrypt('U2FsdGVkX18v%2FIUcwujNbuVPAkUVfwmrOULApy6AOYI%3D');
  //   if (decId !== "" && decId !== null && decId !== undefined) {
  //     curConsumerId = +decId;
  //   } else {
  //     navigate("/not-found");
  //   }
  // } else {
  //   navigate("/not-found");
  // }
  // console.log("curConsumerId:", curConsumerId)
  // const [consumerId, setConsumerId] = useState(curConsumerId);

  //Get Data
  const getConsumerDetailByid = async () => {
   debugger
    let editDetails: any
     let updatedData: any
    let params = new URLSearchParams();
    params.append("consumerId", '2');
    await GetConsumerDetail(params).then((res: any) => {
      editDetails = res.data.data
      updatedData = {
        consumerId: editDetails.consumerId,
        code: editDetails.code,
        authorizedPerson: editDetails.authorizedPerson,
        designation: editDetails.designation,
        address1: editDetails.address1,
        address2: editDetails.address2,
        city: editDetails.city,
        postalCode: editDetails.postalCode,
        primaryMobileNumber: editDetails.primaryMobileNumber,
        secondaryMobileNumber: editDetails.secondaryMobileNumber,
        email: editDetails.email,
        website: editDetails.website,
        trialStartDays: (editDetails.trialStartDays === null) ? format(new Date(), 'yyyy-MM-dd') : format(new Date(editDetails.trialStartDays), 'yyyy-MM-dd'),
        trialEndDays: (editDetails.trialEndDays === null) ? 14 : new Number(editDetails.trialEndDays),
        isTrialActive: editDetails.isTrialActive,
        isMobileAccess: editDetails.isMobileAccess,
        state: editDetails.countryRegionId,
        country: editDetails.countryID,
        subscriptionId: editDetails.subscriptionId,
        planId: editDetails.planId,
        planName: editDetails.planName,
      }
      setInitConData(editDetails)
    }).catch((err) => {
      console.log("err", err)
    })
    console.log("country", editDetails.countryID)
    return editDetails
  }


  //Submit Form
  async function SubmitConsumer(value: any) {
    setSubmitting(true);
    let consumerData = value;
    let SaveUpdateConsumer = {
      consumerId: consumerData.consumerId,
      code: consumerData.code,
      organization: consumerData.organization,
      authorizedPerson: consumerData.authorizedPerson,
      Designation: consumerData.designation,
      Address1: consumerData.address1,
      Address2: consumerData.address2,
      City: consumerData.city,
      postalCode: consumerData.postalCode,
      primaryMobileNumber: consumerData.primaryMobileNumber,
      secondaryMobileNumber: consumerData.secondaryMobileNumber,
      Email: consumerData.email,
      Website: consumerData.website,
      trialStartDays: (consumerData.trialStartDays === null) ? null : new Date(consumerData.trialStartDays),
      trialEndDays: (consumerData.trialEndDays === '') ? null : new Number(consumerData.trialEndDays),
      isTrialActive: checked,
      isMobileAccess: mobileAccess,
      state: consumerData.countryRegionId,
      country: consumerData.countryID,
      maxFirm: consumerData.maxFirm,
      
    };
    console.log(countryData,">>>>>>>>>>")
    if (consumerData.consumerId > 0) {
      const resultConsumerUpdate: any = await UpdateConsumer(
        SaveUpdateConsumer
      );
      try {
        if (resultConsumerUpdate.data === true) {
          setSubmitting(false);
          updateUserContext({ ...userContext, isAlert: true, alertMessage: resultConsumerUpdate.message, alertType: AlertType.Success, isMobile: mobileAccess });
          navigate("/consumer");
        } else {
          updateUserContext({ ...userContext, isAlert: true, alertMessage: resultConsumerUpdate.message, alertType: AlertType.Error });
        }
      } catch (e: any) {
        updateUserContext({ ...userContext, isAlert: true, alertMessage: typeof e === 'string' ? e as string : e?.message as string, alertType: AlertType.Error });
      }
    }
    else {
      const resultConsumerSave: any = await CreateConsumer(SaveUpdateConsumer);
      try {
        if (resultConsumerSave.data === true) {
          setSubmitting(false);
          updateUserContext({ ...userContext, isAlert: true, alertMessage: resultConsumerSave.message, alertType: AlertType.Success });
          navigate("/consumer");
        } else {
          updateUserContext({ ...userContext, isAlert: true, alertMessage: resultConsumerSave.message, alertType: AlertType.Error });
        }
      } catch (e: any) {
        updateUserContext({ ...userContext, isAlert: true, alertMessage: typeof e === 'string' ? e as string : e?.message as string, alertType: AlertType.Error });
      }
    }
  }

  //Country Region
  async function fatchCountryRegion(countryID) {
    debugger
    try {
      let params = new URLSearchParams();
      params.append("countryID", countryID);
      const countryRegionData: any = await GetCountryRegionListForSelect(
        params
      );
      if (countryRegionData) {
        let stateCollection = countryRegionData.data.data;
        setCountryRegion(stateCollection);
      }
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }
  }

  //Fatch Country
  async function fatchCountry() {
    debugger
    try {
      let params = new URLSearchParams();
      const countryData: any = await GetCountryListForSelect(params);
      if (countryData) {
        let countyCollection = countryData.data.data;
        setCountry(countyCollection);
      }
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }
  }

  useEffect(() => {
    //fatchCountryRegion(91)
    //getConsumerDetailByid();
    fatchCountry();
    getConsumerDetailByid().then((data: any) => {
      fatchCountryRegion(data.countryID);
    });
  }, []);



  return (
    <Box className="main-container" pl={2}>
      <Grid xs={12} container className="page-header-save">
        <Grid xs={12} sm={12} item className="page-breadcrumbs">
          <CustomBreadcrumbs />
        </Grid>
      </Grid>
      <Formik
        initialValues={initConData}
        enableReinitialize={true}
        onSubmit={(values) => { SubmitConsumer(values); }}
      >
        {(props) => {
          return (
            <form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} mb={3} className="group-container">
                  <Grid container item spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6">Basic Information</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <FormControl fullWidth>
                        <FormLabel className="fileds-label-text">code</FormLabel>
                        <CustomTextField
                          name="code"
                          placeholder=""
                          autoCapitalize="none"
                          value={props.values.code}
                          onChange={props.handleChange}
                          type="text"
                          fullWidth
                          size="small"
                        />
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={9} /> */}
                    <Grid item xs={12} sm={6} md={8} />
                    <Grid item xs={12} sm={6} md={3}>
                      <FormControl fullWidth>
                        <FormLabel className="fileds-label-text">
                          Organization
                        </FormLabel>
                        <CustomTextField
                          name="organization"
                          placeholder=""
                          autoCapitalize="none"
                          value={props.values.organization}
                          onChange={props.handleChange}
                          type="text"
                          fullWidth
                          size="small"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} />

                  </Grid>
                </Grid>

                <Grid item xs={12} mb={3} className="group-container">
                  <Grid container item spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6">Contact Information</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <FormLabel className="fileds-label-text">Authorized Person</FormLabel>
                        <CustomTextField
                          name="authorizedPerson"
                          placeholder=""
                          autoCapitalize="none"
                          value={props.values.authorizedPerson}
                          onChange={props.handleChange}
                          type="text"
                          fullWidth
                          size="small"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <FormLabel className="fileds-label-text">Designation</FormLabel>
                        <CustomTextField
                          name="designation"
                          placeholder=""
                          autoCapitalize="none"
                          value={props.values.designation}
                          onChange={props.handleChange}
                          type="text"
                          fullWidth
                          size="small"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <FormLabel className="fileds-label-text">Address 1</FormLabel>
                        <CustomTextField
                          name="address1"
                          placeholder=""
                          autoCapitalize="none"
                          value={props.values.address1}
                          onChange={props.handleChange}
                          type="text"
                          fullWidth
                          size="small"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <FormLabel className="fileds-label-text">Address 2</FormLabel>
                        <CustomTextField
                          name="address2"
                          placeholder=""
                          autoCapitalize="none"
                          value={props.values.address2}
                          onChange={props.handleChange}
                          type="text"
                          fullWidth
                          size="small"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <FormControl fullWidth>
                        <FormLabel className="fileds-label-text">City</FormLabel>
                        <CustomTextField
                          name="city"
                          placeholder=""
                          autoCapitalize="none"
                          type="text"
                          value={props.values.city}
                          onChange={props.handleChange}
                          fullWidth
                          size="small"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <FormControl fullWidth>
                        <FormLabel className="fileds-label-text">Postal code</FormLabel>
                        <CustomTextField
                          name="postalCode"
                          placeholder=""
                          autoCapitalize="none"
                          value={props.values.postalCode}
                          onChange={props.handleChange}
                          type="text"
                          fullWidth
                          size="small"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <CustomSelect
                          isNotCreatable={true}
                          id={"country"}
                          FieldName={"country"}
                          options={countryData}
                          openMenuOnFocus
                          menuPortalTarget={document.querySelector(
                            "body"
                          )}
                          handleChange={(e) => {
                            fatchCountryRegion(e?.value)
                            props.setFieldValue(
                              "country",
                              e.value !== null ? e.value : "",
                              false
                            );
                            props.setFieldValue(
                              "state",
                              "",
                              true
                            );
                            props.setFieldValue(
                              "countryRegionId",
                              "",
                              false
                            );
                          }}
                          Value={props.values.country?.toString()}
                          placeholder={"Country *"}
                          className={
                            props.values.country === "" &&
                              props.errors.country &&
                              props.touched.country
                              ? "text-input error"
                              : "text-input"
                          }
                        />
                        {props.values.country === "" &&
                          props.errors.country &&
                          props.touched.country && (
                            <Typography
                              className={
                                props.values.country === "" &&
                                  props.errors.country &&
                                  props.touched.country
                                  ? "validation-message text-input error"
                                  : "validation-message text-input"
                              }
                            >
                              {props.errors.country}
                            </Typography>
                          )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <CustomSelect
                          isNotCreatable={true}
                          id={"state"}
                          FieldName={"state"}
                          options={stateData}
                          openMenuOnFocus
                          isClearable
                          menuPortalTarget={document.querySelector(
                            "body"
                          )}
                          handleChange={(e) => {
                            props.setFieldValue(
                              "state",
                              e.value !== null ? e.value : "",
                              true
                            );
                            props.setFieldValue(
                              "countryRegionId",
                              e.value !== null ? e.value : "",
                              true
                            );
                          }}
                          Value={props.values.state?.toString()}
                          
                          placeholder={"State *"}
                          className={
                            props.values.state === "" &&
                              props.errors.state &&
                              props.touched.state
                              ? "text-input error"
                              : "text-input"
                          }
                          />
                        {console.log(props.values)}
                        {props.values.state === "" &&
                          props.errors.state &&
                          props.touched.state && (
                            <Typography
                              className={
                                props.values.state === "" &&
                                  props.errors.state &&
                                  props.touched.state
                                  ? "validation-message text-input error"
                                  : "validation-message text-input"
                              }
                            >
                              {props.errors.state}
                            </Typography>
                          )}
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <FormControl fullWidth>
                        <FormLabel className="fileds-label-text">Email</FormLabel>
                        <CustomTextField
                          name="email"
                          placeholder=""
                          autoCapitalize="none"
                          type="email"
                          value={props.values.email}
                          onChange={props.handleChange}
                          fullWidth
                          size="small"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <FormControl fullWidth>
                        <FormLabel className="fileds-label-text">Website</FormLabel>
                        <CustomTextField
                          name="website"
                          placeholder=""
                          autoCapitalize="none"
                          value={props.values.website}
                          onChange={props.handleChange}
                          fullWidth
                          size="small"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <div className="input-group">
                        <FormControl
                          fullWidth
                          error={props.errors.primaryMobileNumber && props.touched.primaryMobileNumber}
                        >
                          <label htmlFor="primaryMobileNumber">Primary Mobile Number</label>
                          <PhoneInput
                            id="primaryMobileNumber"
                            name="primaryMobileNumber"
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry="IN"
                            value={props.values.primaryMobileNumber}  // Use form value
                            onChange={(value) => props.setFieldValue('primaryMobileNumber', value)}  // Update form value
                            placeholder="Enter phone number"
                            className={
                              props.errors.primaryMobileNumber && props.touched.primaryMobileNumber
                                ? "phone-input error"
                                : "phone-input"
                            }
                          />
                          <Typography component={'span'} variant="inherit" className="validation-message">
                            {props.errors.primaryMobileNumber && props.touched.primaryMobileNumber && (
                              <Grid className="error-message">
                                {props.errors.primaryMobileNumber}
                              </Grid>
                            )}
                          </Typography>
                        </FormControl>

                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <div className="input-group">
                        <FormControl
                          fullWidth
                          error={props.errors.secondaryMobileNumber && props.touched.secondaryMobileNumber}
                        >
                          <label htmlFor="secondaryMobileNumber">Alternate Mobile Number</label>
                          <PhoneInput
                            id="secondaryMobileNumber"
                            name="secondaryMobileNumber"
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry="IN"
                            value={props.values.secondaryMobileNumber}  // Use form value
                            onChange={(value) => props.setFieldValue('secondaryMobileNumber', value)}  // Update form value
                            placeholder="Enter phone number"
                            className={
                              props.errors.secondaryMobileNumber && props.touched.secondaryMobileNumber
                                ? "phone-input error"
                                : "phone-input"
                            }
                          />
                          <Typography component={'span'} variant="inherit" className="validation-message">
                            {props.errors.secondaryMobileNumber && props.touched.secondaryMobileNumber && (
                              <Grid className="error-message">
                                {props.errors.secondaryMobileNumber}
                              </Grid>
                            )}
                          </Typography>
                        </FormControl>

                      </div>
                    </Grid>

                  </Grid>
                </Grid>
                <Grid item xs={12} mb={3} className="group-container">
                  <Grid container item spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6">Plan Information</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <FormControl fullWidth>
                        <Typography component={'span'} variant="inherit" >
                          <Checkbox
                            value={props.values.isTrialActive}
                            checked={props.values.isTrialActive}
                            onChange={(e) => {
                              props.setFieldValue('isTrialActive', e.target.checked)
                            }}
                            onClick={handleChnageCheckbox}
                          />
                          Is Trial Active
                        </Typography>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={9}>
                      <FormControl fullWidth>
                        <Typography component={'span'} variant="inherit" >
                          <Checkbox
                            value={props.values.isMobileAccess}
                            checked={props.values.isMobileAccess}
                            onChange={(e) => {
                              props.setFieldValue('isMobileAccess', e.target.checked);
                            }}
                            onClick={handleChnageMobileAccessCheckbox}
                          />
                          Has Mobile Access
                        </Typography>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <FormControl fullWidth>
                        <FormLabel className="fileds-label-text">
                          Trail Start Date
                        </FormLabel>
                        <DatePickerCompponent
                          label="Trial Start Date"
                          format="DD-MM-YYYY"
                          disabled={false}
                          mode="date"
                          slotProps={{ textField: { size: 'small' } }}

                          value={props.values?.trialStartDays ? dayjs(props.values.trialStartDays) : null}
                          onChange={(e: any) => {
                            let savedDateFormate: any = format(
                              e.$d,
                              "yyyy-MM-dd"
                            )
                            props.setFieldValue(
                              "trialStartDays",
                              savedDateFormate
                            );
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <FormControl fullWidth>
                        <FormLabel className="fileds-label-text">Trial End Days</FormLabel>
                        <CustomTextField
                          name="trialEndDays"
                          placeholder=""
                          autoCapitalize="none"
                          type="text"
                          fullWidth
                          size="small"
                          onBlur={props.handleBlur}
                          value={props.values.trialEndDays || ''}
                          onChange={props.handleChange}
                          inputProps={{
                            className: 'no-spinner',
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                            step: '1',
                            onKeyPress: (event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            },
                          }}
                        />
                      </FormControl>

                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <FormControl fullWidth>
                        <FormLabel className="fileds-label-text">Max Firm</FormLabel>
                        <CustomTextField
                          name="maxFirm"
                          placeholder=""
                          autoCapitalize="none"
                          type="text"
                          fullWidth
                          size="small"
                          onBlur={props.handleBlur}
                          value={props.values.maxFirm || ''}
                          onChange={props.handleChange}
                          inputProps={{
                            className: 'no-spinner',
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                            step: '1',
                            onKeyPress: (event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                      <Typography>Selected Plan is Standard</Typography>
                      <Typography>SELECT / VIEW PLAN</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}></Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} >
                  <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
                    <LoadingButton
                      color="primary"
                      tabIndex={3}
                      loading={isSubmitting}
                      loadingPosition="start"
                      startIcon={<></>}
                      variant="contained"
                      type="submit"
                      className="primary-button"
                      name="submit"
                    >
                      {/* {consumerId > 0 ? "Update" : "Save"} */}Save
                    </LoadingButton>
                    <LoadingButton
                      tabIndex={3}
                      loadingPosition="start"
                      startIcon={<></>}
                      variant="outlined"
                      type="submit"
                      name="submit"
                      className="secondary-button"
                      color="secondary"
                      onClick={() => navigate("/consumer")}
                    >
                      Cancel
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </form>
          )
        }}
      </Formik>

    </Box>
  );
}

