import React, { useContext, useEffect } from "react";
import { useState } from 'react';
import LoadingButton from "@mui/lab/LoadingButton";
import "../../theme/SignIn.scss";
import {
    Box,
    FormControl,
    Fade,
    TextField,
    Typography,
    Grid,
    Paper,
    FormLabel,
    styled,
    Stack,
    Alert,
} from "@mui/material";
import logo from "../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { ForgotPassword } from "../../services/AuthService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { PreferencesContext } from "../../PreferenceContext";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
const CustomTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: "#FAFBFD", // Set the desired background color
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: theme.palette.grey[300],
            borderWidth: "1px",
            BorderStyle: "solid",
        },
        "&:hover fieldset": {
            borderColor: theme.palette.grey[500],
            borderWidth: "1px",
            BorderStyle: "solid",
        },
        "&.Mui-focused fieldset": {
            borderWidth: "2px",
            borderColor: theme.palette.primary.main,
        },
    },
}));

const initialState = {
    email: "",
    token: "",
    isSubmitted: false,
    isSubmitting: false,
    message: {},
};
let signIninit = { email: "", }
export default function Forgotpassword() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(initialState);
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [showAlert, setAlertMessage] = React.useState({
        isSuccess: false,
        type: 0,
        message: "",
    });

    let navigate = useNavigate();

    // ----for Validation---
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email("Enter a valid email.")
            .required("Email is required."),
    });

    function init() {
        let useContextData = userContext;
        updateUserContext({ ...useContextData, isMenuVisible: false });
    }

    // async function submitLoginForm(value: any) {

    //     let email = value.email;
    //     setLoading(true)
    //     try {
    //         const user: any = await ForgotPassword({
    //             Username: email,
    //         });

    //         if (user) {
    //             navigate("/dashboard");
    //         }

    //     }
    //     catch (error) {
    //         if (error) {
    //             setAlertMessage({
    //                 type: 1,
    //                 isSuccess: true,
    //                 message: error.message as string,
    //             });
    //         }
    //         setLoading(false)
    //     }
    // }
    
    async function submitForm(values: any, resetForm: any) {
        
        setLoading(true);
        setData({
            ...data,
            isSubmitting: true,
            isSubmitted: false,
            email: values.email,
        });

        let useContextData = userContext;
        try {
            let formData = {
                email: values.email,
            };
            const result: any = await ForgotPassword(formData);
            updateUserContext({ ...useContextData, isMenuVisible: false });
            setLoading(false);
            //--After seccessfully Submit--:
            if (result) {
                setData({
                    ...data,
                    isSubmitting: false,
                    isSubmitted: true,
                    email: "",
                });
                
                if (result) {
                    setAlertMessage({
                        type: 1,
                        isSuccess: true,
                        message: result.message as string,
                    });
                }
                resetForm();
                // window.location.reload();
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setData({
                ...data,
                isSubmitting: false,
                isSubmitted: false,
                email: values.email,
            });

            if (error) {
                setAlertMessage({
                    type: 2,
                    isSuccess: false,
                    message: error as string,

                });
                setLoading(false);
            }
        }
        setTimeout(() => {
            setAlertMessage({
                type: 0,
                isSuccess: false,
                message: "",
            })
        }, 5000)
    }


    const methods = useForm({
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        init();
    }, []);

    return (

        <Fade in={true} timeout={{ enter: 500 }}>
            <Box className="signin-container">
                <Paper elevation={3} className="paper-singin">
                    <Grid className="img-wrapper" item xs={6}>
                        {" "}
                    </Grid>
                    <Grid item className="signin-wrapper" xs={4}>
                        <Box className="box-container" sx={{ my: 2 }}>
                            <Paper className="paper-container-forgot paper-pre-signin">
                                <Grid
                                    // marginTop={3}
                                    display="flex"
                                    justifyContent="flex-end"
                                    pb={5}
                                    item
                                >
                                    <img
                                        loading="lazy"
                                        className="img-page"
                                        src={logo}
                                        alt="logo"
                                    /></Grid>
                                <FormProvider {...methods}>
                                    <form onSubmit={methods.handleSubmit(submitForm)}>
                                        <Grid>
                                            <Grid>
                                                <Box
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "left",
                                                    }}>
                                                    <b>Reset your password</b>

                                                </Box></Grid>
                                                {(showAlert.isSuccess === true) && (showAlert.type === 1) ? (
                                                    <Grid marginTop={2}>
                                                        <Stack sx={{ width: "100%", textAlign: 'left' }}>
                                                            <Alert severity="success">{showAlert.message}</Alert>
                                                        </Stack>
                                                    </Grid>
                                                ) : (
                                                    (showAlert.isSuccess === false) && (showAlert.type === 2) ? (
                                                        <Grid marginTop={2}>
                                                            <Stack sx={{ width: "100%", textAlign: 'left' }}>
                                                                <Alert severity="error">{showAlert.message}</Alert>
                                                            </Stack>
                                                        </Grid>
                                                    ) : (''
                                                    ))
                                                }
                                                <Grid container spacing={1} marginTop={1}>
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            <FormLabel
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "left",
                                                                    fontSize: "13px",
                                                                }}
                                                                className={`fileds-label-text  ${methods.formState.errors.email ? 'validation-message' : ''}`}
                                                            >
                                                                Email
                                                            </FormLabel>
                                                            <CustomTextField
                                                                placeholder=""
                                                                autoFocus
                                                                autoCapitalize="none"
                                                                fullWidth
                                                                size="small"
                                                                margin="dense"
                                                                // value={props.values.email}
                                                                // inputProps={{ enterKeyHint: "next" }}
                                                                // onChange={props.handleChange("email")}
                                                                // onBlur={props.handleBlur}
                                                                // tabIndex={1}
                                                                {...methods.register('email')}
                                                                error={
                                                                    !!methods.formState.errors.email
                                                                }
                                                                className={`text-input ${methods.formState.errors.email ? 'error' : ''}`}
                                                            />
                                                            <Typography
                                                                variant="inherit"
                                                                className="validation-message"
                                                                sx={{ textAlign: 'left' }}
                                                            >
                                                                {methods.formState.errors.email?.message}
                                                            </Typography>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={3} marginTop={0}>
                                                    <Grid item xs={12} textAlign="right">
                                                        <LoadingButton
                                                            color="primary"
                                                            loading={loading}
                                                            fullWidth
                                                            tabIndex={3}
                                                            loadingPosition="start"
                                                            startIcon={<></>}
                                                            variant="contained"
                                                            type="submit"
                                                            className="submit-btn"
                                                            name="submit"
                                                        >
                                                            Submit
                                                        </LoadingButton>
                                                    </Grid>
                                                </Grid>
                                                <Grid spacing={3} marginTop={3}>
                                                    <Box
                                                        className="text-primary"
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <Link
                                                            to="/"
                                                            style={{
                                                                textDecoration: "none",
                                                                color: '#3b75ff',
                                                                fontSize: "13px",
                                                                fontFamily: "$font-family-inter",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    marginRight: "10px",
                                                                    lineHeight: "1px",
                                                                }}
                                                            >
                                                                <ArrowBackIcon sx={{ fontSize: "13px" }} />
                                                            </span>
                                                            <span
                                                            >
                                                                Back to Sign in
                                                            </span>
                                                        </Link>
                                                    </Box>
                                                </Grid>
                                           
                                        </Grid>
                                    </form>
                                </FormProvider>

                            </Paper>
                        </Box>
                    </Grid>
                </Paper>
            </Box>
        </Fade>

    );
}
