import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, Box, Button, colors, Grid, Tab, Tabs } from "@mui/material";
import "../../../theme/style.scss";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ChiefComplaints from "./Components/ChiefComplaints/ChiefComplaints";
import History from "./Components/History/History";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import SmartphoneRoundedIcon from "@mui/icons-material/SmartphoneRounded";
import Examination from "./Components/Examination/Examination";
import Diagnosis from "./Components/Diagnosis/Diagnosis";
import Procedure from "./Components/Procedure/Procedure";
import FollowUp from "./Components/FollowUp/FollowUp";
import Vaccine from "./Components/Vaccine/Vaccine";
import Prescription from "./Components/Prescription/Prescription";
import Investigation from "./Components/Investigation/investigation";
export type Props = {
  handleClose: (value: boolean) => void;
};

const item = {
  patientWaitingNumber: "MD825",
  patientNumber: "1000142",
  status: "Completed",
  patientName: "Alice Johnson",
  age: "38",
  gender: "F",
  mobileNo: "555-123-4567",
  followUpVisitReason: "Routine Checkup",
  referralDoctorName: "Dr. Marry Smith",
  lastVisit: "12/06/2024",
  address: "123 Main St, Springfield",
  symptoms: "Essential hypertension, Diabetes mellitus, Osteoarthritis",
  patientStatus: "New",
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{
        paddingTop: {
          xs: 2,
          sm: 2,
          md: 2,
          lg: 2
        },
        paddingLeft: {
          xs: 4,
          sm: 4,
          md: 4,
          lg: 4
        },
        paddingRight: {
          xs: 2,
          sm: 4,
          md: 4,
          lg: 4
        },
        maxWidth: '100%', // Ensures box doesn't overflow screen width

      }}>{children}</Box>}
    </div>
  );
}

const OPDClinicalDetail: React.FC<Props> = React.memo(({ handleClose }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function TabProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <React.Fragment>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>

          <Grid container padding={0} spacing={1}>
            <Grid item xs={12} sm={12}>
              <Box style={{ paddingLeft: 0 }} >
                <span className="patient-container ">{item.patientName} </span>
                <span className="text-saperator">|</span>
                <span
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    color:
                      item.gender === "M"
                        ? " #2e67a3"
                        : item.gender === "F"
                          ? "#BC3965"
                          : "black",
                  }}
                >
                  {` ${item.gender} `}
                </span>
                <span
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    color: '#414D56'
                  }}
                >
                  {`${item.age}`}
                </span>
              </Box>
            </Grid>
            <Grid xs={12} sm={12} sx={{ paddingLeft: "0px", paddingTop: "8px" }}>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: 0,
                  color: '#414D56',
                }}
              >
                <span>
                  <Alert
                    icon={false}
                    className={
                      item.patientStatus.toUpperCase() == "NEW"
                        ? "badge badge-transparent"
                        : "badge-new1 badge-dark"
                    }
                  >
                    {item.patientStatus}
                  </Alert>
                </span>
                <span className="text-saperator">|</span>
                <span className="patient-waiting-number">
                  {`${item.patientWaitingNumber}`}
                </span>
                <span className="text-saperator">|</span>
                <span
                  style={{ paddingLeft: 4, paddingRight: 4 }}
                  className="patient-waiting-number"
                >
                  {` ${item.patientNumber}`}
                </span>
                <span
                  style={{ paddingLeft: "4px" }}
                  className="detail-header-icon-container"
                >
                  <LocationOnRoundedIcon className="detail-header-icon" />
                  {`${item.address}`}
                </span>
                <span
                  style={{ paddingLeft: "16px" }}
                  className="detail-header-icon-container"
                >
                  <SmartphoneRoundedIcon className="detail-header-icon" />
                  {` ${item.mobileNo}`}
                </span>
              </Box>
            </Grid>
          </Grid>

          <IconButton
            edge="start"
            onClick={handleClose}
            aria-label="close"
            m={3}
            size="large"
            p={2}
          >
            <CheckRoundedIcon color="primary" fontSize="large" sx={{ margin: "-5px", color: "green" }}>

            </CheckRoundedIcon>
          </IconButton>
          <IconButton
            edge="start"
            onClick={handleClose}
            aria-label="close"
            m={3}
            p={2}
            size="large"
            style={{ marginLeft: "0px" }}
          >
            <CloseIcon color="primary" fontSize="large" sx={{ margin: "-5px", color: "red" }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box>
        <Grid item xs={6} sm={12}>
          <Box className="item-grid-row-container" sx={{ paddingLeft: "20px" }}>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12}>
                <Box>
                  <span style={{ color: "#74788d", fontSize: "12px" }}>
                    {"WT "}
                  </span>
                  <span style={{ color: "#3b75ff", fontWeight: 500 }}>30 kg</span>
                  <span className="text-saperator">|</span>
                  <span style={{ color: "#74788d", fontSize: "12px" }}>
                    {"HT "}
                  </span>
                  <span style={{ color: "#3b75ff", fontWeight: 500 }}>
                    49.00 cm
                  </span>
                  <span className="text-saperator">|</span>
                  <span style={{ color: "#74788d", fontSize: "12px" }}>
                    {"BMI "}
                  </span>
                  <span style={{ color: "#3b75ff", fontWeight: 500 }}>
                    11.00 kg/m<sup>2</sup>
                  </span>
                  <span className="text-saperator">|</span>
                  <span style={{ color: "#74788d", fontSize: "12px" }}>
                    {"BSA "}
                  </span>
                  <span style={{ color: "#3b75ff", fontWeight: 500 }}>
                    11.00 m<sup>2</sup>
                  </span>
                  <span className="text-saperator">|</span>
                  <span style={{ color: "#74788d", fontSize: "12px" }}>
                    {"PR "}
                  </span>
                  <span style={{ color: "#3b75ff", fontWeight: 500 }}>
                    130bpm
                  </span>
                  <span className="text-saperator">|</span>
                  <span style={{ color: "#74788d", fontSize: "12px" }}>
                    {"SpO2 "}
                  </span>
                  <span style={{ color: "#3b75ff", fontWeight: 500 }}>
                    98.00%
                  </span>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              style={{
                marginTop: 0,
                paddingTop: "4px",
                marginBottom: "8px",
              }}
            >
              <Grid item xs={12} sm={12}>
                {"Ref. By "}
                <span
                  className="text-uppercase"
                  style={{ fontWeight: 500, color: "#3b75ff" }}
                >
                  {`${item.referralDoctorName}`}
                </span>
                <span className="text-saperator">|</span>

                {"Last Visit "}
                <span style={{ fontWeight: 500, color: "#3b75ff" }}>
                  {item.lastVisit}
                </span>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={1}
              style={{
                marginTop: 1,
              }}
            >
              <Grid item xs={12} sm={12} style={{ paddingTop: "0px" }}>
                <Box className="symptoms-container"  >{item.symptoms}</Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Box sx={{ borderBottom: 1, borderColor: "divider", paddingTop: 2 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            aria-label="Sale Invoice"
            TabIndicatorProps={{ sx: { backgroundColor: "#2e67a3" } }}
            sx={{
              "& button:hover": {
                backgroundColor: "#f1f6fe",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              },
            }}
          >
            <Tab
              sx={{ textTransform: "capitalize !important" }}
              label={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <span>Chief complaints </span>
                </Box>
              }
              {...TabProps(0)}
            />
            <Tab
              sx={{ textTransform: "capitalize !important" }}
              label={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <span>History</span>
                </Box>
              }
              {...TabProps(1)}
            />
            <Tab
              sx={{ textTransform: "capitalize !important" }}
              label={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <span>Examination</span>
                </Box>
              }
              {...TabProps(2)}
            />
            <Tab
              sx={{ textTransform: "capitalize !important" }}
              label={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <span>Investigation</span>
                </Box>
              }
              {...TabProps(2)}
            />
            <Tab
              sx={{ textTransform: "capitalize !important" }}
              label={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <span>Diagnosis</span>
                </Box>
              }
              {...TabProps(3)}
            />
            <Tab
              sx={{ textTransform: "capitalize !important" }}
              label={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <span>Procedure</span>
                </Box>
              }
              {...TabProps(4)}
            />
            <Tab
              sx={{ textTransform: "capitalize !important" }}
              label={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <span>Immunization</span>
                </Box>
              }
              {...TabProps(5)}
            />
            <Tab
              sx={{ textTransform: "capitalize !important" }}
              label={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <span>Prescription</span>
                </Box>
              }
              {...TabProps(6)}
            />
            <Tab
              sx={{ textTransform: "capitalize !important" }}
              label={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <span>Follow-up</span>
                </Box>
              }
              {...TabProps(7)}
            />
          </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>
          <ChiefComplaints />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <History />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Examination />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Investigation />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <Diagnosis />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          <Procedure />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={6}>
          < Vaccine />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={7}>
          <Prescription />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={8}>
          <FollowUp />
        </CustomTabPanel>
      </Box>
    </React.Fragment>
  );
});
export default OPDClinicalDetail;
