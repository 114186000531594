import "../../../../../../theme/style.scss";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
  styled,
  Dialog,
  DialogContent
} from "@mui/material";
import React, { useState } from "react";
import { Formik } from "formik";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import { MultiUpload } from "../../../../../../components/MultiUpload/MultiUpload";
import ReactQuill from "react-quill";
import { AsyncCustomSelect, CustomSelect } from "../../../../../../components/CustomFields/CustomSelect";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import HistoryDraggableTable from "../HistoryDraggable/HistoryDraggableTable";
import { reorder } from "../../../../../../Helpers/helpers";
import { DropResult } from "react-beautiful-dnd";
import useMediaQuery from '@mui/material/useMediaQuery'
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import SubjectRoundedIcon from "@mui/icons-material/SubjectRounded";

const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#FAFBFD",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function DietHistory() {
  const [showNote, setShowNote] = useState(false);
  const [documentsFiles, setDocumentsFile] = useState<any>([]);
  const [isDietHistory, setIsDietHistory] = useState("");
  const [typeOfDiet, setTypeOfDiet] = useState(false);
  const [foodItemsTakenDuringMeals, setFoodItemsTakenDuringMeals] = useState(false);
  const [changeInFoodItems, setChangeInFoodItems] = useState(false);
  const [intakeOfFood, setIntakeOfFood] = useState(false);
  const [selected, setSelected] = useState("");
  const smallScreen = useMediaQuery("(max-width:768px)");
  const mobileScreen = useMediaQuery("(max-width:425px)");


  //Draggable Table
  const [items, setItems] = React.useState([
    {
      id: "Item 1",
      symptom: "Type of diet",
    },
    {
      id: "Item 2",
      symptom: "Food Items taken during meals",
    },
    {
      id: "Item 3",
      symptom: "History of change in food items in recent past",
    },
    {
      id: "Item 4",
      symptom: "Approx. intake of food per day in grams",
    },
  ]);

  const saveLogoFileSelected = (files: any) => {
    setDocumentsFile(files);
  };
  const dowloadDocumentFile = (files) => { };

  const removeFile = (file) => { };

  const [foodItemList, setFoodItemList] = React.useState([
    {
      id: "Item 1",
      medicine: "Dal"
    },
    {
      id: "Item 2",
      medicine: "Chapati"
    },
    {
      id: "Item 3",
      medicine: "Rice"
    },
    {
      id: "Item 4",
      medicine: "Curry"
    },
    {
      id: "Item 5",
      medicine: "Sambar"
    }
  ]
  );

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(foodItemList, source.index, destination.index);

    setFoodItemList(newItems);
  };
  const [openDietHistory, setOpenDietHistory] = React.useState(false);

  const handleClickOpenDietHistory = (value: boolean) => {
    if(smallScreen){
      
      setOpenDietHistory(value);
    }else if(mobileScreen){
      setOpenDietHistory(value);
      
    }
  };

  const handleClickCloseDietHistory = () => {
    setOpenDietHistory(false);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (value: boolean) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onHandleSelectData = (val) => {
    if(smallScreen){
    handleClickOpen(true);
    }
    else if(mobileScreen){
      handleClickOpen(true);
    }
    setSelected(val);
  };

  const [openDietSummary, setOpenDietSummary] = React.useState(false);

  const handleClickOpenDietSummary = (value: boolean) => {
    if (mobileScreen) {
      setOpenDietSummary(value);
    }
    else if (smallScreen) {
      setOpenDietSummary(value);
    }
  };

  const handleClickCloseDietSummary= () => {
    setOpenDietSummary(false);
  };
  
  return (
    <Box className="main-container">
      <Grid container spacing={0}>
        <Grid item xs={12} mb={2}>
          <Grid container item spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                className="template-table-container"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                }}
              >
                <Box className="inner-title-container">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box className="inner-header-container"
                    sx={{display:"flex", justifyContent:"space-between"}}
                    >
                      <Typography style={{ fontWeight: 600 }}>
                        Diet History
                      </Typography>
                      {mobileScreen || smallScreen ? <Box>
                      
                      <Button onClick={() => handleClickOpenDietSummary(true)} sx={{ padding: 0, margin: 0, minWidth:"30px" }} aria-label="delete">
                       <SubjectRoundedIcon></SubjectRoundedIcon>
                      </Button>
            </Box> : ''}
                    </Box>
                  </Paper>
                  <Divider />
                  <List>
                    {items.map((item: any, index: number) => (
                      <ListItem
                        sx={{
                          borderBottom: "1px solid #E9EBEC", cursor: 'pointer'
                        }}
                        className={
                          isDietHistory === item.symptom
                            ? "draggable-list-item"
                            : " "
                        }
                      >
                        <Grid container item spacing={2}>
                          <Grid item xs={12} sm={12} md={12}>
                            <Box
                              style={{
                                display: "flex",
                              }}
                              onClick={()=>{handleClickOpenDietHistory(true)}}
                            >
                              <Grid container item>
                                <Grid item xs={12} sm={12} md={12}>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() => {
                                      setIsDietHistory(item.symptom)
                                    }}
                                  >
                                    <Box
                                      className={
                                        isDietHistory === item.symptom
                                          ? "symptom-text"
                                          : " "
                                      }
                                      sx={{ paddingBottom: '8px', paddingTop: '8px' }}
                                  
                                      >
                                      <Typography>{item.symptom}</Typography>
                                    </Box>
                                    {/* <Box>
                                      <FormGroup>
                                        <FormControlLabel
                                          className={
                                            isDietHistory === item.symptom
                                              ? "symptom-text"
                                              : " "
                                          }
                                          control={
                                            <Checkbox
                                              onChange={(e) => {
                                                setIsDietHistory(
                                                  e.target.value
                                                );
                                                console.log(
                                                  "checkbox data:",
                                                  e.target.value
                                                );
                                              }}
                                              value={item.symptom}
                                            />
                                          }
                                          label={item.symptom}
                                        />
                                      </FormGroup>
                                    </Box> */}
                                    {isDietHistory === item.symptom && (
                                      <Box
                                        style={{
                                          paddingLeft: '8px',
                                          paddingTop: "8px",
                                        }}
                                      >
                                        <KeyboardDoubleArrowRightRoundedIcon />
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                  </List>
                  {/* <Box
                    className="search-container-draggable"
                  >
                    <FormControl fullWidth>
                      <CustomTextField
                        name="templateName"
                        placeholder="Search..."
                        autoCapitalize="none"
                        type="text"
                        fullWidth
                        size="small"
                      />
                    </FormControl>
                  </Box> */}
                  {/* <Divider /> */}
                  <Box className="note-container">
                    <Box>
                      {!showNote && (
                        <Button
                          variant="text"
                          style={{ fontSize: "6px" }}
                          onClick={() => {
                            setShowNote(true);
                          }}
                        >
                          <AddIcon className="add-icon" />
                          <span className="add-icon"> Note</span>
                        </Button>
                      )}
                    </Box>

                    {showNote && (
                      <FormControl fullWidth>
                        <Box
                          className='note-container-box'
                        >
                          <FormLabel
                            sx={{ paddingLeft: 1 }}
                            className="fileds-label-text"
                          >
                            Note
                          </FormLabel>
                          <Button
                            variant="text"
                            onClick={() => {
                              setShowNote(false);
                            }}
                          >
                            <RemoveIcon className="remove-note" />
                            <span className="remove-note">Remove Note</span>
                          </Button>
                        </Box>
                        <ReactQuill
                          className="react-quill-alignment"
                          theme="snow"
                          value={""}
                          onChange={(e) => { }}
                        />
                      </FormControl>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container template-hide-small-screen template-hide-medium-screen"
                sx={{
                  border: "1px solid #0000001f",
                  minHeight: 400,
                  borderRadius: 1,
                }}
              >
                <Box className="inner-title-container">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    {/* <Box className="inner-header-container">
                      <Typography
                        className="symptom-text"
                        style={{ fontWeight: 600 }}
                      >
                        {isDietHistory ? isDietHistory : "Diet History"}
                      </Typography>
                    </Box> */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="inner-header-container">
                      <Typography
                        className={isDietHistory ? "symptom-text" : ""}
                        style={{ fontWeight: 600 }}
                      >
                        {isDietHistory ? isDietHistory : "Diet History Summary"}
                      </Typography>
                      {isDietHistory !== '' && <Typography>
                        <IconButton onClick={() => setIsDietHistory("")} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                          <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                        </IconButton>
                      </Typography>}
                    </Box>
                  </Paper>
                  {isDietHistory ? (
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                        <Divider />
                        <Grid
                          container
                          item
                          spacing={2}
                          style={{ padding: 20 }}
                        >
                          {/* {isDietHistory === 'Type of diet' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setTypeOfDiet(!typeOfDiet);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Type of diet"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {isDietHistory === 'Type of diet'
                            // && typeOfDiet 
                            ? <Grid item xs={12} sm={12} md={6}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Type of diet
                                </FormLabel>
                                <CustomSelect
                                  // isNotCreatable={true}
                                  id={"diet"}
                                  FieldName={"diet"}
                                  options={[]}
                                  openMenuOnFocus
                                  isClearable
                                  isSearchable
                                  menuPortalTarget={document.querySelector("body")}
                                  handleChange={(e) => {
                                    console.log("event:", e);
                                  }}
                                  Value=""
                                />
                              </FormControl>
                            </Grid> : ''}
                          {isDietHistory === 'Type of diet'
                            // && typeOfDiet 
                            ? <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> : ''}
                         

                          {isDietHistory === 'Food Items taken during meals'
                            // && foodItemsTakenDuringMeals 
                            ?
                            <Grid item xs={12} sm={12} md={12}>
                              <Box
                                className="template-table-container"
                                style={{
                                  border: "1px solid #0000001f",
                                  borderRadius: 4,
                                }}
                              >
                                <Box className="inner-title-container">
                                  <HistoryDraggableTable
                                    header="Food Items"
                                    items={foodItemList}
                                    onDragEnd={onDragEnd}
                                    onHandleSelectData={onHandleSelectData}
                                    selected={selected}
                                  />
                                </Box>
                              </Box>
                            </Grid> : ''}
                          {isDietHistory === 'Food Items taken during meals'
                            // && typeOfDiet 
                            ? <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> : ''}
                          {isDietHistory === 'Food Items taken during meals'
                            //  && foodItemsTakenDuringMeals
                            ? <Grid item xs={12} sm={12} md={6} className="OPD-Upload-Button ">
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid> : ''}
                          {/* {isDietHistory === 'History of change in food items in recent past' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setChangeInFoodItems(!changeInFoodItems);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="History of change in food items in recent past"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {isDietHistory === 'History of change in food items in recent past'
                            // && changeInFoodItems 
                            ? <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> : ''}
                            {isDietHistory === 'History of change in food items in recent past'
                           
                            ? <Grid item xs={12} sm={12} md={6} className="OPD-Upload-Button ">
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid> :
                            <Divider />
                          }
                          {/* {isDietHistory === 'Approx. intake of food per day in grams' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setIntakeOfFood(!intakeOfFood);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Approx. intake of food per day in grams"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {isDietHistory === 'Approx. intake of food per day in grams'
                            // && intakeOfFood 
                            ? <Grid item xs={12} sm={12} md={3}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Food Intake per day
                                </FormLabel>
                                <OutlinedInput
                                  id="outlined-adornment-weight"
                                  size="small"
                                  endAdornment={<InputAdornment position="end">(grams)</InputAdornment>}
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    'aria-label': 'weight',
                                  }}
                                />
                              </FormControl>
                            </Grid> : ''}
                          {isDietHistory === 'Approx. intake of food per day in grams'
                            // && intakeOfFood 
                            ? <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> : ''}
                         
                        </Grid>
                      </Box>
                    </Formik>
                  ) : (
                    <Box>  <Divider />
                      <Box className="summary-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
          
            <Typography 
             style={{ fontWeight: 600 }}
            sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
         {selected ? selected : 'Food Items'}
            </Typography>
            <IconButton onClick={() => handleClose(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                          <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                        </IconButton>
           
          </Toolbar>
        </AppBar>
       <DialogContent style={{ height: 400 }}>
       <Box
                className="template-table-container"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                  minHeight: 200,
                }}
              >
                <Box className="inner-title-container">
                
                  <Box>
                    
                    <Box>This is summary section new one</Box>
                  </Box>
                </Box>
              </Box>
       </DialogContent>
      </Dialog>

      <Dialog
      fullScreen
                open={openDietHistory}
                onClose={handleClickCloseDietHistory}
            >
               <AppBar sx={{ position: 'relative' }}>
                 <Toolbar
                    sx={{ display:"flex",
                    justifyContent:"space-between"}}>
                     <Typography
                        className={selected ? "symptom-text" : ""}
                        style={{ fontWeight: 600, margin: 1 }}
                        sx={{
                        paddingLeft:{
                          xs:1,
                          sm:0,
                          }
                       }}
                      variant="h6"
                     >
          {isDietHistory ? isDietHistory : "Diet History"}
            </Typography>
            <IconButton onClick={() => handleClickCloseDietHistory(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
              <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
            </IconButton>
          </Toolbar>
          <Divider />
        </AppBar>
                <DialogContent style={{ height: 400 }}>
          
                <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container "
                sx={{
                  border: "0px solid #0000001f",
                  minHeight: 400,
                  borderRadius: 1,
                }}
              >
                <Box className="inner-title-container">
                  
                  {isDietHistory ? (
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                     
                        <Grid
                          container
                          item
                          spacing={2}
                       
                        >
                          {/* {isDietHistory === 'Type of diet' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setTypeOfDiet(!typeOfDiet);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Type of diet"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {isDietHistory === 'Type of diet'
                            // && typeOfDiet 
                            ? <Grid item xs={12} sm={12} md={6}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Type of diet
                                </FormLabel>
                                <CustomSelect
                                  // isNotCreatable={true}
                                  id={"diet"}
                                  FieldName={"diet"}
                                  options={[]}
                                  openMenuOnFocus
                                  isClearable
                                  isSearchable
                                  menuPortalTarget={document.querySelector("body")}
                                  handleChange={(e) => {
                                    console.log("event:", e);
                                  }}
                                  Value=""
                                />
                              </FormControl>
                            </Grid> : ''}
                          {isDietHistory === 'Type of diet'
                            // && typeOfDiet 
                            ? <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> : ''}
                         

                          {isDietHistory === 'Food Items taken during meals'
                            // && foodItemsTakenDuringMeals 
                            ?
                            <Grid item xs={12} sm={12} md={12}>
                              <Box
                                className="template-table-container"
                                style={{
                                  border: "1px solid #0000001f",
                                  borderRadius: 4,
                                }}
                              >
                                <Box className="inner-title-container">
                                  <HistoryDraggableTable
                                    header="Food Items"
                                    items={foodItemList}
                                    onDragEnd={onDragEnd}
                                    onHandleSelectData={onHandleSelectData}
                                    selected={selected}
                                  />
                                </Box>
                              </Box>
                            </Grid> : ''}
                          {isDietHistory === 'Food Items taken during meals'
                            // && typeOfDiet 
                            ? <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> : ''}
                          {isDietHistory === 'Food Items taken during meals'
                            //  && foodItemsTakenDuringMeals
                            ? <Grid item xs={12} sm={12} md={6} >
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid> : ''}
                          {/* {isDietHistory === 'History of change in food items in recent past' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setChangeInFoodItems(!changeInFoodItems);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="History of change in food items in recent past"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {isDietHistory === 'History of change in food items in recent past'
                            // && changeInFoodItems 
                            ? <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> : ''}
                            {isDietHistory === 'History of change in food items in recent past'
                           
                           ? <Grid item xs={12} sm={12} md={6}>
                             <MultiUpload
                               onDropandSelect={saveLogoFileSelected}
                               editFile={documentsFiles}
                               MAX_COUNT={5}
                               onFileDownload={dowloadDocumentFile}
                               onFileRemove={(file) => {
                                 removeFile(file);
                               }}
                             />
                           </Grid> :
                           <Divider />
                         }
                          {/* {isDietHistory === 'Approx. intake of food per day in grams' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setIntakeOfFood(!intakeOfFood);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Approx. intake of food per day in grams"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {isDietHistory === 'Approx. intake of food per day in grams'
                            // && intakeOfFood 
                            ? <Grid item xs={12} sm={12} md={3}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Food Intake per day
                                </FormLabel>
                                <OutlinedInput
                                  id="outlined-adornment-weight"
                                  size="small"
                                  endAdornment={<InputAdornment position="end">(grams)</InputAdornment>}
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    'aria-label': 'weight',
                                  }}
                                />
                              </FormControl>
                            </Grid> : ''}
                          {isDietHistory === 'Approx. intake of food per day in grams'
                            // && intakeOfFood 
                            ? <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> : ''}
                         
                        </Grid>
                      </Box>
                    </Formik>
                  ) : (
                    <Box>  <Divider />
                      <Box>This is summary section </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>      
                </DialogContent>
            </Dialog>

            <Dialog
            fullScreen
            open={openDietSummary}
            onClose={handleClickCloseDietSummary}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
                <Typography
                  // className={selected ? "symptom-text" : ""}
                  style={{ fontWeight: 600, marginLeft: 0 }}
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                >
         Diet History Summary
                </Typography>
                <IconButton onClick={() => handleClickCloseDietSummary(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                  <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent style={{ height: 400 }}>
            <Box className="template-table-container summary-dialog-mainContainer">
                      <Box className="summary-dialog-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
            </Box>
            </DialogContent>
          </Dialog>
    </Box>
  );
}
