import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../theme/style.scss";
import {
  Box,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
  Dialog,
  AppBar,
  DialogContent,
  Toolbar,
  styled,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Select from "react-select";
import { DropResult } from 'react-beautiful-dnd';
import { reorder } from '../../../Helpers/helpers';
import ReactQuill from 'react-quill';
import CustomTimePicker from "../../../components/TimePicker/CustomTimePicker";
import DialogActions from '@mui/material/DialogActions';
import { AsyncCustomSelect, CustomSelect } from "../../../components/CustomFields/CustomSelect";
import DraggableList from '../../../components/DraggableTable/DraggableList';
import BreadcrumbsComponent from "../../../components/BreadcrumbsComponent/BreadcrumbsComponent";
import CustomBreadcrumb from "../../../components/BreadcrumbsComponent/CustomBreadcrumbs";
import MedicineInstructionMasterSave from "../../../pages/Medicine/_component/MedicineInstructionMasterSave";
import MedicineInstructionSave from "../../../pages/Medicine/_component/MedicineInstructionSave";
import { Formik } from "formik";


const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#FAFBFD",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));


export default function MedicineTemplateSave() {
  const navigate = useNavigate();

  //Draggable Table
  const [items, setItems] = React.useState([
    {
      id: "Item 1",
      primary: "Practical Rubber Computer1",
      secondary: "Synergistic leading edge matrices"
    }, {
      id: "Item 2",
      primary: "Practical Rubber Computer2",
      secondary: "Synergistic leading edge matrices"
    }, {
      id: "Item 3",
      primary: "Practical Rubber Computer3",
      secondary: "Synergistic leading edge matrices"
    }
  ]);
  const [itemsOther, setItemsOther] = React.useState([
    {
      id: "Item1 1",
      primary: "1Practical Rubber Computer1",
      secondary: "1Synergistic leading edge matrices"
    }, {
      id: "Item1 2",
      primary: "1Practical Rubber Computer2",
      secondary: "1Synergistic leading edge matrices"
    }, {
      id: "Item1 3",
      primary: "1Practical Rubber Computer3",
      secondary: "1Synergistic leading edge matrices"
    }
  ]);

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(items, source.index, destination.index);

    setItems(newItems);
  };
  const onDragEnd1 = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(itemsOther, source.index, destination.index);

    setItemsOther(newItems);
  };
  const [openDialog, setOpenDialog] = React.useState(false);
  const onHandleOpenDialog = (item: Item) => {

    handleClickOpen(true);
    console.log("Opening dialog for item:", item);
  };
  const handleClickOpen = (value: boolean) => {
    setOpenDialog(value);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const [openDialog1, setOpenDialog1] = React.useState(false);
  const onHandleOpenDialog1 = (item: Item) => {

    handleClickOpen1(true);
    console.log("Opening dialog for item:", item);
  };
  const handleClickOpen1 = (value: boolean) => {
    setOpenDialog1(value);
  };

  const handleClose1 = () => {
    setOpenDialog1(false);
  };



  return (
    <Box className="main-container" pl={2}>
      <Grid item xs={12} container className="page-header-save">
        <Grid xs={12} sm={12} item className="page-breadcrumbs">
          <CustomBreadcrumb />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} mb={2} className="group-container">
          <Grid container item spacing={2}>

            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <FormLabel className="fileds-label-text">Template Name</FormLabel>
                <CustomTextField
                  name="templateName"
                  placeholder=""
                  autoCapitalize="none"
                  type="text"
                  fullWidth
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} />

            <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container"
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  border: '1px solid #0000001f',
                  borderRadius: 4
                }}
              >
                <Box
                  style={{
                    flex: 1,
                    borderRadius: 8
                  }}>
                  <Box
                    className='template-box'
                  >
                    <Typography>
                      Medicine(s)
                    </Typography>
                  </Box>
                  <Divider />
                  <DraggableList
                    items={items}
                    onDragEnd={onDragEnd}
                    onHandleOpenDialog={onHandleOpenDialog}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container"
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  border: '1px solid #0000001f',
                  borderRadius: 4
                }}
              >
                <Box
                  style={{
                    flex: 1,
                    borderRadius: 8
                  }}>
                  <Box
                    className='template-box'
                  >
                    <Typography>
                      Medicine Instruction(s)
                    </Typography>
                  </Box>
                  <Divider />
                  <DraggableList
                    items={items}
                    onDragEnd={onDragEnd}
                    onHandleOpenDialog={onHandleOpenDialog1}
                  />
                </Box>
                
              </Box>
            </Grid>

          </Grid>
        </Grid>
        <Dialog
          className="dialog1"
          open={openDialog}
          onClose={handleClose}
          fullScreen
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              width: "1200px", // Adjust the width as needed
              maxWidth: "none",
            },
          }}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <Typography
                // className={selected ? "symptom-text" : ""}
                style={{ fontWeight: 600, marginLeft: 0 }}
                sx={{ ml: 2, flex: 1 }}
                variant="h6"
              >
                Medicine Master Edit
              </Typography>
            </Toolbar>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </AppBar>
          <DialogContent className="padding-remove-Investigation-Edit main-dialog-background-color">
            <MedicineInstructionMasterSave/>
          </DialogContent>
          <Divider />
          <DialogActions className="padding-add-investigation-templete-save-button">
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
                <LoadingButton
                  color="primary"
                  tabIndex={3}
                  loadingPosition="start"
                  startIcon={<></>}
                  variant="contained"
                  type="submit"
                  onClick={handleClose}
                  className="primary-button"
                  name="submit"
                >
                  Save
                </LoadingButton>
                <LoadingButton
                  tabIndex={3}
                  loadingPosition="start"
                  startIcon={<></>}
                  variant="outlined"
                  type="submit"
                  name="submit"
                  className="secondary-button"
                  color="secondary"
                  onClick={handleClose}                >
                  Cancel
                </LoadingButton>
              </Box>
            </Grid>
          </DialogActions>
        </Dialog>
        <Dialog
          className="dialog1"
          open={openDialog1}
          onClose={handleClose1}
          fullScreen
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              width: "1200px", // Adjust the width as needed
              maxWidth: "none",
            },
          }}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <Typography
                // className={selected ? "symptom-text" : ""}
                style={{ fontWeight: 600, marginLeft: 0 }}
                sx={{ ml: 2, flex: 1 }}
                variant="h6"
              >
                Medicine Master Edit
              </Typography>
            </Toolbar>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </AppBar>
          <DialogContent className="padding-remove-Investigation-Edit main-dialog-background-color">
            <MedicineInstructionSave />
          </DialogContent>
          <Divider />
          <DialogActions className="padding-add-investigation-templete-save-button">
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
                <LoadingButton
                  color="primary"
                  tabIndex={3}
                  loadingPosition="start"
                  startIcon={<></>}
                  variant="contained"
                  type="submit"
                  onClick={handleClose1}
                  className="primary-button"
                  name="submit"
                >
                  Save
                </LoadingButton>
                <LoadingButton
                  tabIndex={3}
                  loadingPosition="start"
                  startIcon={<></>}
                  variant="outlined"
                  type="submit"
                  name="submit"
                  className="secondary-button"
                  color="secondary"
                  onClick={handleClose1}                >
                  Cancel
                </LoadingButton>
              </Box>
            </Grid>
          </DialogActions>
        </Dialog>


        
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
            <LoadingButton
              color="primary"
              tabIndex={3}
              loadingPosition="start"
              startIcon={<></>}
              variant="contained"
              type="submit"
              onClick={() => navigate("/master/medicine-template")}
              className="primary-button"
              name="submit"
            >
              Save
            </LoadingButton>
            <LoadingButton
              tabIndex={3}
              loadingPosition="start"
              startIcon={<></>}
              variant="outlined"
              type="submit"
              name="submit"
              className="secondary-button"
              color="secondary"
              onClick={() => navigate("/master/medicine-template")}
            >
              Cancel
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
