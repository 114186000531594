import "../../../../../../theme/style.scss";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
  styled,
  DialogActions,
} from "@mui/material";
import React, { useState } from "react";
import { Formik } from "formik";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import ReactQuill from "react-quill";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { MultiUpload } from "../../../../../../components/MultiUpload/MultiUpload";
import { AsyncCustomSelect } from "../../../../../../components/CustomFields/CustomSelect";
import { reorder } from "../../../../../../Helpers/helpers";
import { DropResult } from "react-beautiful-dnd";
import HistoryDraggableTable from "../HistoryDraggable/HistoryDraggableTable";
import HistoryOfHospitalisation from "./Components/HistoryOfHospitalisation";
import useMediaQuery from '@mui/material/useMediaQuery'
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import SubjectRoundedIcon from "@mui/icons-material/SubjectRounded";
import LoadingButton from "@mui/lab/LoadingButton";
import { CustomSelect } from "../../../../../../components/CustomFields/CustomSelect";

const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#FAFBFD",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));

const addmissionState = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
]

export default function PerinatalHistory() {
  const [showNote, setShowNote] = useState(false);
  const [documentsFiles, setDocumentsFile] = useState<any>([]);
  const [isPerinatalHistory, setIsPerinatalHistory] = useState("");
  const [value, setValue] = React.useState('yes');
  const [selected, setSelected] = React.useState('yes');
  const [openDialog, setOpenDialog] = React.useState(false);

  const [complicationsduringPregnancy, setComplicationsduringPregnancy] = useState(false);
  const [regularAntetatalCareTaken, setRegularAntetatalCareTaken] = useState(false);
  const [multiplePregnancy, setMultiplePregnancy] = useState(false);
  const [historyOfIllnessDuringPregnancy, setHistoryOfIllnessDuringPregnancy] = useState(false);
  const [medicinesTakenDuringPregnancy, setMedicinesTakenDuringPregnancy] = useState(false);
  const [modeOfDelivery, setModeOfDelivery] = useState(false);
  const [maturityOfBaby, setMaturityOfBaby] = useState(false);
  const [birthWeightInGramsOfBaby, setBirthWeightInGramsOfBaby] = useState(false);
  const [perinatalAsphyxia, setPerinatalAsphyxia] = useState(false);
  const [nICUAdmissionOfBaby, setNICUAdmissionOfBaby] = useState(false);

  //Draggable Table
  const [items, setItems] = React.useState([
    {
      id: 1,
      symptom: "Any complications during pregnancy",
    },
    {
      id: 2,
      symptom: "Regular Antetatal Care taken?",
    },
    {
      id: 3,
      symptom: "Multiple pregnancy",
    },
    {
      id: 4,
      symptom: "History of illness during pregnancy",
    },
    {
      id: 5,
      symptom: "Medicines taken during pregnancy",
    },
    {
      id: 6,
      symptom: "Mode of delivery",
    },
    {
      id: 7,
      symptom: "Maturity of baby",
    },
    {
      id: 8,
      symptom: "Birth weight of baby",
    },
    {
      id: 9,
      symptom: "History of perinatal asphyxia requirinng ressuscitation",
    },
    {
      id: 10,
      symptom: "Need for NICU admission",
    },
  ]);
  const [medicineList, setMedicineList] = React.useState([
    {
      id: "Item 1",
      medicine: "Dollo",
    },
    {
      id: "Item 2",
      medicine: "Paracetamol",
    },
    {
      id: "Item 3",
      medicine: "Ibuprofen",
    },
    {
      id: "Item 4",
      medicine: "Aspirin",
    },
    {
      id: "Item 5",
      medicine: "Amoxicillin",
    },
  ]);
  const saveLogoFileSelected = (files: any) => {
    setDocumentsFile(files);
  };
  const dowloadDocumentFile = (files) => { };

  const removeFile = (file) => { };


  // Dialog Box

  const handleClickOpen = (value: boolean) => {
    setOpenDialog(value);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };
  const handleChangeMultiplePregnancy = (event: React.ChangeEvent<HTMLInputElement>) => {
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(medicineList, source.index, destination.index);

    setMedicineList(newItems);
  };
  const smallScreen = useMediaQuery("(max-width:768px)");
  const mobileScreen = useMediaQuery("(max-width:425px)");
  
  const [openPerinatalHistory, setOpenPerinatalHistory] = React.useState(false);

  const handleClickOpenPerinatalHistory = (value: boolean) => {
    if(smallScreen){
      
      setOpenPerinatalHistory(value);
    }else if(mobileScreen){
      setOpenPerinatalHistory(value);
      
    }
  };

  const handleClickClosePerinatalHistory = () => {
    setOpenPerinatalHistory(false);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpenNew = (value: boolean) => {
    setOpen(true);
  };

  const handleCloseNew = () => {
    setOpen(false);
  };
  const onHandleSelectData = (val) => {
    if(smallScreen){
      handleClickOpenNew(true);
      }
      else if(mobileScreen){
        handleClickOpenNew(true);
      }
      setSelected(val);
  };

  const [openPerinatalSummary, setOpenPerinatalSummary] = React.useState(false);

  const handleClickOpenPerinatalSummary = (value: boolean) => {
    if (mobileScreen) {
      setOpenPerinatalSummary(value);
    }
    else if (smallScreen) {
      setOpenPerinatalSummary(value);
    }
  };

  const handleClickClosePerinatalSummary= () => {
    setOpenPerinatalSummary(false);
  };

  return (
    <Box className="main-container">
      <Grid container spacing={0}>
        <Grid item xs={12} mb={2}>
          <Grid container item spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                className="template-table-container"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                }}
              >
                <Box className="inner-title-container">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box className="inner-header-container"
                    sx={{display:"flex", justifyContent:"space-between"}}
                    >
                      <Typography style={{ fontWeight: 600 }}>
                        Perinatal History
                      </Typography>
                      {mobileScreen || smallScreen ? <Box>
                      
                      <Button onClick={() => handleClickOpenPerinatalSummary(true)} sx={{ padding: 0, margin: 0, minWidth:"30px" }} aria-label="delete">
                       <SubjectRoundedIcon></SubjectRoundedIcon>
                      </Button>
            </Box> : ''}
                    </Box>
                  </Paper>
                  <Divider />
                  <List>
                    {items.map((item: any, index: number) => (
                      <ListItem
                        sx={{
                          borderBottom: "1px solid #E9EBEC",
                          cursor: 'pointer'

                        }}
                        className={
                          isPerinatalHistory === item.symptom
                            ? "draggable-list-item"
                            : " "
                        }
                      >
                        <Grid container item spacing={2}>
                          <Grid item xs={12} sm={12} md={12}>
                            <Box
                              style={{
                                display: "flex",
                              }}
                              onClick = {()=>{handleClickOpenPerinatalHistory(true)}}
                            >
                              <Grid container item>
                                <Grid item xs={12} sm={12} md={12}>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() => {
                                      setIsPerinatalHistory(item.symptom)
                                    }}
                                  >
                                    <Box
                                      className={
                                        isPerinatalHistory === item.symptom
                                          ? "symptom-text"
                                          : " "
                                      }
                                      sx={{ paddingBottom: '8px', paddingTop: '8px' }}
                                     
                                      >
                                      <Typography>{item.symptom}</Typography>
                                    </Box>
                                    {isPerinatalHistory === item.symptom && (
                                      <Box
                                        style={{
                                          paddingLeft: '8px',
                                          paddingTop: "8px",
                                        }}
                                      >
                                        <KeyboardDoubleArrowRightRoundedIcon />
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                  </List>
                  {/* <Box
                    className="search-container-draggable"
                  >
                    <FormControl fullWidth>
                      <CustomTextField
                        name="templateName"
                        placeholder="Search..."
                        autoCapitalize="none"
                        type="text"
                        fullWidth
                        size="small"
                      />
                    </FormControl>
                  </Box> */}
                  {/* <Divider /> */}
                  <Box className="note-container">
                    <Box>
                      {!showNote && (
                        <Button
                          variant="text"
                          style={{ fontSize: "6px" }}
                          onClick={() => {
                            setShowNote(true);
                          }}
                        >
                          <AddIcon className="add-icon" />
                          <span className="add-icon"> Note</span>
                        </Button>
                      )}
                    </Box>

                    {showNote && (
                      <FormControl fullWidth>
                        <Box
                          className='note-container-box'
                        >
                          <FormLabel
                            sx={{ paddingLeft: 1 }}
                            className="fileds-label-text"
                          >
                            Note
                          </FormLabel>
                          <Button
                            variant="text"
                            onClick={() => {
                              setShowNote(false);
                            }}
                          >
                            <RemoveIcon className="remove-note" />
                            <span className="remove-note">Remove Note</span>
                          </Button>
                        </Box>
                        <ReactQuill
                          className="react-quill-alignment"
                          theme="snow"
                          value={""}
                          onChange={(e) => { }}
                        />
                      </FormControl>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container template-hide-small-screen template-hide-medium-screen"
                sx={{
                  border: "1px solid #0000001f",
                  minHeight: 400,
                  borderRadius: 1,
                }}
                style={{ position: "sticky", top: "5px" }}
              >
                <Box className="inner-title-container">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="inner-header-container">
                      <Typography
                        className={isPerinatalHistory ? "symptom-text" : ""}
                        style={{ fontWeight: 600 }}
                      >
                        {isPerinatalHistory ? isPerinatalHistory : "Perinatal History Summary"}
                      </Typography>
                      {isPerinatalHistory !== '' && <Typography>
                        <IconButton onClick={() => setIsPerinatalHistory("")} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                          <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                        </IconButton>
                      </Typography>}
                    </Box>
                  </Paper>
                  {isPerinatalHistory ? (
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                        <Divider />
                        <Grid container item spacing={2} style={{ padding: 20 }}>
                          {/* {isPerinatalHistory === 'Any complications during pregnancy' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setComplicationsduringPregnancy(!complicationsduringPregnancy);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Any complications during pregnancy"
                              />
                            </FormGroup>
                          </Grid>} */}
                           {isPerinatalHistory === 'Any complications during pregnancy' &&
                           <Grid item xs={12} sm={12} md={6}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                              Any complications during pregnancy
                              </FormLabel>
                              <CustomSelect
                                isNotCreatable={true}
                                id={"admission"}
                                FieldName={"admission"}
                                options={addmissionState}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e);
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {isPerinatalHistory === 'Any complications during pregnancy'
                            // && complicationsduringPregnancy
                            ?
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                 Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> : ''}

                          {isPerinatalHistory === 'Any complications during pregnancy'
                            // && complicationsduringPregnancy
                            ?
                            <Grid item xs={12} sm={12} md={12} className="OPD-Upload-Button ">
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid> : ''}
                          {/* {isPerinatalHistory === 'Regular Antetatal Care taken?' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setRegularAntetatalCareTaken(!regularAntetatalCareTaken);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Regular Antetatal Care taken"
                              />
                            </FormGroup>
                          </Grid>} */}

                        {isPerinatalHistory === 'Regular Antetatal Care taken?' &&
                           <Grid item xs={12} sm={12} md={6}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                              Regular Antetatal Care taken?
                              </FormLabel>
                              <CustomSelect
                                isNotCreatable={true}
                                id={"admission"}
                                FieldName={"admission"}
                                options={addmissionState}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e);
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}
                          {isPerinatalHistory === 'Regular Antetatal Care taken?'
                            //&& regularAntetatalCareTaken 
                            ?
                            <Grid item xs={8} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                 Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> : ''}
                          {isPerinatalHistory === 'Regular Antetatal Care taken?'
                            // && regularAntetatalCareTaken
                            ?
                            <Grid item xs={12} sm={12} md={12} className="OPD-Upload-Button ">
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid> : ''}
                          {/* {isPerinatalHistory === 'Multiple pregnancy' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setMultiplePregnancy(!multiplePregnancy);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Multiple pregnancy"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {isPerinatalHistory === 'Multiple pregnancy'
                            // && multiplePregnancy
                            ?
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                 Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> : ''}
                          {/* {isPerinatalHistory === 'History of illness during pregnancy' &&
                            <Grid item xs={4} sm={2} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      setHistoryOfIllnessDuringPregnancy(!historyOfIllnessDuringPregnancy);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="History of illness during pregnancy"
                                />
                              </FormGroup>
                            </Grid>} */}
                          {isPerinatalHistory === 'History of illness during pregnancy'
                            // && historyOfIllnessDuringPregnancy
                            ?
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                 Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> : ''}
                          {isPerinatalHistory === 'History of illness during pregnancy'
                            // && historyOfIllnessDuringPregnancy
                            ?
                            <Grid item xs={12} sm={12} md={12} className="OPD-Upload-Button ">
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid> : ''}
                          {isPerinatalHistory === 'Medicines taken during pregnancy' &&
                            <Grid item xs={12} sm={12} md={12}>
                              <Box
                                className="template-table-container"
                                style={{
                                  border: "1px solid #0000001f",
                                  borderRadius: 4,
                                }}
                              >
                                <Box className="inner-title-container">
                                  <HistoryDraggableTable
                                    header="Medicines taken during pregnancy"
                                    items={medicineList}
                                    onDragEnd={onDragEnd}
                                    onHandleSelectData={onHandleSelectData}
                                    selected={selected}
                                  />
                                </Box>
                              </Box>
                            </Grid>}
                          {isPerinatalHistory === 'Medicines taken during pregnancy'
                            //  && medicinesTakenDuringPregnancy
                            ?
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> : ''}
                          {isPerinatalHistory === 'Medicines taken during pregnancy'
                            // && medicinesTakenDuringPregnancy
                            ?
                            <Grid item xs={4} sm={12} md={12} className="OPD-Upload-Button ">
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid> : ''}

                          {isPerinatalHistory === 'Mode of delivery'
                            //  && modeOfDelivery
                            ?
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Mode of delivery
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid> : ''}

                          {isPerinatalHistory === 'Mode of delivery' &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>}
                          {isPerinatalHistory === 'Maturity of baby'
                            // && maturityOfBaby
                            ?
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Maturity of baby
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid> : ''}
                          {isPerinatalHistory === 'Maturity of baby' &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>}
                          {isPerinatalHistory === 'Birth weight of baby' &&
                            <Grid item xs={12} sm={12} md={3}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Birth weight of baby
                                </FormLabel>
                                <OutlinedInput
                                  id="outlined-adornment-weight"
                                  size="small"
                                endAdornment={<InputAdornment position="end">(grams)</InputAdornment>}
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    'aria-label': 'weight',
                                  }}
                                />
                              </FormControl>
                            </Grid>}
                          {isPerinatalHistory === 'Birth weight of baby'
                            // && birthWeightInGramsOfBaby 
                            ?
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> : ''}
                          {/* {isPerinatalHistory === 'History of perinatal asphyxia requirinng ressuscitation' &&
                            <Grid item xs={4} sm={2} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      setPerinatalAsphyxia(!perinatalAsphyxia);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="History of perinatal asphyxia requirinng ressuscitation"
                                />
                              </FormGroup>
                            </Grid>} */}
                          {isPerinatalHistory === 'History of perinatal asphyxia requirinng ressuscitation'
                            //  && perinatalAsphyxia
                            ?
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> : ''}
                          {isPerinatalHistory === 'History of perinatal asphyxia requirinng ressuscitation'
                            // && perinatalAsphyxia
                            ?
                            <Grid item xs={12} sm={12} md={12} className="OPD-Upload-Button ">
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid> : ''}
                          {isPerinatalHistory === 'Need for NICU admission' &&
                            <Grid item xs={12} sm={12} md={12}>
                              <Button variant="contained" onClick={() => handleClickOpen(true)} >Add History of hospitalisation</Button>
                            </Grid>}
                          {isPerinatalHistory === 'Need for NICU admission'
                            // && nICUAdmissionOfBaby
                            ?
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> : ''}
                          {isPerinatalHistory === 'Need for NICU admission'
                            // && nICUAdmissionOfBaby
                            ?
                            <Grid item xs={12} sm={12} md={12} className="OPD-Upload-Button ">
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid> : ''}
                        </Grid>
                      </Box>
                    </Formik>
                  ) : (
                    <Box>  <Divider />
                      <Box className="summary-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
      className="dialog"
        open={openDialog}
        onClose={handleClose}
        fullScreen
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "1200px", // Adjust the width as needed
            maxWidth: "none",
          },
        }}
      >
         <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography 
             style={{ fontWeight: 600 }}
             variant="h6" 
            >
                History of hospitalisation
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ height: 400 }}>
          <HistoryOfHospitalisation />
        </DialogContent>
        <DialogActions>
        <LoadingButton
            color="primary"
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="contained"
            type="submit"
            className="primary-button"
            name="submit"
            onClick={()=>{
              handleClose()
            }}
          >
            Save
          </LoadingButton>
          <LoadingButton
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="outlined"
            className="secondary-button"
            color="secondary"
            onClick={()=>{
              handleClose()
            }}
          >
            Cancel
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
          
            <Typography 
             style={{ fontWeight: 600 }}
             
             variant="h6" 
             component="div">
                {selected ? selected : 'Taking any Medicine(s)'}
            </Typography>
            <IconButton onClick={() => handleCloseNew(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                          <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                        </IconButton>
           
          </Toolbar>
        </AppBar>
       <DialogContent style={{ height: 400 }}>
       <Box
                className="template-table-container"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                  minHeight: 200,
                }}
              >
                <Box className="inner-title-container">
                
                  <Box>
                    
                    <Box>This is summary section new one</Box>
                  </Box>
                </Box>
              </Box>
       </DialogContent>
      </Dialog>
      
      <Dialog
      fullScreen
                open={openPerinatalHistory}
                onClose={handleClickClosePerinatalHistory}
            >
               <AppBar sx={{ position: 'relative' }}>
          <Toolbar
                sx={{ display:"flex",
                justifyContent:"space-between"}}>
            <Typography
             
              style={{ fontWeight: 600, margin: 1 }}
              sx={{
                paddingLeft:{
                  xs:1,
                  sm:0,
                  }
               }}
              variant="h6"
            >
          {isPerinatalHistory ? isPerinatalHistory : "Perinatal History"}
            </Typography>
            <IconButton onClick={() => handleClickClosePerinatalHistory(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
              <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
            </IconButton>
          </Toolbar>
          <Divider />
        </AppBar>
                <DialogContent style={{ height: 400 }}>
                <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container"
                sx={{
                  border: "0px solid #0000001f",
                  minHeight: 400,
                  borderRadius: 1,
                }}
              >
                <Box className="inner-title-container">
               
                  {isPerinatalHistory ? (
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                    
                        <Grid container item spacing={2} >
                          {/* {isPerinatalHistory === 'Any complications during pregnancy' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setComplicationsduringPregnancy(!complicationsduringPregnancy);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Any complications during pregnancy"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {isPerinatalHistory === 'Any complications during pregnancy'
                            // && complicationsduringPregnancy
                            ?
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                 Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> : ''}

                          {isPerinatalHistory === 'Any complications during pregnancy'
                            // && complicationsduringPregnancy
                            ?
                            <Grid item xs={12} sm={12} md={12} >
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid> : ''}
                          {/* {isPerinatalHistory === 'Regular Antetatal Care taken?' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setRegularAntetatalCareTaken(!regularAntetatalCareTaken);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Regular Antetatal Care taken"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {isPerinatalHistory === 'Regular Antetatal Care taken?'
                            //&& regularAntetatalCareTaken 
                            ?
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                 Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> : ''}
                          {isPerinatalHistory === 'Regular Antetatal Care taken?'
                            // && regularAntetatalCareTaken
                            ?
                            <Grid item xs={12} sm={12} md={12} >
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid> : ''}
                          {/* {isPerinatalHistory === 'Multiple pregnancy' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setMultiplePregnancy(!multiplePregnancy);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Multiple pregnancy"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {isPerinatalHistory === 'Multiple pregnancy'
                            // && multiplePregnancy
                            ?
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                 Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> : ''}
                          {/* {isPerinatalHistory === 'History of illness during pregnancy' &&
                            <Grid item xs={4} sm={2} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      setHistoryOfIllnessDuringPregnancy(!historyOfIllnessDuringPregnancy);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="History of illness during pregnancy"
                                />
                              </FormGroup>
                            </Grid>} */}
                          {isPerinatalHistory === 'History of illness during pregnancy'
                            // && historyOfIllnessDuringPregnancy
                            ?
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                 Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> : ''}
                          {isPerinatalHistory === 'History of illness during pregnancy'
                            // && historyOfIllnessDuringPregnancy
                            ?
                            <Grid item xs={12} sm={12} md={12} >
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid> : ''}
                          {isPerinatalHistory === 'Medicines taken during pregnancy' &&
                            <Grid item xs={12} sm={12} md={12}>
                              <Box
                                className="template-table-container"
                                style={{
                                  border: "1px solid #0000001f",
                                  borderRadius: 4,
                                }}
                              >
                                <Box className="inner-title-container">
                                  <HistoryDraggableTable
                                    header="Medicines taken during pregnancy"
                                    items={medicineList}
                                    onDragEnd={onDragEnd}
                                    onHandleSelectData={onHandleSelectData}
                                    selected={selected}
                                  />
                                </Box>
                              </Box>
                            </Grid>}
                          {isPerinatalHistory === 'Medicines taken during pregnancy'
                            //  && medicinesTakenDuringPregnancy
                            ?
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> : ''}
                          {isPerinatalHistory === 'Medicines taken during pregnancy'
                            // && medicinesTakenDuringPregnancy
                            ?
                            <Grid item xs={6} sm={12} md={12} >
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid> : ''}

                          {isPerinatalHistory === 'Mode of delivery'
                            //  && modeOfDelivery
                            ?
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Mode of delivery
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid> : ''}

                          {isPerinatalHistory === 'Mode of delivery' &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>}
                          {isPerinatalHistory === 'Maturity of baby'
                            // && maturityOfBaby
                            ?
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Maturity of baby
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid> : ''}
                          {isPerinatalHistory === 'Maturity of baby' &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>}
                          {isPerinatalHistory === 'Birth weight of baby' &&
                            <Grid item xs={12} sm={12} md={3}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Birth weight of baby
                                </FormLabel>
                                <OutlinedInput
                                  id="outlined-adornment-weight"
                                  size="small"
                                  endAdornment={<InputAdornment position="end">(grams)</InputAdornment>}
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    'aria-label': 'weight',
                                  }}
                                />
                              </FormControl>
                            </Grid>}
                          {isPerinatalHistory === 'Birth weight of baby'
                            // && birthWeightInGramsOfBaby 
                            ?
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> : ''}
                          {/* {isPerinatalHistory === 'History of perinatal asphyxia requirinng ressuscitation' &&
                            <Grid item xs={4} sm={2} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      setPerinatalAsphyxia(!perinatalAsphyxia);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="History of perinatal asphyxia requirinng ressuscitation"
                                />
                              </FormGroup>
                            </Grid>} */}
                          {isPerinatalHistory === 'History of perinatal asphyxia requirinng ressuscitation'
                            //  && perinatalAsphyxia
                            ?
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> : ''}
                          {isPerinatalHistory === 'History of perinatal asphyxia requirinng ressuscitation'
                            // && perinatalAsphyxia
                            ?
                            <Grid item xs={12} sm={12} md={12}>
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid> : ''}
                          {isPerinatalHistory === 'Need for NICU admission' &&
                            <Grid item xs={12} sm={12} md={12}>
                              <Button variant="contained" onClick={() => handleClickOpen(true)} >Add History of hospitalisation</Button>
                            </Grid>}
                          {isPerinatalHistory === 'Need for NICU admission'
                            // && nICUAdmissionOfBaby
                            ?
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> : ''}
                          {isPerinatalHistory === 'Need for NICU admission'
                            // && nICUAdmissionOfBaby
                            ?
                            <Grid item xs={12} sm={12} md={12} >
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid> : ''}
                        </Grid>
                      </Box>
                    </Formik>
                  ) : (
                    <Box>  <Divider />
                      <Box>This is summary section </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
           
                </DialogContent>
            </Dialog>

            <Dialog
            fullScreen
            open={openPerinatalSummary}
            onClose={handleClickClosePerinatalSummary}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
                <Typography
                  // className={selected ? "symptom-text" : ""}
                  style={{ fontWeight: 600, marginLeft: 0 }}
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                >
                 Perinatal History Summary
                </Typography>
                <IconButton onClick={() => handleClickClosePerinatalSummary(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                  <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent style={{ height: 400 }}>
            <Box className="template-table-container summary-dialog-mainContainer">
                      <Box className="summary-dialog-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
            </Box>
            </DialogContent>
          </Dialog>
    </Box >
  );
}
