import { useNavigate } from "react-router-dom";
import "../../../theme/style.scss";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Paper,
  Dialog,
  DialogContent,
  TextField,
  Typography,
  DialogTitle,
  DialogProps,
  styled,
  AppBar,
  Toolbar,
} from "@mui/material";
import Title from "../../../components/Title";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { reorder } from "../../../Helpers/helpers";
import DraggableList from "../../../components/DraggableTable/DraggableList";
import CustomBreadcrumbs from "../../../components/BreadcrumbsComponent/CustomBreadcrumbs";
import { AsyncCustomSelect } from "../../../components/CustomFields/CustomSelect";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ReactQuill from "react-quill";
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import InvestigationInstructionMasterSave from '../../Investigation/InvestigationMaster/_component/InvestigationInstructionMasterSave';
import InvestigationInstructionSave from '../../Investigation/InvestigationMaster/_component/InvestigationInstructionSave';
import { Item } from '../../../Helpers/typings';


const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#FAFBFD",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function InvestigationTemplateSave() {
  const [showNote, setShowNote] = useState(false);
  const navigate = useNavigate();

  //Draggable Table
  const [items, setItems] = React.useState([
    {
      id: "Item 1",
      primary: "Practical Rubber Computer1",
      secondary: "Synergistic leading edge matrices",
    },
    {
      id: "Item 2",
      primary: "Practical Rubber Computer2",
      secondary: "Synergistic leading edge matrices",
    },
    {
      id: "Item 3",
      primary: "Practical Rubber Computer3",
      secondary: "Synergistic leading edge matrices",
    },
  ]);

  const [itemsOther, setItemsOther1] = React.useState([
    {
      id: "Item2 1",
      primary: "2Practical Rubber Computer1",
      secondary: "1Synergistic leading edge matrices",
    },
    {
      id: "Item1 2",
      primary: "2Practical Rubber Computer2",
      secondary: "1Synergistic leading edge matrices",
    },
    {
      id: "Item1 3",
      primary: "2Practical Rubber Computer3",
      secondary: "2Synergistic leading edge matrices",
    },
  ]);

  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');
  const rowData = [
    {
      followUpReason: 'Regular follow up',

    },
    {
      followUpReason: 'Review reports',
    }
  ]

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(items, source.index, destination.index);

    setItems(newItems);
  };

  const onDragEnd2 = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(itemsOther1, source.index, destination.index);

    setItemsOther1(newItems);
  };
  const [openDialog, setOpenDialog] = React.useState(false);
  const onHandleOpenDialog = (item: Item) => {

    handleClickOpen(true);
    console.log("Opening dialog for item:", item);
  };
  const handleClickOpen = (value: boolean) => {
    setOpenDialog(value);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const [openDialog1, setOpenDialog1] = React.useState(false);
  const onHandleOpenDialog1 = (item: Item) => {

    handleClickOpen1(true);
    console.log("Opening dialog for item:", item);
  };
  const handleClickOpen1 = (value: boolean) => {
    setOpenDialog1(value);
  };

  const handleClose1 = () => {
    setOpenDialog1(false);
  };



  return (
    <Box className="main-container " pl={2}>
      <Grid item xs={12} container className="page-header-save ">
        <Grid xs={12} sm={12} md={12} item className="page-breadcrumbs">
          <CustomBreadcrumbs />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} mb={3} className="group-container ">
          <Grid container item spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <FormLabel className="fileds-label-text">
                  Template Name
                </FormLabel>
                <CustomTextField
                  name="templateName"
                  placeholder=""
                  autoCapitalize="none"
                  type="text"
                  fullWidth
                  size="small"
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={6} />

            <Grid item xs={12} sm={12} md={6}>
              <Box
                className="template-table-container"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  border: "1px solid #0000001f",
                  borderRadius: 4,
                }}
              >
                <Paper
                  style={{
                    flex: 1,
                  }}
                >
                  <Box className="inner-header-container">
                    <Typography style={{ fontWeight: 600 }}>
                      Investigation
                    </Typography>
                  </Box>
                  <Divider />
                  <DraggableList items={items} onDragEnd={onDragEnd} onHandleOpenDialog={onHandleOpenDialog} />
                  {/* <Divider/> */}
                  <Grid item xs={12} sm={6} md={12}>
                    <Divider />
                    <Box className="note-container">
                      <Box>
                        {!showNote && (
                          <Button
                            variant="text"
                            style={{ fontSize: "6px" }}
                            onClick={() => {
                              setShowNote(true);
                            }}
                          >
                            <AddIcon className="add-icon" />
                            <span className="add-icon"> Note</span>
                          </Button>
                        )}
                      </Box>

                      {showNote && (
                        <FormControl fullWidth>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              alignSelf: "end",
                              minWidth: "100%",
                            }}
                          >
                            <FormLabel
                              sx={{ paddingLeft: 1 }}
                              className="fileds-label-text"
                            >
                              Note
                            </FormLabel>

                            <Button
                              variant="text"
                              onClick={() => {
                                setShowNote(false);
                              }}
                            >
                              <RemoveIcon className="remove-note" />
                              <span className="remove-note">
                                Remove Note
                              </span>
                            </Button>
                          </Box>
                          <ReactQuill className="react-quill-alignment" theme="snow" value={""} onChange={(e) => { }} />
                          {/* <CustomTextField
                                    name="templateName"
                                    placeholder=""
                                    autoCapitalize="none"
                                    type="text"
                                    multiline
                                    rows={4}
                                    fullWidth
                                    size="small"
                                  /> */}
                        </FormControl>
                      )}
                    </Box>
                  </Grid>
                </Paper>
              </Box>
            </Grid>

            {/* <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  border: "1px solid #0000001f",
                  borderRadius: 4,
                }}
              >
                <Paper
                  style={{
                    flex: 1,
                  }}
                >
                  <Box className="inner-header-container">
                    <Typography style={{ fontWeight: 600 }}>
                      Investigation Instructions
                    </Typography>
                  </Box>
                  <Divider />
                  <DraggableList items={itemsOther1} onDragEnd={onDragEnd2} />
                </Paper>
              </Box>
            </Grid> */}
            <Grid container item spacing={2}>

            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
            <LoadingButton
              color="primary"
              tabIndex={3}
              loadingPosition="start"
              startIcon={<></>}
              variant="contained"
              type="submit"
              onClick={() => navigate("/master/investigation-template")}
              className="primary-button"
              name="submit"
            >
              Save
            </LoadingButton>
            <LoadingButton
              tabIndex={3}
              loadingPosition="start"
              startIcon={<></>}
              variant="outlined"
              type="submit"
              name="submit"
              className="secondary-button"
              color="secondary"
              onClick={() => navigate("/master/investigation-template")}
            >
              Cancel
            </LoadingButton>
          </Box>
        </Grid>
       
        <Dialog
          className="dialog1"
          open={openDialog}
          onClose={handleClose}
          fullScreen
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              width: "1200px", // Adjust the width as needed
              maxWidth: "none",
            },
          }}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <Typography
                // className={selected ? "symptom-text" : ""}
                style={{ fontWeight: 600, marginLeft: 0 }}
                sx={{ ml: 2, flex: 1 }}
                variant="h6"
              >
                Investigation Edit
              </Typography>
              

            </Toolbar>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </AppBar>
          <DialogContent className="padding-remove-Investigation-Edit main-dialog-background-color">
            <InvestigationInstructionMasterSave
              onHandleOpenDialog={onHandleOpenDialog}
              handleClose={handleClose}
            />
          </DialogContent>
              <Divider />
          
          <DialogActions className="padding-add-investigation-templete-save-button">
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
                <LoadingButton
                  color="primary"
                  tabIndex={3}
                  loadingPosition="start"
                  startIcon={<></>}
                  variant="contained"
                  type="submit"
                  onClick={handleClose}
                  className="primary-button"
                  name="submit"
                >
                  Save
                </LoadingButton>
                <LoadingButton
                  tabIndex={3}
                  loadingPosition="start"
                  startIcon={<></>}
                  variant="outlined"
                  type="submit"
                  name="submit"
                  className="secondary-button"
                  color="secondary"
                  onClick={handleClose}                >
                  Cancel
                </LoadingButton>
              </Box>
            </Grid>
          </DialogActions>
        </Dialog>
      </Grid>
    </Box>
  );
}
