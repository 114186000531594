import React from "react";
import { useNavigate } from "react-router-dom";
import "../../../../../../../theme/style.scss";
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  styled,
  Typography,
  Divider,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  DialogActions
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Formik } from "formik";
import 'react-phone-number-input/style.css';
import DatePickerCompponent from "../../../../../../../components/CustomFields/DatePicker";
import { DropZone } from "../../../../../../../components/CustomFields/DropZone";
import { CustomSelect } from "../../../../../../../components/CustomFields/CustomSelect";
import DraggableList from "../../../../../../OPD/Components/DraggableTable/DraggableList";
import { DropResult } from 'react-beautiful-dnd';
import { reorder } from "../../../../../../../Helpers/helpers";
import MedicinePrescription from "../../../Diagnosis/MedicineComponent/MedicinePrescription";
import DiagnosisInstructionMasterSave from "../../../Diagnosis/DiagnosisComponent/DiagnosisInstructionMasterSave";


const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#FAFBFD", // Set the desired background color
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));

const  durationState = [
  { value: 'days', label: 'Days' },
  { value: 'weeks', label: 'Weeks' },
  { value: 'months', label: 'Months' },
  { value: 'year', label: 'Year' },
]

export default function HistoryOfHospitalisation() {


  const [items, setItems] = React.useState([
    {
      id: "Item 1",
      primary: "Practical Rubber Computer1",
      secondary: "Synergistic leading edge matrices"
    }, {
      id: "Item 2",
      primary: "Practical Rubber Computer2",
      secondary: "Synergistic leading edge matrices"
    }, {
      id: "Item 3",
      primary: "Practical Rubber Computer3",
      secondary: "Synergistic leading edge matrices"
    }
  ]);
  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(items, source.index, destination.index);

    setItems(newItems);
  };

  const [openDialogMedicine, setOpenDialogMedicine] = React.useState(false);
  const onHandleOpenDialogMedicine = (item: Item) => {

    handleClickOpenDialogMedicine(true);
    console.log("Opening dialog for item:", item);
  };
  const handleClickOpenDialogMedicine = (value: boolean) => {
    setOpenDialogMedicine(value);
  };

  const handleCloseDialogMedicine = () => {
    setOpenDialogMedicine(false);
  };

  
  const [openDialogDiagnosis, setOpenDialogDiagnosis] = React.useState(false);
  const onHandleOpenDialogDiagnosis = (item: Item) => {

    handleClickOpenDialogDiagnosis(true);
    console.log("Opening dialog for item:", item);
  };
  const handleClickOpenDialogDiagnosis = (value: boolean) => {
    setOpenDialogDiagnosis(value);
  };

  const handleCloseDialogDiagnosis = () => {
    setOpenDialogDiagnosis(false);
  };

  const navigate = useNavigate();
  return (
    <Box className="main-container" pl={2}>
     
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
        }}
        onSubmit={async (values) => {
          console.log("values", values)
        }}
      >
        <Grid container spacing={2} sx={{marginRight:{
        xs:-2,
        sm:0
       }}}>
          <Grid item xs={12} mb={3} className="group-container">
            <Grid container item spacing={2}>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Name of Hospital</FormLabel>
                  <CustomTextField
                    name=" "
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} >
                <div >
                  <Box style={{ fontSize: 12 }}>Date of admission</Box>
                  <FormControl sx={{ width: '100% !important' }}>
                    <DatePickerCompponent
                      format="DD-MM-YYYY"
                      mode="date"
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} >
                <div >
                  <Box style={{ fontSize: 12 }}>Date of Discharge</Box>
                  <FormControl sx={{ width: '100% !important' }}>
                    <DatePickerCompponent
                      format="DD-MM-YYYY"
                      mode="date"
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </FormControl>
                </div>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={3}/> */}
              <Grid item xs={4} sm={4}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Duration of hospital stay</FormLabel>
                 
                  <CustomTextField
                    name=" "
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                  </FormControl>
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Unit of duration</FormLabel>
                  
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"duration"}
                                FieldName={"duration"}
                                options={durationState}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e);
                                }}
                                Value=""
                              />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
              <Box
                className="template-table-container"
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  border: '1px solid #0000001f',
                  borderRadius: 4
                }}
              >
                <Box
                  style={{
                    flex: 1,
                    borderRadius: 8
                  }}>
                  <Box
                    className='template-box'
                  >
                    <Typography>
                        Diagnosis
                    </Typography>
                  </Box>
                  <Divider />
                  <DraggableList
                    items={items}
                    onDragEnd={onDragEnd}
                    onHandleOpenDialog={onHandleOpenDialogDiagnosis}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box
                className="template-table-container"
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  border: '1px solid #0000001f',
                  borderRadius: 4
                }}
              >
                <Box
                  style={{
                    flex: 1,
                    borderRadius: 8
                  }}>
                  <Box
                    className='template-box'
                  >
                    <Typography>
                     Details of medicine
                    </Typography>
                  </Box>
                  <Divider />
                  <DraggableList
                    items={items}
                    onDragEnd={onDragEnd}
                    onHandleOpenDialog={onHandleOpenDialogMedicine}
                  />
                </Box>
              </Box>
            </Grid>
  
            </Grid>
          </Grid>
        
        </Grid>

      </Formik>
      <Dialog
        className="dialog"
        open={openDialogMedicine}
        onClose={handleCloseDialogMedicine}
        fullScreen
      >
          <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography
              // className={selected ? "symptom-text" : ""}
              style={{ fontWeight: 600, marginLeft: 1 }}
              sx={{
                paddingLeft: {
                  xs: 1,
                  sm: 0,
                }
              }}
             
              variant="h6"
            >
           Medicine Edit
            </Typography>
          
          </Toolbar>
        </AppBar>
        <Divider />
        <DialogContent>
        <MedicinePrescription/>
        </DialogContent>
        <DialogActions>
        <LoadingButton
            color="primary"
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="contained"
            type="submit"
            className="primary-button"
            name="submit"
            onClick={()=>{
              handleCloseDialogMedicine()
            }}
          >
            Save
          </LoadingButton>
          <LoadingButton
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="outlined"
            className="secondary-button"
            color="secondary"
            onClick={()=>{
              handleCloseDialogMedicine()
            }}
          >
            Cancel
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        className="dialog"
        open={openDialogDiagnosis}
        onClose={handleCloseDialogDiagnosis}
        fullScreen
      >
          <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography
              // className={selected ? "symptom-text" : ""}
              style={{ fontWeight: 600, marginLeft: 1 }}
              sx={{
                paddingLeft: {
                  xs: 1,
                  sm: 0,
                }
              }}
             
              variant="h6"
            >
              Diagnosis Edit
            </Typography>
          
          </Toolbar>
        </AppBar>
        <Divider />
        <DialogContent>
        <DiagnosisInstructionMasterSave/>
        </DialogContent>
        <DialogActions>
        <LoadingButton
            color="primary"
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="contained"
            type="submit"
            className="primary-button"
            name="submit"
            onClick={()=>{
              handleCloseDialogDiagnosis()
            }}
          >
            Save
          </LoadingButton>
          <LoadingButton
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="outlined"
            className="secondary-button"
            color="secondary"
            onClick={()=>{
              handleCloseDialogDiagnosis()
            }}
          >
            Cancel
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
