import React from 'react';
import { Box, Breadcrumbs, Link, Typography } from '@mui/material';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import Title from '../Title';
import LinkTitle from '../LinkTitle';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const BreadcrumbsComponent = () => {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);
    console.log("pathnames:", pathnames)
    return (
        <Breadcrumbs aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}>
            {pathnames.map((value, index) => {
                const last = index === pathnames.length - 1;
                const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                return last ? (
                    <Box key={to}>
                        <Title pageTitle={value} />
                    </Box>
                ) : (
                    <Link component={RouterLink} to={to} key={to}>
                        <LinkTitle pageLinkTitle={value} />
                    </Link>
                );
            })}
        </Breadcrumbs>
    );
};

export default BreadcrumbsComponent;
