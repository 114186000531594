import "../../../../../../theme/style.scss";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  TextField,
  Typography,
  styled,
  Select,
  MenuItem
} from "@mui/material";
import React, { useState } from "react";
import { Formik } from "formik";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import { MultiUpload } from "../../../../../../components/MultiUpload/MultiUpload";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import HistoryOfSimilarIllness from "./Components/HistoryOfSimilarIllness";
import HistoryOfHospitalisation from "./Components/HistoryOfHospitalisation";
import AnySurgeryDoneInPast from "./Components/AnySurgeryDoneInPast";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import useMediaQuery from "@mui/material/useMediaQuery";
import SubjectRoundedIcon from '@mui/icons-material/SubjectRounded';
import { reorder } from "../../../../../../Helpers/helpers";
import { DropResult } from 'react-beautiful-dnd';
import DialogActions from '@mui/material/DialogActions';
import { CustomSelect } from "../../../../../../components/CustomFields/CustomSelect";
import DraggableList from "../../../../../OPD/Components/DraggableTable/DraggableList";
import DatePickerCompponent from "../../../../../../components/CustomFields/DatePicker";
import MedicinePrescription from "../../Diagnosis/MedicineComponent/MedicinePrescription";
import LoadingButton from "@mui/lab/LoadingButton";
import DiagnosisInstructionMasterSave from "../../Diagnosis/DiagnosisComponent/DiagnosisInstructionMasterSave";


const addmissionState = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
]

const  durationState = [

  { value: 'days', label: 'Days' },
  { value: 'weeks', label: 'Weeks' },
  { value: 'months', label: 'Months' },
  { value: 'year', label: 'Year' },
]

const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#FAFBFD",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function PastHistory() {
  const [showNote, setShowNote] = useState(false);
  const [documentsFiles, setDocumentsFile] = useState<any>([]);
  const [isPastHistory, setIsPastHistory] = useState("");
  const [similarIllness, setSimilarIllness] = useState(false);
  const [hospitalisation, setHospitalisation] = useState(false);
  const [surgeryDoneInPast, setSurgeryDoneInPast] = useState(false);
  const [selected, setSelected] = useState("");
  const smallScreen = useMediaQuery("(max-width:768px)");
  const mobileScreen = useMediaQuery("(max-width:425px)");


  const [itemsDraggable, setItemsDraggable] = React.useState([
    {
      id: "Item 1",
      primary: "Practical Rubber Computer1",
      secondary: "Synergistic leading edge matrices"
    }, {
      id: "Item 2",
      primary: "Practical Rubber Computer2",
      secondary: "Synergistic leading edge matrices"
    }, {
      id: "Item 3",
      primary: "Practical Rubber Computer3",
      secondary: "Synergistic leading edge matrices"
    }
  ]);
  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(itemsDraggable, source.index, destination.index);

    setItemsDraggable(newItems);
  };
  //Draggable Table
  const [items, setItems] = React.useState([
    {
      id: 1,
      symptom: "History of similar illness",
    },
    {
      id: 2,
      symptom: "History of hospitalisation",
    },
    {
      id: 3,
      symptom: "Any surgery done in past",
    },
  ]);

  // Dialog Box
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpen = (value: boolean) => {
    setOpenDialog(value);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  ///////
  const [openDialogHistoryOfHospitalisation, setOpenDialogHistoryOfHospitalisation] = React.useState(false);

  const handleClickOpenHistoryOfHospitalisation = (value: boolean) => {
    setOpenDialogHistoryOfHospitalisation(value);
  };

  const handleCloseHistoryOfHospitalisation = () => {
    setOpenDialogHistoryOfHospitalisation(false);
  };
  /////

  const [openDialogAnySurgeryDoneInPast, setOpenDialogAnySurgeryDoneInPast] = React.useState(false);

  const handleClickOpenAnySurgeryDoneInPast = (value: boolean) => {
    setOpenDialogAnySurgeryDoneInPast(value);
  };

  const handleCloseAnySurgeryDoneInPast = () => {
    setOpenDialogAnySurgeryDoneInPast(false);
  };



  const saveLogoFileSelected = (files: any) => {
    setDocumentsFile(files);
  };
  const dowloadDocumentFile = (files) => { };

 

  const removeFile = (file) => { };

  const [open, setOpen] = React.useState(false);

  const handleClickOpenForHistoryOfSimilarIllness = (value: boolean) => {
    if(smallScreen){
      
      setOpen(value);
    }else if(mobileScreen){
      setOpen(value);
      
    }
  };

  const handleCloseForHistoryOfSimilarIllness = () => {
      setOpen(false);
  };

  // Past history summary section
  const [openPastSummary, setopenPastSummary] = React.useState(false);

  const handleClickOpenPastSummary = (value: boolean) => {
    if (mobileScreen) {
      setopenPastSummary(value);
    }
    else if (smallScreen) {
      setopenPastSummary(value);
    }
  };

  const handleClickClosePastSummary = () => {
    setopenPastSummary(false);
  };

  const [openDialogMedicine, setOpenDialogMedicine] = React.useState(false);
  const onHandleOpenDialogMedicine = (item: Item) => {

    handleClickOpenDialogMedicine(true);
    console.log("Opening dialog for item:", item);
  };
  const handleClickOpenDialogMedicine = (value: boolean) => {
    setOpenDialogMedicine(value);
  };

  const handleCloseDialogMedicine = () => {
    setOpenDialogMedicine(false);
  };

  
  const [openDialogDiagnosis, setOpenDialogDiagnosis] = React.useState(false);
  const onHandleOpenDialogDiagnosis = (item: Item) => {

    handleClickOpenDialogDiagnosis(true);
    console.log("Opening dialog for item:", item);
  };
  const handleClickOpenDialogDiagnosis = (value: boolean) => {
    setOpenDialogDiagnosis(value);
  };

  const handleCloseDialogDiagnosis = () => {
    setOpenDialogDiagnosis(false);
  };

  return (
    <Box className="main-container">
      <Grid container spacing={0}>
        <Grid item xs={12} mb={2}>
          <Grid container item spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                className="template-table-container"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                }}
              >
                <Box className="inner-title-container">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box className="inner-header-container"
                    sx={{display:"flex", justifyContent:"space-between"}}>
                      <Typography style={{ fontWeight: 600 }}>
                        Past History
                      </Typography>
                      {mobileScreen || smallScreen ? <Box>
                        <Button onClick={() => handleClickOpenPastSummary(true)} sx={{ padding: 0, margin: 0, minWidth:"30px" }} aria-label="delete">
                         <SubjectRoundedIcon></SubjectRoundedIcon>
                        </Button>
              </Box> : ''}
                    </Box>
                  </Paper>
                  <Divider />
                  <List>
                    {items.map((item: any, index: number) => (
                      <ListItem
                        sx={{
                          borderBottom: "1px solid #E9EBEC",
                          cursor: 'pointer'
                        }}
                        className={
                          isPastHistory === item.symptom
                            ? "draggable-list-item"
                            : " "
                        }
                      >
                        <Grid container item spacing={2}>
                          <Grid item xs={12} sm={12} md={12}>
                            <Box
                              style={{
                                display: "flex",
                              }}
                              onClick={()=>{handleClickOpenForHistoryOfSimilarIllness(true)}}
                            >
                              <Grid container item>
                                <Grid item xs={12} sm={12} md={12}>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() => {
                                      setIsPastHistory(item.symptom)
                                    }}
                                  >
                                    <Box
                                      className={
                                        isPastHistory === item.symptom
                                          ? "symptom-text"
                                          : " "
                                      }
                                      sx={{ paddingBottom: '8px', paddingTop: '8px' }} 
                                     
                                    >
                                      <Typography>{item.symptom}</Typography>
                                    </Box>
                                    {/* <Box>
                                      <FormGroup>
                                        <FormControlLabel
                                          className={
                                            isPastHistory === item.symptom
                                              ? "symptom-text"
                                              : " "
                                          }
                                          control={
                                            <Checkbox
                                              onChange={(e) => {
                                                setIsPastHistory(
                                                  e.target.value
                                                );
                                                console.log(
                                                  "checkbox data:",
                                                  e.target.value
                                                );
                                              }}
                                              value={item.symptom}
                                            />
                                          }
                                          label={item.symptom}
                                        />
                                      </FormGroup>
                                    </Box> */}
                                    {isPastHistory === item.symptom && (
                                      <Box
                                        style={{
                                          paddingTop: "8px",
                                          paddingLeft: '8px'
                                        }}
                                      >
                                        <KeyboardDoubleArrowRightRoundedIcon />
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                  </List>
                  {/* <Box
                    className="search-container-draggable"
                  >
                    <FormControl fullWidth>
                      <CustomTextField
                        name="templateName"
                        placeholder="Search..."
                        autoCapitalize="none"
                        type="text"
                        fullWidth
                        size="small"
                      />
                    </FormControl>
                  </Box> */}
                  {/* <Divider /> */}
                  <Box className="note-container">
                    <Box>
                      {!showNote && (
                        <Button
                          variant="text"
                          style={{ fontSize: "6px" }}
                          onClick={() => {
                            setShowNote(true);
                          }}
                        >
                          <AddIcon className="add-icon" />
                          <span className="add-icon"> Note</span>
                        </Button>
                      )}
                    </Box>

                    {showNote && (
                      <FormControl fullWidth>
                        <Box
                          className='note-container-box'
                        >
                          <FormLabel
                            sx={{ paddingLeft: 1 }}
                            className="fileds-label-text"
                          >
                            Note
                          </FormLabel>
                          <Button
                            variant="text"
                            onClick={() => {
                              setShowNote(false);
                            }}
                          >
                            <RemoveIcon className="remove-note" />
                            <span className="remove-note">Remove Note</span>
                          </Button>
                        </Box>
                        <ReactQuill
                          className="react-quill-alignment"
                          theme="snow"
                          value={""}
                          onChange={(e) => { }}
                        />
                      </FormControl>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container template-hide-small-screen template-hide-medium-screen"
                sx={{
                  border: "1px solid #0000001f",
                  minHeight: 400,
                  borderRadius: 1,
                }}
              >
                <Box className="inner-title-container">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="inner-header-container">
                      <Typography
                        className={isPastHistory ? "symptom-text" : ""}
                        style={{ fontWeight: 600 }}
                        
                      >
                        {isPastHistory ? isPastHistory : "Past History Summary"}
                      </Typography>
                      {isPastHistory !== '' && <Typography>
                        <IconButton onClick={() => setIsPastHistory("")} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                          <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                        </IconButton>
                      </Typography>}
                    </Box>
                  </Paper>
                  {isPastHistory ? (
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                        <Divider />
                        {isPastHistory === 'History of similar illness' ? (
                          <Grid
                            container
                            item
                            spacing={2}
                            style={{ padding: 20 }}
                            
                          >
                            {/* <Grid item xs={4} sm={2} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      setSimilarIllness(!similarIllness);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="History of similar illness"
                                />
                              </FormGroup>
                            </Grid> */}
                            <Grid item xs={12} sm={12} md={12}>
                              <Button variant="contained" onClick={() => handleClickOpen(true)} >Add History of similar illness</Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} className="OPD-Upload-Button ">
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              
                              />
                            </Grid>
                          </Grid>) : (
                          // <Divider />
                          ""
                        )}
                        {isPastHistory === 'History of hospitalisation' ? (
                          <Grid
                            container
                            item
                            spacing={2}
                            style={{ padding: 20 }}
                          >
                            {/* <Grid item xs={4} sm={2} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      setHospitalisation(!hospitalisation);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="History of hospitalisation"
                                />
                              </FormGroup>
                            </Grid> */}
                            
                            <Grid item xs={12} sm={12} md={12}>
                              <Button variant="contained" onClick={() => handleClickOpenHistoryOfHospitalisation(true)} >Add History of hospitalisation</Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} className="OPD-Upload-Button ">
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid>
                          </Grid>
                        ) : (
                          // <Divider />
                          ""
                        )}
                        {isPastHistory === 'Any surgery done in past' ? (
                          <Grid
                            container
                            item
                            spacing={2}
                            style={{ padding: 20 }}
                          >
                            {/* <Grid item xs={4} sm={2} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Any surgery done in past"
                                />
                              </FormGroup>
                            </Grid> */}
                            {/* {surgeryDoneInPast &&  */}
                            <Grid item xs={6} sm={12} md={12}>
                              <Button variant="contained" onClick={() => handleClickOpenAnySurgeryDoneInPast(true)} >Add Any surgery done in past</Button>
                            </Grid>
                            {/* } */}
                            {/* {surgeryDoneInPast &&  */}
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                            {/* } */}
                            {/* {surgeryDoneInPast && */}
                            <Grid item xs={12} sm={12} md={6} className="OPD-Upload-Button ">
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid>
                            {/* } */}
                          </Grid>
                        ) : (
                          // <Divider />
                          ""
                        )}
                      </Box>
                    </Formik>
                  ) : (
                    <Box>  <Divider />
                       <Box className="summary-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
      
    className="dialog"
    open={openDialog}
    onClose={handleClose}
    fullScreen
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "1200px", // Adjust the width as needed
            maxWidth: "none",
          },
        }}
      >
         <AppBar sx={{ position: 'relative' }}>
              <Toolbar
              sx={{ display:"flex",
                justifyContent:"space-between"}}>
                <Typography
                  // className={selected ? "symptom-text" : ""}
                  style={{ fontWeight: 600}}
                  sx={{
                    paddingLeft:{
                      xs:1,
                      sm:0,
                    }
                  }}
                  variant="h6"
                >
               Add History of similar illness
                </Typography>
               
              </Toolbar>
            </AppBar>
        <DialogContent>
        <Box className="main-container" pl={2}>
   
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
        }}
        onSubmit={async (values) => {
          console.log("values", values)
        }}
      >
        <Grid container spacing={2} sx={{marginRight:{
        xs:-2,
        sm:0
       }}}>
          <Grid item xs={12} mb={3} className="group-container">
            <Grid container item spacing={2}>

              <Grid item xs={4} sm={4}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Duration of last episode</FormLabel>
                  <CustomTextField
                    name=" "
                    placeholder=""
                    autoCapitalize="none"
                    type="number"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Unit of duration</FormLabel>
                  <CustomSelect
                          // isNotCreatable={true}
                          id={"duration"}
                          FieldName={"duration"}
                          options={durationState}
                          openMenuOnFocus
                          isClearable
                          isSearchable
                          menuPortalTarget={document.querySelector("body")}
                          handleChange={(e) => {
                            console.log("event:", e)
                          }}
                          Value=""
                        />
               
                </FormControl>
              </Grid>
              <Grid item xs={2} sm={2} />     
              <Grid item xs={4} sm={4}>
              <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Time taken to relieve</FormLabel>
                 
                  <CustomTextField
                    name=" "
                    placeholder=""
                    autoCapitalize="none"
                    type="number"
                    fullWidth
                    size="small"
                  />
                  </FormControl>
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Unit of duration</FormLabel>
                  
                  <CustomSelect
                          // isNotCreatable={true}
                          id={"duration"}
                          FieldName={"duration"}
                          options={durationState}
                          openMenuOnFocus
                          isClearable
                          isSearchable
                          menuPortalTarget={document.querySelector("body")}
                          handleChange={(e) => {
                            console.log("event:", e)
                          }}
                          Value=""
                        />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
              <Box
                className="template-table-container"
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  border: '1px solid #0000001f',
                  borderRadius: 4
                }}
              >
                <Box
                  style={{
                    flex: 1,
                    borderRadius: 8
                  }}>
                  <Box
                    className='template-box'
                  >
                    <Typography>
                        Detail of medicine taken
                    </Typography>
                  </Box>
                  <Divider />
                  <DraggableList
                    items={itemsDraggable}
                    onDragEnd={onDragEnd}
                    onHandleOpenDialog={onHandleOpenDialogMedicine}
                  />
                </Box>
              </Box>
            </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Required Admission?</FormLabel>
                 
                    <CustomSelect
                      isNotCreatable={true}
                      id={"admission"}
                      FieldName={"admission"}
                      options={addmissionState}
                      openMenuOnFocus
                      isClearable
                      isSearchable
                      menuPortalTarget={document.querySelector("body")}
                      handleChange={(e) => {
                        console.log("event:", e)
                      }}
                      Value=""
                    />
                  </FormControl>
              
              </Grid>
            </Grid>
          </Grid>
        
        </Grid>

      </Formik>

    </Box>
        </DialogContent>
        <DialogActions>
        <LoadingButton
            color="primary"
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="contained"
            type="submit"
            className="primary-button"
            name="submit"
            onClick={()=>{
              handleClose()
            }}
          >
            Save
          </LoadingButton>
          <LoadingButton
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="outlined"
            className="secondary-button"
            color="secondary"
            onClick={()=>{
              handleClose()
            }}
          >
            Cancel
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
      className="dialog"
        open={openDialogHistoryOfHospitalisation}
        onClose={handleCloseHistoryOfHospitalisation}
        fullScreen
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "1200px", // Adjust the width as needed
            maxWidth: "none",
          },
        }}
      >
         <AppBar sx={{ position: 'relative' }}>
              <Toolbar
               sx={{ display:"flex",
                justifyContent:"space-between"}}>
                <Typography
                  // className={selected ? "symptom-text" : ""}
                  style={{ fontWeight: 600}}
                  sx={{
                    paddingLeft:{
                      xs:1,
                      sm:0,
                    }
                  }}
                  variant="h6"
                >
               Add History of hospitalisation
                </Typography>
               
              </Toolbar>
            </AppBar>
        <DialogContent className="set-height-small set-height-mobile set-height-medium set-height-large">
        <Box className="main-container" pl={2}>
    
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
        }}
        onSubmit={async (values) => {
          console.log("values", values)
        }}
      >
        <Grid container spacing={2} sx={{marginRight:{
        xs:-2,
        sm:0
       }}}>
          <Grid item xs={12} mb={3} className="group-container">
            <Grid container item spacing={2}>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Name of Hospital</FormLabel>
                  <CustomSelect
                      // isNotCreatable={true}
                      id={"name"}
                      FieldName={"name"}
                      options={[]}
                      openMenuOnFocus
                      isClearable
                      isSearchable
                      menuPortalTarget={document.querySelector("body")}
                      handleChange={(e) => {
                        console.log("event:", e)
                      }}
                      Value=""
                    />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} >
                <div >
                  <Box style={{ fontSize: 12 }}>Date of admission</Box>
                  <FormControl sx={{ width: '100% !important' }}>
                    <DatePickerCompponent
                      format="DD-MM-YYYY"
                      mode="date"
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} >
                <div >
                  <Box style={{ fontSize: 12 }}>Date of Discharge</Box>
                  <FormControl sx={{ width: '100% !important' }}>
                    <DatePickerCompponent
                      format="DD-MM-YYYY"
                      mode="date"
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </FormControl>
                </div>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={3}/> */}
              <Grid item xs={4} sm={4}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Duration of hospital stay</FormLabel>
                 
                  <CustomTextField
                    name=" "
                    placeholder=""
                    autoCapitalize="none"
                    type="number"
                    fullWidth
                    size="small"
                  />
                  </FormControl>
              
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Unit of duration</FormLabel>
                  
                  <CustomSelect
                          // isNotCreatable={true}
                          id={"duration"}
                          FieldName={"duration"}
                          options={durationState}
                          openMenuOnFocus
                          isClearable
                          isSearchable
                          menuPortalTarget={document.querySelector("body")}
                          handleChange={(e) => {
                            console.log("event:", e)
                          }}
                          Value=""
                        />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12}>
              <Box
                className="template-table-container"
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  border: '1px solid #0000001f',
                  borderRadius: 4
                }}
              >
                <Box
                  style={{
                    flex: 1,
                    borderRadius: 8
                  }}>
                  <Box
                    className='template-box'
                  >
                    <Typography>
                        Diagnosis
                    </Typography>
                  </Box>
                  <Divider />
                  <DraggableList
                    items={itemsDraggable}
                    onDragEnd={onDragEnd}
                    onHandleOpenDialog={onHandleOpenDialogDiagnosis}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box
                className="template-table-container"
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  border: '1px solid #0000001f',
                  borderRadius: 4
                }}
              >
                <Box
                  style={{
                    flex: 1,
                    borderRadius: 8
                  }}>
                  <Box
                    className='template-box'
                  >
                    <Typography>
                     Details of medicine taken
                    </Typography>
                  </Box>
                  <Divider />
                  <DraggableList
                    items={itemsDraggable}
                    onDragEnd={onDragEnd}
                    onHandleOpenDialog={onHandleOpenDialogMedicine}
                  />
                </Box>
              </Box>
            </Grid>
  
            </Grid>
          </Grid>
          
        </Grid>

      </Formik>

    </Box>
        </DialogContent>
        <DialogActions>
        <LoadingButton
            color="primary"
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="contained"
            type="submit"
            className="primary-button"
            name="submit"
            onClick={()=>{
              handleCloseHistoryOfHospitalisation()
            }}
          >
            Save
          </LoadingButton>
          <LoadingButton
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="outlined"
            className="secondary-button"
            color="secondary"
            onClick={()=>{
              handleCloseHistoryOfHospitalisation()
            }}
          >
            Cancel
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
      className="dialog"
        open={openDialogAnySurgeryDoneInPast}
        onClose={handleCloseAnySurgeryDoneInPast}
        fullScreen
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "1200px", // Adjust the width as needed
            maxWidth: "none",
          },
        }}
      >
         <AppBar sx={{ position: 'relative' }}>
              <Toolbar
              sx={{ display:"flex",
                justifyContent:"space-between"}}>
                <Typography
                  // className={selected ? "symptom-text" : ""}
                  style={{ fontWeight: 600 }}
                  sx={{
                    paddingLeft:{
                      xs:1,
                      sm:0,
                    }
                  }}
                  variant="h6"
                >
              Add any surgery done in past
                </Typography>
               
              </Toolbar>
            </AppBar>
        <DialogContent className="set-height-small set-height-mobile set-height-medium set-height-large">
        <Box className="main-container" pl={2}>
  
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
        }}
        onSubmit={async (values) => {
          console.log("values", values)
        }}
      >
        <Grid container spacing={2} sx={{marginRight:{
        xs:-2,
        sm:0
       }}}>
          <Grid item xs={12} mb={3} className="group-container">
            <Grid container item spacing={2}>

              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Name of Hospital</FormLabel>
                  <CustomSelect
                      // isNotCreatable={true}
                      id={"name"}
                      FieldName={"name"}
                      options={[]}
                      openMenuOnFocus
                      isClearable
                      isSearchable
                      menuPortalTarget={document.querySelector("body")}
                      handleChange={(e) => {
                        console.log("event:", e)
                      }}
                      Value=""
                    />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div >
                  <Box style={{ fontSize: 12 }}>Date of admission</Box>
                  <FormControl sx={{ width: '100% !important' }}>
                    <DatePickerCompponent
                      format="DD-MM-YYYY"
                      mode="date"
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div >
                  <Box style={{ fontSize: 12 }}>Date of Discharge</Box>
                  <FormControl sx={{ width: '100% !important' }}>
                    <DatePickerCompponent
                      format="DD-MM-YYYY"
                      mode="date"
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </FormControl>
                </div>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={3}/> */}
              <Grid item xs={4} sm={4}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Duration of hospital stay</FormLabel>
                  <CustomTextField
                    name=" "
                    placeholder=""
                    autoCapitalize="none"
                    type="number"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Unit of duration</FormLabel>
                  
                  <CustomSelect
                          // isNotCreatable={true}
                          id={"duration"}
                          FieldName={"duration"}
                          options={durationState}
                          openMenuOnFocus
                          isClearable
                          isSearchable
                          menuPortalTarget={document.querySelector("body")}
                          handleChange={(e) => {
                            console.log("event:", e)
                          }}
                          Value=""
                        />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Name of Surgery</FormLabel>
                  <CustomTextField
                    name=" "
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
              <Box
                className="template-table-container"
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  border: '1px solid #0000001f',
                  borderRadius: 4
                }}
              >
                <Box
                  style={{
                    flex: 1,
                    borderRadius: 8
                  }}>
                  <Box
                    className='template-box'
                  >
                    <Typography>
                        Detail of medicine taken
                    </Typography>
                  </Box>
                  <Divider />
                  <DraggableList
                    items={itemsDraggable}
                    onDragEnd={onDragEnd}
                    onHandleOpenDialog={onHandleOpenDialogMedicine}
                  />
                </Box>
              </Box>
            </Grid>


            </Grid>
          </Grid>
         
        </Grid>

      </Formik>

    </Box>
        </DialogContent>
        <DialogActions>
        <LoadingButton
            color="primary"
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="contained"
            type="submit"
            className="primary-button"
            name="submit"
            onClick={()=>{
              handleCloseAnySurgeryDoneInPast()
            }}
          >
            Save
          </LoadingButton>
          <LoadingButton
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="outlined"
            className="secondary-button"
            color="secondary"
            onClick={()=>{
              handleCloseAnySurgeryDoneInPast()
            }}
          >
            Cancel
          </LoadingButton>
        </DialogActions>
      </Dialog>
    
      <Dialog
      fullScreen
                open={open}
                onClose={handleCloseForHistoryOfSimilarIllness}
       
            >
               <AppBar sx={{ position: 'relative' }}>
          <Toolbar
          sx={{ display:"flex",
            justifyContent:"space-between"}}>
            <Typography
              // className={selected ? "symptom-text" : ""}
              style={{ fontWeight: 600, marginLeft:1}}
              sx={{
                paddingLeft:{
                  xs:1,
                  sm:0,
                }
              }}
              variant="h6"
            >
          {isPastHistory ? isPastHistory : "Past History"}
            </Typography>
            <IconButton onClick={() => handleCloseForHistoryOfSimilarIllness(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
              <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
            </IconButton>
          </Toolbar>
          <Divider />
        </AppBar>
                <DialogContent style={{ height: 400 }}>
                <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container"
                sx={{
                  border: "0px solid #0000001f",
                  minHeight: 400,
                  borderRadius: 1,
                }}
              >
                <Box className="inner-title-container">
                 
                  {isPastHistory ? (
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                     
                        {isPastHistory === 'History of similar illness' ? (
                          <Grid
                            container
                            item
                            spacing={2}
                          >
                         
                            <Grid item xs={12} sm={12} md={12}>
                              <Button variant="contained" onClick={() => handleClickOpen(true)} >Add History of similar illness</Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              
                              />
                            </Grid>
                          </Grid>) : (
                          // <Divider />
                          ""
                        )}
                        {isPastHistory === 'History of hospitalisation' ? (
                          <Grid
                            container
                            item
                            spacing={2}
                         
                          >
                            <Grid item xs={12} sm={12} md={12}>
                              <Button variant="contained" onClick={() => handleClickOpenHistoryOfHospitalisation(true)} >Add History of hospitalisation</Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} >
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid>
                          </Grid>
                        ) : (
                          // <Divider />
                          ""
                        )}
                        {isPastHistory === 'Any surgery done in past' ? (
                          <Grid
                            container
                            item
                            spacing={2}
                    
                          >
                           
                            <Grid item xs={6} sm={12} md={12}>
                              <Button variant="contained" onClick={() => handleClickOpenAnySurgeryDoneInPast(true)} >Add Any surgery done in past</Button>
                            </Grid>
                            {/* } */}
                            {/* {surgeryDoneInPast &&  */}
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                            {/* } */}
                            {/* {surgeryDoneInPast && */}
                            <Grid item xs={12} sm={12} md={6} >
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid>
                            {/* } */}
                          </Grid>
                        ) : (
                          // <Divider />
                          ""
                        )}
                      </Box>
                    </Formik>
                  ) : (
                    <Box>  <Divider />
                      <Box>This is summary section </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>     
                </DialogContent>
            </Dialog>
        
        {/* Past Summary Section */}
        <Dialog
            fullScreen
            open={openPastSummary}
            onClose={handleClickClosePastSummary}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
                <Typography
                  // className={selected ? "symptom-text" : ""}
                  style={{ fontWeight: 600, marginLeft: 0 }}
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                >
               Past History Summary
                </Typography>
                <IconButton onClick={() => handleClickClosePastSummary(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                  <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent style={{ height: 400 }}>
            <Box className="template-table-container summary-dialog-mainContainer">
                      <Box className="summary-dialog-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
            </Box>
            </DialogContent>
          </Dialog>

          <Dialog
        className="dialog"
        open={openDialogMedicine}
        onClose={handleCloseDialogMedicine}
        fullScreen
      >
          <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography
              // className={selected ? "symptom-text" : ""}
              style={{ fontWeight: 600, marginLeft: 1 }}
              sx={{
                paddingLeft: {
                  xs: 1,
                  sm: 0,
                }
              }}
             
              variant="h6"
            >
           Medicine Edit
            </Typography>
          
          </Toolbar>
        </AppBar>
        <Divider />
        <DialogContent>
        <MedicinePrescription/>
        </DialogContent>
        <DialogActions>
        <LoadingButton
            color="primary"
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="contained"
            type="submit"
            className="primary-button"
            name="submit"
            onClick={()=>{
              handleCloseDialogMedicine()
            }}
          >
            Save
          </LoadingButton>
          <LoadingButton
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="outlined"
            className="secondary-button"
            color="secondary"
            onClick={()=>{
              handleCloseDialogMedicine()
            }}
          >
            Cancel
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        className="dialog"
        open={openDialogDiagnosis}
        onClose={handleCloseDialogDiagnosis}
        fullScreen
      >
          <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography
              // className={selected ? "symptom-text" : ""}
              style={{ fontWeight: 600, marginLeft: 1 }}
              sx={{
                paddingLeft: {
                  xs: 1,
                  sm: 0,
                }
              }}
             
              variant="h6"
            >
              Diagnosis Edit
            </Typography>
          
          </Toolbar>
        </AppBar>
        <Divider />
        <DialogContent>
        <DiagnosisInstructionMasterSave/>
        </DialogContent>
        <DialogActions>
        <LoadingButton
            color="primary"
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="contained"
            type="submit"
            className="primary-button"
            name="submit"
            onClick={()=>{
              handleCloseDialogDiagnosis()
            }}
          >
            Save
          </LoadingButton>
          <LoadingButton
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="outlined"
            className="secondary-button"
            color="secondary"
            onClick={()=>{
              handleCloseDialogDiagnosis()
            }}
          >
            Cancel
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
