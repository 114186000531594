import React from "react";
import { useNavigate } from "react-router-dom";
import "../../../../../../../theme/style.scss";
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  styled,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  AppBar,
  Toolbar
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Formik } from "formik";
import 'react-phone-number-input/style.css';
import DatePickerCompponent from "../../../../../../../components/CustomFields/DatePicker";
import { DropZone } from "../../../../../../../components/CustomFields/DropZone";
import { CustomSelect } from "../../../../../../../components/CustomFields/CustomSelect";
import 'react-phone-number-input/style.css';
import { reorder } from "../../../../../../../Helpers/helpers";
import { DropResult } from 'react-beautiful-dnd';
import DraggableList from "../../../../../../../components/DraggableTable/DraggableList";
import MedicinePrescription from "../../../Diagnosis/MedicineComponent/MedicinePrescription";
interface ColourOption {
  value: string;
  label: string;
}

const admissionState: ColourOption[] = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
 
];

const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#FAFBFD", // Set the desired background color
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));


export default function HistoryOfSimilarIllness() {
  const [items, setItems] = React.useState([
    {
      id: "Item 1",
      primary: "Practical Rubber Computer1",
      secondary: "Synergistic leading edge matrices"
    }, {
      id: "Item 2",
      primary: "Practical Rubber Computer2",
      secondary: "Synergistic leading edge matrices"
    }, {
      id: "Item 3",
      primary: "Practical Rubber Computer3",
      secondary: "Synergistic leading edge matrices"
    }
  ]);
  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(items, source.index, destination.index);

    setItems(newItems);
  };

  const [openDialogMedicine, setOpenDialogMedicine] = React.useState(false);
  const onHandleOpenDialogMedicine = (item: Item) => {

    handleClickOpenDialogMedicine(true);
    console.log("Opening dialog for item:", item);
  };
  const handleClickOpenDialogMedicine = (value: boolean) => {
    setOpenDialogMedicine(value);
  };

  const handleCloseDialogMedicine = () => {
    setOpenDialogMedicine(false);
  };

  const navigate = useNavigate();
  return (
    <Box className="main-container" pl={2}>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
        }}
        onSubmit={async (values) => {
          console.log("values", values)
        }}
      >
        <Grid container spacing={2} sx={{marginRight:{
        xs:-2,
        sm:0
       }}}>
          <Grid item xs={12} mb={3} className="group-container">
            <Grid container item spacing={2}>

              <Grid item xs={6} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Duration of last episode of similar illness</FormLabel>
                  <CustomTextField
                    name=" "
                    placeholder=""
                    autoCapitalize="none"
                    type="number"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3} sm={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Unit of duration</FormLabel>
                  
                  <CustomTextField
                    name=" "
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  
                  />
                </FormControl>
              </Grid>
           
              <Grid item xs={6} sm={6}>
              <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Time taken to relieve the symptoms</FormLabel>
                 
                    <CustomSelect
                      isNotCreatable={true}
                      id={"admission"}
                      FieldName={"admission"}
                      options={admissionState}
                      openMenuOnFocus
                      isClearable
                      isSearchable
                      menuPortalTarget={document.querySelector("body")}
                      handleChange={(e) => {
                        console.log("event:", e)
                      }}
                      Value=""
                    />
                  </FormControl>
              </Grid>
              <Grid item xs={3} sm={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Unit of duration</FormLabel>
                  
                  <CustomTextField
                    name=" "
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
              <Box
                className="template-table-container"
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  border: '1px solid #0000001f',
                  borderRadius: 4
                }}
              >
                <Box
                  style={{
                    flex: 1,
                    borderRadius: 8
                  }}>
                  <Box
                    className='template-box'
                  >
                    <Typography>
                        Detail of medicine taken
                    </Typography>
                  </Box>
                  <Divider />
                  <DraggableList
                    items={items}
                    onDragEnd={onDragEnd}
                    onHandleOpenDialog={onHandleOpenDialogMedicine}
                  />
                </Box>
              </Box>
            </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Required Admission?</FormLabel>
                 
                    <CustomSelect
                      isNotCreatable={true}
                      id={"admission"}
                      FieldName={"admission"}
                      options={admissionState}
                      openMenuOnFocus
                      isClearable
                      isSearchable
                      menuPortalTarget={document.querySelector("body")}
                      handleChange={(e) => {
                        console.log("event:", e)
                      }}
                      Value=""
                    />
                  </FormControl>
              
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Formik>

      <Dialog
        className="dialog"
        open={openDialogMedicine}
        onClose={handleCloseDialogMedicine}
        fullScreen
      >
          <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography
              // className={selected ? "symptom-text" : ""}
              style={{ fontWeight: 600, marginLeft: 1 }}
              sx={{
                paddingLeft: {
                  xs: 1,
                  sm: 0,
                }
              }}
              variant="h6"
            >
              Medicine Edit
            </Typography>
          
          </Toolbar>
        </AppBar>
        <Divider />
        <DialogContent>
        <MedicinePrescription/>
        </DialogContent>
        <DialogActions>
        <LoadingButton
            color="primary"
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="contained"
            type="submit"
            className="primary-button"
            name="submit"
            onClick={()=>{
              handleCloseDialogMedicine()
            }}
          >
            Save
          </LoadingButton>
          <LoadingButton
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="outlined"
            className="secondary-button"
            color="secondary"
            onClick={()=>{
              handleCloseDialogMedicine()
            }}
          >
            Cancel
          </LoadingButton>
        </DialogActions>
      </Dialog>


    </Box>
  );
}
