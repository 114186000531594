import React from "react";
import { useNavigate } from "react-router-dom";
import "../../theme/style.scss";
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  styled,
  Typography,
} from "@mui/material";
import Divider from '@mui/material/Divider';
import LoadingButton from "@mui/lab/LoadingButton";
import Title from "../../components/Title";
import { Formik } from "formik";
import DatePickerCompponent from "../../components/CustomFields/DatePicker";
import 'react-phone-number-input/style.css';
import { DropZone } from "../CustomFields/DropZone";


const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#FAFBFD", // Set the desired background color
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));

export type Props = {
  handleClose: () => void;
};

const CustomDocSave = ({ handleClose }: Props) => {
  return (

    <Grid container spacing={0}>
      <Grid item xs={12} >
        <Typography variant="h6" sx={{ padding: '16px' }}> Documents</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item  xs={12} >
        <Grid  item container p={'16px !important'} spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <FormControl fullWidth>
              <FormLabel className="fileds-label-text">Registration Number</FormLabel>
              <CustomTextField
                name="laboratoryName"
                placeholder=""
                autoCapitalize="none"
                type="text"
                fullWidth
                size="small"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <div>
              <FormLabel className="fileds-label-text">Registration / Birth Date</FormLabel>

              <FormControl sx={{ width: '100% !important' }}>
                <DatePickerCompponent
                  format="DD-MM-YYYY"
                  mode="date"
                  slotProps={{ textField: { size: 'small' } }}
                />
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <div>
              <FormLabel className="fileds-label-text">Expiration Date</FormLabel>
              <FormControl sx={{ width: '100% !important' }}>
                <DatePickerCompponent
                  format="DD-MM-YYYY"
                  mode="date"
                  slotProps={{ textField: { size: 'small' } }}
                />
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <FormControl fullWidth>
              <FormLabel className="fileds-label-text">Name</FormLabel>
              <CustomTextField
                name="laboratoryName"
                placeholder=""
                autoCapitalize="none"
                type="text"
                fullWidth
                size="small"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <FormControl fullWidth>
              <FormLabel className="fileds-label-text">Description</FormLabel>
              <CustomTextField
                name="laboratoryName"
                placeholder=""
                autoCapitalize="none"
                type="text"
                fullWidth
                multiline
                rows={4}
                size="small"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} className="remove">
            <Grid item xs={12} sm={12} md={12}>
              <FormControl fullWidth >

                <FormLabel className="fileds-label-text">File</FormLabel>
                <DropZone />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: "flex", justifyContent: "end", gap: 2, padding: '16px' }}>
          <LoadingButton
            color="primary"
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="contained"
            type="submit"
            className="primary-button"
            name="submit"
          >
            Save
          </LoadingButton>
          <LoadingButton
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="outlined"
            className="secondary-button"
            color="secondary"
            onClick={handleClose}
          >
            Cancel
          </LoadingButton>
        </Box>
      </Grid>
    </Grid>

  );
}

export default CustomDocSave;
