

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../theme/style.scss';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, TextField, Typography, styled } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CustomBreadcrumbs from '../../components/BreadcrumbsComponent/CustomBreadcrumbs';

const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: '#FAFBFD', // Set the desired background color
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.grey[300],
      borderWidth: '1px',
      BorderStyle: 'solid'
    },
    '&:hover fieldset': {
      borderColor: theme.palette.grey[500],
      borderWidth: '1px',
      BorderStyle: 'solid'
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: "2px",
    },
  },
}));

export default function SpecialitySave() {
  const navigate = useNavigate()
  const [systems, setSystem] = useState([
    {
      id: 1,
      label: 'General Symptoms'
    },
    {
      id: 2,
      label: 'Respiratory System'
    }
  ]);
  return (
    <Box className="main-container " pl={2}>
      <Grid xs={12} container className='page-header-save'>
        <Grid xs={12} sm={12} md={12} item>
          <CustomBreadcrumbs />
        </Grid>
      </Grid>
      <Grid container spacing={2}  >
        <Grid item xs={12} mb={2} className="group-container">
          <Grid container item spacing={2} >
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>Code</FormLabel>
                <CustomTextField
                  name="code"
                  placeholder=""
                  autoCapitalize='none'
                  type="text"
                  fullWidth
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item md={12}></Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>Speciality</FormLabel>
                <CustomTextField
                  name="code"
                  autoCapitalize='none'
                  type="text"
                  fullWidth
                  size='small'
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>Department </FormLabel>
                <CustomTextField
                  name="code"
                  placeholder=""
                  autoCapitalize='none'
                  type="text"
                  fullWidth
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <Typography>
                  Systems
                </Typography>
                <FormGroup>
                  {systems.map((system, i) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                          // defaultChecked
                          // onChange={() => setMorning(!morning)}
                          />
                        }
                        label={system.label}
                      />
                    )
                  })
                  }
                </FormGroup>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'end', gap: 2 }}>
            <LoadingButton
              color="primary"
              tabIndex={3}
              loadingPosition="start"
              startIcon={<></>}
              variant="contained"
              type="submit"
              className='primary-button'
              width="180px"
              name="submit"
              onClick={() => navigate('/speciality')}
            >
              SAVE
            </LoadingButton>
            <LoadingButton
              tabIndex={3}
              loadingPosition="start"
              startIcon={<></>}
              variant="outlined"
              type="submit"
              className='secondary-button'
              color="secondary"
              name="submit"
              onClick={() => navigate('/settings/speciality')}
            >
              CANCEL
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}