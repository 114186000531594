import React, { FC, useState } from "react";
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  List,
  Paper,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { Item } from "../../../../../../Helpers/typings";
import ChiefComplaintsDraggableTableItem from "./FollowupDraggableTableItem";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ReactQuill from "react-quill";
import SubjectRoundedIcon from "@mui/icons-material/SubjectRounded";
import useMediaQuery from "@mui/material/useMediaQuery";

const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#FAFBFD",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));

export type Props = {
  header: string;
  items: Item[];
  onDragEnd: OnDragEndResponder;
  onHandleSelectData: (value: string) => void;
  selected: string;
};

const FollowupDraggableTable: FC<Props> = React.memo(
  ({ header, items, onDragEnd, onHandleSelectData, selected }) => {
    const smallScreen = useMediaQuery("(max-width:768px)");
    const mobileScreen = useMediaQuery("(max-width:425px)");
    const [open, setOpen] = React.useState(false);

    const onRowClick = (val) => {
      onHandleSelectData(val);
    };
    const handleClickOpen = (value: boolean) => {
      if (mobileScreen) {
        setOpen(value);
      }
      else if (smallScreen) {
        setOpen(value);
      }
    };

    const handleClose = () => {
      setOpen(false);
    };
    const [showNote, setShowNote] = useState(false);

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Box
          style={{
            display: "flex",
            flexWrap: "wrap",
            
          }}
        >
          <Paper
            style={{
              flex: 1,
            }}
          >
            <Box className="inner-header-container"
               style={{
                display: "flex",
                justifyContent: "space-between",
              }}>
              <Typography style={{ fontWeight: 600 }}>{header}</Typography>
              
            </Box>
          </Paper>
        </Box>
        <Droppable droppableId="droppable-list">
          {(provided) => (
            <List ref={provided.innerRef} {...provided.droppableProps}>
              {items.map((item: Item, index: number) => (
                <ChiefComplaintsDraggableTableItem
                  item={item}
                  index={index}
                  key={item.id}
                  onHandleSelect={onRowClick}
                  selectedSymptom={selected}
                />
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
        <Divider />
        <Box style={{ padding: 10, paddingRight: 24, paddingLeft: 24 }}>
          <FormControl fullWidth>
            <CustomTextField
              name="templateName"
              placeholder="Search..."
              autoCapitalize="none"
              type="text"
              fullWidth
              size="small"
            />
          </FormControl>
        </Box>
        <Divider />
        {/* <Box
          style={{
            padding: 10,
          }}
        >
          <Box>
            {!showNote && (
              <Button
                variant="text"
                style={{ fontSize: "6px" }}
                onClick={() => {
                  setShowNote(true);
                }}
              >
                <AddIcon className="add-icon" />
                <span className="add-icon"> Note</span>
              </Button>
            )}
          </Box>

          {showNote && (
            <FormControl fullWidth>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "end",
                  minWidth: "100%",
                }}
              >
                <FormLabel
                  sx={{ paddingLeft: 1 }}
                  className="fileds-label-text"
                >
                  Note
                </FormLabel>
                <Button
                  variant="text"
                  onClick={() => {
                    setShowNote(false);
                  }}
                >
                  <RemoveIcon className="remove-note" />
                  <span className="remove-note">Remove Note</span>
                </Button>
              </Box>
              <ReactQuill
                className="react-quill-alignment"
                theme="snow"
                value={""}
                onChange={(e) => { }}
              />
            </FormControl>
          )}
        </Box> */}
      </DragDropContext>
    );
  }
);

export default FollowupDraggableTable;
