import "../../../../../../theme/style.scss";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
  styled,
  Dialog,
  DialogContent
} from "@mui/material";
import React, { useState } from "react";
import { Formik } from "formik";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import ReactQuill from "react-quill";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { MultiUpload } from "../../../../../../components/MultiUpload/MultiUpload";
import { AsyncCustomSelect } from "../../../../../../components/CustomFields/CustomSelect";
import useMediaQuery from '@mui/material/useMediaQuery'
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import SubjectRoundedIcon from "@mui/icons-material/SubjectRounded";

const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#FAFBFD",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function DevelopmentHistory() {
  const [showNote, setShowNote] = useState(false);
  // const [documentsFiles, setDocumentsFile] = useState<any>([]);
  const [isDevelopmentHistory, setIsDevelopmentHistory] = useState("");

  const [concernsWithDevelopmentOfChild, setConcernsWithDevelopmentOfChild] = useState(false);
  const [grossMotorMilestones, setGrossMotorMilestones] = useState(false);
  const [fineMoterMilestones, setFineMoterMilestones] = useState(false);
  const [motorAgeOfChildInMonths, setMotorAgeOfChildInMonths] = useState(false);
  const [socialMilestones, setSocialMilestones] = useState(false);
  const [languageMilestones, setLanguageMilestones] = useState(false);
  const [cognitiveMilestones, setCognitiveMilestones] = useState(false);
  const [scholasticPerformanceOfChild, setScholasticPerformanceOfChild] = useState(false);
  const [mentalAgeOfChilldInMonths, setMentalAgeOfChilldInMonths] = useState(false);
  const [documentsFiles, setDocumentsFile] = useState<any>([]);
  const [selected, setSelected] = useState("");
  const smallScreen = useMediaQuery("(max-width:768px)");
  const mobileScreen = useMediaQuery("(max-width:425px)");

  //Draggable Table
  const [items, setItems] = React.useState([
    {
      id: 1,
      symptom: "Any concerns with development of child",
    },
    {
      id: 2,
      symptom: "Gross motor milestones",
    },
    {
      id: 3,
      symptom: "Fine Moter Milestones",
    },
    {
      id: 4,
      symptom: "Motor age of child",
    },
    {
      id: 5,
      symptom: "Social Milestones",
    },
    {
      id: 6,
      symptom: "Language Milestones",
    },
    {
      id: 7,
      symptom: "Cognitive Milestones",
    },
    {
      id: 8,
      symptom: "Scholastic Performance of child",
    },
    {
      id: 9,
      symptom: "Mental age of chilld in months",
    },
  ]);

  const saveLogoFileSelected = (files: any) => {
    // setDocumentsFile(files);
  };
  const dowloadDocumentFile = (files) => { };

  const removeFile = (file) => { };

  const [open, setOpen] = React.useState(false);

  const handleClickOpenDevelopmentHistory = (value: boolean) => {
    if(smallScreen){
      
      setOpen(value);
    }else if(mobileScreen){
      setOpen(value);
      
    }
  };

  const handleClickCloseDevelopmentHistory = () => {
      setOpen(false);
  };

  const [openDevelopmentSummary, setOpenDevelopmentSummary] = React.useState(false);

  const handleClickOpenDevelopmentSummary = (value: boolean) => {
    if (mobileScreen) {
      setOpenDevelopmentSummary(value);
    }
    else if (smallScreen) {
      setOpenDevelopmentSummary(value);
    }
  };

  const handleClickCloseDevelopmentSummary = () => {
    setOpenDevelopmentSummary(false);
  };

  return (
    <Box className="main-container">
      <Grid container spacing={0}>
        <Grid item xs={12} mb={2}>
          <Grid container item spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                className="template-table-container"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                }}
              >
                <Box className="inner-title-container">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box className="inner-header-container"
                    
                      sx={{display:"flex", justifyContent:"space-between"}}
                    >
                      <Typography style={{ fontWeight: 600 }}
                      >
                        Development History
                      </Typography>
                      {mobileScreen || smallScreen ? <Box>
                      
                      <Button onClick={() => handleClickOpenDevelopmentSummary(true)} sx={{ padding: 0, margin: 0,minWidth:"30px" }} aria-label="delete">
                       <SubjectRoundedIcon></SubjectRoundedIcon>
                      </Button>
            </Box> : ''}
                    </Box>
                  </Paper>
                  <Divider />
                  <List>
                    {items.map((item: any, index: number) => (
                      <ListItem
                        sx={{
                          borderBottom: "1px solid #E9EBEC",
                          cursor: 'pointer'
                        }}
                        className={
                          isDevelopmentHistory === item.symptom
                            ? "draggable-list-item"
                            : " "
                        }
                      >
                        <Grid container item spacing={2}>
                          <Grid item xs={12} sm={12} md={12}>
                            <Box
                              style={{
                                display: "flex",
                              }}
                              onClick={()=>{handleClickOpenDevelopmentHistory(true)}}
                            >
                              <Grid container item>
                                <Grid item xs={12} sm={12} md={12}>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() => {
                                      setIsDevelopmentHistory(item.symptom)
                                    }}
                                  >
                                    <Box
                                      className={
                                        isDevelopmentHistory === item.symptom
                                          ? "symptom-text"
                                          : " "
                                      }
                                      sx={{ paddingBottom: '8px', paddingTop: '8px' }}
                                    
                                      >
                                      <Typography>{item.symptom}</Typography>
                                    </Box>
                                    {/* <Box>
                                      <FormGroup>
                                        <FormControlLabel
                                          className={
                                            isDevelopmentHistory ===
                                              item.symptom
                                              ? "symptom-text"
                                              : " "
                                          }
                                          control={
                                            <Checkbox
                                              onChange={(e) => {
                                                setIsDevelopmentHistory(
                                                  e.target.value
                                                );
                                                console.log(
                                                  "checkbox data:",
                                                  e.target.value
                                                );
                                              }}
                                              value={item.symptom}
                                            />
                                          }
                                          label={item.symptom}
                                        />
                                      </FormGroup>
                                    </Box> */}
                                    {isDevelopmentHistory === item.symptom && (
                                      <Box
                                        style={{
                                          paddingTop: "8px",
                                          paddingLeft: '8px'
                                        }}
                                      >
                                        <KeyboardDoubleArrowRightRoundedIcon />
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                  </List>
                  <Box className="note-container">
                    <Box>
                      {!showNote && (
                        <Button
                          variant="text"
                          style={{ fontSize: "6px" }}
                          onClick={() => {
                            setShowNote(true);
                          }}
                        >
                          <AddIcon className="add-icon" />
                          <span className="add-icon"> Note</span>
                        </Button>
                      )}
                    </Box>

                    {showNote && (
                      <FormControl fullWidth>
                        <Box
                          className='note-container-box'
                        >
                          <FormLabel
                            sx={{ paddingLeft: 1 }}
                            className="fileds-label-text"
                          >
                            Note
                          </FormLabel>
                          <Button
                            variant="text"
                            onClick={() => {
                              setShowNote(false);
                            }}
                          >
                            <RemoveIcon className="remove-note" />
                            <span className="remove-note">Remove Note</span>
                          </Button>
                        </Box>
                        <ReactQuill
                          className="react-quill-alignment"
                          theme="snow"
                          value={""}
                          onChange={(e) => { }}
                        />
                      </FormControl>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container template-hide-small-screen template-hide-medium-screen"
                sx={{
                  border: "1px solid #0000001f",
                  minHeight: 400,
                  borderRadius: 1,
                }}
                style={{ position: "sticky", top: "5px" }}
              >
                <Box className="inner-title-container">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="inner-header-container">
                      <Typography
                        className={isDevelopmentHistory ? "symptom-text" : ""}
                        style={{ fontWeight: 600 }}
                      >
                        {isDevelopmentHistory ? isDevelopmentHistory : "Development History Summary"}
                      </Typography>
                      {isDevelopmentHistory !== '' && <Typography>
                        <IconButton onClick={() => setIsDevelopmentHistory("")} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                          <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                        </IconButton>
                      </Typography>}
                    </Box>
                  </Paper>
                  {isDevelopmentHistory ? (
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                        <Divider />
                        <Grid
                          container
                          item
                          spacing={2}
                          style={{ padding: 20 }}
                        >
                          {isDevelopmentHistory === 'Any concerns with development of child' &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Any concerns with development of child
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>}
                          {isDevelopmentHistory === "Any concerns with development of child" &&
                            // concernsWithDevelopmentOfChild &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>}
                         

                          {/* {isDevelopmentHistory === 'Gross motor milestones' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setGrossMotorMilestones(!grossMotorMilestones);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Gross motor milestones"
                              />
                            </FormGroup>
                          </Grid>} */}

                          {isDevelopmentHistory === "Gross motor milestones" &&
                            // grossMotorMilestones &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Gross motor milestones
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>}
                          {isDevelopmentHistory === "Gross motor milestones" &&
                            // grossMotorMilestones &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>}

                          {isDevelopmentHistory === "Gross motor milestones" &&
                            // grossMotorMilestones &&
                            <Grid item xs={12} sm={12} md={12}>
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid>}

                          {/* {isDevelopmentHistory === 'Fine Moter Milestones' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setFineMoterMilestones(!fineMoterMilestones);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Fine Moter Milestones"
                              />
                            </FormGroup>
                          </Grid>} */}

                          {isDevelopmentHistory === "Fine Moter Milestones" &&
                            // fineMoterMilestones &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Fine Moter Milestones
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>}
                            {isDevelopmentHistory === 'Fine Moter Milestones'
                           
                           ? <Grid item xs={12} sm={12} md={12} className="OPD-Upload-Button ">
                             <MultiUpload
                               onDropandSelect={saveLogoFileSelected}
                               editFile={documentsFiles}
                               MAX_COUNT={5}
                               onFileDownload={dowloadDocumentFile}
                               onFileRemove={(file) => {
                                 removeFile(file);
                               }}
                             />
                           </Grid> :
                           <Divider />
                         }

                          {isDevelopmentHistory === "Fine Moter Milestones" &&
                            fineMoterMilestones &&
                            <Grid item xs={12} sm={12} md={12}>
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid>}
                                
                          {/* {isDevelopmentHistory === 'Motor age of child in months' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setMotorAgeOfChildInMonths(!motorAgeOfChildInMonths);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Motor age of child in months"
                              />
                            </FormGroup>
                          </Grid>} */}

                          {isDevelopmentHistory === "Motor age of child" &&
                            // motorAgeOfChildInMonths &&
                            <Grid item xs={12} sm={12} md={3}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">Motor age of child</FormLabel>
                                <OutlinedInput
                                  id="outlined-adornment-weight"
                                  size="small"
                                  endAdornment={<InputAdornment position="end">(months)</InputAdornment>}
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    'aria-label': 'weight',
                                  }}
                                />
                              </FormControl>
                            </Grid>}
                          {isDevelopmentHistory === "Motor age of child" &&
                            // motorAgeOfChildInMonths &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">Note</FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>}

                          {/* {isDevelopmentHistory === 'Social Milestones' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setSocialMilestones(!socialMilestones);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Social Milestones" />
                            </FormGroup>
                          </Grid>} */}

                          {isDevelopmentHistory === "Social Milestones" &&
                            //  socialMilestones &&
                            <Grid item xs={8} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Social Milestones
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>}
                          {isDevelopmentHistory === "Social Milestones" &&
                            //  socialMilestones &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>}

                          {isDevelopmentHistory === "Social Milestones" &&
                            //  socialMilestones && 
                            <Grid item xs={12} sm={12} md={12}>
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid>}

                          {/* {isDevelopmentHistory === 'Language Milestones' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setLanguageMilestones(!languageMilestones);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Language Milestones"
                              />
                            </FormGroup>
                          </Grid>} */}

                          {isDevelopmentHistory === "Language Milestones" &&
                            // languageMilestones &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Language Milestones
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>}
                          {isDevelopmentHistory === "Language Milestones" &&
                            // languageMilestones &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>}

                          {isDevelopmentHistory === "Language Milestones" &&
                            //  languageMilestones &&
                            <Grid item xs={12} sm={12} md={12}>
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid>}

                          {/* {isDevelopmentHistory === 'Cognitive Milestones' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setCognitiveMilestones(!cognitiveMilestones);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Cognitive Milestones"
                              />
                            </FormGroup>
                          </Grid>} */}

                          {isDevelopmentHistory === "Cognitive Milestones" &&
                            //  cognitiveMilestones &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Cognitive Milestones
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>}
                          {isDevelopmentHistory === "Cognitive Milestones" &&
                            //  cognitiveMilestones &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>}

                          {isDevelopmentHistory === "Cognitive Milestones" &&
                            //  cognitiveMilestones &&
                            <Grid item xs={12} sm={12} md={12}>
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid>}

                          {/* {isDevelopmentHistory === 'Scholastic Performance of child' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setScholasticPerformanceOfChild(!scholasticPerformanceOfChild);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Scholastic Performance of child"
                              />
                            </FormGroup>
                          </Grid>} */}

                          {isDevelopmentHistory === "Scholastic Performance of child" &&
                            // scholasticPerformanceOfChild &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Scholastic Performance of child
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>}

                          {isDevelopmentHistory === "Scholastic Performance of child" &&
                            // scholasticPerformanceOfChild &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>}

                          {isDevelopmentHistory === "Scholastic Performance of child" &&
                            // scholasticPerformanceOfChild &&
                            <Grid item xs={12} sm={12} md={12}>
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid>}
                          {/* {isDevelopmentHistory === 'Mental age of chilld in months' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setMentalAgeOfChilldInMonths(!mentalAgeOfChilldInMonths);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Mental age of chilld in months"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {isDevelopmentHistory === "Mental age of chilld in months" &&
                            // mentalAgeOfChilldInMonths &&
                            <Grid item xs={12} sm={12} md={3}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">Mental age of chilld</FormLabel>

                                <OutlinedInput
                                  id="outlined-adornment-weight"
                                  size="small"
                                  endAdornment={<InputAdornment position="end">(months)</InputAdornment>}
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    'aria-label': 'weight',
                                  }}
                                />
                              </FormControl>
                            </Grid>}
                          {/* {isDevelopmentHistory === "Mental age of chilld in months" &&
                            // mentalAgeOfChilldInMonths &&
                            <Grid item xs={4} sm={2} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">Unit</FormLabel>
                                <CustomTextField
                                  name="branchName"
                                  placeholder=""
                                  autoCapitalize="none"
                                  type="number"
                                  fullWidth
                                  size="small"
                                />
                              </FormControl>
                            </Grid>} */}
                          {isDevelopmentHistory === "Mental age of chilld in months" &&
                            // mentalAgeOfChilldInMonths &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">Note</FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>}
                        </Grid>
                      </Box>
                    </Formik>
                  ) : (
                    <Box>  <Divider />
                       <Box className="summary-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
      fullScreen
      open={open}
      onClose={handleClickCloseDevelopmentHistory} 
      >
               <AppBar sx={{ position: 'relative' }}>
          <Toolbar 
            sx={{ display:"flex",
            justifyContent:"space-between"}}>
            <Typography
              className={selected ? "symptom-text" : ""}
              style={{ fontWeight: 600, margin: 1 }}
              sx={{
                paddingLeft:{
                  xs:1,
                  sm:0,
                  }
               }}
              variant="h6"
            >
          {isDevelopmentHistory ? isDevelopmentHistory : "Development History"}
            </Typography>
            <IconButton onClick={() => handleClickCloseDevelopmentHistory(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
              <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
            </IconButton>
          </Toolbar>
          <Divider />
        </AppBar>
                <DialogContent style={{ height: 400 }}>
            
                <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container"
                sx={{
                  border: "0px solid #0000001f",
                  minHeight: 400,
                  borderRadius: 1,
                }}
              >
                <Box className="inner-title-container">
                 
                  {isDevelopmentHistory ? (
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                       
                        <Grid
                          container
                          item
                          spacing={2}
                          
                        >
                          {isDevelopmentHistory === 'Any concerns with development of child' &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Any concerns with development of child
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>}
                          {isDevelopmentHistory === "Any concerns with development of child" &&
                            // concernsWithDevelopmentOfChild &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>}
                         

                          {/* {isDevelopmentHistory === 'Gross motor milestones' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setGrossMotorMilestones(!grossMotorMilestones);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Gross motor milestones"
                              />
                            </FormGroup>
                          </Grid>} */}

                          {isDevelopmentHistory === "Gross motor milestones" &&
                            // grossMotorMilestones &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Gross motor milestones
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>}
                          {isDevelopmentHistory === "Gross motor milestones" &&
                            // grossMotorMilestones &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>}

                          {isDevelopmentHistory === "Gross motor milestones" &&
                            // grossMotorMilestones &&
                            <Grid item xs={12} sm={12} md={12}>
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid>}

                          {/* {isDevelopmentHistory === 'Fine Moter Milestones' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setFineMoterMilestones(!fineMoterMilestones);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Fine Moter Milestones"
                              />
                            </FormGroup>
                          </Grid>} */}

                          {isDevelopmentHistory === "Fine Moter Milestones" &&
                            // fineMoterMilestones &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Fine Moter Milestones
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>}
                          {isDevelopmentHistory === "Fine Moter Milestones" &&
                            // fineMoterMilestones &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>}

                            {isDevelopmentHistory === 'Fine Moter Milestones'
                           
                           ? <Grid item xs={12} sm={12} md={6}>
                             <MultiUpload
                               onDropandSelect={saveLogoFileSelected}
                               editFile={documentsFiles}
                               MAX_COUNT={5}
                               onFileDownload={dowloadDocumentFile}
                               onFileRemove={(file) => {
                                 removeFile(file);
                               }}
                             />
                           </Grid> :
                           <Divider />
                         }

                          {/* {isDevelopmentHistory === 'Motor age of child in months' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setMotorAgeOfChildInMonths(!motorAgeOfChildInMonths);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Motor age of child in months"
                              />
                            </FormGroup>
                          </Grid>} */}

                          {isDevelopmentHistory === "Motor age of child" &&
                            // motorAgeOfChildInMonths &&
                            <Grid item xs={12} sm={12} md={3}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">Motor age of child</FormLabel>
                                <OutlinedInput
                                  id="outlined-adornment-weight"
                                  size="small"
                                  endAdornment={<InputAdornment position="end">(Months)</InputAdornment>}
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    'aria-label': 'weight',
                                  }}
                                />
                              </FormControl>
                            </Grid>}
                          {isDevelopmentHistory === "Motor age of child" &&
                            // motorAgeOfChildInMonths &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">Note</FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>}

                          {/* {isDevelopmentHistory === 'Social Milestones' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setSocialMilestones(!socialMilestones);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Social Milestones" />
                            </FormGroup>
                          </Grid>} */}

                          {isDevelopmentHistory === "Social Milestones" &&
                            //  socialMilestones &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Social Milestones
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>}
                          {isDevelopmentHistory === "Social Milestones" &&
                            //  socialMilestones &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>}

                          {isDevelopmentHistory === "Social Milestones" &&
                            //  socialMilestones && 
                            <Grid item xs={12} sm={12} md={12}>
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid>}

                          {/* {isDevelopmentHistory === 'Language Milestones' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setLanguageMilestones(!languageMilestones);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Language Milestones"
                              />
                            </FormGroup>
                          </Grid>} */}

                          {isDevelopmentHistory === "Language Milestones" &&
                            // languageMilestones &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Language Milestones
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>}
                          {isDevelopmentHistory === "Language Milestones" &&
                            // languageMilestones &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>}

                          {isDevelopmentHistory === "Language Milestones" &&
                            //  languageMilestones &&
                            <Grid item xs={12} sm={12} md={12}>
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid>}

                          {/* {isDevelopmentHistory === 'Cognitive Milestones' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setCognitiveMilestones(!cognitiveMilestones);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Cognitive Milestones"
                              />
                            </FormGroup>
                          </Grid>} */}

                          {isDevelopmentHistory === "Cognitive Milestones" &&
                            //  cognitiveMilestones &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Cognitive Milestones
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>}
                          {isDevelopmentHistory === "Cognitive Milestones" &&
                            //  cognitiveMilestones &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>}

                          {isDevelopmentHistory === "Cognitive Milestones" &&
                            //  cognitiveMilestones &&
                            <Grid item xs={12} sm={12} md={12}>
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid>}

                          {/* {isDevelopmentHistory === 'Scholastic Performance of child' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setScholasticPerformanceOfChild(!scholasticPerformanceOfChild);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Scholastic Performance of child"
                              />
                            </FormGroup>
                          </Grid>} */}

                          {isDevelopmentHistory === "Scholastic Performance of child" &&
                            // scholasticPerformanceOfChild &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Scholastic Performance of child
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>}

                          {isDevelopmentHistory === "Scholastic Performance of child" &&
                            // scholasticPerformanceOfChild &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>}

                          {isDevelopmentHistory === "Scholastic Performance of child" &&
                            // scholasticPerformanceOfChild &&
                            <Grid item xs={12} sm={12} md={12}>
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid>}
                          {/* {isDevelopmentHistory === 'Mental age of chilld in months' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setMentalAgeOfChilldInMonths(!mentalAgeOfChilldInMonths);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Mental age of chilld in months"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {isDevelopmentHistory === "Mental age of chilld in months" &&
                            // mentalAgeOfChilldInMonths &&
                            <Grid item xs={12} sm={12} md={3}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">Mental age of chilld</FormLabel>

                                <OutlinedInput
                                  id="outlined-adornment-weight"
                                  size="small"
                                  endAdornment={<InputAdornment position="end">(months)</InputAdornment>}
                                  aria-describedby="outlined-weight-helper-text"
                                  inputProps={{
                                    'aria-label': 'weight',
                                  }}
                                />
                              </FormControl>
                            </Grid>}
                          {/* {isDevelopmentHistory === "Mental age of chilld in months" &&
                            // mentalAgeOfChilldInMonths &&
                            <Grid item xs={4} sm={2} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">Unit</FormLabel>
                                <CustomTextField
                                  name="branchName"
                                  placeholder=""
                                  autoCapitalize="none"
                                  type="number"
                                  fullWidth
                                  size="small"
                                />
                              </FormControl>
                            </Grid>} */}
                          {isDevelopmentHistory === "Mental age of chilld in months" &&
                            // mentalAgeOfChilldInMonths &&
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">Note</FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>}
                        </Grid>
                      </Box>
                    </Formik>
                  ) : (
                    <Box>  <Divider />
                      <Box>This is summary section </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
                          
                </DialogContent>
            </Dialog>

            <Dialog
            fullScreen
            open={openDevelopmentSummary}
            onClose={handleClickCloseDevelopmentSummary}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
                <Typography
                  // className={selected ? "symptom-text" : ""}
                  style={{ fontWeight: 600, marginLeft: 0 }}
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                >
                Development History Summary
                </Typography>
                <IconButton onClick={() => handleClickCloseDevelopmentSummary(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                  <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent style={{ height: 400 }}>
            <Box className="template-table-container summary-dialog-mainContainer">
                      <Box className="summary-dialog-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
            </Box>
            </DialogContent>
          </Dialog>
    </Box>
  );
}
