import "../../../../../../theme/style.scss";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import { Formik } from "formik";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import { MultiUpload } from "../../../../../../components/MultiUpload/MultiUpload";
import ReactQuill from "react-quill";
import Siblings from "./Components/Siblings";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import HistoryOfSimilarIllness from "./Components/HistoryOfSimilarIllness";
import { CustomSelect } from "../../../../../../components/CustomFields/CustomSelect";
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import useMediaQuery from "@mui/material/useMediaQuery";
import SubjectRoundedIcon from "@mui/icons-material/SubjectRounded";
import DraggableList from "../../../../../OPD/Components/DraggableTable/DraggableList";
import { reorder } from "../../../../../../Helpers/helpers";
import { DropResult } from 'react-beautiful-dnd';
import DialogActions from '@mui/material/DialogActions';
import DatePickerCompponent from "../../../../../../components/CustomFields/DatePicker";
import MedicinePrescription from "../../Diagnosis/MedicineComponent/MedicinePrescription";
import LoadingButton from "@mui/lab/LoadingButton";

const addmissionState = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
]

const gender = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
]

const liveState = [
  { value: 'live', label: 'Live' },
  { value: 'deceased', label: 'Deceased' },
]

const  durationState = [

  { value: 'days', label: 'Days' },
  { value: 'weeks', label: 'Weeks' },
  { value: 'months', label: 'Months' },
  { value: 'year', label: 'Year' },
]

const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#FAFBFD",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function FamilyHistory() {
  const [showNote, setShowNote] = useState(false);
  const [documentsFiles, setDocumentsFile] = useState<any>([]);
  const [isFamilyHistory, setIsFamilyHistory] = useState("");
  const [familyHistoryOfSimilarIllness, setFamilyHistoryOfSimilarIllness] = useState(false);
  const [numberOfFamilyMembers, setNumberOfFamilyMembers] = useState(false);
  const [consaguinousMarriageByParents, setConsaguinousMarriageByParents] = useState(false);
  const [birthOrder, setBirthOrder] = useState(false);
  const [siblings, setSiblings] = useState(false);
  const [anyOtherSignificant, setAnyOtherSignificant] = useState(false);
  const [selected, setSelected] = useState("");
  const smallScreen = useMediaQuery("(max-width:768px)");
  const mobileScreen = useMediaQuery("(max-width:425px)");


  const [itemsDraggable, setItemsDraggable] = React.useState([
    {
      id: "Item 1",
      primary: "Practical Rubber Computer1",
      secondary: "Synergistic leading edge matrices"
    }, {
      id: "Item 2",
      primary: "Practical Rubber Computer2",
      secondary: "Synergistic leading edge matrices"
    }, {
      id: "Item 3",
      primary: "Practical Rubber Computer3",
      secondary: "Synergistic leading edge matrices"
    }
  ]);
  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(itemsDraggable, source.index, destination.index);

    setItemsDraggable(newItems);
  };

  // const [selected, setSelected] = useState("");
  //Draggable Table
  const [items, setItems] = React.useState([
    {
      id: "Item 1",
      symptom: "Family History of similar illness",
    },
    {
      id: "Item 2",
      symptom: "Number of family members",
    },
    {
      id: "Item 3",
      symptom: "History of consaguinous marriage by parents",
    },
    {
      id: "Item 4",
      symptom: "Birth Order",
    },
    {
      id: "Item 5",
      symptom: "Siblings",
    },
    {
      id: "Item 6",
      symptom:
        "Any other significant Family History relavant to present condition",
    },
  ]);
  const [open, setOpen] = React.useState(false);

  const handleOpenFamilyHistory = (value: boolean) => {
    if(smallScreen){
      
      setOpen(value);
    }else if(mobileScreen){
      setOpen(value);
      
    }
  };

  const handleCloseFamilyHistory = () => {
      setOpen(false);
  };

  // Dialog Box
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpen = (value: boolean) => {
    setOpenDialog(value);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };


  const saveLogoFileSelected = (files: any) => {
    setDocumentsFile(files);
  };
  const dowloadDocumentFile = (files) => { };

  const removeFile = (file) => { };

  const [countryData, setCountry] = useState([
    { countryID: 0, code: "", name: "" },
    { countryID: 1, code: "123", name: "India" },
    { countryID: 2, code: "456", name: "USA" },
  ]);

  // Dialog Box
  const [openFamilyDialog, setOpenFamilyDialog] = React.useState(false);

  const handleClickOpenFamilyDialog = (value: boolean) => {
    setOpenFamilyDialog(value);
  };

  const handleCloseFamilyDialog = () => {
    setOpenFamilyDialog(false);
  };

  const [openFamilySummary, setopenFamilySummary] = React.useState(false);

  const handleClickOpenFamilySummary = (value: boolean) => {
    if (mobileScreen) {
      setopenFamilySummary(value);
    }
    else if (smallScreen) {
      setopenFamilySummary(value);
    }
  };

  const handleClickCloseFamilySummary = () => {
    setopenFamilySummary(false);
  };

  
  const [openDialogMedicine, setOpenDialogMedicine] = React.useState(false);
  const onHandleOpenDialogMedicine = (item: Item) => {

    handleClickOpenDialogMedicine(true);
    console.log("Opening dialog for item:", item);
  };
  const handleClickOpenDialogMedicine = (value: boolean) => {
    setOpenDialogMedicine(value);
  };

  const handleCloseDialogMedicine = () => {
    setOpenDialogMedicine(false);
  };

  return (
    <Box className="main-container">
      <Grid container spacing={0}>
        <Grid item xs={12} mb={2}>
          <Grid container item spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                className="template-table-container"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                }}
              >
                <Box
                  className="inner-title-container"
                >
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box className="inner-header-container"
                    sx={{display:"flex", justifyContent:"space-between"
                    }}>
                      <Typography style={{ fontWeight: 600 }}>
                        Family History
                      </Typography>
                      {mobileScreen || smallScreen ? <Box>
                      
                      <Button onClick={() => handleClickOpenFamilySummary(true)} sx={{ padding: 0, margin: 0, minWidth:"30px" }} aria-label="delete">
                       <SubjectRoundedIcon></SubjectRoundedIcon>
                      </Button>
          
            </Box> : ''}
                    </Box>
                  </Paper>
                  <Divider />
                  <List>
                    {items.map((item: any, index: number) => (
                      <ListItem
                        sx={{
                          borderBottom: "1px solid #E9EBEC",
                          cursor: 'pointer'
                        }}
                        className={
                          isFamilyHistory === item.symptom
                            ? "draggable-list-item"
                            : " "
                        }
                      >
                        <Grid container item spacing={2}>
                          <Grid item xs={12} sm={12} md={12}>
                            <Box
                              style={{
                                display: "flex",
                              }}
                              onClick={()=> {handleOpenFamilyHistory(true)}}
                            >
                              <Grid container item>
                                <Grid item xs={12} sm={12} md={12}>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() => {
                                      setIsFamilyHistory(item.symptom)
                                    }}
                                  >
                                    <Box
                                      className={
                                        isFamilyHistory === item.symptom
                                          ? "symptom-text"
                                          : " "
                                      }
                                      sx={{ paddingBottom: '8px', paddingTop: '8px' }}
                                   
                                      >
                                      <Typography>{item.symptom}</Typography>
                                    </Box>
                                    {/* <Box>
                                      <FormGroup>
                                        <FormControlLabel
                                          className={
                                            isFamilyHistory === item.symptom
                                              ? "symptom-text"
                                              : " "
                                          }
                                          control={
                                            <Checkbox
                                              onChange={(e) => {
                                                setIsFamilyHistory(
                                                  e.target.value
                                                );
                                                console.log(
                                                  "checkbox data:",
                                                  e.target.value
                                                );
                                              }}
                                              value={item.symptom}
                                            />
                                          }
                                          label={item.symptom}
                                        />
                                      </FormGroup>
                                    </Box> */}
                                    {isFamilyHistory === item.symptom && (
                                      <Box
                                        style={{
                                          paddingTop: "8px",
                                          paddingLeft: '8px'
                                        }}
                                      >
                                        <KeyboardDoubleArrowRightRoundedIcon />
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                  </List>
                  {/* <Box
                    className="search-container-draggable"
                  >
                    <FormControl fullWidth>
                      <CustomTextField
                        name="templateName"
                        placeholder="Search..."
                        autoCapitalize="none"
                        type="text"
                        fullWidth
                        size="small"
                      />
                    </FormControl>
                  </Box>
                  <Divider /> */}
                  <Box className="note-container">
                    <Box>
                      {!showNote && (
                        <Button
                          variant="text"
                          style={{ fontSize: "6px" }}
                          onClick={() => {
                            setShowNote(true);
                          }}
                        >
                          <AddIcon className="add-icon" />
                          <span className="add-icon"> Note</span>
                        </Button>
                      )}
                    </Box>

                    {showNote && (
                      <FormControl fullWidth>
                        <Box
                          className='note-container-box'
                        >
                          <FormLabel
                            sx={{ paddingLeft: 1 }}
                            className="fileds-label-text"
                          >
                            Note
                          </FormLabel>
                          <Button
                            variant="text"
                            onClick={() => {
                              setShowNote(false);
                            }}
                          >
                            <RemoveIcon className="remove-note" />
                            <span className="remove-note">Remove Note</span>
                          </Button>
                        </Box>
                        <ReactQuill
                          className="react-quill-alignment"
                          theme="snow"
                          value={""}
                          onChange={(e) => { }}
                        />
                      </FormControl>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container template-hide-small-screen template-hide-medium-screen"
                sx={{
                  border: "1px solid #0000001f",
                  minHeight: 400,
                  borderRadius: 1,
                }}
              >
                <Box
                  className="inner-title-container"
                >
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    {/* <Box className="inner-header-container">
                      <Typography
                        className="symptom-text"
                        style={{ fontWeight: 600 }}
                      >
                        {isFamilyHistory ? isFamilyHistory : "Family History"}
                      </Typography>
                    </Box> */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="inner-header-container">
                      <Typography
                        className={isFamilyHistory ? "symptom-text" : ""}
                        style={{ fontWeight: 600 }}
                      >
                        {isFamilyHistory ? isFamilyHistory : "Family History Summary"}
                      </Typography>
                      {isFamilyHistory !== '' && <Typography>
                        <IconButton onClick={() => setIsFamilyHistory("")} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                          <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                        </IconButton>
                      </Typography>}
                    </Box>
                  </Paper>
                  {isFamilyHistory ? (
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                        <Divider />
                        <Grid container item spacing={2} style={{ padding: 20 }}>
                          {/* {isFamilyHistory === 'Family History of similar illness' &&
                            <Grid item xs={4} sm={2} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      setFamilyHistoryOfSimilarIllness(!familyHistoryOfSimilarIllness);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Family History of similar illness"
                                />s
                              </FormGroup>
                            </Grid>} */}
                          {isFamilyHistory === 'Family History of similar illness' &&
                            <Grid item xs={12} sm={12} md={12}>
                              <Button variant="contained" onClick={() => handleClickOpenFamilyDialog(true)} >Add Family History of similar illness</Button>
                            </Grid>}
                          {isFamilyHistory === 'Family History of similar illness'
                            // && familyHistoryOfSimilarIllness 
                            ? <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">Note</FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> :
                            <Divider />
                          }
                          {isFamilyHistory === 'Family History of similar illness'
                            //  && familyHistoryOfSimilarIllness 
                            ? <Grid item xs={12} sm={12} md={6} className="OPD-Upload-Button ">
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid> :
                            <Divider />
                          }
                          {/* {isFamilyHistory === 'Number of family members' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setNumberOfFamilyMembers(!numberOfFamilyMembers);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Number of family members"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {isFamilyHistory === 'Number of family members'
                            // && numberOfFamilyMembers
                            ? <Grid item xs={12} sm={12} md={6}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">No. of Family Members</FormLabel>
                                <CustomTextField
                                  name=" "
                                  placeholder=""
                                  autoCapitalize="none"
                                  type="number"
                                  fullWidth
                                  size="small"
                                />
                              </FormControl>
                            </Grid> :
                            <Divider />
                          }
                          {isFamilyHistory === 'Number of family members'
                            // && numberOfFamilyMembers 
                            ? <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">Note</FormLabel>
                              <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> :
                            <Divider />
                          }
                          {/* {isFamilyHistory === 'Number of family members' && numberOfFamilyMembers ? <Grid item xs={4} sm={2} md={6}>
                            <MultiUpload
                              onDropandSelect={saveLogoFileSelected}
                              editFile={documentsFiles}
                              MAX_COUNT={5}
                              onFileDownload={dowloadDocumentFile}
                              onFileRemove={(file) => {
                                removeFile(file);
                              }}
                            />
                          </Grid> :
                            <Divider />
                          } */}
                          {/* {isFamilyHistory === 'History of consaguinous marriage by parents'
                            && <Grid item xs={4} sm={2} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      setConsaguinousMarriageByParents(!consaguinousMarriageByParents);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="History of consaguinous marriage by parents"
                                />
                              </FormGroup>
                            </Grid>} */}
                          {isFamilyHistory === 'History of consaguinous marriage by parents'
                            //  && consaguinousMarriageByParents 
                            ? <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">Note</FormLabel>
                              <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> :
                            <Divider />
                          }
                          {/* {isFamilyHistory === 'Birth Order' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setBirthOrder(!birthOrder);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Birth Order"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {isFamilyHistory === 'Birth Order'
                            // && birthOrder 
                            ? <Grid item xs={12} sm={12} md={3}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">Birth Order No.</FormLabel>
                                <CustomTextField
                                  name=" "
                                  placeholder=""
                                  autoCapitalize="none"
                                  type="number"
                                  fullWidth
                                  size="small"
                                />
                              </FormControl>
                            </Grid> :
                            <Divider />
                          }
                          {isFamilyHistory === 'Birth Order'
                            // && birthOrder 
                            ? <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">Note</FormLabel>
                              <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> :
                            <Divider />
                          }
                          {/* {isFamilyHistory === 'Birth Order' && birthOrder ? <Grid item xs={4} sm={2} md={6}>
                            <MultiUpload
                              onDropandSelect={saveLogoFileSelected}
                              editFile={documentsFiles}
                              MAX_COUNT={5}
                              onFileDownload={dowloadDocumentFile}
                              onFileRemove={(file) => {
                                removeFile(file);
                              }}
                            />
                          </Grid> :
                            <Divider />
                          } */}
                          {/* {isFamilyHistory === 'Siblings' && <Grid item xs={4} sm={2} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  onChange={(e) => {
                                    setSiblings(!siblings);
                                    console.log(
                                      "checkbox data:",
                                      e.target.value
                                    );
                                  }}
                                // value={item.symptom}
                                />}
                                label="Siblings"
                              />
                            </FormGroup>
                          </Grid>} */}
                          {isFamilyHistory === 'Siblings'
                            //  && siblings 
                            ? <Grid item xs={12} sm={12} md={12}>
                              <Button variant="contained" onClick={() => handleClickOpen(true)} >Add Siblings</Button>
                            </Grid> :
                            <Divider />
                          }
                          {isFamilyHistory === 'Siblings'
                            // && siblings 
                            ? <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">Note</FormLabel>
                              <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> :
                            <Divider />
                          }
                          {isFamilyHistory === 'Siblings'
                            //  && siblings
                            ? <Grid item xs={12} sm={12} md={6} className="OPD-Upload-Button ">
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid> :
                            <Divider />
                          }
                          {isFamilyHistory === 'Any other significant Family History relavant to present condition' &&
                           <Grid item xs={12} sm={12} md={6}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Any other significant Family History relavant to present condition
                              </FormLabel>
                              <CustomSelect
                                isNotCreatable={true}
                                id={"admission"}
                                FieldName={"admission"}
                                options={addmissionState}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e);
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}

                          <Grid item xs={12} sm={2} md={6}/>
                          {isFamilyHistory === 'Any other significant Family History relavant to present condition'
                            // && anyOtherSignificant 
                            ? <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                              <FormLabel className="fileds-label-text" >Note</FormLabel>
                              <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> :
                            <Divider />
                          }
                          {/* {isFamilyHistory === 'Any other significant Family History relavant to present condition' && anyOtherSignificant ? <Grid item xs={4} sm={2} md={6}>
                            <MultiUpload
                              onDropandSelect={saveLogoFileSelected}
                              editFile={documentsFiles}
                              MAX_COUNT={5}
                              onFileDownload={dowloadDocumentFile}
                              onFileRemove={(file) => {
                                removeFile(file);
                              }}
                            />
                          </Grid> :
                            <Divider />
                          } */}
                        </Grid>
                      </Box>
                    </Formik>
                  ) : (
                    <Box>  <Divider />
                      <Box className="summary-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        className="dialog"
        open={openDialog}
        onClose={handleClose}
        fullScreen
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "1200px", // Adjust the width as needed
            maxWidth: "none",
          },
        }}
      >
         <AppBar sx={{ position: 'relative' }}>
              <Toolbar
                sx={{ display:"flex",
                  justifyContent:"space-between"}}>
                <Typography
                  // className={selected ? "symptom-text" : ""}
                  style={{ fontWeight: 600, marginLeft: 1 }}
                  sx={{
                    paddingLeft:{
                      xs:1,
                      sm:0,
                    }
                  }}
                  variant="h6"
                >
             Add siblings
                </Typography>
               
              </Toolbar>
            </AppBar>
        <DialogContent style={{ height: 400 }}>
          {/* <HistoryOfSimilarIllness /> */}
          <Box className="main-container" pl={2}>
     
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
        }}
        onSubmit={async (values) => {
          console.log("values", values)
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} mb={3} className="group-container">
            <Grid container item spacing={2}>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Birth order </FormLabel>
                  <CustomTextField
                    name=" "
                    placeholder=""
                    autoCapitalize="none"
                    type="number"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div >
                  <Box style={{ fontSize: 12 }}> DOB</Box>
                  <FormControl sx={{ width: '100% !important' }}>
                    <DatePickerCompponent
                      format="DD-MM-YYYY"
                      mode="date"
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </FormControl>
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                    <FormLabel className="fileds-label-text">Live / Deceased</FormLabel>
                    <CustomSelect
                      FieldName={"state"}
                      id={"state"}
                      options={liveState}
                      isNotCreatable={true}
                      openMenuOnFocus
                      isClearable
                      isSearchable
                      menuPortalTarget={document.querySelector("body")}
                      handleChange={(e) => {
                        console.log("event:", e)
                      }}
                      Value=""
                    />
                  </FormControl>
             
              </Grid>
              <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <FormLabel className="fileds-label-text">Gender</FormLabel>
                    <CustomSelect
                      isNotCreatable={true}
                      id={"gender"}
                      FieldName={"gender"}
                      options={gender}
                      openMenuOnFocus
                      isClearable
                      isSearchable
                      menuPortalTarget={document.querySelector("body")}
                      handleChange={(e) => {
                        console.log("event:", e)
                      }}
                      Value=""
                    />
                  </FormControl>
                </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Health condition</FormLabel>
                  <CustomTextField
                    name=" "
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
           
            </Grid>
          </Grid>
      
        </Grid>

      </Formik>

    </Box>
        </DialogContent>
        <DialogActions>
        <LoadingButton
            color="primary"
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="contained"
            type="submit"
            className="primary-button"
            name="submit"
            onClick={()=>{
              handleClose()
            }}
          >
            Save
          </LoadingButton>
          <LoadingButton
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="outlined"
            className="secondary-button"
            color="secondary"
            onClick={()=>{
              handleClose()
            }}
          >
            Cancel
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
      className="dialog"
        open={openFamilyDialog}
        onClose={handleCloseFamilyDialog}
        fullScreen
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "1200px", // Adjust the width as needed
            maxWidth: "none",
          },
        }}
      >
         <AppBar sx={{ position: 'relative' }}>
              <Toolbar
              sx={{ display:"flex",
                justifyContent:"space-between"}}
              >
                <Typography
                  // className={selected ? "symptom-text" : ""}
                  style={{ fontWeight: 600, marginLeft:1 }}
                  sx={{
                    paddingLeft:{
                      xs:1,
                      sm:0,
                    }
                  }}
                  variant="h6"
                >
             Add family history of similar illness
                </Typography>
               
              </Toolbar>
            </AppBar>
        <DialogContent>
        <Box className="main-container" pl={2}>
   
   <Formik
     initialValues={{
       firstName: '',
       lastName: '',
       email: '',
     }}
     onSubmit={async (values) => {
       console.log("values", values)
     }}
   >
     <Grid container spacing={2} sx={{marginRight:{
        xs:-2,
        sm:0
       }}}>
       <Grid item xs={12} mb={3} className="group-container" >
         <Grid container item spacing={2}>

           <Grid item xs={4} sm={4}>
             <FormControl fullWidth>
               <FormLabel className="fileds-label-text">Duration of last episode</FormLabel>
               <CustomTextField
                 name=" "
                 placeholder=""
                 autoCapitalize="none"
                 type="number"
                 fullWidth
                 size="small"
               />
             </FormControl>
           </Grid>
           <Grid item xs={6} sm={6}>
             <FormControl fullWidth>
               <FormLabel className="fileds-label-text">Unit of duration</FormLabel>
               
               <CustomSelect
                          // isNotCreatable={true}
                          id={"duration"}
                          FieldName={"duration"}
                          options={durationState}
                          openMenuOnFocus
                          isClearable
                          isSearchable
                          menuPortalTarget={document.querySelector("body")}
                          handleChange={(e) => {
                            console.log("event:", e)
                          }}
                          Value=""
                        />
             </FormControl>
           </Grid>
      
           <Grid item xs={4} sm={4}>
           <FormControl fullWidth>
               <FormLabel className="fileds-label-text">Time taken to relieve</FormLabel>
              
               <CustomTextField
                    name=" "
                    placeholder=""
                    autoCapitalize="none"
                    type="number"
                    fullWidth
                    size="small"
                  />
               </FormControl>
           </Grid>
           <Grid item xs={6} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Unit of duration</FormLabel>
                  
                  <CustomSelect
                          // isNotCreatable={true}
                          id={"duration"}
                          FieldName={"duration"}
                          options={durationState}
                          openMenuOnFocus
                          isClearable
                          isSearchable
                          menuPortalTarget={document.querySelector("body")}
                          handleChange={(e) => {
                            console.log("event:", e)
                          }}
                          Value=""
                        />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
           <Box
             className="template-table-container"
             style={{
               display: 'flex',
               flexWrap: 'wrap',
               border: '1px solid #0000001f',
               borderRadius: 4
             }}
           >
             <Box
               style={{
                 flex: 1,
                 borderRadius: 8
               }}>
               <Box
                 className='template-box'
               >
                 <Typography>
                     Detail of medicine taken
                 </Typography>
               </Box>
               <Divider />
               <DraggableList
                 items={itemsDraggable}
                 onDragEnd={onDragEnd}
                 onHandleOpenDialog={onHandleOpenDialogMedicine}
               />
             </Box>
           </Box>
         </Grid>
           <Grid item xs={12} sm={12}>
             <FormControl fullWidth>
               <FormLabel className="fileds-label-text">Required Admission?</FormLabel>
              
                 <CustomSelect
                   isNotCreatable={true}
                   id={"admission"}
                   FieldName={"admission"}
                   options={addmissionState}
                   openMenuOnFocus
                   isClearable
                   isSearchable
                   menuPortalTarget={document.querySelector("body")}
                   handleChange={(e) => {
                     console.log("event:", e)
                   }}
                   Value=""
                 />
               </FormControl>
           
           </Grid>
         </Grid>
       </Grid>
     
     </Grid>

   </Formik>

 </Box>
        </DialogContent>
        <DialogActions>
        <LoadingButton
            color="primary"
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="contained"
            type="submit"
            className="primary-button"
            name="submit"
            onClick={()=>{
              handleCloseFamilyDialog()
            }}
          >
            Save
          </LoadingButton>
          <LoadingButton
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="outlined"
            className="secondary-button"
            color="secondary"
            onClick={()=>{
              handleCloseFamilyDialog()
            }}
          >
            Cancel
          </LoadingButton>
        </DialogActions>
      </Dialog>
          
      <Dialog
                fullScreen
                open={open}
                onClose={handleCloseFamilyHistory}
            >
               <AppBar sx={{ position: 'relative' }}>
          <Toolbar
           sx={{ display:"flex",
            justifyContent:"space-between"}}>
            <Typography
              className={selected ? "symptom-text" : ""}
              style={{ fontWeight: 600, marginLeft: 1 }}
              sx={{
                paddingLeft:{
                  xs:1,
                  sm:0,
                }
              }}
              variant="h6"
            >
       {isFamilyHistory ? isFamilyHistory : "Family History"}
            </Typography>
            <IconButton onClick={() => handleCloseFamilyHistory(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
              <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
            </IconButton>
          </Toolbar>
          <Divider />
        </AppBar>
                <DialogContent style={{ height: 400 }}>
              
                <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container"
                sx={{
                  border: "0px solid #0000001f",
                  minHeight: 400,
                  borderRadius: 1,
                }}
              >
                <Box
                  className="inner-title-container"
                >
                 
                  {isFamilyHistory ? (
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                    
                        <Grid container item spacing={2} >
                        
                          {isFamilyHistory === 'Family History of similar illness' &&
                            <Grid item xs={12} sm={12} md={12}>
                              <Button variant="contained" onClick={() => handleClickOpenFamilyDialog(true)} >Add Family History of similar illness</Button>
                            </Grid>}
                          {isFamilyHistory === 'Family History of similar illness'
                            // && familyHistoryOfSimilarIllness 
                            ? <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">Note</FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> :
                            <Divider />
                          }
                          {isFamilyHistory === 'Family History of similar illness'
                            //  && familyHistoryOfSimilarIllness 
                            ? <Grid item xs={12} sm={12} md={6}>
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid> :
                            <Divider />
                          }
                         
                          {isFamilyHistory === 'Number of family members'
                            // && numberOfFamilyMembers
                            ? <Grid item xs={12} sm={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">No. of Family Members</FormLabel>
                                <CustomTextField
                                  name=" "
                                  placeholder=""
                                  autoCapitalize="none"
                                  type="number"
                                  fullWidth
                                  size="small"
                                />
                              </FormControl>
                            </Grid> :
                            <Divider />
                          }
                          {isFamilyHistory === 'Number of family members'
                            // && numberOfFamilyMembers 
                            ? <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">Note</FormLabel>
                              <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> :
                            <Divider />
                          }
                         
                          {isFamilyHistory === 'History of consaguinous marriage by parents'
                            //  && consaguinousMarriageByParents 
                            ? <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">Note</FormLabel>
                              <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> :
                            <Divider />
                          }
                         
                          {isFamilyHistory === 'Birth Order'
                            // && birthOrder 
                            ? <Grid item xs={12} sm={12} md={3}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">Birth Order No.</FormLabel>
                                <CustomTextField
                                  name=" "
                                  placeholder=""
                                  autoCapitalize="none"
                                  type="number"
                                  fullWidth
                                  size="small"
                                />
                              </FormControl>
                            </Grid> :
                            <Divider />
                          }
                          {isFamilyHistory === 'Birth Order'
                            // && birthOrder 
                            ? <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">Note</FormLabel>
                              <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> :
                            <Divider />
                          }
                         
                          {isFamilyHistory === 'Siblings'
                            //  && siblings 
                            ? <Grid item xs={12} sm={12} md={12}>
                              <Button variant="contained" onClick={() => handleClickOpen(true)} >Add Siblings</Button>
                            </Grid> :
                            <Divider />
                          }
                          {isFamilyHistory === 'Siblings'
                            // && siblings 
                            ? <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">Note</FormLabel>
                              <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> :
                            <Divider />
                          }
                          {isFamilyHistory === 'Siblings'
                            //  && siblings
                            ? <Grid item xs={12} sm={12} md={6}>
                              <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                            </Grid> :
                            <Divider />
                          }
                          {isFamilyHistory === 'Any other significant Family History relavant to present condition' &&
                           <Grid item xs={12} sm={12} md={6}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Any other significant Family History relavant to present condition
                              </FormLabel>
                              <CustomSelect
                                isNotCreatable={true}
                                id={"admission"}
                                FieldName={"admission"}
                                options={addmissionState}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e);
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>}

                          <Grid item xs={12} sm={2} md={6}/>
                          {isFamilyHistory === 'Any other significant Family History relavant to present condition'
                            // && anyOtherSignificant 
                            ? <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                              <FormLabel className="fileds-label-text" >Note</FormLabel>
                              <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid> :
                            <Divider />
                          }
                        
                        </Grid>
                      </Box>
                    </Formik>
                  ) : (
                    <Box>  <Divider />
                      <Box>This is summary section </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
                          
                </DialogContent>
            </Dialog>

              {/* Past Summary Section */}
        <Dialog
            fullScreen
            open={openFamilySummary}
            onClose={handleClickCloseFamilySummary}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
                <Typography
                  // className={selected ? "symptom-text" : ""}
                  style={{ fontWeight: 600, marginLeft: 0 }}
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                >
             Family History Summary
                </Typography>
                <IconButton onClick={() => handleClickCloseFamilySummary(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                  <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent style={{ height: 400 }}>
            <Box className="template-table-container summary-dialog-mainContainer">
                      <Box className="summary-dialog-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
            </Box>
            </DialogContent>
          </Dialog>

          
      <Dialog
        className="dialog"
        open={openDialogMedicine}
        onClose={handleCloseDialogMedicine}
        fullScreen
      >
          <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography
              // className={selected ? "symptom-text" : ""}
              style={{ fontWeight: 600, marginLeft: 1 }}
              sx={{
                paddingLeft: {
                  xs: 1,
                  sm: 0,
                }
              }}
             
              variant="h6"
            >
              Medicine Edit
            </Typography>
          
          </Toolbar>
        </AppBar>
        <Divider />
        <DialogContent>
        <MedicinePrescription/>
        </DialogContent>
        <DialogActions>
        <LoadingButton
            color="primary"
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="contained"
            type="submit"
            className="primary-button"
            name="submit"
            onClick={()=>{
              handleCloseDialogMedicine()
            }}
          >
            Save
          </LoadingButton>
          <LoadingButton
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="outlined"
            className="secondary-button"
            color="secondary"
            onClick={()=>{
              handleCloseDialogMedicine()
            }}
          >
            Cancel
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
