import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Box } from '@mui/material';
export default function CustomTimePicker() {

    // const useStylesD = makeStyles((theme) => ({
    //     noIconButton: {
    //         '& .MuiInputAdornment-root ': {

    //         },
    //     },
    // }));
    // const classes: any = useStylesD();
    return (
        <Box sx={{ marginTop: '0px' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}  >
                <DemoContainer components={['TimePicker']} sx={{ padding: 0 }}>
                    <TimePicker
                        label="Select"
                        slotProps={{ textField: { size: 'small'  } }}
                    />
                </DemoContainer>
            </LocalizationProvider>
        </Box>
    )
}
