import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { PreferencesContext } from "../../PreferenceContext";
import { useContext } from "react";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Alerts(props: {
  isOpen: boolean;
  displayMessage: string;
  type: string;
}) {
  const { userContext, updateUserContext } = useContext(PreferencesContext);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    updateUserContext({
      ...userContext,
      isAlert: false,
      alertMessage: "",
      alertType: "success",
    });
  };

  return (
    <>
      <Snackbar
        open={props.isOpen}
        autoHideDuration={4000}
        onClose={handleClose}
        sx={{ bottom: "35px" }}
      >
        <Alert
          onClose={handleClose}
          //@ts-ignore
          severity={`${props.type}`}
          sx={{ width: "100%" }}
        >
          {props.displayMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
