// import { Box, Button } from "@mui/material";
// import { isEmpty, reject } from "lodash";
// import React, { useState, useCallback, useEffect } from "react";
// import { useDropzone } from "react-dropzone";
// import CloseIcon from "@mui/icons-material/Close";
// import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// const getFormattedFileSize = (bytes, decimals = 2) => {
//     if (bytes === 0) return "0 Bytes";

//     const k = 1024;
//     const dm = decimals < 0 ? 0 : decimals;
//     const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

//     const i = Math.floor(Math.log(bytes) / Math.log(k));

//     return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
// };
// const allowedFileTypes = [
//     // excel >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
//     "application/vnd.ms-excel",
//     "application/vnd.ms-excel.sheet.macroEnabled.12",
//     "application/msexcel",
//     "application/x-msexcel",
//     "application/x-ms-excel",
//     "application/x-excel",
//     "application/x-dos_ms_excel",
//     "application/xls",
//     "application/x-xls",
//     "application/x-msi",
//     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",

//     //CSV>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
//     ".csv",
//     "text/csv",

//     'application/pdf',
//     'application/json',
//     'image/jpeg',
//     'image/png',
//     'text/plain',
//     'application/msword',
//     'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
// ];

// export const MultiUpload = ({ selectedFiles }) => {
//     const [myFiles, setMyFiles] = useState([]);

//     const onDrop = useCallback(
//         (acceptedFiles) => {
//             if (myFiles?.length === 5) return
//             const files = [...myFiles, ...acceptedFiles];
//             setMyFiles(files);
//         },
//         [myFiles]
//     );
//     const customvalidator: any = (file) => {
//         const MaxSize = 5;

//         if (file.size / 1024 / 1204 > MaxSize) {
//             return {
//                 code: "size-too-large",
//                 message: `Max allowed file size is 5 MB.`,
//             };
//         }
//         if (!allowedFileTypes.includes(file.type)) {
//             return {
//                 code: "invalid-type",
//                 message: `Please upload only Excel Files (supported types: xlsx, xls or csv only).`,
//             };
//         }
//         return null;
//     };
//     const { getRootProps, getInputProps, fileRejections } = useDropzone({
//         onDrop,
//         validator: customvalidator,
//         multiple: true,
//         accept: {
//             'application/pdf': ['.pdf'],
//             'application/json': ['.json'],
//             'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
//             "application/vnd.ms-excel": [".xls"],
//             "text/csv": [".csv"],
//             'image/jpeg': ['.jpg', '.jpeg'],
//             'image/gif': ['.gif'],
//             'image/png': ['.png'],
//             'text/plain': ['.txt'],
//             'application/msword': ['.doc'],
//             'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
//         },
//         maxFiles: 5
//     });

//     const removeFile = (file) => {
//         const newFiles = [...myFiles];
//         setMyFiles(reject(newFiles, { path: file?.path }));
//     };

//     console.log(myFiles);
//     useEffect(() => {
//         if (myFiles?.length > 0) {
//             selectedFiles(myFiles);
//         }
//         else {
//             selectedFiles([]);
//         }
//     }, [myFiles])
//     // useEffect(() => {
//     //     if (myFiles?.length > 0) {
//     //         selectedFiles(myFiles);
//     //     }
//     //     else {
//     //         selectedFiles([]);
//     //     }
//     // }, [selectedFiles])
//     return (
//         <>
//             <Button {...getRootProps({ className: 'dropzone' })} sx={{
//                 border: 'dashed gray 1px !important', borderRadius: "6px", padding: '0px !important',
//                 '&:hover': {
//                     cursor: 'grab'
//                 }
//             }}
//                 // color="primary"
//                 // variant="contained"
//                 size="medium"
//             >
//                 <input {...getInputProps()} />
//                 <Box className="multi-upload-button">
//                     <Box className='left-icon'><DriveFolderUploadIcon /></Box>
//                     <Box style={{ textAlign: 'center' }} >Upload File</Box>
//                     <Box className='multi-upload-right-icon' ><KeyboardArrowDownIcon /></Box>
//                 </Box>
//             </Button >
//             {
//                 !isEmpty(myFiles) && (
//                     <Box className="pt-1">
//                         {myFiles?.length > 0 && myFiles?.map((files: any, index) => {
//                             return (
//                                 <Box display={"flex"} width={'100%'} alignItems={'centre'}>

//                                     {files?.name} {" "} ({getFormattedFileSize(files?.size)})
//                                     <Box sx={{ display: "flex", }}>
//                                         <button
//                                             type="button"
//                                             style={{
//                                                 border: "none",
//                                                 cursor: "pointer",
//                                                 backgroundColor: "transparent",
//                                             }}
//                                             onClick={() => removeFile(files)}
//                                         >
//                                             <Box
//                                                 sx={{
//                                                     textDecoration: "underline",
//                                                     color: "red",
//                                                     cursor: "pointer",
//                                                     marginLeft: "10px",
//                                                 }}>
//                                                 <CloseIcon />
//                                             </Box>
//                                         </button>
//                                     </Box>
//                                 </Box>
//                             )
//                         })}
//                     </Box>
//                 )
//             }
//         </>
//     );
// }

import { Box, Button, Grid, Typography } from "@mui/material";
import { isEmpty, reject } from "lodash";
import React, { useState, useCallback, useEffect, useContext } from "react";
import { useDropzone } from "react-dropzone";
import CloseIcon from "@mui/icons-material/Close";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { AlertType } from "../../services/models/AlertType";
import { PreferencesContext } from "../../PreferenceContext";

interface MultiUploadProps {
  onDropandSelect: any;
  editFile: any;
  onFileRemove: any;
  onFileDownload: any;
  MAX_COUNT: number;
}

const allowedFileTypes = [
  // excel >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  "application/vnd.ms-excel",
  "application/vnd.ms-excel.sheet.macroEnabled.12",
  "application/msexcel",
  "application/x-msexcel",
  "application/x-ms-excel",
  "application/x-excel",
  "application/x-dos_ms_excel",
  "application/xls",
  "application/x-xls",
  "application/x-msi",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",

  //CSV>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  ".csv",
  "text/csv",

  "application/pdf",
  "application/json",
  "image/jpeg",
  "image/png",
  "text/plain",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const MultiUpload: React.FC<MultiUploadProps> = ({
  onDropandSelect,
  editFile,
  onFileRemove,
  onFileDownload,
  MAX_COUNT,
}) => {
  const [file, setFile] = useState<any>();
  const [fileLimit, setFileLimit] = useState(false);
  const { userContext, updateUserContext } = useContext(PreferencesContext);

  // set file if alreday exists
  useEffect(() => {
    setFile(editFile);
  }, [editFile]);

  // on select or on drop file or image
  const onDrop = useCallback(
    (acceptedFiles) => {
      let limitExceeded = false;
      const uploaded = [...file];
      const checkForFile: any = [...file, ...acceptedFiles];
      if (
        file?.length === MAX_COUNT ||
        checkForFile === MAX_COUNT ||
        checkForFile > MAX_COUNT ||
        acceptedFiles > MAX_COUNT
      ) {
        return updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: `You can only add a maximum of ${MAX_COUNT} files`,
          alertType: AlertType.Error,
        });
      }
      acceptedFiles.some((file) => {
        if (uploaded.findIndex((f) => f.name === file.name) === -1) {
          uploaded.push(file);
          if (uploaded.length === MAX_COUNT) setFileLimit(true);
          if (uploaded.length > MAX_COUNT) {
            updateUserContext({
              ...userContext,
              isAlert: true,
              alertMessage: `You can only add a maximum of ${MAX_COUNT} files`,
              alertType: AlertType.Error,
            });
            setFileLimit(false);
            limitExceeded = true;
            return true;
          }
        }
      });
      // const filesWithId = uploaded.map((item, index) => {  //just edit the file but it's NIU -NOT IN USE
      //     return {
      //         id: index + 1,
      //         lastModified: item.lastModified,
      //         lastModifiedDate: item.lastModifiedDate,
      //         name: item.name,
      //         size: item.size,
      //         type: item.type,
      //         webkitRelativePath: item.webkitRelativePath
      //     };
      // });
      // console.log("filesWithId", filesWithId);
      // console.log("uploaded", uploaded);

      if (!limitExceeded) {
        setFile(uploaded);
        onDropandSelect(uploaded);
      }
    },
    [file]
  );
  const customvalidator: any = (file) => {
    const MaxSize = 5;
    if (file.size / 1024 / 1204 > MaxSize) {
      return {
        code: "size-too-large",
        message: `Max allowed file size is 5 MB.`,
      };
    }
    if (!allowedFileTypes.includes(file.type)) {
      return {
        code: "invalid-type",
        message: `Please upload only Excel Files (supported types: xlsx, xls or csv only).`,
      };
    }
    return null;
  };
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    validator: customvalidator,
    multiple: true,
    accept: {
      "application/pdf": [".pdf"],
      "application/json": [".json"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "application/vnd.ms-excel": [".xls"],
      "text/csv": [".csv"],
      "image/jpeg": [".jpg", ".jpeg"],
      "image/gif": [".gif"],
      "image/png": [".png"],
      "text/plain": [".txt"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
    maxFiles: 5,
  });

  // errors on file uploadings
  const fileRejectionItems = fileRejections.map(({ file, errors }, index) => (
    <Box>
      {errors.map((e) => (
        <span key={e.code} style={{ color: "red" }}>
          * {e.message}
        </span>
      ))}
    </Box>
  ));

  const getFormattedFileSize = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    // JV
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  return (
    <>
      <Button
        {...getRootProps({ className: "dropzone" })}
        sx={{
          border: "dashed gray 1px !important",
          borderRadius: "6px",
          padding: "0px !important",
          "&:hover": {
            cursor: "grab",
          },
        }}
        size="medium"
      >
        <input {...getInputProps()} />
        <Box sx={{ justifyContent: "center" }} className="multi-upload-button">
          <Box className="left-icon">
            <DriveFolderUploadIcon />
          </Box>
          <Box style={{ paddingTop: "2px", textAlign: "center" }}>
            Upload File
          </Box>
          {/* <Box className="multi-upload-right-icon">
            <KeyboardArrowDownIcon />
          </Box> */}
        </Box>
      </Button>
      {!isEmpty(file) && (
        <Box sx={{ marginTop: "6px" }}>
          {file?.length > 0 &&
            file?.map((files: any, index) => {
              return (
                <Grid
                  sx={{
                    alignItems: "start",
                    // border: 'dashed gray 1px !important', borderRadius: "6px", padding: '0px !important',
                    // '&:hover': {
                    //     cursor: 'grab'
                    // },
                    display: "flex",
                    backgroundColor: "transparent",
                  }}
                >
                  <Button
                    sx={{
                      justifyContent: "start",
                      maxWidth: "350px",
                      minWidth: "0px !important",
                      textOverflow: "ellipsis ",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textAlign: "left",
                      display: "block",
                    }}
                    className="multi-upload-button"
                    onClick={() => {
                      onFileDownload(files);
                    }}
                  >
                    {files?.name} ({getFormattedFileSize(files?.size)})
                  </Button>
                  <span
                    className="multi-upload-right-icon"
                    onClick={() => onFileRemove(files)}
                  >
                    {" "}
                    <CloseIcon sx={{ color: "red !important" }} />
                  </span>
                </Grid>
              );
            })}
        </Box>
      )}
      <Box>{fileRejectionItems}</Box>
      <Box>
        <span className="multi-upload-info-text">
          You can upload a maximum of 5 files, 4MB each
        </span>
      </Box>
    </>
  );
};
