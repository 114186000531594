import React, { useEffect, useState } from 'react';
import './App.css';
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import { lightTheme } from './theme/Palette/light-palette';
import CssBaseline from '@mui/material/CssBaseline';
import RootNavigation from './navigations/RootNavigation';
import { Context } from './services/models/Context';
import { getStorage, getVersion, setStorage } from './services/common/storage';
import "./theme/style.scss";
import { PreferencesContext } from './PreferenceContext';
import Alerts from './components/Common/Alerts';
import { GetAndroidConfig } from './services/ConfigService';
import { getNotificationStorage, setNotificationStorage } from './services/common/NotificationStorage';
//import { SendNotification } from './services/UserService';
//import { useTranslation } from 'react-i18next';

function App() {
  const [mode, setMode] = useState<"light" | "dark" | "">(
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? "light" : "light"
  );
  const [userContext, setUserContext] = useState<Context>(getStorage());
  const [isAndroidRestrict, setAndroidRestrict] = useState(false);
  const [isIPhoneRestrict, setIPhoneRestrict] = useState(false);
  const [isWebRestrict, setWebRestrict] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const navigate = useNavigate();
  //const { i18n, t } = useTranslation();

  const updateUserContext = React.useCallback((context: any) => {
    setStorage(context);
    return (setUserContext(context));
  }, [userContext]);

  const preferences = React.useMemo(
    () => ({
      updateUserContext, userContext,
    }), [userContext]
  );

  const setLanguage = () => {
    // if (userContext.memberDetail !== undefined && userContext.memberDetail !== null) {
    //   if (userContext.memberDetail.curMemberSelectedLanguage !== "") {
    //     i18n.changeLanguage(userContext.memberDetail.curMemberSelectedLanguage);
    //   } else {
    //     i18n.changeLanguage('en');
    //   }
    // } else {
    //   i18n.changeLanguage('en');
    // }
  }
  

  async function checkIfLoggedIn() {
    let version = getVersion();
    let androidVersion = '1.0'; // change to take from env
    let iosVersion = '1.0';// change to take from env

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const urlRemoveLocalStorage = params.get('rm');
    if (urlRemoveLocalStorage === "1") {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      var context = new Context();
      updateUserContext(context);
      setLoading(false);
      navigate('/');
    }

    const urlFroceUpdate = params.get('cc');
    if (urlFroceUpdate === "1") {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }

    const result: any = await GetAndroidConfig();

    if (result !== undefined && result !== null && result.data !== null && result.data.data !== null) {

      let data = result.data.data;
      setLoading(false);

      // for android
      if (androidVersion !== data.androidAppVersion) {
        if (data.androidForceLogout === true) {
          var context = new Context();
          updateUserContext(context);
          navigate('/');
        } else {
          //cookies
        }
      }

      // for ios
      if (iosVersion !== data.iosAppVersion) {
        if (data.iosForceLogout === true) {
          var context = new Context();
          updateUserContext(context);
          navigate('/');
        } else {
          //cookies
        }
      }

      // for version
      if (version !== data.version) {
        if (data.versionForceLogout === true) {
          var context = new Context();
          updateUserContext(context);
          navigate('/');
        } else {
          //cookies
        }
      }
    }

    let value = getStorage();
    setLoading(false);
    if (userContext.loginDetail !== undefined && userContext.loginDetail !== null && userContext.loginDetail.userId > 0) {
      if (userContext.isUnauthorized === true) {
        var context = new Context();
        updateUserContext(context);
        navigate('/');
      } else {
        const windowUrl = window.location.pathname.length
        if (windowUrl < 2) {
          navigate('/dashboard');
        }
      }
    } else {
      var context = new Context();
      updateUserContext(context);
      const windowUrl = window.location.pathname.length
      if (windowUrl < 2) {
        navigate('/');
      }
    }
  }

  function getOS() {
    let os = navigator.userAgent;
    // debugger
    let finalOs = "Unknown";
    if (os.search('Windows') !== -1) {
      finalOs = "Windows";
    }
    else if (os.search('Mac') !== -1 && os.search('iPhone') !== -1) {
      finalOs = "iPhone";
    }
    else if (os.search('Mac') !== -1 && os.search('iPad') !== -1) {
      finalOs = "iPad";
    }
    else if (os.search('Mac') !== -1) {
      finalOs = "Mac";
    }
    else if (os.search('X11') !== -1 && !(os.search('Linux') !== -1)) {
      finalOs = "UNIX";
    }
    else if (os.search('Linux') !== -1 && os.search('X11') !== -1) {
      finalOs = "Linux"
    }
    else if (os.search('Android') !== -1) {
      finalOs = "Android"
    }
    return finalOs;
  }

  // const isInStandaloneMode = () =>
  //   (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');

  const isInStandaloneMode = () =>
    (window.matchMedia('(display-mode: standalone)').matches) ||  document.referrer.includes('android-app://');

  useEffect(() => {
    let os = getOS();
    if (isInStandaloneMode()) {
      if (os === "Android") {
        setAndroidRestrict(true);
      }
      if (os === "iPhone") {
        setIPhoneRestrict(true);
      }
      if (os === "Windows") {
        setWebRestrict(true);
      }
    } else {
      if (os === "Android") {
        setAndroidRestrict(true);
      }
      if (os === "iPhone") {
        setIPhoneRestrict(true);
      }
      if (os === "Windows") {
        setWebRestrict(true);
      }
    }
    // checkIfLoggedIn();
    // setNotificationDataToStorage();
    // setLanguage();
  }, []);

  // useEffect(() => {
  //   sendNotification();
  // }, [userContext.memberDetail.familyMemberId]);

  let notificationValue = getNotificationStorage();


  async function setNotificationDataToStorage() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const urlToken = params.get('t');
    const urlDevice = params.get('m');
    let notificationData: any = {
      token: urlToken,
      mode: urlDevice
    };
    setNotificationStorage(notificationData);
    sendNotification();
  }

  async function sendNotification() {
    // let notificationStorageData: any = getNotificationStorage();
    // if (notificationStorageData !== undefined && notificationStorageData !== null) {
    //   if (userContext !== undefined && userContext.loginDetail !== undefined && userContext.loginDetail !== null) {
    //     if (userContext.memberDetail !== undefined && userContext.memberDetail !== null) {
    //       if (userContext.loginDetail.familyId > 0 && userContext.memberDetail.familyMemberId > 0
    //         && notificationStorageData.mode != null && notificationStorageData.token != null) {
    //         let notificationData = {
    //           familyId: userContext.loginDetail.familyId,
    //           familyMemberId: userContext.memberDetail.familyMemberId,
    //           device: notificationStorageData.mode,
    //           token: notificationStorageData.token,
    //           dataManagedBy: userContext.loginDetail.userId,
    //         };
    //         const resultParentSave: any = await SendNotification(notificationData);
    //       }
    //     }
    //   }
    // }
  }

  return (
    <ThemeProvider theme={mode === 'dark' ? lightTheme : lightTheme}>
      <CssBaseline />
      <PreferencesContext.Provider value={preferences}>
        {userContext &&
          <>
            <Alerts isOpen={userContext.isAlert} displayMessage={userContext.alertMessage} type={userContext.alertType}></Alerts>
          </>
        }
        {/* {!isLoading && */}
          <RootNavigation colorScheme={(userContext.isThemeDark ? 'dark' : 'light')} />
          {/* } */}
      </PreferencesContext.Provider>


    </ThemeProvider>
  );
}

export default App;
