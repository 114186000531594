import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import * as React from "react";

interface DatepickerProperties {
    label?: string;
    onChange?: (value: Dayjs | null) => void;
    format?: string;
    value?: (Dayjs | null | undefined);
    disablePast?: boolean;
    slotProps?: any;
    disabled?: boolean;
    name?: string;
    mode?: string;
    sx?: any;
    readonly?: boolean;
    minDate?: any;
    maxDate?: any;
    renderInput?: any;
    isClassName?: boolean;
    // FieldName?:any;
}
export default function DatePickerCompponent(props: DatepickerProperties) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} >
            <DesktopDatePicker
                {...props}
                views={['year', 'month', 'day']}
            // {...field}
            />
        </LocalizationProvider>
    );
}
