import React, { FC, useState } from "react";
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  List,
  Paper,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { Item } from "../../../../../../Helpers/typings";
// import ChiefComplaintsDraggableTableItem from "./ChiefComplaintsDraggableTableItem";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ReactQuill from "react-quill";
import HistoryDraggableTableItem from "./HistoryDraggableTableItem";
import { CustomSelect } from "../../../../../../components/CustomFields/CustomSelect";

const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#FAFBFD",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));

export type Props = {
  header: string;
  items: Item[];
  onDragEnd: OnDragEndResponder;
  onHandleSelectData: (value: string) => void;
  selected: string;
};

const HistoryDraggableTable: FC<Props> = React.memo(
  ({ header, items, onDragEnd, onHandleSelectData, selected }) => {
    const onRowClick = (val) => {
      onHandleSelectData(val);
    };
    const [showNote, setShowNote] = useState(false);

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Box
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Paper
            style={{
              flex: 1,
            }}
          >
            <Box className="inner-header-container">
              <Typography style={{ fontWeight: 600 }}>{header}</Typography>
            </Box>
          </Paper>
        </Box>
        <Droppable droppableId="droppable-list">
          {(provided) => (
            <List ref={provided.innerRef} {...provided.droppableProps}>
              {items.map((item: Item, index: number) => (
                <HistoryDraggableTableItem
                  item={item}
                  index={index}
                  key={item.id}
                  onHandleSelect={onRowClick}
                  selectedMedicine={selected}
                />
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
        <Divider />
        <Box style={{ padding: 10, paddingRight: 24, paddingLeft: 24 }}>
          <FormControl fullWidth>
          <CustomSelect
                          // isNotCreatable={true}
                          id={"search"}
                          FieldName={"search"}
                          options={[]}
                          openMenuOnFocus
                          isClearable
                          isSearchable
                          placeholder="Search..."
                          menuPortalTarget={document.querySelector("body")}
                          handleChange={(e) => {
                            console.log("event:", e)
                          }}
                          Value=""
                        />
          </FormControl>
        </Box>
        <Divider />
      </DragDropContext>
    );
  }
);

export default HistoryDraggableTable;
