import React, { FC } from "react";
import { Draggable } from "react-beautiful-dnd";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { Item } from "../../Helpers/typings";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // fontSize: '1rem',
          // width:'10%'
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontSize: 20,
        },
      },
    },
  },
});

export type Props = {
  item: Item;
  index: number;
  onRowClickForOpenDialog: (value: boolean) => void;
};

const DraggableListItem: FC<Props> = ({
  item,
  index,
  onRowClickForOpenDialog,
}) => {
  // const navigate = useNavigate()
  const [anchorEl, setAnchorEl3] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const handleCloseMore = () => {
    setAnchorEl3(null);
  };
  const handleOpenMore = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: any
  ) => {
    setAnchorEl3(event.currentTarget);
    // setBuyerId(id);
  };
  return (
    <ThemeProvider theme={theme}>
      <Draggable draggableId={item.id} index={index}>
        {(provided, snapshot) => (
          <ListItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            // {...provided.dragHandleProps}
            sx={snapshot.isDragging ? { background: "rgb(235,235,235)" } : ""}
          >
            {/* <Box style={{ paddingRight: 16, }}>
                            <DragIndicatorIcon />
                        </Box> */}
            <Box
              style={{ paddingRight: 16 }}
              {...provided.dragHandleProps} // Drag handle only on the icon
            >
              <DragIndicatorIcon />
            </Box>
            <ListItemText primary={item.primary} secondary={item.secondary} />
            <Box style={{ alignSelf: "flex-start" }}>
              <Button size="small" style={{ minWidth: "30px" }}>
                <EditIcon
                  onClick={() => onRowClickForOpenDialog(true)}
                  style={{ color: "#414d55" }}
                />
              </Button>
            </Box>
            <Box style={{ alignSelf: "flex-start" }}>
              <Button size="small" style={{ minWidth: "30px" }}>
                <CloseRoundedIcon
                  // onClick={() => onRowClickForOpenDialog(true)}
                  style={{ color: "#d32f2f" }}
                />
              </Button>
            </Box>
          </ListItem>
        )}
      </Draggable>
      <Divider />
      {/* <Popover
        elevation={1}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseMore}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      > */}
        {/* <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => onRowClickForOpenDialog(true)}>
              <ListItemText primary="Edit" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton className="delete-container">Delete</ListItemButton>
          </ListItem>
        </List> */}
      {/* </Popover> */}
    </ThemeProvider>
  );
};

export default DraggableListItem;
