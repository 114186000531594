import React, { FC } from "react";
import { Draggable } from "react-beautiful-dnd";
import "../../../../../../theme/style.scss"
import {
  Box,
  Button,
  Divider,
  Grid,
  ListItem,
  ThemeProvider,
  Typography,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  AppBar,
  Toolbar
} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Item } from "../../../../../../Helpers/typings";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import MedicinePrescription from "../../Diagnosis/MedicineComponent/MedicinePrescription";
import LoadingButton from "@mui/lab/LoadingButton";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {},
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontSize: 20,
        },
      },
    },
  },
});

export type Props = {
  item: Item;
  index: number;
  onHandleSelect: (value: string) => void;
  selectedMedicine: string;
};

const HistoryDraggableTableItem: FC<Props> = ({
  item,
  index,
  onHandleSelect,
  selectedMedicine,
}) => {
  const handleSelect = () => {
    onHandleSelect(item.medicine);
  };

  const [openDialogMedicine, setOpenDialogMedicine] = React.useState(false);

  const handleClickOpenDialogMedicine = (value: boolean) => {
    setOpenDialogMedicine(value);
  };

  const handleCloseDialogMedicine = () => {
    setOpenDialogMedicine(false);
  };
  return (
    <ThemeProvider theme={theme}>
      <Divider />
      <Draggable draggableId={item.id} index={index}>
        {(provided, snapshot) => (
          <ListItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            sx={{
              ...(snapshot.isDragging && {
                backgroundColor: "rgb(235,235,235)",
              }),
            }}
            className={
              selectedMedicine === item.medicine ? "draggable-list-item" : " "
            }
          >
            <Grid container item spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Box
                  // onClick={handleSelect}
                  style={{
                    display: "flex",
                    cursor: "pointer",
                  }}
                >
             
                  <Grid container item>
                    <Grid item xs={12} sm={12} md={12}>
                    <Box
                  // onClick={handleSelect}
                  style={{
                    display: "flex",
                    cursor: "pointer",
                  }}
                >
                  <Box
                    style={{ paddingRight: 16, paddingTop: 5 }}
                    {...provided.dragHandleProps}
                  >
                    <DragIndicatorIcon />
                  </Box>
                  <Grid container item>
                    <Grid item xs={12} sm={12} md={12}>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "start",
                          paddingTop: 5,
                        }}
                      >
                        <Typography
                          className={
                            selectedMedicine === item.medicine
                              ? "symptom-text"
                              : " "
                          }
                        >
                          {item.medicine}
                        </Typography>
                        {selectedMedicine === item.medicine && (
                          <Box style={{ paddingLeft: "8px" }}>
                            <KeyboardDoubleArrowRightRoundedIcon />
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                  <Box>
                        <IconButton onClick={()=>{
                          handleClickOpenDialogMedicine(true);
                        }}>
                             <EditIcon sx={{ color: "black" }} />
                        </IconButton>
                        </Box>
                <Box
                >
                  <Button size="small" style={{ minWidth: "30px" }}>
                    <CloseIcon style={{ color: "#d32f2f" }} />
                  </Button>
                </Box>
             
                </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            
            </Grid>
          </ListItem>
        )}
      </Draggable>
      <Dialog
        className="dialog"
        open={openDialogMedicine}
        onClose={handleCloseDialogMedicine}
        fullScreen
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography
              style={{ fontWeight: 600, marginLeft: 1 }}
              sx={{
                paddingLeft:{
                  xs:1,
                  sm:0,
                }
              }}
              variant="h6"
            >
            Medicine Edit
            </Typography>
           
          </Toolbar>
        </AppBar>
     
        <DialogContent>
        <MedicinePrescription></MedicinePrescription>
        </DialogContent>
        <DialogActions>
        <LoadingButton
            color="primary"
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="contained"
            type="submit"
            className="primary-button"
            name="submit"
            onClick={()=>{
              handleCloseDialogMedicine()
            }}
          >
            Save
          </LoadingButton>
          <LoadingButton
          style={{borderColor:"rgba(65, 77, 85, 0.5)",color:"#414d55"}}
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="outlined"
            className="secondary-button"
            // color="secondary"
            onClick={()=>{
              handleCloseDialogMedicine()
            }}
          >
            Cancel
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default HistoryDraggableTableItem;
