import React from 'react'
import {
  Box, Grid, IconButton, InputAdornment, TextField, Button, styled, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
  Popover,
  Pagination,
  Stack,
} from '@mui/material'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate } from 'react-router-dom';
import '../../../theme/style.scss';

import CustomBreadcrumb from '../../../components/BreadcrumbsComponent/CustomBreadcrumbs';

// import ClearIcon from "@mui/icons-material/Clear";
export default function MedicineTemplateList() {
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl3] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);

  const CustomTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: '#FAFBFD', // Set the desired background color
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.grey[300],
        borderWidth: '1px',
        BorderStyle: 'solid'
      },
      '&:hover fieldset': {
        borderColor: theme.palette.grey[500],
        borderWidth: '1px',
        BorderStyle: 'solid'
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: "2px",
      },
    },
  }));

  // for Edit & Delet pop 
  const handleOpenMore = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: any
  ) => {
    setAnchorEl3(event.currentTarget);
    // setBuyerId(id);
  };
  const handleCloseMore = () => {
    setAnchorEl3(null);
  };

  const rowData = [
    {
      branch: 'Metformin ',
      address: 'Navratna Corporate Park Ahmedabad Gujarat',
      contact: '9876543210',

    },
    {
      branch: 'Atorvastatin ',
      address: 'Navratna Corporate Park Ahmedabad Gujarat',
      contact: '9876543210',

    }
  ]

  //  Table Header 

  function EnhancedTableHead(props: EnhancedTableProps) {
    return (
      // checkBox for Header

      <TableHead className='table-header-color'>
        <TableRow>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "90%"
            }}
            align="left"
          >
            Template Name
          </TableCell>
          {/* <TableCell /> */}
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "10%"
            }}
            align="left"
          >
            {''}
          </TableCell>

        </TableRow>
      </TableHead>
    );
  }

  return (
    <>
      <Box className='main-container' >
        <Grid xs={12} container className='page-header'>
          <Grid xs={8} sm={6} item className="page-breadcrumbs">
            {/* <BreadcrumbsComponent /> */}
            <CustomBreadcrumb />
          </Grid>
          <Grid xs={4} sm={6} item className='page-header-icon'>
            <Button
              color="primary"
              variant="contained"
              onClick={() => navigate('medicine-template-save')}
              size="medium"
              startIcon={<AddIcon />}
            >
              Add
            </Button></Grid>
        </Grid>

        <Grid container className="list-container" >
          <Grid item xs={12} sm={4} md={3} lg={2} className='search-bar'>

            <CustomTextField
              placeholder="Search..."
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              sx={{ paddingLeft: '0px' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" >
                    <IconButton onClick={() => searchValue("")} >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                // endAdornment: value && (
                //   <IconButton
                //     aria-label="toggle password visibility"
                //     onClick={() => searchValue("")}
                //   >
                //     <ClearIcon />
                //   </IconButton>
                // ),
              }}
            />
            {/* <span><AddBoxRoundedIcon /></span> */}
          </Grid>
         
          <Grid item xs={12} sm={8} md={9} lg={10} className='search-bar pdf-icon setMarginIcon'>
            <img src="/xls.png" alt="" className='pdf-icon-hover '  />
            <img src="/pdf.png" alt="" className='pdf-icon-hover' />

            {/* <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
            // anchorEl={anchorEl2}
            // open={openExportType}
            // onClose={handleCloseExportType}
            >
              <MenuItem
                disableRipple
              >
                PDF
              </MenuItem>
              <MenuItem
                disableRipple
              >
                EXCEL
              </MenuItem>
            </StyledMenu> */}
          </Grid>

          <TableContainer className='table'>
            <Table>
              <EnhancedTableHead />
              <TableBody>
                {rowData.map((item) => {
                  return (
                    <TableRow>
                      <TableCell><Link className='text-link' to="medicine-template-save">{item.branch}</Link></TableCell>
                      <TableCell align="right">
                        <Tooltip title="More" placement="top">
                          <IconButton
                            onClick={(e: any) =>
                              handleOpenMore(e)
                            }
                          >
                            <MoreVertRoundedIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid
            container
            paddingTop={1}
            className='pagination'
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid item xs={12} sm={6}
              className="pagination-label"
            >
              1 of 1 {/* {pageStartIndex} - {pageEndIndex} of {totalCount} */}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack
                component="div"
                className='pagination'
              >
                <Pagination
                  color="primary"
                // count={counData}
                // page={page_Index}
                // onChange={handleChangePageNew}
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Popover
        elevation={1}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseMore}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('medicine-template-save')}>
              <ListItemText primary="Edit" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              className="delete-container"
            >
              Delete
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </>
  )
}
