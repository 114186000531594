import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../theme/style.scss";
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Title from "../../../components/Title";
import DatePickerCompponent from "../../../components/CustomFields/DatePicker";
import { Formik } from "formik";
import { CustomSelect } from "../../../components/CustomFields/CustomSelect";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import CustomBreadcrumbs from "../../../components/BreadcrumbsComponent/CustomBreadcrumbs";

interface ColourOption {
  value: string;
  label: string;
}

const prifixOption: ColourOption[] = [
  { value: 'Mr', label: 'Mr' },
  { value: 'Mrs', label: 'Mrs' },
  { value: 'Miss', label: 'Miss' },
  { value: 'Ms', label: 'Ms' },
  { value: 'Mx', label: 'Mx' },
  { value: 'Sir', label: 'Sir' },
  { value: 'Dr', label: 'Dr' },
];

const preferredOption: ColourOption[] = [
  { value: 'Hindi', label: 'Hindi' },
  { value: 'English', label: 'English' },
];
const genderOption: ColourOption[] = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
  { value: 'Other', label: 'Other' },
];

const MaratialStatusOption: ColourOption[] = [
  { value: 'Married', label: 'Married' },
  { value: 'UnMarried', label: 'Unmarried' },
  { value: 'Other', label: 'Other' },
];
const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#FAFBFD", // Set the desired background color
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      borderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      borderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function MedicalRepresentativeSave() {
  const [value, setValue] = useState('');
  const navigate = useNavigate();
  const [countryData, setCountry] = useState([
    { countryID: 0, code: "", name: "" },
    { countryID: 1, code: "123", name: "India" },
    { countryID: 2, code: "456", name: "USA" },
  ]);

  return (
    <Box className="main-container" pl={2}>
      <Grid item xs={12} container className="page-header-save">
        <Box>
          <CustomBreadcrumbs />
        </Box>
      </Grid>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
        }}
        onSubmit={async (values) => {
          console.log("values", values)
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} mb={3} className="group-container">
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Basic Information</Typography>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Medical Representative ID</FormLabel>
                  <CustomTextField
                    name="laboratoryName"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={9} md={9} />
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Prefix</FormLabel>
                  <CustomSelect
                    className="col"
                    isNotCreatable={true}
                    id={"prefix"}
                    FieldName={"prefix"}
                    options={prifixOption}
                    openMenuOnFocus
                    isClearable
                    isSearchable
                    menuPortalTarget={document.querySelector("body")}
                    handleChange={(e) => {
                      console.log("event:", e)
                    }}
                    Value=""
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">First Name</FormLabel>
                  <CustomTextField
                    name="laboratoryName"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Middle Name</FormLabel>
                  <CustomTextField
                    name="laboratoryName"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Last Name</FormLabel>
                  <CustomTextField
                    name="laboratoryName"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div>
                  <Box style={{ fontSize: 12 }}>Date of Birth</Box>
                  <FormControl sx={{ width: '100% !important' }}>
                    <DatePickerCompponent
                      format="DD-MM-YYYY"
                      mode="date"
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Age</FormLabel>
                  <CustomTextField
                    name="laboratoryName"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Gender</FormLabel>
                  <CustomSelect
                    isNotCreatable={true}
                    id={"country"}
                    FieldName={"country"}
                    options={genderOption}
                    openMenuOnFocus
                    isClearable
                    isSearchable
                    menuPortalTarget={document.querySelector("body")}
                    handleChange={(e) => {
                      console.log("event:", e)
                    }}
                    Value=""
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Marital Status</FormLabel>
                  <CustomSelect
                    isNotCreatable={true}
                    id={"country"}
                    FieldName={"country"}
                    options={MaratialStatusOption}
                    openMenuOnFocus
                    isClearable
                    isSearchable
                    menuPortalTarget={document.querySelector("body")}
                    handleChange={(e) => {
                      console.log("event:", e)
                    }}
                    Value=""
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Preferred Language</FormLabel>
                  <CustomSelect
                    isNotCreatable={true}
                    id={"referredBy"}
                    FieldName={"referredBy"}
                    options={preferredOption}
                    openMenuOnFocus
                    isClearable
                    isSearchable
                    menuPortalTarget={document.querySelector("body")}
                    handleChange={(e) => {
                      console.log("event:", e)
                    }}
                    Value=""
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mb={3} className="group-container">
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Company Information</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Comapny Name</FormLabel>
                  <CustomTextField
                    name="address1"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mb={3} className="group-container">
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Contact Information</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Address 1</FormLabel>
                  <CustomTextField
                    name="address1"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Address 2</FormLabel>
                  <CustomTextField
                    name="address2"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">City</FormLabel>
                  <CustomTextField
                    name="city"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Postal Code</FormLabel>
                  <CustomTextField
                    name="postalCode"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">State</FormLabel>
                  <CustomSelect
                    isNotCreatable={true}
                    id={"state"}
                    FieldName={"state"}
                    options={countryData}
                    openMenuOnFocus
                    isClearable
                    isSearchable
                    menuPortalTarget={document.querySelector("body")}
                    handleChange={(e) => {
                      console.log("event:", e)
                    }}
                    Value=""
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Country</FormLabel>
                  <CustomSelect
                    isNotCreatable={true}
                    id={"country"}
                    FieldName={"country"}
                    options={countryData}
                    openMenuOnFocus
                    isClearable
                    isSearchable
                    menuPortalTarget={document.querySelector("body")}
                    handleChange={(e) => {
                      console.log("event:", e)
                    }}
                    Value=""
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Email</FormLabel>
                  <CustomTextField
                    name="email"
                    placeholder=""
                    autoCapitalize="none"
                    type="email"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div className="input-group">
                  <label htmlFor="phone">Primary Mobile Number</label>
                  <PhoneInput
                    id="phone"
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="IN"
                    value={value}
                    onChange={setValue}
                    placeholder="Enter phone number"
                    className="phone-input"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div className="input-group">
                  <label htmlFor="phone">Alternate Mobile Number</label>
                  <PhoneInput
                    id="phone"
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="IN"
                    value={value}
                    onChange={setValue}
                    placeholder="Enter phone number"
                    className="phone-input"
                  />
                </div>
              </Grid>

            </Grid>
          </Grid>
          
          <Grid item xs={12}>
            <Box sx={{ display: "flex", gap: 1, justifyContent: "flex-end" }}>
              <LoadingButton
                variant="contained"
                color="primary"
                loading={false}
                className="green-button"
                onClick={() => { navigate("/master/medical-representative") }}
              >
                Submit
              </LoadingButton>
              <LoadingButton
                color="secondary"
                tabIndex={3}
                loadingPosition="start"
                startIcon={<></>}
                variant="outlined"
                type="submit"
                className="secondary-button"
                name="submit"
                onClick={() => navigate("/master/medical-representative")}
              >
                CANCEL
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Formik>
    </Box>
  );
}
