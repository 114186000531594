import { Box } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

type TLogo = {
  className: string,
  size: number
}

export default function HmisLogo(props: TLogo = { className: 'logo', size: 38 }) {

  const navigate = useNavigate()

  const dashboardClick = () => {
    navigate('/')
  }

  return (
    <>
      <Box className={props.className} onClick={dashboardClick}>
        <img src="/logo.png" style={{ height: `${props.size}px`, width: 'auto' }} alt="Hims Logo"></img>
      </Box>
    </>
  )
}
