import React from "react";
import { Route, Routes } from "react-router-dom";

import SignIn from "../pages/SignIn/SignIn";
import Dashboard from "../pages/Dashboard/Dashboard";
import Master from "../pages/Master/Master";
import NavBar from "./_components/Navbar/NavBar";
import ForgotPassword from "../pages/ForgotPassword/Forgotpassword";
import ResetPassword from "../pages/Resetpassword/Resetpassword";
import Settings from "../pages/Settings/Settings";
import BranchList from "../pages/Branch/BranchList";
import BranchSave from "../pages/Branch/BranchSave";
import DepartmentList from "../pages/Department/DepartmentList";
import DepartmentSave from "../pages/Department/DepartmentSave";
import MedicineMasterList from "../pages/Medicine/MedicineMaster/MedicineMasterList";
import MedicineMasterSave from "../pages/Medicine/MedicineMaster/MedicineMasterSave";
import FollowUpMasterList from "../pages/FollowUp/FollowUpMaster/FollowUpMasterList";
import FollowUpMasterSave from "../pages/FollowUp/FollowUpMaster/FollowUpMasterSave";
import FollowUpReasonList from "../pages/FollowUp/FollowUpReason/FollowUpReasonList";
import FollowUpReasonSave from "../pages/FollowUp/FollowUpReason/FollowUpReasonSave";
import FollowUpInstructionList from "../pages/FollowUp/FollowUpInstruction/FollowUpInstructionList";
import FollowUpInstructionSave from "../pages/FollowUp/FollowUpInstruction/FollowUpInstructionSave";
import ConsumerList from "../pages/Consumer/ConsumerList";
import ConsumerSave from "../pages/Consumer/ConsumerSave";
import NotFound from "../pages/NotFound/NotFound";
import OrganizationSave from "../pages/Organisation/OraganizationSave";
import OrganizationList from "../pages/Organisation/OrganizationList";
import DiagnosisMasterSave from "../pages/Diagnosis/DiagnosisMaster/DiagnosisMasterSave";
import DiagnosisMasterList from "../pages/Diagnosis/DiagnosisMaster/DiagnosisMasterList";
import FollowUpDurationSave from "../pages/FollowUp/FollowUpDuration/FollowUpDurationSave";
import FollowUpDurationList from "../pages/FollowUp/FollowUpDuration/FollowUpDurationList";
import DiagnosisTypeList from "../pages/Diagnosis/DiagnosisType/DiagnosisTypeList";
import DiagnosisTypeSave from "../pages/Diagnosis/DiagnosisType/DiagnosisTypeSave";
import ICDCodesList from "../pages/Diagnosis/ICDCodes/ICDCodesList";
import ICDCodesSave from "../pages/Diagnosis/ICDCodes/ICDCodesSave";
import InstructionMasterSave from "../pages/Instruction/InstructionMaster/InstructionMasterSave";
import InstructionMasterList from "../pages/Instruction/InstructionMaster/InstructionMasterList";
import GeneralInstructionList from "../pages/Instruction/GeneralInstruction/GeneralInstructionList";
import GeneralInstructionSave from "../pages/Instruction/GeneralInstruction/GeneralInstructionSave";
import DietandNutritionInstructionSave from "../pages/Instruction/DietandNutritionInstruction/DietandNutritionInstructionSave";
import DietandNutritionInstructionList from "../pages/Instruction/DietandNutritionInstruction/DietandNutritionInstructionList";
import InvestigationInstructionList from "../pages/Instruction/InvestigationInstruction/InvestigationInstructionList";
import InvestigationInstructionSave from "../pages/Instruction/InvestigationInstruction/InvestigationInstructionSave";
import MedicineInstructionList from "../pages/Instruction/MedicineInstruction/MedicineInstructionList";
import MedicineInstructionSave from "../pages/Instruction/MedicineInstruction/MedicineInstructionSave";
import SpecialInstructionList from "../pages/Instruction/SpecialInstruction/SpecialInstructionList";
import SpecialInstructionSave from "../pages/Instruction/SpecialInstruction/SpecialInstructionSave";
import AlertInstructionList from "../pages/Instruction/AlertInstruction/AlertInstructionList";
import AlertInstructionSave from "../pages/Instruction/AlertInstruction/AlertInstructionSave";
import InvestigationMasterList from "../pages/Investigation/InvestigationMaster/InvestigationMasterList";
import InvestigationMasterSave from "../pages/Investigation/InvestigationMaster/InvestigationMasterSave";
import MedicineTemplateList from "../pages/Template/MedicineTemplate/MedicineTemplateList";
import MedicineGenericNameList from "../pages/Medicine/MedicineGenericName/MedicineGenericNameList";
import MedicineGenericNameSave from "../pages/Medicine/MedicineGenericName/MedicineGenericNameSave";
import MedicinecompanyList from "../pages/Medicine/Medicinecompany/MedicinecompanyList";
import MedicinecompanySave from "../pages/Medicine/Medicinecompany/MedicinecompanySave";
import MedicineGroupList from "../pages/Medicine/MedicineGroup/MedicineGroupList";
import MedicineGroupSave from "../pages/Medicine/MedicineGroup/MedicineGroupSave";
import MedicineFormulationList from "../pages/Medicine/MedicineFormulation/MedicineFormulationList";
import MedicineFormulationSave from "../pages/Medicine/MedicineFormulation/MedicineFormulationSave";
import MedicineUsedAsList from "../pages/Medicine/MedicineUsedAs/MedicineUsedAsList";
import MedicineUsedAsSave from "../pages/Medicine/MedicineUsedAs/MedicineUsedAsSave";
import MedicineDiluentNameList from "../pages/Medicine/MedicineDiluentName/MedicineDiluentNameList";
import MedicineDiluentNameSave from "../pages/Medicine/MedicineDiluentName/MedicineDiluentNameSave";
import MedicineTemplateSave from "../pages/Template/MedicineTemplate/MedicineTemplateSave";
import DiagnosisTemplateList from "../pages/Template/DiagnosisTemplate/DiagnosisTemplateList";
import DiagnosisTemplateSave from "../pages/Template/DiagnosisTemplate/DiagnosisTemplateSave";
import InvestigationTemplateSave from "../pages/Template/InvestigationTemplate/InvestigationTemplateSave";
import InvestigationTemplateList from "../pages/Template/InvestigationTemplate/InvestigationTemplateList";
import LabMasterList from "../pages/Laboratory/LabMaster/LabMasterList";
import LabMasterSave from "../pages/Laboratory/LabMaster/LabMasterSave";
import HospitalMasterList from "../pages/HospitalMaster/HospitalMasterList";
import HospitalMasterSave from "../pages/HospitalMaster/HospitalMasterSave";
import SymptomsList from "../pages/Symptoms/SymptomsList";
import SymptomsSave from "../pages/Symptoms/SymptomsSave";
import StaffList from "../pages/Team/Staff/StaffList";
import StaffSave from "../pages/Team/Staff/StaffSave";
import PatientMasterList from "../pages/Patient/PatientMaster/PatientMasterList";
import PatientMasterSave from "../pages/Patient/PatientMaster/PatientMasterSave";
import MedicalRepresentativeSave from "../pages/MedicalRepresentative/MedicalRepresentativeMaster/MedicalRepresentativeSave";
import MedicalRepresentativeList from "../pages/MedicalRepresentative/MedicalRepresentativeMaster/MedicalRepresentativeList";
import UsersSave from "../pages/Team/Users/UsersSave";
import UsersList from "../pages/Team/Users/UsersList";
import DoctorList from "../pages/Doctor/Doctor/DoctorList";
import DoctorSave from "../pages/Doctor/Doctor/DoctorSave";
import AddAppointment from "../pages/FrontDesk/AddAppointment/AddAppointment";
import Appointments from "../pages/FrontDesk/ReceptionAppointments/Appointments";
import Certificates from "../pages/OPD/Certificates/Certificates";
import PatientHistory from "../pages/Patient/PatientHistory/PatientHistory";
import DoctorSchedule from "../pages/Doctor/DoctorSchedule/DoctorSchedule";
import OutrichVisits from "../pages/Doctor/OutrichVisits/OutrichVisits";
import OPDBilling from "../pages/Accounts/OPDBilling/OPDBilling";
import Report from "../pages/Report/Report";
import BillingItemMaster from "../pages/BillingItem/BillingItemMaster/BillingItemMaster";
import AddMr from "../pages/FrontDesk/AddMr/AddMr";
import ProcedureTemplate from "../pages/Template/ProcedureTemplate/ProcedureTemplate";
import CertificatesTemplate from "../pages/Template/CertificatesTemplate/CertificatesTemplate";
import OPDClinical from "../pages/OPD/OPDClinical/OPDClinical";
import ProcedureTemplateSave from "../pages/Template/ProcedureTemplate/ProcedureTemplateSave";
import CertificatesTemplateSave from "../pages/Template/CertificatesTemplate/CertificatesTemplateSave";
import CertificatesSave from "../pages/OPD/Certificates/CertificatesSave";
import Profile from "../pages/Profile/Profile";
import Tax from "../pages/BillingItem/BillingItemMaster/Tax";
import TaxSave from "../pages/BillingItem/BillingItemMaster/TaxSave";
import BillingItemMasterSave from "../pages/BillingItem/BillingItemMaster/BillingItemMasterSave";
import SpecialitySave from "../pages/Speciality/SpecialitySave";
import Speciality from "../pages/Speciality/Speciality";
import OPDBillingSave from "../pages/Accounts/OPDBilling/OPDBillingSave";

// const CertificatesTemplate = React.lazy(
//   () => import("../pages/Template/CertificatesTemplate/CertificatesTemplate")
// );
// const OPDClinical = React.lazy(
//   () => import("../pages/OPD/OPDClinical/OPDClinical")
// );

export default function RootNavigation({ colorScheme }: { colorScheme: any }) {
  return <RootNavigator colorScheme={colorScheme} />;
}

// function AuthRoute(props: any) {
//     const { userContext, updateUserContext } = React.useContext(PreferencesContext);
//     // if (userContext.userId > 0) {
//     if (userContext.mobileNumber !== "") {
//         return props.children;
//     } else {
//         return <Navigate to="/" replace />;
//     }

// }

function RootNavigator({ colorScheme }: { colorScheme: any }) {
  return (
    <Routes>
      <Route element={<SignIn />} path="/" />
      <Route element={<ForgotPassword />} path="/forgot-password" />
      <Route element={<ResetPassword />} path="/reset-password" />
      <Route
        element={
          <NavBar>
            <Dashboard />
          </NavBar>
        }
        path="/dashboard"
      />
      <Route
        element={
          <NavBar>
            <Master />
          </NavBar>
        }
        path="/master"
      />
      <Route
        element={
          <NavBar>
            <OrganizationSave />
          </NavBar>
        }
        path="/settings/organization/organization-save"
      />
      <Route
        element={
          <NavBar>
            <OrganizationList />
          </NavBar>
        }
        path="/settings/organization"
      />
      <Route
        element={
          <NavBar>
            <BranchList />
          </NavBar>
        }
        path="/settings/branch"
      />
      <Route
        element={
          <NavBar>
            <BranchSave />
          </NavBar>
        }
        path="/settings/branch/branch-save"
      />
      <Route
        element={
          <NavBar>
            <DepartmentList />
          </NavBar>
        }
        path="/settings/department"
      />
      <Route
        element={
          <NavBar>
            <DepartmentSave />
          </NavBar>
        }
        path="/settings/department/department-save"
      />
      <Route
        element={
          <NavBar>
            <Speciality />
          </NavBar>
        }
        path="/settings/speciality"
      />
      <Route
        element={
          <NavBar>
            <SpecialitySave />
          </NavBar>
        }
        path="/settings/speciality/speciality-save"
      />
      <Route
        element={
          <NavBar>
            <Settings />
          </NavBar>
        }
        path="/settings"
      />

      <Route
        element={
          <NavBar>
            <PatientMasterList />
          </NavBar>
        }
        path="/patient-master"
      />
      <Route
        element={
          <NavBar>
            <PatientMasterSave />
          </NavBar>
        }
        path="/patient-master/patient-master-save"
      />

      <Route
        element={
          <NavBar>
            <DoctorList />
          </NavBar>
        }
        path="/master/doctor"
      />
      <Route
        element={
          <NavBar>
            <DoctorSave />
          </NavBar>
        }
        path="/master/doctor/doctor-save"
      />

      <Route
        element={
          <NavBar>
            <MedicalRepresentativeList />
          </NavBar>
        }
        path="master/medical-representative"
      />
      <Route
        element={
          <NavBar>
            <MedicalRepresentativeSave />
          </NavBar>
        }
        path="/master/medical-representative/medical-representative-save"
      />

      <Route
        element={
          <NavBar>
            <DiagnosisTypeList />
          </NavBar>
        }
        path="master/diagnosis-type"
      />
      <Route
        element={
          <NavBar>
            <DiagnosisTypeSave />
          </NavBar>
        }
        path="/master/diagnosis-type/diagnosis-type-save"
      />
      <Route
        element={
          <NavBar>
            <ICDCodesList />
          </NavBar>
        }
        path="master/icd-codes"
      />
      <Route
        element={
          <NavBar>
            <ICDCodesSave />
          </NavBar>
        }
        path="/master/icd-codes/icd-codes-save"
      />

      <Route
        element={
          <NavBar>
            <DiagnosisMasterList />
          </NavBar>
        }
        path="master/diagnosis-master"
      />
      <Route
        element={
          <NavBar>
            <DiagnosisMasterSave />
          </NavBar>
        }
        path="/master/diagnosis-master/diagnosis-master-save"
      />
      <Route
        element={
          <NavBar>
            <MedicineMasterList />
          </NavBar>
        }
        path="/master/medicine-master"
      />
      <Route
        element={
          <NavBar>
            <MedicineMasterSave />
          </NavBar>
        }
        path="/master/medicine-master/medicine-master-save"
      />
      <Route
        element={
          <NavBar>
            <MedicineGroupList />
          </NavBar>
        }
        path="/master/medicine-group"
      />
      <Route
        element={
          <NavBar>
            <MedicineGroupSave />
          </NavBar>
        }
        path="/master/medicine-group/medicine-group-save"
      />
      <Route
        element={
          <NavBar>
            <MedicineGenericNameList />
          </NavBar>
        }
        path="/master/medicine-Generic-name"
      />
      <Route
        element={
          <NavBar>
            <MedicineGenericNameSave />
          </NavBar>
        }
        path="/master/medicine-generic-name/medicine-generic-name-save"
      />
      <Route
        element={
          <NavBar>
            <MedicinecompanyList />
          </NavBar>
        }
        path="/master/medicine-company"
      />
      <Route
        element={
          <NavBar>
            <MedicinecompanySave />
          </NavBar>
        }
        path="/master/medicine-company/medicine-company-save"
      />
      <Route
        element={
          <NavBar>
            <MedicineFormulationList />
          </NavBar>
        }
        path="/master/medicine-formulation"
      />
      <Route
        element={
          <NavBar>
            <MedicineFormulationSave />
          </NavBar>
        }
        path="/master/medicine-formulation/medicine-formulation-save"
      />
      <Route
        element={
          <NavBar>
            <MedicineUsedAsList />
          </NavBar>
        }
        path="/master/medicine-used-as"
      />
      <Route
        element={
          <NavBar>
            <MedicineUsedAsSave />
          </NavBar>
        }
        path="/master/medicine-used-as/medicine-used-as-save"
      />
      <Route
        element={
          <NavBar>
            <MedicineDiluentNameList />
          </NavBar>
        }
        path="/master/medicine-diluent-name"
      />
      <Route
        element={
          <NavBar>
            <MedicineDiluentNameSave />
          </NavBar>
        }
        path="/master/medicine-diluent-name/medicine-diluent-name-save"
      />
      <Route
        element={
          <NavBar>
            <InvestigationMasterList />
          </NavBar>
        }
        path="master/investigation-master"
      />
      <Route
        element={
          <NavBar>
            <InvestigationMasterSave />
          </NavBar>
        }
        path="/master/investigation-master/investigation-master-save"
      />
      <Route
        element={
          <NavBar>
            <FollowUpDurationList />
          </NavBar>
        }
        path="master/followup-duration"
      />
      <Route
        element={
          <NavBar>
            <FollowUpDurationSave />
          </NavBar>
        }
        path="/master/followup-duration/followup-duration-save"
      />
      <Route
        element={
          <NavBar>
            <FollowUpReasonList />
          </NavBar>
        }
        path="master/followup-reason"
      />
      <Route
        element={
          <NavBar>
            <FollowUpReasonSave />
          </NavBar>
        }
        path="/master/followup-reason/followup-reason-save"
      />
      <Route
        element={
          <NavBar>
            <FollowUpInstructionList />
          </NavBar>
        }
        path="master/followup-instruction"
      />
      <Route
        element={
          <NavBar>
            <FollowUpInstructionSave />
          </NavBar>
        }
        path="/master/followup-instruction/followup-instruction-save"
      />

      <Route
        element={
          <NavBar>
            <DiagnosisTemplateList />
          </NavBar>
        }
        path="master/diagnosis-template"
      />
      <Route
        element={
          <NavBar>
            <DiagnosisTemplateSave />
          </NavBar>
        }
        path="/master/diagnosis-template/diagnosis-template-save"
      />
      <Route
        element={
          <NavBar>
            <InvestigationTemplateList />
          </NavBar>
        }
        path="master/investigation-template"
      />
      <Route
        element={
          <NavBar>
            <InvestigationTemplateSave />
          </NavBar>
        }
        path="/master/investigation-template/investigation-template-save"
      />
      <Route
        element={
          <NavBar>
            <MedicineTemplateList />
          </NavBar>
        }
        path="master/medicine-template"
      />
      <Route
        element={
          <NavBar>
            <MedicineTemplateSave />
          </NavBar>
        }
        path="/master/medicine-template/medicine-template-save"
      />

      <Route
        element={
          <NavBar>
            <DietandNutritionInstructionList />
          </NavBar>
        }
        path="/master/diet-and-nutrition-instruction"
      />
      <Route
        element={
          <NavBar>
            <DietandNutritionInstructionSave />
          </NavBar>
        }
        path="/master/diet-and-nutrition-instruction/diet-and-nutrition-instruction-save"
      />
      <Route
        element={
          <NavBar>
            <InvestigationInstructionList />
          </NavBar>
        }
        path="/master/investigation-instruction"
      />
      <Route
        element={
          <NavBar>
            <InvestigationInstructionSave />
          </NavBar>
        }
        path="/master/investigation-instruction/investigation-instruction-save"
      />
      <Route
        element={
          <NavBar>
            <MedicineInstructionList />
          </NavBar>
        }
        path="/master/medicine-instruction"
      />
      <Route
        element={
          <NavBar>
            <MedicineInstructionSave />
          </NavBar>
        }
        path="/master/medicine-instruction/medicine-instruction-save"
      />
      <Route
        element={
          <NavBar>
            <SpecialInstructionList />
          </NavBar>
        }
        path="master/special-instruction"
      />
      <Route
        element={
          <NavBar>
            <SpecialInstructionSave />
          </NavBar>
        }
        path="/master/special-instruction/special-instruction-save"
      />
      <Route
        element={
          <NavBar>
            <AlertInstructionList />
          </NavBar>
        }
        path="/master/alert-instruction"
      />
      <Route
        element={
          <NavBar>
            <AlertInstructionSave />
          </NavBar>
        }
        path="/master/alert-instruction/alert-instruction-save"
      />

      <Route
        element={
          <NavBar>
            <GeneralInstructionList />
          </NavBar>
        }
        path="master/general-instruction"
      />
      <Route
        element={
          <NavBar>
            <GeneralInstructionSave />
          </NavBar>
        }
        path="/master/general-instruction/general-instruction-save"
      />

      <Route
        element={
          <NavBar>
            <ConsumerSave />
          </NavBar>
        }
        path="/consumer/consumer-save"
      />
      <Route
        element={
          <NavBar>
            <ConsumerList />
          </NavBar>
        }
        path="consumer"
      />

      <Route
        element={
          <NavBar>
            <MedicineTemplateList />
          </NavBar>
        }
        path="master/medicine-template"
      />
      <Route
        element={
          <NavBar>
            <MedicineTemplateSave />
          </NavBar>
        }
        path="/master/medicine-template/medicine-template-save"
      />

      <Route
        element={
          <NavBar>
            <LabMasterList />
          </NavBar>
        }
        path="master/laboratory-master"
      />
      <Route
        element={
          <NavBar>
            <LabMasterSave />
          </NavBar>
        }
        path="/master/laboratory-master/laboratory-master-save"
      />

      <Route
        element={
          <NavBar>
            <HospitalMasterList />
          </NavBar>
        }
        path="master/hospital-master"
      />
      <Route
        element={
          <NavBar>
            <HospitalMasterSave />
          </NavBar>
        }
        path="/master/hospital-master/hospital-master-save"
      />

      <Route
        element={
          <NavBar>
            <SymptomsList />
          </NavBar>
        }
        path="master/symptoms"
      />
      <Route
        element={
          <NavBar>
            <SymptomsSave />   
          </NavBar>
        }
        path="/master/symptoms/symptoms-save"
      />

      <Route
        element={
          <NavBar>
            <StaffList />
          </NavBar>
        }
        path="staff"
      />
      <Route
        element={
          <NavBar>
            <StaffSave />
          </NavBar>
        }
        path="/staff/staff-save"
      />

      <Route
        element={
          <NavBar>
            <UsersList />
          </NavBar>
        }
        path="users"
      />
      <Route
        element={
          <NavBar>
            <UsersSave />
          </NavBar>
        }
        path="/users/users-save"
      />

      <Route
        element={
          <NavBar>
            <OPDClinical />
          </NavBar>
        }
        path="opd-clinical"
      />

      <Route
        element={
          <NavBar>
            <AddMr />
          </NavBar>
        }
        path="add-mr"
      />
      <Route
        element={
          <NavBar>
            <AddMr />
          </NavBar>
        }
        path="patient-save"
      />
      <Route
        element={
          <NavBar>
            <AddAppointment />
          </NavBar>
        }
        path="add-appointment"
      />
      <Route
        element={
          <NavBar>
            <Appointments />
          </NavBar>
        }
        path="reception-appointments"
      />
      <Route
        element={
          <NavBar>
            <Certificates />
          </NavBar>
        }
        path="certificates"
      />
      <Route
        element={
          <NavBar>
            <CertificatesSave />
          </NavBar>
        }
        path="/certificates/certificates-save"
      />
      <Route
        element={
          <NavBar>
            <PatientHistory />
          </NavBar>
        }
        path="patient-history"
      />
      <Route
        element={
          <NavBar>
            <ProcedureTemplate />
          </NavBar>
        }
        path="master/procedure-template"
      />
      <Route
        element={
          <NavBar>
            <ProcedureTemplateSave />
          </NavBar>
        }
        path="/master/procedure-template/procedure-template-save"
      />
      <Route
        element={
          <NavBar>
            <CertificatesTemplate />
          </NavBar>
        }
        path="master/certificates-template"
      />
      <Route
        element={
          <NavBar>
            <CertificatesTemplateSave />
          </NavBar>
        }
        path="/master/certificates-template/certificates-template-save"
      />
      <Route
        element={
          <NavBar>
            <Profile />
          </NavBar>
        }
        path="profile"
      />

      {/* Empty Pages */}

      <Route
        element={
          <NavBar>
            <DoctorSchedule />
          </NavBar>
        }
        path="doctor-schedule"
      />
      <Route
        element={
          <NavBar>
            <OutrichVisits />
          </NavBar>
        }
        path="outreach-visits"
      />
      <Route
        element={
          <NavBar>
            <OPDBilling />
          </NavBar>
        }
        path="opd-billing"
      />
      <Route
        element={
          <NavBar>
            <OPDBillingSave />
          </NavBar>
        }
        path="opd-billing/opd-billing-save"
      />
      <Route
        element={
          <NavBar>
            <Report />
          </NavBar>
        }
        path="report"
      />
      <Route
        element={
          <NavBar>
            <BillingItemMaster />
          </NavBar>
        }
        path="/master/billing-item-master"
      />
      <Route
        element={
          <NavBar>
            <BillingItemMasterSave />
          </NavBar>
        }
        path="/master/billing-item-master/billing-item-master-save"
      />
      <Route
        element={
          <NavBar>
            <Tax />
          </NavBar>
        }
        path="/master/Tax"
      />
      <Route
        element={
          <NavBar>
            <TaxSave />
          </NavBar>
        }
        path="/master/Tax/TaxSave"
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
