import React, { useState } from 'react';
import { InputAdornment, OutlinedInput } from '@mui/material';

const BloodPresureInput = ({ label, name, value, onChange, error, helperText }) => {
    const [internalValue, setInternalValue] = useState('');
    // const [errorText, setErrorText] = useState('');

    const validateMonthYear = (input) => {
        // const regex = /^(0[1-9]|1[0-2])\/\d{2}$/;
        // if (!regex.test(input)) {
        //     setErrorText('Invalid format. Use MM/YY.');
        //     onChange(name, '');
        //     return false;
        // }

        // const currentDate = dayjs();
        // const [month, year] = input.split('/').map(Number);
        // const currentYear = currentDate.year() % 100; // Last two digits of the current year

        // if (year < currentYear || (year === currentYear && month <= currentDate.month() + 1)) {
        //     setErrorText('Date must be in the future.');
        //     onChange(name, '');
        //     return false;
        // }

        // Create and format the date
        // const fullYear = 2000 + year;
        // const date = new Date(fullYear, month - 1, 1);
        // const formatted = format(date, 'yyyy-MM-dd');

        // setErrorText('');
        // onChange(name, formatted);
        // return true;
    };

    const handleChange = (event) => {
        let inputValue = event.target.value;

        // Automatically add '/' after entering the month
        if (inputValue.length === 3 && !inputValue.includes('/')) {
            // const month = parseInt(inputValue, 10);
            // if (month >= 1 && month <= 12) {
            inputValue = inputValue + '/';
            // } else {
            //     setErrorText('Month must be between 01 and 12.');
            //     return;
            // }
        }
        // Limit the input length to 7 characters (MM/YY)
        if (inputValue.length > 8) {
            inputValue = inputValue.slice(0, 8);
        }

        setInternalValue(inputValue);
        if (inputValue.length === 8) {
            validateMonthYear(inputValue);
        }
        //  else {
        //     setErrorText('');
        // }
    };

    return (
        <OutlinedInput
            label={label}
            value={internalValue}
            onChange={handleChange}
            size='small'
            // error={!!error || !!errorText}
            // helperText={helperText || errorText}
            placeholder=""
            endAdornment={<InputAdornment position="end">mmHg</InputAdornment>}
            inputProps={{ maxLength: 7 }}
        />
    );
};

export default BloodPresureInput;