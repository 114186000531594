import React, { useContext, useEffect } from "react";
import { useState } from 'react';
import LoadingButton from "@mui/lab/LoadingButton";
import "../../theme/SignIn.scss";
import {
    Box,
    FormControl,
    Fade,
    TextField,
    Typography,
    Grid,
    Paper,
    FormLabel,
    styled,
    Stack,
    Alert,
    InputAdornment,
    IconButton,
} from "@mui/material";
import logo from "../../assets/images/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ForgotPassword, Info, ResetPassword } from "../../services/AuthService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { PreferencesContext } from "../../PreferenceContext";
import { AlertType } from "../../services/models/AlertType";



const CustomTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: "#FAFBFD", // Set the desired background color
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: theme.palette.grey[300],
            borderWidth: "1px",
            BorderStyle: "solid",
        },
        "&:hover fieldset": {
            borderColor: theme.palette.grey[500],
            borderWidth: "1px",
            BorderStyle: "solid",
        },
        "&.Mui-focused fieldset": {
            borderWidth: "2px",
            borderColor: theme.palette.primary.main,
        },
    },
}));

const initialState = {
    email: "",
    token: "",
    newPassword: "",
    confirmPassword: "",
    showPassword: false,
    showConfPassword: false,
    isSubmitted: false,
    isSubmitting: false,
};
const validateSchema = yup.object({
    newPassword: yup.string()
        .required("New Password is required.")
        .matches(
            /^(?=.{8,})(?=.*[a-z])((?=.*[\d+]))(?=.*[A-Z])(?=.*[!@#$%^&+=]).*$/,
            "Password should contain at least 8 characters, including one capital, one small, one special letter and one number"
        )
    ,
    confirmPassword: yup.string()
        .required("Confirm Password is required.")
        .oneOf([yup.ref("newPassword"), null], "Confirm password doesn't match with New Password"),
});
export default function Resetpassword(props: any) {
    const [values, setValues] = React.useState(initialState);
    const [loading, setLoading] = React.useState(false);
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [data, setData] = React.useState(initialState);
    const [showAlert, setAlertMessage] = React.useState({
        isSuccess: false,
        type: 0,
        message: "",
    });

    let navigate = useNavigate();
    let location = useLocation();


    const onClickReturn = () => {
        navigate("/");
    };


    // For Navbar visible
    async function init() {
        const data: any = await Info(null);
        const query = new URLSearchParams(location.search);
        const localToken = query.get("t") || "";
        const localEmail = query.get("e") || "";

        setValues({ ...values, token: localToken, email: localEmail });

        let useContextData = userContext;
        updateUserContext({ ...useContextData, isMenuVisible: false });
    }

    //POST Method
    async function submitForm(values: any, resetForm: any) {
        setLoading(true);
        let newPassword = values.newPassword;
        let confirmPassword = values.confirmPassword;


        const query = new URLSearchParams(location.search);
        const token = query.get("t") || "";
        const email = query.get("e") || "";

        let useContextData = userContext;
        // setValue({ ...useContextData, isLoadding: true });
        //setData({ ...data, isSubmitting: true });
        try {
            const formData = {
                Password: newPassword,
                ConfirmPassword: confirmPassword,
                Email: email,
                Token: token,
            }

            const result: any = await ResetPassword(formData);

            // updateUserContext({ ...userContext, isAlert: true, alertMessage: resultUserSave.message, alertType: AlertType.Success });

            updateUserContext({ ...useContextData, token: values.token, isMenuVisible: false, isAlert: true, alertMessage: result.message, alertType: AlertType.Success });

            setLoading(false);


            if (result) {
                setData({
                    ...data,
                    isSubmitting: false,
                    isSubmitted: true,
                    email: values.email,
                });
                navigate("/");
                setIsSubmitting(false);
                if (result) {
                    setAlertMessage({
                        type: 1,
                        isSuccess: true,
                        message: result.message.message as string,
                    });
                }
                // setData({
                //   ...data,
                //   isSubmitting: false,
                //   isSubmitted: true,
                //   email: values.email,
                //   newPassword:"",
                //   confirmPassword:"",
                // });
            }

        } catch (error) {
            setLoading(false);

            setData({
                ...data,
                isSubmitting: false,
                isSubmitted: false,
                email: values.email,
            });


            setIsSubmitting(false);
            if (error) {
                setAlertMessage({
                    type: 2,
                    isSuccess: false,
                    message: error as string,
                });
            }
        }
        setTimeout(() => {
            setAlertMessage({
                type: 0,
                isSuccess: false,
                message: "",
            })
            // window.location.reload();
        }, 5000)


    }

    //--show & hide function for NewPassword--
    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    //--show & hide function for CondormPassword--
    const handleClickShowConfPassword = () => {
        setValues({
            ...values,
            showConfPassword: !values.showConfPassword,
        });
    };


    const methods = useForm({
        resolver: yupResolver(validateSchema),
    });

    useEffect(() => {
        init();
    }, []);


    return (
        <Fade in={true} timeout={{ enter: 500 }}>
            <Box className="signin-container">
                <Paper elevation={3} className="paper-singin">
                    <Grid className="img-wrapper" item xs={6}>
                        {" "}
                    </Grid>
                    <Grid item className="signin-wrapper" xs={4}>
                        <Box className="box-container" sx={{ my: 2 }}>
                            <Paper className="paper-container-forgot paper-pre-signin">
                                <Grid
                                    display="flex"
                                    justifyContent="flex-end"
                                    pb={5}
                                    item
                                >
                                    <img
                                        loading="lazy"
                                        className="img-page"
                                        src={logo}
                                        alt="logo"
                                    /></Grid>
                                <FormProvider {...methods}>
                                    <form onSubmit={methods.handleSubmit(submitForm)}>
                                        <Grid>
                                            <Grid>
                                                <Box
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "left",
                                                    }}>
                                                    <b>Reset your password</b>

                                                </Box>
                                            </Grid>
                                            <Grid container spacing={2} marginTop={1}>
                                                <Grid item xs={12}>
                                                    <FormLabel sx={{ display: 'flex', justifyContent: 'left', fontSize: '13px' }} className={`fileds-label-text  ${methods.formState.errors.newPassword ? 'validation-message' : ''}`}>Password</FormLabel>
                                                    <CustomTextField
                                                        id="newPassword"
                                                        //label="New Password*"
                                                        fullWidth
                                                        size="small"
                                                        margin="dense"
                                                        // value={props.values.password}
                                                        // onChange={props.handleChange}
                                                        // onBlur={props.handleBlur}
                                                        {...methods.register('newPassword')}
                                                        error={
                                                            !!methods.formState.errors.newPassword
                                                        }
                                                        className={`text-input ${methods.formState.errors.newPassword ? 'error' : ''}`}
                                                        inputProps={{ enterKeyHint: 'next' }}
                                                        type={values.showPassword ? "text" : "password"}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPassword}
                                                                        edge="end"
                                                                    >
                                                                        {values.showPassword? (
                                                                            <VisibilityOff />
                                                                        ) : (
                                                                            <Visibility />
                                                                        )}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                    <Typography variant="inherit" className="validation-message" sx={{ textAlign: 'left' }}>
                                                        {methods.formState.errors.newPassword?.message}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormLabel sx={{ display: 'flex', justifyContent: 'left', fontSize: '13px' }} className={`fileds-label-text  ${methods.formState.errors.confirmPassword ? 'validation-message' : ''}`}>Password</FormLabel>
                                                    <CustomTextField
                                                        id="confirmPassword"
                                                        //label="Confirm Password*"
                                                        fullWidth
                                                        size="small"
                                                        margin="dense"
                                                        // value={props.values.password}
                                                        // onChange={props.handleChange}
                                                        // onBlur={props.handleBlur}
                                                        {...methods.register('confirmPassword')}
                                                        error={
                                                            !!methods.formState.errors.confirmPassword
                                                        }
                                                        className={`text-input ${methods.formState.errors.confirmPassword ? 'error' : ''}`}
                                                        inputProps={{ enterKeyHint: 'next' }}
                                                        type={values.showPassword ? "text" : "password"}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle Confpassword visibility"
                                                                        onClick={handleClickShowConfPassword}
                                                                        edge="end"
                                                                    >
                                                                        {values.showConfPassword? (
                                                                            <VisibilityOff />
                                                                        ) : (
                                                                            <Visibility />
                                                                        )}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                    <Typography variant="inherit" className="validation-message" sx={{ textAlign: 'left' }}>
                                                        {methods.formState.errors.confirmPassword?.message}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3} marginTop={0}>
                                                <Grid item xs={12} textAlign="right">
                                                    <LoadingButton
                                                        className="btn"
                                                        color="primary"
                                                        //onClick={() => setLoading(true)}
                                                        // loading={loading}
                                                        loading={loading}
                                                        loadingPosition="start"
                                                        fullWidth
                                                        startIcon={<></>}
                                                        variant="contained"
                                                        type="submit"

                                                    >
                                                        Submit
                                                    </LoadingButton>
                                                </Grid>
                                            </Grid>
                                            <Grid spacing={3} marginTop={3}>
                                                <Box
                                                    className="text-primary"
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Link
                                                        to="/"
                                                        style={{
                                                            textDecoration: "none",
                                                            color: '#2662f0',
                                                            fontSize: "13px",
                                                            fontFamily: "$font-family-inter",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                marginRight: "10px",
                                                                lineHeight: "1px",
                                                            }}
                                                        >
                                                            <ArrowBackIcon sx={{ fontSize: "13px" }} />
                                                        </span>
                                                        <span

                                                        >
                                                            Back to Sign in
                                                        </span>
                                                    </Link>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </FormProvider>
                            </Paper>
                        </Box>
                    </Grid>
                </Paper>
            </Box>
        </Fade>
    );

}



