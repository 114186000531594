import { GET, POST } from "./common/http";
import { BASE_URL } from "./common/const";

export const GetConsumerDetail = (params: any) =>
  GET(`${BASE_URL}/api/Consumer/GetDetail?` + params, null);

export const GetConsumerList = (params: any) =>
  GET(`${BASE_URL}/api/Consumer/GetList?` + params, null);

export const CreateConsumer = (data: any) =>
  POST(`${BASE_URL}/api/Consumer/Create`, data);

export const UpdateConsumer = (data: any) =>
  POST(`${BASE_URL}/api/Consumer/Update`, data);    

export const DeleteConsumer = (data: any) =>
  POST(`${BASE_URL}/api/Consumer/Delete`, data);






