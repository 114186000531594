import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../theme/style.scss";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  OutlinedInput,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Title from "../../../components/Title";
import { Formik } from "formik";
import { CustomSelect } from "../../../components/CustomFields/CustomSelect";
import 'react-phone-number-input/style.css';
import BreadcrumbsComponent from "../../../components/BreadcrumbsComponent/BreadcrumbsComponent";
import CustomBreadcrumb from "../../../components/BreadcrumbsComponent/CustomBreadcrumbs";
import PhoneInput from 'react-phone-number-input'
import BloodPresureInput from "../../../components/BloodPresure/BloodPresureInput";
import CustomInput from "../../../components/CustomInput/custominput";


const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#FAFBFD", // Set the desired background color
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));


export default function NewPatient() {
  const path = useLocation().pathname
  // console.log("path:", path)
  const [value, setValue] = useState("patient");
  const [pr, setPr] = useState(' ');
  const [spo2, setSpo2] = useState(' ');

  const handleSpo2Change = (e) => {
    const value = e.target.value;
    if (/^\d{0,3}$/.test(value)) { // Allow only numbers and a maximum of 3 digits
      setSpo2(value);
    }
  }

  const handlePrChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,3}$/.test(value)) { // Allow only numbers and a maximum of 3 digits
      setPr(value);
    }
  }
  const navigate = useNavigate();
  useEffect(() => {
    console.log("Useeffect calling....")
    setValue(path === '/patient-save' ? "patient" : 'patient')
  }, [path])
  interface ColourOption {
    value: string;
    label: string;
  }
  const colourOptions: ColourOption[] = [
    { value: 'patient', label: 'Patient' },
    { value: 'mr', label: 'MR' },
    { value: 'guest', label: 'Guest' }
  ];
  const stateOptions: ColourOption[] = [
    { value: 'gujrat', label: 'Gujrat' },
    { value: 'rajesthan', label: 'Rajesthan' },
  ];
  const visitTypeOptions: ColourOption[] = [
    { value: 'gujrat', label: 'New' },
    { value: 'rajesthan', label: 'Revisit' },
  ];
  const countryOptions: ColourOption[] = [
    { value: 'india', label: 'India' },
    { value: 'usa', label: 'USA' },
  ];

  return (
    <Box className="main-container" pl={2}>
      <Grid item xs={12} container className="page-header-save">
        <Grid xs={12} sm={12} item className="page-breadcrumbs">
          <CustomBreadcrumb />
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
        }}
        onSubmit={async (values) => {
          console.log("values", values)
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} mb={3} className="group-container">
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Basic Information</Typography>
              </Grid>

              {/* Type Selection */}
              <Grid item xs={12} sm={3}>
                <FormControl component="fieldset" fullWidth>
                  <FormLabel className="fileds-label-text">Type</FormLabel>
                  <RadioGroup
                    row
                    id="referredBy"
                    name="referredBy"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                  >
                    {colourOptions.map((option) => (
                      <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid xs={12} sm={12} md={12} />

              {/* Conditional Rendering */}
              {value === 'mr' && (
                <>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                      <FormLabel className="fileds-label-text">MR</FormLabel>
                      <CustomSelect
                        id="mr"
                        FieldName="mr"
                        options={[]}
                        openMenuOnFocus
                        isClearable
                        isSearchable
                        menuPortalTarget={document.body}
                        handleChange={(e) => console.log("event:", e)}
                        Value=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                      <FormLabel className="fileds-label-text">Visit Type</FormLabel>
                      <CustomSelect
                        isNotCreatable
                        id="visitType"
                        FieldName="visitType"
                        options={visitTypeOptions}
                        openMenuOnFocus
                        isClearable
                        isSearchable
                        menuPortalTarget={document.body}
                        handleChange={(e) => console.log("event:", e)}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}

              {value === 'patient' && (
                <>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                      <FormLabel className="fileds-label-text">Patient</FormLabel>
                      <CustomSelect
                        id="patient"
                        FieldName="patient"
                        options={[]}
                        openMenuOnFocus
                        isClearable
                        isSearchable
                        menuPortalTarget={document.body}
                        handleChange={(e) => console.log("event:", e)}
                        Value=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                      <FormLabel className="fileds-label-text">Visit Type</FormLabel>
                      <CustomSelect
                        isNotCreatable
                        id="visitType"
                        FieldName="visitType"
                        options={visitTypeOptions}
                        openMenuOnFocus
                        isClearable
                        isSearchable
                        menuPortalTarget={document.body}
                        handleChange={(e) => console.log("event:", e)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}/>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                      <FormLabel className="fileds-label-text">Referral Doctor</FormLabel>
                      <CustomSelect
                        isNotCreatable
                        id="referralDoctor"
                        FieldName="referralDoctor"
                        openMenuOnFocus
                        isClearable
                        isSearchable
                        menuPortalTarget={document.body}
                        handleChange={(e) => console.log("event:", e)}
                        Value=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box className="branch-checkbox">
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Emergency"
                        />
                      </FormGroup>
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>


          <Grid item xs={12} mb={3} className="group-container">
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Contact Information</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Address 1</FormLabel>
                  <CustomTextField
                    name="address1"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Address 2</FormLabel>
                  <CustomTextField
                    name="address2"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">City</FormLabel>
                  <CustomTextField
                    name="city"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Postal Code</FormLabel>
                  <CustomTextField
                    name="postalCode"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">State</FormLabel>
                  <CustomSelect
                    isNotCreatable={true}
                    id={"state"}
                    FieldName={"state"}
                    //options={countryData}
                    openMenuOnFocus
                    isClearable
                    isSearchable
                    menuPortalTarget={document.querySelector("body")}
                    handleChange={(e) => {
                      console.log("event:", e)
                    }}
                    Value=""
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Country</FormLabel>
                  <CustomSelect
                    isNotCreatable={true}
                    id={"country"}
                    FieldName={"country"}
                    //options={countryData}
                    openMenuOnFocus
                    isClearable
                    isSearchable
                    menuPortalTarget={document.querySelector("body")}
                    handleChange={(e) => {
                      console.log("event:", e)
                    }}
                    Value=""
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Email</FormLabel>
                  <CustomTextField
                    name="email"
                    placeholder=""
                    autoCapitalize="none"
                    type="email"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div className="input-group">
                  <label htmlFor="phone">Primary Mobile Number</label>
                  <PhoneInput
                    id="phone"
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="IN"
                    value={value}
                    onChange={setValue}
                    placeholder="Enter phone number"
                    className="phone-input"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div className="input-group">
                  <label htmlFor="phone">Alternate Mobile Number</label>
                  <PhoneInput
                    id="phone"
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="IN"
                    value={value}
                    onChange={setValue}
                    placeholder="Enter phone number"
                    className="phone-input"
                  />
                </div>
              </Grid>

            </Grid>
          </Grid>

          {value === 'patient' && <Grid item xs={12} mb={3} className="group-container">
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Profile</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    WT
                  </FormLabel>
                  <CustomInput
                    // label= "Weight"
                    maxLength={7}
                    endAdornment="Kg"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    HT
                  </FormLabel>

                  <CustomInput
                    maxLength={7}
                    endAdornment="cm" />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    BMI
                  </FormLabel>
                  <CustomInput maxLength={7} endAdornment={<InputAdornment position="end">kg/m<sup>2</sup></InputAdornment>} />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    BSA
                  </FormLabel>
                  <CustomInput maxLength={7} endAdornment={<InputAdornment position="end">m<sup>2</sup></InputAdornment>} />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    PR
                  </FormLabel>
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    type="number"
                    inputMode="decimal"
                    value={pr}
                    onChange={handlePrChange}
                    size="small"
                    endAdornment={<InputAdornment position="end"> bpm</InputAdornment>}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                      className: 'no-spinner'
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    SpO2
                  </FormLabel>
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    size="small"
                    type="number"
                    value={spo2}
                    onChange={handleSpo2Change}
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      className: 'no-spinner',
                      'aria-label': 'weight',
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    BP
                  </FormLabel>
                  <BloodPresureInput
                    type="number"
                  />
                 
                </FormControl>
              </Grid> 
            </Grid>
          </Grid>}
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
              <LoadingButton
                color="primary"
                tabIndex={3}
                loadingPosition="start"
                startIcon={<></>}
                variant="contained"
                type="submit"
                className="primary-button"
                onClick={() => navigate(-1)}
                name="submit"
              >
                Save
              </LoadingButton>
              <LoadingButton
                tabIndex={3}
                loadingPosition="start"
                startIcon={<></>}
                variant="outlined"
                type="submit"
                name="submit"
                className="secondary-button"
                color="secondary"
                onClick={() => navigate(-1)}
              >
                Cancel
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>

      </Formik>

    </Box>
  );
}
