import React, { useContext, useEffect, useState } from 'react'
import Title from '../../../components/Title'
import {
  Box, Grid, IconButton, InputAdornment, TextField, Button, styled, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
  Popover,
  Pagination,
  Stack,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate } from 'react-router-dom';
import '../../../theme/style.scss';
import { DeleteStaff, GetStaffList } from '../../../services/StaffService';
import { PreferencesContext } from '../../../PreferenceContext';
import { AlertType } from '../../../services/models/AlertType';
import ClearIcon from "@mui/icons-material/Clear";
import BreadcrumbsComponent from '../../../components/BreadcrumbsComponent/BreadcrumbsComponent';
import CustomBreadcrumbs from '../../../components/BreadcrumbsComponent/CustomBreadcrumbs';
import { PAGE_SIZE } from '../../../services/common/const';
import { EncryptDecryptService } from '../../../services/common/EncryptionService';

// const CustomTextField = styled(TextField)(({ theme }) => ({
//   backgroundColor: '#FAFBFD', // Set the desired background color
//   '& .MuiOutlinedInput-root': {
//     '& fieldset': {
//       borderColor: theme.palette.grey[300],
//       borderWidth: '1px',
//       BorderStyle: 'solid'
//     },
//     '&:hover fieldset': {
//       borderColor: theme.palette.grey[500],
//       borderWidth: '1px',
//       BorderStyle: 'solid'
//     },
//     '&.Mui-focused fieldset': {
//       borderColor: theme.palette.primary.main,
//       borderWidth: "2px",
//     },
//   },
// }));

interface Data {
  staId: number;
  firstName: string;
  lastName: string;
  department: string;
  specialization: string;
  degree: string;
  primaryContactNumber: string;
  joiningDate: string;
  email: string;
  none: string;
}

const parameters: any = {
  pageIndex: 1,
  pageSize: 5,
  sortColumn: "",
  sortDirection: "",
  searchValue: "",
};

const staff: any = {
  staffCollcection: []
}

// import ClearIcon from "@mui/icons-material/Clear";
export default function StaffList() {
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  const [order, setOrder] = React.useState<Order>("");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("none");
  const [rowsPerPage, setRowsPerPage] = React.useState(PAGE_SIZE);
  const [param, setParam] = useState(parameters);
  const [page, setPage] = React.useState(0);
  const [page_Index, setPage_Index] = useState(1);
  const [value, setValue] = useState("");
  const [counData, setCounData] = useState(0);
  const [Curr_page, setCurrPage] = React.useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [pageStartIndex, setPageStartIndex] = useState(1);
  const [pageEndIndex, setPageEndIndex] = useState(1);
  const [staListData, setStaListData] = useState([])
  const [openDeleteDilog, setOpenDeleteDilog] = React.useState(false);
  const [resultDataCollection, setResultDataCollection] = useState(staff.staffCollcection);
  const [staId, setStaId] = useState(0)
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl3] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);

  const rowData = [
    {
      name: "Chintan Shah",
      department: "Cardio",
      specialization: "Specialization",
      degree: "Mbbs",
      mobileNo: "987654321",
      joiningDate: "2022-04-13",
      email: "cintanshah@gmail.com",
    },
    {
      name: "Ashish mehta",
      department: "Cardio",
      specialization: "Specialization",
      degree: "Mbbs",
      mobileNo: "987654321",
      joiningDate: "2022-04-13",
      email: "ashishmehta@gmail.com",
    },

  ]

  // for Edit & Delet pop 
  const handleOpenMore = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: any
  ) => {
    setAnchorEl3(event.currentTarget);
    // setBuyerId(id);
  };
  const handleCloseMore = () => {
    setAnchorEl3(null);
  };

  //  Table Header 
  function EnhancedTableHead(props: EnhancedTableProps) {
    return (
      // checkBox for Header

      <TableHead className='table-header-color'>
        <TableRow>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "15%"
            }}
            align="left"
          >
            Name
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "15%"
            }}
            align="left"
          >
            Department
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "none",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "15%"
            }}
            align="left"
          >
            Specialization
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "none",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "15%"
            }}
            align="left"
          >
            Degree
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "15%"
            }}
            align="left"
          >
            Mobile No.
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "none",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "15%"
            }}
            align="left"
          >
            Joining Date
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "none",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "15%"
            }}
            align="left"
          >
            Email
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "10%"
            }}
            align="left"
          >
            {''}
          </TableCell>

        </TableRow>
      </TableHead>
    );
  }

  useEffect(() => {
    getStaffList()
  }, []);
  useEffect(() => {
    getStaffList()
  }, [param]);

  const getStaffList = async () => {
    let params = new URLSearchParams();
    params.append("consumerId", "1");
    params.append("pageIndex", param.pageIndex);
    params.append("pageSize", param.pageSize);
    params.append("sortColumn", param.sortColumn);
    params.append("sortDirection", param.sortDirection);
    params.append("searchValue", param.searchValue);

    await GetStaffList(params).then((res: any) => {
      let dataCount = 0;
      let showDataCount = 0;
      if (res && res.data.data) {
        const staColl: any =
          res.data.data;

        if (staColl !== null && staColl !== undefined) {
          dataCount = staColl.totalCount;
        }
      }
      showDataCount = Math.ceil(dataCount / 5);
      setCounData(showDataCount);
      setResultDataCollection(res.data.data.staffColl);
      let startIndex = (page_Index - 1) * param.pageSize + 1;
      let endIndex =
        (page_Index - 1) * param.pageSize +
        (res.data.data.staffColl
          ? res.data.data.staffColl.length
          : 0);
      setPageStartIndex(startIndex);
      setPageEndIndex(endIndex);
      setTotalCount(dataCount);
      setStaListData(res.data.data.staffColl)
    }).catch((err) => {
      console.log("err:", err)
    })
  }

  // sorting Table login
  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
  ) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  type Order = "asc" | "desc" | "";

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // pagination
  const handleChangePageNew = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    if (Curr_page !== value) {
      setCurrPage(value);
      setPage_Index(value);
      setParam({ ...param, pageIndex: value });
    }
  };

  // search function
  function searchValue(val: any) {
    if (val === "") {
      setValue("");
    }
    setPage_Index(1);
    setParam({ ...param, searchValue: val.toString(), pageIndex: 1 });
  }

  const edService = new EncryptDecryptService();

  // edit Staff
  const staffEdit = (staffId: any) => {
    let encryptStaffId: any = edService.encrypt(staffId);
    navigate(
      {
        pathname: "staff-save",
        search: `?q=${encodeURIComponent(encryptStaffId)}`,
      },
      {
        state: {
          title: " Edit",
          button: "Update",
          staId: staffId,
        },
      }
    );

  };


  //delete warning dialogbox
  const handleClickOpen = () => {
    setOpenDeleteDilog(true);
  };

  const handleClose = () => {
    setOpenDeleteDilog(false);
  };

  // delete staff
  async function deleteRow(staIds: any) {
    try {
      let DeleteData = {
        staffId: staIds.toString(),
        isDeleted: true,
        dataManagedBy: 1,
      };
      const result: any = await DeleteStaff(DeleteData);
      if (result) {
        getStaffList();
        setAnchorEl3(null);
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: result.message,
          alertType: AlertType.Success,
        });
      } else {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: result.message,
          alertType: AlertType.Error,
        });
      }
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage:
          typeof e === "string" ? (e as string) : (e?.message as string),
        alertType: AlertType.Error,
      });
    }
    handleClose();
  }



  return (
    <>
      <Box className='main-container'>
        <Grid xs={12} container className='page-header'>
          <Grid xs={6} sm={6} item className="page-breadcrumbs">
            <CustomBreadcrumbs />
          </Grid>
          <Grid xs={6} sm={6} item className='page-header-icon'>
            <Button
              color="primary"
              variant="contained"
              onClick={() => navigate('staff-save')}
              size="medium"
              startIcon={<AddIcon />}
            >
              Add
            </Button></Grid>
        </Grid>
        <Grid container className="list-container" sx={{ padding: '16px' }}>
          <Grid item xs={12} sm={4} md={3} lg={2} className='search-bar' sx={{ gap: 2 }} >
            <TextField
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  searchValue(value);
                }
              }}
              placeholder="Search..."
              type="text"
              variant="outlined"
              size="small"
              onChange={(e) => setValue(e.target.value)}
              value={value}
              fullWidth
              sx={{ paddingLeft: '0px' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={() => searchValue(value)}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),

                endAdornment: value && (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => searchValue("")}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={9} lg={10} sx={{ paddingTop: '10px' }} className='search-bar pdf-icon'>
            <img src="./xls.png" alt="" className='pdf-icon-hover' />
            <img src="./pdf.png" alt="" className='pdf-icon-hover' />
          </Grid>

          <TableContainer className='table'>
            <Table>
              <EnhancedTableHead />
              <TableBody>
                {stableSort(
                  resultDataCollection,
                  getComparator(order, orderBy)
                )
                  .slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                  .map((item: any, index: any) => {
                    return (

                      <TableRow>
                        <TableCell className='text-link'
                          sx={{ color: 'red' }}
                          onClick={() => {
                            staffEdit(item.staffId)
                          }}> <Link className='text-link' to="staff-save">{item.firstName} {item.lastName}</Link></TableCell>
                        <TableCell>{item.department}</TableCell>
                        <TableCell sx={{
                          display: {
                            xs: "none",
                            sm: "table-cell",
                            md: "table-cell",
                            lg: "table-cell",
                          }
                        }}>{item.specialization}</TableCell>

                        <TableCell sx={{
                          display: {
                            xs: "none",
                            sm: "table-cell",
                            md: "table-cell",
                            lg: "table-cell",
                          }
                        }}>{item.degree}</TableCell>
                        <TableCell

                        >{item.primaryContactNumber}</TableCell>
                        <TableCell sx={{
                          display: {
                            xs: "none",
                            sm: "table-cell",
                            md: "table-cell",
                            lg: "table-cell",
                          }
                        }}>{item.joiningDate}</TableCell>
                        <TableCell
                          sx={{
                            display: {
                              xs: "none",
                              sm: "table-cell",
                              md: "table-cell",
                              lg: "table-cell",
                            }
                          }}
                        >{item.email}</TableCell>
                        <TableCell align="right">
                          <Tooltip title="More" placement="top">
                            <IconButton
                              onClick={(e: any) =>
                                handleOpenMore(e)
                              }
                            >
                              <MoreVertRoundedIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>

            </Table>
          </TableContainer>

          <Grid
            container
            paddingTop={1}
            className='pagination'
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid item xs={12} sm={6}
              className="pagination-label"
            >

              {pageStartIndex} - {pageEndIndex} of {totalCount}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack
                component="div"
                className='pagination'
              >
                <Pagination
                  color="primary"
                  count={counData}
                  page={page_Index}
                  onChange={handleChangePageNew}
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Box >
      <Popover
        elevation={1}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseMore}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => {
              staffEdit(staId)

            }}>
              <ListItemText primary="Edit" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              className="delete-container"
              onClick={() => handleClickOpen()}
            >
              Delete
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
      <Dialog
        open={openDeleteDilog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this consumer?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => deleteRow(staId)}
            className="button-delete"
          >
            Yes
          </Button>
          <Button onClick={handleClose} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
