import "../../../../../theme/style.scss";
import {
  Alert,
  Box,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
  styled,
  DialogActions,
  Toolbar,
  AppBar,
} from "@mui/material";
import React, { useState } from "react";
import HistoryOfSimilarIllness from "../History/Tabs/Components/HistoryOfSimilarIllness";
import HistoryOfHospitalisation from "../History/Tabs/Components/HistoryOfHospitalisation";
import AnySurgeryDoneInPast from "../History/Tabs/Components/AnySurgeryDoneInPast";
import AddVaccine from "./_components/AddVaccine";
import LoadingButton from "@mui/lab/LoadingButton";

export default function Vaccine() {
  const [showNote, setShowNote] = useState(false);
  const [documentsFiles, setDocumentsFile] = useState<any>([]);
  const [isPastHistory, setIsPastHistory] = useState("");
  const [similarIllness, setSimilarIllness] = useState(false);
  const [hospitalisation, setHospitalisation] = useState(false);
  const [surgeryDoneInPast, setSurgeryDoneInPast] = useState(false);

  //Draggable Table
  const [items, setItems] = React.useState([
    {
      id: 1,
      symptom: "History of similar illness",
    },
    {
      id: 2,
      symptom: "History of hospitalisation",
    },
    {
      id: 3,
      symptom: "Any surgery done in past",
    },
  ]);

  // Dialog Box
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpen = (value: boolean) => {
    setOpenDialog(value);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };
  ///////
  const [openDialogHistoryOfHospitalisation, setOpenDialogHistoryOfHospitalisation] = React.useState(false);

  const handleClickOpenHistoryOfHospitalisation = (value: boolean) => {
    setOpenDialogHistoryOfHospitalisation(value);
  };

  const handleCloseHistoryOfHospitalisation = () => {
    setOpenDialogHistoryOfHospitalisation(false);
  };
  /////

  const [openDialogAnySurgeryDoneInPast, setOpenDialogAnySurgeryDoneInPast] = React.useState(false);

  const handleClickOpenAnySurgeryDoneInPast = (value: boolean) => {
    setOpenDialogAnySurgeryDoneInPast(value);
  };

  const handleCloseAnySurgeryDoneInPast = () => {
    setOpenDialogAnySurgeryDoneInPast(false);
  };



  const saveLogoFileSelected = (files: any) => {
    setDocumentsFile(files);
  };
  const dowloadDocumentFile = (files) => { };

  const removeFile = (file) => { };
  const rowData = [
    {

      patientNumber: "P001",
      status: "Overdue",
      patientName: "BCG + OPV + Hep B - Dose 0",
      date: '20/12/23',
      duration: '11 Month ago'
    },
    {
      patientNumber: "P002",
      status: "Overdue",
      patientName: "DwPT/DaPT + Hep B + Hib + IPV - Dose 1",
      date: '20/12/23',
      duration: '11 Month ago'
    },
    {
      patientNumber: "P003",
      status: "Current due",
      patientName: "Pneumococcal Vaccine - Dose 1",
      date: '20/12/23',
      duration: '11 Month ago'
    },
    {
      patientNumber: "P004",
      status: "Upcoming",
      patientName: "Rotavirus Vaccine - Dose 2",
      date: '20/12/23',
      duration: '11 Month ago'
    },
    {
      patientNumber: "P005",
      status: "Upcoming",
      patientName: "DwPT/DaPT + Hep B + Hib + IPV - Dose 3",
      date: '20/12/23',
      duration: '11 Month ago'
    },
  ];
  return (
    <Box className="main-container">
      <Grid container spacing={0}>
        <Grid item xs={12} mb={2}>
          <Grid container item spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                }}
              >
                <Box className="inner-title-container">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box className="inner-header-container">
                      <Typography style={{ fontWeight: 600 }}>
                        Current
                      </Typography>
                    </Box>
                  </Paper>
                  <Divider />
                  <Grid container >
                    {rowData.map((item) => {
                      return (
                        <Grid item xs={12} sm={12} md={12}>
                          <Box key={item.patientNumber} onClick={() => { handleClickOpenAnySurgeryDoneInPast(true) }} className="vaccine-grid" sx={{ border: '0px', borderBottom: '1px solid #e0e0e0', cursor: 'pointer' }}>
                            <Grid container padding={0} spacing={1}>
                              <Grid item xs={8} md={10}>
                                <Box sx={{ paddingX: 1 }}>
                                  <Box sx={{ fontSize: '14px', fontWeight: 'bold', paddingBottom: 1 }}>{item.patientName}</Box>
                                  <Box sx={{ fontSize: '12px' }}>{item.date}</Box>
                                </Box>
                              </Grid>
                              <Grid item xs={4} md={2}>
                                <Box sx={{ paddingTop: '12px' }} className="action-icon-placement">
                                  <Alert
                                    icon={false}
                                    className={
                                      item.status.toUpperCase() === "UPCOMING"
                                        ? "badge-new1 badge-warning"
                                        : item.status.toUpperCase() === "CURRENT DUE"
                                          ? "badge-new1 badge-success"
                                          : "badge-new1 badge-danger"
                                    }
                                  >
                                    {item.status}
                                  </Alert>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                }}
              >
                <Box className="inner-title-container">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box className="inner-header-container">
                      <Typography style={{ fontWeight: 600 }}>
                        History
                      </Typography>
                    </Box>
                  </Paper>
                  <Divider />
                  <Grid container >
                    {rowData.map((item) => {
                      return (
                        <Grid item xs={12} sm={12} md={12}>
                        <Box key={item.patientNumber} onClick={() => { handleClickOpenAnySurgeryDoneInPast(true) }} className="vaccine-grid" sx={{ border: '0px', borderBottom: '1px solid #e0e0e0', cursor: 'pointer' }}>
                          <Grid container padding={0} spacing={1}>
                            <Grid item xs={8} md={10}>
                              <Box sx={{ paddingX: 1 }}>
                                <Box sx={{ fontSize: '14px', fontWeight: 'bold', paddingBottom: 1 }}>{item.patientName}</Box>
                                <Box sx={{ fontSize: '12px' }}>{item.date}</Box>
                              </Box>
                            </Grid>
                            <Grid item xs={4} md={2}>
                              <Box sx={{ paddingTop: '12px' }} className="action-icon-placement">
                                <Alert
                                  icon={false}
                                  className={
                                    item.status.toUpperCase() === "UPCOMING"
                                      ? "badge-new1 badge-warning"
                                      : item.status.toUpperCase() === "CURRENT DUE"
                                        ? "badge-new1 badge-success"
                                        : "badge-new1 badge-danger"
                                  }
                                >
                                  {item.status}
                                </Alert>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
      className="dialog"
        open={openDialog}
        onClose={handleClose}
        fullScreen
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "1200px",
            maxWidth: "none",
          },
        }}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography
              // className={selected ? "symptom-text" : ""}
              style={{ fontWeight: 600, marginLeft: 1 }}
              sx={{
                paddingLeft: {
                  xs: 1,
                  sm: 0,
                }
              }}
             
              variant="h6"
            >
          History of similar illness
            </Typography>
          
          </Toolbar>
        </AppBar>
        <Divider />
        <DialogContent className="set-height-small set-height-mobile set-height-medium set-height-large">
          <HistoryOfSimilarIllness />
        </DialogContent>
        <DialogActions>
        <LoadingButton
            color="primary"
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="contained"
            type="submit"
            className="primary-button"
            name="submit"
            onClick={()=>{
              handleClose()
            }}
          >
            Save
          </LoadingButton>
          <LoadingButton
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="outlined"
            className="secondary-button"
            color="secondary"
            onClick={()=>{
              handleClose()
            }}
          >
            Cancel
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
      className="dialog"
        open={openDialogAnySurgeryDoneInPast}
        onClose={handleCloseAnySurgeryDoneInPast}
        fullScreen
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "1200px",
            maxWidth: "none",
          },
        }}
      >
         <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography
              // className={selected ? "symptom-text" : ""}
              style={{ fontWeight: 600, marginLeft: 1 }}
              sx={{
                paddingLeft: {
                  xs: 1,
                  sm: 0,
                }
              }}
             
              variant="h6"
            >
              Add Vaccine
            </Typography>
          
          </Toolbar>
        </AppBar>
        <Divider/>
        <DialogContent style={{ height: 'relative' }}>
          <AddVaccine />
        </DialogContent>
        <DialogActions>
        <LoadingButton
            color="primary"
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="contained"
            type="submit"
            className="primary-button"
            name="submit"
            onClick={()=>{
              handleCloseAnySurgeryDoneInPast();
            }}
          >
            Save
          </LoadingButton>
          <LoadingButton
            tabIndex={3}
            loadingPosition="start"
            startIcon={<></>}
            variant="outlined"
            className="secondary-button"
            color="secondary"
            onClick={()=>{
              handleCloseAnySurgeryDoneInPast();
            }}
          >
            Cancel
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
