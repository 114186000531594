import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../theme/style.scss";
import {
    Box,
    FormControl,
    FormLabel,
    Grid,
    TextField,
    Typography,
    styled,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Formik } from "formik";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import { CustomSelect } from "../../../components/CustomFields/CustomSelect";

const CustomTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: "#FAFBFD", // Set the desired background color
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: theme.palette.grey[300],
            borderWidth: "1px",
            BorderStyle: "solid",
        },
        "&:hover fieldset": {
            borderColor: theme.palette.grey[500],
            borderWidth: "1px",
            BorderStyle: "solid",
        },
        "&.Mui-focused fieldset": {
            borderWidth: "2px",
            borderColor: theme.palette.primary.main,
        },
    },
}));

interface MainProps {
    isEdit: boolean,
    setIsEdit: boolean
}

export default function BasicSetting(props: MainProps) {
    const { isEdit, setIsEdit } = props;
    const [value, setValue] = useState('');
    const navigate = useNavigate();
    const [countryData, setCountry] = useState([
        { countryID: 0, code: "", name: "" },
        { countryID: 1, code: "123", name: "India" },
        { countryID: 2, code: "456", name: "USA" },
    ]);


    return (
        <Box className="main-container profile-contianer">
            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                }}
                onSubmit={async (values) => {
                    console.log("values", values)
                }}
            >
                <Grid container spacing={2}>
                    {!isEdit && <Grid item xs={12} mb={3} className="group-container" >
                        <Grid container item spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6" >Profile</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={12}>
                                <Typography>Code</Typography>
                                <span style={{ fontWeight: 600, fontSize: '14px' }}>ristinagroves@example.com</span>
                            </Grid>
                            <Grid item xs={12} sm={6} md={12}>
                                <Typography>Name</Typography>
                                <span style={{ fontWeight: 600, fontSize: '14px' }}>Dr. Cristina Groves Smith</span>
                            </Grid>
                            <Grid item xs={12} sm={6} md={12}>
                                <Typography>Address</Typography>
                                <span style={{ fontWeight: 600, fontSize: '14px' }}>ristinagroves@example.com</span>
                            </Grid>
                        </Grid>
                    </Grid>}
                    {isEdit && <Grid item xs={12} mb={3} className="group-container" >
                        <Grid container item spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6" >Profile</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text">Code</FormLabel>
                                    <CustomTextField
                                        name="laboratoryName"
                                        placeholder=""
                                        autoCapitalize="none"
                                        type="text"
                                        fullWidth
                                        size="small"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={9} />
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text">Prefix</FormLabel>
                                    <CustomTextField
                                        name="laboratoryName"
                                        placeholder=""
                                        autoCapitalize="none"
                                        type="text"
                                        fullWidth
                                        size="small"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text">First Name</FormLabel>
                                    <CustomTextField
                                        name="laboratoryName"
                                        placeholder=""
                                        autoCapitalize="none"
                                        type="text"
                                        fullWidth
                                        size="small"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text">Middle Name</FormLabel>
                                    <CustomTextField
                                        name="laboratoryName"
                                        placeholder=""
                                        autoCapitalize="none"
                                        type="text"
                                        fullWidth
                                        size="small"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text">Last Name</FormLabel>
                                    <CustomTextField
                                        name="laboratoryName"
                                        placeholder=""
                                        autoCapitalize="none"
                                        type="text"
                                        fullWidth
                                        size="small"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text">Address1</FormLabel>
                                    <CustomTextField
                                        name="laboratoryName"
                                        placeholder=""
                                        autoCapitalize="none"
                                        type="text"
                                        fullWidth
                                        size="small"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text">Address2</FormLabel>
                                    <CustomTextField
                                        name="laboratoryName"
                                        placeholder=""
                                        autoCapitalize="none"
                                        type="text"
                                        fullWidth
                                        size="small"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text">City</FormLabel>
                                    <CustomTextField
                                        name="laboratoryName"
                                        placeholder=""
                                        autoCapitalize="none"
                                        type="text"
                                        fullWidth
                                        size="small"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text">Postal Code</FormLabel>
                                    <CustomTextField
                                        name="laboratoryName"
                                        placeholder=""
                                        autoCapitalize="none"
                                        type="text"
                                        fullWidth
                                        size="small"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text">Country</FormLabel>
                                    <CustomSelect
                                        isNotCreatable={true}
                                        id={"state"}
                                        FieldName={"state"}
                                        options={countryData}
                                        openMenuOnFocus
                                        isClearable
                                        isSearchable
                                        menuPortalTarget={document.querySelector("body")}
                                        handleChange={(e) => {
                                            console.log("event:", e)
                                        }}
                                        Value=""
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text">State</FormLabel>
                                    <CustomSelect
                                        isNotCreatable={true}
                                        id={"state"}
                                        FieldName={"state"}
                                        options={countryData}
                                        openMenuOnFocus
                                        isClearable
                                        isSearchable
                                        menuPortalTarget={document.querySelector("body")}
                                        handleChange={(e) => {
                                            console.log("event:", e)
                                        }}
                                        Value=""
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>}
                    {isEdit && <Grid item xs={12}>
                        <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
                            <LoadingButton
                                color="primary"
                                tabIndex={3}
                                loadingPosition="start"
                                startIcon={<></>}
                                variant="contained"
                                type="submit"
                                className="primary-button"
                                // onClick={() => navigate("/master/laboratory-master")}
                                name="submit"
                            >
                                Save
                            </LoadingButton>
                            <LoadingButton
                                tabIndex={3}
                                loadingPosition="start"
                                startIcon={<></>}
                                variant="outlined"
                                type="submit"
                                name="submit"
                                className="secondary-button"
                                color="secondary"
                                onClick={() => setIsEdit(false)}
                            >
                                Cancel
                            </LoadingButton>
                        </Box>
                    </Grid>}
                </Grid>

            </Formik>
        </Box>
    );
}

