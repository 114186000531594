import React, { FC } from 'react';
import {
    DragDropContext,
    Droppable,
    OnDragEndResponder,
} from 'react-beautiful-dnd';
import { Box, FormControl, List, MenuItem, Select } from '@mui/material';
import AnesthesiologistDraggableListItem from './AnesthesiologistDraggableListItem';
import { Item } from '../../Helpers/typings';
import { CustomSelect } from '../CustomFields/CustomSelect';

export type Props = {
    items: Item[];
    onDragEnd: OnDragEndResponder;
    onHandleOpenDialog: (value: boolean) => void;
};
const AnesthesiologistDraggableList: FC<Props> = React.memo(({ items, onDragEnd, onHandleOpenDialog }) => {

    const onRowClick = (val) => {
        
        onHandleOpenDialog(val)
    }
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-list">
                {(provided) => (
                    <List ref={provided.innerRef} {...provided.droppableProps}>
                        {items.map((item: Item, index: number) => (
                            <AnesthesiologistDraggableListItem item={item} index={index} key={item.id} onRowClickForOpenDialog={onRowClick} />
                        ))}
                        {provided.placeholder}
                    </List>

                )}
            </Droppable>
            <Box style={{ padding: 10, paddingLeft: 20, paddingRight: 20 }}>
                <FormControl fullWidth>
                <CustomSelect
                          // isNotCreatable={true}
                          id={"duration"}
                          FieldName={"duration"}
                          options={[]}
                          openMenuOnFocus
                          isClearable
                          isSearchable
                          menuPortalTarget={document.querySelector("body")}
                          handleChange={(e) => {
                            console.log("event:", e)
                          }}
                          Value=""
                        />
                       
                   
                </FormControl>
            </Box>
        </DragDropContext>
    );
});

export default AnesthesiologistDraggableList;
