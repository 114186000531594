import React, { useContext, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import './../../theme/SignIn.scss';
import { Box, Button, FormControl, Fade, TextField, Typography, Grid, Paper, FormLabel, IconButton, InputAdornment, FormControlLabel, Checkbox, styled, Stack, Alert } from '@mui/material';
import logo from '../../assets/images/logo.png';
import { Link, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { EncryptDecryptService } from "../../components/Common/EncryptionService";
import { Login } from '../../services/AuthService'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoginDetail } from '../../services/models/Context';
import { PreferencesContext } from '../../PreferenceContext';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const CustomTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: '#FAFBFD', // Set the desired background color
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.grey[300],
            borderWidth: '1px',
            BorderStyle: 'solid'
        },
        '&:hover fieldset': {
            borderColor: theme.palette.grey[500],
            borderWidth: '1px',
            BorderStyle: 'solid'
        },
        '&.Mui-focused fieldset': {
            borderWidth: "2px",
            borderColor: theme.palette.primary.main,
        },
    },
}));

const formInitialValues = {
    email: "",
    password: "",
    isRemember: false
};





export default function SignIn() {
    const [loading, setLoading] = useState(false);
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [showAlert, setAlertMessage] = React.useState({
        isSuccess: false,
        message: "",
    });
    const [values, setValues] = useState({
        password: '',
        showPassword: "",
        isRemember: false

    })
    let navigate = useNavigate();

    // ----for Validation--- 
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email("Enter a valid email.")
            .required("Email is required."),
        password: Yup.string()
            .required("Password is required."),
    });

    // registration
    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    }

    async function submitLoginForm(value: any) {
        let email = value.email
        let password = value.password
        setLoading(true)
        try {
            const user: any = await Login({

                Username: email,
                Password: password

            })
            if (user) {
                let curuserInfo: LoginDetail = new LoginDetail();
                curuserInfo.token = user.data.token;
                curuserInfo.tokenExpiryTime = user.data.tokenExpiryTime;
                curuserInfo.userId = user.data.userId;
                curuserInfo.userKey = user.data.userKey;
                curuserInfo.userRole = user.data.roles[0].toLowerCase();
                curuserInfo.firstName = user.data.firstName;
                curuserInfo.lastName = user.data.lastName;
                curuserInfo.fullName = user.data.fullName;
                curuserInfo.email = user.data.email;
                curuserInfo.isMenuVisible = true;
                curuserInfo.isLoadding = true;
                curuserInfo.isFatchinToken = false;
                updateUserContext(curuserInfo);

                navigate('/dashboard')
            }
            setLoading(false)
        } catch (error) {
            if (error) {
                setAlertMessage({
                    isSuccess: true,
                    message: error as string,
                });
            }
            setLoading(false)
        }

    }
    const methods = useForm({
        resolver: yupResolver(validationSchema),
        // defaultValues: initStaData,
    });

    return (
        <Fade in={true} timeout={{ enter: 500 }}>
            <Box className="signin-container">
                <Paper elevation={3} className="paper-singin">
                    <Grid className='img-wrapper' item display={{ xs: "none", lg: "block", md: "block" }} xs={12} md={12} />
                    <Grid item className="signin-wrapper" xs={12}>
                        <React.Fragment>
                            <Box
                                className="box-container"
                            // sx={{ my: 2 }}
                            >
                                <Box
                                    className="paper-container-forgot paper-pre-signin"
                                >
                                    <Grid
                                        display='flex'
                                        justifyContent="end"
                                        paddingBottom={3}
                                        item>
                                        <img loading="lazy" className='img-page'
                                            src={logo} alt="logo" />
                                    </Grid>
                                    <Grid item xs={12} className='pre-signin-text' sx={{ fontSize: '18px !important' }}><b>Welcome!</b></Grid>
                                    <Grid item xs={12} className='pre-signin-text' pb={2}>Enter your email to start your daily work.</Grid>
                                    {showAlert.isSuccess ? (
                                        <Grid marginTop={2}>
                                            <Stack sx={{ width: "100%" , }}>
                                                <Alert severity="error">{showAlert.message}</Alert>
                                            </Stack>
                                        </Grid>
                                    ) : (
                                        ""
                                    )}
                                    <FormProvider {...methods}>
                                        <form onSubmit={methods.handleSubmit(submitLoginForm)}>
                                            <Grid container>
                                                {/* <Form> */}
                                                <Grid container spacing={1} marginTop={1}>
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            <FormLabel sx={{ display: 'flex', justifyContent: 'left', fontSize: '13px' }} className={`fileds-label-text  ${methods.formState.errors.email ? 'validation-message' : ''}`}>Email</FormLabel>
                                                            <CustomTextField
                                                                autoFocus
                                                                autoCapitalize='none'
                                                                //type="email"
                                                                fullWidth
                                                                size="small"
                                                                margin="dense"
                                                                tabIndex={1}
                                                                // value={props.values.email}
                                                                // inputProps={{ enterKeyHint: 'next' }}
                                                                // onChange={props.handleChange("email")}
                                                                // onBlur={props.handleBlur}
                                                                {...methods.register('email')}
                                                                error={
                                                                    !!methods.formState.errors.email
                                                                }
                                                                className={`text-input ${methods.formState.errors.email ? 'error' : ''}`}
                                                            />
                                                            <Typography
                                                                variant="inherit"
                                                                className="validation-message"
                                                                sx={{ textAlign: 'left' }}
                                                            >{methods.formState.errors.email?.message}
                                                            </Typography>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} marginTop={1}>
                                                        <FormLabel sx={{ display: 'flex', justifyContent: 'left', fontSize: '13px' }} className={`fileds-label-text  ${methods.formState.errors.password ? 'validation-message' : ''}`}>Password</FormLabel>
                                                        <CustomTextField
                                                            placeholder=""
                                                            // label="Password*  "
                                                            fullWidth
                                                            size="small"
                                                            margin="dense"
                                                            // value={props.values.password}
                                                            // onChange={props.handleChange("password")}
                                                            // onBlur={props.handleBlur}
                                                            tabIndex={2}
                                                            //inputProps={{ enterKeyHint: 'next' }}
                                                            type={values.showPassword ? "text" : "password"}
                                                            {...methods.register('password')}
                                                            error={
                                                                !!methods.formState.errors.password
                                                            }
                                                            className={`text-input ${methods.formState.errors.password ? 'error' : ''}`}
                                                            inputProps={{ enterKeyHint: 'next' }}
                                                            type={values.showPassword ? "text" : "password"}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            style={{ paddingRight: 20, paddingLeft: 0 }}
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowPassword}
                                                                            edge="end"
                                                                            id="eyebutton"
                                                                        >
                                                                            {values.showPassword ? (
                                                                                <VisibilityOff />
                                                                            ) : (
                                                                                <Visibility />
                                                                            )}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                        <Typography
                                                            variant="inherit"
                                                            className="validation-message"
                                                            sx={{ textAlign: 'left' }}
                                                        >{methods.formState.errors.password?.message}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Box className="forgot-psw-box">
                                                            <Typography>
                                                                <FormControlLabel
                                                                    className="forgot-psw-button"
                                                                    control={<Checkbox name="isRemember" checked={formInitialValues.isRemember} value={formInitialValues.isRemember} color="primary" />} //onChange={props.handleChange("isRemember")}
                                                                    label={
                                                                        <Box component="div" fontSize={13}>
                                                                            Remember me
                                                                        </Box>
                                                                    }
                                                                >
                                                                </FormControlLabel>
                                                            </Typography>
                                                            <Typography>
                                                                <Button
                                                                    disableRipple
                                                                    color="inherit"
                                                                    tabIndex={4}
                                                                    className="forgot-psw-button"
                                                                    onClick={() => navigate('/forgot-password')}
                                                                >
                                                                    Forgot Password?
                                                                </Button>
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={3} marginTop={0}>
                                                    <Grid item xs={12} textAlign="right">
                                                        <LoadingButton
                                                            color="primary"
                                                            loading={loading}
                                                            fullWidth
                                                            tabIndex={3}
                                                            loadingPosition="start"
                                                            startIcon={<></>}
                                                            variant="contained"
                                                            type="submit"
                                                            className='submit-btn'
                                                            name="submit">
                                                            Sign In
                                                        </LoadingButton>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={3} marginTop={0}>
                                                    <Grid item xs={12} textAlign="left">
                                                        <Typography className="smallSize">By logging in, you agree to our&nbsp;
                                                            <Link to="https://pediaverse.org/terms-of-service/" target="_blank" style={{ textDecoration: 'none' }} className="blue-link">terms of service</Link> and <Link to="https://pediaverse.org/privacy-policy/" target="_blank" style={{ textDecoration: 'none' }} className="blue-link">privacy policy</Link>.
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid xs={12} marginTop={3} textAlign="left">
                                                    <Typography className='smallSize' sx={{ marginBottom: '2px' }}>Want to create your account, contact sales team.</Typography>
                                                    <Link to={'https://pediaverse.org/'} style={{ textDecoration: 'none' }} target="_blank">
                                                        <LoadingButton
                                                            className='contact_btn'
                                                            color="primary"
                                                            fullWidth
                                                            variant="outlined"
                                                            name="contact sales"
                                                        >
                                                            <Typography
                                                                className="smallSize contact">
                                                                Contact Sales
                                                            </Typography>
                                                            <ChevronRightIcon className="bold-600" />
                                                        </LoadingButton>
                                                    </Link>
                                                </Grid>
                                                {/* </Form> */}
                                            </Grid>

                                        </form>
                                    </FormProvider>
                                </Box>
                            </Box>
                        </React.Fragment>

                    </Grid>
                </Paper>
            </Box>
        </Fade>
    );
}