

import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../theme/style.scss';
import { Box, FormControl, FormLabel, Grid, TextField, styled } from '@mui/material';
import Title from '../../../components/Title';
import LoadingButton from '@mui/lab/LoadingButton';
import BreadcrumbsComponent from '../../../components/BreadcrumbsComponent/BreadcrumbsComponent';
import CustomBreadcrumbs from '../../../components/BreadcrumbsComponent/CustomBreadcrumbs';

const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: '#FAFBFD',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.grey[300],
      borderWidth: '1px',
      BorderStyle: 'solid'
    },
    '&:hover fieldset': {
      borderColor: theme.palette.grey[500],
      borderWidth: '1px',
      BorderStyle: 'solid'
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: "2px",
    },
  },
}));

export default function ICDCodesSave() {
  const navigate = useNavigate()

  return (
    <Box className="main-container " pl={2}>
      <Grid xs={12} container className='page-header-save'>
      <Grid xs={12} sm={12} md={12} item className="page-breadcrumbs">
          <CustomBreadcrumbs />
        </Grid>
      </Grid>
      <Grid container spacing={2}  >
        <Grid item xs={12} mb={2} className="group-container">
          <Grid container item spacing={2} >
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>Code</FormLabel>

                <CustomTextField
                  name="code"
                  placeholder=""
                  autoCapitalize='none'
                  type="text"
                  fullWidth
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={9}>
              <></>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>Medicine Used As Name</FormLabel>
                <CustomTextField
                  name="diagnosisName"
                  placeholder=""
                  autoCapitalize='none'
                  type="text"
                  fullWidth
                  size="small"
                />
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>Diagnosis Type</FormLabel>
                <Select
                  name="organizationType"
                  // options={options}
                  // value={selectedOption}
                  // onChange={handleSelectChange}
                  placeholder="Select"
                  styles={{
                    placeholder: (provided) => ({
                      ...provided,
                      fontSize: '0.813rem', // Set the desired placeholder font size here
                    }),
                    control: (base) => ({
                      ...base,
                      backgroundColor: '#FAFBFD',
                      border: '2px solid #cbcbda',
                      '&:hover': {
                        border: '2px solid #bdc1cd',
                      },
                      boxShadow: 'none',
                    }),
                  }}
                />
              </FormControl>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'end', gap: 2 }}>
            <LoadingButton
              color="primary"
              tabIndex={3}
              loadingPosition="start"
              startIcon={<></>}
              variant="contained"
              onClick={() => navigate('/master/icd-codes')}
              type="submit"
              className='primary-button'
              name="submit">
              SAVE
            </LoadingButton>
            <LoadingButton
              tabIndex={3}
              loadingPosition="start"
              startIcon={<></>}
              variant="outlined"
              type="submit"
              className='secondary-button'
              color="secondary"
              name="submit"
              onClick={() => navigate('/master/icd-codes')}
            >
              CANCEL
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}