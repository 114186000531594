import React from 'react';
import "../../../../../../theme/style.scss";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  TextField,
 
} from "@mui/material";
import  { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CustomTimePicker from "../../../../../../components/TimePicker/CustomTimePicker";
import ReactQuill from "react-quill";
import { Formik } from 'formik';

const MedicinePrescription = () => {

     const [showNote, setShowNote] = useState(false);
    const [morning, setMorning] = useState(false);
    const [afternoon, setAfternoon] = useState(false);
    const [evening, setEvening] = useState(false);
    const [night, setNight] = useState(false);
    const [temperature, setTemperature] = useState("");
    const [unit, setUnit] = useState("mg");


    const handleTemperatureChange = (event) => {
        setTemperature(event.target.value);
      };
    
      const handleUnitChange = (event) => {
        setUnit(event.target.value);
      };

    return (
      <Box
      className="template-table-container group-container"
      sx={{
        border: "0px solid #0000001f",
        borderRadius: 1,
        minHeight: 320,
      }}
    >
      <Box className="inner-title-container">
    
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
            }}
            onSubmit={async (values) => {
              console.log("values", values);
            }}
          >
            <Box>
            
              <Grid
                container
                item
                spacing={2}
               
              >
                <Grid item xs={12} sm={8} md={8}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => setMorning(!morning)}
                        />
                      }
                      label="Morning"
                    />
                  </FormGroup>
                </Grid>

                {!morning && <Grid item xs={12} sm={4} md={6} />}
                {morning &&
                  <Grid item xs={12} sm={6} md={8}>
                    <Box sx={{ paddingRight: 14 }}>
                      <FormControl fullWidth>
                        <FormLabel className="fileds-label-text">
                          Dose
                        </FormLabel>
                        <TextField
                          variant="outlined"
                          fullWidth
                          value={temperature}
                          onChange={handleTemperatureChange}
                          type="number"
                          size="small"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Select
                                  value={unit}
                                  onChange={handleUnitChange}
                                  size="small"
                                  displayEmpty
                                  inputProps={{
                                    "aria-label": "Without label",
                                  }}
                                  sx={{
                                    marginLeft: 1,
                                    ".MuiSelect-select": {
                                      // paddingRight: "0px",
                                    },
                                  }}
                                >
                                  <MenuItem value="mg">mg</MenuItem>
                                  <MenuItem value="g">g</MenuItem>
                                  <MenuItem value="mL">mL</MenuItem>
                                </Select>
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            minWidth: "150px",
                            ".MuiOutlinedInput-root": {
                              paddingRight: 0,
                            },
                          }}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                }
                {!morning && <Grid item xs={12} sm={6} md={4} />}
                {morning && <Grid item xs={12} sm={12} md={12}>
                  <CustomTimePicker />
                </Grid>}
                <Grid item xs={12} sm={8} md={8}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => setAfternoon(!afternoon)}
                        />
                      }
                      label="Afternoon"
                    />
                  </FormGroup>
                </Grid>

                {!afternoon && <Grid item xs={12} sm={4} md={6} />}
                {afternoon && <Grid item xs={12} sm={6} md={8}>
                  <Box sx={{ paddingRight: 14 }}>
                    <FormControl  >
                      <FormLabel className="fileds-label-text">
                        Dose
                      </FormLabel>
                      <TextField
                        variant="outlined"

                        value={temperature}
                        onChange={handleTemperatureChange}
                        type="number"
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Select
                                value={unit}
                                onChange={handleUnitChange}
                                size="small"
                                displayEmpty
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                                sx={{
                                  marginLeft: 1,
                                  ".MuiSelect-select": {
                                    // paddingRight: "0px",
                                  },
                                }}
                              >
                                <MenuItem value="mg">mg</MenuItem>
                                <MenuItem value="g">g</MenuItem>
                                <MenuItem value="mL">mL</MenuItem>
                              </Select>
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          minWidth: "150px",
                          ".MuiOutlinedInput-root": {
                            paddingRight: 0,
                          },
                        }}
                      />
                    </FormControl>
                  </Box>
                </Grid>}
                
                {!afternoon && <Grid item xs={12} sm={6} md={6} />}
                {afternoon && <Grid item xs={12} sm={12} md={12} >
                  <CustomTimePicker />
                </Grid>}

                <Grid item xs={12} sm={8} md={8}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => setEvening(!evening)}
                        />
                      }
                      label="Evening"
                    />
                  </FormGroup>
                </Grid>
                {!evening && <Grid item xs={12} sm={4} md={6} />}
                {evening && <Grid item xs={12} sm={6} md={8}>
                  <Box sx={{ paddingRight: 14 }}>
                    <FormControl fullWidth>
                      <FormLabel className="fileds-label-text">
                        Dose
                      </FormLabel>
                      <TextField
                        variant="outlined"
                        fullWidth
                        value={temperature}
                        onChange={handleTemperatureChange}
                        type="number"
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Select
                                value={unit}
                                onChange={handleUnitChange}
                                size="small"
                                displayEmpty
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                                sx={{
                                  marginLeft: 1,
                                  ".MuiSelect-select": {
                                    // paddingRight: "0px",
                                  },
                                }}
                              >
                                <MenuItem value="mg">mg</MenuItem>
                                <MenuItem value="g">g</MenuItem>
                                <MenuItem value="mL">mL</MenuItem>
                              </Select>
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          minWidth: "150px",
                          ".MuiOutlinedInput-root": {
                            paddingRight: 0,
                          },
                        }}
                      />
                    </FormControl>
                  </Box>
                </Grid>}
                {!evening && <Grid item xs={12} sm={6} md={6} />}
                {evening && <Grid item xs={12} sm={12} md={12} >
                  <CustomTimePicker />
                </Grid>}
                <Grid item xs={12} sm={8} md={6}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => setNight(!night)}
                        />
                      }
                      label="Night"
                    />
                  </FormGroup>
                </Grid>
               
                {!night && <Grid item xs={12} sm={4} md={6} />}
                {night && <Grid item xs={12} sm={6} md={8}>
                  <Box sx={{ paddingRight: 14 }}>
                    <FormControl fullWidth>
                      <FormLabel className="fileds-label-text">
                        Dose
                      </FormLabel>
                      <TextField
                        variant="outlined"
                        fullWidth
                        value={temperature}
                        onChange={handleTemperatureChange}
                        type="number"
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Select
                                value={unit}
                                onChange={handleUnitChange}
                                size="small"
                                displayEmpty
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                                sx={{
                                  marginLeft: 1,
                                  ".MuiSelect-select": {
                                    // paddingRight: "0px",
                                  },
                                }}
                              >
                                <MenuItem value="mg">mg</MenuItem>
                                <MenuItem value="g">g</MenuItem>
                                <MenuItem value="mL">mL</MenuItem>
                              </Select>
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          minWidth: "150px",
                          ".MuiOutlinedInput-root": {
                            paddingRight: 0,
                          },
                        }}
                      />
                    </FormControl>
                  </Box>
                </Grid>}
               
                {!night && <Grid item xs={12} sm={6} md={6} />}
                {night && <Grid item xs={12} sm={12} md={12}>
                  <CustomTimePicker />
                </Grid>}
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                 
                 
                </Grid>
              </Grid>
            </Box>
          </Formik>
        
      </Box>
    </Box>
    );
}

export default MedicinePrescription;
