import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../theme/style.scss";
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Title from "../../../components/Title";
import { CustomSelect } from "../../../components/CustomFields/CustomSelect";
import { Formik } from "formik";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css';
import CustomBreadcrumbs from "../../../components/BreadcrumbsComponent/CustomBreadcrumbs";

const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#FAFBFD", // Set the desired background color
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));


export default function UsersSave() {
  const navigate = useNavigate();
  const [value, setValue] = useState('');
  const [countryData, setCountry] = useState([
    { countryID: 0, code: "", name: "" },
    { countryID: 1, code: "123", name: "India" },
    { countryID: 2, code: "456", name: "USA" },
  ]);


  return (
    <Box className="main-container" pl={2}>
      <Grid item xs={12} container className="page-header-save">
      <Grid xs={6} sm={6} item className="page-breadcrumbs">
          <CustomBreadcrumbs />
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
        }}
        onSubmit={async (values) => {
          console.log("values", values)
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} mb={3} className="group-container">
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Basic Information</Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text"> Code</FormLabel>
                  <CustomTextField
                    name="laboratoryName"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={9} />
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text"> First Name</FormLabel>
                  <CustomTextField
                    name="laboratoryName"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text"> Middle Name</FormLabel>
                  <CustomTextField
                    name="laboratoryName"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Last Name</FormLabel>
                  <CustomTextField
                    name="laboratoryName"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} mb={3} className="group-container">
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Contact Information</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Address 1</FormLabel>
                  <CustomTextField
                    name="address1"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Address 2</FormLabel>
                  <CustomTextField
                    name="address2"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div className="input-group">
                  <label htmlFor="phone">Mobile Number</label>
                  <PhoneInput
                    id="phone"
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="IN"
                    value={value}
                    onChange={setValue}
                    placeholder="Enter phone number"
                    className="phone-input"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Email</FormLabel>
                  <CustomTextField
                    name="email"
                    placeholder=""
                    autoCapitalize="none"
                    type="email"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">City</FormLabel>
                  <CustomTextField
                    name="city"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Postal Code</FormLabel>
                  <CustomTextField
                    name="postalCode"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Country</FormLabel>
                  <CustomSelect
                    isNotCreatable={true}
                    id={"country"}
                    FieldName={"country"}
                    options={countryData}
                    openMenuOnFocus
                    isClearable
                    isSearchable
                    menuPortalTarget={document.querySelector("body")}
                    handleChange={(e) => {
                      console.log("event:", e)
                    }}
                    Value=""
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">State</FormLabel>
                  <CustomSelect
                    isNotCreatable={true}
                    id={"state"}
                    FieldName={"state"}
                    options={countryData}
                    openMenuOnFocus
                    isClearable
                    isSearchable
                    menuPortalTarget={document.querySelector("body")}
                    handleChange={(e) => {
                      console.log("event:", e)
                    }}
                    Value=""
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
              <LoadingButton
                color="primary"
                tabIndex={3}
                loadingPosition="start"
                startIcon={<></>}
                variant="contained"
                type="submit"
                className="primary-button"
                onClick={() => navigate("/users")}
                name="submit"
              >
                Save
              </LoadingButton>
              <LoadingButton
                tabIndex={3}
                loadingPosition="start"
                startIcon={<></>}
                variant="outlined"
                type="submit"
                name="submit"
                className="secondary-button"
                color="secondary"
                onClick={() => navigate("/users")}
              >
                Cancel
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>

      </Formik>

    </Box>
  );
}
