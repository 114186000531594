import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box } from '@mui/material'
// import '../Theme/main.scss'


export const DropZone = ({ customvalidator, onDropandSelect, editFile, onFileRemove }) => {
    const [file, setFile] = useState<any>();
    const [messageFlag, setMessageFlag] = useState(false)

    // set file if alreday exists 
    useEffect(() => {
        setFile(editFile)
    }, [editFile]);

    // on select or on drop file or image 
    const onDrop = useCallback(acceptedFiles => {
        const file = acceptedFiles[0];
        setMessageFlag(true);
        if (file) {
            onDropandSelect(acceptedFiles);
            setFile(URL.createObjectURL(file));
        }
    }, []);

    const { getRootProps, getInputProps, fileRejections } = useDropzone({ onDrop, validator: customvalidator });

    // errors on file uploadings 
    const fileRejectionItems = fileRejections.map(({ file, errors }, index) => (
        <Box>
            {errors.map(e => (
                <span key={e.code} style={{ color: 'red' }}>* {' '}{e.message}</span>
            ))}
        </Box>
    ));

    return (
        <Box className="container">
            {
                !file ?
                    <Box {...getRootProps({ className: 'dropzone' })} sx={{
                        padding: '15px 15px', border: 'dashed gray 2px !important', marginBottom: '10px', borderRadius: "6px",
                        '&:hover': {
                            border: 'dashed gray 2px !important',
                            cursor: 'grab'
                        }
                    }}>
                        <input {...getInputProps()} />
                        <Box style={{ textAlign: 'center' }} >Select or Drop File</Box>
                        <Box style={{ textAlign: 'center', fontSize: '11px', color: 'gray' }}>Max File Size: 4 MB</Box>
                    </Box>
                    :
                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <img src={file} style={{ objectFit: 'contain', minHeight: '40px', minWidth: '40px', maxHeight: '40px', maxWidth: '80px' }} />
                            <button type="button"
                                style={{
                                    border: 'none', backgroundColor: 'transparent'
                                }}
                                onClick={e => { onFileRemove(); setMessageFlag(false); }} >
                                <Box sx={{ textDecoration: 'underline', color: 'blue', marginLeft: '10px', '&:hover': { color: 'red', textDecorationColor: 'red' } }}>remove</Box>
                            </button>
                        </Box>
                        {messageFlag && <Box sx={{ color: 'red' }}>Please press "Save/Update" button to save/upload the image to server. </Box>}
                    </Box >
            }
            <Box>{fileRejectionItems}</Box>
        </Box >
    )
}
