import React, { useState } from "react";
import Title from "../../../components/Title";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Button,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
  Tab,
  Tabs,
  Popover,
  Pagination,
  Stack,
} from "@mui/material";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate } from "react-router-dom";
import "../../../theme/style.scss";
import BreadcrumbsComponent from "../../../components/BreadcrumbsComponent/BreadcrumbsComponent";
import CustomBreadcrumbs from "../../../components/BreadcrumbsComponent/CustomBreadcrumbs";
// import ClearIcon from "@mui/icons-material/Clear";
export default function DoctorList() {
  const navigate = useNavigate();
  const [value, setValue] = useState(0)

  const [anchorEl, setAnchorEl3] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const rowData = [
    {
      name: 'John Doe',
      department: 'Cardiology',
      specialty: 'Heart Surgery',
      mobileNo: '123-456-7890',
      email: 'john.doe@example.com',
      qualification: 'MD, FACS'
    },
    {
      name: 'Jane Smith',
      department: 'Neurology',
      specialty: 'Brain Surgery',
      mobileNo: '098-765-4321',
      email: 'jane.smith@example.com',
      qualification: 'MD, PhD'
    },
    {
      name: 'Alice Johnson',
      department: 'Orthopedics',
      specialty: 'Joint Replacement',
      mobileNo: '234-567-8901',
      email: 'alice.johnson@example.com',
      qualification: 'MD, FRCS'
    },
    {
      name: 'Bob Brown',
      department: 'Pediatrics',
      specialty: 'Child Health',
      mobileNo: '345-678-9012',
      email: 'bob.brown@example.com',
      qualification: 'MD, FAAP'
    },
    {
      name: 'Emily Davis',
      department: 'Dermatology',
      specialty: 'Skin Care',
      mobileNo: '456-789-0123',
      email: 'emily.davis@example.com',
      qualification: 'MD, FAAD'
    }
  ];

  const CustomTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: "#FAFBFD", // Set the desired background color
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.grey[300],
        borderWidth: "2px",
        BorderStyle: "solid",
      },
      "&:hover fieldset": {
        borderColor: theme.palette.grey[500],
        borderWidth: "2px",
        BorderStyle: "solid",
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },
  }));
  function TabProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };


  // for Edit & Delet pop
  const handleOpenMore = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: any
  ) => {
    setAnchorEl3(event.currentTarget);
    // setBuyerId(id);
  };
  const handleCloseMore = () => {
    setAnchorEl3(null);
  };
  //  Table Header

  function EnhancedTableHead(props: EnhancedTableProps) {
    return (
      // checkBox for Header

      <TableHead className="table-header-color">
        <TableRow>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "20%",
            }}
            align="left"
          >
            Name
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "none",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "20%",
            }}
            align="left"
          >
            Department	
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "20%",
            }}
            align="left"
          >
            Specialty
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "20%",
            }}
            align="left"
          >
            Mobile No
          </TableCell>

          
          <TableCell
            sx={{
              display: {
                xs: "none",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "15%",
            }}
            align="left"
          >
            Email
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "5%",
            }}
            align="right"
          ></TableCell>
        </TableRow>
      </TableHead>
    );
  }

  return (
    <>
      <Box className="main-container">
        <Grid xs={12} container className="page-header">
        <Grid xs={6} sm={6} item className="page-breadcrumbs">
          <CustomBreadcrumbs />
        </Grid>
          <Grid xs={6} sm={6} item className="page-header-icon">
            <Button
              color="primary"
              variant="contained"
              onClick={() => navigate("doctor-save")}
              size="medium"
              startIcon={<AddIcon />}
            >
              Add
            </Button>
          </Grid>
        </Grid>

        <Grid container className="list-container" sx={{ padding: "16px" }}>
          <Grid item xs={8} sm={8} md={8} lg={10} className='search-bar' sx={{ gap: 2 }} >

            <CustomTextField
              placeholder="Search..."
              type="text"
              variant="outlined"
              size="small"

              sx={{ paddingLeft: '0px' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" >
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={2} className='search-bar pdf-icon'>
            <img src="/xls.png" alt="" className='pdf-icon-hover' />
            <img src="/pdf.png" alt="" className='pdf-icon-hover' />
          </Grid>
          <Grid item xs={12}></Grid>
          <Tabs
            className="table"
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            aria-label="Sale Invoice"
            TabIndicatorProps={{ sx: { backgroundColor: " #2e67a3" } }}
            sx={{
              "& button:hover": {
                backgroundColor: "#f1f6fe",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              },
            }}
          >

            <Tab
              sx={{ textTransform: "capitalize !important" }}
              label={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <span>Consuliting Doctor</span>
                  <Box
                    sx={{ backgroundColor: "#448944", color: "#fff" }}
                    className="inner-tabs"
                  >
                    1
                  </Box>
                </Box>
              }
              {...TabProps(1)}
            />
            <Tab
              sx={{ textTransform: "capitalize !important" }}
              label={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <span>Referral Doctor</span>
                  <Box
                    sx={{ backgroundColor: "#c19603", color: "#fff" }}
                    className="inner-tabs"
                  >
                    2
                  </Box>
                </Box>
              }
              {...TabProps(2)}
            />
            <Tab
              sx={{ textTransform: "capitalize !important" }}
              label={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <span>Visiting Doctor</span>
                  <Box
                    sx={{ backgroundColor: "#943432", color: "#fff" }}
                    className="inner-tabs"
                  >
                    3
                  </Box>
                </Box>
              }
              {...TabProps(3)}
            />
            
          </Tabs>

          <TableContainer >
            <Table>
              
              <EnhancedTableHead />
              <TableBody className="table-body">
                {rowData.map((item) => {
                  return (
                    <TableRow>
                      <TableCell >
                        <Link className='text-link doctor-name-link' to="doctor-save">
                          {item.name}
                          
                        </Link>
                        <div>{item.qualification}</div>
                      </TableCell>
                      <TableCell>{item.department}</TableCell>
                      <TableCell>{item.specialty}</TableCell>
                      <TableCell sx={{
                        display: {
                          xs: "none",
                          sm: "table-cell",
                          md: "table-cell",
                          lg: "table-cell",
                        },
                      }}>{item.mobileNo}</TableCell>
                      <TableCell sx={{
                        display: {
                          xs: "none",
                          sm: "table-cell",
                          md: "table-cell",
                          lg: "table-cell",
                        },
                      }}>{item.email}</TableCell>
                      <TableCell align="right">
                        <Tooltip title="More" placement="top">
                          <IconButton onClick={(e: any) => handleOpenMore(e)}>
                            <MoreVertRoundedIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid
            container
            paddingTop={1}
            className="pagination"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid item xs={12} sm={6} className="pagination-label">
              1 of 1 {/* {pageStartIndex} - {pageEndIndex} of {totalCount} */}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack
                component="div"
                className='pagination'
              >
                <Pagination
                  color="primary"
                // count={counData}
                // page={page_Index}
                // onChange={handleChangePageNew}
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Popover
        elevation={1}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseMore}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate("doctor-save")}>
              <ListItemText primary="Edit" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton className="delete-container">Delete</ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </>
  );
}
