import "../../../../../theme/style.scss";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  styled,
  Dialog,
  DialogContent
} from "@mui/material";
import React, { useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { reorder } from "../../../../../Helpers/helpers";
import { Formik } from "formik";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { AsyncCustomSelect } from "../../../../../components/CustomFields/CustomSelect";
import ReactQuill from "react-quill";
import FollowupDraggableTable from "./FollowupDraggable/FollowupDraggableTable";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar"
import useMediaQuery from '@mui/material/useMediaQuery'
import SubjectRoundedIcon from "@mui/icons-material/SubjectRounded";

const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#FAFBFD",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));


const initialDoctorRefered = [
  {
    id: 1,
    doctorName: '',
    FollowupReason: '',
    FollowupDuration: '',
    Followup: '',
    FollowupInstructio: ''
  }
]

export default function FollowUp() {
  const [showNote, setShowNote] = useState(false);
  const [showRefDoctor, setShowRefDoctor] = useState(true);
  const [selected, setSelected] = useState("");
  const [addDoctorRefered, setAddDoctorRefered] = useState(initialDoctorRefered);
  const [temperature, setTemperature] = useState("");
  const [unit, setUnit] = useState("days");
  const smallScreen = useMediaQuery("(max-width:768px)");
  const mobileScreen = useMediaQuery("(max-width:425px)");
  //Draggable Table
  const [items, setItems] = React.useState([
    {
      id: "Item 1",
      symptom: " Essential hypertension123",
    },
    {
      id: "Item 2",
      symptom: "Diabetes mellitus",
    },
    {
      id: "Item 3",
      symptom: "Osteoarthritis",
    },
    {
      id: "Item 4",
      symptom: "Acute respiratory infections",
    },
    {
      id: "Item 5",
      symptom: "Joint disorders",
    },
  ]);

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(items, source.index, destination.index);

    setItems(newItems);
  };


  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (value: boolean) => {
    setOpen(value);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onHandleSelectData = (val) => {
    if (smallScreen || mobileScreen) {

      handleClickOpen(true);
    }
    setSelected(val);
  };

  const addRowDoctorRefered = () => {
    const initialData = {
      id: addDoctorRefered.length + 1,
      doctorName: '',
      FollowupReason: '',
      FollowupDuration: '',
      Followup: '',
      FollowupInstructio: ''
    };
    setAddDoctorRefered([...addDoctorRefered, initialData]);
  };
  const handleTemperatureChange = (event) => {
    setTemperature(event.target.value);
  };

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
  };

  const [openFollowUpSummary, setopenFollowUpSummary] = React.useState(false);

  const handleClickOpenFollowUpSummary = (value: boolean) => {
    if (mobileScreen) {
      setopenFollowUpSummary(value);
    }
    else if (smallScreen) {
      setopenFollowUpSummary(value);
    }
  };

  const handleClickCloseFollowUpSummary = () => {
    setopenFollowUpSummary(false);
  };

  return (
    <Box className="main-container">
      <Grid container spacing={2}>
        <Grid item xs={12} mb={2}>
          <Grid container item spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                className="template-table-container"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                  minHeight: 200,
                }}
              >
                <Box className="inner-title-container">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="inner-header-container">
                      <Typography
                        // className={selected ? "symptom-text" : ""}
                        style={{ fontWeight: 600 }}
                      >
                        Follow-up
                      </Typography>
                       {mobileScreen || smallScreen ? <Box>
                <Button size="small" style={{ minWidth: "30px" }} onClick={() => {
                  handleClickOpenFollowUpSummary(true);
                }}  >
                  <SubjectRoundedIcon />
                </Button>
              </Box> : ''}
                     
                    </Box>
                  </Paper>

                  <Formik
                    initialValues={{
                      firstName: "",
                      lastName: "",
                      email: "",
                    }}
                    onSubmit={async (values) => {
                      console.log("values", values);
                    }}
                  >
                    <Box>
                      <Divider />
                      <Grid
                        container
                        item
                        spacing={2}
                        style={{ padding: 20 }}
                      >
                        <Grid item xs={12} sm={6} md={12}>
                          <FormControl
                            sx={{
                              minWidth: "auto",
                            }}
                            fullWidth
                          >
                            <FormLabel className="fileds-label-text">Follow-up Reason</FormLabel>
                            <AsyncCustomSelect
                              tabSelectsValue={false}
                              // openMenuOnFocus={
                              //   saleId <= 0
                              // }
                              isNotCreatable={false}
                              isInnerWidth={true}
                              // optionInnerWidth={
                              //   sourceProductCollection.length <
                              //     1
                              //     ? "837px !important"
                              //     : ""
                              // }
                              menuPortalTarget={document.querySelector("body")}
                              id={"followUpReason"}
                              isClearable={true}
                              FieldName={`followUpReason`}

                              handleChange={(e) => {

                              }}
                            // Value={ }
                            // CustomOptionComponent={  }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                              <Box display="flex" alignItems="center">
                                <FormControl fullWidth>
                                  <FormLabel className="fileds-label-text">
                                    Follow-up
                                  </FormLabel>
                                  <TextField
                                    variant="outlined"
                                    fullWidth
                                    value={temperature}
                                    onChange={handleTemperatureChange}
                                    type="number"
                                    size="small"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <Select
                                            value={unit}
                                            onChange={handleUnitChange}
                                            size="small"
                                            displayEmpty
                                            inputProps={{
                                              "aria-label": "Without label",
                                            }}
                                            sx={{
                                              marginLeft: 1,
                                              ".MuiSelect-select": {
                                                paddingRight: "0px",
                                              },
                                            }}
                                          >
                                            <MenuItem value="days">Days</MenuItem>
                                            <MenuItem value="weeks">Weeks</MenuItem>
                                            <MenuItem value="months">Months</MenuItem>
                                            <MenuItem value="year">Year</MenuItem>
                                          </Select>
                                        </InputAdornment>
                                      ),
                                    }}
                                    sx={{
                                      minWidth: "150px",
                                      ".MuiOutlinedInput-root": {
                                        paddingRight: 0,
                                      },
                                    }}
                                  />
                                </FormControl>
                              </Box>
                            </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Box
                            className="template-table-container"
                            style={{
                              border: "1px solid #0000001f",
                              borderRadius: 4,
                            }}
                          >
                            <Box className="inner-title-container">
                              <FollowupDraggableTable
                                header="Follow-up instruction(s)"
                                items={items}
                                onDragEnd={onDragEnd}
                                onHandleSelectData={onHandleSelectData}
                                selected={selected}
                              />
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container item spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                          <Divider />
                          <Box className="note-container">
                            <Box>
                              {!showNote && (
                                <Button
                                  variant="text"
                                  style={{ fontSize: "6px" }}
                                  onClick={() => {
                                    setShowNote(true);
                                  }}
                                >
                                  <AddIcon className="add-icon" />
                                  <span className="add-icon"> Note</span>
                                </Button>
                              )}
                            </Box>

                            {showNote && (
                              <FormControl fullWidth>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    alignSelf: "end",
                                    minWidth: "100%",
                                  }}
                                >
                                  <FormLabel
                                    sx={{ paddingLeft: 1 }}
                                    className="fileds-label-text"
                                  >
                                    Note
                                  </FormLabel>
                                  <Button
                                    variant="text"
                                    onClick={() => {
                                      setShowNote(false);
                                    }}
                                  >
                                    <RemoveIcon className="remove-note" />
                                    <span className="remove-note">
                                      Remove Note
                                    </span>
                                  </Button>
                                </Box>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Formik>

                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container template-hide-small-screen template-hide-medium-screen"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                  minHeight: 400,
                }}
                style = {{position:"sticky", top:"5px"}}
              >
                <Box className="inner-title-container">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="inner-header-container">
                      <Typography
                        className={selected ? "symptom-text" : ""}
                        style={{ fontWeight: 600 }}
                      >
                        {selected ? selected : "Follow-up Summary"}
                      </Typography>
                      {selected !== '' && <Typography>
                        <IconButton onClick={() => setSelected("")} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                          <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                        </IconButton>
                      </Typography>}
                    </Box>
                  </Paper>
                  <Box>
                    <Divider />
                    <Box className="summary-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1} >
        <Grid item xs={12} sm={6} md={6} sx={{ margin: 0 }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', minWidth: '50%' }}   >
            <Box>
              {!showRefDoctor && (
                <Button
                  variant="text"
                  style={{ fontSize: "6px" }}
                  onClick={() => {
                    setShowRefDoctor(true);
                  }}
                >
                  <AddIcon className="add-icon" />
                  <span className="add-icon"> Doctors to be refered</span>
                </Button>
              )}
            </Box>

            {showRefDoctor && (
              <Box>
                <Button
                  variant="text"
                  onClick={() => {
                    setShowRefDoctor(false);
                  }}
                >
                  <RemoveIcon className="remove-note" />
                  <span className="remove-note">
                    Remove Doctors to be refered
                  </span>
                </Button>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} />
        {showRefDoctor &&
          <Grid item xs={12} sm={12} md={6}>
            <Box
              className="template-table-container"
              sx={{
                border: "1px solid #0000001f",
                borderRadius: 1,
              }}
            >
              <Box className="inner-title-container">
                <Paper
                  style={{
                    flex: 1,
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="inner-header-container">
                    <Typography
                      className={selected ? "symptom-text" : ""}
                      style={{ fontWeight: 600 }}
                    >
                      Doctors to be refered
                    </Typography>
                    <Box>
                      <Button
                        onClick={() => { addRowDoctorRefered() }}
                      >
                        <AddRoundedIcon /></Button>
                    </Box>
                  </Box>
                </Paper>
                <Divider />
                <Formik
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    email: "",
                  }}
                  onSubmit={async (values) => {
                    console.log("values", values);
                  }}
                >
                  <Box>
                    {addDoctorRefered.map(() => {
                      return (
                        <Box>
                          <Grid
                            container
                            item
                            spacing={2}
                            style={{ padding: 20 }}
                          >
                            <Grid item xs={12} sm={6} md={6}>
                              <FormControl
                                sx={{
                                  minWidth: "auto",
                                }}
                                fullWidth
                              >
                                <FormLabel className="fileds-label-text">Doctors Name</FormLabel>
                                <CustomTextField
                                  name="templateName"
                                  placeholder=""
                                  autoCapitalize="none"
                                  type="text"
                                  fullWidth
                                  size="small"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={12}>
                              <FormControl
                                sx={{
                                  minWidth: "auto",
                                }}
                                fullWidth
                              >
                                <FormLabel className="fileds-label-text">Follow-up Reason</FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  handleChange={(e) => {
                                  }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                            {/* <Grid item xs={12} sm={6} md={6}>
                              <FormControl
                                sx={{
                                  minWidth: "auto",
                                }}
                                fullWidth
                              >
                                <FormLabel className="fileds-label-text">Follow-up</FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  handleChange={(e) => {
                                  }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <FormControl
                                sx={{
                                  minWidth: "auto",
                                }}
                                fullWidth
                              >
                                <FormLabel className="fileds-label-text">Follow-up Duration</FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  handleChange={(e) => {
                                  }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid> */}
                            <Grid item xs={12} sm={6} md={4}>
                              <Box display="flex" alignItems="center">
                                <FormControl fullWidth>
                                  <FormLabel className="fileds-label-text">
                                    Follow-up
                                  </FormLabel>
                                  <TextField
                                    variant="outlined"
                                    fullWidth
                                    value={temperature}
                                    onChange={handleTemperatureChange}
                                    type="number"
                                    size="small"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <Select
                                            value={unit}
                                            onChange={handleUnitChange}
                                            size="small"
                                            displayEmpty
                                            inputProps={{
                                              "aria-label": "Without label",
                                            }}
                                            sx={{
                                              marginLeft: 1,
                                              ".MuiSelect-select": {
                                                paddingRight: "0px",
                                              },
                                            }}
                                          >
                                            <MenuItem value="days">Days</MenuItem>
                                            <MenuItem value="weeks">Weeks</MenuItem>
                                            <MenuItem value="months">Months</MenuItem>
                                            <MenuItem value="year">Year</MenuItem>
                                          </Select>
                                        </InputAdornment>
                                      ),
                                    }}
                                    sx={{
                                      minWidth: "150px",
                                      ".MuiOutlinedInput-root": {
                                        paddingRight: 0,
                                      },
                                    }}
                                  />
                                </FormControl>
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                              <Box
                                className="template-table-container"
                                style={{
                                  border: "1px solid #0000001f",
                                  borderRadius: 4,
                                }}
                              >
                                <Box className="inner-title-container">
                                  <FollowupDraggableTable
                                    header="Follow-up instruction(s)"
                                    items={items}
                                    onDragEnd={onDragEnd}
                                    onHandleSelectData={onHandleSelectData}
                                    selected={selected}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                          <Divider />
                          <Box
                            sx={{
                              padding: '10px',
                              borderBottom:'1px solid lightgray'
                            }}
                          >
                            <Box>
                              {!showNote && (
                                <Button
                                  variant="text"
                                  style={{ fontSize: "6px" }}
                                  onClick={() => {
                                    setShowNote(true);
                                  }}
                                >
                                  <AddIcon className="add-icon" />
                                  <span className="add-icon"> Note</span>
                                </Button>
                              )}
                            </Box>
                            {showNote && (
                              <FormControl fullWidth>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    alignSelf: "end",
                                    minWidth: "100%",
                                  }}
                                >
                                  <FormLabel
                                    sx={{ paddingLeft: 1 }}
                                    className="fileds-label-text"
                                  >
                                    Note
                                  </FormLabel>
                                  <Button
                                    variant="text"
                                    onClick={() => {
                                      setShowNote(false);
                                    }}
                                  >
                                    <RemoveIcon className="remove-note" />
                                    <span className="remove-note">Remove Note</span>
                                  </Button>
                                </Box>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            )}
                          </Box>
                        </Box>
                      )
                    })}
                  </Box>

                </Formik>
              </Box>
            </Box>
          </Grid>
        }
      </Grid>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
          <Typography
                        className={selected ? "symptom-text" : ""}
                        style={{ fontWeight: 600 }}
                        sx={{ ml: 2, flex: 1 }}
                      >
                        {selected ? selected : "Follow-up"}
                      </Typography>
                     
                        <IconButton onClick={() => handleClose(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                          <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                        </IconButton>
                     
          </Toolbar>
          <Divider />
        </AppBar>
        <DialogContent style={{ height: 400 }}>
        <Box
                className="template-table-container"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                  minHeight: 200,
                }}
              >
                <Box className="inner-title-container">
                
                  <Box>
                    
                    <Box>This is summary section new one</Box>
                  </Box>
                </Box>
              </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
          <Typography
                          className={selected ? "symptom-text" : ""}
                        style={{ fontWeight: 600 }}
                        sx={{ ml: 2, flex: 1 }}
                      >
                        {selected ? selected : "Follow-up"}
                      </Typography>
                     
                        <IconButton onClick={() => handleClose(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                          <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                        </IconButton>
                     
          </Toolbar>
          <Divider />
        </AppBar>
        <DialogContent style={{ height: 400 }}>
        <Box
                className="template-table-container"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                  minHeight: 200,
                }}
              >
                <Box className="inner-title-container">
                
                  <Box>
                    
                    <Box>This is summary section new one</Box>
                  </Box>
                </Box>
              </Box>
        </DialogContent>
      </Dialog>

      <Dialog
            fullScreen
            open={openFollowUpSummary}
            onClose={handleClickCloseFollowUpSummary}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
                <Typography
                  className={selected ? "symptom-text" : ""}
                  style={{ fontWeight: 600, marginLeft: 0 }}
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                >
                  Follow-up Summary
                </Typography>
                <IconButton onClick={() => handleClickCloseFollowUpSummary(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                  <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent style={{ height: 400 }}>
            <Box className="template-table-container summary-dialog-mainContainer">
                      <Box className="summary-dialog-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
            </Box>
            </DialogContent>
          </Dialog>
    </Box >
  );
}
