import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const DropzoneContainer = styled(Box)(({ theme }) => ({
  border: '2px dashed #cccccc',
  borderRadius: '10px',
  backgroundColor: '#FAFBFD',
  padding: theme.spacing(2),
  textAlign: 'center',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#f0f0f0',
  },
}));

const DragDropFile = ({ onFileAccepted }) => {
  const onDrop = useCallback((acceptedFiles) => {
    onFileAccepted(acceptedFiles);
  }, [onFileAccepted]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false, // Set to true if you want to allow multiple files
  });

  return (
    <DropzoneContainer {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <Typography variant="body1">Drop the files here...</Typography>
      ) : (
        <Typography variant="body1">Drag 'n' drop some files here, or click to select files</Typography>
      )}
    </DropzoneContainer>
  );
};

export default DragDropFile;
