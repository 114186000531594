import "../../../../../../theme/style.scss";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  Typography,
  Dialog,
  DialogContent,
  Button,
  List,
  ListItem,
} from "@mui/material";
import React, { useState } from "react";
import { Formik } from "formik";
import ReactQuill from "react-quill";
import ChiefComplaintsDraggableTable from "../../ChiefComplaints/ChiefComplaintsDraggable/ChiefComplaintsDraggableTable";
import { reorder } from "../../../../../../Helpers/helpers";
import { DropResult } from "react-beautiful-dnd";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { AsyncCustomSelect } from "../../../../../../components/CustomFields/CustomSelect";
import ExaminationDraggableTable from "../ExaminationDraggable/ExaminationDraggableTable";
import useMediaQuery from "@mui/material/useMediaQuery";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import SubjectRoundedIcon from "@mui/icons-material/SubjectRounded";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";


export default function CentralNervousSystem() {
  const [selectedHigherFunctions, setSelectedHigherFunctions] = useState("");
  const [selectedMotorSystem, setSelectedMotorSystem] = useState("");
  const [selectedSensorySystem, setSelectedSensorySystem] = useState("");
  const [selectedCranialNerves, setSelectedCranialNerves] = useState("");
  const [showNote, setShowNote] = useState(false);
  const [showNoteMotorSystem, setShowNoteMotorSystem] = useState(false);
  const [showNoteSensorySystem, setShowNoteSensorySystem] = useState(false);
  const [showNoteCranialNerves, setShowNoteCranialNerves] = useState(false);
  const smallScreen = useMediaQuery("(max-width:768px)");
  const mobileScreen = useMediaQuery("(max-width:425px)");

  const [higherFunctions, setHigherFunctions] = React.useState([
    {
      id: "Item 1",
      symptom: "Stage of consciousness",
    },
    {
      id: "Item 2",
      symptom: "Memory",
    },
    {
      id: "Item 3",
      symptom: "Orientation to time",
    },
    {
      id: "Item 4",
      symptom: "Orientation to place",
    },
    {
      id: "Item 5",
      symptom: "Orientation to person",
    },
    {
      id: "Item 6",
      symptom: "Speech",
    },
    {
      id: "Item 7",
      symptom: "General Behaviour",
    },
    {
      id: "Item 8",
      symptom: "Hallucinations",
    },
    {
      id: "Item 9",
      symptom: "Delusions",
    },
    {
      id: "Item 10",
      symptom: "Gait",
    },
  ]);
  const [motorSystem, setMotorSystem] = React.useState([
    {
      id: "Item 1",
      symptom: "Muscle Bulk",
    },
    {
      id: "Item 2",
      symptom: "Muscle power Grade",
    },
    {
      id: "Item 3",
      symptom: "Muscle tone",
    },
    {
      id: "Item 4",
      symptom: "Co-ordination",
    },
    {
      id: "Item 5",
      symptom: "Finger nose test",
    },
    {
      id: "Item 6",
      symptom: "Finger test",
    },
    {
      id: "Item 7",
      symptom: "Knee-heal test",
    },
    {
      id: "Item 8",
      symptom: "Involuntary movements",
    },
  ]);
  const [sensorySystem, setSensorySystem] = React.useState([
    {
      id: "Item 1",
      symptom: "Fine touch perception",
    },
    {
      id: "Item 2",
      symptom: "Crude touch perception",
    },
    {
      id: "Item 3",
      symptom: "Pain perception",
    },
    {
      id: "Item 4",
      symptom: "Temperature perception",
    },
    {
      id: "Item 5",
      symptom: "Joint sense",
    },
    {
      id: "Item 6",
      symptom: "Tactile localization",
    },
    {
      id: "Item 7",
      symptom: "Tactile discrimination",
    },
    {
      id: "Item 8",
      symptom: "Tactile extinction",
    },
    {
      id: "Item 9",
      symptom: "Cerebellar signs",
    },
    {
      id: "Item 10",
      symptom: "Meningeal signs",
    },
    {
      id: "Item 11",
      symptom: "Glabellar Tap",
    },
    {
      id: "Item 12",
      symptom: "Corneal reflex",
    },
    {
      id: "Item 13",
      symptom: "Conjuctival reflex",
    },
    {
      id: "Item 14",
      symptom: "Palatal reflex",
    },
    {
      id: "Item 15",
      symptom: "Pharygeal reflex",
    },
    {
      id: "Item 16",
      symptom: "Abdominal reflex",
    },
    {
      id: "Item 17",
      symptom: "Planter reflex",
    },
    {
      id: "Item 18",
      symptom: "Jaw jerk",
    },
    {
      id: "Item 19",
      symptom: "Biceps jerk",
    },
    {
      id: "Item 20",
      symptom: "Tricips jerk",
    },
    {
      id: "Item 21",
      symptom: "Knee reflex",
    },
    {
      id: "Item 22",
      symptom: "Ankle reflex",
    },

  ]);
  const [cranialNerves, setCranialNerves] = React.useState([
    {
      id: "Item 1",
      symptom: "Cranial nerve 1",
    },
    {
      id: "Item 2",
      symptom: "Cranial nerve 2",
    },
    {
      id: "Item 3",
      symptom: "Cranial nerve 3",
    },
    {
      id: "Item 4",
      symptom: "Cranial nerve 4",
    },
    {
      id: "Item 5",
      symptom: "Cranial nerve 5",
    },
    {
      id: "Item 6",
      symptom: "Cranial nerve 6",
    },
    {
      id: "Item 7",
      symptom: "Cranial nerve 7",
    },
    {
      id: "Item 8",
      symptom: "Cranial nerve 8",
    },
    {
      id: "Item 9",
      symptom: "Cranial nerve 9",
    },
    {
      id: "Item 10",
      symptom: "Cranial nerve 10",
    },
    {
      id: "Item 11",
      symptom: "Cranial nerve 11",
    },
    {
      id: "Item 12",
      symptom: "Cranial nerve 12",
    },
  ]);

  const [openHigherFunctions, setOpenHigherFunctions] = React.useState(false);

  const handleClickOpenHigherFunctions = (value: boolean) => {
    if (smallScreen) {
      setOpenHigherFunctions(value);
    } else if (mobileScreen) {
      setOpenHigherFunctions(value);
    }

  };
  const handleClickCloseHigherFunctions = () => {
    setOpenHigherFunctions(false);
  };

  const [openMotorSystem, setOpenMotorSystem] = React.useState(false);

  const handleClickOpenMotorSystem = (value: boolean) => {

    if (smallScreen) {
      setOpenMotorSystem(value);
    } else if (mobileScreen) {
      setOpenMotorSystem(value);
    }

  };
  const handleClickCloseMotorSystem = () => {
    setOpenMotorSystem(false);
  };

  const [openSensorySystem, setOpenSensorySystem] = React.useState(false);

  const handleClickOpenSensorySystem = (value: boolean) => {

    if (smallScreen) {
      setOpenSensorySystem(value);
    } else if (mobileScreen) {
      setOpenSensorySystem(value);
    }

  };

  const handleClickCloseSensorySystem = () => {
    setOpenSensorySystem(false);
  };

  const [openCranialNerves, setOpenCranialNerves] = React.useState(false);

  const handleClickOpenCranialNerves = (value: boolean) => {
    if (smallScreen) {
      setOpenCranialNerves(value);
    } else if (mobileScreen) {
      setOpenCranialNerves(value);
    }
  };

  const handleClickCloseCranialNerves = () => {
    setOpenCranialNerves(false);
  };

  // const onDragHigherFunctionsEnd = ({ destination, source }: DropResult) => {
  //   if (!destination) return;
  //   const newItems = reorder(higherFunctions, source.index, destination.index);
  //   setHigherFunctions(newItems);
  // };
  // const onDragMotorSystemEnd = ({ destination, source }: DropResult) => {
  //   if (!destination) return;
  //   const newItems = reorder(motorSystem, source.index, destination.index);
  //   setMotorSystem(newItems);
  // };
  // const onDragSensorySystemEnd = ({ destination, source }: DropResult) => {
  //   if (!destination) return;
  //   const newItems = reorder(sensorySystem, source.index, destination.index);
  //   setSensorySystem(newItems);
  // };
  // const onDragCranialNervesEnd = ({ destination, source }: DropResult) => {
  //   if (!destination) return;
  //   const newItems = reorder(cranialNerves, source.index, destination.index);
  //   setCranialNerves(newItems);
  // };

  // const onHandleSelectHigherFunctions = (val) => {
  //   if(smallScreen){
  //     handleClickOpenHigherFunctions(true);
  //   }else if(mobileScreen){
  //     handleClickOpenHigherFunctions(true);
  //   }
  //   setSelectedHigherFunctions(val);
  // };

  // const onHandleSelectMotorSystem = (val) => {
  //   if(smallScreen){
  //     handleClickOpenMotorSystem(true);
  //   }else if(mobileScreen){
  //     handleClickOpenMotorSystem(true);
  //   }
  //   setSelectedMotorSystem(val);
  // };

  // const onHandleSelectSensorySystem = (val) => {
  //    if(smallScreen){
  //     handleClickOpenSensorySystem(true);
  // }else if(mobileScreen){
  //   handleClickOpenSensorySystem(true);
  // }
  //   setSelectedSensorySystem(val);
  // };

  // const onHandleSelectCranialNerves = (val) => {
  //   if(smallScreen){
  //     handleClickOpenCranialNerves(true);
  // }else if(mobileScreen){
  //   handleClickOpenCranialNerves(true);
  // }

  //   setSelectedCranialNerves(val);
  // };

  const [openHigherFunctionsSummary, setopenHigherFunctionsSummary] = React.useState(false);

  const handleClickOpenHigherFunctionsSummary = (value: boolean) => {
    if (mobileScreen) {
      setopenHigherFunctionsSummary(value);
    }
    else if (smallScreen) {
      setopenHigherFunctionsSummary(value);
    }
  };

  const handleClickCloseHigherFunctionsSummary = () => {
    setopenHigherFunctionsSummary(false);
  };

  const [openMotorSystemSummary, setopenMotorSystemSummary] = React.useState(false);

  const handleClickOpenMotorSystemSummary = (value: boolean) => {
    if (mobileScreen) {
      setopenMotorSystemSummary(value);
    }
    else if (smallScreen) {
      setopenMotorSystemSummary(value);
    }
  };

  const handleClickCloseMotorSystemSummary = () => {
    setopenMotorSystemSummary(false);
  };

  const [openSensorySystemSummary, setopenSensorySystemSummary] = React.useState(false);

  const handleClickOpenSensorySystemSummary = (value: boolean) => {
    if (mobileScreen) {
      setopenSensorySystemSummary(value);
    }
    else if (smallScreen) {
      setopenSensorySystemSummary(value);
    }
  };

  const handleClickCloseSensorySystemSummary = () => {
    setopenSensorySystemSummary(false);
  };

  const [openCranialNervesSummary, setopenCranialNervesSummary] = React.useState(false);

  const handleClickOpenCranialNervesSummary = (value: boolean) => {
    if (mobileScreen) {
      setopenCranialNervesSummary(value);
    }
    else if (smallScreen) {
      setopenCranialNervesSummary(value);
    }
  };

  const handleClickCloseCranialNervesSummary = () => {
    setopenCranialNervesSummary(false);
  };

  return (
    <Box className="main-container">
      <Grid container spacing={0}>
        <Grid item xs={12} mb={2}>
          <Grid container item spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                className="template-table-container"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                }}
              >
                <Box className="inner-title-container">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box className="inner-header-container"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography style={{ fontWeight: 600 }}>
                        Higher Functions
                      </Typography>
                      {mobileScreen || smallScreen ? <Box>
                        <Button onClick={() => handleClickOpenHigherFunctionsSummary(true)} sx={{ padding: 0, margin: 0, minWidth: "30px" }} aria-label="delete">
                          <SubjectRoundedIcon></SubjectRoundedIcon>
                        </Button>
                      </Box> : ''}
                    </Box>
                  </Paper>
                  <Divider />
                  <List>
                    {higherFunctions.map((item: any, index: number) => (
                      <ListItem
                        sx={{
                          borderBottom: "1px solid #E9EBEC",
                          cursor: 'pointer'
                        }}
                        className={
                          selectedHigherFunctions === item.symptom
                            ? "draggable-list-item"
                            : " "
                        }
                      >
                        <Grid container item spacing={2}>
                          <Grid item xs={12} sm={12} md={12}>
                            <Box
                              style={{
                                display: "flex",
                              }}
                              onClick={() => { handleClickOpenHigherFunctions(true) }}
                            >
                              <Grid container item>
                                <Grid item xs={12} sm={12} md={12}>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() => {
                                      setSelectedHigherFunctions(item.symptom)
                                    }}
                                  >
                                    <Box
                                      className={
                                        selectedHigherFunctions === item.symptom
                                          ? "symptom-text"
                                          : " "
                                      }
                                      sx={{ paddingBottom: '8px', paddingTop: '8px' }}

                                    >
                                      <Typography>{item.symptom}</Typography>
                                    </Box>

                                    {selectedHigherFunctions === item.symptom && (
                                      <Box
                                        style={{
                                          paddingTop: "8px",
                                          paddingLeft: '8px'
                                        }}
                                      >
                                        <KeyboardDoubleArrowRightRoundedIcon />
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                  </List>

                  <Box className="note-container">
                    <Box>
                      {!showNote && (
                        <Button
                          variant="text"
                          style={{ fontSize: "6px" }}
                          onClick={() => {
                            setShowNote(true);
                          }}
                        >
                          <AddIcon className="add-icon" />
                          <span className="add-icon"> Note</span>
                        </Button>
                      )}
                    </Box>

                    {showNote && (
                      <FormControl fullWidth>
                        <Box className="note-container-box">
                          <FormLabel
                            sx={{ paddingLeft: 1 }}
                            className="fileds-label-text"
                          >
                            Note
                          </FormLabel>
                          <Button
                            variant="text"
                            onClick={() => {
                              setShowNote(false);
                            }}
                          >
                            <RemoveIcon className="remove-note" />
                            <span className="remove-note">Remove Note</span>
                          </Button>
                        </Box>
                        <ReactQuill
                          className="react-quill-alignment"
                          theme="snow"
                          value={""}
                          onChange={(e) => { }}
                        />
                      </FormControl>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container template-hide-small-screen template-hide-medium-screen"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                }}
                style={{ position: "sticky", top: "5px" }}
              >
                <Box
                  style={{
                    flex: 1,
                    borderRadius: 8,
                    minHeight: 400,
                  }}
                >
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="inner-header-container">
                      <Typography
                        className={selectedHigherFunctions ? "symptom-text" : ""}
                        style={{ fontWeight: 600 }}
                      >
                        {selectedHigherFunctions ? selectedHigherFunctions : "Higher Functions Summary"}
                      </Typography>
                      {selectedHigherFunctions !== '' && <Typography>
                        <IconButton onClick={() => setSelectedHigherFunctions("")} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                          <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                        </IconButton>
                      </Typography>}
                    </Box>
                  </Paper>
                  {selectedHigherFunctions ? (
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                        <Divider />
                        <Grid
                          container
                          item
                          spacing={2}
                          style={{ padding: 20 }}
                        >
                          {/* {selectedHigherFunctions === "Stage of consciousness" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Stage of consciousness"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedHigherFunctions === "Stage of consciousness" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Stage of consciousness
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedHigherFunctions === "Stage of consciousness" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedHigherFunctions === "Memory" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Memory"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedHigherFunctions === "Memory" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Memory
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedHigherFunctions === "Memory" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedHigherFunctions === "Orientation to time" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Orientation to time"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedHigherFunctions === "Orientation to time" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Orientation to time
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedHigherFunctions === "Orientation to time" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedHigherFunctions === "Orientation to place" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Orientation to place"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedHigherFunctions === "Orientation to place" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Orientation to place
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedHigherFunctions === "Orientation to place" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedHigherFunctions === "Orientation to person" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Orientation to person"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedHigherFunctions === "Orientation to person" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Orientation to person
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedHigherFunctions === "Orientation to person" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedHigherFunctions === "Speech" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Speech"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedHigherFunctions === "Speech" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Speech
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedHigherFunctions ===
                            "Speech" && (
                              <Grid item xs={8} sm={4} md={12}>
                                <FormControl fullWidth>
                                  <FormLabel className="fileds-label-text">
                                    Note
                                  </FormLabel>
                                  <ReactQuill
                                    className="react-quill-alignment"
                                    theme="snow"
                                    value={""}
                                    onChange={(e) => { }}
                                  />
                                </FormControl>
                              </Grid>
                            )}
                          {/* {selectedHigherFunctions ===
                            "General Behaviour" && (
                              <Grid item xs={8} sm={4} md={12}>
                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox
                                      onChange={(e) => {
                                        // setSurgeryDoneInPast(!surgeryDoneInPast);
                                        console.log(
                                          "checkbox data:",
                                          e.target.value
                                        );
                                      }}
                                    // value={item.symptom}
                                    />}
                                    label="General Behaviour"
                                  />
                                </FormGroup>
                              </Grid>
                            )} */}
                          {selectedHigherFunctions ===
                            "General Behaviour" && (
                              <Grid item xs={8} sm={4} md={12}>
                                <FormControl fullWidth>
                                  <FormLabel className="fileds-label-text">
                                    General Behaviour
                                  </FormLabel>
                                  <AsyncCustomSelect
                                    tabSelectsValue={false}
                                    // openMenuOnFocus={
                                    //   saleId <= 0
                                    // }
                                    isNotCreatable={false}
                                    isInnerWidth={true}
                                    // optionInnerWidth={
                                    //   sourceProductCollection.length <
                                    //     1
                                    //     ? "837px !important"
                                    //     : ""
                                    // }
                                    menuPortalTarget={document.querySelector("body")}
                                    id={"followUpReason"}
                                    isClearable={true}
                                    FieldName={`followUpReason`}
                                    // onFocus={(e) => {
                                    // }}
                                    // options={ }
                                    // defaultOptions={ }
                                    // loadOptions={
                                    //   loadProductOptions
                                    // }
                                    // onCreateOption={(value) => {  }}
                                    // autoFocus={ }
                                    handleChange={(e) => { }}
                                  // Value={ }
                                  // CustomOptionComponent={  }
                                  />
                                </FormControl>
                              </Grid>
                            )}
                          {selectedHigherFunctions === "General Behaviour" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedHigherFunctions ===
                            "Hallucinations" && (
                              <Grid item xs={8} sm={4} md={12}>
                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox
                                      onChange={(e) => {
                                        // setSurgeryDoneInPast(!surgeryDoneInPast);
                                        console.log(
                                          "checkbox data:",
                                          e.target.value
                                        );
                                      }}
                                    // value={item.symptom}
                                    />}
                                    label="Hallucinations"
                                  />
                                </FormGroup>
                              </Grid>
                            )} */}
                          {selectedHigherFunctions ===
                            "Hallucinations" && (
                              <Grid item xs={8} sm={4} md={12}>
                                <FormControl fullWidth>
                                  <FormLabel className="fileds-label-text">
                                    Hallucinations
                                  </FormLabel>
                                  <AsyncCustomSelect
                                    tabSelectsValue={false}
                                    // openMenuOnFocus={
                                    //   saleId <= 0
                                    // }
                                    isNotCreatable={false}
                                    isInnerWidth={true}
                                    // optionInnerWidth={
                                    //   sourceProductCollection.length <
                                    //     1
                                    //     ? "837px !important"
                                    //     : ""
                                    // }
                                    menuPortalTarget={document.querySelector("body")}
                                    id={"followUpReason"}
                                    isClearable={true}
                                    FieldName={`followUpReason`}
                                    // onFocus={(e) => {
                                    // }}
                                    // options={ }
                                    // defaultOptions={ }
                                    // loadOptions={
                                    //   loadProductOptions
                                    // }
                                    // onCreateOption={(value) => {  }}
                                    // autoFocus={ }
                                    handleChange={(e) => { }}
                                  // Value={ }
                                  // CustomOptionComponent={  }
                                  />
                                </FormControl>
                              </Grid>
                            )}
                          {selectedHigherFunctions === "Hallucinations" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedHigherFunctions ===
                            "Delusions" && (
                              <Grid item xs={8} sm={4} md={12}>
                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox
                                      onChange={(e) => {
                                        // setSurgeryDoneInPast(!surgeryDoneInPast);
                                        console.log(
                                          "checkbox data:",
                                          e.target.value
                                        );
                                      }}
                                    // value={item.symptom}
                                    />}
                                    label="Delusions"
                                  />
                                </FormGroup>
                              </Grid>
                            )} */}
                          {selectedHigherFunctions ===
                            "Delusions" && (
                              <Grid item xs={8} sm={4} md={12}>
                                <FormControl fullWidth>
                                  <FormLabel className="fileds-label-text">
                                    Delusions
                                  </FormLabel>
                                  <AsyncCustomSelect
                                    tabSelectsValue={false}
                                    // openMenuOnFocus={
                                    //   saleId <= 0
                                    // }
                                    isNotCreatable={false}
                                    isInnerWidth={true}
                                    // optionInnerWidth={
                                    //   sourceProductCollection.length <
                                    //     1
                                    //     ? "837px !important"
                                    //     : ""
                                    // }
                                    menuPortalTarget={document.querySelector("body")}
                                    id={"followUpReason"}
                                    isClearable={true}
                                    FieldName={`followUpReason`}
                                    // onFocus={(e) => {
                                    // }}
                                    // options={ }
                                    // defaultOptions={ }
                                    // loadOptions={
                                    //   loadProductOptions
                                    // }
                                    // onCreateOption={(value) => {  }}
                                    // autoFocus={ }
                                    handleChange={(e) => { }}
                                  // Value={ }
                                  // CustomOptionComponent={  }
                                  />
                                </FormControl>
                              </Grid>
                            )}
                          {selectedHigherFunctions === "Delusions" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedHigherFunctions ===
                            "Gait" && (
                              <Grid item xs={8} sm={4} md={12}>
                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox
                                      onChange={(e) => {
                                        // setSurgeryDoneInPast(!surgeryDoneInPast);
                                        console.log(
                                          "checkbox data:",
                                          e.target.value
                                        );
                                      }}
                                    // value={item.symptom}
                                    />}
                                    label="Gait"
                                  />
                                </FormGroup>
                              </Grid>
                            )} */}
                          {selectedHigherFunctions ===
                            "Gait" && (
                              <Grid item xs={8} sm={4} md={12}>
                                <FormControl fullWidth>
                                  <FormLabel className="fileds-label-text">
                                    Gait
                                  </FormLabel>
                                  <AsyncCustomSelect
                                    tabSelectsValue={false}
                                    // openMenuOnFocus={
                                    //   saleId <= 0
                                    // }
                                    isNotCreatable={false}
                                    isInnerWidth={true}
                                    // optionInnerWidth={
                                    //   sourceProductCollection.length <
                                    //     1
                                    //     ? "837px !important"
                                    //     : ""
                                    // }
                                    menuPortalTarget={document.querySelector("body")}
                                    id={"followUpReason"}
                                    isClearable={true}
                                    FieldName={`followUpReason`}
                                    // onFocus={(e) => {
                                    // }}
                                    // options={ }
                                    // defaultOptions={ }
                                    // loadOptions={
                                    //   loadProductOptions
                                    // }
                                    // onCreateOption={(value) => {  }}
                                    // autoFocus={ }
                                    handleChange={(e) => { }}
                                  // Value={ }
                                  // CustomOptionComponent={  }
                                  />
                                </FormControl>
                              </Grid>
                            )}
                          {selectedHigherFunctions === "Gait" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Formik>
                  ) : (
                    <Box>  <Divider />
                     <Box className="summary-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mb={2}>
          <Grid container item spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                className="template-table-container"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                }}
              >
                <Box className="inner-title-container">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box className="inner-header-container"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography style={{ fontWeight: 600 }}>
                        Motor System
                      </Typography>
                      {mobileScreen || smallScreen ? <Box>
                        <Button onClick={() => handleClickOpenMotorSystemSummary(true)} sx={{ padding: 0, margin: 0, minWidth: "30px" }} aria-label="delete">
                          <SubjectRoundedIcon></SubjectRoundedIcon>
                        </Button>
                      </Box> : ''}
                    </Box>
                  </Paper>
                  <Divider />
                  <List>
                    {motorSystem.map((item: any, index: number) => (
                      <ListItem
                        sx={{
                          borderBottom: "1px solid #E9EBEC",
                          cursor: 'pointer'
                        }}
                        className={
                          selectedMotorSystem === item.symptom
                            ? "draggable-list-item"
                            : " "
                        }
                      >
                        <Grid container item spacing={2}>
                          <Grid item xs={12} sm={12} md={12}>
                            <Box
                              style={{
                                display: "flex",
                              }}
                              onClick={() => { handleClickOpenMotorSystem(true) }}
                            >
                              <Grid container item>
                                <Grid item xs={12} sm={12} md={12}>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() => {
                                      setSelectedMotorSystem(item.symptom)
                                    }}
                                  >
                                    <Box
                                      className={
                                        selectedMotorSystem === item.symptom
                                          ? "symptom-text"
                                          : " "
                                      }
                                      sx={{ paddingBottom: '8px', paddingTop: '8px' }}

                                    >
                                      <Typography>{item.symptom}</Typography>
                                    </Box>

                                    {selectedMotorSystem === item.symptom && (
                                      <Box
                                        style={{
                                          paddingTop: "8px",
                                          paddingLeft: '8px'
                                        }}
                                      >
                                        <KeyboardDoubleArrowRightRoundedIcon />
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                  </List>

                  <Box className="note-container">
                    <Box>
                      {!showNoteMotorSystem && (
                        <Button
                          variant="text"
                          style={{ fontSize: "6px" }}
                          onClick={() => {
                            setShowNoteMotorSystem(true);
                          }}
                        >
                          <AddIcon className="add-icon" />
                          <span className="add-icon"> Note</span>
                        </Button>
                      )}
                    </Box>

                    {showNoteMotorSystem && (
                      <FormControl fullWidth>
                        <Box className="note-container-box">
                          <FormLabel
                            sx={{ paddingLeft: 1 }}
                            className="fileds-label-text"
                          >
                            Note
                          </FormLabel>
                          <Button
                            variant="text"
                            onClick={() => {
                              setShowNoteMotorSystem(false);
                            }}
                          >
                            <RemoveIcon className="remove-note" />
                            <span className="remove-note">Remove Note</span>
                          </Button>
                        </Box>
                        <ReactQuill
                          className="react-quill-alignment"
                          theme="snow"
                          value={""}
                          onChange={(e) => { }}
                        />
                      </FormControl>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container template-hide-small-screen template-hide-medium-screen"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                }}
                style={{ position: "sticky", top: "5px" }}
              >
                <Box
                  style={{
                    flex: 1,
                    borderRadius: 8,
                    minHeight: 400,
                  }}
                >
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    {/* <Box className="inner-header-container">
                      <Typography
                        className="symptom-text"
                        style={{ fontWeight: 600 }}
                      >
                        {selectedMotorSystem ? selectedMotorSystem : "Motor System"}
                      </Typography>
                    </Box> */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="inner-header-container">
                      <Typography
                        className={selectedMotorSystem ? "symptom-text" : ""}
                        style={{ fontWeight: 600 }}
                      >
                        {selectedMotorSystem ? selectedMotorSystem : "Motor System Summary"}
                      </Typography>
                      {selectedMotorSystem !== '' && <Typography>
                        <IconButton onClick={() => setSelectedMotorSystem("")} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                          <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                        </IconButton>
                      </Typography>}
                    </Box>
                  </Paper>
                  {selectedMotorSystem ? (
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                        <Divider />
                        <Grid
                          container
                          item
                          spacing={2}
                          style={{ padding: 20 }}
                        >
                          {/* {selectedMotorSystem ===
                            "Muscle Bulk" && (
                              <Grid item xs={8} sm={4} md={12}>
                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox
                                      onChange={(e) => {
                                        // setSurgeryDoneInPast(!surgeryDoneInPast);
                                        console.log(
                                          "checkbox data:",
                                          e.target.value
                                        );
                                      }}
                                    // value={item.symptom}
                                    />}
                                    label="Muscle Bulk"
                                  />
                                </FormGroup>
                              </Grid>
                            )} */}
                          {selectedMotorSystem ===
                            "Muscle Bulk" && (
                              <Grid item xs={8} sm={4} md={12}>
                                <FormControl fullWidth>
                                  <FormLabel className="fileds-label-text">
                                    Muscle Bulk
                                  </FormLabel>
                                  <AsyncCustomSelect
                                    tabSelectsValue={false}
                                    // openMenuOnFocus={
                                    //   saleId <= 0
                                    // }
                                    isNotCreatable={false}
                                    isInnerWidth={true}
                                    // optionInnerWidth={
                                    //   sourceProductCollection.length <
                                    //     1
                                    //     ? "837px !important"
                                    //     : ""
                                    // }
                                    menuPortalTarget={document.querySelector("body")}
                                    id={"followUpReason"}
                                    isClearable={true}
                                    FieldName={`followUpReason`}
                                    // onFocus={(e) => {
                                    // }}
                                    // options={ }
                                    // defaultOptions={ }
                                    // loadOptions={
                                    //   loadProductOptions
                                    // }
                                    // onCreateOption={(value) => {  }}
                                    // autoFocus={ }
                                    handleChange={(e) => { }}
                                  // Value={ }
                                  // CustomOptionComponent={  }
                                  />
                                </FormControl>
                              </Grid>
                            )}
                          {selectedMotorSystem === "Muscle Bulk" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedMotorSystem === "Muscle power Grade" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Muscle power Grade"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedMotorSystem === "Muscle power Grade" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Muscle power Grade
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedMotorSystem === "Muscle power Grade" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedMotorSystem === "Muscle tone" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Muscle tone"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedMotorSystem === "Muscle tone" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Muscle tone
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedMotorSystem === "Muscle tone" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedMotorSystem ===
                            "Co-ordination" && (
                              <Grid item xs={8} sm={4} md={12}>
                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox
                                      onChange={(e) => {
                                        // setSurgeryDoneInPast(!surgeryDoneInPast);
                                        console.log(
                                          "checkbox data:",
                                          e.target.value
                                        );
                                      }}
                                    // value={item.symptom}
                                    />}
                                    label="Co-ordination"
                                  />
                                </FormGroup>
                              </Grid>
                            )} */}
                          {selectedMotorSystem ===
                            "Co-ordination" && (
                              <Grid item xs={8} sm={4} md={12}>
                                <FormControl fullWidth>
                                  <FormLabel className="fileds-label-text">
                                    Co-ordination
                                  </FormLabel>
                                  <AsyncCustomSelect
                                    tabSelectsValue={false}
                                    // openMenuOnFocus={
                                    //   saleId <= 0
                                    // }
                                    isNotCreatable={false}
                                    isInnerWidth={true}
                                    // optionInnerWidth={
                                    //   sourceProductCollection.length <
                                    //     1
                                    //     ? "837px !important"
                                    //     : ""
                                    // }
                                    menuPortalTarget={document.querySelector("body")}
                                    id={"followUpReason"}
                                    isClearable={true}
                                    FieldName={`followUpReason`}
                                    // onFocus={(e) => {
                                    // }}
                                    // options={ }
                                    // defaultOptions={ }
                                    // loadOptions={
                                    //   loadProductOptions
                                    // }
                                    // onCreateOption={(value) => {  }}
                                    // autoFocus={ }
                                    handleChange={(e) => { }}
                                  // Value={ }
                                  // CustomOptionComponent={  }
                                  />
                                </FormControl>
                              </Grid>
                            )}
                          {selectedMotorSystem ===
                            "Co-ordination" && (
                              <Grid item xs={8} sm={4} md={12}>
                                <FormControl fullWidth>
                                  <FormLabel className="fileds-label-text">
                                    Note
                                  </FormLabel>
                                  <ReactQuill
                                    className="react-quill-alignment"
                                    theme="snow"
                                    value={""}
                                    onChange={(e) => { }}
                                  />
                                </FormControl>
                              </Grid>
                            )}
                          {/* {selectedMotorSystem ===
                            "Finger nose test" && (
                              <Grid item xs={8} sm={4} md={12}>
                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox
                                      onChange={(e) => {
                                        // setSurgeryDoneInPast(!surgeryDoneInPast);
                                        console.log(
                                          "checkbox data:",
                                          e.target.value
                                        );
                                      }}
                                    // value={item.symptom}
                                    />}
                                    label="Finger nose test"
                                  />
                                </FormGroup>
                              </Grid>
                            )} */}
                          {selectedMotorSystem ===
                            "Finger nose test" && (
                              <Grid item xs={8} sm={4} md={12}>
                                <FormControl fullWidth>
                                  <FormLabel className="fileds-label-text">
                                    Finger nose test
                                  </FormLabel>
                                  <AsyncCustomSelect
                                    tabSelectsValue={false}
                                    // openMenuOnFocus={
                                    //   saleId <= 0
                                    // }
                                    isNotCreatable={false}
                                    isInnerWidth={true}
                                    // optionInnerWidth={
                                    //   sourceProductCollection.length <
                                    //     1
                                    //     ? "837px !important"
                                    //     : ""
                                    // }
                                    menuPortalTarget={document.querySelector("body")}
                                    id={"followUpReason"}
                                    isClearable={true}
                                    FieldName={`followUpReason`}
                                    // onFocus={(e) => {
                                    // }}
                                    // options={ }
                                    // defaultOptions={ }
                                    // loadOptions={
                                    //   loadProductOptions
                                    // }
                                    // onCreateOption={(value) => {  }}
                                    // autoFocus={ }
                                    handleChange={(e) => { }}
                                  // Value={ }
                                  // CustomOptionComponent={  }
                                  />
                                </FormControl>
                              </Grid>
                            )}

                          {selectedMotorSystem === "Finger nose test" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedMotorSystem === "Finger test" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Finger test
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedMotorSystem === "Finger test" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedMotorSystem === "Knee-heal test" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Knee-heal test"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedMotorSystem === "Knee-heal test" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Knee-heal test
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedMotorSystem === "Knee-heal test" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedMotorSystem === "Involuntary movements" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Involuntary movements"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedMotorSystem === "Involuntary movements" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Involuntary movements
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedMotorSystem === "Involuntary movements" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Formik>
                  ) : (
                    <Box>  <Divider />
                      <Box className="summary-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mb={2}>
          <Grid container item spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                className="template-table-container"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                }}
              >
                <Box className="inner-title-container">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box className="inner-header-container"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography style={{ fontWeight: 600 }}>
                        Sensory System
                      </Typography>
                      {mobileScreen || smallScreen ? <Box>
                        <Button onClick={() => handleClickOpenSensorySystemSummary(true)} sx={{ padding: 0, margin: 0, minWidth: "30px" }} aria-label="delete">
                          <SubjectRoundedIcon></SubjectRoundedIcon>
                        </Button>
                      </Box> : ''}
                    </Box>
                  </Paper>
                  <Divider />
                  <List>
                    {sensorySystem.map((item: any, index: number) => (
                      <ListItem
                        sx={{
                          borderBottom: "1px solid #E9EBEC",
                          cursor: 'pointer'
                        }}
                        className={
                          selectedSensorySystem === item.symptom
                            ? "draggable-list-item"
                            : " "
                        }
                      >
                        <Grid container item spacing={2}>
                          <Grid item xs={12} sm={12} md={12}>
                            <Box
                              style={{
                                display: "flex",
                              }}
                              onClick={() => { handleClickOpenSensorySystem(true) }}
                            >
                              <Grid container item>
                                <Grid item xs={12} sm={12} md={12}>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() => {
                                      setSelectedSensorySystem(item.symptom)
                                    }}
                                  >
                                    <Box
                                      className={
                                        selectedSensorySystem === item.symptom
                                          ? "symptom-text"
                                          : " "
                                      }
                                      sx={{ paddingBottom: '8px', paddingTop: '8px' }}

                                    >
                                      <Typography>{item.symptom}</Typography>
                                    </Box>

                                    {selectedSensorySystem === item.symptom && (
                                      <Box
                                        style={{
                                          paddingTop: "8px",
                                          paddingLeft: '8px'
                                        }}
                                      >
                                        <KeyboardDoubleArrowRightRoundedIcon />
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                  </List>

                  <Box className="note-container">
                    <Box>
                      {!showNoteSensorySystem && (
                        <Button
                          variant="text"
                          style={{ fontSize: "6px" }}
                          onClick={() => {
                            setShowNoteSensorySystem(true);
                          }}
                        >
                          <AddIcon className="add-icon" />
                          <span className="add-icon"> Note</span>
                        </Button>
                      )}
                    </Box>

                    {showNoteSensorySystem && (
                      <FormControl fullWidth>
                        <Box className="note-container-box">
                          <FormLabel
                            sx={{ paddingLeft: 1 }}
                            className="fileds-label-text"
                          >
                            Note
                          </FormLabel>
                          <Button
                            variant="text"
                            onClick={() => {
                              setShowNoteSensorySystem(false);
                            }}
                          >
                            <RemoveIcon className="remove-note" />
                            <span className="remove-note">Remove Note</span>
                          </Button>
                        </Box>
                        <ReactQuill
                          className="react-quill-alignment"
                          theme="snow"
                          value={""}
                          onChange={(e) => { }}
                        />
                      </FormControl>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container template-hide-small-screen template-hide-medium-screen"
                sx={{

                  border: "1px solid #0000001f",
                  borderRadius: 1,
                }}
                style={{ position: "sticky", top: "5px" }}
              >
                <Box
                  style={{
                    flex: 1,
                    borderRadius: 8,
                    minHeight: 400,
                  }}
                >
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="inner-header-container">
                      <Typography
                        className={selectedSensorySystem ? "symptom-text" : ""}
                        style={{ fontWeight: 600 }}
                      >
                        {selectedSensorySystem ? selectedSensorySystem : "Sensory System Summary"}
                      </Typography>
                      {selectedSensorySystem !== '' && <Typography>
                        <IconButton onClick={() => setSelectedSensorySystem("")} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                          <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                        </IconButton>
                      </Typography>}
                    </Box>
                  </Paper>
                  {selectedSensorySystem ? (
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                        <Divider />
                        <Grid
                          container
                          item
                          spacing={2}
                          style={{ padding: 20 }}
                        >
                          {/* {selectedSensorySystem === "Fine touch perception" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Fine touch perception"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedSensorySystem === "Fine touch perception" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Fine touch perception
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedSensorySystem === "Fine touch perception" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedSensorySystem === "Crude touch perception" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Built"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedSensorySystem === "Crude touch perception" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Crude touch perception
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedSensorySystem === "Crude touch perception" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedSensorySystem === "Pain perception" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Built"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedSensorySystem === "Pain perception" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Pain perception
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedSensorySystem === "Pain perception" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedSensorySystem === "Temperature perception" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Built"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedSensorySystem === "Temperature perception" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Temperature perception
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedSensorySystem === "Temperature perception" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}

                          {/* {selectedSensorySystem === "Joint sense" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Joint sense"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedSensorySystem === "Joint sense" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Joint sense
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedSensorySystem === "Joint sense" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedSensorySystem === "Tactile localization" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Tactile localization"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedSensorySystem === "Tactile localization" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Tactile localization
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedSensorySystem === "Tactile localization" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedSensorySystem === "Tactile discrimination" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Tactile discrimination"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedSensorySystem === "Tactile discrimination" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Tactile discrimination
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedSensorySystem === "Tactile discrimination" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedSensorySystem === "Tactile extinction" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Tactile extinction"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedSensorySystem === "Tactile extinction" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Tactile extinction
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedSensorySystem === "Tactile extinction" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedSensorySystem === "Cerebellar signs" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Cerebellar signs"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedSensorySystem === "Cerebellar signs" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Cerebellar signs
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedSensorySystem === "Cerebellar signs" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedSensorySystem === "Meningeal signs" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Meningeal signs"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedSensorySystem === "Meningeal signs" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Meningeal signs
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedSensorySystem === "Meningeal signs" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedSensorySystem === "Glabellar Tap" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Glabellar Tap"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedSensorySystem === "Glabellar Tap" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Glabellar Tap
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedSensorySystem === "Glabellar Tap" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedSensorySystem === "Corneal reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Corneal reflex"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedSensorySystem === "Corneal reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Corneal reflex
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedSensorySystem === "Corneal reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedSensorySystem === "Conjuctival reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Conjuctival reflex"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedSensorySystem === "Conjuctival reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Conjuctival reflex
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedSensorySystem === "Conjuctival reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedSensorySystem === "Palatal reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Palatal reflex"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedSensorySystem === "Palatal reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Palatal reflex
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedSensorySystem === "Palatal reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedSensorySystem === "Pharygeal reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Pharygeal reflex"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedSensorySystem === "Pharygeal reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Pharygeal reflex
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedSensorySystem === "Pharygeal reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedSensorySystem === "Abdominal reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Abdominal reflex"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedSensorySystem === "Abdominal reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Abdominal reflex
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedSensorySystem === "Abdominal reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedSensorySystem === "Planter reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Planter reflex"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedSensorySystem === "Planter reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Planter reflex
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedSensorySystem === "Planter reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedSensorySystem === "Jaw jerk" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Jaw jerk"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedSensorySystem === "Jaw jerk" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Jaw jerk
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedSensorySystem === "Jaw jerk" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedSensorySystem === "Biceps jerk" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Biceps jerk"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedSensorySystem === "Biceps jerk" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Biceps jerk
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedSensorySystem === "Biceps jerk" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedSensorySystem === "Tricips jerk" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Tricips jerk"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedSensorySystem === "Tricips jerk" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Tricips jerk
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedSensorySystem === "Tricips jerk" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedSensorySystem === "Knee reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Knee reflex"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedSensorySystem === "Knee reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Knee reflex
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedSensorySystem === "Knee reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedSensorySystem === "Ankle reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Ankle reflex"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedSensorySystem === "Ankle reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Ankle reflex
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedSensorySystem === "Ankle reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Formik>
                  ) : (
                    <Box>  <Divider />
                      <Box className="summary-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mb={2}>
          <Grid container item spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                className="template-table-container"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                }}
              >
                <Box className="inner-title-container">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box className="inner-header-container"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography style={{ fontWeight: 600 }}>
                        Cranial Nerves
                      </Typography>
                      {mobileScreen || smallScreen ? <Box>
                        <Button onClick={() => handleClickOpenCranialNervesSummary(true)} sx={{ padding: 0, margin: 0, minWidth: "30px" }} aria-label="delete">
                          <SubjectRoundedIcon></SubjectRoundedIcon>
                        </Button>
                      </Box> : ''}
                    </Box>
                  </Paper>
                  <Divider />
                  <List>
                    {cranialNerves.map((item: any, index: number) => (
                      <ListItem
                        sx={{
                          borderBottom: "1px solid #E9EBEC",
                          cursor: 'pointer'
                        }}
                        className={
                          selectedCranialNerves === item.symptom
                            ? "draggable-list-item"
                            : " "
                        }
                      >
                        <Grid container item spacing={2}>
                          <Grid item xs={12} sm={12} md={12}>
                            <Box
                              style={{
                                display: "flex",
                              }}
                              onClick={() => { handleClickOpenCranialNerves(true) }}
                            >
                              <Grid container item>
                                <Grid item xs={12} sm={12} md={12}>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() => {
                                      setSelectedCranialNerves(item.symptom)
                                    }}
                                  >
                                    <Box
                                      className={
                                        selectedCranialNerves === item.symptom
                                          ? "symptom-text"
                                          : " "
                                      }
                                      sx={{ paddingBottom: '8px', paddingTop: '8px' }}

                                    >
                                      <Typography>{item.symptom}</Typography>
                                    </Box>

                                    {selectedCranialNerves === item.symptom && (
                                      <Box
                                        style={{
                                          paddingTop: "8px",
                                          paddingLeft: '8px'
                                        }}
                                      >
                                        <KeyboardDoubleArrowRightRoundedIcon />
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                  </List>

                  <Box className="note-container">
                    <Box>
                      {!showNoteCranialNerves && (
                        <Button
                          variant="text"
                          style={{ fontSize: "6px" }}
                          onClick={() => {
                            setShowNoteCranialNerves(true);
                          }}
                        >
                          <AddIcon className="add-icon" />
                          <span className="add-icon"> Note</span>
                        </Button>
                      )}
                    </Box>

                    {showNoteCranialNerves && (
                      <FormControl fullWidth>
                        <Box className="note-container-box">
                          <FormLabel
                            sx={{ paddingLeft: 1 }}
                            className="fileds-label-text"
                          >
                            Note
                          </FormLabel>
                          <Button
                            variant="text"
                            onClick={() => {
                              setShowNoteCranialNerves(false);
                            }}
                          >
                            <RemoveIcon className="remove-note" />
                            <span className="remove-note">Remove Note</span>
                          </Button>
                        </Box>
                        <ReactQuill
                          className="react-quill-alignment"
                          theme="snow"
                          value={""}
                          onChange={(e) => { }}
                        />
                      </FormControl>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container template-hide-small-screen template-hide-medium-screen"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                }}
                style={{ position: "sticky", top: "5px" }}
              >
                <Box
                  style={{
                    flex: 1,
                    borderRadius: 8,
                    minHeight: 400,
                  }}
                >
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="inner-header-container">
                      <Typography
                        className={selectedCranialNerves ? "symptom-text" : ""}
                        style={{ fontWeight: 600 }}
                      >
                        {selectedCranialNerves ? selectedCranialNerves : "Cranial Nerves Summary"}
                      </Typography>
                      {selectedCranialNerves !== '' && <Typography>
                        <IconButton onClick={() => setSelectedCranialNerves("")} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                          <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                        </IconButton>
                      </Typography>}
                    </Box>
                  </Paper>
                  {selectedCranialNerves ? (
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                        <Divider />
                        <Grid
                          container
                          item
                          spacing={2}
                          style={{ padding: 20 }}
                        >
                          {/* {selectedCranialNerves === "Cranial nerve 1" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Cranial nerve 1"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedCranialNerves === "Cranial nerve 1" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Cranial nerve 1
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedCranialNerves === "Cranial nerve 1" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedCranialNerves === "Cranial nerve 2" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Cranial nerve 2"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedCranialNerves === "Cranial nerve 2" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Cranial nerve 2
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedCranialNerves === "Cranial nerve 2" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedCranialNerves === "Cranial nerve 3" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Cranial nerve 3"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedCranialNerves === "Cranial nerve 3" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Cranial nerve 3
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedCranialNerves === "Cranial nerve 3" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedCranialNerves === "Cranial nerve 4" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Cranial nerve 4"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedCranialNerves === "Cranial nerve 4" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Cranial nerve 4
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedCranialNerves === "Cranial nerve 4" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedCranialNerves === "Cranial nerve 5" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Cranial nerve 5"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedCranialNerves === "Cranial nerve 5" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Cranial nerve 5
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedCranialNerves === "Cranial nerve 5" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedCranialNerves === "Cranial nerve 6" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Cranial nerve 6"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedCranialNerves === "Cranial nerve 6" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Cranial nerve 6
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedCranialNerves === "Cranial nerve 6" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedCranialNerves === "Cranial nerve 7" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Cranial nerve 7"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedCranialNerves === "Cranial nerve 7" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Cranial nerve 7
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedCranialNerves === "Cranial nerve 7" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedCranialNerves === "Cranial nerve 8" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Cranial nerve 8
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedCranialNerves === "Cranial nerve 8" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedCranialNerves === "Cranial nerve 9" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Cranial nerve 9
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedCranialNerves === "Cranial nerve 9" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedCranialNerves === "Cranial nerve 10" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Cranial nerve 10
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedCranialNerves === "Cranial nerve 10" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {/* {selectedCranialNerves === "Cranial nerve 11" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Cranial nerve 10"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                          {selectedCranialNerves === "Cranial nerve 11" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Cranial nerve 11
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedCranialNerves === "Cranial nerve 11" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedCranialNerves === "Cranial nerve 12" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Cranial nerve 12
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                          {selectedCranialNerves === "Cranial nerve 12" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Formik>
                  ) : (
                    <Box>  <Divider />
                      <Box className="summary-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        fullScreen
        open={openHigherFunctions}
        onClose={handleClickCloseHigherFunctions}

      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar
            sx={{ display:"flex",
              justifyContent:"space-between"}}>
            <Typography
            
              style={{ fontWeight: 600}}
              sx={{
                paddingLeft:{
                  xs:1,
                  sm:0,
                }
              }}
              variant="h6"
            >
              {selectedHigherFunctions ? selectedHigherFunctions : "Higher Functions"}
            </Typography>
            <IconButton onClick={() => handleClickCloseHigherFunctions(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
              <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
            </IconButton>
          </Toolbar>
          <Divider />
        </AppBar>
        <DialogContent style={{ height: 400 }}>

          <Grid item xs={12} sm={6} md={6}>
            <Box
              className="template-table-container"
              sx={{
                border: "0px solid #0000001f",
                borderRadius: 1,
              }}
            >
              <Box
                style={{
                  flex: 1,
                  borderRadius: 8,
                  minHeight: 400,
                }}
              >

                {selectedHigherFunctions ? (
                  <Formik
                    initialValues={{
                      firstName: "",
                      lastName: "",
                      email: "",
                    }}
                    onSubmit={async (values) => {
                      console.log("values", values);
                    }}
                  >
                    <Box>

                      <Grid
                        container
                        item
                        spacing={2}

                      >
                        {/* {selectedHigherFunctions === "Stage of consciousness" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Stage of consciousness"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedHigherFunctions === "Stage of consciousness" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Stage of consciousness
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedHigherFunctions === "Stage of consciousness" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedHigherFunctions === "Memory" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Memory"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedHigherFunctions === "Memory" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Memory
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedHigherFunctions === "Memory" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedHigherFunctions === "Orientation to time" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Orientation to time"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedHigherFunctions === "Orientation to time" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Orientation to time
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedHigherFunctions === "Orientation to time" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedHigherFunctions === "Orientation to place" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Orientation to place"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedHigherFunctions === "Orientation to place" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Orientation to place
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedHigherFunctions === "Orientation to place" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedHigherFunctions === "Orientation to person" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Orientation to person"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedHigherFunctions === "Orientation to person" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Orientation to person
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedHigherFunctions === "Orientation to person" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedHigherFunctions === "Speech" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Speech"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedHigherFunctions === "Speech" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Speech
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedHigherFunctions ===
                          "Speech" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                        {/* {selectedHigherFunctions ===
                            "General Behaviour" && (
                              <Grid item xs={8} sm={4} md={12}>
                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox
                                      onChange={(e) => {
                                        // setSurgeryDoneInPast(!surgeryDoneInPast);
                                        console.log(
                                          "checkbox data:",
                                          e.target.value
                                        );
                                      }}
                                    // value={item.symptom}
                                    />}
                                    label="General Behaviour"
                                  />
                                </FormGroup>
                              </Grid>
                            )} */}
                        {selectedHigherFunctions ===
                          "General Behaviour" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  General Behaviour
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                        {selectedHigherFunctions === "General Behaviour" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedHigherFunctions ===
                            "Hallucinations" && (
                              <Grid item xs={8} sm={4} md={12}>
                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox
                                      onChange={(e) => {
                                        // setSurgeryDoneInPast(!surgeryDoneInPast);
                                        console.log(
                                          "checkbox data:",
                                          e.target.value
                                        );
                                      }}
                                    // value={item.symptom}
                                    />}
                                    label="Hallucinations"
                                  />
                                </FormGroup>
                              </Grid>
                            )} */}
                        {selectedHigherFunctions ===
                          "Hallucinations" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Hallucinations
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                        {selectedHigherFunctions === "Hallucinations" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedHigherFunctions ===
                            "Delusions" && (
                              <Grid item xs={8} sm={4} md={12}>
                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox
                                      onChange={(e) => {
                                        // setSurgeryDoneInPast(!surgeryDoneInPast);
                                        console.log(
                                          "checkbox data:",
                                          e.target.value
                                        );
                                      }}
                                    // value={item.symptom}
                                    />}
                                    label="Delusions"
                                  />
                                </FormGroup>
                              </Grid>
                            )} */}
                        {selectedHigherFunctions ===
                          "Delusions" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Delusions
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                        {selectedHigherFunctions === "Delusions" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedHigherFunctions ===
                            "Gait" && (
                              <Grid item xs={8} sm={4} md={12}>
                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox
                                      onChange={(e) => {
                                        // setSurgeryDoneInPast(!surgeryDoneInPast);
                                        console.log(
                                          "checkbox data:",
                                          e.target.value
                                        );
                                      }}
                                    // value={item.symptom}
                                    />}
                                    label="Gait"
                                  />
                                </FormGroup>
                              </Grid>
                            )} */}
                        {selectedHigherFunctions ===
                          "Gait" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Gait
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                        {selectedHigherFunctions === "Gait" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Formik>
                ) : (
                  <Box>  <Divider />
                    <Box>This is summary section </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        fullScreen
        open={openMotorSystem}
        onClose={handleClickCloseMotorSystem}

      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar
               sx={{ display:"flex",
                justifyContent:"space-between"}}>
            <Typography
             
              style={{ fontWeight: 600 }}
              sx={{
                paddingLeft:{
                  xs:1,
                  sm:0,
                }
              }}
              variant="h6"
            >
              {selectedMotorSystem ? selectedMotorSystem : "Motor System"}
            </Typography>
            <IconButton onClick={() => handleClickCloseMotorSystem(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
              <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
            </IconButton>
          </Toolbar>
          <Divider />
        </AppBar>
        <DialogContent style={{ height: 400 }}>
          <Grid item xs={12} sm={6} md={6}>
            <Box
              className="template-table-container"
              sx={{
                border: "0px solid #0000001f",
                borderRadius: 1,
              }}
            >
              <Box
                style={{
                  flex: 1,
                  borderRadius: 8,
                  minHeight: 400,
                }}
              >

                {selectedMotorSystem ? (
                  <Formik
                    initialValues={{
                      firstName: "",
                      lastName: "",
                      email: "",
                    }}
                    onSubmit={async (values) => {
                      console.log("values", values);
                    }}
                  >
                    <Box>

                      <Grid
                        container
                        item
                        spacing={2}

                      >
                        {/* {selectedMotorSystem ===
                            "Muscle Bulk" && (
                              <Grid item xs={8} sm={4} md={12}>
                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox
                                      onChange={(e) => {
                                        // setSurgeryDoneInPast(!surgeryDoneInPast);
                                        console.log(
                                          "checkbox data:",
                                          e.target.value
                                        );
                                      }}
                                    // value={item.symptom}
                                    />}
                                    label="Muscle Bulk"
                                  />
                                </FormGroup>
                              </Grid>
                            )} */}
                        {selectedMotorSystem ===
                          "Muscle Bulk" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Muscle Bulk
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                        {selectedMotorSystem === "Muscle Bulk" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedMotorSystem === "Muscle power Grade" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Muscle power Grade"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedMotorSystem === "Muscle power Grade" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Muscle power Grade
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedMotorSystem === "Muscle power Grade" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedMotorSystem === "Muscle tone" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Muscle tone"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedMotorSystem === "Muscle tone" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Muscle tone
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedMotorSystem === "Muscle tone" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedMotorSystem ===
                            "Co-ordination" && (
                              <Grid item xs={8} sm={4} md={12}>
                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox
                                      onChange={(e) => {
                                        // setSurgeryDoneInPast(!surgeryDoneInPast);
                                        console.log(
                                          "checkbox data:",
                                          e.target.value
                                        );
                                      }}
                                    // value={item.symptom}
                                    />}
                                    label="Co-ordination"
                                  />
                                </FormGroup>
                              </Grid>
                            )} */}
                        {selectedMotorSystem ===
                          "Co-ordination" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Co-ordination
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                        {selectedMotorSystem ===
                          "Co-ordination" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Note
                                </FormLabel>
                                <ReactQuill
                                  className="react-quill-alignment"
                                  theme="snow"
                                  value={""}
                                  onChange={(e) => { }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                        {/* {selectedMotorSystem ===
                            "Finger nose test" && (
                              <Grid item xs={8} sm={4} md={12}>
                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox
                                      onChange={(e) => {
                                        // setSurgeryDoneInPast(!surgeryDoneInPast);
                                        console.log(
                                          "checkbox data:",
                                          e.target.value
                                        );
                                      }}
                                    // value={item.symptom}
                                    />}
                                    label="Finger nose test"
                                  />
                                </FormGroup>
                              </Grid>
                            )} */}
                        {selectedMotorSystem ===
                          "Finger nose test" && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Finger nose test
                                </FormLabel>
                                <AsyncCustomSelect
                                  tabSelectsValue={false}
                                  // openMenuOnFocus={
                                  //   saleId <= 0
                                  // }
                                  isNotCreatable={false}
                                  isInnerWidth={true}
                                  // optionInnerWidth={
                                  //   sourceProductCollection.length <
                                  //     1
                                  //     ? "837px !important"
                                  //     : ""
                                  // }
                                  menuPortalTarget={document.querySelector("body")}
                                  id={"followUpReason"}
                                  isClearable={true}
                                  FieldName={`followUpReason`}
                                  // onFocus={(e) => {
                                  // }}
                                  // options={ }
                                  // defaultOptions={ }
                                  // loadOptions={
                                  //   loadProductOptions
                                  // }
                                  // onCreateOption={(value) => {  }}
                                  // autoFocus={ }
                                  handleChange={(e) => { }}
                                // Value={ }
                                // CustomOptionComponent={  }
                                />
                              </FormControl>
                            </Grid>
                          )}

                        {selectedMotorSystem === "Finger nose test" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedMotorSystem === "Finger test" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Finger test
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedMotorSystem === "Finger test" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedMotorSystem === "Knee-heal test" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Knee-heal test"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedMotorSystem === "Knee-heal test" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Knee-heal test
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedMotorSystem === "Knee-heal test" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedMotorSystem === "Involuntary movements" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Involuntary movements"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedMotorSystem === "Involuntary movements" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Involuntary movements
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedMotorSystem === "Involuntary movements" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Formik>
                ) : (
                  <Box>  <Divider />
                    <Box>This is summary section </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>

        </DialogContent>
      </Dialog>
      <Dialog
        fullScreen
        open={openSensorySystem}
        onClose={handleClickCloseSensorySystem}

      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar
            sx={{ display:"flex",
              justifyContent:"space-between"}}>
            <Typography
              
              style={{ fontWeight: 600 }}
              sx={{
                paddingLeft:{
                  xs:1,
                  sm:0,
                }
              }}
              variant="h6"
            >
              {selectedSensorySystem ? selectedSensorySystem : "Sensory System"}
            </Typography>
            <IconButton onClick={() => handleClickCloseSensorySystem(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
              <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
            </IconButton>
          </Toolbar>
          <Divider />
        </AppBar>
        <DialogContent style={{ height: 400 }}>
          <Grid item xs={12} sm={6} md={6}>
            <Box
              className="template-table-container"
              sx={{

                border: "0px solid #0000001f",
                borderRadius: 1,
                height: 400
              }}
            >
              <Box
                style={{
                  flex: 1,
                  borderRadius: 8,
                  minHeight: 400,
                }}
              >

                {selectedSensorySystem ? (
                  <Formik
                    initialValues={{
                      firstName: "",
                      lastName: "",
                      email: "",
                    }}
                    onSubmit={async (values) => {
                      console.log("values", values);
                    }}
                  >
                    <Box>

                      <Grid
                        container
                        item
                        spacing={2}

                      >
                        {/* {selectedSensorySystem === "Fine touch perception" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Fine touch perception"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedSensorySystem === "Fine touch perception" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Fine touch perception
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedSensorySystem === "Fine touch perception" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedSensorySystem === "Crude touch perception" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Built"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedSensorySystem === "Crude touch perception" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Crude touch perception
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedSensorySystem === "Crude touch perception" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedSensorySystem === "Pain perception" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Built"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedSensorySystem === "Pain perception" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Pain perception
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedSensorySystem === "Pain perception" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedSensorySystem === "Temperature perception" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Built"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedSensorySystem === "Temperature perception" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Temperature perception
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedSensorySystem === "Temperature perception" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}

                        {/* {selectedSensorySystem === "Joint sense" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Joint sense"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedSensorySystem === "Joint sense" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Joint sense
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedSensorySystem === "Joint sense" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedSensorySystem === "Tactile localization" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Tactile localization"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedSensorySystem === "Tactile localization" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Tactile localization
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedSensorySystem === "Tactile localization" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedSensorySystem === "Tactile discrimination" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Tactile discrimination"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedSensorySystem === "Tactile discrimination" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Tactile discrimination
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedSensorySystem === "Tactile discrimination" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedSensorySystem === "Tactile extinction" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Tactile extinction"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedSensorySystem === "Tactile extinction" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Tactile extinction
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedSensorySystem === "Tactile extinction" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedSensorySystem === "Cerebellar signs" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Cerebellar signs"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedSensorySystem === "Cerebellar signs" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Cerebellar signs
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedSensorySystem === "Cerebellar signs" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedSensorySystem === "Meningeal signs" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Meningeal signs"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedSensorySystem === "Meningeal signs" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Meningeal signs
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedSensorySystem === "Meningeal signs" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedSensorySystem === "Glabellar Tap" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Glabellar Tap"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedSensorySystem === "Glabellar Tap" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Glabellar Tap
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedSensorySystem === "Glabellar Tap" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedSensorySystem === "Corneal reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Corneal reflex"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedSensorySystem === "Corneal reflex" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Corneal reflex
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedSensorySystem === "Corneal reflex" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedSensorySystem === "Conjuctival reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Conjuctival reflex"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedSensorySystem === "Conjuctival reflex" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Conjuctival reflex
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedSensorySystem === "Conjuctival reflex" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedSensorySystem === "Palatal reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Palatal reflex"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedSensorySystem === "Palatal reflex" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Palatal reflex
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedSensorySystem === "Palatal reflex" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedSensorySystem === "Pharygeal reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Pharygeal reflex"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedSensorySystem === "Pharygeal reflex" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Pharygeal reflex
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedSensorySystem === "Pharygeal reflex" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedSensorySystem === "Abdominal reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Abdominal reflex"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedSensorySystem === "Abdominal reflex" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Abdominal reflex
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedSensorySystem === "Abdominal reflex" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedSensorySystem === "Planter reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Planter reflex"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedSensorySystem === "Planter reflex" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Planter reflex
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedSensorySystem === "Planter reflex" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedSensorySystem === "Jaw jerk" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Jaw jerk"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedSensorySystem === "Jaw jerk" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Jaw jerk
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedSensorySystem === "Jaw jerk" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedSensorySystem === "Biceps jerk" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Biceps jerk"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedSensorySystem === "Biceps jerk" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Biceps jerk
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedSensorySystem === "Biceps jerk" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedSensorySystem === "Tricips jerk" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Tricips jerk"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedSensorySystem === "Tricips jerk" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Tricips jerk
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedSensorySystem === "Tricips jerk" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedSensorySystem === "Knee reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Knee reflex"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedSensorySystem === "Knee reflex" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Knee reflex
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedSensorySystem === "Knee reflex" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedSensorySystem === "Ankle reflex" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Ankle reflex"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedSensorySystem === "Ankle reflex" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Ankle reflex
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedSensorySystem === "Ankle reflex" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Formik>
                ) : (
                  <Box>  <Divider />
                    <Box>This is summary section </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        fullScreen
        open={openCranialNerves}
        onClose={handleClickCloseCranialNerves}

      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar
            sx={{ display:"flex",
              justifyContent:"space-between"}}>
            <Typography
              
              style={{ fontWeight: 600 }}
              sx={{
                paddingLeft:{
                  xs:1,
                  sm:0,
                }
              }}
              variant="h6"
            >
              {selectedCranialNerves ? selectedCranialNerves : "Cranial Nerves"}
            </Typography>
            <IconButton onClick={() => handleClickCloseCranialNerves(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
              <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
            </IconButton>
          </Toolbar>
          <Divider />
        </AppBar>
        <DialogContent style={{ height: 400 }}>
          <Grid item xs={12} sm={6} md={6}>
            <Box
              className="template-table-container"
              sx={{
                border: "0px solid #0000001f",
                borderRadius: 1,
              }}
            >
              <Box
                style={{
                  flex: 1,
                  borderRadius: 8,
                  minHeight: 400,
                }}
              >

                {selectedCranialNerves ? (
                  <Formik
                    initialValues={{
                      firstName: "",
                      lastName: "",
                      email: "",
                    }}
                    onSubmit={async (values) => {
                      console.log("values", values);
                    }}
                  >
                    <Box>

                      <Grid
                        container
                        item
                        spacing={2}

                      >
                        {/* {selectedCranialNerves === "Cranial nerve 1" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Cranial nerve 1"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedCranialNerves === "Cranial nerve 1" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Cranial nerve 1
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedCranialNerves === "Cranial nerve 1" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedCranialNerves === "Cranial nerve 2" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Cranial nerve 2"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedCranialNerves === "Cranial nerve 2" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Cranial nerve 2
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedCranialNerves === "Cranial nerve 2" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedCranialNerves === "Cranial nerve 3" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Cranial nerve 3"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedCranialNerves === "Cranial nerve 3" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Cranial nerve 3
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedCranialNerves === "Cranial nerve 3" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedCranialNerves === "Cranial nerve 4" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Cranial nerve 4"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedCranialNerves === "Cranial nerve 4" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Cranial nerve 4
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedCranialNerves === "Cranial nerve 4" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedCranialNerves === "Cranial nerve 5" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Cranial nerve 5"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedCranialNerves === "Cranial nerve 5" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Cranial nerve 5
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedCranialNerves === "Cranial nerve 5" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedCranialNerves === "Cranial nerve 6" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Cranial nerve 6"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedCranialNerves === "Cranial nerve 6" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Cranial nerve 6
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedCranialNerves === "Cranial nerve 6" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedCranialNerves === "Cranial nerve 7" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Cranial nerve 7"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedCranialNerves === "Cranial nerve 7" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Cranial nerve 7
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedCranialNerves === "Cranial nerve 7" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedCranialNerves === "Cranial nerve 8" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Cranial nerve 8
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedCranialNerves === "Cranial nerve 8" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedCranialNerves === "Cranial nerve 9" && (
                          <Grid item xs={8} sm={4} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Cranial nerve 9
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedCranialNerves === "Cranial nerve 9" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedCranialNerves === "Cranial nerve 10" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Cranial nerve 10
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedCranialNerves === "Cranial nerve 10" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {/* {selectedCranialNerves === "Cranial nerve 11" && (
                            <Grid item xs={8} sm={4} md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      // setSurgeryDoneInPast(!surgeryDoneInPast);
                                      console.log(
                                        "checkbox data:",
                                        e.target.value
                                      );
                                    }}
                                  // value={item.symptom}
                                  />}
                                  label="Cranial nerve 10"
                                />
                              </FormGroup>
                            </Grid>
                          )} */}
                        {selectedCranialNerves === "Cranial nerve 11" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Cranial nerve 11
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedCranialNerves === "Cranial nerve 11" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedCranialNerves === "Cranial nerve 12" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Cranial nerve 12
                              </FormLabel>
                              <AsyncCustomSelect
                                tabSelectsValue={false}
                                // openMenuOnFocus={
                                //   saleId <= 0
                                // }
                                isNotCreatable={false}
                                isInnerWidth={true}
                                // optionInnerWidth={
                                //   sourceProductCollection.length <
                                //     1
                                //     ? "837px !important"
                                //     : ""
                                // }
                                menuPortalTarget={document.querySelector("body")}
                                id={"followUpReason"}
                                isClearable={true}
                                FieldName={`followUpReason`}
                                // onFocus={(e) => {
                                // }}
                                // options={ }
                                // defaultOptions={ }
                                // loadOptions={
                                //   loadProductOptions
                                // }
                                // onCreateOption={(value) => {  }}
                                // autoFocus={ }
                                handleChange={(e) => { }}
                              // Value={ }
                              // CustomOptionComponent={  }
                              />
                            </FormControl>
                          </Grid>
                        )}
                        {selectedCranialNerves === "Cranial nerve 12" && (
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Note
                              </FormLabel>
                              <ReactQuill
                                className="react-quill-alignment"
                                theme="snow"
                                value={""}
                                onChange={(e) => { }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Formik>
                ) : (
                  <Box>  <Divider />
                    <Box>This is summary section </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        fullScreen
        open={openHigherFunctionsSummary}
        onClose={handleClickCloseHigherFunctionsSummary}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography
              // className={selected ? "symptom-text" : ""}
              style={{ fontWeight: 600, marginLeft: 0 }}
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
            >
              Higher Functions Summary
            </Typography>
            <IconButton onClick={() => handleClickCloseHigherFunctionsSummary(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
              <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Divider />
        <DialogContent style={{ height: 400 }}>
        <Box className="template-table-container summary-dialog-mainContainer">
                      <Box className="summary-dialog-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
            </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        fullScreen
        open={openMotorSystemSummary}
        onClose={handleClickCloseMotorSystemSummary}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography
              // className={selected ? "symptom-text" : ""}
              style={{ fontWeight: 600, marginLeft: 0 }}
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
            >
              Motor System Summary
            </Typography>
            <IconButton onClick={() => handleClickCloseMotorSystemSummary(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
              <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Divider />
        <DialogContent style={{ height: 400 }}>
        <Box className="template-table-container summary-dialog-mainContainer">
                      <Box className="summary-dialog-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
            </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        fullScreen
        open={openSensorySystemSummary}
        onClose={handleClickCloseSensorySystemSummary}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography
              // className={selected ? "symptom-text" : ""}
              style={{ fontWeight: 600, marginLeft: 0 }}
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
            >
              Sensory System Summary
            </Typography>
            <IconButton onClick={() => handleClickCloseSensorySystemSummary(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
              <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Divider />
        <DialogContent style={{ height: 400 }}>
        <Box className="template-table-container summary-dialog-mainContainer">
                      <Box className="summary-dialog-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
            </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        fullScreen
        open={openCranialNervesSummary}
        onClose={handleClickCloseCranialNervesSummary}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography
              // className={selected ? "symptom-text" : ""}
              style={{ fontWeight: 600, marginLeft: 0 }}
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
            >
              Cranial Nerves Summary
            </Typography>
            <IconButton onClick={() => handleClickCloseCranialNervesSummary(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
              <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Divider />
        <DialogContent style={{ height: 400 }}>
        <Box className="template-table-container summary-dialog-mainContainer">
                      <Box className="summary-dialog-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
            </Box>
        </DialogContent>
      </Dialog>

    </Box>
  );
}
