import React, { useState } from 'react'
import {
  Box, Grid, IconButton, InputAdornment, TextField, Button, styled, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
  Popover,
  Pagination,
  Stack,
  Typography,
  Alert,
  MenuItem,
  Divider,
  Menu,
  MenuProps,
  alpha,
} from '@mui/material'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate } from 'react-router-dom';
import '../../../theme/style.scss';
import BreadcrumbsComponent from '../../../components/BreadcrumbsComponent/BreadcrumbsComponent'
import { DateRange } from '@mui/icons-material';
import DateRangeIcon from "@mui/icons-material/DateRange";
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { format } from "date-fns";
import CustomBreadcrumbs from '../../../components/BreadcrumbsComponent/CustomBreadcrumbs';

// import ClearIcon from "@mui/icons-material/Clear";
export default function OPDBilling() {
  const [selectedDateRange, setDateRange] = useState<any>([{}]);
  const [isCustomDate, setIsCustomDate] = React.useState(false);
  const [dateHeading, setDateHeading] = React.useState<any>("Last 30 days");
  const [selectedIndex, setSelectedIndex] = React.useState(3);
  const popupState = usePopupState({ variant: "popover", popupId: "demoMenu" });


  const navigate = useNavigate()
  const [anchorEl, setAnchorEl3] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const CustomTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: '#FAFBFD', // Set the desired background color
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.grey[300],
        borderWidth: '1px',
        BorderStyle: 'solid'
      },
      '&:hover fieldset': {
        borderColor: theme.palette.grey[500],
        borderWidth: '1px',
        BorderStyle: 'solid'
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: "2px",
      },
    },
  }));

  // for Edit & Delet pop 
  const handleOpenMore = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl3(event.currentTarget);
    // setBuyerId(id);
  };
  const handleCloseMore = () => {
    setAnchorEl3(null);
  };
  //  Table Header 
  const handleListItemClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    setSelectedIndex(index);
    switch (
    index
    // case 0:
    //   setDateRange([{
    //     startDate: (userContext.financialYear !== getCurrentFinancialYear() ? new Date(userContext.financialYearEndDate) : new Date()),
    //     endDate: (userContext.financialYear !== getCurrentFinancialYear() ? new Date(userContext.financialYearEndDate) : new Date()),
    //     key: "selection",
    //   },])
    //   setDateHeading("Today")
    //   break;
    // case 1:
    //   setDateRange([{
    //     startDate: (userContext.financialYear !== getCurrentFinancialYear() ? new Date(userContext.financialYearEndDate).getTime() - (1 * 24 * 60 * 60 * 1000) : new Date().getTime() - (1 * 24 * 60 * 60 * 1000)),
    //     endDate: (userContext.financialYear !== getCurrentFinancialYear() ? new Date(userContext.financialYearEndDate).getTime() - (1 * 24 * 60 * 60 * 1000) : new Date().getTime() - (1 * 24 * 60 * 60 * 1000)),
    //     key: "selection",
    //   },])
    //   setDateHeading("Yestarday")
    //   break;
    // case 2:
    //   setDateRange([{
    //     startDate: (userContext.financialYear !== getCurrentFinancialYear() ? new Date(userContext.financialYearEndDate).getTime() - (14 * 24 * 60 * 60 * 1000) : new Date().getTime() - (14 * 24 * 60 * 60 * 1000)),
    //     endDate: (userContext.financialYear !== getCurrentFinancialYear() ? new Date(userContext.financialYearEndDate) : new Date()),
    //     key: "selection",
    //   },])
    //   setDateHeading("Last 15 days")
    //   break;
    // case 3:
    //   setDateRange([{
    //     startDate: (userContext.financialYear !== getCurrentFinancialYear() ? new Date(userContext.financialYearEndDate).getTime() - (29 * 24 * 60 * 60 * 1000) : new Date().getTime() - (29 * 24 * 60 * 60 * 1000)),
    //     endDate: (userContext.financialYear !== getCurrentFinancialYear() ? new Date(userContext.financialYearEndDate) : new Date()),
    //     key: "selection",
    //   },])
    //   setDateHeading("Last 30 days")
    //   break;
    // case 4:
    //   setDateRange([{
    //     startDate: (userContext.financialYear !== getCurrentFinancialYear() ? new Date(userContext.financialYearEndDate).getTime() - (89 * 24 * 60 * 60 * 1000) : new Date().getTime() - (89 * 24 * 60 * 60 * 1000)),
    //     endDate: (userContext.financialYear !== getCurrentFinancialYear() ? new Date(userContext.financialYearEndDate) : new Date()),
    //     key: "selection",
    //   },])
    //   setDateHeading("Last 90 days")
    //   break;
    // case 5:
    //   setDateRange([{
    //     startDate: new Date(userContext.financialYearStartDate),
    //     endDate: new Date(userContext.financialYearEndDate),
    //     key: "selection",
    //   },])
    //   setDateHeading("Current financial year")
    //   break;
    // case 6:
    //   // setDateHeading(`${formatDate(new Date(selectedDateRange[0].startDate))} To ${formatDate(new Date(selectedDateRange[0].endDate))}`)
    //   setIsCustomDate(true);
    //   break;
    // default:
    //   break;
    ) {
    }
  };
  function EnhancedTableHead(props: EnhancedTableProps) {
    return (
      <TableHead className='table-header-color'>
        <TableRow>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "20%"
            }}
            align="left"
          >
            Date
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "20%"
            }}
            align="left"
          >
            Patient Name
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "none",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "20%"
            }}
            align="left"
          >
            Department
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "20%"
            }}
            align="right"
          >
            Amount
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  const rowData = [
    {
      date: '2024-07-10',
      patientName: 'John Doe',
      department: 'General Medicine',
      amount: 100.00,
    },
    {
      date: '2024-07-11',
      patientName: 'Jane Smith',
      department: 'Pediatrics',
      amount: 120.00,
    },
    {
      date: '2024-07-12',
      patientName: 'Samuel Green',
      department: 'Dermatology',
      amount: 150.00,
    },
    {
      date: '2024-07-13',
      patientName: 'Lucy Brown',
      department: 'Cardiology',
      amount: 200.00,
    },
  ];


  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: "10px !important",
      marginTop: theme.spacing(1),
      minWidth: 250,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px !important",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  const handleDateRange = (selected: any, popupState) => {
    // Your logic here
    const calenderHeading =
      format(new Date(selected[0].startDate), "dd-MM-yyyy") +
      " To " +
      format(new Date(selected[0].endDate), "dd-MM-yyyy");
    setDateRange(selected);
    setDateHeading(calenderHeading);
    popupState.close();
  };
  return (
    <>
      <Box className='main-container'>
        <Grid xs={12} container className='page-header'>
          <Grid xs={8} sm={6} item className="page-breadcrumbs">
            <CustomBreadcrumbs />
          </Grid>
          <Grid xs={4} sm={6} item className='page-header-icon'>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <span style={{ fontSize: '12px', marginBottom: '8px' }}>Total Amount</span>
              <Alert
                icon={false}
                sx={{
                  paddingRight: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '16px !important',
                  paddingX: "32px !important",
                  paddingY: "6px !important",
                  fontWeight: "bold",
                  border: "none !important",
                }}
                className="badge badge-success">
                {570}
              </Alert>
            </div>
            <Button
              color="primary"
              variant="contained"
              onClick={() => navigate('opd-billing-save')}
              size="medium"
              startIcon={<AddIcon />}
              style={{ marginTop: '25px', marginLeft: '16px' }} 
            >
              Add
            </Button>
          </Grid>
        </Grid>

        <Grid container className="list-container" sx={{ padding: '12px' }}>
          <Grid item xs={12} sm={4} md={2} lg={2} className='search-bar'   >
            <CustomTextField
              placeholder="Search..."
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              sx={{ paddingLeft: '0px' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" >
                    <IconButton onClick={() => searchValue("")} >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={2}
            lg={2}
           
          >
            <div className='days-filter' style={{ display: "flex", alignItems: 'center' }}>
              <Button
                className="button-responsive-calender"
                variant="outlined"
                fullWidth
                endIcon={<DateRangeIcon />}
                // style={{ paddingBottom: 5 }}
                {...bindTrigger(popupState)}
              >
                {dateHeading.toString()}
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                {...bindMenu(popupState)}
              >
                {!isCustomDate && (
                  <>
                    <Grid className="dashboard-date-label">
                      <Typography className="typography-first-child">
                        {dateHeading.toString()}
                      </Typography>
                    </Grid>
                    <Divider sx={{ margin: "0px !important" }} />
                    <MenuItem
                      sx={{ padding: "0px !important" }}
                      onClick={popupState.close}
                    >
                      <ListItemButton
                        selected={selectedIndex === 0}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: "#2e67a3",
                            color: "#ffffff",
                          },
                          "&.Mui-focusVisible": {
                            backgroundColor: "#f1f6fe !important",
                          },
                          ":hover": {
                            backgroundColor: "#f1f6fe",
                          },
                        }}
                        onClick={(event: any) => handleListItemClick(event, 0)}
                      >
                        <span>Today</span>
                      </ListItemButton>
                    </MenuItem>
                    <MenuItem
                      sx={{ padding: "0px !important" }}
                      onClick={popupState.close}
                    >
                      <ListItemButton
                        selected={selectedIndex === 1}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: "#2e67a3",
                            color: "#ffffff",
                          },
                          "&.Mui-focusVisible": {
                            backgroundColor: "#f1f6fe !important",
                          },
                          ":hover": {
                            backgroundColor: "#f1f6fe",
                          },
                        }}
                        onClick={(event: any) => handleListItemClick(event, 1)}
                      >
                        <span>Yestarday</span>
                      </ListItemButton>
                    </MenuItem>
                    <MenuItem
                      sx={{ padding: "0px !important" }}
                      onClick={popupState.close}
                    >
                      <ListItemButton
                        selected={selectedIndex === 2}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: "#2e67a3",
                            color: "#ffffff",
                          },
                          "&.Mui-focusVisible": {
                            backgroundColor: "#f1f6fe !important",
                          },
                          ":hover": {
                            backgroundColor: "#f1f6fe",
                          },
                        }}
                        onClick={(event: any) => handleListItemClick(event, 2)}
                      >
                        <span>Last 15 days</span>
                      </ListItemButton>
                    </MenuItem>
                    <MenuItem
                      sx={{ padding: "0px !important" }}
                      onClick={popupState.close}
                    >
                      <ListItemButton
                        selected={selectedIndex === 3}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: "#2e67a3",
                            color: "#ffffff",
                          },
                          "&.Mui-focusVisible": {
                            backgroundColor: "#f1f6fe !important",
                          },
                          ":hover": {
                            backgroundColor: "#f1f6fe",
                          },
                        }}
                        onClick={(event: any) => handleListItemClick(event, 3)}
                      >
                        <span>Last 30 days</span>
                      </ListItemButton>
                    </MenuItem>

                    <MenuItem
                      sx={{ padding: "0px !important" }}
                      onClick={popupState.close}
                    >
                      <ListItemButton
                        selected={selectedIndex === 4}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: "#2e67a3",
                            color: "#ffffff",
                          },
                          "&.Mui-focusVisible": {
                            backgroundColor: "#f1f6fe !important",
                          },
                          ":hover": {
                            backgroundColor: "#f1f6fe",
                          },
                        }}
                        onClick={(event: any) => handleListItemClick(event, 4)}
                      >
                        <span>Last 90 days</span>
                      </ListItemButton>
                    </MenuItem>

                    <MenuItem
                      sx={{ padding: "0px !important" }}
                      onClick={popupState.close}
                    >
                      <ListItemButton
                        selected={selectedIndex === 5}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: "#2e67a3",
                            color: "#ffffff",
                          },
                          "&.Mui-focusVisible": {
                            backgroundColor: "#f1f6fe !important",
                          },
                          ":hover": {
                            backgroundColor: "#f1f6fe",
                          },
                        }}
                        onClick={(event: any) => handleListItemClick(event, 5)}
                      >
                        <span>Current financial year</span>
                      </ListItemButton>
                    </MenuItem>
                    <Divider sx={{ margin: "0px !important" }} />
                    <MenuItem sx={{ padding: "0px !important" }}>
                      <ListItemButton
                        selected={selectedIndex === 6}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: "#2e67a3",
                            color: "#ffffff",
                          },
                          "&.Mui-focusVisible": {
                            backgroundColor: "#f1f6fe !important",
                          },
                          ":hover": {
                            backgroundColor: "#f1f6fe",
                          },
                        }}
                        onClick={(event: any) => handleListItemClick(event, 6)}
                      >
                        <span>Custom...</span>
                      </ListItemButton>
                    </MenuItem>
                  </>
                )}
                {isCustomDate && (
                  <DateRange
                    editableDateInputs={true}
                    onChange={
                      (item) => handleDateRange([item.selection], popupState)
                      // console.log(item)
                    }
                    // minDate={new Date(userContext.financialYearStartDate)}
                    // maxDate={new Date(userContext.financialYearEndDate)}
                    moveRangeOnFirstSelection={false}
                    ranges={selectedDateRange}
                  />
                )}
              </StyledMenu>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} md={6} lg={2} className='search-bar'  >
            <CustomTextField
              placeholder="Department"
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              sx={{ paddingLeft: '0px' }}
            // InputProps={{
            //   startAdornment: (
            //     <InputAdornment position="start" >
            //       <IconButton onClick={() => searchValue("")} >
            //         <SearchIcon />
            //       </IconButton>
            //     </InputAdornment>
            //   ),
            // }}
            />
          </Grid>
          <Grid item xs={0} sm={6} md={4} lg={4} />
          <Grid item xs={12} sm={6} md={4} lg={2} className='search-bar pdf-icon' style={{ paddingTop: '10px' }}>
            <img src="./xls.png" alt="" className='pdf-icon-hover' />
            <img src="./pdf.png" alt="" className='pdf-icon-hover' />
          </Grid>

          <TableContainer className='table'>
            <Table>
              <EnhancedTableHead />
              <TableBody>
                {rowData.map((item, i) => {
                  return (
                    <TableRow>
                      <TableCell>
                        {item.date}
                      </TableCell>
                      <TableCell>
                        {item.patientName}
                      </TableCell>
                      <TableCell
                        sx={{
                          display: {
                            xs: "none",
                            sm: "table-cell",
                            md: "table-cell",
                            lg: "table-cell",
                          }
                        }}
                      >{item.department}</TableCell>
                      <TableCell align="right">{item.amount}</TableCell>
                    </TableRow>
                  )
                })}
                <TableRow>
                  <TableCell
                    sx={{
                      display: {
                        xs: "none",
                        sm: "table-cell",
                        md: "table-cell",
                        lg: "table-cell",
                      }
                    }}
                  />
                  <TableCell />
                  <TableCell align="right" sx={{ fontWeight: 600 }}>Total Amount:</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 600 }}> {570}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Grid
            container
            paddingTop={1}
            className='pagination'
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid item xs={12} sm={6}
              className="pagination-label"
            >
              1 of 1 {/* {pageStartIndex} - {pageEndIndex} of {totalCount} */}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack
                component="div"
                alignItems={"end"}
                justifyContent={"center"}
              >
                <Pagination
                  color="primary"
                // count={counData}
                // page={page_Index}
                // onChange={handleChangePageNew}
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Popover
        elevation={1}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseMore}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('/billing-item-master-save')}>
              <ListItemText primary="Edit" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              className="delete-container"
            >
              Delete
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </>
  )
}
