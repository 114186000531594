import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import React from 'react'
import Title from '../../components/Title';
import './Settings.scss'
import BusinessIcon from "@mui/icons-material/Business";
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import { useNavigate } from 'react-router-dom';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
export default function Settings() {
    const navigate = useNavigate()
    return (
        <>
            <Box className="main-container">

                <Grid xs={12} container className='page-header'>
                    <Grid xs={6} sm={6} item><Title pageTitle='Settings' /></Grid>
                </Grid>
                {/* <Title pageTitle="Settings" /> */}
                <Grid spacing={2} container >
                    <Grid item xs={12} md={6} xl={6} sx={{ padding: '5px' }} >
                        <Grid className="setting-container">
                            <Box className='setting-page-mainCard ' onClick={() => navigate('/settings/organization')}>
                                <Card className="setting-page-card">
                                    <CardContent>
                                        <Grid className="setting-page-card-box">
                                            <Grid className='card-icon'>
                                                <span className="menu-icon-span" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <BusinessIcon
                                                        className="menu-icon"
                                                        color="primary" />
                                                </span>
                                            </Grid>

                                            <Grid pl={2} >
                                                <Typography variant="h6" className="setting-page-card-amount card-discription-h3" >
                                                    <span className="card-discription-h3-span">
                                                        Oraganization
                                                    </span>
                                                </Typography>
                                                <Typography variant="h4" className="setting-page-card-title">
                                                    Manage your Firms, GST Number, PAN Number, Logo, Signature, Stamp and other important information.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={6} xl={6} sx={{ padding: '5px' }}>
                        <Grid className="setting-container">
                            <Box className='setting-page-mainCard' onClick={() => navigate('/settings/branch')} >
                                <Card className="setting-page-card">
                                    <CardContent>
                                        <Grid className="setting-page-card-box">
                                            <Grid className='card-icon'>
                                                <span className="menu-icon-span" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <AccountTreeOutlinedIcon
                                                        className="menu-icon"
                                                        color="primary" />
                                                </span>
                                            </Grid>

                                            <Grid pl={2} >
                                                <Typography variant="h6" className="setting-page-card-amount card-discription-h3" >
                                                    <span className="card-discription-h3-span">
                                                        Branch
                                                    </span>
                                                </Typography>
                                                <Typography variant="h4" className="setting-page-card-title">
                                                    Manage your Branches, Jurisdiction, Bank, Invoice Terms & Conditions, and other important information
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={6} xl={6} sx={{ padding: '5px' }}>
                        <Grid className="setting-container">
                            <Box className='setting-page-mainCard' onClick={() => navigate('/settings/department')}>
                                <Card className="setting-page-card">
                                    <CardContent>
                                        <Grid className="setting-page-card-box">
                                            <Grid className='card-icon'>
                                                <span className="menu-icon-span" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <WidgetsOutlinedIcon
                                                        className="menu-icon"
                                                        color="primary" />
                                                </span>
                                            </Grid>

                                            <Grid pl={2} >
                                                <Typography variant="h6" className="setting-page-card-amount card-discription-h3" >
                                                    <span className="card-discription-h3-span">
                                                        Department
                                                    </span>
                                                </Typography>
                                                <Typography variant="h4" className="setting-page-card-title">
                                                    Manage your Firms, GST Number, PAN Number, Logo, Signature, Stamp and other important information.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} xl={6} sx={{ padding: '5px' }}>
                        <Grid className="setting-container">
                            <Box className='setting-page-mainCard' onClick={() => navigate('/settings/speciality')}>
                                <Card className="setting-page-card">
                                    <CardContent>
                                        <Grid className="setting-page-card-box">
                                            <Grid className='card-icon'>
                                                <span className="menu-icon-span" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <WidgetsOutlinedIcon
                                                        className="menu-icon"
                                                        color="primary" />
                                                </span>
                                            </Grid>

                                            <Grid pl={2} >
                                                <Typography variant="h6" className="setting-page-card-amount card-discription-h3" >
                                                    <span className="card-discription-h3-span">
                                                        Speciality
                                                    </span>
                                                </Typography>
                                                <Typography variant="h4" className="setting-page-card-title">
                                                    Manage your Firms, GST Number, PAN Number, Logo, Signature, Stamp and other important information.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}
