import "../../../../../theme/style.scss";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  styled,
  List,
  ListItem
} from "@mui/material";
import React, { useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { reorder } from "../../../../../Helpers/helpers";
import ChiefComplaintsDraggableTable from "./ChiefComplaintsDraggable/ChiefComplaintsDraggableTable";
import { CustomSelect } from "../../../../../components/CustomFields/CustomSelect";
import { Formik } from "formik";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ReactQuill from "react-quill";
import { MultiUpload } from "../../../../../components/MultiUpload/MultiUpload";
import { useTheme, Theme } from "@mui/material/styles";
import useMediaQuery from '@mui/material/useMediaQuery'
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import SubjectRoundedIcon from "@mui/icons-material/SubjectRounded";
import EditIcon from '@mui/icons-material/Edit';

const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#FAFBFD",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));

const initialData = [
  {
    id: 1,
    agravatedBy: "One",
  },
];
const initialRelievedData = [
  {
    id: 1,
    relievedBy: "One",
  },
];

const  durationState = [

  { value: 'days', label: 'Days' },
  { value: 'weeks', label: 'Weeks' },
  { value: 'months', label: 'Months' },
  { value: 'year', label: 'Year' },
]

export default function ChiefComplaints() {
  const [showNote, setShowNote] = useState(false);
  const [showNoteNew, setShowNoteNew] = useState(false);
  const [addAgravated, setAddAgravated] = useState(initialData);
  const [addRelieved, setAddRelieved] = useState(initialRelievedData);
  const [selected, setSelected] = useState("");
  const [documentsFiles, setDocumentsFile] = useState<any>([]);
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // const matches = useMediaQuery("(max-width:1024px)");
  const smallScreen = useMediaQuery("(max-width:768px)");
  const mobileScreen = useMediaQuery("(max-width:425px)");

  //Draggable Table
  const [items, setItems] = React.useState([
    {
      id: "Item 1",
      symptom: "Fever",
    },
    {
      id: "Item 2",
      symptom: "Cough",
    },
    {
      id: "Item 3",
      symptom: "Headache",
    },
    {
      id: "Item 4",
      symptom: "Fatigue",
    },
    {
      id: "Item 5",
      symptom: "Sore Throat",
    },
  ]);

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(items, source.index, destination.index);

    setItems(newItems);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (value: boolean) => {
    if (smallScreen || mobileScreen) {
      setOpen(value);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addRowAgravated = () => {
    const initialData = {
      id: addAgravated.length + 1,
      agravatedBy: " ",
    };
    setAddAgravated([...addAgravated, initialData]);
  };
  const addRowRelieved = () => {
    const initialData = {
      id: addRelieved.length + 1,
      relievedBy: "",
    };
    setAddRelieved([...addRelieved, initialData]);
  };

  const onHandleSelectData = (val) => {
    if (smallScreen || mobileScreen) {

      handleClickOpen(true);
    }
    setSelected(val);
  };

  // const Transition = React.forwardRef(function Transition(props, ref) {
  //   return <Slide direction="up" ref={ref} {...props} />;
  // });

  const saveLogoFileSelected = (files: any) => {
    // setDocumentsFile(files);
  };
  const dowloadDocumentFile = (files) => { };

  const removeFile = (file) => { };

  const [openChiefSummary, setopenChiefSummary] = React.useState(false);

  const handleClickOpenChiefSummary = (value: boolean) => {
    if (mobileScreen) {
      setopenChiefSummary(value);
    }
    else if (smallScreen) {
      setopenChiefSummary(value);
    }
  };

  const handleClickCloseChiefSummary = () => {
    setopenChiefSummary(false);
  };

  return (
    <Box className="main-container">
      <Grid container spacing={2}>
        <Grid item xs={12} mb={2}>
          <Grid container item spacing={2}
          >
              <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
            <Grid item xs={12} sm={12} md={6}
            >
              <Box
                className="template-table-container"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                }}
              >
                <Box className="inner-title-container">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box className="inner-header-container"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography style={{ fontWeight: 600 }}>
                        Chief Complaints
                      </Typography>
                      {mobileScreen || smallScreen ? <Box>
                        <Button onClick={() => handleClickOpenChiefSummary(true)} sx={{ padding: 0, margin: 0, minWidth: "30px" }} aria-label="delete">
                          <SubjectRoundedIcon></SubjectRoundedIcon>
                        </Button>
                      </Box> : ''}
                    </Box>
                  </Paper>
                  <Divider />
                  <List>
                    {items.map((item: any, index: number) => (
                      <ListItem
                        sx={{
                          borderBottom: "1px solid #E9EBEC",
                          cursor: 'pointer'
                        }}
                        className={
                          selected === item.symptom
                            ? "draggable-list-item"
                            : " "
                        }
                      >
                        <Grid container item spacing={2}>
                          <Grid item xs={12} sm={12} md={12}>
                            <Box
                              style={{
                                display: "flex",
                              }}
                              onClick={() => { handleClickOpen(true) }}
                            >
                              <Grid container item>
                                <Grid item xs={12} sm={12} md={12}>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() => {
                                      setSelected(item.symptom)
                                    }}
                                  >
                                    <Box
                                      className={
                                        selected === item.symptom
                                          ? "symptom-text"
                                          : " "
                                      }
                                      sx={{ paddingBottom: '8px', paddingTop: '8px' }}
                                    >
                                      <Typography>{item.symptom}</Typography>
                                    </Box>

                                    {selected === item.symptom && (
                                      <Box
                                        style={{
                                          paddingTop: "8px",
                                          paddingLeft: '8px'
                                        }}
                                      >
                                        <KeyboardDoubleArrowRightRoundedIcon />
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>

                          </Grid>
                        </Grid>
                        <Box>
                          <IconButton>
                            <EditIcon sx={{ color: "rgb(65, 77, 85)" }} />
                          </IconButton>
                        </Box>
                        <Box>
                          <IconButton>
                            <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                          </IconButton>
                        </Box>
                      </ListItem>
                    ))}
                  </List>

                  <Box style={{ padding: 10, paddingRight: 24, paddingLeft: 24 }}>
                    <FormControl fullWidth>
                    <CustomSelect
                          // isNotCreatable={true}
                          id={"type"}
                          FieldName={"type"}
                          options={[]}
                          placeholder="Search..."
                          openMenuOnFocus
                          isClearable
                          isSearchable
                          menuPortalTarget={document.querySelector("body")}
                          handleChange={(e) => {
                            console.log("event:", e)
                          }}
                          Value=""
                        />
                    </FormControl>
                  </Box>
                  <Divider />
                  <Box className="note-container">
                    <Box>
                      {!showNote && (
                        <Button
                          variant="text"
                          style={{ fontSize: "6px" }}
                          onClick={() => {
                            setShowNote(true);
                          }}
                        >
                          <AddIcon className="add-icon" />
                          <span className="add-icon"> Note</span>
                        </Button>
                      )}
                    </Box>

                    {showNote && (
                      <FormControl fullWidth>
                        <Box className="note-container-box">
                          <FormLabel
                            sx={{ paddingLeft: 1 }}
                            className="fileds-label-text"
                          >
                            Note
                          </FormLabel>
                          <Button
                            variant="text"
                            onClick={() => {
                              setShowNote(false);
                            }}
                          >
                            <RemoveIcon className="remove-note" />
                            <span className="remove-note">Remove Note</span>
                          </Button>
                        </Box>
                        <ReactQuill
                          className="react-quill-alignment"
                          theme="snow"
                          value={""}
                          onChange={(e) => { }}
                        />
                      </FormControl>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            </Formik>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container template-hide-small-screen  template-hide-medium-screen "
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                  minHeight: 400,
                }}
              >
                <Box className="inner-title-container ">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="inner-header-container">
                      <Typography
                        className={selected ? "symptom-text" : ""}
                        style={{ fontWeight: 600 }}
                      >
                        {selected ? selected : "Chief Complaint Summary"}
                      </Typography>
                      {selected !== '' && <Typography>
                       
                        <IconButton onClick={() => setSelected("")} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                          <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                        </IconButton>
                      </Typography>}
                    </Box>
                  </Paper>
                  {selected ?
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      {/* {selected && !showSummary ? 
                       */}
                      <Box>
                        <Divider />
                        <Grid
                          container
                          item
                          spacing={2}
                          style={{ padding: 20 }}
                        >
                          <Grid item xs={4} sm={2} md={2}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Duration
                              </FormLabel>
                              <CustomTextField
                                name="duration"
                                placeholder=""
                                autoCapitalize="none"
                                type="number"
                                fullWidth
                                size="small"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={8} sm={4} md={4}>
                            <FormControl fullWidth >
                            <FormLabel className="fileds-label-text">
                       Unit of duration
                      </FormLabel>
                          <CustomSelect
                          // isNotCreatable={true}
                          id={"duration"}
                          FieldName={"duration"}
                          options={durationState}
                          openMenuOnFocus
                          isClearable
                          isSearchable
                          menuPortalTarget={document.querySelector("body")}
                          handleChange={(e) => {
                            console.log("event:", e)
                          }}
                          Value=""
                        />
                            </FormControl>
                          </Grid>
                          <Grid item xs={4} sm={2} md={2}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Frequency
                              </FormLabel>
                              <CustomTextField
                                name="city"
                                placeholder=""
                                autoCapitalize="none"
                                type="text"
                                fullWidth
                                size="small"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={8} sm={4} md={4}>
                            <FormControl fullWidth >
                            <FormLabel className="fileds-label-text">
                       Unit of frequency
                      </FormLabel>
                      <CustomSelect
                      // isNotCreatable={true}
                      id={"duration"}
                      FieldName={"duration"}
                      options={durationState}
                      openMenuOnFocus
                      isClearable
                      isSearchable
                      menuPortalTarget={document.querySelector("body")}
                      handleChange={(e) => {
                        console.log("event:", e)
                      }}
                      Value=""
                    />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Nature
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"country"}
                                FieldName={"country"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector(
                                  "body"
                                )}
                                handleChange={(e) => {
                                  console.log("event:", e);
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Severity
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"country"}
                                FieldName={"country"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector(
                                  "body"
                                )}
                                handleChange={(e) => {
                                  console.log("event:", e);
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Timing
                              </FormLabel>
                              <CustomSelect
                                // isNotCreatable={true}
                                id={"country"}
                                FieldName={"country"}
                                options={[]}
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector(
                                  "body"
                                )}
                                handleChange={(e) => {
                                  console.log("event:", e);
                                }}
                                Value=""
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={12}>
                            <FormLabel className="fileds-label-text">
                              Agravated by
                            </FormLabel>
                          </Grid>
                          {addAgravated.map((item) => {
                            return (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={12}
                                style={{ paddingTop: 0, paddingBottom: 5 }}
                              >
                                <FormControl fullWidth>
                                  <Box className="text-field-container">
                                  <CustomTextField
                              name="postalCode"
                              placeholder=""
                              autoCapitalize="none"
                              type="text"
                              fullWidth
                              size="small"
                            />
                                    <IconButton>
                                      <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                                    </IconButton>
                                  </Box>
                                </FormControl>
                              </Grid>
                            );
                          })}
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={12}
                            style={{ paddingTop: 0 }}
                          >
                            <Box>
                              <Button
                                variant="text"
                                style={{ fontSize: "6px" }}
                                onClick={() => {
                                  addRowAgravated();
                                }}
                              >
                                <AddIcon className="add-icon" />
                                <span className="add-icon"> Agravated by</span>
                              </Button>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6} md={12}>
                            <FormLabel className="fileds-label-text">
                              Relieved by
                            </FormLabel>
                          </Grid>
                          {addRelieved.map((item) => {
                            return (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={12}
                                style={{ paddingTop: 0, paddingBottom: 5 }}
                              >
                                <FormControl fullWidth>
                                  <Box className="text-field-container">
                                  <CustomTextField
                              name="postalCode"
                              placeholder=""
                              autoCapitalize="none"
                              type="text"
                              fullWidth
                              size="small"
                            />
                                    <IconButton>
                                      <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                                    </IconButton>
                                  </Box>
                                </FormControl>
                              </Grid>
                            );
                          })}
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={12}
                            style={{ paddingTop: 0 }}
                          >
                            <Box  >
                              <Button
                                variant="text"
                                style={{ fontSize: "6px" }}
                                onClick={() => {
                                  addRowRelieved();
                                }}
                              >
                                <AddIcon className="add-icon" />
                                <span className="add-icon"> Relieved by</span>
                              </Button>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={12}
                          // style={{ paddingTop: 0 }}
                          >
                            <MultiUpload
                              onDropandSelect={saveLogoFileSelected}
                              editFile={documentsFiles}
                              MAX_COUNT={5}
                              onFileDownload={dowloadDocumentFile}
                              onFileRemove={(file) => {
                                removeFile(file);
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container item spacing={2}>
                          <Grid item xs={12} sm={6} md={12}>
                            <Divider />
                            <Box className="note-container">
                              <Box>
                                {!showNoteNew && (
                                  <Button
                                    variant="text"
                                    style={{ fontSize: "6px" }}
                                    onClick={() => {
                                      setShowNoteNew(true);
                                    }}
                                  >
                                    <AddIcon className="add-icon" />
                                    <span className="add-icon"> Note</span>
                                  </Button>
                                )}
                              </Box>

                              {showNoteNew && (
                                <FormControl fullWidth>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      alignSelf: "end",
                                      minWidth: "100%",
                                    }}
                                  >
                                    <FormLabel
                                      sx={{ paddingLeft: 1 }}
                                      className="fileds-label-text"
                                    >
                                      Note
                                    </FormLabel>
                                    <Button
                                      variant="text"
                                      onClick={() => {
                                        setShowNoteNew(false);
                                      }}
                                    >
                                      <RemoveIcon className="remove-note" />
                                      <span className="remove-note">
                                        Remove Note
                                      </span>
                                    </Button>
                                  </Box>
                                  <ReactQuill
                                    className="react-quill-alignment"
                                    theme="snow"
                                    value={""}
                                    onChange={(e) => { }}
                                  />
                                </FormControl>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Formik>
                    :
                    <Box>  <Divider />
                      <Box className="summary-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
                    </Box>}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
      >
        <AppBar sx={{
          position: 'relative',

        }}>
          <Toolbar sx={{
            display: "flex",
            justifyContent: "space-between"
          }}>
            <Typography
              // className={selected ? "symptom-text" : ""}
              style={{ fontWeight: 600 }}
              sx={{
                paddingLeft: {
                  xs: 1,
                  sm: 0,
                }
              }}
              variant="h6"
            >
              {selected ? selected : "Chief Complaint"}
            </Typography>
            <IconButton onClick={() => handleClose(true)} aria-label="delete">
              <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
            </IconButton>
          </Toolbar>
          <Divider />
        </AppBar>
        <DialogContent style={{ height: 400, }}>
          <Box
            className="template-table-container"
            sx={{
              border: "0px solid #0000001f",
              borderRadius: 1,
              minHeight: 400,
              overflowY: "auto"
            }}
          >
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
              }}
              onSubmit={async (values) => {
                console.log("values", values);
              }}
            >
              <Box>
                <Grid
                  container
                  item
                  spacing={2}
                >
                  <Grid item xs={12} sm={2} md={2}>
                    <FormControl fullWidth>
                      <FormLabel className="fileds-label-text">
                        Duration
                      </FormLabel>
                      <CustomTextField
                        name="duration"
                        placeholder=""
                        autoCapitalize="none"
                        type="number"
                        fullWidth
                        size="small"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={4} md={4}>
                    <FormControl fullWidth >
                    <FormLabel className="fileds-label-text">
                       Unit of duration
                      </FormLabel>
                     <CustomSelect
                          // isNotCreatable={true}
                          id={"duration"}
                          FieldName={"duration"}
                          options={durationState}
                          openMenuOnFocus
                          isClearable
                          isSearchable
                          menuPortalTarget={document.querySelector("body")}
                          handleChange={(e) => {
                            console.log("event:", e)
                          }}
                          Value=""
                        />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={2} md={2}>
                    <FormControl fullWidth>
                      <FormLabel className="fileds-label-text">
                        Frequency
                      </FormLabel>
                      <CustomTextField
                        name="city"
                        placeholder=""
                        autoCapitalize="none"
                        type="text"
                        fullWidth
                        size="small"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={4} md={4}>
               
                    <FormControl fullWidth>
                    <FormLabel className="fileds-label-text">
                       Unit of frequency
                      </FormLabel>
                      <Select
                                name="city"
                                // options={cityOptions}
                                // value={cityOptions}
                                // onChange={handleSelectChange}
                                size="small"
                                placeholder="Select"
                                style={{
                                  placeholder: (provided) => ({
                                    ...provided,
                                    fontSize: '0.813rem',
                                  }),
                                  control: (base) => ({
                                    ...base,
                                    backgroundColor: '#FAFBFD',
                                    border: '2px solid #cbcbda',
                                    '&:hover': {
                                      border: '2px solid #bdc1cd',
                                    },
                                    boxShadow: 'none',
                                  }),
                                }}
                              >
                                <MenuItem value={'Ahmadabad'}>Days</MenuItem>
                                <MenuItem value={'Ahmadabad'}>Weeks</MenuItem>
                                <MenuItem value={'Ahmadabad'}>Months</MenuItem>
                                <MenuItem value={'Ahmadabad'}>Year</MenuItem>
                              </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel className="fileds-label-text">
                        Nature
                      </FormLabel>
                      <CustomSelect
                        // isNotCreatable={true}
                        id={"country"}
                        FieldName={"country"}
                        options={[]}
                        openMenuOnFocus
                        isClearable
                        isSearchable
                        menuPortalTarget={document.querySelector(
                          "body"
                        )}
                        handleChange={(e) => {
                          console.log("event:", e);
                        }}
                        Value=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel className="fileds-label-text">
                        Severity
                      </FormLabel>
                      <CustomSelect
                        // isNotCreatable={true}
                        id={"country"}
                        FieldName={"country"}
                        options={[]}
                        openMenuOnFocus
                        isClearable
                        isSearchable
                        menuPortalTarget={document.querySelector(
                          "body"
                        )}
                        handleChange={(e) => {
                          console.log("event:", e);
                        }}
                        Value=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel className="fileds-label-text">
                        Timing
                      </FormLabel>
                      <CustomSelect
                        // isNotCreatable={true}
                        id={"country"}
                        FieldName={"country"}
                        options={[]}
                        openMenuOnFocus
                        isClearable
                        isSearchable
                        menuPortalTarget={document.querySelector(
                          "body"
                        )}
                        handleChange={(e) => {
                          console.log("event:", e);
                        }}
                        Value=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <FormLabel className="fileds-label-text">
                      Agravated by
                    </FormLabel>
                  </Grid>
                  {addAgravated.map((item) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        style={{ paddingTop: 0, paddingBottom: 5 }}
                      >
                        <FormControl fullWidth>
                          <Box className="text-field-container">
                            <CustomTextField
                              name="postalCode"
                              placeholder=""
                              autoCapitalize="none"
                              type="text"
                              fullWidth
                              size="small"
                            />
                            <IconButton>
                              <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                            </IconButton>
                          </Box>
                        </FormControl>
                      </Grid>
                    );
                  })}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    style={{ paddingTop: 0 }}
                  >
                    <Box>
                      <Button
                        variant="text"
                        style={{ fontSize: "6px" }}
                        onClick={() => {
                          addRowAgravated();
                        }}
                      >
                        <AddIcon className="add-icon" />
                        <span className="add-icon"> Agravated by</span>
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <FormLabel className="fileds-label-text">
                      Relieved by
                    </FormLabel>
                  </Grid>
                  {addRelieved.map((item) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        style={{ paddingTop: 0, paddingBottom: 5 }}
                      >
                        <FormControl fullWidth>
                          <Box className="text-field-container">
                            <CustomTextField
                              name="postalCode"
                              placeholder=""
                              autoCapitalize="none"
                              type="text"
                              fullWidth
                              size="small"
                            />
                            <IconButton>
                              <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                            </IconButton>
                          </Box>
                        </FormControl>
                      </Grid>
                    );
                  })}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    style={{ paddingTop: 0 }}
                  >
                    <Box  >
                      <Button
                        variant="text"
                        style={{ fontSize: "6px" }}
                        onClick={() => {
                          addRowRelieved();
                        }}
                      >
                        <AddIcon className="add-icon" />
                        <span className="add-icon"> Relieved by</span>
                      </Button>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={12}
                  >
                    <MultiUpload
                      onDropandSelect={saveLogoFileSelected}
                      editFile={documentsFiles}
                      MAX_COUNT={5}
                      onFileDownload={dowloadDocumentFile}
                      onFileRemove={(file) => {
                        removeFile(file);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container item spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box>
                      <Box>
                        {!showNote && (
                          <Button
                            variant="text"
                            style={{ fontSize: "6px" }}
                            onClick={() => {
                              setShowNote(true);
                            }}
                          >
                            <AddIcon className="add-icon" />
                            <span className="add-icon"> Note</span>
                          </Button>
                        )}
                      </Box>

                      {showNote && (
                        <FormControl fullWidth>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              alignSelf: "end",
                              minWidth: "100%",
                            }}
                          >
                            <FormLabel
                              sx={{ paddingLeft: 1 }}
                              className="fileds-label-text"
                            >
                              Note
                            </FormLabel>
                            <Button
                              variant="text"
                              onClick={() => {
                                setShowNote(false);
                              }}
                            >
                              <RemoveIcon className="remove-note" />
                              <span className="remove-note">
                                Remove Note
                              </span>
                            </Button>
                          </Box>
                          <ReactQuill
                            className="react-quill-alignment"
                            theme="snow"
                            value={""}
                            onChange={(e) => { }}
                          />
                        </FormControl>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        fullScreen
        open={openChiefSummary}
        onClose={handleClickCloseChiefSummary}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography
              // className={selected ? "symptom-text" : ""}
              style={{ fontWeight: 600, marginLeft: 0 }}
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
            >
              Chief Complaints Summary
            </Typography>
            <IconButton onClick={() => handleClickCloseChiefSummary(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
              <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Divider />
        <DialogContent>
          <Box className="template-table-container summary-dialog-mainContainer">
                      <Box className="summary-dialog-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
            </Box>
        </DialogContent>
      </Dialog>
    </Box >
  );
}
