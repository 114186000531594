import { Box, Button, FormControl, FormLabel, Grid, styled, TextField, Typography } from '@mui/material'
import React from 'react'
import CustomBreadcrumbs from '../../../../../../components/BreadcrumbsComponent/CustomBreadcrumbs'
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DatePickerCompponent from '../../../../../../components/CustomFields/DatePicker';
import { MultiUpload } from '../../../../../../components/MultiUpload/MultiUpload';


const CustomTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: "#FAFBFD", // Set the desired background color
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: theme.palette.grey[300],
            borderWidth: "1px",
            BorderStyle: "solid",
        },
        "&:hover fieldset": {
            borderColor: theme.palette.grey[500],
            borderWidth: "1px",
            BorderStyle: "solid",
        },
        "&.Mui-focused fieldset": {
            borderColor: theme.palette.primary.main,
            borderWidth: "2px",
        },
    },
}));


const initialAddVaccine = [
    {
        id: 0,
        VaccinationDate: '',
        GenericName: '',
        Brand: '',
        Dose: '',
        Batch: '',
        Upload: ''
    }
]

export default function AddVaccine() {
    const [showVaccine, setshowVaccine] = useState(true);
    const [addAddVaccine, setAddAddVaccine] = useState(initialAddVaccine);
    const [documentsFiles, setDocumentsFile] = useState<any>([]);

    const addRowAddVaccine = () => {
        const initialData = {
            id: addAddVaccine.length + 1,
            VaccinationDate: '',
            GenericName: '',
            Brand: '',
            Dose: '',
            Batch: '',
            Upload: ''
        };
        setAddAddVaccine([...addAddVaccine, initialData]);
    };

    const RemoveRowAddVaccine = (id) => {
        const finalVaccine = addAddVaccine?.filter(x => x.id !== id);
        setAddAddVaccine([...finalVaccine]);
    };

    const saveLogoFileSelected = (files: any) => {
        setDocumentsFile(files);
      };
      const dowloadDocumentFile = (files) => { };
    
      const removeFile = (file) => { };
    return (
        <Box className="main-container" pl={2}>
            <Grid container spacing={2}>
                <Grid item xs={12} mb={3} sx={{ marginTop: 2 }} className="group-container">
                    <Grid container item spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                            <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">Vaccination Date</FormLabel>
                                <DatePickerCompponent
                      format="DD-MM-YYYY"
                      mode="date"
                      slotProps={{ textField: { size: 'small' } }}
                    />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} sx={{display:"flex", justifyContent:"flex-end"}}>
                        {addAddVaccine.length > 1 && 
                                    <Button
                                        variant="text"
                                        onClick={() => {
                                            RemoveRowAddVaccine(1);
                                        }}
                                    >
                                        <RemoveIcon className="remove-note" />
                                        <span className="remove-note" >
                                            Remove Vaccine
                                        </span>
                                    </Button>
                                    }
                                    </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} />
                    <Box sx={{ border: '1px solid lightgray', borderRadius: 2, marginTop: '10px', padding: '10px' }}>
                        <Grid container item spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text">
                                        Generic Name
                                    </FormLabel>
                                    <CustomTextField
                                        name="name"
                                        placeholder="Name"
                                        autoCapitalize="none"
                                        type="text"
                                        fullWidth
                                        size="small"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text">
                                        Brand
                                    </FormLabel>
                                    <CustomTextField
                                        name="name"
                                        placeholder="Name"
                                        autoCapitalize="none"
                                        type="text"
                                        fullWidth
                                        size="small"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text">
                                        Dose
                                    </FormLabel>
                                    <CustomTextField
                                        name="name"
                                        placeholder="Name"
                                        autoCapitalize="none"
                                        type="text"
                                        fullWidth
                                        size="small"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text">
                                        Batch
                                    </FormLabel>
                                    <CustomTextField
                                        name="name"
                                        placeholder="Name"
                                        autoCapitalize="none"
                                        type="text"
                                        fullWidth
                                        size="small"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text">
                                        Upload
                                    </FormLabel>
                                    <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', minWidth: '50%' }}   >
                    <Box>
                        <Button
                            variant="text"
                            style={{ fontSize: "6px" }}
                            onClick={() => { addRowAddVaccine() }}
                        >
                            <AddIcon className="add-icon" />
                            <span className="add-icon"> ADD ANOTHER VACCINE</span>
                        </Button>
                        </Box>
                    </Box>

                </Grid>
                <Box>
                    {addAddVaccine.map((vaccine) => {
                        return (
                            <Grid container spacing={2}>
                                <Grid item xs={12} mb={3} sx={{ marginTop: 2, marginLeft: 2 }} className='group-container'>
                                    <Grid container item spacing={2}>
                                        <Grid item xs={12} sm={6} md={6}>
                                            
                                            <FormControl fullWidth>
                                                <FormLabel className="fileds-label-text">Vaccination Date</FormLabel>
                                                <DatePickerCompponent
                      format="DD-MM-YYYY"
                      mode="date"
                      slotProps={{ textField: { size: 'small' } }}
                    />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} sx={{display:"flex", justifyContent:"flex-end"}}>
                                        <Button
                                           
                                            variant="text"
                                            onClick={() => {
                                                RemoveRowAddVaccine(vaccine.id);
                                            }}
                                        >
                                            <RemoveIcon className="remove-note" />
                                            <span className="remove-note" >
                                                Remove Vaccine
                                            </span>
                                        </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6} />
                                    <Box sx={{ border: '1px solid lightgray', borderRadius: 2, marginTop: '10px', padding: '10px' }}>
                                        <Grid container item spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl fullWidth>
                                                    <FormLabel className="fileds-label-text">
                                                        Generic Name
                                                    </FormLabel>
                                                    <CustomTextField
                                                        name="name"
                                                        placeholder="Name"
                                                        autoCapitalize="none"
                                                        type="text"
                                                        fullWidth
                                                        size="small"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl fullWidth>
                                                    <FormLabel className="fileds-label-text">
                                                        Brand
                                                    </FormLabel>
                                                    <CustomTextField
                                                        name="name"
                                                        placeholder="Name"
                                                        autoCapitalize="none"
                                                        type="text"
                                                        fullWidth
                                                        size="small"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl fullWidth>
                                                    <FormLabel className="fileds-label-text">
                                                        Dose
                                                    </FormLabel>
                                                    <CustomTextField
                                                        name="name"
                                                        placeholder="Name"
                                                        autoCapitalize="none"
                                                        type="text"
                                                        fullWidth
                                                        size="small"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl fullWidth>
                                                    <FormLabel className="fileds-label-text">
                                                        Batch
                                                    </FormLabel>
                                                    <CustomTextField
                                                        name="name"
                                                        placeholder="Name"
                                                        autoCapitalize="none"
                                                        type="text"
                                                        fullWidth
                                                        size="small"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl fullWidth>
                                                    <FormLabel className="fileds-label-text">
                                                        Upload
                                                    </FormLabel>
                                                    <MultiUpload
                                onDropandSelect={saveLogoFileSelected}
                                editFile={documentsFiles}
                                MAX_COUNT={5}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                              />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box>

                                        <Button
                                            variant="text"
                                            style={{ fontSize: "6px" }}
                                            onClick={() => { addRowAddVaccine() }}
                                        >
                                            <AddIcon className="add-icon" />
                                            <span className="add-icon"> ADD ANOTHER VACCINE</span>
                                        </Button>
                                       
                                    </Box>
                                </Grid>
                            </Grid>
                        )
                    })}

                </Box>
            </Grid>
        </Box>
    )
}
