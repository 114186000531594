import { NotificationContext } from "../models/NotificationContext";
import { EncryptDecryptService } from "./EncryptionService";

const STORAGE_KEY = "hpv_1x";

// function isTokenExpired(loginExpiryTime: string) {
//     if (loginExpiryTime === "") {
//         return true;
//     }
//     else {
//         let expiryTime: Date = new Date(loginExpiryTime);
//         let nowDate: Date = new Date(new Date().toUTCString());
//         let pendingSeconds: number = (+expiryTime - +nowDate) / 1000;
//         if (pendingSeconds < 10) {
//             return true;
//         }
//         else {
//             return false;
//         }
//     }

// }

async function storeDataToStorage(credentials: NotificationContext) {
    try {
        //remove existing
        localStorage.removeItem(STORAGE_KEY);

        const edService = new EncryptDecryptService();

        const A = JSON.stringify(credentials)!!.toString();
        localStorage.setItem(STORAGE_KEY, A);

    } catch (error) {
        console.log(error);
    }
}

const readDataFromStorage = () => {
    try {
        let credentials = localStorage.getItem(STORAGE_KEY);
        let storage_value: any = null;
        storage_value = credentials;
        const edService = new EncryptDecryptService();
        const d = credentials
        if (d != null) {
            storage_value = d;
        }
        let context: NotificationContext = (storage_value) ? JSON.parse(storage_value) : null;
        if (context != null) {
            setNotificationStorage(context);
        } else {
            return new NotificationContext();
        }
    } catch (error) {
        console.log(error)
    }
    return new NotificationContext();
}


export const getNotificationStorage = () => {
    try {
        let credentials = localStorage.getItem(STORAGE_KEY);
        let storage_value: any = null;
        storage_value = credentials;
        const edService = new EncryptDecryptService();
        const d = credentials
        if (d != null) {
            storage_value = d;
        }
        let context: any = (storage_value) ? JSON.parse(storage_value) : null;
        return context;

    } catch (error) {
        console.log(error)
    }
}

export function setNotificationStorage(data: any) {
    localStorage.removeItem(STORAGE_KEY);
    const edService = new EncryptDecryptService();

    const A = JSON.stringify(data)!!.toString();
    localStorage.setItem(STORAGE_KEY, A);
}