import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../theme/style.scss";
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    TextField,
    Typography,
    styled,
    Select,
    Radio,
    MenuItem,
    InputAdornment
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Formik } from "formik";
import BreadcrumbsComponent from "../../../components/BreadcrumbsComponent/BreadcrumbsComponent";
import { CustomSelect } from "../../../components/CustomFields/CustomSelect";
import CustomBreadcrumbs from "../../../components/BreadcrumbsComponent/CustomBreadcrumbs";

const CustomTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: "#FAFBFD",
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: theme.palette.grey[300],
            borderWidth: "1px",
            BorderStyle: "solid",
        },
        "&:hover fieldset": {
            borderColor: theme.palette.grey[500],
            borderWidth: "1px",
            BorderStyle: "solid",
        },
        "&.Mui-focused fieldset": {
            borderWidth: "2px",
            borderColor: theme.palette.primary.main,
        },
    },
}));

export default function BillingItemMasterSave() {
    const [countryData, setCountry] = useState([
        { countryID: 0, code: "", name: "" },
        { countryID: 1, code: "123", name: "India" },
        { countryID: 2, code: "456", name: "USA" },
    ]);
    const [discountType, setDiscountType] = useState('%');
    const [discountValue, setDiscountValue] = useState(20);
    const navigate = useNavigate();

    const handleDiscountTypeChange = (event) => {
        setDiscountType(event.target.value);
    };

    const handleDiscountValueChange = (event) => {
        setDiscountValue(event.target.value);
    };

    return (
        <Box className="main-container" pl={2}>
            <Grid item xs={12} container className="page-header-save">
                <Grid xs={6} sm={6} item className="page-breadcrumbs">
                    <CustomBreadcrumbs />
                </Grid>
            </Grid>
            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                }}
                onSubmit={async (values) => {
                    console.log("values", values)
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} mb={3} className="group-container">
                        <Grid container item spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Basic Information</Typography>
                            </Grid>
                            <Grid item xs={12} md={3} sm={6}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text">Code</FormLabel>
                                    <CustomSelect
                                        isNotCreatable={true}
                                        id={"referredBy"}
                                        FieldName={"referredBy"}
                                        // options={colourOptions}
                                        openMenuOnFocus
                                        isClearable
                                        isSearchable
                                        menuPortalTarget={document.querySelector("body")}
                                        
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={9} md={9} />
                            <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text">Integrated Tax (IGST)</FormLabel>
                                    <CustomTextField
                                        name="branchName"
                                        placeholder=""
                                        autoCapitalize="none"
                                        type="text"
                                        fullWidth
                                        size="small"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} />
                            <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text">Central Tax (CGST)</FormLabel>
                                    <CustomTextField
                                        name="branchName"
                                        placeholder=""
                                        autoCapitalize="none"
                                        type="text"
                                        fullWidth
                                        size="small"
                                    />
                                </FormControl>
                            </Grid>                           
                            <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text">State/UT Tax (SGST)</FormLabel>
                                    <CustomTextField
                                        name="branchName"
                                        placeholder=""
                                        autoCapitalize="none"
                                        type="text"
                                        fullWidth
                                        size="small"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text">Cess</FormLabel>
                                    <CustomTextField
                                        name="branchName"
                                        placeholder=""
                                        autoCapitalize="none"
                                        type="text"
                                        fullWidth
                                        size="small"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} />
                            <Grid item xs={12} sm={12} md={12}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text"></FormLabel>
                                    <CustomTextField
                                        name="Description"
                                        placeholder="Description"
                                        autoCapitalize="none"
                                        type="text"
                                        fullWidth
                                        multiline
                                        size="small"
                                        rows={4}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
                            <LoadingButton
                                color="primary"
                                tabIndex={3}
                                loadingPosition="start"
                                startIcon={<></>}
                                variant="contained"
                                type="submit"
                                className="primary-button"
                                name="submit"
                            >
                                Save
                            </LoadingButton>
                            <LoadingButton
                                tabIndex={3}
                                loadingPosition="start"
                                startIcon={<></>}
                                variant="outlined"
                                type="submit"
                                name="submit"
                                className="secondary-button"
                                color="secondary"
                                onClick={() => navigate("/master/billing-item-master")}
                            >
                                Cancel
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </Formik>
        </Box>
    );
}
