import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../theme/style.scss";
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    styled,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Formik } from "formik";
import ReactQuill from "react-quill";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DraggableList from "../../../components/DraggableTable/DraggableList";
import TeamMembersDraggableList from "../../../components/DraggableTable/TeamMembersDraggableList";
import AnesthesiologistDraggableList from "../../../components/DraggableTable/AnesthesiologistDraggableList";
import { DropResult } from 'react-beautiful-dnd';
import { reorder } from "../../../Helpers/helpers";
import { CustomSelect } from "../../../components/CustomFields/CustomSelect";
import DatePickerCompponent from "../../../components/CustomFields/DatePicker";
import CustomBreadcrumbs from "../../../components/BreadcrumbsComponent/CustomBreadcrumbs";

export default function ProcedureTemplateSave() {
    const [isAnesthesiaUsed, setIsAnesthesiaUsed] = useState('no');
    const [showNote, setShowNote] = useState(false);
    const [showNote1, setShowNote1] = useState(false);

    const CustomTextField = styled(TextField)(({ theme }) => ({
        backgroundColor: "#FAFBFD",
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: theme.palette.grey[300],
                borderWidth: "1px",
            },
            "&:hover fieldset": {
                borderColor: theme.palette.grey[500],
                borderWidth: "1px",
            },
            "&.Mui-focused fieldset": {
                borderWidth: "2px",
                borderColor: theme.palette.primary.main,
            },
        },
    }));

    const navigate = useNavigate();
    const [itemsOther, setItemsOther] = useState([
        { id: "Item1 1", primary: "Dr. Smith", secondary: "Cardiologist" },
        { id: "Item1 2", primary: "Dr. Johnson", secondary: "Pediatrician" },
        { id: "Item1 3", primary: "Dr. Garcia", secondary: "Neurologist" },
    ]);
    const [itemsAnesthesiologist, setItemsAnesthesiologist] = useState([
        { id: "Anesthesiologist1", primary: "Dr. Alice Brown", secondary: "Anesthesiologist" },
        { id: "Anesthesiologist2", primary: "Dr. Bob White", secondary: "Anesthesiologist" },
        { id: "Anesthesiologist3", primary: "Dr. Charlie Green", secondary: "Anesthesiologist" },
    ]);

    const onDragEnd1 = ({ destination, source }: DropResult) => {
        if (!destination) return;
        const newItems = reorder(itemsOther, source.index, destination.index);
        setItemsOther(newItems);
    };

    const onDragEnd2 = ({ destination, source }: DropResult) => {
        if (!destination) return;
        const newItems = reorder(itemsAnesthesiologist, source.index, destination.index);
        setItemsAnesthesiologist(newItems);
    };

    const [countryData] = useState([
        { countryID: 0, code: "", name: "" },
        { countryID: 1, code: "123", name: "India" },
        { countryID: 2, code: "456", name: "USA" },
    ]);

    return (
        <Box className="main-container" pl={2}>
            <Grid xs={12} container className="page-header-save">
                <Grid xs={12} sm={12} md={12} item className="page-breadcrumbs">
                    <CustomBreadcrumbs />
                </Grid>
            </Grid>
            <Formik
                initialValues={{
                    firstName: "",
                    lastName: "",
                    email: "",
                }}
                onSubmit={async (values) => {
                    console.log("values", values);
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} mb={3} className="group-container">
                        <Grid container item spacing={2}>
                            <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text">
                                        Template Name
                                    </FormLabel>
                                    <CustomTextField
                                        name="branchName"
                                        placeholder=""
                                        autoCapitalize="none"
                                        type="text"
                                        fullWidth
                                        size="small"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} />
                            <Grid item xs={12} sm={3} md={3}>
                                <div>
                                    <Box style={{ fontSize: 12 }}>Date</Box>
                                    <FormControl sx={{ width: '100% !important' }}>
                                        <DatePickerCompponent
                                            format="DD-MM-YYYY"
                                            mode="date"
                                            slotProps={{ textField: { size: 'small' } }}
                                        />
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text">
                                        Time
                                    </FormLabel>
                                    <CustomTextField
                                        name="branchName"
                                        placeholder=""
                                        autoCapitalize="none"
                                        type="text"
                                        fullWidth
                                        size="small"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} />
                            <Grid item xs={12} sm={12}>
                                <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label" className="fileds-label-text">
                                        Anesthesia used for procedure
                                    </FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={(e) => setIsAnesthesiaUsed(e.target.value)}
                                        value={isAnesthesiaUsed}
                                    >
                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="no" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            {isAnesthesiaUsed === 'yes' && (
                                <>
                                    {/* <Grid item xs={12} sm={3}>
                                        <FormControl fullWidth>
                                            <FormLabel className="fileds-label-text">Type of anesthesia</FormLabel>
                                            <CustomSelect
                                                isNotCreatable
                                                id={"department"}
                                                FieldName={"department"}
                                                options={countryData}
                                                openMenuOnFocus
                                                isClearable
                                                isSearchable
                                                menuPortalTarget={document.querySelector("body")}
                                                handleChange={(e) => console.log("event:", e)}
                                                Value=""
                                            />
                                        </FormControl>
                                    </Grid> */}
                                    <Grid item xs={12} sm={9} />
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Box
                                            className="template-table-container"
                                            style={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                border: '1px solid #0000001f',
                                                borderRadius: 4,
                                            }}
                                        >
                                            <Box style={{ flex: 1, borderRadius: 8 }}>
                                                <Box className="template-box">
                                                    <Typography>Anesthesiologist(s)</Typography>
                                                </Box>
                                                <Divider />
                                                <AnesthesiologistDraggableList
                                                    items={itemsAnesthesiologist}
                                                    onDragEnd={onDragEnd2}
                                                />
                                                <Divider />
                                                <Box className="note-container">
                                                    <Box>
                                                        {!showNote && (
                                                            <Button
                                                                variant="text"
                                                                style={{ fontSize: "6px" }}
                                                                onClick={() => setShowNote(true)}
                                                            >
                                                                <AddIcon className="add-icon" />
                                                                <span className="add-icon">Note</span>
                                                            </Button>
                                                        )}
                                                    </Box>
                                                    {showNote && (
                                                        <FormControl fullWidth>
                                                            <Box className="note-container-box">
                                                                <FormLabel
                                                                    sx={{ paddingLeft: 1 }}
                                                                    className="fileds-label-text"
                                                                >
                                                                    Note
                                                                </FormLabel>
                                                                <Button
                                                                    variant="text"
                                                                    onClick={() => setShowNote(false)}
                                                                >
                                                                    <RemoveIcon className="remove-note" />
                                                                    <span className="remove-note">Remove Note</span>
                                                                </Button>
                                                            </Box>
                                                            <ReactQuill
                                                                className="react-quill-alignment"
                                                                theme="snow"
                                                                value={""}
                                                                onChange={(e) => { }}
                                                            />
                                                        </FormControl>
                                                    )}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </>
                            )}

                            <Grid item xs={12} sm={12} md={isAnesthesiaUsed === 'yes' ? 6 : 12}>
                                <Box
                                    className="template-table-container"
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        border: '1px solid #0000001f',
                                        borderRadius: 4,
                                    }}
                                >
                                    <Box style={{ flex: 1, borderRadius: 8 }}>
                                        <Box className="template-box">
                                            <Typography>Team Members</Typography>
                                        </Box>
                                        <Divider />
                                        <TeamMembersDraggableList
                                            items={itemsOther}
                                            onDragEnd={onDragEnd1}
                                        />
                                        <Divider />
                                        <Box className="note-container">
                                            <Box>
                                                {!showNote1 && (
                                                    <Button
                                                        variant="text"
                                                        style={{ fontSize: "6px" }}
                                                        onClick={() => setShowNote1(true)}
                                                    >
                                                        <AddIcon className="add-icon" />
                                                        <span className="add-icon">Note</span>
                                                    </Button>
                                                )}
                                            </Box>
                                            {showNote1 && (
                                                <FormControl fullWidth>
                                                    <Box className="note-container-box">
                                                        <FormLabel
                                                            sx={{ paddingLeft: 1 }}
                                                            className="fileds-label-text"
                                                        >
                                                            Note
                                                        </FormLabel>
                                                        <Button
                                                            variant="text"
                                                            onClick={() => setShowNote1(false)}
                                                        >
                                                            <RemoveIcon className="remove-note" />
                                                            <span className="remove-note">Remove Note</span>
                                                        </Button>
                                                    </Box>
                                                    <ReactQuill
                                                        className="react-quill-alignment"
                                                        theme="snow"
                                                        value={""}
                                                        onChange={(e) => { }}
                                                    />
                                                </FormControl>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
                            <LoadingButton
                                color="primary"
                                tabIndex={3}
                                loadingPosition="start"
                                startIcon={<></>}
                                variant="contained"
                                type="submit"
                                className="primary-button"
                                width="180px"
                                name="submit"
                                onClick={() => navigate("/master/procedure-template")}
                            >
                                SAVE
                            </LoadingButton>
                            <LoadingButton
                                tabIndex={3}
                                loadingPosition="start"
                                startIcon={<></>}
                                variant="outlined"
                                type="submit"
                                className="secondary-button"
                                color="secondary"
                                name="submit"
                                onClick={() => navigate("/master/procedure-template")}
                            >
                                CANCEL
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </Formik>
        </Box>
    );
}
