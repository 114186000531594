import React from 'react'
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { Grid, ListItem } from "@mui/material";
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import './styel.scss'
import Title from '../../components/Title';
import BreadcrumbsComponent from '../../components/BreadcrumbsComponent/BreadcrumbsComponent';
import CustomBreadcrumb from '../../components/BreadcrumbsComponent/CustomBreadcrumbs';


export default function Master() {
  const navigate = useNavigate()
  let data: any[] = []
  function createMenuObject(header: string, submaster: string[]) {
    return {
      masterHeader: header,
      subMaster: submaster
    };
  }
  data.push(createMenuObject("Procedure", ["Procedure Master"]))
  data.push(createMenuObject("Examination", ["Examination Master"]))
  data.push(createMenuObject("Report", ["Report Master"]))
  data.push(createMenuObject("Billing Item", ["Billing Item Master"]))
  data.push(createMenuObject("Certificates", ["Certificates Master"]))



  return (
    <>
      <Box mt={2} mb={1}>
        <Box className="page-breadcrumbs">
          <CustomBreadcrumb text="Master" />
          {/* <Title pageTitle="Master" /> */}
        </Box>

      </Box>
      <Box>
        <Grid container spacing={2} >
          <Grid item xs={12} sm={6} md={4} xl={3} >
            <Paper className='report-menu-page-paper' >
              <MenuList className='report-menu-page-MenuList'>
                <ListItem key={"GST Reports"} disablePadding sx={{ display: "block" }} className="nav-ListItemButton report-nav-ListItemButton">
                  <Box className='report-menu-ListItemButton'>
                    <ListItemIcon
                      className='report-icon'
                    >
                      <ReceiptLongOutlinedIcon className="menu-icon" />
                    </ListItemIcon>
                    <ListItemText ><span style={{ fontWeight: "bold" }}>Templates</span></ListItemText>
                    <span className='report-menu-ListItemCount'>
                      5
                    </span>
                  </Box>
                </ListItem>
                <Divider />
                <Box className="fix-box">

                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/medicine-template')}
                  >
                    <ListItemText>Medicine Templates</ListItemText>
                  </MenuItem>
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/diagnosis-template')}
                  >
                    <ListItemText>Diagnosis Templates</ListItemText>
                  </MenuItem>

                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/investigation-template')}
                  >
                    <ListItemText>Investigation Templates</ListItemText>
                  </MenuItem>

                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/certificates-template')}
                  >
                    <ListItemText>Certificates Templates</ListItemText>
                  </MenuItem>

                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/procedure-template')}
                  >
                    <ListItemText>Procedure Templates</ListItemText>
                  </MenuItem>

                </Box>
              </MenuList>

            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4} xl={3} >
            <Paper className='report-menu-page-paper' >
              <MenuList className='report-menu-page-MenuList'>
                <ListItem key={"GST Reports"} disablePadding sx={{ display: "block" }} className="nav-ListItemButton report-nav-ListItemButton">
                  <Box className='report-menu-ListItemButton'>
                    <ListItemIcon
                      className='report-icon'
                    >
                      <ReceiptLongOutlinedIcon className="menu-icon" />

                    </ListItemIcon>
                    <ListItemText ><span style={{ fontWeight: "bold" }}>Diagnosis </span></ListItemText>
                    <span className='report-menu-ListItemCount'>
                      4
                    </span>
                  </Box>
                </ListItem>
                <Divider />
                <Box className="fix-box">
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/diagnosis-template')}
                  >
                    <ListItemText>Diagnosis Templates</ListItemText>
                  </MenuItem>
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/diagnosis-master')}
                  >
                    <ListItemText>Diagnosis Masters</ListItemText>
                  </MenuItem>
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/icd-codes')}
                  >
                    <ListItemText>ICD Codes</ListItemText>
                  </MenuItem>
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/diagnosis-type')}
                  >
                    <ListItemText>Diagnosis Types</ListItemText>
                  </MenuItem>
                </Box>
              </MenuList>

            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4} xl={3} >
            <Paper className='report-menu-page-paper' >
              <MenuList className='report-menu-page-MenuList'>
                <ListItem key={"GST Reports"} disablePadding sx={{ display: "block" }} className="nav-ListItemButton report-nav-ListItemButton">
                  <Box className='report-menu-ListItemButton'>
                    <ListItemIcon
                      className='report-icon'
                    >
                      <ReceiptLongOutlinedIcon className="menu-icon" />
                    </ListItemIcon>
                    <ListItemText ><span style={{ fontWeight: "bold" }}>Medicines</span></ListItemText>
                    {/* <ListItemText ><span style={{ fontWeight: "bold" }}>Clinical</span></ListItemText> */}
                    <span className='report-menu-ListItemCount'>
                      9
                    </span>
                  </Box>
                </ListItem>
                <Divider />
                <Box className="fix-box">

                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/medicine-template')}
                  >
                    <ListItemText>Medicine Templates</ListItemText>
                  </MenuItem>
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/medicine-master')}
                  >
                    <ListItemText>Medicine Masters</ListItemText>
                  </MenuItem>

                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/medicine-group')}
                  >
                    <ListItemText>Medicine Group</ListItemText>
                  </MenuItem>

                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/medicine-Generic-name')}
                  >
                    <ListItemText>Medicine Generic Names</ListItemText>
                  </MenuItem>

                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/medicine-company')}
                  >
                    <ListItemText>Medicine Companys</ListItemText>
                  </MenuItem>

                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/medicine-formulation')}
                  >
                    <ListItemText>Medicine Formulations</ListItemText>
                  </MenuItem>

                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/medicine-used-as')}
                  >
                    <ListItemText>Medicine Used As</ListItemText>
                  </MenuItem>

                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/medicine-diluent-name')}
                  >
                    <ListItemText>Medicine Diluent Names</ListItemText>
                  </MenuItem>

                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/medicine-instruction')}
                  >
                    <ListItemText>Medicine Instructions</ListItemText>
                  </MenuItem>

                  {/* <MenuItem className='number-report-ul'
                    onClick={() => navigate('/medicine-group')}
                  >
                    <ListItemText>Medicine Group</ListItemText>
                  </MenuItem> */}

                </Box>
              </MenuList>

            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4} xl={3} >
            <Paper className='report-menu-page-paper' >
              <MenuList className='report-menu-page-MenuList'>
                <ListItem key={"GST Reports"} disablePadding sx={{ display: "block" }} className="nav-ListItemButton report-nav-ListItemButton">
                  <Box className='report-menu-ListItemButton'>
                    <ListItemIcon
                      className='report-icon'
                    >
                      <ReceiptLongOutlinedIcon className="menu-icon" />

                    </ListItemIcon>
                    <ListItemText ><span style={{ fontWeight: "bold" }}>Follow-ups </span></ListItemText>
                    <span className='report-menu-ListItemCount'>
                      3
                    </span>
                  </Box>
                </ListItem>
                <Divider />
                <Box className="fix-box">
                  {/* <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/followup-duration')}
                  >
                    <ListItemText>Follow-up Duration</ListItemText>
                  </MenuItem> */}
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/followup-reason')}
                  >
                    <ListItemText>Follow-up Reasons</ListItemText>
                  </MenuItem>
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/followup-instruction')}
                  >
                    <ListItemText>Follow-up Instructions</ListItemText>
                  </MenuItem>
                </Box>
              </MenuList>

            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={4} xl={3} >
            <Paper className='report-menu-page-paper' >
              <MenuList className='report-menu-page-MenuList'>
                <ListItem key={"GST Reports"} disablePadding sx={{ display: "block" }} className="nav-ListItemButton report-nav-ListItemButton">
                  <Box className='report-menu-ListItemButton'>
                    <ListItemIcon
                      className='report-icon'
                    >
                      <ReceiptLongOutlinedIcon className="menu-icon" />

                    </ListItemIcon>
                    <ListItemText ><span style={{ fontWeight: "bold" }}>Investigations</span></ListItemText>
                    <span className='report-menu-ListItemCount'>
                      2
                    </span>
                  </Box>
                </ListItem>
                <Divider />
                <Box className="fix-box">
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/investigation-template')}
                  >
                    <ListItemText>Investigation Templates</ListItemText>
                  </MenuItem>
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/investigation-master')}
                  >
                    <ListItemText>Investigation Masters</ListItemText>
                  </MenuItem>
                </Box>
              </MenuList>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4} xl={3} >
            <Paper className='report-menu-page-paper' >
              <MenuList className='report-menu-page-MenuList'>
                <ListItem key={"GST Reports"} disablePadding sx={{ display: "block" }} className="nav-ListItemButton report-nav-ListItemButton">
                  <Box className='report-menu-ListItemButton'>
                    <ListItemIcon
                      className='report-icon'
                    >
                      <ReceiptLongOutlinedIcon className="menu-icon" />

                    </ListItemIcon>
                    <ListItemText ><span style={{ fontWeight: "bold" }}>Instructions</span></ListItemText>
                    <span className='report-menu-ListItemCount'>
                      7
                    </span>
                  </Box>
                </ListItem>
                <Divider />
                <Box className="fix-box">
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/general-instruction')}
                  >
                    <ListItemText>General Instructions</ListItemText>
                  </MenuItem>
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/medicine-instruction')}
                  >
                    <ListItemText>Medicine Instructions</ListItemText>
                  </MenuItem>
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/special-instruction')}
                  >
                    <ListItemText>Special Instructions</ListItemText>
                  </MenuItem>
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/followup-instruction')}
                  >
                    <ListItemText>Follow-up Instructions</ListItemText>
                  </MenuItem>
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/alert-instruction')}
                  >
                    <ListItemText>Alert Instructions</ListItemText>
                  </MenuItem>
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/diet-and-nutrition-instruction')}
                  >
                    <ListItemText>Diet and Nutrition Instructions</ListItemText>
                  </MenuItem>
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/investigation-instruction')}
                  >
                    <ListItemText>Investigation Instructions</ListItemText>
                  </MenuItem>
                </Box>
              </MenuList>

            </Paper>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4} xl={3} >
            <Paper className='report-menu-page-paper' >
              <MenuList className='report-menu-page-MenuList'>
                <ListItem key={"GST Reports"} disablePadding sx={{ display: "block" }} className="nav-ListItemButton report-nav-ListItemButton">
                  <Box className='report-menu-ListItemButton'>
                    <ListItemIcon
                      className='report-icon'
                    >
                      <ReceiptLongOutlinedIcon className="menu-icon" />

                    </ListItemIcon>
                    <ListItemText ><span style={{ fontWeight: "bold" }}>Procedure</span></ListItemText>
                    <span className='report-menu-ListItemCount'>
                      1
                    </span>
                  </Box>
                </ListItem>
                <Divider />
                <Box className="fix-box">
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/instruction-master')}
                  >
                    <ListItemText>Procedure Master</ListItemText>
                  </MenuItem>

                </Box>
              </MenuList>

            </Paper>
          </Grid> */}
          {/* <Grid item xs={12} sm={6} md={4} xl={3} >
            <Paper className='report-menu-page-paper' >
              <MenuList className='report-menu-page-MenuList'>
                <ListItem key={"GST Reports"} disablePadding sx={{ display: "block" }} className="nav-ListItemButton report-nav-ListItemButton">
                  <Box className='report-menu-ListItemButton'>
                    <ListItemIcon
                      className='report-icon'
                    >
                      <ReceiptLongOutlinedIcon className="menu-icon" />

                    </ListItemIcon>
                    <ListItemText ><span style={{ fontWeight: "bold" }}>Examination</span></ListItemText>
                    <span className='report-menu-ListItemCount'>
                      1
                    </span>
                  </Box>
                </ListItem>
                <Divider />
                <Box className="fix-box">
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/instruction-master')}
                  >
                    <ListItemText>Examination Master</ListItemText>
                  </MenuItem>

                </Box>
              </MenuList>

            </Paper>
          </Grid> */}
          {/* <Grid item xs={12} sm={6} md={4} xl={3} >
            <Paper className='report-menu-page-paper' >
              <MenuList className='report-menu-page-MenuList'>
                <ListItem key={"GST Reports"} disablePadding sx={{ display: "block" }} className="nav-ListItemButton report-nav-ListItemButton">
                  <Box className='report-menu-ListItemButton'>
                    <ListItemIcon
                      className='report-icon'
                    >
                      <ReceiptLongOutlinedIcon className="menu-icon" />

                    </ListItemIcon>
                    <ListItemText ><span style={{ fontWeight: "bold" }}>Report</span></ListItemText>
                    <span className='report-menu-ListItemCount'>
                      1
                    </span>
                  </Box>
                </ListItem>
                <Divider />
                <Box className="fix-box">
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/instruction-master')}
                  >
                    <ListItemText>Report Master</ListItemText>
                  </MenuItem>

                </Box>
              </MenuList>

            </Paper>
          </Grid> */}
          <Grid item xs={12} sm={6} md={4} xl={3} >
            <Paper className='report-menu-page-paper' >
              <MenuList className='report-menu-page-MenuList'>
                <ListItem key={"GST Reports"} disablePadding sx={{ display: "block" }} className="nav-ListItemButton report-nav-ListItemButton">
                  <Box className='report-menu-ListItemButton'>
                    <ListItemIcon
                      className='report-icon'
                    >
                      <ReceiptLongOutlinedIcon className="menu-icon" />

                    </ListItemIcon>
                    <ListItemText ><span style={{ fontWeight: "bold" }}>Billing Items</span></ListItemText>
                    <span className='report-menu-ListItemCount'>
                      1
                    </span>
                  </Box>
                </ListItem>
                <Divider />
                <Box className="fix-box">
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/billing-item-master')}
                  >
                    <ListItemText>Billing  Items</ListItemText>
                  </MenuItem>
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/Tax')}
                  >
                    <ListItemText>Taxs</ListItemText>
                  </MenuItem>

                </Box>
              </MenuList>

            </Paper>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4} xl={3} >
            <Paper className='report-menu-page-paper' >
              <MenuList className='report-menu-page-MenuList'>
                <ListItem key={"GST Reports"} disablePadding sx={{ display: "block" }} className="nav-ListItemButton report-nav-ListItemButton">
                  <Box className='report-menu-ListItemButton'>
                    <ListItemIcon
                      className='report-icon'
                    >
                      <ReceiptLongOutlinedIcon className="menu-icon" />

                    </ListItemIcon>
                    <ListItemText ><span style={{ fontWeight: "bold" }}>Certificates</span></ListItemText>
                    <span className='report-menu-ListItemCount'>
                      1
                    </span>
                  </Box>
                </ListItem>
                <Divider />
                <Box className="fix-box">
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/instruction-master')}
                  >
                    <ListItemText>Certificates Master</ListItemText>
                  </MenuItem>

                </Box>
              </MenuList>

            </Paper>
          </Grid> */}
          <Grid item xs={12} sm={6} md={4} xl={3} >
            <Paper className='report-menu-page-paper' >
              <MenuList className='report-menu-page-MenuList'>
                <ListItem key={"GST Reports"} disablePadding sx={{ display: "block" }} className="nav-ListItemButton report-nav-ListItemButton">
                  <Box className='report-menu-ListItemButton'>
                    <ListItemIcon
                      className='report-icon'
                    >
                      <ReceiptLongOutlinedIcon className="menu-icon" />

                    </ListItemIcon>
                    <ListItemText ><span style={{ fontWeight: "bold" }}>Lab</span></ListItemText>
                    <span className='report-menu-ListItemCount'>
                      1
                    </span>
                  </Box>
                </ListItem>
                <Divider />
                <Box className="fix-box">
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/laboratory-master')}
                  >
                    <ListItemText>Lab Masters</ListItemText>
                  </MenuItem>

                </Box>
              </MenuList>

            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={4} xl={3} >
            <Paper className='report-menu-page-paper' >
              <MenuList className='report-menu-page-MenuList'>
                <ListItem key={"GST Reports"} disablePadding sx={{ display: "block" }} className="nav-ListItemButton report-nav-ListItemButton">
                  <Box className='report-menu-ListItemButton'>
                    <ListItemIcon
                      className='report-icon'
                    >
                      <ReceiptLongOutlinedIcon className="menu-icon" />

                    </ListItemIcon>
                    <ListItemText ><span style={{ fontWeight: "bold" }}>Hospitals</span></ListItemText>
                    <span className='report-menu-ListItemCount'>
                      1
                    </span>
                  </Box>
                </ListItem>
                <Divider />
                <Box className="fix-box">
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/hospital-master')}
                  >
                    <ListItemText>Hospital Masters</ListItemText>
                  </MenuItem>

                </Box>
              </MenuList>

            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={4} xl={3} >
            <Paper className='report-menu-page-paper' >
              <MenuList className='report-menu-page-MenuList'>
                <ListItem key={"GST Reports"} disablePadding sx={{ display: "block" }} className="nav-ListItemButton report-nav-ListItemButton">
                  <Box className='report-menu-ListItemButton'>
                    <ListItemIcon
                      className='report-icon'
                    >
                      <ReceiptLongOutlinedIcon className="menu-icon" />

                    </ListItemIcon>
                    <ListItemText ><span style={{ fontWeight: "bold" }}>Doctors</span></ListItemText>
                    <span className='report-menu-ListItemCount'>
                      3
                    </span>
                  </Box>
                </ListItem>
                <Divider />
                <Box className="fix-box">
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/doctor')}
                  >
                    <ListItemText>Consulting Doctors</ListItemText>
                  </MenuItem>
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/doctor')}
                  >
                    <ListItemText>Referral Doctors</ListItemText>
                  </MenuItem>
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/doctor')}
                  >
                    <ListItemText>Visiting Doctors</ListItemText>
                  </MenuItem>

                </Box>
              </MenuList>

            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4} xl={3} >
            <Paper className='report-menu-page-paper' >
              <MenuList className='report-menu-page-MenuList'>
                <ListItem key={"GST Reports"} disablePadding sx={{ display: "block" }} className="nav-ListItemButton report-nav-ListItemButton">
                  <Box className='report-menu-ListItemButton'>
                    <ListItemIcon
                      className='report-icon'
                    >
                      <ReceiptLongOutlinedIcon className="menu-icon" />

                    </ListItemIcon>
                    <ListItemText ><span style={{ fontWeight: "bold" }}>Medical Representatives</span></ListItemText>
                    <span className='report-menu-ListItemCount'>
                      1
                    </span>
                  </Box>
                </ListItem>
                <Divider />
                <Box className="fix-box">
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/master/medical-representative')}
                  >
                    <ListItemText>Medical Representative Masters</ListItemText>
                  </MenuItem>
                  {/* <MenuItem className='number-report-ul'
                    onClick={() => navigate('/laboratory-master')}
                  >
                    <ListItemText>Referral Doctors</ListItemText>
                  </MenuItem>
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/laboratory-master')}
                  >
                    <ListItemText>Visiting Doctors</ListItemText>
                  </MenuItem> */}
                </Box>
              </MenuList>

            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={4} xl={3} >
            <Paper className='report-menu-page-paper' >
              <MenuList className='report-menu-page-MenuList'>
                <ListItem key={"symptom"} disablePadding sx={{ display: "block" }} className="nav-ListItemButton report-nav-ListItemButton">
                  <Box className='report-menu-ListItemButton'>
                    <ListItemIcon
                      className='report-icon'
                    >
                      <ReceiptLongOutlinedIcon className="menu-icon" />

                    </ListItemIcon>
                    <ListItemText ><span style={{ fontWeight: "bold" }}>Symptoms</span></ListItemText>
                    <span className='report-menu-ListItemCount'>
                      1
                    </span>
                  </Box>
                </ListItem>
                <Divider />
                <Box className="fix-box">
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('symptoms')}
                  >
                    <ListItemText>Symptoms</ListItemText>
                  </MenuItem>
                  {/* <MenuItem className='number-report-ul'
                    onClick={() => navigate('/laboratory-master')}
                  >
                    <ListItemText>Referral Doctors</ListItemText>
                  </MenuItem>
                  <MenuItem className='number-report-ul'
                    onClick={() => navigate('/laboratory-master')}
                  >
                    <ListItemText>Visiting Doctors</ListItemText>
                  </MenuItem> */}
                </Box>
              </MenuList>

            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
