import React, { useState } from 'react'
import {
  Box, Grid, TextField, Button, styled,
  TableCell,
  TableHead,
  TableRow,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
  Tabs,
  Tab,
} from '@mui/material'
import { useNavigate } from 'react-router-dom';
import '../../theme/style.scss';
import BreadcrumbsComponent from '../../components/BreadcrumbsComponent/BreadcrumbsComponent';
import profile from '../../assets/images/2.jpg'
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import BasicSetting from './_components/BasicSetting';
import SecuritySetting from './_components/SecuritySetting';
import ChangePassword from './_components/ChangePassword';
import CustomBreadcrumbs from '../../components/BreadcrumbsComponent/CustomBreadcrumbs';


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Profile() {
  const [isEdit, setIsEdit] = useState(false)
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl3] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);

  const handleCloseMore = () => {
    setAnchorEl3(null);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Box className='main-container'>
        <Grid xs={12} container className='page-header'>
        <Grid xs={6} sm={6} item className="page-breadcrumbs">
          <CustomBreadcrumbs />
        </Grid>
          {!isEdit && <Grid xs={6} sm={6} item className='page-header-icon'>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setIsEdit(!isEdit)}
              size="medium"
              startIcon={<EditRoundedIcon />}
            >
              Edit
            </Button></Grid>}
        </Grid>
        <Grid container className="profile-list-container" >
          <Grid item xs={12} sm={4} md={1} lg={1} sx={{ paddingX: '24px', paddingTop: '24px' }}>
            <Grid container>
              <Grid className='set' item xs={12} sm={6} md={2} lg={2}>
                <img src={profile} style={{ borderRadius: '50%' }} alt="profile" height={150} width={150} />
              </Grid>
            </Grid>
          </Grid>
          <Grid className='profile' item xs={12} sm={6} md={5} lg={5} sx={{ paddingLeft: 11, paddingTop: '24px' }} >
            <Grid container  >
              <Grid item xs={12} sm={8} md={12} lg={12}  >
                <span style={{ fontSize: '24px', fontWeight: 'bold' }}>Cristina Groves</span>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <span style={{ fontSize: '14px' }}>Gynecologist</span>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: '5px' }}>
                <span style={{ fontSize: '14px' }} > Employee ID : DR-0001</span>
              </Grid>
            </Grid >
          </Grid>
          <Grid  item xs={12} sm={6} md={6} lg={6} sx={{ paddingTop: '24px' }}>
            <Grid className='text' container sx={{ fontSize: '12px' }} >
              <Grid item xs={3} sm={3} md={1} lg={1} sx={{paddingLeft:'20px'}}>
                <Typography>Phone:</Typography>
              </Grid>
              <Grid item xs={9} sm={9} md={11} lg={11} sx={{ paddingLeft: '20px' }}>
                <span className='user-detail-text'>770-889-6484</span>
              </Grid>
              <Grid item xs={3} sm={3} md={1} lg={1} sx={{ marginTop: '5px', paddingLeft: '20px' }} >
                <Typography>Email:</Typography>
              </Grid>
              <Grid item xs={9} sm={9} md={11} lg={11} sx={{ marginTop: '5px', paddingLeft: '20px' }}>
                <span className='user-detail-text'>cristinagroves@example.com</span>
              </Grid>
              <Grid item xs={3} sm={3} md={1} lg={1} sx={{ marginTop: '5px', paddingLeft: '20px' }}>
                <Typography>Birthday:</Typography>
              </Grid>
              <Grid item xs={9} sm={9} md={11} lg={11} sx={{ marginTop: '5px', paddingLeft: '20px' }}>
                <span className='user-detail-text'>3rd March</span>
              </Grid>
              <Grid item xs={3} sm={3} md={1} lg={1} sx={{ marginTop: '5px', paddingLeft: '20px' }}>
                <Typography>Address:</Typography>
              </Grid>
              <Grid item xs={9} sm={9} md={11} lg={11} sx={{ marginTop: '5px', paddingLeft: '20px' }}>
                <span className='user-detail-text'>714 Burwell Heights Road, Bridge City, TX, 77611</span>
              </Grid>
              <Grid item xs={3} sm={3} md={1} lg={1} sx={{ marginTop: '5px', paddingLeft: '20px' }}>
                <Typography>Gender:</Typography>
              </Grid>
              <Grid item xs={9} sm={9} md={11} lg={11} sx={{ marginTop: '5px', paddingLeft: '20px' }}>
                <span className='user-detail-text'>Male</span>
              </Grid>
            </Grid >
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box >
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Profile" {...a11yProps(0)} />
                <Tab label="Security" {...a11yProps(1)} />
                <Tab label="Change Password" {...a11yProps(2)} />
              </Tabs>
            </Box>

          </Grid>
        </Grid >
        <Grid container >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomTabPanel value={value} index={0}>
              <BasicSetting isEdit={isEdit} setIsEdit={setIsEdit} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <SecuritySetting isEdit={isEdit} setIsEdit={setIsEdit} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <ChangePassword isEdit={isEdit} setIsEdit={setIsEdit} />
            </CustomTabPanel>
          </Grid>
        </Grid>
      </Box >
      <Popover
        elevation={1}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseMore}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('/branch-save')}>
              <ListItemText primary="Edit" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              className="delete-container"
            >
              Delete
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </>
  )
}
