import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import "../../../theme/style.scss";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import PhoneInput from 'react-phone-number-input'
import Title from "../../components/Title";
import { CustomSelect } from "../../components/CustomFields/CustomSelect";
import { Formik } from "formik";
import CustomBreadcrumbs from "../../components/BreadcrumbsComponent/CustomBreadcrumbs";

const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#FAFBFD", // Set the desired background color
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));
function TabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  setValue(newValue);
};


export default function SymptomsSave() {
  const navigate = useNavigate();
  const [value, setValue] = useState('');
  const [countryData, setCountry] = useState([
    { countryID: 0, code: "", name: "" },
    { countryID: 1, code: "123", name: "India" },
    { countryID: 2, code: "456", name: "USA" },
    
  ]);
  const [systems, setSystem] = useState([
    {
      id: 1,
      label: 'General Symptoms'
    },
    {
      id: 2,
      label: 'Respiratory System'
    },
  ]);
 

  return (
    <Box className="main-container" pl={2}>
      <Grid item xs={12} container className="page-header-save">
      <Grid xs={12} sm={12} item className="page-breadcrumbs">
          <CustomBreadcrumbs />
        </Grid>
      </Grid>
      <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
      }}
      onSubmit={async (values) => {
        console.log("values",values)
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} mb={3} className="group-container">
          <Grid container item spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Name</FormLabel>
                  <CustomTextField
                    name="laboratoryName"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}/>
              <Grid item xs={12} sm={6}>
                <Box>
                  <Typography>
                    Systems
                  </Typography>
                  <FormGroup>
                    {systems.map((system, i) => {
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                            // defaultChecked
                            // onChange={() => setMorning(!morning)}
                            />
                          }
                          label={system.label}
                        />
                      )
                    })
                    }
                  </FormGroup>
                </Box>
              </Grid>
          </Grid>
        </Grid> 
                 
          

        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
            <LoadingButton
              color="primary"
              tabIndex={3}
              loadingPosition="start"
              startIcon={<></>}
              variant="contained"
              type="submit"
              className="primary-button"
              onClick={() => navigate("/master/laboratory-master")}
              name="submit"
            >
              Save
            </LoadingButton>
            <LoadingButton
              tabIndex={3}
              loadingPosition="start"
              startIcon={<></>}
              variant="outlined"
              type="submit"
              name="submit"
              className="secondary-button"
              color="secondary"
                onClick={() => navigate("/master/hospital-master")}
            >
              Cancel
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>

      </Formik>

    </Box>
  );
}
