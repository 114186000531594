import React, { useEffect } from 'react';
import { SITE_TITLE } from '../services/common/const';
import { Grid } from '@mui/material';



// export default function Title(props: {pageTitle: string,}) {
export default function Title(props: any) {

  function getSiteTitle() {
    let fullSiteName = SITE_TITLE;

    if (props.pageTitle && props.pageTitle.length > 0) {
      fullSiteName = props.pageTitle + " - " + fullSiteName;
    }
    return fullSiteName;
  }
  // useEffect(() => {
  //   const prevTitle = document.title;
  //   document.title = getSiteTitle();
  //   return () => {
  //     document.title = prevTitle;
  //   };
  // }, []);


  function pageTitleName(page: any) {
    let pageName = "";

    switch (page) {
      case "profile": {
        pageName = "Profile";
        break;
      }
      case "billing-item-master": {
        pageName = "Billing Item Master";
        break;
      }
      case "medicine-template": {
        pageName = "Medicine Template";
        break;
      }

      case "diagnosis-template": {
        pageName = "Diagnosis Template";
        break;
      }

      case "investigation-template": {
        pageName = "Investigation Template";
        break;
      }
      case "procedure-template": {
        pageName = "Procedure Template";
        break;
      }
      case "patient-history": {
        pageName = "Patient History";
        break;
      }
      case "add-Appointments": {
        pageName = "Add Appointments";
        break;
      }
      case "Appointments": {
        pageName = "Appointments";
        break;
      }
      case "certificates-template": {
        pageName = "Certificates Template";
        break;
      }
      case "certificates": {
        pageName = "Certificates";
        break;
      }
      case "opd-billing": {
        pageName = "Opd Billing";
        break;
      }
      case "medicine-master": {
        pageName = "Medicine Master"
        break;
      }
      case "medicine-instruction": {
        pageName = "Medicine Instruction"
        break;
      }
      case "followup-duration": {
        pageName = "Follow - up Duration"
        break;
      }
      case "followup-reason": {
        pageName = "Follow-up Reason"
        break;
      }
      case "followup-instruction": {
        pageName = "Follow-up Instruction"
        break;
      }
      case "department": {
        pageName = "Department"
        break;
      }
      case "organization": {
        pageName = "Organization"
        break;
      }
      case "branch": {
        pageName = "Branch"
        break;
      }
      case "diagnosis-master": {
        pageName = "Diagnosis Master"
        break;
      }
      case "icd-codes": {
        pageName = "ICD-10 Code"
        break;
      }
      case "diagnosis-type": {
        pageName = "Diagnosis Type"
        break;
      }
      case "speciality": {
        pageName = "Speciality"
        break;
      }
      case "investigation-master": {
        pageName = "Investigation Master"
        break;
      }
      case "general-instruction": {
        pageName = "General Instruction"
        break;
      }
      case "special-instruction": {
        pageName = "Special Instruction"
        break;
      }
      case "alert-instruction": {
        pageName = "Alert Instruction"
        break;
      }
      case "diet-and-nutrition-instruction": {
        pageName = "Diet and Nutrition Instruction"
        break;
      }
      case "investigation-instruction": {
        pageName = "Investigation Instruction"
        break;
      }
      case "laboratory-master": {
        pageName = "Laboratory"
        break;
      }
      case "doctor": {
        pageName = "Doctor"
        break;
      }
      case "medical-representative": {
        pageName = "Medical Representative"
        break;
      }

      case "consumer": {
        pageName = "Consumer"
        break;
      }
      case "opd-clinical": {
        pageName = "OPD Clinical"
        break;
      }

      case "patient-master": {
        pageName = "Patient"
        break;
      }
      case "staff": {
        pageName = "Staff"
        break;
      }
      case "users": {
        pageName = "Users"
        break;
      }
      case "dashboard": {
        pageName = "Dashboard"
        break;
      }


    }
    return pageName;

  }
  return <>
    <h4>{pageTitleName(props.pageTitle)}</h4>
  </>;
}