import React, { useContext, useEffect, useState } from 'react'
import {
  Box, Grid, IconButton, InputAdornment, TextField, Button, styled, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
  Popover,
  Pagination,
  Stack,
  Tab,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tabs,
  Alert,
} from '@mui/material'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate } from 'react-router-dom';
import '../../theme/style.scss';
import CustomBreadcrumb from '../../components/BreadcrumbsComponent/CustomBreadcrumbs';
import {
  GetConsumerList,
  DeleteConsumer,
} from "../../services/ConsumerService";
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import { PAGE_SIZE } from '../../services/common/const';
import { EncryptDecryptService } from '../../services/common/EncryptionService';
import { PreferencesContext } from '../../PreferenceContext';
import { AlertType } from '../../services/models/AlertType';
import CustomBreadcrumbs from '../../components/BreadcrumbsComponent/CustomBreadcrumbs';


interface Data {
  consumerId: number;
  code: string;
  authorizePersion: string;
  organization: string;
  designation: string;
  email: string;
  website: string;
  plan: string;
  none: string;
}

const parameters: any = {
  consumerId: "",
  pageIndex: 1,
  pageSize: 5,
  sortColumn: "",
  sortDirection: "",
  searchValue: "",
};

const consumer: any = {
  consumerCollcection: []
}
export default function ConsumerList() {
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  const [param, setParam] = useState(parameters);
  const [order, setOrder] = React.useState<Order>("");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("none");
  const [rowsPerPage, setRowsPerPage] = React.useState(PAGE_SIZE);
  const [page, setPage] = React.useState(0);
  const [page_Index, setPage_Index] = useState(1);
  const [value, setValue] = useState("");
  const [counData, setCounData] = useState(0);
  const [Curr_page, setCurrPage] = React.useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [pageStartIndex, setPageStartIndex] = useState(1);
  const [pageEndIndex, setPageEndIndex] = useState(1);
  const [openDeleteDilog, setOpenDeleteDilog] = React.useState(false);
  const [resultDataCollection, setResultDataCollection] = useState(consumer.consumerCollcection);
  const [consumerId, setConsumerId] = useState(0)
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl3] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);

  // for Edit & Delet pop 
  const handleOpenMore = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: any
  ) => {
    setAnchorEl3(event.currentTarget);
    // setBuyerId(id);
  };
  const handleCloseMore = () => {
    setAnchorEl3(null);
  };
  //  Table Header 

  function EnhancedTableHead(props: EnhancedTableProps) {
    return (
      // checkBox for Header

      <TableHead className='table-header-color'>
        <TableRow>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "4%"
            }}
            align="left"
          >

          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "10%"
            }}
            align="left"
          >
            Code
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "10%"
            }}
            align="left"
          >
            Authorize Persion
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "10%"
            }}
            align="left"
          >
            Organization
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "none",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "15%"
            }}
            align="left"
          >
            Designation
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "none",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "15%"
            }}
            align="left"
          >
            Eamil
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "none",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "15%"
            }}
            align="left"
          >
            Website
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "20%"
            }}
            align="left"
          >
            Plan
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "12%"
            }}
            align="right"
          >
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  useEffect(() => {
    getConsumerList()
  }, []);
  useEffect(() => {
    getConsumerList()
  }, [param]);

  const getConsumerList = async () => {


    let params = new URLSearchParams();
    params.append("consumerId", "1");
    params.append("pageIndex", param.pageIndex);
    params.append("pageSize", param.pageSize);
    params.append("sortColumn", param.sortColumn);
    params.append("sortDirection", param.sortDirection);
    params.append("searchValue", param.searchValue);

    await GetConsumerList(params).then((res: any) => {
      let dataCount = 0;
      let showDataCount = 0;
      if (res && res.data.data) {
        const consumertColl: any =
          res.data.data;
        if (consumertColl !== null && consumertColl !== undefined) {
          dataCount = consumertColl.totalCount;
        }
      }
      showDataCount = Math.ceil(dataCount / param.pageSize);
      setCounData(showDataCount);
      setResultDataCollection(res.data.data.consumertColl);
      let startIndex = (page_Index - 1) * param.pageSize + 1;
      let endIndex =
        (page_Index - 1) * param.pageSize +
        (res.data.data.consumertColl
          ? res.data.data.consumertColl.length
          : 0);
      setPageStartIndex(startIndex);
      setPageEndIndex(endIndex);
      setTotalCount(dataCount);
    }).catch((err) => {
      console.log("err:", err)
    })
  }

  // sorting Table login
  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
  ) {
    const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  type Order = "asc" | "desc" | "";

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // pagination
  const handleChangePageNew = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    if (Curr_page !== value) {
      setCurrPage(value);
      setPage_Index(value);
      setParam({ ...param, pageIndex: value });
    }
  };

  // search function
  function searchValue(val: any) {
    if (val === "") {
      setValue("");
    }
    setPage_Index(1);
    setParam({ ...param, searchValue: val.toString(), pageIndex: 1 });
  }
  const edService = new EncryptDecryptService();

  // edit consumer
  const consumerEdit = (consumerId: any) => {
    let encryptconsumertId: any = edService.encrypt(consumerId);
    navigate(
      {
        pathname: "/settings/consumer/consumer-save",
        search: `?q=${encodeURIComponent(encryptconsumertId)}`,
      },
      {
        state: {
          title: " Edit",
          button: "Update",
          consumerId: consumerId,
        },
      }
    );
  };

  //delete warning dialogbox
  const handleClickOpen = () => {
    setOpenDeleteDilog(true);
  };

  const handleClose = () => {
    setOpenDeleteDilog(false);
  };


  // delete consumer
  async function deleteRow(consumertIds: any) {
    try {
      let DeleteData = {
        consumertId: consumertIds.toString(),
        isDeleted: true,
        dataManagedBy: 1,
      };
      const result: any = await DeleteConsumer(DeleteData);
      if (result) {
        getConsumerList();
        setAnchorEl3(null);
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: result.message,
          alertType: AlertType.Success,
        });
      } else {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: result.message,
          alertType: AlertType.Error,
        });
      }
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage:
          typeof e === "string" ? (e as string) : (e?.message as string),
        alertType: AlertType.Error,
      });
    }
    handleClose();
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function TabProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    setResultDataCollection(consumer.consumerCollection);
  }, [consumer.consumerCollection]);

  return (
    <>
      <Box className='main-container'>
        <Grid xs={12} container className='page-header'>
          <Grid xs={6} sm={6} item className="page-breadcrumbs">
            <CustomBreadcrumbs />
          </Grid>
          <Grid xs={6} sm={6} item className='page-header-icon'>
            <Button
              color="primary"
              variant="contained"
              onClick={() => navigate("consumer-save")}
              size="medium"
              startIcon={<AddIcon />}
            >
              Add
            </Button></Grid>
        </Grid>

        <Grid container className="list-container" sx={{ padding: '16px' }}>
          <Grid item xs={8} sm={8} md={8} lg={10} className='search-bar' sx={{ gap: 2 }} >

            <TextField
              placeholder="Search..."
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  searchValue(value);
                }
              }}
              type="text"
              variant="outlined"
              size="small"

              onChange={(e) => setValue(e.target.value)}
              value={value}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      size="small"
                      onClick={() => searchValue(value)}
                    >
                      {" "}
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: value && (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => searchValue("")}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={2} className='search-bar pdf-icon'>
            <img src="./xls.png" alt="" className='pdf-icon-hover' />
            <img src="./pdf.png" alt="" className='pdf-icon-hover' />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box
              sx={{ borderBottom: 1, borderColor: "divider", paddingTop: 2 }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                indicatorColor="primary"
                aria-label="Sale Invoice"
                TabIndicatorProps={{ sx: { backgroundColor: " #2e67a3" } }}
                sx={{
                  "& button:hover": {
                    backgroundColor: "#f1f6fe",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  },
                }}
              >

                <Tab
                  sx={{ textTransform: "capitalize !important" }}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <span>Active</span>
                      <Box
                        sx={{ backgroundColor: "#448944", color: "#fff" }}
                        className="inner-tabs"
                      >
                        1
                      </Box>
                    </Box>
                  }
                  {...TabProps(1)}
                />
                <Tab
                  sx={{ textTransform: "capitalize !important" }}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <span>On Trial</span>
                      <Box
                        sx={{ backgroundColor: "#c19603", color: "#fff" }}
                        className="inner-tabs"
                      >
                        3
                      </Box>
                    </Box>
                  }
                  {...TabProps(3)}
                />
                <Tab
                  sx={{ textTransform: "capitalize !important" }}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <span>Expried</span>
                      <Box
                        sx={{ backgroundColor: "#943432", color: "#fff" }}
                        className="inner-tabs"
                      >
                        3
                      </Box>
                    </Box>
                  }
                  {...TabProps(3)}
                />
                <Tab
                  sx={{ textTransform: "capitalize !important" }}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <span>All</span>
                      <Box
                        sx={{ backgroundColor: "#2e67a3", color: "#fff" }}
                        className="inner-tabs"
                      >
                        5
                      </Box>
                    </Box>
                  }
                  {...TabProps(0)}
                />
              </Tabs>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TableContainer >
              <Table>
                <EnhancedTableHead />
                {/* <TableBody>
                  {stableSort(
                    resultDataCollection,
                    getComparator(order, orderBy)
                  )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item: any, index: any) => {
                      return (
                        <TableRow>
                          <TableCell><BookmarkBorderOutlinedIcon /></TableCell>
                          <TableCell><Link className='text-link' to="consumer-save" onClick={() => consumerEdit(item.consumerId)}>{item.code}</Link></TableCell>
                          <TableCell>{item.authorizedPerson}</TableCell>
                          <TableCell>{item.organization}</TableCell>
                          <TableCell sx={{
                            display: {
                              xs: "none",
                              sm: "table-cell",
                              md: "table-cell",
                              lg: "table-cell",
                            },

                          }}>{item.designation}</TableCell>
                          <TableCell sx={{
                            display: {
                              xs: "none",
                              sm: "table-cell",
                              md: "table-cell",
                              lg: "table-cell",
                            },

                          }}>{item.email}</TableCell>
                          <TableCell sx={{
                            display: {
                              xs: "none",
                              sm: "table-cell",
                              md: "table-cell",
                              lg: "table-cell",
                            },

                          }}>{item.website}</TableCell>
                          <TableCell>
                            <Box
                              // style = {{
                              //    margin : "2px",
                              //    width : "100%",
                              //    display : 'flex',
                              // }}
                              sx={{
                                display: {
                                  xs: "table-cell",
                                  sm: "table-cell",
                                  md: "table-cell",
                                  lg: "table-cell",
                                },
                                paddingBottom: "4px",
                                width: "15%"
                              }}
                            >{item.plantype}</Box>
                            <Alert
                              icon={false}
                              sx={{
                                width: "80px !important",
                                paddingX: "6px !important",
                                paddingY: "0px !important",
                                paddingTop: "2px !important",
                                fontWeight: "bold",
                                border: "none !important",
                                lineHeight: "8px !important",
                              }}
                              className={
                                item.plantype == "Standard" ? "badge badge-success"
                                  : item.plantype == "Trial" ? "badge badge-warning" : "badge badge-danger"
                              }
                            //severity="secondary"

                            >
                              {item.date}
                            </Alert>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody> */}
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            container
            paddingTop={1}
            className='pagination'
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid item xs={12} sm={6} className="pagination-label">
              {pageStartIndex} - {pageEndIndex} of {totalCount}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack
                component="div"
                className='pagination'
              >
                <Pagination
                  color="primary"
                  count={counData} // Number of pages
                  page={Curr_page} // Current page
                  onChange={handleChangePageNew} // Handle page change
                />
              </Stack>
            </Grid>
          </Grid>

        </Grid>
      </Box>
      <Popover
        elevation={1}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseMore}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => {
              consumerEdit(consumerId)

            }}>
              <ListItemText primary="Edit" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              className="delete-container"
              onClick={() => handleClickOpen()}
            >
              Delete
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>

      {/* Delete modal popup - starts */}
      <Dialog
        open={openDeleteDilog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this consumer?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => deleteRow(consumerId)}
            className="button-delete"
          >
            Yes
          </Button>
          <Button onClick={handleClose} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
      {/* Delete modal popup - ends */}
    </>
  )
}
