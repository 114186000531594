import "../../../../../theme/style.scss";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  styled,
  Dialog,
  DialogContent,
  List,
  ListItem
} from "@mui/material";
import React, { useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { reorder } from "../../../../../Helpers/helpers";
import { Formik } from "formik";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ChiefComplaintsDraggableTable from "../ChiefComplaints/ChiefComplaintsDraggable/ChiefComplaintsDraggableTable";
import CustomTimePicker from "../../../../../components/TimePicker/CustomTimePicker";
import ReactQuill from "react-quill";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar"
import useMediaQuery from '@mui/material/useMediaQuery';
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import SubjectRoundedIcon from "@mui/icons-material/SubjectRounded";
import EditIcon from '@mui/icons-material/Edit';
import { CustomSelect } from "../../../../../components/CustomFields/CustomSelect";

const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#FAFBFD",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function Prescription() {
  const [showNote, setShowNote] = useState(false);
  const [showNoteNew, setShowNoteNew] = useState(false);
  const [selected, setSelected] = useState("");
  const [dialuentIsUsed, setDiluentIsUsed] = useState(false);
  const [morning, setMorning] = useState(false);
  const [afternoon, setAfternoon] = useState(false);
  const [evening, setEvening] = useState(false);
  const [night, setNight] = useState(false);
  const [temperature, setTemperature] = useState("");
  const [unit, setUnit] = useState("mg");
  const smallScreen = useMediaQuery("(max-width:768px)");
  const mobileScreen = useMediaQuery("(max-width:425px)");
  //Draggable Table
  const [items, setItems] = React.useState([
    {
      id: "Item 1",
      symptom: " Dapagliflozin",
    },
    {
      id: "Item 2",
      symptom: "Mayzent",
    },
    {
      id: "Item 3",
      symptom: "Clobetasol",
    },
    {
      id: "Item 4",
      symptom: "Mobic",
    },
    {
      id: "Item 5",
      symptom: "Diltiazem",
    },
  ]);

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return;

    const newItems = reorder(items, source.index, destination.index);

    setItems(newItems);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (value: boolean) => {
    if(smallScreen){

      setOpen(value);
    } else if(mobileScreen){

      setOpen(value);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [itemsOther, setItemsOther] = React.useState([
    {
      id: "Item1 1",
      primary: "1Practical Rubber Computer1",
      secondary: "1Synergistic leading edge matrices",
    },
    {
      id: "Item1 2",
      primary: "1Practical Rubber Computer2",
      secondary: "1Synergistic leading edge matrices",
    },
    {
      id: "Item1 3",
      primary: "1Practical Rubber Computer3",
      secondary: "1Synergistic leading edge matrices",
    },
  ]);

  const onDragEnd1 = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(itemsOther, source.index, destination.index);

    setItemsOther(newItems);
  };

  const handleTemperatureChange = (event) => {
    setTemperature(event.target.value);
  };

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
  };
  const [openPrescriptionSummary, setopenPrescriptionSummary] = React.useState(false);

  const handleClickOpenPrescriptionSummary = (value: boolean) => {
    if (mobileScreen) {
      setopenPrescriptionSummary(value);
    }
    else if (smallScreen) {
      setopenPrescriptionSummary(value);
    }
  };

  const handleClickClosePrescriptionSummary = () => {
    setopenPrescriptionSummary(false);
  };
  return (
    <Box className="main-container">
      <Grid container spacing={2}>
        <Grid item xs={12} mb={2}>
          <Grid container item spacing={2}>
          <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
            <Grid item xs={12} sm={12} md={6}>
            <Box
                className="template-table-container"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                }}
              >
                <Box className="inner-title-container">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box className="inner-header-container"
                    sx={{display:"flex",  justifyContent:"space-between"}}
                    >
                      <Typography style={{ fontWeight: 600 }}>
                        Prescription
                      </Typography>
                      {mobileScreen || smallScreen ? <Box>
                        <Button onClick={() => handleClickOpenPrescriptionSummary(true)} sx={{ padding: 0, margin: 0, minWidth:"30px" }} aria-label="delete">
                         <SubjectRoundedIcon></SubjectRoundedIcon>
                        </Button>
              </Box> : ''}
                    </Box>
                  </Paper>
                  <Divider />
                  <List>
                    {items.map((item: any, index: number) => (
                      <ListItem
                        sx={{
                          borderBottom: "1px solid #E9EBEC",
                          cursor: 'pointer'
                        }}
                        className={
                          selected === item.symptom
                            ? "draggable-list-item"
                            : " "
                        }
                      >
                        <Grid container item spacing={2}>
                          <Grid item xs={12} sm={12} md={12}>
                            <Box
                              style={{
                                display: "flex",
                              }}
                              onClick = {()=>{handleClickOpen(true)}}
                            >
                              <Grid container item>
                                <Grid item xs={12} sm={12} md={12}>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() => {
                                      setSelected(item.symptom)
                                    }}
                                  >
                                    <Box
                                      className={
                                        selected === item.symptom
                                          ? "symptom-text"
                                          : " "
                                      }
                                      sx={{ paddingBottom: '8px', paddingTop: '8px' }}
                                    
                                      >
                                      <Typography>{item.symptom}</Typography>
                                    </Box>

                                    {selected === item.symptom && (
                                      <Box
                                        style={{
                                          paddingTop: "8px",
                                          paddingLeft: '8px'
                                        }}
                                      >
                                        <KeyboardDoubleArrowRightRoundedIcon />
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                        <Box>
                        <IconButton>
                             <EditIcon sx={{ color: "rgb(65, 77, 85)" }} />
                        </IconButton>
                        </Box>
                        <Box>
                        <IconButton>
                             <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                         </IconButton>
                        </Box>
                      </ListItem>
                    ))}
                  </List>
               
        <Box style={{ padding: 10, paddingRight: 24, paddingLeft: 24 }}>
          <FormControl fullWidth>
          <CustomSelect
                                // isNotCreatable={true}
                                id={"admission"}
                                FieldName={"admission"}
                                options={[]}
                                placeholder="Search..."
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e);
                                }}
                                Value=""
                              />
          </FormControl>
        </Box>
        <Divider />
                  <Box className="note-container">
                    <Box>
                      {!showNote && (
                        <Button
                          variant="text"
                          style={{ fontSize: "6px" }}
                          onClick={() => {
                            setShowNote(true);
                          }}
                        >
                          <AddIcon className="add-icon" />
                          <span className="add-icon"> Note</span>
                        </Button>
                      )}
                    </Box>

                    {showNote && (
                      <FormControl fullWidth>
                        <Box className="note-container-box">
                          <FormLabel
                            sx={{ paddingLeft: 1 }}
                            className="fileds-label-text"
                          >
                            Note
                          </FormLabel>
                          <Button
                            variant="text"
                            onClick={() => {
                              setShowNote(false);
                            }}
                          >
                            <RemoveIcon className="remove-note" />
                            <span className="remove-note">Remove Note</span>
                          </Button>
                        </Box>
                        <ReactQuill
                          className="react-quill-alignment"
                          theme="snow"
                          value={""}
                          onChange={(e) => { }}
                        />
                      </FormControl>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            </Formik>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container template-hide-small-screen template-hide-medium-screen"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                  minHeight: 400,
                }}
                style = {{position:"sticky", top:"5px"}}
              >
                <Box className="inner-title-container">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="inner-header-container">
                      <Typography
                        className={selected ? "symptom-text" : ""}
                        style={{ fontWeight: 600 }}
                      >
                        {selected ? selected : "Prescription Summary"}
                      </Typography>
                      {selected !== '' && <Typography>
                        <IconButton onClick={() => setSelected("")} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                          <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                        </IconButton>
                      </Typography>}
                    </Box>
                  </Paper>
                  {selected ?
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                        <Divider />
                        <Grid
                          container
                          item
                          spacing={2}
                          style={{ padding: 20 }}
                        >
                          <Grid item xs={12} sm={6} md={6}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={() => setMorning(!morning)}
                                  />
                                }
                                label="Morning"
                              />
                            </FormGroup>
                          </Grid>

                          <Grid item xs={12} sm={6} md={6}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={() => setAfternoon(!afternoon)}
                                  />
                                }
                                label="Afternoon"
                              />
                            </FormGroup>
                          </Grid>

                          {!morning && <Grid item xs={12} sm={6} md={6} />}
                          {morning &&
                            <Grid item xs={12} sm={6} md={6}>
                              <Box sx={{ paddingRight: 14 }}>
                                <FormControl fullWidth>
                                  <FormLabel className="fileds-label-text">
                                    Dose
                                  </FormLabel>
                                  <TextField
                                    variant="outlined"
                                    fullWidth
                                    value={temperature}
                                    onChange={handleTemperatureChange}
                                    type="number"
                                    size="small"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <Select
                                            value={unit}
                                            onChange={handleUnitChange}
                                            size="small"
                                            displayEmpty
                                            inputProps={{
                                              "aria-label": "Without label",
                                            }}
                                            sx={{
                                              marginLeft: 1,
                                              ".MuiSelect-select": {
                                                // paddingRight: "0px",
                                              },
                                            }}
                                          >
                                            <MenuItem value="mg">mg</MenuItem>
                                            <MenuItem value="g">g</MenuItem>
                                            <MenuItem value="mL">mL</MenuItem>
                                          </Select>
                                        </InputAdornment>
                                      ),
                                    }}
                                    sx={{
                                      minWidth: "150px",
                                      ".MuiOutlinedInput-root": {
                                        paddingRight: 0,
                                      },
                                    }}
                                  />
                                </FormControl>
                              </Box>
                            </Grid>
                          }
                          {!afternoon && <Grid item xs={12} sm={6} md={6} />}
                          {afternoon && <Grid item xs={12} sm={6} md={6}>
                            <Box sx={{ paddingRight: 14 }}>
                              <FormControl  >
                                <FormLabel className="fileds-label-text">
                                  Dose
                                </FormLabel>
                                <TextField
                                  variant="outlined"

                                  value={temperature}
                                  onChange={handleTemperatureChange}
                                  type="number"
                                  size="small"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Select
                                          value={unit}
                                          onChange={handleUnitChange}
                                          size="small"
                                          displayEmpty
                                          inputProps={{
                                            "aria-label": "Without label",
                                          }}
                                          sx={{
                                            marginLeft: 1,
                                            ".MuiSelect-select": {
                                              // paddingRight: "0px",
                                            },
                                          }}
                                        >
                                          <MenuItem value="mg">mg</MenuItem>
                                          <MenuItem value="g">g</MenuItem>
                                          <MenuItem value="mL">mL</MenuItem>
                                        </Select>
                                      </InputAdornment>
                                    ),
                                  }}
                                  sx={{
                                    minWidth: "150px",
                                    ".MuiOutlinedInput-root": {
                                      paddingRight: 0,
                                    },
                                  }}
                                />
                              </FormControl>
                            </Box>
                          </Grid>}
                          {!morning && <Grid item xs={12} sm={6} md={6} />}
                          {morning && <Grid item xs={12} sm={6} md={6}>
                            <CustomTimePicker />
                          </Grid>}
                          {!afternoon && <Grid item xs={12} sm={6} md={6} />}
                          {afternoon && <Grid item xs={12} sm={6} md={6} >
                            <CustomTimePicker />
                          </Grid>}

                          <Grid item xs={12} sm={6} md={6}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={() => setEvening(!evening)}
                                  />
                                }
                                label="Evening"
                              />
                            </FormGroup>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={() => setNight(!night)}
                                  />
                                }
                                label="Night"
                              />
                            </FormGroup>
                          </Grid>
                          {!evening && <Grid item xs={12} sm={6} md={6} />}
                          {evening && <Grid item xs={12} sm={6} md={6}>
                            <Box sx={{ paddingRight: 14 }}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Dose
                                </FormLabel>
                                <TextField
                                  variant="outlined"
                                  fullWidth
                                  value={temperature}
                                  onChange={handleTemperatureChange}
                                  type="number"
                                  size="small"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Select
                                          value={unit}
                                          onChange={handleUnitChange}
                                          size="small"
                                          displayEmpty
                                          inputProps={{
                                            "aria-label": "Without label",
                                          }}
                                          sx={{
                                            marginLeft: 1,
                                            ".MuiSelect-select": {
                                              // paddingRight: "0px",
                                            },
                                          }}
                                        >
                                          <MenuItem value="mg">mg</MenuItem>
                                          <MenuItem value="g">g</MenuItem>
                                          <MenuItem value="mL">mL</MenuItem>
                                        </Select>
                                      </InputAdornment>
                                    ),
                                  }}
                                  sx={{
                                    minWidth: "150px",
                                    ".MuiOutlinedInput-root": {
                                      paddingRight: 0,
                                    },
                                  }}
                                />
                              </FormControl>
                            </Box>
                          </Grid>}
                          {!night && <Grid item xs={12} sm={6} md={6} />}
                          {night && <Grid item xs={12} sm={6} md={6}>
                            <Box sx={{ paddingRight: 14 }}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Dose
                                </FormLabel>
                                <TextField
                                  variant="outlined"
                                  fullWidth
                                  value={temperature}
                                  onChange={handleTemperatureChange}
                                  type="number"
                                  size="small"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Select
                                          value={unit}
                                          onChange={handleUnitChange}
                                          size="small"
                                          displayEmpty
                                          inputProps={{
                                            "aria-label": "Without label",
                                          }}
                                          sx={{
                                            marginLeft: 1,
                                            ".MuiSelect-select": {
                                              // paddingRight: "0px",
                                            },
                                          }}
                                        >
                                          <MenuItem value="mg">mg</MenuItem>
                                          <MenuItem value="g">g</MenuItem>
                                          <MenuItem value="mL">mL</MenuItem>
                                        </Select>
                                      </InputAdornment>
                                    ),
                                  }}
                                  sx={{
                                    minWidth: "150px",
                                    ".MuiOutlinedInput-root": {
                                      paddingRight: 0,
                                    },
                                  }}
                                />
                              </FormControl>
                            </Box>
                          </Grid>}
                          {!evening && <Grid item xs={12} sm={6} md={6} />}
                          {evening && <Grid item xs={12} sm={6} md={6} >
                            <CustomTimePicker />
                          </Grid>}
                          {!night && <Grid item xs={12} sm={6} md={6} />}
                          {night && <Grid item xs={12} sm={6} md={6}>
                            <CustomTimePicker />
                          </Grid>}
                        </Grid>
                        <Grid container item spacing={2}>
                          <Grid item xs={12} sm={6} md={12}>
                            <Divider />
                            <Box className="note-container">
                    <Box>
                      {!showNoteNew && (
                        <Button
                          variant="text"
                          style={{ fontSize: "6px" }}
                          onClick={() => {
                            setShowNoteNew(true);
                          }}
                        >
                          <AddIcon className="add-icon" />
                          <span className="add-icon"> Note</span>
                        </Button>
                      )}
                    </Box>

                    {showNoteNew && (
                      <FormControl fullWidth>
                        <Box className="note-container-box">
                          <FormLabel
                            sx={{ paddingLeft: 1 }}
                            className="fileds-label-text"
                          >
                            Note
                          </FormLabel>
                          <Button
                            variant="text"
                            onClick={() => {
                              setShowNoteNew(false);
                            }}
                          >
                            <RemoveIcon className="remove-note" />
                            <span className="remove-note">Remove Note</span>
                          </Button>
                        </Box>
                        <ReactQuill
                          className="react-quill-alignment"
                          theme="snow"
                          value={""}
                          onChange={(e) => { }}
                        />
                      </FormControl>
                    )}
                  </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Formik>
                    :
                    <Box>
                      <Divider />
                      <Box className="summary-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
                    </Box>}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mb={2}>
          <Grid container item spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container template-hide-small-screen template-hide-medium-screen"
                style={{
                  border: "1px solid #0000001f",
                  borderRadius: 4,
                }}
              >
                <Box className="inner-title-container">
                  <ChiefComplaintsDraggableTable
                    header="General Instruction / Advise"
                    items={items}
                    // onDragEnd={onDragEnd}
                     onHandleSelectData={()=>{}}
                    //selected={selected}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              {/* <Box
                className="template-table-container"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                  minHeight: 320,
                }}
              >
                <Box className="inner-title-container">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="inner-header-container">
                      <Typography
                        className={selected ? "symptom-text" : ""}
                        style={{ fontWeight: 600 }}
                      >
                        {selected ? selected : "Prescription"}
                      </Typography>
                      {selected !== '' && <Typography>
                        <IconButton 
                        // onClick={() => setSelected("")} 
                        sx={{ padding: 0, margin: 0 }} aria-label="delete">
                          <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                        </IconButton>
                      </Typography>}
                    </Box>
                  </Paper>
                  {selected ?
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                        <Divider />
                        <Grid
                          container
                          item
                          spacing={2}
                          style={{ padding: 20 }}
                        >
                          <Grid item xs={12} sm={6} md={6}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={() => setMorning(!morning)}
                                  />
                                }
                                label="Morning"
                              />
                            </FormGroup>
                          </Grid>

                          <Grid item xs={12} sm={6} md={6}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={() => setAfternoon(!afternoon)}
                                  />
                                }
                                label="Afternoon"
                              />
                            </FormGroup>
                          </Grid>

                          {!morning && <Grid item xs={12} sm={6} md={6} />}
                          {morning &&
                            <Grid item xs={12} sm={6} md={6}>
                              <Box sx={{ paddingRight: 14 }}>
                                <FormControl fullWidth>
                                  <FormLabel className="fileds-label-text">
                                    Dose
                                  </FormLabel>
                                  <TextField
                                    variant="outlined"
                                    fullWidth
                                    value={temperature}
                                    onChange={handleTemperatureChange}
                                    type="number"
                                    size="small"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <Select
                                            value={unit}
                                            onChange={handleUnitChange}
                                            size="small"
                                            displayEmpty
                                            inputProps={{
                                              "aria-label": "Without label",
                                            }}
                                            sx={{
                                              marginLeft: 1,
                                              ".MuiSelect-select": {
                                                // paddingRight: "0px",
                                              },
                                            }}
                                          >
                                            <MenuItem value="mg">mg</MenuItem>
                                            <MenuItem value="g">g</MenuItem>
                                            <MenuItem value="mL">mL</MenuItem>
                                          </Select>
                                        </InputAdornment>
                                      ),
                                    }}
                                    sx={{
                                      minWidth: "150px",
                                      ".MuiOutlinedInput-root": {
                                        paddingRight: 0,
                                      },
                                    }}
                                  />
                                </FormControl>
                              </Box>
                            </Grid>
                          }
                          {!afternoon && <Grid item xs={12} sm={6} md={6} />}
                          {afternoon && <Grid item xs={12} sm={6} md={6}>
                            <Box sx={{ paddingRight: 14 }}>
                              <FormControl  >
                                <FormLabel className="fileds-label-text">
                                  Dose
                                </FormLabel>
                                <TextField
                                  variant="outlined"

                                  value={temperature}
                                  onChange={handleTemperatureChange}
                                  type="number"
                                  size="small"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Select
                                          value={unit}
                                          onChange={handleUnitChange}
                                          size="small"
                                          displayEmpty
                                          inputProps={{
                                            "aria-label": "Without label",
                                          }}
                                          sx={{
                                            marginLeft: 1,
                                            ".MuiSelect-select": {
                                              // paddingRight: "0px",
                                            },
                                          }}
                                        >
                                          <MenuItem value="mg">mg</MenuItem>
                                          <MenuItem value="g">g</MenuItem>
                                          <MenuItem value="mL">mL</MenuItem>
                                        </Select>
                                      </InputAdornment>
                                    ),
                                  }}
                                  sx={{
                                    minWidth: "150px",
                                    ".MuiOutlinedInput-root": {
                                      paddingRight: 0,
                                    },
                                  }}
                                />
                              </FormControl>
                            </Box>
                          </Grid>}
                          {!morning && <Grid item xs={12} sm={6} md={6} />}
                          {morning && <Grid item xs={12} sm={6} md={6}>
                            <CustomTimePicker />
                          </Grid>}
                          {!afternoon && <Grid item xs={12} sm={6} md={6} />}
                          {afternoon && <Grid item xs={12} sm={6} md={6} >
                            <CustomTimePicker />
                          </Grid>}

                          <Grid item xs={12} sm={6} md={6}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={() => setEvening(!evening)}
                                  />
                                }
                                label="Evening"
                              />
                            </FormGroup>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={() => setNight(!night)}
                                  />
                                }
                                label="Night"
                              />
                            </FormGroup>
                          </Grid>
                          {!evening && <Grid item xs={12} sm={6} md={6} />}
                          {evening && <Grid item xs={12} sm={6} md={6}>
                            <Box sx={{ paddingRight: 14 }}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Dose
                                </FormLabel>
                                <TextField
                                  variant="outlined"
                                  fullWidth
                                  value={temperature}
                                  onChange={handleTemperatureChange}
                                  type="number"
                                  size="small"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Select
                                          value={unit}
                                          onChange={handleUnitChange}
                                          size="small"
                                          displayEmpty
                                          inputProps={{
                                            "aria-label": "Without label",
                                          }}
                                          sx={{
                                            marginLeft: 1,
                                            ".MuiSelect-select": {
                                              // paddingRight: "0px",
                                            },
                                          }}
                                        >
                                          <MenuItem value="mg">mg</MenuItem>
                                          <MenuItem value="g">g</MenuItem>
                                          <MenuItem value="mL">mL</MenuItem>
                                        </Select>
                                      </InputAdornment>
                                    ),
                                  }}
                                  sx={{
                                    minWidth: "150px",
                                    ".MuiOutlinedInput-root": {
                                      paddingRight: 0,
                                    },
                                  }}
                                />
                              </FormControl>
                            </Box>
                          </Grid>}
                          {!night && <Grid item xs={12} sm={6} md={6} />}
                          {night && <Grid item xs={12} sm={6} md={6}>
                            <Box sx={{ paddingRight: 14 }}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Dose
                                </FormLabel>
                                <TextField
                                  variant="outlined"
                                  fullWidth
                                  value={temperature}
                                  onChange={handleTemperatureChange}
                                  type="number"
                                  size="small"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Select
                                          value={unit}
                                          onChange={handleUnitChange}
                                          size="small"
                                          displayEmpty
                                          inputProps={{
                                            "aria-label": "Without label",
                                          }}
                                          sx={{
                                            marginLeft: 1,
                                            ".MuiSelect-select": {
                                              // paddingRight: "0px",
                                            },
                                          }}
                                        >
                                          <MenuItem value="mg">mg</MenuItem>
                                          <MenuItem value="g">g</MenuItem>
                                          <MenuItem value="mL">mL</MenuItem>
                                        </Select>
                                      </InputAdornment>
                                    ),
                                  }}
                                  sx={{
                                    minWidth: "150px",
                                    ".MuiOutlinedInput-root": {
                                      paddingRight: 0,
                                    },
                                  }}
                                />
                              </FormControl>
                            </Box>
                          </Grid>}
                          {!evening && <Grid item xs={12} sm={6} md={6} />}
                          {evening && <Grid item xs={12} sm={6} md={6} >
                            <CustomTimePicker />
                          </Grid>}
                          {!night && <Grid item xs={12} sm={6} md={6} />}
                          {night && <Grid item xs={12} sm={6} md={6}>
                            <CustomTimePicker />
                          </Grid>}
                        </Grid>
                        <Grid container item spacing={2}>
                          <Grid item xs={12} sm={6} md={12}>
                            <Divider />
                            <Box className="note-container">
                              <Box>
                                {!showNote && (
                                  <Button
                                    variant="text"
                                    style={{ fontSize: "6px" }}
                                    onClick={() => {
                                      setShowNote(true);
                                    }}
                                  >
                                    <AddIcon className="add-icon" />
                                    <span className="add-icon"> Note</span>
                                  </Button>
                                )}
                              </Box>

                              {showNote && (
                                <FormControl fullWidth>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      alignSelf: "end",
                                      minWidth: "100%",
                                    }}
                                  >
                                    <FormLabel
                                      sx={{ paddingLeft: 1 }}
                                      className="fileds-label-text"
                                    >
                                      Note
                                    </FormLabel>
                                    <Button
                                      variant="text"
                                      onClick={() => {
                                        setShowNote(false);
                                      }}
                                    >
                                      <RemoveIcon className="remove-note" />
                                      <span className="remove-note">
                                        Remove Note
                                      </span>
                                    </Button>
                                  </Box>
                                  <ReactQuill
                                    className="react-quill-alignment"
                                    theme="snow"
                                    value={""}
                                    onChange={(e) => { }}
                                  />
                                </FormControl>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Formik>
                    :
                    <Box>
                      <Divider />
                      <Box>This is summary section </Box>
                    </Box>}
                </Box>
              </Box> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
          <Typography
                       
                        style={{ fontWeight: 600, color:"#495057" }}
                        sx={{ ml: 2, flex: 1 }}
                      >
                        {selected ? selected : "Prescription"}
                      </Typography>
                   
                        <IconButton onClick={() => handleClose(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                          <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                        </IconButton>
                     
          </Toolbar>
          <Divider />
        </AppBar>
        <DialogContent style={{ height: 400 }}>
        <Box
                className="template-table-container"
                sx={{
                  border: "0px solid #0000001f",
                  borderRadius: 1,
                  minHeight: 400,
                }}
              >
                <Box className="inner-title-container">
              
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                      
                        <Grid
                          container
                          item
                          spacing={2}
                          style={{ padding: 20 }}
                        >
                          <Grid item xs={12} sm={8} md={6}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={() => setMorning(!morning)}
                                  />
                                }
                                label="Morning"
                              />
                            </FormGroup>
                          </Grid>

                          {!morning && <Grid item xs={12} sm={4} md={6} />}
                          {morning &&
                            <Grid item xs={12} sm={6} md={6}>
                              <Box sx={{ paddingRight: 14 }}>
                                <FormControl fullWidth>
                                  <FormLabel className="fileds-label-text">
                                    Dose
                                  </FormLabel>
                                  <TextField
                                    variant="outlined"
                                    fullWidth
                                    value={temperature}
                                    onChange={handleTemperatureChange}
                                    type="number"
                                    size="small"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <Select
                                            value={unit}
                                            onChange={handleUnitChange}
                                            size="small"
                                            displayEmpty
                                            inputProps={{
                                              "aria-label": "Without label",
                                            }}
                                            sx={{
                                              marginLeft: 1,
                                              ".MuiSelect-select": {
                                                // paddingRight: "0px",
                                              },
                                            }}
                                          >
                                            <MenuItem value="mg">mg</MenuItem>
                                            <MenuItem value="g">g</MenuItem>
                                            <MenuItem value="mL">mL</MenuItem>
                                          </Select>
                                        </InputAdornment>
                                      ),
                                    }}
                                    sx={{
                                      minWidth: "150px",
                                      ".MuiOutlinedInput-root": {
                                        paddingRight: 0,
                                      },
                                    }}
                                  />
                                </FormControl>
                              </Box>
                            </Grid>
                          }
                          {!morning && <Grid item xs={12} sm={6} md={6} />}
                          {morning && <Grid item xs={12} sm={12} md={6}>
                            <CustomTimePicker />
                          </Grid>}
                          <Grid item xs={12} sm={8} md={6}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={() => setAfternoon(!afternoon)}
                                  />
                                }
                                label="Afternoon"
                              />
                            </FormGroup>
                          </Grid>

                          {!afternoon && <Grid item xs={12} sm={4} md={6} />}
                          {afternoon && <Grid item xs={12} sm={6} md={6}>
                            <Box sx={{ paddingRight: 14 }}>
                              <FormControl  >
                                <FormLabel className="fileds-label-text">
                                  Dose
                                </FormLabel>
                                <TextField
                                  variant="outlined"

                                  value={temperature}
                                  onChange={handleTemperatureChange}
                                  type="number"
                                  size="small"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Select
                                          value={unit}
                                          onChange={handleUnitChange}
                                          size="small"
                                          displayEmpty
                                          inputProps={{
                                            "aria-label": "Without label",
                                          }}
                                          sx={{
                                            marginLeft: 1,
                                            ".MuiSelect-select": {
                                              // paddingRight: "0px",
                                            },
                                          }}
                                        >
                                          <MenuItem value="mg">mg</MenuItem>
                                          <MenuItem value="g">g</MenuItem>
                                          <MenuItem value="mL">mL</MenuItem>
                                        </Select>
                                      </InputAdornment>
                                    ),
                                  }}
                                  sx={{
                                    minWidth: "150px",
                                    ".MuiOutlinedInput-root": {
                                      paddingRight: 0,
                                    },
                                  }}
                                />
                              </FormControl>
                            </Box>
                          </Grid>}
                          
                          {!afternoon && <Grid item xs={12} sm={6} md={6} />}
                          {afternoon && <Grid item xs={12} sm={12} md={6} >
                            <CustomTimePicker />
                          </Grid>}

                          <Grid item xs={12} sm={8} md={6}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={() => setEvening(!evening)}
                                  />
                                }
                                label="Evening"
                              />
                            </FormGroup>
                          </Grid>
                          {!evening && <Grid item xs={12} sm={4} md={6} />}
                          {evening && <Grid item xs={12} sm={6} md={6}>
                            <Box sx={{ paddingRight: 14 }}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Dose
                                </FormLabel>
                                <TextField
                                  variant="outlined"
                                  fullWidth
                                  value={temperature}
                                  onChange={handleTemperatureChange}
                                  type="number"
                                  size="small"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Select
                                          value={unit}
                                          onChange={handleUnitChange}
                                          size="small"
                                          displayEmpty
                                          inputProps={{
                                            "aria-label": "Without label",
                                          }}
                                          sx={{
                                            marginLeft: 1,
                                            ".MuiSelect-select": {
                                              // paddingRight: "0px",
                                            },
                                          }}
                                        >
                                          <MenuItem value="mg">mg</MenuItem>
                                          <MenuItem value="g">g</MenuItem>
                                          <MenuItem value="mL">mL</MenuItem>
                                        </Select>
                                      </InputAdornment>
                                    ),
                                  }}
                                  sx={{
                                    minWidth: "150px",
                                    ".MuiOutlinedInput-root": {
                                      paddingRight: 0,
                                    },
                                  }}
                                />
                              </FormControl>
                            </Box>
                          </Grid>}
                          {!evening && <Grid item xs={12} sm={6} md={6} />}
                          {evening && <Grid item xs={12} sm={12} md={6} >
                            <CustomTimePicker />
                          </Grid>}
                          <Grid item xs={12} sm={8} md={6}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={() => setNight(!night)}
                                  />
                                }
                                label="Night"
                              />
                            </FormGroup>
                          </Grid>
                         
                          {!night && <Grid item xs={12} sm={4} md={6} />}
                          {night && <Grid item xs={12} sm={6} md={6}>
                            <Box sx={{ paddingRight: 14 }}>
                              <FormControl fullWidth>
                                <FormLabel className="fileds-label-text">
                                  Dose
                                </FormLabel>
                                <TextField
                                  variant="outlined"
                                  fullWidth
                                  value={temperature}
                                  onChange={handleTemperatureChange}
                                  type="number"
                                  size="small"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Select
                                          value={unit}
                                          onChange={handleUnitChange}
                                          size="small"
                                          displayEmpty
                                          inputProps={{
                                            "aria-label": "Without label",
                                          }}
                                          sx={{
                                            marginLeft: 1,
                                            ".MuiSelect-select": {
                                              // paddingRight: "0px",
                                            },
                                          }}
                                        >
                                          <MenuItem value="mg">mg</MenuItem>
                                          <MenuItem value="g">g</MenuItem>
                                          <MenuItem value="mL">mL</MenuItem>
                                        </Select>
                                      </InputAdornment>
                                    ),
                                  }}
                                  sx={{
                                    minWidth: "150px",
                                    ".MuiOutlinedInput-root": {
                                      paddingRight: 0,
                                    },
                                  }}
                                />
                              </FormControl>
                            </Box>
                          </Grid>}
                         
                          {!night && <Grid item xs={12} sm={6} md={6} />}
                          {night && <Grid item xs={12} sm={12} md={6}>
                            <CustomTimePicker />
                          </Grid>}
                        </Grid>
                        <Grid container item spacing={2}>
                          <Grid item xs={12} sm={12} md={12}>
                            <Divider />
                            <Box className="note-container">
                              <Box>
                                {!showNote && (
                                  <Button
                                    variant="text"
                                    style={{ fontSize: "6px" }}
                                    onClick={() => {
                                      setShowNote(true);
                                    }}
                                  >
                                    <AddIcon className="add-icon" />
                                    <span className="add-icon"> Note</span>
                                  </Button>
                                )}
                              </Box>

                              {showNote && (
                                <FormControl fullWidth>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      alignSelf: "end",
                                      minWidth: "100%",
                                    }}
                                  >
                                    <FormLabel
                                      sx={{ paddingLeft: 1 }}
                                      className="fileds-label-text"
                                    >
                                      Note
                                    </FormLabel>
                                    <Button
                                      variant="text"
                                      onClick={() => {
                                        setShowNote(false);
                                      }}
                                    >
                                      <RemoveIcon className="remove-note" />
                                      <span className="remove-note">
                                        Remove Note
                                      </span>
                                    </Button>
                                  </Box>
                                  <ReactQuill
                                    className="react-quill-alignment"
                                    theme="snow"
                                    value={""}
                                    onChange={(e) => { }}
                                  />
                                </FormControl>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Formik>
                  
                </Box>
              </Box>
        </DialogContent>
      </Dialog>

      <Dialog
            fullScreen
            open={openPrescriptionSummary}
            onClose={handleClickClosePrescriptionSummary}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
                <Typography
                  // className={selected ? "symptom-text" : ""}
                  style={{ fontWeight: 600, marginLeft: 0 }}
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                >
                   Prescription Summary
                </Typography>
                <IconButton onClick={() => handleClickClosePrescriptionSummary(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                  <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent style={{ height: 400 }}>
            <Box className="template-table-container summary-dialog-mainContainer">
                      <Box className="summary-dialog-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
            </Box>
            </DialogContent>
          </Dialog>
    </Box >
  );
}
