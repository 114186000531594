import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import "../../../theme/style.scss";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  TextField,
  Typography,
  Toolbar,
  AppBar,
  Dialog,
  DialogContent,
  styled,
  Divider,
} from "@mui/material";
import Select from "react-select";
import Title from "../../../components/Title";
import LoadingButton from "@mui/lab/LoadingButton";
import DraggableList from "../../../components/DraggableTable/DraggableList";
import { DropResult } from "react-beautiful-dnd";
import { reorder } from "../../../Helpers/helpers";
import ReactQuill from 'react-quill';
import DialogActions from '@mui/material/DialogActions';
import DatePickerCompponent from "../../../components/CustomFields/DatePicker";
import CustomTimePicker from "../../../components/TimePicker/CustomTimePicker";
import { AsyncCustomSelect, CustomSelect } from "../../../components/CustomFields/CustomSelect";
import { Formik } from "formik";
import CustomBreadcrumbs from "../../../components/BreadcrumbsComponent/CustomBreadcrumbs";
import MedicineInstructionSave from "../../../pages/Medicine/_component/MedicineInstructionSave";

const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#FAFBFD", // Set the desired background color
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function MedicineMasterSave() {
  const [dialuentIsUsed, setDiluentIsUsed] = useState(false);
  const [morning, setMorning] = useState(false);
  const [afternoon, setAfternoon] = useState(false);
  const [evening, setEvening] = useState(false);
  const [night, setNight] = useState(false);
  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(itemsOther, source.index, destination.index);

    setItemsOther(newItems);
  };

  const [itemsOther, setItemsOther] = React.useState([
    {
      id: "Item1 1",
      primary: "1Practical Rubber Computer1",
      secondary: "1Synergistic leading edge matrices",
    },
    {
      id: "Item1 2",
      primary: "1Practical Rubber Computer2",
      secondary: "1Synergistic leading edge matrices",
    },
    {
      id: "Item1 3",
      primary: "1Practical Rubber Computer3",
      secondary: "1Synergistic leading edge matrices",
    },
  ]);

  const [openDialog, setOpenDialog] = React.useState(false);
  const onHandleOpenDialog = (item: Item) => {

    handleClickOpen(true);
    console.log("Opening dialog for item:", item);
  };
  const handleClickOpen = (value: boolean) => {
    setOpenDialog(value);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };
  const navigate = useNavigate();
  
  const [countryData, setCountry] = useState([
    { countryID: 0, code: "", name: "" },
    { countryID: 1, code: "123", name: "India" },
    { countryID: 2, code: "456", name: "USA" },
  ]);
  return (
    <Box className="main-container " pl={2}>
      <Grid xs={12} container className="page-header-save">
      <Grid xs={12} sm={12} item className="page-breadcrumbs">
          <CustomBreadcrumbs />
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
        }}
        onSubmit={async (values) => {
          console.log("values", values);
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} mb={2} className="group-container">
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Medicine Information</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl
                  sx={{
                    minWidth: "auto",
                  }}
                  fullWidth
                >
                  <FormLabel className="fileds-label-text">  Generic Name</FormLabel>
                  <AsyncCustomSelect
                    tabSelectsValue={false}
                    // openMenuOnFocus={
                    //   saleId <= 0
                    // }
                    isNotCreatable={false}
                    isInnerWidth={true}
                    // optionInnerWidth={
                    //   sourceProductCollection.length <
                    //     1
                    //     ? "837px !important"
                    //     : ""
                    // }
                    menuPortalTarget={document.querySelector("body")}
                    id={"followUpReason"}
                    isClearable={true}
                    FieldName={`followUpReason`}
                    // onFocus={(e) => {
                    //   setOpenCreateProduct({
                    //     ...openCreateProduct,
                    //     event: e,
                    //   });
                    // }}
                    // options={sourceProductCollection?.map(
                    //   (product) => {
                    //     if (product !== null) {
                    //       let allProduct = 0;
                    //       let currowquantity =
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]?.quantity;
                    //       props?.values?.saleDetailCollection?.map(
                    //         (rowData) => {
                    //           if (
                    //             rowData.productPriceId ===
                    //             product.productPriceId &&
                    //             rowData.isDeleted ===
                    //             false
                    //           ) {
                    //             allProduct =
                    //               allProduct +
                    //               rowData.quantity;
                    //           }
                    //         }
                    //       );
                    //       if (
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]
                    //           ?.productPriceId ===
                    //         product?.productPriceId
                    //       ) {
                    //         product.quantity =
                    //           product.orgquantity -
                    //           allProduct +
                    //           currowquantity;
                    //       } else {
                    //         product.quantity =
                    //           product.orgquantity -
                    //           allProduct;
                    //       }
                    //     }
                    //     return product;
                    //   }
                    // )}
                    // defaultOptions={sourceProductCollection
                    //   ?.map((product) => {
                    //     if (product !== null) {
                    //       let allProduct = 0;
                    //       let currowquantity =
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]?.quantity;
                    //       props?.values?.saleDetailCollection?.map(
                    //         (rowData) => {
                    //           if (
                    //             rowData?.productPriceId ===
                    //             product?.productPriceId &&
                    //             rowData?.isDeleted ===
                    //             false
                    //           ) {
                    //             allProduct =
                    //               allProduct +
                    //               rowData?.quantity;
                    //           }
                    //         }
                    //       );
                    //       if (
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]
                    //           ?.productPriceId ===
                    //         product?.productPriceId
                    //       ) {
                    //         product.quantity =
                    //           product?.orgquantity -
                    //           allProduct +
                    //           currowquantity;
                    //       } else {
                    //         product.quantity =
                    //           product?.orgquantity -
                    //           allProduct;
                    //       }
                    //     }
                    //     return product;
                    //   })
                    //   .slice(0, 7)}
                    // loadOptions={
                    //   loadProductOptions
                    // }
                    // onCreateOption={(value) => {
                    //   handleCreateProduct(
                    //     value,
                    //     `saleDetailCollection[${dataRow.rowNo}].productId`
                    //   );
                    // }}
                    // autoFocus={
                    //   dataRow.rowNo > 0 &&
                    //   saleData.saleDetailCollection !=
                    //   null &&
                    //   saleData
                    //     .saleDetailCollection[
                    //     dataRow.rowNo
                    //   ]?.productId < 1
                    // }
                    handleChange={(e) => {
                      // if (e) {
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productId`,
                      //     e.value !== null
                      //       ? e.productId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].taxCode`,
                      //     e.value !== null
                      //       ? e.taxCode
                      //       : "",
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productDescription`,
                      //     e.value !== null
                      //       ? e.description
                      //       : "",
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].unitId`,
                      //     e.value !== null
                      //       ? e.unitId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].taxId`,
                      //     e.value !== null
                      //       ? e.taxId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productpriceId`,
                      //     e.value !== null
                      //       ? e.productPriceId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productObj`,
                      //     e.value !== null
                      //       ? e
                      //       : null,
                      //     false
                      //   );
                      //   var pId: number =
                      //     +(e.value
                      //       ? e.productId
                      //       : 0);
                      //   handleSaleDetailChange(
                      //     "product",
                      //     pId,
                      //     dataRow.rowNo,
                      //     pId,
                      //     e.value ? e : "",
                      //     ''
                      //   );
                      // }
                    }}
                  // Value={ }
                  // CustomOptionComponent={  }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Brand Name</FormLabel>
                  <CustomTextField
                    name="name"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    Medicine Group
                  </FormLabel>
                  <Select
                    name="organizationType"
                    // options={options}
                    // value={selectedOption}
                    // onChange={handleSelectChange}
                    placeholder="Select"
                    styles={{
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: "0.813rem", // Set the desired placeholder font size here
                      }),
                      control: (base) => ({
                        ...base,
                        backgroundColor: "#FAFBFD",
                        border: "2px solid #cbcbda",
                        "&:hover": {
                          border: "2px solid #bdc1cd",
                        },
                        boxShadow: "none",
                      }),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl
                  sx={{
                    minWidth: "auto",
                  }}
                  fullWidth
                >
                  <FormLabel className="fileds-label-text">Used as</FormLabel>
                  <AsyncCustomSelect
                    tabSelectsValue={false}
                    // openMenuOnFocus={
                    //   saleId <= 0
                    // }
                    isNotCreatable={false}
                    isInnerWidth={true}
                    // optionInnerWidth={
                    //   sourceProductCollection.length <
                    //     1
                    //     ? "837px !important"
                    //     : ""
                    // }
                    menuPortalTarget={document.querySelector("body")}
                    id={"followUpReason"}
                    isClearable={true}
                    FieldName={`followUpReason`}
                    // onFocus={(e) => {
                    //   setOpenCreateProduct({
                    //     ...openCreateProduct,
                    //     event: e,
                    //   });
                    // }}
                    // options={sourceProductCollection?.map(
                    //   (product) => {
                    //     if (product !== null) {
                    //       let allProduct = 0;
                    //       let currowquantity =
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]?.quantity;
                    //       props?.values?.saleDetailCollection?.map(
                    //         (rowData) => {
                    //           if (
                    //             rowData.productPriceId ===
                    //             product.productPriceId &&
                    //             rowData.isDeleted ===
                    //             false
                    //           ) {
                    //             allProduct =
                    //               allProduct +
                    //               rowData.quantity;
                    //           }
                    //         }
                    //       );
                    //       if (
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]
                    //           ?.productPriceId ===
                    //         product?.productPriceId
                    //       ) {
                    //         product.quantity =
                    //           product.orgquantity -
                    //           allProduct +
                    //           currowquantity;
                    //       } else {
                    //         product.quantity =
                    //           product.orgquantity -
                    //           allProduct;
                    //       }
                    //     }
                    //     return product;
                    //   }
                    // )}
                    // defaultOptions={sourceProductCollection
                    //   ?.map((product) => {
                    //     if (product !== null) {
                    //       let allProduct = 0;
                    //       let currowquantity =
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]?.quantity;
                    //       props?.values?.saleDetailCollection?.map(
                    //         (rowData) => {
                    //           if (
                    //             rowData?.productPriceId ===
                    //             product?.productPriceId &&
                    //             rowData?.isDeleted ===
                    //             false
                    //           ) {
                    //             allProduct =
                    //               allProduct +
                    //               rowData?.quantity;
                    //           }
                    //         }
                    //       );
                    //       if (
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]
                    //           ?.productPriceId ===
                    //         product?.productPriceId
                    //       ) {
                    //         product.quantity =
                    //           product?.orgquantity -
                    //           allProduct +
                    //           currowquantity;
                    //       } else {
                    //         product.quantity =
                    //           product?.orgquantity -
                    //           allProduct;
                    //       }
                    //     }
                    //     return product;
                    //   })
                    //   .slice(0, 7)}
                    // loadOptions={
                    //   loadProductOptions
                    // }
                    // onCreateOption={(value) => {
                    //   handleCreateProduct(
                    //     value,
                    //     `saleDetailCollection[${dataRow.rowNo}].productId`
                    //   );
                    // }}
                    // autoFocus={
                    //   dataRow.rowNo > 0 &&
                    //   saleData.saleDetailCollection !=
                    //   null &&
                    //   saleData
                    //     .saleDetailCollection[
                    //     dataRow.rowNo
                    //   ]?.productId < 1
                    // }
                    handleChange={(e) => {
                      // if (e) {
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productId`,
                      //     e.value !== null
                      //       ? e.productId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].taxCode`,
                      //     e.value !== null
                      //       ? e.taxCode
                      //       : "",
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productDescription`,
                      //     e.value !== null
                      //       ? e.description
                      //       : "",
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].unitId`,
                      //     e.value !== null
                      //       ? e.unitId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].taxId`,
                      //     e.value !== null
                      //       ? e.taxId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productpriceId`,
                      //     e.value !== null
                      //       ? e.productPriceId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productObj`,
                      //     e.value !== null
                      //       ? e
                      //       : null,
                      //     false
                      //   );
                      //   var pId: number =
                      //     +(e.value
                      //       ? e.productId
                      //       : 0);
                      //   handleSaleDetailChange(
                      //     "product",
                      //     pId,
                      //     dataRow.rowNo,
                      //     pId,
                      //     e.value ? e : "",
                      //     ''
                      //   );
                      // }
                    }}
                  // Value={ }
                  // CustomOptionComponent={  }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl
                  sx={{
                    minWidth: "auto",
                  }}
                  fullWidth
                >
                  <FormLabel className="fileds-label-text">Formulation</FormLabel>
                  <AsyncCustomSelect
                    tabSelectsValue={false}
                    // openMenuOnFocus={
                    //   saleId <= 0
                    // }
                    isNotCreatable={false}
                    isInnerWidth={true}
                    // optionInnerWidth={
                    //   sourceProductCollection.length <
                    //     1
                    //     ? "837px !important"
                    //     : ""
                    // }
                    menuPortalTarget={document.querySelector("body")}
                    id={"followUpReason"}
                    isClearable={true}
                    FieldName={`followUpReason`}
                    // onFocus={(e) => {
                    //   setOpenCreateProduct({
                    //     ...openCreateProduct,
                    //     event: e,
                    //   });
                    // }}
                    // options={sourceProductCollection?.map(
                    //   (product) => {
                    //     if (product !== null) {
                    //       let allProduct = 0;
                    //       let currowquantity =
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]?.quantity;
                    //       props?.values?.saleDetailCollection?.map(
                    //         (rowData) => {
                    //           if (
                    //             rowData.productPriceId ===
                    //             product.productPriceId &&
                    //             rowData.isDeleted ===
                    //             false
                    //           ) {
                    //             allProduct =
                    //               allProduct +
                    //               rowData.quantity;
                    //           }
                    //         }
                    //       );
                    //       if (
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]
                    //           ?.productPriceId ===
                    //         product?.productPriceId
                    //       ) {
                    //         product.quantity =
                    //           product.orgquantity -
                    //           allProduct +
                    //           currowquantity;
                    //       } else {
                    //         product.quantity =
                    //           product.orgquantity -
                    //           allProduct;
                    //       }
                    //     }
                    //     return product;
                    //   }
                    // )}
                    // defaultOptions={sourceProductCollection
                    //   ?.map((product) => {
                    //     if (product !== null) {
                    //       let allProduct = 0;
                    //       let currowquantity =
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]?.quantity;
                    //       props?.values?.saleDetailCollection?.map(
                    //         (rowData) => {
                    //           if (
                    //             rowData?.productPriceId ===
                    //             product?.productPriceId &&
                    //             rowData?.isDeleted ===
                    //             false
                    //           ) {
                    //             allProduct =
                    //               allProduct +
                    //               rowData?.quantity;
                    //           }
                    //         }
                    //       );
                    //       if (
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]
                    //           ?.productPriceId ===
                    //         product?.productPriceId
                    //       ) {
                    //         product.quantity =
                    //           product?.orgquantity -
                    //           allProduct +
                    //           currowquantity;
                    //       } else {
                    //         product.quantity =
                    //           product?.orgquantity -
                    //           allProduct;
                    //       }
                    //     }
                    //     return product;
                    //   })
                    //   .slice(0, 7)}
                    // loadOptions={
                    //   loadProductOptions
                    // }
                    // onCreateOption={(value) => {
                    //   handleCreateProduct(
                    //     value,
                    //     `saleDetailCollection[${dataRow.rowNo}].productId`
                    //   );
                    // }}
                    // autoFocus={
                    //   dataRow.rowNo > 0 &&
                    //   saleData.saleDetailCollection !=
                    //   null &&
                    //   saleData
                    //     .saleDetailCollection[
                    //     dataRow.rowNo
                    //   ]?.productId < 1
                    // }
                    handleChange={(e) => {
                      // if (e) {
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productId`,
                      //     e.value !== null
                      //       ? e.productId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].taxCode`,
                      //     e.value !== null
                      //       ? e.taxCode
                      //       : "",
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productDescription`,
                      //     e.value !== null
                      //       ? e.description
                      //       : "",
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].unitId`,
                      //     e.value !== null
                      //       ? e.unitId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].taxId`,
                      //     e.value !== null
                      //       ? e.taxId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productpriceId`,
                      //     e.value !== null
                      //       ? e.productPriceId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productObj`,
                      //     e.value !== null
                      //       ? e
                      //       : null,
                      //     false
                      //   );
                      //   var pId: number =
                      //     +(e.value
                      //       ? e.productId
                      //       : 0);
                      //   handleSaleDetailChange(
                      //     "product",
                      //     pId,
                      //     dataRow.rowNo,
                      //     pId,
                      //     e.value ? e : "",
                      //     ''
                      //   );
                      // }
                    }}
                  // Value={ }
                  // CustomOptionComponent={  }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={1}>
                <FormControl
                  sx={{
                    minWidth: "auto",
                  }}
                  fullWidth
                >
                  <FormLabel className="fileds-label-text">Strength</FormLabel>
                  <AsyncCustomSelect
                    tabSelectsValue={false}
                    // openMenuOnFocus={
                    //   saleId <= 0
                    // }
                    isNotCreatable={false}
                    isInnerWidth={true}
                    // optionInnerWidth={
                    //   sourceProductCollection.length <
                    //     1
                    //     ? "837px !important"
                    //     : ""
                    // }
                    menuPortalTarget={document.querySelector("body")}
                    id={"followUpReason"}
                    isClearable={true}
                    FieldName={`followUpReason`}
                    // onFocus={(e) => {
                    //   setOpenCreateProduct({
                    //     ...openCreateProduct,
                    //     event: e,
                    //   });
                    // }}
                    // options={sourceProductCollection?.map(
                    //   (product) => {
                    //     if (product !== null) {
                    //       let allProduct = 0;
                    //       let currowquantity =
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]?.quantity;
                    //       props?.values?.saleDetailCollection?.map(
                    //         (rowData) => {
                    //           if (
                    //             rowData.productPriceId ===
                    //             product.productPriceId &&
                    //             rowData.isDeleted ===
                    //             false
                    //           ) {
                    //             allProduct =
                    //               allProduct +
                    //               rowData.quantity;
                    //           }
                    //         }
                    //       );
                    //       if (
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]
                    //           ?.productPriceId ===
                    //         product?.productPriceId
                    //       ) {
                    //         product.quantity =
                    //           product.orgquantity -
                    //           allProduct +
                    //           currowquantity;
                    //       } else {
                    //         product.quantity =
                    //           product.orgquantity -
                    //           allProduct;
                    //       }
                    //     }
                    //     return product;
                    //   }
                    // )}
                    // defaultOptions={sourceProductCollection
                    //   ?.map((product) => {
                    //     if (product !== null) {
                    //       let allProduct = 0;
                    //       let currowquantity =
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]?.quantity;
                    //       props?.values?.saleDetailCollection?.map(
                    //         (rowData) => {
                    //           if (
                    //             rowData?.productPriceId ===
                    //             product?.productPriceId &&
                    //             rowData?.isDeleted ===
                    //             false
                    //           ) {
                    //             allProduct =
                    //               allProduct +
                    //               rowData?.quantity;
                    //           }
                    //         }
                    //       );
                    //       if (
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]
                    //           ?.productPriceId ===
                    //         product?.productPriceId
                    //       ) {
                    //         product.quantity =
                    //           product?.orgquantity -
                    //           allProduct +
                    //           currowquantity;
                    //       } else {
                    //         product.quantity =
                    //           product?.orgquantity -
                    //           allProduct;
                    //       }
                    //     }
                    //     return product;
                    //   })
                    //   .slice(0, 7)}
                    // loadOptions={
                    //   loadProductOptions
                    // }
                    // onCreateOption={(value) => {
                    //   handleCreateProduct(
                    //     value,
                    //     `saleDetailCollection[${dataRow.rowNo}].productId`
                    //   );
                    // }}
                    // autoFocus={
                    //   dataRow.rowNo > 0 &&
                    //   saleData.saleDetailCollection !=
                    //   null &&
                    //   saleData
                    //     .saleDetailCollection[
                    //     dataRow.rowNo
                    //   ]?.productId < 1
                    // }
                    handleChange={(e) => {
                      // if (e) {
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productId`,
                      //     e.value !== null
                      //       ? e.productId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].taxCode`,
                      //     e.value !== null
                      //       ? e.taxCode
                      //       : "",
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productDescription`,
                      //     e.value !== null
                      //       ? e.description
                      //       : "",
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].unitId`,
                      //     e.value !== null
                      //       ? e.unitId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].taxId`,
                      //     e.value !== null
                      //       ? e.taxId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productpriceId`,
                      //     e.value !== null
                      //       ? e.productPriceId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productObj`,
                      //     e.value !== null
                      //       ? e
                      //       : null,
                      //     false
                      //   );
                      //   var pId: number =
                      //     +(e.value
                      //       ? e.productId
                      //       : 0);
                      //   handleSaleDetailChange(
                      //     "product",
                      //     pId,
                      //     dataRow.rowNo,
                      //     pId,
                      //     e.value ? e : "",
                      //     ''
                      //   );
                      // }
                    }}
                  // Value={ }
                  // CustomOptionComponent={  }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    Unit of Strength
                  </FormLabel>
                  <CustomTextField
                    name="name"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={1}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    Per Unit/Vol.
                  </FormLabel>
                  <CustomTextField
                    name="name"
                    placeholder=""
                    autoCapitalize="none"
                    type="number"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Unit/Volume</FormLabel>
                  <Select
                    name="organizationType"
                    // options={options}
                    // value={selectedOption}
                    // onChange={handleSelectChange}
                    placeholder="Select"
                    styles={{
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: "0.813rem",
                      }),
                      control: (base) => ({
                        ...base,
                        backgroundColor: "#FAFBFD",
                        border: "2px solid #cbcbda",
                        "&:hover": {
                          border: "2px solid #bdc1cd",
                        },
                        boxShadow: "none",
                      }),
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <FormControl
                  sx={{
                    minWidth: "auto",
                  }}
                  fullWidth
                >
                  <FormLabel className="fileds-label-text"> Company / Marketer Name</FormLabel>
                  <AsyncCustomSelect
                    tabSelectsValue={false}
                    // openMenuOnFocus={
                    //   saleId <= 0
                    // }
                    isNotCreatable={false}
                    isInnerWidth={true}
                    // optionInnerWidth={
                    //   sourceProductCollection.length <
                    //     1
                    //     ? "837px !important"
                    //     : ""
                    // }
                    menuPortalTarget={document.querySelector("body")}
                    id={"followUpReason"}
                    isClearable={true}
                    FieldName={`followUpReason`}
                    // onFocus={(e) => {
                    //   setOpenCreateProduct({
                    //     ...openCreateProduct,
                    //     event: e,
                    //   });
                    // }}
                    // options={sourceProductCollection?.map(
                    //   (product) => {
                    //     if (product !== null) {
                    //       let allProduct = 0;
                    //       let currowquantity =
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]?.quantity;
                    //       props?.values?.saleDetailCollection?.map(
                    //         (rowData) => {
                    //           if (
                    //             rowData.productPriceId ===
                    //             product.productPriceId &&
                    //             rowData.isDeleted ===
                    //             false
                    //           ) {
                    //             allProduct =
                    //               allProduct +
                    //               rowData.quantity;
                    //           }
                    //         }
                    //       );
                    //       if (
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]
                    //           ?.productPriceId ===
                    //         product?.productPriceId
                    //       ) {
                    //         product.quantity =
                    //           product.orgquantity -
                    //           allProduct +
                    //           currowquantity;
                    //       } else {
                    //         product.quantity =
                    //           product.orgquantity -
                    //           allProduct;
                    //       }
                    //     }
                    //     return product;
                    //   }
                    // )}
                    // defaultOptions={sourceProductCollection
                    //   ?.map((product) => {
                    //     if (product !== null) {
                    //       let allProduct = 0;
                    //       let currowquantity =
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]?.quantity;
                    //       props?.values?.saleDetailCollection?.map(
                    //         (rowData) => {
                    //           if (
                    //             rowData?.productPriceId ===
                    //             product?.productPriceId &&
                    //             rowData?.isDeleted ===
                    //             false
                    //           ) {
                    //             allProduct =
                    //               allProduct +
                    //               rowData?.quantity;
                    //           }
                    //         }
                    //       );
                    //       if (
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]
                    //           ?.productPriceId ===
                    //         product?.productPriceId
                    //       ) {
                    //         product.quantity =
                    //           product?.orgquantity -
                    //           allProduct +
                    //           currowquantity;
                    //       } else {
                    //         product.quantity =
                    //           product?.orgquantity -
                    //           allProduct;
                    //       }
                    //     }
                    //     return product;
                    //   })
                    //   .slice(0, 7)}
                    // loadOptions={
                    //   loadProductOptions
                    // }
                    // onCreateOption={(value) => {
                    //   handleCreateProduct(
                    //     value,
                    //     `saleDetailCollection[${dataRow.rowNo}].productId`
                    //   );
                    // }}
                    // autoFocus={
                    //   dataRow.rowNo > 0 &&
                    //   saleData.saleDetailCollection !=
                    //   null &&
                    //   saleData
                    //     .saleDetailCollection[
                    //     dataRow.rowNo
                    //   ]?.productId < 1
                    // }
                    handleChange={(e) => {
                      // if (e) {
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productId`,
                      //     e.value !== null
                      //       ? e.productId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].taxCode`,
                      //     e.value !== null
                      //       ? e.taxCode
                      //       : "",
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productDescription`,
                      //     e.value !== null
                      //       ? e.description
                      //       : "",
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].unitId`,
                      //     e.value !== null
                      //       ? e.unitId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].taxId`,
                      //     e.value !== null
                      //       ? e.taxId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productpriceId`,
                      //     e.value !== null
                      //       ? e.productPriceId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productObj`,
                      //     e.value !== null
                      //       ? e
                      //       : null,
                      //     false
                      //   );
                      //   var pId: number =
                      //     +(e.value
                      //       ? e.productId
                      //       : 0);
                      //   handleSaleDetailChange(
                      //     "product",
                      //     pId,
                      //     dataRow.rowNo,
                      //     pId,
                      //     e.value ? e : "",
                      //     ''
                      //   );
                      // }
                    }}
                  // Value={ }
                  // CustomOptionComponent={  }
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mb={2} className="group-container">
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Dosage Calculation</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        // defaultChecked
                        onChange={() => setDiluentIsUsed(!dialuentIsUsed)}
                      />
                    }
                    label="Diluent being used for dosage calculation"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={6} />

              {dialuentIsUsed && (
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl
                    sx={{
                      minWidth: "auto",
                    }}
                    fullWidth
                  >
                    <FormLabel className="fileds-label-text">  Name of Diluent</FormLabel>
                    <AsyncCustomSelect
                      tabSelectsValue={false}
                      // openMenuOnFocus={
                      //   saleId <= 0
                      // }
                      isNotCreatable={false}
                      isInnerWidth={true}
                      // optionInnerWidth={
                      //   sourceProductCollection.length <
                      //     1
                      //     ? "837px !important"
                      //     : ""
                      // }
                      menuPortalTarget={document.querySelector("body")}
                      id={"followUpReason"}
                      isClearable={true}
                      FieldName={`followUpReason`}
                      // onFocus={(e) => {
                      //   setOpenCreateProduct({
                      //     ...openCreateProduct,
                      //     event: e,
                      //   });
                      // }}
                      // options={sourceProductCollection?.map(
                      //   (product) => {
                      //     if (product !== null) {
                      //       let allProduct = 0;
                      //       let currowquantity =
                      //         props?.values
                      //           ?.saleDetailCollection[
                      //           dataRow.rowNo
                      //         ]?.quantity;
                      //       props?.values?.saleDetailCollection?.map(
                      //         (rowData) => {
                      //           if (
                      //             rowData.productPriceId ===
                      //             product.productPriceId &&
                      //             rowData.isDeleted ===
                      //             false
                      //           ) {
                      //             allProduct =
                      //               allProduct +
                      //               rowData.quantity;
                      //           }
                      //         }
                      //       );
                      //       if (
                      //         props?.values
                      //           ?.saleDetailCollection[
                      //           dataRow.rowNo
                      //         ]
                      //           ?.productPriceId ===
                      //         product?.productPriceId
                      //       ) {
                      //         product.quantity =
                      //           product.orgquantity -
                      //           allProduct +
                      //           currowquantity;
                      //       } else {
                      //         product.quantity =
                      //           product.orgquantity -
                      //           allProduct;
                      //       }
                      //     }
                      //     return product;
                      //   }
                      // )}
                      // defaultOptions={sourceProductCollection
                      //   ?.map((product) => {
                      //     if (product !== null) {
                      //       let allProduct = 0;
                      //       let currowquantity =
                      //         props?.values
                      //           ?.saleDetailCollection[
                      //           dataRow.rowNo
                      //         ]?.quantity;
                      //       props?.values?.saleDetailCollection?.map(
                      //         (rowData) => {
                      //           if (
                      //             rowData?.productPriceId ===
                      //             product?.productPriceId &&
                      //             rowData?.isDeleted ===
                      //             false
                      //           ) {
                      //             allProduct =
                      //               allProduct +
                      //               rowData?.quantity;
                      //           }
                      //         }
                      //       );
                      //       if (
                      //         props?.values
                      //           ?.saleDetailCollection[
                      //           dataRow.rowNo
                      //         ]
                      //           ?.productPriceId ===
                      //         product?.productPriceId
                      //       ) {
                      //         product.quantity =
                      //           product?.orgquantity -
                      //           allProduct +
                      //           currowquantity;
                      //       } else {
                      //         product.quantity =
                      //           product?.orgquantity -
                      //           allProduct;
                      //       }
                      //     }
                      //     return product;
                      //   })
                      //   .slice(0, 7)}
                      // loadOptions={
                      //   loadProductOptions
                      // }
                      // onCreateOption={(value) => {
                      //   handleCreateProduct(
                      //     value,
                      //     `saleDetailCollection[${dataRow.rowNo}].productId`
                      //   );
                      // }}
                      // autoFocus={
                      //   dataRow.rowNo > 0 &&
                      //   saleData.saleDetailCollection !=
                      //   null &&
                      //   saleData
                      //     .saleDetailCollection[
                      //     dataRow.rowNo
                      //   ]?.productId < 1
                      // }
                      handleChange={(e) => {
                        // if (e) {
                        //   props.setFieldValue(
                        //     `saleDetailCollection[${dataRow.rowNo}].productId`,
                        //     e.value !== null
                        //       ? e.productId
                        //       : 0,
                        //     false
                        //   );
                        //   props.setFieldValue(
                        //     `saleDetailCollection[${dataRow.rowNo}].taxCode`,
                        //     e.value !== null
                        //       ? e.taxCode
                        //       : "",
                        //     false
                        //   );
                        //   props.setFieldValue(
                        //     `saleDetailCollection[${dataRow.rowNo}].productDescription`,
                        //     e.value !== null
                        //       ? e.description
                        //       : "",
                        //     false
                        //   );
                        //   props.setFieldValue(
                        //     `saleDetailCollection[${dataRow.rowNo}].unitId`,
                        //     e.value !== null
                        //       ? e.unitId
                        //       : 0,
                        //     false
                        //   );
                        //   props.setFieldValue(
                        //     `saleDetailCollection[${dataRow.rowNo}].taxId`,
                        //     e.value !== null
                        //       ? e.taxId
                        //       : 0,
                        //     false
                        //   );
                        //   props.setFieldValue(
                        //     `saleDetailCollection[${dataRow.rowNo}].productpriceId`,
                        //     e.value !== null
                        //       ? e.productPriceId
                        //       : 0,
                        //     false
                        //   );
                        //   props.setFieldValue(
                        //     `saleDetailCollection[${dataRow.rowNo}].productObj`,
                        //     e.value !== null
                        //       ? e
                        //       : null,
                        //     false
                        //   );
                        //   var pId: number =
                        //     +(e.value
                        //       ? e.productId
                        //       : 0);
                        //   handleSaleDetailChange(
                        //     "product",
                        //     pId,
                        //     dataRow.rowNo,
                        //     pId,
                        //     e.value ? e : "",
                        //     ''
                        //   );
                        // }
                      }}
                    // Value={ }
                    // CustomOptionComponent={  }
                    />
                  </FormControl>
                </Grid>
              )}
              {dialuentIsUsed && (<Grid item xs={12} sm={6} md={9} />)}
              {dialuentIsUsed && (
                <Grid item xs={12} sm={6} md={1}>
                  <FormControl fullWidth>
                    <FormLabel className="fileds-label-text">
                      Vol. of Diluent
                    </FormLabel>
                    <CustomTextField
                      name="code"
                      placeholder=""
                      autoCapitalize="none"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </FormControl>
                </Grid>
              )}
              {dialuentIsUsed && (
                <Grid item xs={12} sm={6} md={2}>
                  <FormControl fullWidth>
                    <FormLabel className="fileds-label-text">
                      Unit of Diluent Volume
                    </FormLabel>
                    <Select
                      name="organizationType"
                      // options={options}
                      // value={selectedOption}
                      // onChange={handleSelectChange}
                      placeholder="Select"
                      styles={{
                        placeholder: (provided) => ({
                          ...provided,
                          fontSize: "0.813rem", // Set the desired placeholder font size here
                        }),
                        control: (base) => ({
                          ...base,
                          backgroundColor: "#FAFBFD",
                          border: "2px solid #cbcbda",
                          "&:hover": {
                            border: "2px solid #bdc1cd",
                          },
                          boxShadow: "none",
                        }),
                      }}
                    />
                  </FormControl>
                </Grid>
              )}
              {dialuentIsUsed && <Grid item xs={12} sm={6} md={9} />}

              {dialuentIsUsed && (
                <Grid item xs={12} sm={6} md={1}>
                  <FormControl fullWidth>
                    <FormLabel className="fileds-label-text">
                      Concentration
                    </FormLabel>
                    <CustomTextField
                      name="code"
                      placeholder=""
                      autoCapitalize="none"
                      type="number"
                      fullWidth
                      size="small"
                    />
                  </FormControl>
                </Grid>
              )}
              {dialuentIsUsed && (
                <Grid item xs={12} sm={6} md={2}>
                  <FormControl fullWidth>
                    <FormLabel className="fileds-label-text">
                      Unit of Concentration
                    </FormLabel>
                    <Select
                      name="organizationType"
                      // options={options}
                      // value={selectedOption}
                      // onChange={handleSelectChange}
                      placeholder="Select"
                      styles={{
                        placeholder: (provided) => ({
                          ...provided,
                          fontSize: "0.813rem", // Set the desired placeholder font size here
                        }),
                        control: (base) => ({
                          ...base,
                          backgroundColor: "#FAFBFD",
                          border: "2px solid #cbcbda",
                          "&:hover": {
                            border: "2px solid #bdc1cd",
                          },
                          boxShadow: "none",
                        }),
                      }}
                    />
                  </FormControl>
                </Grid>
              )}
              {dialuentIsUsed && <Grid item xs={12} sm={6} md={9} />}

              <Grid item xs={12} sm={6} md={1}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    Dose
                    {/* of the Drug */}
                  </FormLabel>
                  <CustomTextField
                    name="code"
                    placeholder=""
                    autoCapitalize="none"
                    type="number"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    Unit of the Dose
                  </FormLabel>
                  <Select
                    name="organizationType"
                    // options={options}
                    // value={selectedOption}
                    // onChange={handleSelectChange}
                    placeholder="Select"
                    styles={{
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: "0.813rem", // Set the desired placeholder font size here
                      }),
                      control: (base) => ({
                        ...base,
                        backgroundColor: "#FAFBFD",
                        border: "2px solid #cbcbda",
                        "&:hover": {
                          border: "2px solid #bdc1cd",
                        },
                        boxShadow: "none",
                      }),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={9} />

              <Grid item xs={12} sm={6} md={1}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    Frequency
                    {/* of medicine */}
                  </FormLabel>
                  <Select
                    name="organizationType"
                    // options={options}
                    // value={selectedOption}
                    // onChange={handleSelectChange}
                    placeholder="Select"
                    styles={{
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: "0.813rem", // Set the desired placeholder font size here
                      }),
                      control: (base) => ({
                        ...base,
                        backgroundColor: "#FAFBFD",
                        border: "2px solid #cbcbda",
                        "&:hover": {
                          border: "2px solid #bdc1cd",
                        },
                        boxShadow: "none",
                      }),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    Unit of Frequency
                  </FormLabel>
                  <Select
                    name="organizationType"
                    // options={options}
                    // value={selectedOption}
                    // onChange={handleSelectChange}
                    placeholder="Select"
                    styles={{
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: "0.813rem", // Set the desired placeholder font size here
                      }),
                      control: (base) => ({
                        ...base,
                        backgroundColor: "#FAFBFD",
                        border: "2px solid #cbcbda",
                        "&:hover": {
                          border: "2px solid #bdc1cd",
                        },
                        boxShadow: "none",
                      }),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={9} />

              <Grid item xs={12} sm={6} md={1}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    Duration
                    {/* of Medicine */}
                  </FormLabel>
                  <Select
                    name="organizationType"
                    // options={options}
                    // value={selectedOption}
                    // onChange={handleSelectChange}
                    placeholder="Select"
                    styles={{
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: "0.813rem", // Set the desired placeholder font size here
                      }),
                      control: (base) => ({
                        ...base,
                        backgroundColor: "#FAFBFD",
                        border: "2px solid #cbcbda",
                        "&:hover": {
                          border: "2px solid #bdc1cd",
                        },
                        boxShadow: "none",
                      }),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    Unit of Duration
                  </FormLabel>
                  <Select
                    name="organizationType"
                    // options={options}
                    // value={selectedOption}
                    // onChange={handleSelectChange}
                    placeholder="Select"
                    styles={{
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: "0.813rem", // Set the desired placeholder font size here
                      }),
                      control: (base) => ({
                        ...base,
                        backgroundColor: "#FAFBFD",
                        border: "2px solid #cbcbda",
                        "&:hover": {
                          border: "2px solid #bdc1cd",
                        },
                        boxShadow: "none",
                      }),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={9} />

              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    Print Unit of Drug
                  </FormLabel>
                  <Select
                    name="organizationType"
                    // options={options}
                    // value={selectedOption}
                    // onChange={handleSelectChange}
                    placeholder="Select"
                    styles={{
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: "0.813rem", // Set the desired placeholder font size here
                      }),
                      control: (base) => ({
                        ...base,
                        backgroundColor: "#FAFBFD",
                        border: "2px solid #cbcbda",
                        "&:hover": {
                          border: "2px solid #bdc1cd",
                        },
                        boxShadow: "none",
                      }),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={9} />

              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    Route of Administration
                  </FormLabel>
                  <Select
                    name="organizationType"
                    // options={options}
                    // value={selectedOption}
                    // onChange={handleSelectChange}
                    placeholder="Select"
                    styles={{
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: "0.813rem", // Set the desired placeholder font size here
                      }),
                      control: (base) => ({
                        ...base,
                        backgroundColor: "#FAFBFD",
                        border: "2px solid #cbcbda",
                        "&:hover": {
                          border: "2px solid #bdc1cd",
                        },
                        boxShadow: "none",
                      }),
                    }}
                  />
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    Schedule of Giving Medicine
                  </FormLabel>
                  <Select
                    name="organizationType"
                    // options={options}
                    // value={selectedOption}
                    // onChange={handleSelectChange}
                    placeholder="Select"
                    styles={{
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: "0.813rem", // Set the desired placeholder font size here
                      }),
                      control: (base) => ({
                        ...base,
                        backgroundColor: "#FAFBFD",
                        border: "2px solid #cbcbda",
                        "&:hover": {
                          border: "2px solid #bdc1cd",
                        },
                        boxShadow: "none",
                      }),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div>
                  <Box style={{ fontSize: 12 }}>
                    Exact time of Giving Medicine
                  </Box>
                  <FormControl sx={{ width: "100% !important" }}>
                    <DatePickerCompponent
                      format="DD-MM-YYYY"
                      mode="date"
                      slotProps={{ textField: { size: "small" } }}
                    />
                  </FormControl>
                </div>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={12} mb={2} className="group-container">
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Medicine Schedule</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl
                  sx={{
                    minWidth: "auto",
                  }}
                  fullWidth
                >
                  <FormLabel className="fileds-label-text"> Relation to food</FormLabel>
                  <AsyncCustomSelect
                    tabSelectsValue={false}
                    // openMenuOnFocus={
                    //   saleId <= 0
                    // }
                    isNotCreatable={false}
                    isInnerWidth={true}
                    // optionInnerWidth={
                    //   sourceProductCollection.length <
                    //     1
                    //     ? "837px !important"
                    //     : ""
                    // }
                    menuPortalTarget={document.querySelector("body")}
                    id={"followUpReason"}
                    isClearable={true}
                    FieldName={`followUpReason`}
                    // onFocus={(e) => {
                    // }}
                    // options={ }
                    // defaultOptions={ }
                    // loadOptions={
                    //   loadProductOptions
                    // }
                    // onCreateOption={(value) => {  }}
                    // autoFocus={ }
                    handleChange={(e) => { }}
                  // Value={ }
                  // CustomOptionComponent={  }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl
                  sx={{
                    minWidth: "auto",
                  }}
                  fullWidth
                >
                  <FormLabel className="fileds-label-text"> Relation to sleep</FormLabel>
                  <AsyncCustomSelect
                    tabSelectsValue={false}
                    // openMenuOnFocus={
                    //   saleId <= 0
                    // }
                    isNotCreatable={false}
                    isInnerWidth={true}
                    // optionInnerWidth={
                    //   sourceProductCollection.length <
                    //     1
                    //     ? "837px !important"
                    //     : ""
                    // }
                    menuPortalTarget={document.querySelector("body")}
                    id={"followUpReason"}
                    isClearable={true}
                    FieldName={`followUpReason`}
                    // onFocus={(e) => {
                    //   setOpenCreateProduct({
                    //     ...openCreateProduct,
                    //     event: e,
                    //   });
                    // }}
                    // options={sourceProductCollection?.map(
                    //   (product) => {
                    //     if (product !== null) {
                    //       let allProduct = 0;
                    //       let currowquantity =
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]?.quantity;
                    //       props?.values?.saleDetailCollection?.map(
                    //         (rowData) => {
                    //           if (
                    //             rowData.productPriceId ===
                    //             product.productPriceId &&
                    //             rowData.isDeleted ===
                    //             false
                    //           ) {
                    //             allProduct =
                    //               allProduct +
                    //               rowData.quantity;
                    //           }
                    //         }
                    //       );
                    //       if (
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]
                    //           ?.productPriceId ===
                    //         product?.productPriceId
                    //       ) {
                    //         product.quantity =
                    //           product.orgquantity -
                    //           allProduct +
                    //           currowquantity;
                    //       } else {
                    //         product.quantity =
                    //           product.orgquantity -
                    //           allProduct;
                    //       }
                    //     }
                    //     return product;
                    //   }
                    // )}
                    // defaultOptions={sourceProductCollection
                    //   ?.map((product) => {
                    //     if (product !== null) {
                    //       let allProduct = 0;
                    //       let currowquantity =
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]?.quantity;
                    //       props?.values?.saleDetailCollection?.map(
                    //         (rowData) => {
                    //           if (
                    //             rowData?.productPriceId ===
                    //             product?.productPriceId &&
                    //             rowData?.isDeleted ===
                    //             false
                    //           ) {
                    //             allProduct =
                    //               allProduct +
                    //               rowData?.quantity;
                    //           }
                    //         }
                    //       );
                    //       if (
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]
                    //           ?.productPriceId ===
                    //         product?.productPriceId
                    //       ) {
                    //         product.quantity =
                    //           product?.orgquantity -
                    //           allProduct +
                    //           currowquantity;
                    //       } else {
                    //         product.quantity =
                    //           product?.orgquantity -
                    //           allProduct;
                    //       }
                    //     }
                    //     return product;
                    //   })
                    //   .slice(0, 7)}
                    // loadOptions={
                    //   loadProductOptions
                    // }
                    // onCreateOption={(value) => {
                    //   handleCreateProduct(
                    //     value,
                    //     `saleDetailCollection[${dataRow.rowNo}].productId`
                    //   );
                    // }}
                    // autoFocus={
                    //   dataRow.rowNo > 0 &&
                    //   saleData.saleDetailCollection !=
                    //   null &&
                    //   saleData
                    //     .saleDetailCollection[
                    //     dataRow.rowNo
                    //   ]?.productId < 1
                    // }
                    handleChange={(e) => {
                      // if (e) {
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productId`,
                      //     e.value !== null
                      //       ? e.productId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].taxCode`,
                      //     e.value !== null
                      //       ? e.taxCode
                      //       : "",
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productDescription`,
                      //     e.value !== null
                      //       ? e.description
                      //       : "",
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].unitId`,
                      //     e.value !== null
                      //       ? e.unitId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].taxId`,
                      //     e.value !== null
                      //       ? e.taxId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productpriceId`,
                      //     e.value !== null
                      //       ? e.productPriceId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productObj`,
                      //     e.value !== null
                      //       ? e
                      //       : null,
                      //     false
                      //   );
                      //   var pId: number =
                      //     +(e.value
                      //       ? e.productId
                      //       : 0);
                      //   handleSaleDetailChange(
                      //     "product",
                      //     pId,
                      //     dataRow.rowNo,
                      //     pId,
                      //     e.value ? e : "",
                      //     ''
                      //   );
                      // }
                    }}
                  // Value={ }
                  // CustomOptionComponent={  }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">
                    {" "}
                    Time Gap with food
                  </FormLabel>
                  <CustomTextField
                    name="branchName"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl
                  sx={{
                    minWidth: "auto",
                  }}
                  fullWidth
                >
                  <FormLabel className="fileds-label-text">  Unit of time gap</FormLabel>
                  <AsyncCustomSelect
                    tabSelectsValue={false}
                    // openMenuOnFocus={
                    //   saleId <= 0
                    // }
                    isNotCreatable={false}
                    isInnerWidth={true}
                    // optionInnerWidth={
                    //   sourceProductCollection.length <
                    //     1
                    //     ? "837px !important"
                    //     : ""
                    // }
                    menuPortalTarget={document.querySelector("body")}
                    id={"followUpReason"}
                    isClearable={true}
                    FieldName={`followUpReason`}
                    // onFocus={(e) => {
                    //   setOpenCreateProduct({
                    //     ...openCreateProduct,
                    //     event: e,
                    //   });
                    // }}
                    // options={sourceProductCollection?.map(
                    //   (product) => {
                    //     if (product !== null) {
                    //       let allProduct = 0;
                    //       let currowquantity =
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]?.quantity;
                    //       props?.values?.saleDetailCollection?.map(
                    //         (rowData) => {
                    //           if (
                    //             rowData.productPriceId ===
                    //             product.productPriceId &&
                    //             rowData.isDeleted ===
                    //             false
                    //           ) {
                    //             allProduct =
                    //               allProduct +
                    //               rowData.quantity;
                    //           }
                    //         }
                    //       );
                    //       if (
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]
                    //           ?.productPriceId ===
                    //         product?.productPriceId
                    //       ) {
                    //         product.quantity =
                    //           product.orgquantity -
                    //           allProduct +
                    //           currowquantity;
                    //       } else {
                    //         product.quantity =
                    //           product.orgquantity -
                    //           allProduct;
                    //       }
                    //     }
                    //     return product;
                    //   }
                    // )}
                    // defaultOptions={sourceProductCollection
                    //   ?.map((product) => {
                    //     if (product !== null) {
                    //       let allProduct = 0;
                    //       let currowquantity =
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]?.quantity;
                    //       props?.values?.saleDetailCollection?.map(
                    //         (rowData) => {
                    //           if (
                    //             rowData?.productPriceId ===
                    //             product?.productPriceId &&
                    //             rowData?.isDeleted ===
                    //             false
                    //           ) {
                    //             allProduct =
                    //               allProduct +
                    //               rowData?.quantity;
                    //           }
                    //         }
                    //       );
                    //       if (
                    //         props?.values
                    //           ?.saleDetailCollection[
                    //           dataRow.rowNo
                    //         ]
                    //           ?.productPriceId ===
                    //         product?.productPriceId
                    //       ) {
                    //         product.quantity =
                    //           product?.orgquantity -
                    //           allProduct +
                    //           currowquantity;
                    //       } else {
                    //         product.quantity =
                    //           product?.orgquantity -
                    //           allProduct;
                    //       }
                    //     }
                    //     return product;
                    //   })
                    //   .slice(0, 7)}
                    // loadOptions={
                    //   loadProductOptions
                    // }
                    // onCreateOption={(value) => {
                    //   handleCreateProduct(
                    //     value,
                    //     `saleDetailCollection[${dataRow.rowNo}].productId`
                    //   );
                    // }}
                    // autoFocus={
                    //   dataRow.rowNo > 0 &&
                    //   saleData.saleDetailCollection !=
                    //   null &&
                    //   saleData
                    //     .saleDetailCollection[
                    //     dataRow.rowNo
                    //   ]?.productId < 1
                    // }
                    handleChange={(e) => {
                      // if (e) {
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productId`,
                      //     e.value !== null
                      //       ? e.productId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].taxCode`,
                      //     e.value !== null
                      //       ? e.taxCode
                      //       : "",
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productDescription`,
                      //     e.value !== null
                      //       ? e.description
                      //       : "",
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].unitId`,
                      //     e.value !== null
                      //       ? e.unitId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].taxId`,
                      //     e.value !== null
                      //       ? e.taxId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productpriceId`,
                      //     e.value !== null
                      //       ? e.productPriceId
                      //       : 0,
                      //     false
                      //   );
                      //   props.setFieldValue(
                      //     `saleDetailCollection[${dataRow.rowNo}].productObj`,
                      //     e.value !== null
                      //       ? e
                      //       : null,
                      //     false
                      //   );
                      //   var pId: number =
                      //     +(e.value
                      //       ? e.productId
                      //       : 0);
                      //   handleSaleDetailChange(
                      //     "product",
                      //     pId,
                      //     dataRow.rowNo,
                      //     pId,
                      //     e.value ? e : "",
                      //     ''
                      //   );
                      // }
                    }}
                  // Value={ }
                  // CustomOptionComponent={  }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        // defaultChecked
                        onChange={() => setMorning(!morning)}
                      />
                    }
                    label="Morning"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        // defaultChecked
                        onChange={() => setAfternoon(!afternoon)}
                      />
                    }
                    label="Afternoon"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        // defaultChecked
                        onChange={() => setEvening(!evening)}
                      />
                    }
                    label="Evening"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        // defaultChecked
                        onChange={() => setNight(!night)}
                      />
                    }
                    label="Night"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4} />
              {!morning && <Grid item xs={12} sm={6} md={2} />}
              {morning && <Grid item xs={12} sm={6} md={2}>
                <CustomTimePicker />
              </Grid>}
              {!afternoon && <Grid item xs={12} sm={6} md={2} />}
              {afternoon && <Grid item xs={12} sm={6} md={2} >
                <CustomTimePicker />
              </Grid>}
              {!evening && <Grid item xs={12} sm={6} md={2} />}
              {evening && <Grid item xs={12} sm={6} md={2} >
                <CustomTimePicker />
              </Grid>}
              {!night && <Grid item xs={12} sm={6} md={2} />}
              {night && <Grid item xs={12} sm={6} md={2}>
                <CustomTimePicker />
              </Grid>}
            </Grid>
          </Grid>
          <Grid item xs={12} mb={2} className="group-container">
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Instruction(s)</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box
                  className="template-table-container"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    border: "1px solid #0000001f",
                    borderRadius: 4,
                  }}
                >
                  <Box className="inner-title-container">
                    <Box className="template-box">
                      <Typography>Medicine Instruction(s)</Typography>
                    </Box>
                    <DraggableList items={itemsOther} onDragEnd={onDragEnd} onHandleOpenDialog={onHandleOpenDialog} />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}></Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
              <LoadingButton
                color="primary"
                tabIndex={3}
                loadingPosition="start"
                startIcon={<></>}
                variant="contained"
                type="submit"
                onClick={() => navigate("/master/medicine-master")}
                className="primary-button"
                width="180px"
                name="submit"
              >
                SAVE
              </LoadingButton>
              <LoadingButton
                color="secondary"
                tabIndex={3}
                loadingPosition="start"
                startIcon={<></>}
                variant="outlined"
                type="submit"
                className="secondary-button"
                name="submit"
                onClick={() => navigate("/master/medicine-master")}
              >
                CANCEL
              </LoadingButton>
            </Box>
          </Grid>

          <Dialog
            className="dialog1"
            open={openDialog}
            onClose={handleClose}
            fullScreen
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                width: "1200px", // Adjust the width as needed
                maxWidth: "none",
              },
            }}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
                <Typography
                  // className={selected ? "symptom-text" : ""}
                  style={{ fontWeight: 600, marginLeft: 0 }}
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                >
                  Medicine Edit
                </Typography>
              </Toolbar>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </AppBar>
            <DialogContent className="padding-remove-Investigation-Edit main-dialog-background-color">
              <MedicineInstructionSave />
            </DialogContent>
            <Divider />
            <DialogActions className="padding-add-investigation-templete-save-button">
              <Grid item xs={12}>
                <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
                  <LoadingButton
                    color="primary"
                    tabIndex={3}
                    loadingPosition="start"
                    startIcon={<></>}
                    variant="contained"
                    type="submit"
                    onClick={handleClose}
                    className="primary-button"
                    name="submit"
                  >
                    Save
                  </LoadingButton>
                  <LoadingButton
                    tabIndex={3}
                    loadingPosition="start"
                    startIcon={<></>}
                    variant="outlined"
                    type="submit"
                    name="submit"
                    className="secondary-button"
                    color="secondary"
                    onClick={handleClose}                >
                    Cancel
                  </LoadingButton>
                </Box>
              </Grid>
            </DialogActions>
          </Dialog>
        </Grid>
      </Formik >
    </Box >
  );
}
