import "../../../../../theme/style.scss";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  styled,
  Dialog,
  DialogContent,
  List,
  ListItem
} from "@mui/material";
import React, { useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { reorder } from "../../../../../Helpers/helpers";
import { Formik } from "formik";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ChiefComplaintsDraggableTable from "../ChiefComplaints/ChiefComplaintsDraggable/ChiefComplaintsDraggableTable";
import DraggableList from "../../../../../components/DraggableTable/DraggableList";
import TeamMembersDraggableList from "../../../../../components/DraggableTable/TeamMembersDraggableList";
import AnesthesiologistDraggableList from "../../../../../components/DraggableTable/AnesthesiologistDraggableList"
import DatePickerCompponent from "../../../../../components/CustomFields/DatePicker";
import { CustomSelect } from "../../../../../components/CustomFields/CustomSelect";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from '@mui/material/useMediaQuery'
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import SubjectRoundedIcon from "@mui/icons-material/SubjectRounded";
import ReactQuill from "react-quill";
import EditIcon from '@mui/icons-material/Edit';

const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#FAFBFD",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function Procedure() {
  const [showNote, setShowNote] = useState(false);
  const [showNoteNew, setShowNoteNew] = useState(false);
  const [selected, setSelected] = useState("");
  const smallScreen = useMediaQuery("(max-width:768px)");
  const mobileScreen = useMediaQuery("(max-width:425px)");
  //Draggable Table
  const [items, setItems] = React.useState([
    {
      id: "Item 1",
      symptom: " Essential hypertension",
    },
    {
      id: "Item 2",
      symptom: "Diabetes mellitus",
    },
    {
      id: "Item 3",
      symptom: "Osteoarthritis",
    },
    {
      id: "Item 4",
      symptom: "Acute respiratory infections",
    },
    {
      id: "Item 5",
      symptom: "Joint disorders",
    },
  ]);
  const [isAnesthesiaUsed, setIsAnesthesiaUsed] = useState('no')

  const [countryData, setCountry] = useState([
    { countryID: 0, code: "", name: "" },
    { countryID: 1, code: "123", name: "India" },
    { countryID: 2, code: "456", name: "USA" },
  ]);
  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return;

    const newItems = reorder(items, source.index, destination.index);

    setItems(newItems);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (value: boolean) => {

    if(smallScreen){
      
      setOpen(value);
    } else if(mobileScreen){

      setOpen(value);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onHandleSelectData = (val) => {
    if (smallScreen || mobileScreen) {

      handleClickOpen(true);
    }
    setSelected(val);
  };

  const [itemsOther, setItemsOther] = React.useState([
    {
      id: "Item1 1",
      primary: "1Practical Rubber Computer1",
      secondary: "1Synergistic leading edge matrices",
    },
    {
      id: "Item1 2",
      primary: "1Practical Rubber Computer2",
      secondary: "1Synergistic leading edge matrices",
    },
    {
      id: "Item1 3",
      primary: "1Practical Rubber Computer3",
      secondary: "1Synergistic leading edge matrices",
    },
  ]);

  const onDragEnd1 = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(itemsOther, source.index, destination.index);

    setItemsOther(newItems);
  };
  
  const [openProcedureSummary, setopenProcedureSummary] = React.useState(false);

  const handleClickOpenProcedureSummary = (value: boolean) => {
    if (mobileScreen) {
      setopenProcedureSummary(value);
    }
    else if (smallScreen) {
      setopenProcedureSummary(value);
    }
  };

  const handleClickCloseProcedureSummary = () => {
    setopenProcedureSummary(false);
  };

  return (
    <Box className="main-container">
      <Grid container spacing={2}>
        <Grid item xs={12} mb={2}>
          <Grid container item spacing={2}>
          <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
            <Grid item xs={12} sm={12} md={6} sx={{paddingLeft:"0px"}}>
            <Box
                className="template-table-container"
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                }}
              >
                <Box className="inner-title-container">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box className="inner-header-container"
                    sx={{display:"flex",  justifyContent:"space-between"}}
                    >
                      <Typography style={{ fontWeight: 600 }}>
                        Procedure
                      </Typography>
                      {mobileScreen || smallScreen ? <Box>
                        <Button onClick={() => handleClickOpenProcedureSummary(true)} sx={{ padding: 0, margin: 0, minWidth:"30px"}} aria-label="delete">
                         <SubjectRoundedIcon></SubjectRoundedIcon>
                        </Button>
              </Box> : ''}
                    </Box>
                  </Paper>
                  <Divider />
                  <List>
                    {items.map((item: any, index: number) => (
                      <ListItem
                        sx={{
                          borderBottom: "1px solid #E9EBEC",
                          cursor: 'pointer'
                        }}
                        className={
                          selected === item.symptom
                            ? "draggable-list-item"
                            : " "
                        }
                      >
                        <Grid container item spacing={2}>
                          <Grid item xs={12} sm={12} md={12}>
                            <Box
                              style={{
                                display: "flex",
                              }}
                              onClick = {()=>{handleClickOpen(true)}}
                            >
                              <Grid container item>
                                <Grid item xs={12} sm={12} md={12}>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() => {
                                      setSelected(item.symptom)
                                    }}
                                  >
                                    <Box
                                      className={
                                        selected === item.symptom
                                          ? "symptom-text"
                                          : " "
                                      }
                                      sx={{ paddingBottom: '8px', paddingTop: '8px' }}
                                    
                                      >
                                      <Typography>{item.symptom}</Typography>
                                    </Box>

                                    {selected === item.symptom && (
                                      <Box
                                        style={{
                                          paddingTop: "8px",
                                          paddingLeft: '8px'
                                        }}
                                      >
                                        <KeyboardDoubleArrowRightRoundedIcon />
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                        <Box>
                        <IconButton>
                             <EditIcon sx={{ color: "rgb(65, 77, 85)" }} />
                        </IconButton>
                        </Box>
                        <Box>
                        <IconButton>
                           <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                              </IconButton>
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                
        <Box style={{ padding: 10, paddingRight: 24, paddingLeft: 24 }}>
          <FormControl fullWidth>
          <CustomSelect
                                // isNotCreatable={true}
                                id={"admission"}
                                FieldName={"admission"}
                                options={[]}
                                placeholder="Search..."
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e);
                                }}
                                Value=""
                              />
          </FormControl>
        </Box>
        <Divider />
                  <Box className="note-container">
                    <Box>
                      {!showNote && (
                        <Button
                          variant="text"
                          style={{ fontSize: "6px" }}
                          onClick={() => {
                            setShowNote(true);
                          }}
                        >
                          <AddIcon className="add-icon" />
                          <span className="add-icon"> Note</span>
                        </Button>
                      )}
                    </Box>

                    {showNote && (
                      <FormControl fullWidth>
                        <Box className="note-container-box">
                          <FormLabel
                            sx={{ paddingLeft: 1 }}
                            className="fileds-label-text"
                          >
                            Note
                          </FormLabel>
                          <Button
                            variant="text"
                            onClick={() => {
                              setShowNote(false);
                            }}
                          >
                            <RemoveIcon className="remove-note" />
                            <span className="remove-note">Remove Note</span>
                          </Button>
                        </Box>
                        <ReactQuill
                          className="react-quill-alignment"
                          theme="snow"
                          value={""}
                          onChange={(e) => { }}
                        />
                      </FormControl>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            </Formik>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                className="template-table-container  template-hide-small-screen template-hide-medium-screen "
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                  minHeight: 400,
                }}
              >
                <Box className="inner-title-container">
                  <Paper
                    style={{
                      flex: 1,
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="inner-header-container">
                      <Typography
                        className={selected ? "symptom-text" : ""}
                        style={{ fontWeight: 600 }}
                      >
                        {selected ? selected : "Procedure Summary"}
                      </Typography>
                      {selected !== '' && <Typography>
                        <IconButton onClick={() => setSelected("")} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                          <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                        </IconButton>
                      </Typography>}
                    </Box>
                  </Paper>
                  {selected ?
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                        <Divider />
                        <Grid
                          container
                          item
                          spacing={2}
                          style={{ padding: 20 }}
                        >
                          <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Tempalte Name
                              </FormLabel>
                              <CustomTextField
                                name="branchName"
                                placeholder=""
                                autoCapitalize="none"
                                type="text"
                                fullWidth
                                size="small"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} />
                          <Grid item xs={12} sm={6}>
                            <div >
                              <Box style={{ fontSize: 12 }}>Date</Box>
                              <FormControl sx={{ width: '100% !important' }}>
                                <DatePickerCompponent
                                  format="DD-MM-YYYY"
                                  mode="date"
                                  slotProps={{ textField: { size: 'small' } }}
                                />
                              </FormControl>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Time
                              </FormLabel>
                              <CustomTextField
                                name="branchName"
                                placeholder=""
                                autoCapitalize="none"
                                type="text"
                                fullWidth
                                size="small"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl>
                              <FormLabel id="demo-row-radio-buttons-group-label" className="fileds-label-text">Anesthesia used for procedure</FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                onChange={(e) => setIsAnesthesiaUsed(e.target.value)}
                                value={isAnesthesiaUsed}
                              >
                                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="no" control={<Radio />} label="No " />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          {isAnesthesiaUsed === 'yes' && <Grid item xs={12} sm={9} />}
                          {isAnesthesiaUsed === 'yes' && <Grid item xs={12} sm={12}>
                          <Box
                              className="template-table-container"
                              style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                border: '1px solid #0000001f',
                                borderRadius: 4
                              }}
                            >
                              <Box
                                style={{
                                  flex: 1,
                                  borderRadius: 8
                                }}>
                                <Box
                                  className='template-box'
                                >
                                  <Typography>
                                    Type of Anesthesia
                                  </Typography>
                                </Box>
                                <Divider />
                                <AnesthesiologistDraggableList
                                  items={itemsOther}
                                  onDragEnd={onDragEnd1}
                                />
                              </Box>
                            </Box>
                          </Grid>}
                          <Grid item xs={12} sm={6} />
                          <Grid item xs={12} sm={6} md={12}>
                            <Box
                              className="template-table-container"
                              style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                border: '1px solid #0000001f',
                                borderRadius: 4
                              }}
                            >
                              <Box
                                style={{
                                  flex: 1,
                                  borderRadius: 8
                                }}>
                                <Box
                                  className='template-box'
                                >
                                  <Typography>
                                    Team Members
                                  </Typography>
                                </Box>
                                <Divider />
                                <TeamMembersDraggableList
                                  items={itemsOther}
                                  onDragEnd={onDragEnd1}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container item spacing={2}>
                          <Grid item xs={12} sm={6} md={12}>
                            <Divider />
                            <Box className="note-container">
                    <Box>
                      {!showNoteNew && (
                        <Button
                          variant="text"
                          style={{ fontSize: "6px" }}
                          onClick={() => {
                            setShowNoteNew(true);
                          }}
                        >
                          <AddIcon className="add-icon" />
                          <span className="add-icon"> Note</span>
                        </Button>
                      )}
                    </Box>

                    {showNoteNew && (
                      <FormControl fullWidth>
                        <Box className="note-container-box">
                          <FormLabel
                            sx={{ paddingLeft: 1 }}
                            className="fileds-label-text"
                          >
                            Note
                          </FormLabel>
                          <Button
                            variant="text"
                            onClick={() => {
                              setShowNoteNew(false);
                            }}
                          >
                            <RemoveIcon className="remove-note" />
                            <span className="remove-note">Remove Note</span>
                          </Button>
                        </Box>
                        <ReactQuill
                          className="react-quill-alignment"
                          theme="snow"
                          value={""}
                          onChange={(e) => { }}
                        />
                      </FormControl>
                    )}
                  </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Formik>
                    :
                    <Box>
                      <Divider />
                      <Box className="summary-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
                    </Box>}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar
          sx={{ display:"flex",
            justifyContent:"space-between"}}
          >
          <Typography
                      
                        style={{ fontWeight: 600, color:"#495057" }}
                        sx={{
                          paddingLeft:{
                          xs:1,
                          sm:0,
                         }
                      }}
                      >
                        {selected ? selected : "Procedure"}
                      </Typography>
                      <IconButton onClick={() => handleClose(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                          <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                        </IconButton>
         
          </Toolbar>
          <Divider />
        </AppBar>
        <DialogContent style={{ height: 400 }}>
        <Box
                className="template-table-container "
                sx={{
                  border: "0px solid #0000001f",
                  borderRadius: 1,
                  minHeight: 400,
                }}
              >
                <Box className="inner-title-container">
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >
                      <Box>
                  
                        <Grid
                          container
                          item
                          spacing={2}
                    
                        >
                          <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Tempalte Name
                              </FormLabel>
                              <CustomTextField
                                name="branchName"
                                placeholder=""
                                autoCapitalize="none"
                                type="text"
                                fullWidth
                                size="small"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} />
                          <Grid item xs={12} sm={6}>
                            <div >
                              <Box style={{ fontSize: 12 }}>Date</Box>
                              <FormControl sx={{ width: '100% !important' }}>
                                <DatePickerCompponent
                                  format="DD-MM-YYYY"
                                  mode="date"
                                  slotProps={{ textField: { size: 'small' } }}
                                />
                              </FormControl>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                              <FormLabel className="fileds-label-text">
                                Time
                              </FormLabel>
                              <CustomTextField
                                name="branchName"
                                placeholder=""
                                autoCapitalize="none"
                                type="text"
                                fullWidth
                                size="small"
                              />
                            </FormControl>
                          
                          </Grid>
                         
                          <Grid item xs={12} sm={6}>
                            <FormControl>
                              <FormLabel id="demo-row-radio-buttons-group-label" className="fileds-label-text">Anesthesia used for procedure</FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                onChange={(e) => setIsAnesthesiaUsed(e.target.value)}
                                value={isAnesthesiaUsed}
                              >
                                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="no" control={<Radio />} label="No " />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          {isAnesthesiaUsed === 'yes' && <Grid item xs={12} sm={9} />}
                          {isAnesthesiaUsed === 'yes' && <Grid item xs={12} sm={12}>
                          <Box
                              className="template-table-container"
                              style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                border: '1px solid #0000001f',
                                borderRadius: 4
                              }}
                            >
                              <Box
                                style={{
                                  flex: 1,
                                  borderRadius: 8
                                }}>
                                <Box
                                  className='template-box'
                                >
                                  <Typography>
                                    Type of Anesthesia
                                  </Typography>
                                </Box>
                                <Divider />
                                <AnesthesiologistDraggableList
                                  items={itemsOther}
                                  onDragEnd={onDragEnd1}
                                />
                              </Box>
                            </Box>
                          </Grid>}
                         
                          <Grid item xs={12} sm={6} />
                          <Grid item xs={12} sm={12} md={12}>
                            <Box
                              className="template-table-container"
                              style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                border: '1px solid #0000001f',
                                borderRadius: 4
                              }}
                            >
                              <Box
                                style={{
                                  flex: 1,
                                  borderRadius: 8
                                }}>
                                <Box
                                  className='template-box'
                                >
                                  <Typography>
                                    Team Members
                                  </Typography>
                                </Box>
                                <Divider />
                                <TeamMembersDraggableList
                                  items={itemsOther}
                                  onDragEnd={onDragEnd1}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container item spacing={2}>
                          <Grid item xs={12} sm={12} md={12}>
                            <Divider />
                            <Box className="note-container">
                              <Box>
                                {!showNote && (
                                  <Button
                                    variant="text"
                                    style={{ fontSize: "6px" }}
                                    onClick={() => {
                                      setShowNote(true);
                                    }}
                                  >
                                    <AddIcon className="add-icon" />
                                    <span className="add-icon"> Note</span>
                                  </Button>
                                )}
                              </Box>

                              {showNote && (
                                <FormControl fullWidth>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      alignSelf: "end",
                                      minWidth: "100%",
                                    }}
                                  >
                                    <FormLabel
                                      sx={{ paddingLeft: 1 }}
                                      className="fileds-label-text"
                                    >
                                      Note
                                    </FormLabel>
                                    <Button
                                      variant="text"
                                      onClick={() => {
                                        setShowNote(false);
                                      }}
                                    >
                                      <RemoveIcon className="remove-note" />
                                      <span className="remove-note">
                                        Remove Note
                                      </span>
                                    </Button>
                                  </Box>
                                  <CustomTextField
                                    name="templateName"
                                    placeholder=""
                                    autoCapitalize="none"
                                    type="text"
                                    multiline
                                    rows={4}
                                    fullWidth
                                    size="small"
                                  />
                                </FormControl>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Formik>
                  
                </Box>
              </Box>
        </DialogContent>
      </Dialog>
      <Dialog
            fullScreen
            open={openProcedureSummary}
            onClose={handleClickCloseProcedureSummary}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
                <Typography
                  // className={selected ? "symptom-text" : ""}
                  style={{ fontWeight: 600, marginLeft: 0 }}
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                >
              Procedure Summary
                </Typography>
                <IconButton onClick={() => handleClickCloseProcedureSummary(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                  <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent style={{ height: 400 }}>
            <Box className="template-table-container summary-dialog-mainContainer">
                      <Box className="summary-dialog-container">
                      <img src="./no-summary.png" alt="" className="summary-img"/>
                      </Box>
                      <div className="summary-text">No summary found</div>
                      <div className="summary-text1">This is some random text will be gone here...</div>
            </Box>
            </DialogContent>
          </Dialog>
    </Box >
  );
}
