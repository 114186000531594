import React,{useState} from 'react';
import { useNavigate } from 'react-router-dom';
import '../../theme/style.scss';
import { Box,  FormControl,  FormLabel, Grid, MenuItem, Select, TextField, Typography, styled } from '@mui/material';
import Title from '../../components/Title';
import LoadingButton from '@mui/lab/LoadingButton';
import DragDropFile from '../../components/DragDropFile/DragDropFile';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css';
import CustomBreadcrumb from '../../components/BreadcrumbsComponent/CustomBreadcrumbs';


const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: '#FAFBFD', // Set the desired background color
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.grey[300],
      borderWidth: '1px',
      BorderStyle: 'solid'
    },
    '&:hover fieldset': {
      borderColor: theme.palette.grey[500],
      borderWidth: '1px',
      BorderStyle: 'solid'
    },
    '&.Mui-focused fieldset': {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));


export default function OrganizationSave() {
  const [value, setValue] = useState('');

  const navigate = useNavigate()
  return (
    <Box className="main-container " pl={2}>
      <Grid xs={12} container className='page-header-save'>
      <Grid xs={12} sm={12} md={12} item className="page-breadcrumbs">
            <CustomBreadcrumb />
          </Grid>
      </Grid>
      <Grid container spacing={2}  >
        <Grid item xs={12} mb={4} className="group-container">
          <Grid container item spacing={2} >
            <Grid item xs={12} >
              <Typography variant='h6'>Basic Information</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>Code</FormLabel>
                <CustomTextField
                  name="code"
                  placeholder=""
                  autoCapitalize='none'
                  type="text"
                  fullWidth
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}></Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>Organization Name*</FormLabel>
                <Select
                  name="type"
                  // options={options}
                  // value={selectedOption}
                  // onChange={handleSelectChange}
                  placeholder="Select"
                  size='small'
                  styles={{
                    placeholder: (provided) => ({
                      ...provided,
                      fontSize: '0.813rem', // Set the desired placeholder font size here
                    }),
                    control: (base) => ({
                      ...base,
                      backgroundColor: '#FAFBFD',
                      border: '2px solid #cbcbda',
                      '&:hover': {
                        border: '2px solid #bdc1cd',
                      },
                      boxShadow: 'none',
                    }),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}></Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>Type</FormLabel>
                <Select
                  name="type"
                  // options={options}
                  // value={selectedOption}
                  // onChange={handleSelectChange}
                  placeholder="Select"
                  size='small'
                  styles={{
                    placeholder: (provided) => ({
                      ...provided,
                      fontSize: '0.813rem', // Set the desired placeholder font size here
                    }),
                    control: (base) => ({
                      ...base,
                      backgroundColor: '#FAFBFD',
                      border: '2px solid #cbcbda',
                      '&:hover': {
                        border: '2px solid #bdc1cd',
                      },
                      boxShadow: 'none',
                    }),
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
            <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>Business as*</FormLabel>
                <Select
                  name="businessAs"
                  // options={options}
                  // value={selectedOption}
                  // onChange={handleSelectChange}
                  size='small'
                  placeholder="Select"
                  styles={{
                    placeholder: (provided) => ({
                      ...provided,
                      fontSize: '0.813rem', // Set the desired placeholder font size here
                    }),
                    control: (base) => ({
                      ...base,
                      backgroundColor: '#FAFBFD',
                      border: '2px solid #cbcbda',
                      '&:hover': {
                        border: '2px solid #bdc1cd',
                      },
                      boxShadow: 'none',
                    }),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
            <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>GST Number</FormLabel>
                <CustomTextField
                  name="gstNumber"
                  placeholder=""
                  autoCapitalize='none'
                  type="text"
                  fullWidth
                  size="small"
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mb={4} className="group-container" >
          <Grid container item spacing={2}>
            <Grid item xs={12} >
              <Typography variant='h6'>Contact Information</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>Authorize Person</FormLabel>
                <CustomTextField
                  name="authorizePerson"
                  placeholder=""
                  autoCapitalize='none'
                  type="text"
                  fullWidth
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>Address 1</FormLabel>
                <CustomTextField
                  name="address1"
                  placeholder=""
                  autoCapitalize='none'
                  type="text"
                  fullWidth
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>Address 2</FormLabel>
                <CustomTextField
                  name="address2"
                  placeholder=""
                  autoCapitalize='none'
                  type="text"
                  fullWidth
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>City</FormLabel>
                <CustomTextField
                  name="city"
                  placeholder=""
                  autoCapitalize='none'
                  type="text"
                  fullWidth
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>Postal Code</FormLabel>
                <CustomTextField
                  name="postalCode"
                  placeholder=""
                  autoCapitalize='none'
                  type="text"
                  fullWidth
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>Country</FormLabel>
                <Select
                  name="city"
                  // options={cityOptions}
                  // value={cityOptions}
                  // onChange={handleSelectChange}
                  size="small"
                  placeholder="Select"
                  styles={{
                    placeholder: (provided) => ({
                      ...provided,
                      fontSize: '0.813rem', // Set the desired placeholder font size here
                    }),
                    control: (base) => ({
                      ...base,
                      backgroundColor: '#FAFBFD',
                      border: '2px solid #cbcbda',
                      '&:hover': {
                        border: '2px solid #bdc1cd',
                      },
                      boxShadow: 'none',
                    }),
                  }}
                >
                  <MenuItem value={'Ahmadabad'}>India</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>State</FormLabel>
                <Select
                  name="city"
                  // options={cityOptions}
                  // value={cityOptions}
                  // onChange={handleSelectChange}
                  size="small"
                  placeholder="Select"
                  styles={{
                    placeholder: (provided) => ({
                      ...provided,
                      fontSize: '0.813rem', // Set the desired placeholder font size here
                    }),
                    control: (base) => ({
                      ...base,
                      backgroundColor: '#FAFBFD',
                      border: '2px solid #cbcbda',
                      '&:hover': {
                        border: '2px solid #bdc1cd',
                      },
                      boxShadow: 'none',
                    }),
                  }}
                >
                  <MenuItem value={'Ahmadabad'}>Gujarat</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div className="input-group">
                <label htmlFor="phone">Mobile Number</label>
                <PhoneInput
                  id="phone"
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="IN"
                  value={value}
                  onChange={setValue}
                  placeholder="Enter phone number"
                  className="phone-input"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>Email</FormLabel>
                <CustomTextField
                  name="email"
                  placeholder=""
                  autoCapitalize='none'
                  type="email"
                  fullWidth
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>Website</FormLabel>
                <CustomTextField
                  name="website"
                  placeholder=""
                  autoCapitalize='none'
                  type="text"
                  fullWidth
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>

            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mb={4} className="group-container" >
          <Grid container item spacing={2}>
            <Grid item xs={12} >
              <Typography variant='h6'>Other</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>PAN Number</FormLabel>
                <CustomTextField
                  name="panNumber"
                  placeholder=""
                  autoCapitalize='none'
                  type="text"
                  fullWidth
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>Drug License Number</FormLabel>
                <CustomTextField
                  name="dlNumber"
                  placeholder=""
                  autoCapitalize='none'
                  type="text"
                  fullWidth
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>Company Identification Number</FormLabel>
                <CustomTextField
                  name="cinNumber"
                  placeholder=""
                  autoCapitalize='none'
                  type="email"
                  fullWidth
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>Sonography Number</FormLabel>
                <CustomTextField
                  name="sonographyNumber"
                  placeholder=""
                  autoCapitalize='none'
                  type="text"
                  fullWidth
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>PF Number</FormLabel>
                <CustomTextField
                  name="pfNumber"
                  placeholder=""
                  autoCapitalize='none'
                  type="text"
                  fullWidth
                  size="small"
                />

              </FormControl>
            </Grid>
            {/* <Grid item xs={12} sm={6}>

            </Grid> */}
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>Upload Logo</FormLabel>
                <DragDropFile
                  // onFileAccepted={handleFileAccepted}
                />
                {/* {selectedFile && (
                  <Typography variant="body2" sx={{ marginTop: 1 }}>
                    {selectedFile.name}
                  </Typography>
                )} */}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>Upload Stamp</FormLabel>
                <DragDropFile
                  // onFileAccepted={handleFileAccepted}
                />
                {/* {selectedFile && (
                  <Typography variant="body2" sx={{ marginTop: 1 }}>
                    {selectedFile.name}
                  </Typography>
                )} */}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>Upload Signaure</FormLabel>
                <DragDropFile
                  // onFileAccepted={handleFileAccepted}
                />
                {/* {selectedFile && (
                  <Typography variant="body2" sx={{ marginTop: 1 }}>
                    {selectedFile.name}
                  </Typography>
                )} */}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>

            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} mb={4} className="group-container">
          <Grid container item spacing={2} >
            <Grid item xs={12} >
              <Typography variant='h6'>Subscription </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>Max Branch</FormLabel>
                <CustomTextField
                  name="max user"
                  placeholder=""
                  autoCapitalize='none'
                  type="text"
                  fullWidth
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <FormLabel className='fileds-label-text'>Max User</FormLabel>
                <CustomTextField
                  name="max user"
                  placeholder=""
                  autoCapitalize='none'
                  type="text"
                  fullWidth
                  size="small"
                />
              </FormControl>
            </Grid>         
          </Grid>
        </Grid>

        
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'end', gap: 2 }}>
            <LoadingButton
              color="primary"
              tabIndex={3}
              loadingPosition="start"
              startIcon={<></>}
              variant="contained"
              type="submit"
              className='primary-button'
              width="180px"
              name="submit">
              Save
            </LoadingButton>
            <LoadingButton
              color="primary"
              tabIndex={3}
              loadingPosition="start"
              startIcon={<></>}
              variant="outlined"
              type="submit"
              className='secondary-button'
              name="submit"
              onClick={() => navigate('/settings/organization')}
            >
              Cancel
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}