import "../../../../../../theme/style.scss";
import React, { FC, useState } from "react";
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  List,
  Paper,
  TextField,
  Typography,
  styled,
  DialogContent,
} from "@mui/material";
import { Item } from "../../../../../../Helpers/typings";
import ChiefComplaintsDraggableTableItem from "./ChiefComplaintsDraggableTableItem";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ReactQuill from "react-quill";
import SubjectRoundedIcon from '@mui/icons-material/SubjectRounded';
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from '@mui/material/useMediaQuery';
import { CustomSelect } from "../../../../../../components/CustomFields/CustomSelect";
import { Formik } from "formik";

const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#FAFBFD",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));

export type Props = {
  header: string;
  items: Item[];
  onDragEnd: OnDragEndResponder;
  onHandleSelectData: (value: string) => void;
  selected: string;
};

const ChiefComplaintsDraggableTable: FC<Props> = React.memo(
  ({ header, items, onDragEnd, onHandleSelectData, selected }) => {
    const smallScreen = useMediaQuery("(max-width:768px)");
    const mobileScreen = useMediaQuery("(max-width:425px)");
    const [showNote, setShowNote] = useState(false);
    const [open, setOpen] = React.useState(false);
    const onRowClick = (val) => {
      onHandleSelectData(val);
    };

    const handleClickOpen = (value: boolean) => {
      if (mobileScreen) {
        setOpen(value);
      }
      else if (smallScreen) {
        setOpen(value);
      }
    };

    const handleClose = () => {
      setOpen(false);
    };
    return (
      <DragDropContext onDragEnd={onDragEnd}>
         
                  
        <Box
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Paper
            style={{
              flex: 1,
            }}
          >
            <Box className="inner-header-container"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}>
              <Typography style={{ fontWeight: 600 }}>{header} </Typography>
              {mobileScreen || smallScreen ? <Box>
                <Button size="small" style={{ minWidth: "30px" }} onClick={() => {
                  handleClickOpen(true);
                }}  >
                  <SubjectRoundedIcon />
                </Button>
              </Box> : ''}
            </Box>
          </Paper>
        </Box>
        <Droppable droppableId="droppable-list">
          {(provided) => (
            <List ref={provided.innerRef} {...provided.droppableProps}>
              {items.map((item: Item, index: number) => (
                <ChiefComplaintsDraggableTableItem
                  item={item}
                  index={index}
                  key={item.id}
                  onHandleSelect={onRowClick}
                  selectedSymptom={selected}
                />
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
        <Divider />
        <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                      }}
                      onSubmit={async (values) => {
                        console.log("values", values);
                      }}
                    >

        <Box style={{ padding: 10, paddingRight: 24, paddingLeft: 24 }}>
          <FormControl fullWidth>
          <CustomSelect
                                // isNotCreatable={true}
                                id={"admission"}
                                FieldName={"admission"}
                                options={[]}
                                placeholder="Search..."
                                openMenuOnFocus
                                isClearable
                                isSearchable
                                menuPortalTarget={document.querySelector("body")}
                                handleChange={(e) => {
                                  console.log("event:", e);
                                }}
                                Value=""
                              />
          </FormControl>
        </Box>
        </Formik>
        <Divider />
        <Box
          style={{
            padding: 10,
          }}
        >
          <Box>
            {!showNote && (
              <Button
                variant="text"
                style={{ fontSize: "6px" }}
                onClick={() => {
                  setShowNote(true);
                }}
              >
                <AddIcon className="add-icon" />
                <span className="add-icon"> Note</span>
              </Button>
            )}
          </Box>

          {showNote && (
            <FormControl fullWidth>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "end",
                  minWidth: "100%",
                }}
              >
                <FormLabel
                  sx={{ paddingLeft: 1 }}
                  className="fileds-label-text"
                >
                  Note
                </FormLabel>
                <Button
                  variant="text"
                  onClick={() => {
                    setShowNote(false);
                  }}
                >
                  <RemoveIcon className="remove-note" />
                  <span className="remove-note">Remove Note</span>
                </Button>
              </Box>
              <ReactQuill
                className="react-quill-alignment"
                theme="snow"
                value={""}
                onChange={(e) => { }}
              />
            </FormControl>
          )}
          <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
                <Typography
                  style={{ fontWeight: 600, marginLeft: 0 }}
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                >
                {selected ? selected : "Chief Complaints"}
                </Typography>
                <IconButton onClick={() => handleClose(true)} sx={{ padding: 0, margin: 0 }} aria-label="delete">
                  <CloseRoundedIcon sx={{ color: " #d32f2f" }} />
                </IconButton>
              </Toolbar>
            </AppBar>
            <DialogContent style={{ height: 400 }}>
              <Box className="template-table-container" sx={{ flexDirection: "column", }}>

                <Box className="inner-title-container">
                </Box>
                <Box sx={{ height: '400px' }}>
                  This is summary section
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
        </Box>
        
      </DragDropContext>
    );
  }
);

export default ChiefComplaintsDraggableTable;
