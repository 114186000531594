import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { displayNameMapping } from "./BreadcrumbHelper"

const CustomBreadcrumbs = () => {
    const location = useLocation();
    const { pathname } = location;
    const segments = pathname.split('/').filter(Boolean);
    let url = "";
    const breadcrumbLink = segments.map((segment, index: number) => {
        url += `/${segment}`;
        const displayName = displayNameMapping[segment] || segment;
        const isLast = segments.length - 1;
        return (
            <Link key={index} to={url}
                style={{
                    fontSize: '14px',
                    textDecoration: 'none',
                    color: index !== isLast ? '#3b75ff' : 'black',
                }}>
                <span>
                    {displayName} {index !== isLast ?
                        '/ '
                        : ""}

                </span>
            </Link >
        );
    });

    return (
        <div>
            {breadcrumbLink}
        </div>
    );
};

export default CustomBreadcrumbs;
