import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import "../../../theme/style.scss";
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  TableContainer,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import PhoneInput from 'react-phone-number-input'
import Title from "../../components/Title";

// import { CustomSelect } from "../../components/CustomFields/CustomSelect";
import { Formik } from "formik";
import DatePickerCompponent from "../../../components/CustomFields/DatePicker";
import { CustomSelect } from "../../../components/CustomFields/CustomSelect";
import CustomBreadcrumbs from "../../../components/BreadcrumbsComponent/CustomBreadcrumbs";

const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#FAFBFD", // Set the desired background color
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));
function TabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}



export default function OPDBillingSave() {
  const navigate = useNavigate();
  const [value, setValue] = useState('');
  const [countryData, setCountry] = useState([
    { countryID: 0, code: "", name: "" },
    { countryID: 1, code: "123", name: "India" },
    { countryID: 2, code: "456", name: "USA" },
  ]);

  interface ColourOption {
    value: string;
    label: string;
  }
  const colourOptions: ColourOption[] = [
    { value: 'dr', label: 'Dr.' },
    { value: 'mr', label: 'Mr.' },
    { value: 'ms', label: 'Ms' },
    { value: 'mrs', label: 'Mrs' },
    { value: 'prof', label: 'Prof' },
  ];
 

  return (
    <Box className="main-container " pl={2}>
      <Grid xs={12} container className="page-header-save">
        <Grid xs={12} sm={12} md={12} item className="page-breadcrumbs">
          <CustomBreadcrumbs />
        </Grid>
      </Grid>

      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
        }}
        onSubmit={async (values) => {
          console.log("values", values)
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} mb={3} className="group-container">
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Basic Information</Typography>
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Billing Table</FormLabel>
                  <CustomTextField
                    name="laboratoryName"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Patient Name</FormLabel>
                  <CustomSelect
                    isNotCreatable={true}
                    id={"referredBy"}
                    FieldName={"referredBy"}
                    //options={colourOptions}
                    openMenuOnFocus
                    isClearable
                    isSearchable
                    menuPortalTarget={document.querySelector("body")}
                    handleChange={(e) => {
                      setValue(e.value)
                      console.log("event:", e)
                    }}
                    Value={value}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Payment Type</FormLabel>
                  <CustomSelect
                    isNotCreatable={true}
                    id={"referredBy"}
                    FieldName={"referredBy"}
                    //options={colourOptions}
                    openMenuOnFocus
                    isClearable
                    isSearchable
                    menuPortalTarget={document.querySelector("body")}
                    handleChange={(e) => {
                      setValue(e.value)
                      console.log("event:", e)
                    }}
                    Value={value}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Invoice Number</FormLabel>
                  <CustomTextField
                    name="laboratoryName"
                    placeholder=""
                    autoCapitalize="none"
                    type="text"
                    fullWidth
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <div >
                  <Box style={{ fontSize: 12 }}>Invoice Date</Box>
                  <FormControl sx={{ width: '100% !important' }}>
                    <DatePickerCompponent
                      format="DD-MM-YYYY"
                      mode="date"
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Po/ref Number</FormLabel>
                  <CustomSelect
                    isNotCreatable={true}
                    id={"referredBy"}
                    FieldName={"referredBy"}
                   // options={colourOptions}
                    openMenuOnFocus
                    isClearable
                    isSearchable
                    menuPortalTarget={document.querySelector("body")}
                    handleChange={(e) => {
                      setValue(e.value)
                      console.log("event:", e)
                    }}
                    Value={value}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Status</FormLabel>
                  <CustomSelect
                    isNotCreatable={true}
                    id={"referredBy"}
                    FieldName={"referredBy"}
                    //options={colourOptions}
                    openMenuOnFocus
                    isClearable
                    isSearchable
                    menuPortalTarget={document.querySelector("body")}
                    handleChange={(e) => {
                      setValue(e.value)
                      console.log("event:", e)
                    }}
                    Value={value}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <FormControl fullWidth>
                  <FormLabel className="fileds-label-text">Due on</FormLabel>
                  <CustomSelect
                    isNotCreatable={true}
                    id={"referredBy"}
                    FieldName={"referredBy"}
                    //options={colourOptions}
                    openMenuOnFocus
                    isClearable
                    isSearchable
                    menuPortalTarget={document.querySelector("body")}
                    handleChange={(e) => {
                      setValue(e.value)
                      console.log("event:", e)
                    }}
                    Value={value}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <div >
                  <Box style={{ fontSize: 12 }}>Due on Date</Box>
                  <FormControl sx={{ width: '100% !important' }}>
                    <DatePickerCompponent
                      format="DD-MM-YYYY"
                      mode="date"
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </FormControl>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} mb={3} className="group-container">
            
          </Grid>
        </Grid>

        
      </Formik>
    </Box>
    
  );
}
