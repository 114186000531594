import React, { useState, Component } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useNavigate } from "react-router-dom";
import "../../../theme/style.scss";
import { Resizable } from "re-resizable";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    styled,
} from "@mui/material";
import Title from "../../../components/Title";
import LoadingButton from "@mui/lab/LoadingButton";
import "react-quill/dist/quill.snow.css";
import { Formik } from "formik";
import DraggableList from "../../../components/DraggableTable/DraggableList";
import { DropResult } from 'react-beautiful-dnd';
import { reorder } from "../../../Helpers/helpers";
import { CustomSelect } from "../../../components/CustomFields/CustomSelect";
import ReactQuill from "react-quill";
import CustomBreadcrumbs from "../../../components/BreadcrumbsComponent/CustomBreadcrumbs";
import { text } from "stream/consumers";

export default function CertificatesTemplateSave() {
    const [templateDetail, setTemplateDetail] = useState("")
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = (value: boolean) => {
        setOpen(value);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const CustomTextField = styled(TextField)(({ theme }) => ({
        backgroundColor: "#FAFBFD", // Set the desired background color
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: theme.palette.grey[300],
                borderWidth: "1px",
                BorderStyle: "solid",
            },
            "&:hover fieldset": {
                borderColor: theme.palette.grey[500],
                borderWidth: "1px",
                BorderStyle: "solid",
            },
            "&.Mui-focused fieldset": {
                borderWidth: "2px",
                borderColor: theme.palette.primary.main,
            },
        },
    }));

    const navigate = useNavigate();
    const [itemsOther, setItemsOther] = React.useState([
        {
            id: "Item1 1",
            primary: "Dr. Smith",
            secondary: "Cardiologist"
        }, {
            id: "Item1 2",
            primary: "Dr. Johnson",
            secondary: "Pediatrician"
        }, {
            id: "Item1 3",
            primary: "Dr. Garcia",
            secondary: "Neurologist"
        }
    ]);

    const onDragEnd1 = ({ destination, source }: DropResult) => {
        // dropped outside the list
        if (!destination) return;

        const newItems = reorder(itemsOther, source.index, destination.index);

        setItemsOther(newItems);
    };

    const [countryData, setCountry] = useState([
        { countryID: 0, code: "", name: "" },
        { countryID: 1, code: "123", name: "India" },
        { countryID: 2, code: "456", name: "USA" },
    ]);

    const initialDynamicText = [
        {
            id: 1,
            text: 'case_no',
            value: '{{case_no}}'
        },
        {
            id: 2,
            text: 'patient_name',
            value: '{{patient_name}}'
        },
        {
            id: 3,
            text: 'case_date',
            value: '{{case_date}}'
        },
        {
            id: 4,
            text: 'patient_gender',
            value: '{{patient_gender}}'
        },
        {
            id: 5,
            text: 'patient_age',
            value: '{{patient_age}}'
        },
    ]

    const [dynamicText, setDynamicText] = useState(initialDynamicText)

    return (
        <Box className="main-container " pl={2}>
            <Grid xs={12} container className="page-header-save">
            <Grid xs={6} sm={6} item className="page-breadcrumbs">
          <CustomBreadcrumbs />
        </Grid>
            </Grid>
            <Formik
                initialValues={{
                    firstName: "",
                    lastName: "",
                    email: "",
                }}
                onSubmit={async (values) => {
                    console.log("values", values);
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} mb={3} className="group-container">
                        <Grid container item spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <FormLabel className="fileds-label-text">
                                        Tempalte Name
                                    </FormLabel>
                                    <CustomTextField
                                        name="branchName"
                                        placeholder=""
                                        autoCapitalize="none"
                                        type="text"
                                        fullWidth
                                        size="small"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Box sx={{ border: '1px solid lightgray', padding: 2, display: 'flex' }}>
                                    {dynamicText.map((text) => {
                                        return (
                                            <Typography sx={{ paddingRight: 1, cursor: 'pointer' }}
                                                onClick={() => setTemplateDetail(templateDetail + " " + text.value)}
                                            >
                                                {` {{${text.text}}} `}
                                            </Typography>
                                        )
                                    })}
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormControl fullWidth>
                                    {/* <Resizable> */}
                                           <CKEditor   
                                        editor={ ClassicEditor }
                                            data = {templateDetail}                 
                                        onReady={ editor => {
                                            // You can store the "editor" and use when it is needed.
                                            console.log( 'Editor is ready to use!', editor );
                                        } }
                                        onChange={ ( event ) => {
                                            console.log( event );
                                        } }
                                        onBlur={ ( event, editor ) => {
                                            console.log( 'Blur.', editor );
                                        } }
                                        onFocus={ ( event, editor ) => {
                                            console.log( 'Focus.', editor );
                                        } }
                                        />
                                    {/* </Resizable> */}
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                            <Box >
                                <LoadingButton
                                    color="primary"
                                    tabIndex={3}
                                    loadingPosition="start"
                                    startIcon={<></>}
                                    variant="contained"
                                    type="submit"
                                    className="primary-button"
                                    width="180px"
                                    name="submit"
                                    onClick={() => handleClickOpen(true)}
                                >
                                    Preview
                                </LoadingButton>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
                                <LoadingButton
                                    color="primary"
                                    tabIndex={3}
                                    loadingPosition="start"
                                    startIcon={<></>}
                                    variant="contained"
                                    type="submit"
                                    className="primary-button"
                                    width="180px"
                                    name="submit"
                                    onClick={() => navigate("/master/certificates-template")}
                                >
                                    SAVE
                                </LoadingButton>
                                <LoadingButton
                                    tabIndex={3}
                                    loadingPosition="start"
                                    startIcon={<></>}
                                    variant="outlined"
                                    type="submit"
                                    className="secondary-button"
                                    color="secondary"
                                    name="submit"
                                    onClick={() => navigate("/master/certificates-template")}
                                >
                                    CANCEL
                                </LoadingButton>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Formik>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        width: "1000px",
                        maxWidth: "none",
                    },
                }}
            >
                <DialogContent style={{ height: 400 }}>

                </DialogContent>
            </Dialog>
        </Box>
    );
}
